import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";

function Contact({ onLoad, mode, pageId, searchText, registerCallBacks, onCall }) {
  const componentName = "uc.phone.book";

  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  //notifications
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onSync: onSyncClick,
      });
  }, [activePage]);

  const onSyncClick = () => {
    application
      .post(operationURLS.sync)
      .then((response) => {
        openSuccessSB();
      })
      .catch((error) => {
        openErrorSB();
      });
  };
  

  //Notifications
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Done"
      content="Contacts Sync Successfully"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Failed"
      content="Unable To Sync. Contact Administrator"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = () => {
    setConfirmDelete(true);
  };

  const onAgree = () => {
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setConfirmDelete(false);
  };

  const addToPhoneBook = (data) => {
    let dataToSave = {
      firstName: data.name,
      designation: data.designation ? data.designation : "",
      phoneOffice: data.offEXtn ? data.offEXtn : "",
      phoneHome: data.phoneHome ? data.phoneHome : ""
    }
    loggerFactory.debug(componentName, "Add to Phone book", data);
    loggerFactory.debug(componentName, "Data For Phone book", dataToSave);

    application.post(operationURLS.add, dataToSave).then((response) => {
      openSuccessSB();
    })
    .catch((error) => {
      openErrorSB();
    });
  }

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="Contact"
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLAdvancedFilter
        entityName="Contact"
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL="uc/phoneBook/list"
        columns={getColumns(addToPhoneBook, onCall)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="type"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        refetchInterval={Attributes.refetchInterval}
        onCall={onCall}
      />
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default Contact;
