import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import CTLDialogContent from "../styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import variables from "globals/variables";
import { IconButton } from "@mui/material";

export default function VoicePickerPlayAudio(row) {
  const id = row.row.original.id;
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={"play"}>
        <Button variant="contained" color="primary"  style={{margin:"10px"}} onClick={handleClickOpen}>
          <PlayArrowIcon />
        </Button>
       </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Voice Picker Audio Recording...
          <IconButton
            color="error"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <ReactPlayer
            url={`${
              variables.app.services
            }auth/epVoicePicker/${id}`}
            width="500px"
            height="60px"
            playing={true}
            controls={true}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
