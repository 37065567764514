import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { IconButton } from "@material-ui/core";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@mui/material";
import { application } from "globals/endpoints";
import SendIcon from "@mui/icons-material/Send";
import CTLNotification from "application/components/CTLNotification";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const operationURLS = {
  fileImport: "/configuration/upload",
};

export default function CTLFileExport({
  entityName,
  fileExport,
  onCloseFileExport,
  setUpdateList,
  setFileExport
}) {
  const [open, setOpen] = React.useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();

  useEffect(() => {
    setOpen(fileExport);
  }, [fileExport]);

  const [file, setFile] = useState();


  const handleClose = () => {
    setOpen(onCloseFileExport);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onUploadFile = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      application
        .post(operationURLS.fileImport, formData)
        .then((response) => {
          console.log("File Upload Successful");
        
          setNotify({
            isOpen: true,
            message: response.message,
            type: 'success',
            // pagename: pageName,
            status: response.data.status,
          });
          setUpdateList(Date.now());
          setTimeout(() => {
            setFileExport(false)
          }, 3000);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.message + " " + error.response.messageDetail,
            type: 'error',
            // pagename: pageName,
            status: error.response.status,
          });
        });
    } else {
      console.log("No file selected.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseFileExport}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      classes={{ dialog }}
    >
      <DialogTitle
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        id="alert-dialog-title"
      >
        {entityName}
      </DialogTitle>

      <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <Box sx={{ width: 100 }}>
          <IconButton>
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{ height: 70, width: 170 }}
              >
                <AttachmentIcon
                  style={{ fontSize: "40px", marginRight: "6px" }}
                />
                <span style={{ paddingLeft: "6px" }}>Attach_File</span>
              </Button>
            </label>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              className={classes.input}
              onChange={handleFileChange}
            />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <MDButton onClick={handleClose} color="secondary">
          Cancel
        </MDButton>

        <MDButton color="success" onClick={onUploadFile} endIcon={<SendIcon />}>
          Send
        </MDButton>
      </DialogActions>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Dialog>
  );
}
