import CTLDate from "application/components/CTLDate";
import DefaultCell from "application/components/DefaultCell";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  enableAddButton: false,
  refetchInterval: 5000,
  enableAdvanceSearch: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/userLog/list",
};

const getColumns = (onCall) => {
  return [
    {
      Header: "Date",
      accessor: "changeEpoch",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <CTLDate value={value} />,
    },
    {
      Header: "Client IP",
      accessor: "clientIp",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "API URL",
      accessor: "url",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Method",
      accessor: "method",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User",
      accessor: "username",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "API Call",
      accessor: "ipHolder",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
