import { telemetry } from "globals/endpoints";
import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import loggerFactory from "globals/logger/logger-factory";
import { useMaterialUIController, setActivePage } from "context";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { navbarIconButton } from "features/Navbars/DashboardNavbar/styles";
import CTLDate from "../CTLDate";
import { Card } from "@mui/material";
import NotificationManager from "globals/websocket/WebSocket";

const requireImage = require.context(
  "../../../../public/images/serverAlertImages",
  true
);

export default function CTLAlertNotification({ light }) {
  const [alert, setAlert] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, transparentNavbar } = controller;
  const [menuItems, setMenuItems] = useState([]);
  const notificationSound = new Audio("/sounds/notificationSound.wav");


   const playNotificationSound = () => {
     notificationSound.play().catch((error) => {
       console.error("Failed to play notification sound:", error);
     });
   };

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const AlertList = (message) => {
    if (message) {
      const outage = JSON.parse(message.body);
      if (outage?.severity!=0) {
        playNotificationSound();
      }
    }
    telemetry
      .get("alert/active/list/imageDetails")
      .then((response) => {
        setMenuItems(response.data.data);
      })
      .catch((error) => {
        loggerFactory.error("Unable to fetch Alert list", error);
      });
  };

  useEffect(() => {
    AlertList();
    NotificationManager.receiveMessageFor("events", AlertList, "nms-events");
  }, []);

  const handleClick = (event) => {
    setAlert(event.currentTarget);
  };
  const handleCloseAlert = () => {
    setAlert(null);
  };

  const handleClose = () => {
    setAlert(null);
    const updatedActivePage = {
      key: "dashboard.controller",
      name: "DashboardController",
      url: "/App/DashboardController/Home",
      description: "Dashboard Controller",
    };
    setActivePage(dispatch, updatedActivePage);
  };

  // Function to check if the image file exists
  const imageExists = (imageName) => {
    try {
      requireImage(`./${imageName}`);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <tooltip title="Alerts">
        <IconButton
          size="small"
          disableRipple
          color="inherit"
          sx={navbarIconButton}
          variant="contained"
          onClick={handleClick}
        >
          <MDBadge
            color="error"
            badgeContent={menuItems?.length}
            max={999}
            size="xs"
            circular
          >
            <Icon sx={iconsStyle}>notifications</Icon>
          </MDBadge>
        </IconButton>
      </tooltip>
      <Menu
        id="simple-menu"
        anchorEl={alert}
        keepMounted
        open={Boolean(alert)}
        onClose={handleCloseAlert}
        PaperProps={{
          style: {
            maxHeight: "530px",
            width: "auto",
            overflowY: "auto",
          },
        }}
      >
        {Array.isArray(menuItems) &&
          menuItems.map((item, index) => (
            <MenuItem key={index} onClick={handleClose}>
              <MDBox style={{ display: "flex", alignItems: "center" }}>
                <Card
                  style={{
                    width: "70px",
                    height: "70px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {imageExists(item?.imageName) ? (
                    <img
                      src={requireImage(`./${item?.imageName}`)}
                      alt={item?.imageName}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "20%",
                      }}
                    />
                  ) : (
                    <img
                      src={requireImage(`./no_pictures.png`)}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10%",
                      }}
                    />
                  )}
                </Card>
                <MDBox style={{ marginLeft: "30px" }}>
                  <MDTypography variant="h6" display="block">
                    {item.serviceCode} / {item.name}
                  </MDTypography>
                  <MDTypography display="block">
                    <CTLDate value={item.alertGeneratedOn} />
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
