import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CTLNotification from "application/components/CTLNotification";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "../../AuthenticationService";
import { useMaterialUIController } from "context";

function isStrongPassword(password) {
  // Password must have at least one uppercase letter, one lowercase letter, one digit, and one special character
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/;
  return strongPasswordRegex.test(password);
}

function CTLPassword() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userName, passWord } = controller;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const cancel = () => {
    navigate("/");
  };

  const isComplexPassword = AuthenticationService.getPasswordComplexity();

  const update = () => {
    if (isComplexPassword === true) {
      if (newPassword !== confirmPassword) {
        setNotify({
          isOpen: true,
          message: "New Password and Confirm Password do not match.",
          type: "error",
        });
        return;
      }

      if (!isStrongPassword(newPassword)) {
        setNotify({
          isOpen: true,
          message:
            "Password must have at least one uppercase letter, one lowercase letter, one digit, and one special character.",
          type: "error",
        });
        return;
      }
    }

    const dataToSave = {
      username: userName,
      oldPassword: passWord,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    if (dataToSave) {
      AuthenticationService.ChangePassword(dataToSave)
        .then(() => {
          setNotify({
            isOpen: true,
            message:
              AuthenticationService.getFullName() === dataToSave.username
                ? "Reset Successfully. Signing out."
                : "Reset Successful for " + dataToSave.username,
            type: "success",
          });

          if (AuthenticationService.getFullName() === dataToSave.username) {
            setTimeout(function () {
              navigate("/");
              window.location.reload();
            }, 1500);
          } else {
            setTimeout(function () {
              navigate("/");
            }, 1500);
          }
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
          });
        });
    }
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Visibility & VisibilityOff Password

  const handleShowNewPassword = () => {
    setShowNewPassword(true);
  };

  const handleHideNewPassword = () => {
    setShowNewPassword(false);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(true);
  };

  const handleHideConfirmPassword = () => {
    setShowConfirmPassword(false);
  };

  return (
    <>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <Card>
          <MDBox
            variant="gradient"
            bgColor="secondary"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
              Change Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type={showNewPassword ? "text" : "password"}
                  label="New Password"
                  variant="standard"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={handleShowNewPassword}
                          onMouseUp={handleHideNewPassword}
                          edge="end"
                          style={{ margin: "1px" }}
                        >
                          {showNewPassword ? (
                            <Visibility color="success" />
                          ) : (
                            <VisibilityOff color="info" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirm New Password"
                  variant="standard"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={handleShowConfirmPassword}
                          onMouseUp={handleHideConfirmPassword}
                          edge="end"
                          style={{ margin: "1px" }}
                        >
                          {showConfirmPassword ? (
                            <Visibility color="success" />
                          ) : (
                            <VisibilityOff color="info" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox
                mt={4}
                mb={1}
                display="flex"
                justifyContent="space-between"
              >
                <MDButton
                  variant="gradient"
                  fullWidth
                  color="secondary"
                  onClick={cancel}
                >
                  cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  fullWidth
                  color="info"
                  onClick={update}
                >
                  Update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default CTLPassword;
