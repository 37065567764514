import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";

function PriConfiguration({ onLoad, pageName,  registerCallBacks, }) {
  const componentName = "default.pri.configuration";
  const [formData, setFormData] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        PriPublish:PriPublish,
      
      });
    application
      .post(operationURLS.list)
      .then((response) => {
        let dataToDisplay = response.data.data[0];
        loggerFactory.debug(
          componentName,
          "Data Received From PRI File",
          dataToDisplay
        );
        setFormData(dataToDisplay);
      })
      .catch((error) => {
        setFormData([]);
      });
  }, []);

  function onSaveForm(data) {
    loggerFactory.debug(componentName, "Data to create", data);
    application
      .post(operationURLS.create, data)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
        return application.post(operationURLS.list);
      })
      .then((response) => {
        let dataToDisplay = response.data.data[0];
        setFormData(dataToDisplay);
      })
      .catch((error) => {
          loggerFactory.debug(
            componentName,
            "Unable to create the PRI file data.",
            error
          );
        setNotify({
          isOpen: true,
          status: error.response.data.status,
          message: response.data.messageDetail,
          type: "error",
          pagename: pageName,
        });
      });
  }


  /// For  PriPublish 
  function PriPublish(){
    application.post(operationURLS.publish).then((response)=>{
      setNotify({
        isOpen: true,
        message: response.data.messageDetail,
        type: "success",
        pagename: pageName,
        message: response.data.message,
        status: response.data.status,
   
      });
    }).catch((error)=>{
      setNotify({
        isOpen: true,
        // status: error.status,
        message:"Required parameters not available!",
        type: "error",
        pagename: pageName,
      });
    })
  }

  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Nodes"
        columns={getColumns(null, null)}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default PriConfiguration;
