import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Divider,
  InputAdornment,
  Tooltip,
  Box,
  Card,
  Grid,
  Tabs,
  Tab,
  Switch,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import MDButton from "components/MDButton";
import formControl from "globals/form-control";
import { useFormik } from "formik";
import loggerFactory from "globals/logger/logger-factory";
import CTLSelect from "../CTLSelect";
import CTLPassword from "../CTLPassword";
import CTLIpField from "../CTLIpField";
import CTLConferenceBrowseFile from "../CTLConferenceBrowser";
import CTLColorPicker from "../CTLColorPicker";
import CTLSelectWithColor from "../CTLSelectWithColor";
import CTLMultipleSelect from "../CTLMultipleSelect";
import SelectImg from "../CTLImgSelect";
import CTLSelectFile from "../CTLSelectFile";

export default function CTLBaseForm({
  onSaveForm,
  columns,
  formData,
  formAttributes,
  enableFormButtons,
  enableAgentFormButtons,
  enableActionButtons,
  registerCallbacks,
  dialogSize,
  handleSend,
}) {
  const componentName = "CTLBaseDialogForm";
  const isSettingsMode = formAttributes && formAttributes.settingsMode;
  const hasTabs = formAttributes && formAttributes.tabs;
  let keyField = formAttributes && formAttributes.keyField;

  if (keyField == null) {
    keyField = "id";
  }

  const columnCount =
    formAttributes && formAttributes.columns ? formAttributes.columns : 1;
  const columnWidth = 12 / columnCount;

  const [value, setValue] = useState(0);
  let height = 0.4 * screen.height;

  let i = 0;
  let prevGroupHeading = "";
  let fieldCount = 0;

  switch (dialogSize) {
    case "xs":
      height = 0.3 * screen.height;
      break;
    case "sm":
      height = 0.4 * screen.height;
      break;
    case "md":
      height = 0.5 * screen.height;
      break;
    case "lg":
      height = 0.6 * screen.height;
      break;
    case "xl":
      height = 0.7 * screen.height;
      break;
    default:
      height = 0.4 * screen.height;
      break;
  }

  const cardHeight =
    formAttributes && formAttributes.tabs && dialogSize && dialogSize !== "full"
      ? `${height}px`
      : "100%";

  const validations = {};
  const initialValues = {};

  registerCallbacks &&
    registerCallbacks({
      onSave: () => {
        formik.handleSubmit();
      },
    });

  columns &&
    columns.map((column) => {
      if (column.allowInForm && column.validation) {
        validations[`field_${column.accessor}`] = column.validation;
      }

      if (column.allowInForm && column.isMandatory) {
        validations[`field_${column.accessor}`] = formControl.string().required("Required").typeError('Required');
      }

    });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formControl.object().shape(validations),
    onSubmit: (values) => {
      saveData(values);
    },
  });

  function saveData(values) {
    let dataToSave = {};

    loggerFactory.debug(componentName, "Raw Data To Save", values);

    columns &&
      columns.map((column) => {
        if (column.allowInForm) {
          if (formData[keyField]) {
            dataToSave[keyField] = formData[keyField];
            dataToSave[`${column.accessor}`] =
              values[`field_${column.accessor}`];
          } else {
            if (column.isUserGenerated) {
              dataToSave[`${column.accessor}`] =
                values[`field_${column.accessor}`];
            } else {
              dataToSave[keyField] = 0;
              dataToSave[`${column.accessor}`] =
                values[`field_${column.accessor}`];
            }
          }
        }
      });

    loggerFactory.debug(componentName, "Data To Save", dataToSave);
    onSaveForm(dataToSave, null, 2);
  }

  useEffect(() => {
    if (formData) {
      columns &&
        columns.map((column) => {
          if (column.allowInForm) {
            if (formData[keyField]) {
              formik.setFieldValue(
                `field_${column.accessor}`,
                formData[column.accessor]
              );
            } else {
              formik.setFieldValue(`field_${column.accessor}`, "");
            }
          }
        });
    }
    setInterval(() => {
      formik.validateForm();
    }, 500);
  }, [formData]);

  const getValues = (fieldName) => {
    if (formik.values[`field_${fieldName}`]) {
      return formik.values[`field_${fieldName}`];
    } else {
      return "";
    }
  };

  const setSwitchValue = (event, column) => {
    formik.setFieldValue(`field_${column.accessor}`, event.target.checked);

    if (column.onChange) {
      column.onChange(event, formik);
    }
  };

  const setSelectValue = (event, column) => {
    formik.setFieldValue(`field_${column.accessor}`, event.target.value);
    if (column.onChange) {
      column.onChange(event, formik);
    }
  };

  const setDateTimeValue = (event, column) => {
    formik.setFieldValue(`field_${column.accessor}`, event.target.value);
    formik.setFieldValue(`field_${column.setChange}`, event.target.value);
    if (column.onChange) {
      column.onChange(event, formik);
    }
  };

  function onReset() {
    let values = {};
    columns &&
      columns.map((column) => {
        if (column.allowInForm) {
          values[`field_${column.accessor}`] = column.defaultValue;
          formik.setFieldValue(`field_${column.accessor}`, column.defaultValue);
        }
      });
    saveData(values);
  }

  function layoutField(column) {
    if (column.controlType == "boolean") {
      if (isSettingsMode) {
        return (
          <Switch
            name={`field_${column.accessor}`}
            checked={getValues(column.accessor)}
            onChange={(event) => setSwitchValue(event, column)}
            sx={{
              "& .MuiSwitch-thumb": {
                bgcolor: getValues(column.accessor) ? "#4CAF50" : "#F44335",
              },
            }}
          />
        );
      } else {
        return (
          <Grid container>
            <Grid item xs={6}>
              <MDTypography variant="caption">{column.Header}</MDTypography>
            </Grid>
            <Grid item xs={3}>
              <Switch
                name={`field_${column.accessor}`}
                checked={getValues(column.accessor)}
                onChange={(event) => setSwitchValue(event, column)}
                sx={{
                  "& .MuiSwitch-thumb": {
                    bgcolor: getValues(column.accessor) ? "green" : "red",
                  },
                }}
              />
            </Grid>
          </Grid>
        );
      }
    } else if (column.controlType == "select") {
      return (
        <CTLSelect
          label={column.Header}
          name={`field_${column.accessor}`}
          value={getValues(column.accessor)}
          onChange={(event) => setSelectValue(event, column)}
          disabled={ column.disabled }
          error={
            formik.touched[`field_${column.accessor}`] &&
            formik.errors[`field_${column.accessor}`]
          }
          success={
            !formik.touched[`field_${column.accessor}`] ||
            !formik.errors[`field_${column.accessor}`]
          }
          options={
            column.options && column.options.values ? column.options.values : []
          }
          keyField={
            column.options && column.options.keyField
              ? column.options.keyField
              : ""
          }
          keyDescription={
            column.options && column.options.keyDescription
              ? column.options.keyDescription
              : ""
          }
          accessorKeyField={
            column.options && column.options.accessorKeyField
              ? column.options.accessorKeyField
              : ""
          }
          accessorValueField={
            column.options && column.options.accessorValueField
              ? column.options.accessorValueField
              : ""
          }
          url={column.options && column.options.url ? column.options.url : ""}
        />
      );
    } else if (column.controlType == "imgSelect") {
      return (
        <SelectImg
          label={column.Header}
          name={`field_${column.accessor}`}
          value={getValues(column.accessor)}
          onChange={formik.handleChange}
          error={
            formik.touched[`field_${column.accessor}`] &&
            formik.errors[`field_${column.accessor}`]
          }
          success={
            !formik.touched[`field_${column.accessor}`] ||
            !formik.errors[`field_${column.accessor}`]
          }
          options={
            column.options && column.options.values ? column.options.values : []
          }
          keyField={
            column.options && column.options.keyField
              ? column.options.keyField
              : ""
          }
          keyDescription={
            column.options && column.options.keyDescription
              ? column.options.keyDescription
              : ""
          }
          accessorKeyField={
            column.options && column.options.accessorKeyField
              ? column.options.accessorKeyField
              : ""
          }
          accessorValueField={
            column.options && column.options.accessorValueField
              ? column.options.accessorValueField
              : ""
          }
        />
      );
    } else if (column.controlType == "selectWithColor") {
      return (
        <CTLSelectWithColor
          label={column.Header}
          name={`field_${column.accessor}`}
          value={getValues(column.accessor)}
          onChange={formik.handleChange}
          error={
            formik.touched[`field_${column.accessor}`] &&
            formik.errors[`field_${column.accessor}`]
          }
          success={
            !formik.touched[`field_${column.accessor}`] ||
            !formik.errors[`field_${column.accessor}`]
          }
          options={
            column.options && column.options.values ? column.options.values : []
          }
          keyField={
            column.options && column.options.keyField
              ? column.options.keyField
              : ""
          }
          keyDescription1={
            column.options && column.options.keyDescription1
              ? column.options.keyDescription1
              : ""
          }
          keyDescription2={
            column.options && column.options.keyDescription2
              ? column.options.keyDescription2
              : ""
          }
          accessorKeyField={
            column.options && column.options.accessorKeyField
              ? column.options.accessorKeyField
              : ""
          }
          accessorValueField={
            column.options && column.options.accessorValueField
              ? column.options.accessorValueField
              : ""
          }
          url={column.options && column.options.url ? column.options.url : ""}
        />
      );
    } else if (column.controlType == "multipleSelect") {
      return (
        <CTLMultipleSelect
          label={column.Header}
          name={`field_${column.accessor}`}
          value={getValues(column.accessor)}
          onChange={formik.handleChange}
          error={
            formik.touched[`field_${column.accessor}`] &&
            formik.errors[`field_${column.accessor}`]
          }
          success={
            !formik.touched[`field_${column.accessor}`] ||
            !formik.errors[`field_${column.accessor}`]
          }
          options={
            column.options && column.options.values ? column.options.values : []
          }
          keyField={
            column.options && column.options.keyField
              ? column.options.keyField
              : ""
          }
          keyDescription={
            column.options && column.options.keyDescription
              ? column.options.keyDescription
              : ""
          }
          accessorKeyField={
            column.options && column.options.accessorKeyField
              ? column.options.accessorKeyField
              : ""
          }
          accessorValueField={
            column.options && column.options.accessorValueField
              ? column.options.accessorValueField
              : ""
          }
          url={column.options && column.options.url ? column.options.url : ""}
        />
      );
    } else if (column.controlType == "password") {
      return (
        <CTLPassword
          label={column.Header}
          name={`field_${column.accessor}`}
          value={getValues(column.accessor)}
          onChange={formik.handleChange}
          error={formik.dirty && formik.errors[`field_${column.accessor}`]}
          success={!formik.errors[`field_${column.accessor}`]}
          autoComplete={column.autoComplete === false ? "off" : "on"}
          disabled={column.disabled}
        />
      );
    } else if (column.controlType == "upload") {
      return <CTLConferenceBrowseFile />;
    } else if (column.controlType == "fileUpload") {
      return (
        <CTLSelectFile getValues={getValues} formik={formik} column={column} />
      );
    } else if (column.controlType == "colorPicker") {
      return (
        <CTLColorPicker getValues={getValues} formik={formik} column={column} />
      );
    } else if (column.controlType === "date") {
      return (
        <>
          <FormField
            label={column.Header}
            name={`field_${column.accessor}`}
            value={getValues(column.accessor)}
            onChange={(event) => setDateTimeValue(event, column)}
            type="date"
            InputLabelProps={{ shrink: true }}
            autoComplete={column.autoComplete === false ? "off" : "on"}
          />
        </>
      );
    } else if (column.controlType == "ip") {
      return (
        <CTLIpField
          label={column.Header}
          name={`field_${column.accessor}`}
          value={getValues(column.accessor)}
          onChange={formik.handleChange}
          placeholder={"192.168.20.105"}
          autoComplete={column.autoComplete === false ? "off" : "on"}
          disabled={
            formData && formData[keyField] == null ? false : column.disabled
          }
        />
      );
    } else if (column.controlType == "textField") {
      return (
        <TextField
          name={`field_${column.accessor}`}
          label={column.Header}
          value={getValues(column.accessor)}
          onChange={(event) => setSelectValue(event, column)}
          autoComplete={column.autoComplete === false ? "off" : "on"}
          multiline
          fullWidth
          minRows={3}
          maxRows={6}
          InputLabelProps={{ shrink: true }}
        />
      );
    } else if (column.controlType == "dateTime") {
      return (
        <FormField
          name={`field_${column.accessor}`}
          label={column.Header}
          value={getValues(column.accessor)}
          onChange={(event) => setDateTimeValue(event, column)}
          type="datetime-local"
          autoComplete={column.autoComplete === false ? "off" : "on"}
          InputLabelProps={{ shrink: true }}
          inputProps={{ readOnly: column.disabled }}
        />
      );
    } else if (column.controlType == "time") {
      return (
        <FormField
          name={`field_${column.accessor}`}
          label={column.Header}
          value={getValues(column.accessor)}
          onChange={(event) => setDateTimeValue(event, column)}
          type="time"
          autoComplete={column.autoComplete === false ? "off" : "on"}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            readOnly:
              formData && formData[keyField] == null ? false : column.disabled,
          }}
        />
      );
    } else {
      let fieldLabel = formik.errors[`${column.accessor}`]
        ? `${column.Header}` +
          " (" +
          formik.errors[`field_${column.accessor}`] +
          ")"
        : `${column.Header}`;

      if (isSettingsMode) {
        fieldLabel = "";
      }

      return column.hiddenForm ? (
        ""
      ) : (
        <FormField
          name={`field_${column.accessor}`}
          label={fieldLabel}
          autoComplete={column.autoComplete === false ? "off" : "on"}
          value={
            column.toUpperCase
              ? getValues(column.accessor)?.toUpperCase() ||
                column.defaultValue?.toUpperCase()
              : getValues(column.accessor) || column.defaultValue
          }
          onChange={formik.handleChange}
          error={formik.dirty && formik.errors[`field_${column.accessor}`]}
          success={!formik.errors[`field_${column.accessor}`]}
          Required={true}
          inputProps={{
            readOnly:
              formData && formData[keyField] == null ? false : column.disabled,
            style:
              column.dataType === "integer"
                ? { textAlign: "right" }
                : { textAlign: "left" },
          }}
          InputProps={
            column.dataType == "integer"
              ? {
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        marginLeft: "5px",
                      }}
                      position="start"
                    >
                      <MDTypography
                        variant="caption"
                        color={
                          formik.errors[`field_${column.accessor}`]
                            ? "error"
                            : "dark"
                        }
                      >
                        {column.dataUnit}
                      </MDTypography>
                    </InputAdornment>
                  ),
                }
              : {
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography
                        variant="caption"
                        color={
                          formik.errors[`field_${column.accessor}`]
                            ? "error"
                            : "dark"
                        }
                      >
                        {column.dataUnit}
                      </MDTypography>
                    </InputAdornment>
                  ),
                }
          }
        />
      );
    }
  }

  function settingsLayout(fieldCount, groupHeading, column, i) {
    return (
      <>
        {groupHeading && (
          <Grid item xs={12}>
            <MDBox
              sx={{
                borderBottom: 5,
                marginTop: fieldCount == 1 ? 1 : 5,
                borderColor: "dark",
              }}
            >
              <MDTypography variant="h6">{groupHeading}</MDTypography>
            </MDBox>
          </Grid>
        )}
        {fieldCount == 1 && <Grid item xs={12}></Grid>}
        <Grid item xs={9} style={{ paddingTop: "0px" }}>
          <MDTypography variant="h6" color="dark">
            {`${column.Header}`}
          </MDTypography>
          {column.detailedExplanation && (
            <MDTypography variant="caption">
              {column.detailedExplanation}{" "}
              {column.defaultValue
                ? "if left empty, the default value would be " +
                  column.defaultValue
                : ""}
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={1} style={{ paddingTop: "0px" }}>
          {column.dataUnit && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDTypography inline variant="button" align="right">
                {column.dataUnit} :
              </MDTypography>
            </Box>
          )}
        </Grid>
        <Grid item xs={2} style={{ paddingTop: "0px" }}>
          <Tooltip title={`Default value : ${column.defaultValue}`}>
            {layoutField(column)}
          </Tooltip>
          {formik.touched[`field_${column.accessor}`] &&
            formik.errors[`field_${column.accessor}`] && (
              <MDTypography color="error" variant="caption">
                {formik.errors[`field_${column.accessor}`]}
              </MDTypography>
            )}
        </Grid>
        <Grid item xs={12} style={{ padding: "0px" }}>
          <Divider />
        </Grid>
      </>
    );
  }

  function formLayout(fieldCount, groupHeading, column, i) {
    return (
      <>
        {groupHeading && (
          <Grid item xs={12}>
            <MDBox
              sx={{
                borderBottom: 5,
                marginTop: fieldCount == 1 ? 1 : 5,
                borderColor: "dark",
              }}
            >
              <MDTypography variant="h6">{groupHeading}</MDTypography>
            </MDBox>
          </Grid>
        )}

        <Grid item xs={column.width ? column.width : columnWidth}>
          {layoutField(column)}
          {column.detailedExplanation && (
            <MDTypography
              component="div"
              color={
                formik.errors[`field_${column.accessor}`] ? "error" : "dark"
              }
              variant="caption"
            >
              {column.detailedExplanation}{" "}
              {column.defaultValue
                ? "if left empty, the default value would be " +
                  column.defaultValue
                : ""}
              .
            </MDTypography>
          )}
          {formik.errors[`field_${column.accessor}`] && (
            <MDTypography color="error" variant="caption">
              {formik.errors[`field_${column.accessor}`]}
            </MDTypography>
          )}
        </Grid>
        {i == columnCount && (
          <Grid item xs={12} style={{ padding: "0px" }}>
            <Divider></Divider>
          </Grid>
        )}
      </>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Card
      style={{
        marginTop: dialogSize == "full" ? "" : "5px",
        // height: `${cardHeight}`,
      }}
    >
      <MDBox pb={1} px={1} pt={1} pr={1}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={hasTabs ? 1 : 0}
            sx={dialogSize == "full" ? { height: "calc(100vh - 64px)" } : {}}
          >
            {hasTabs && (
              <Grid
                item
                xs={
                  formAttributes.verticalTabs
                    ? formAttributes.tabSize
                      ? formAttributes.tabSize
                      : 4
                    : 12
                }
                sx={
                  formAttributes.verticalTabs
                    ? { borderRight: 5, borderColor: "divider" }
                    : { borderBottom: 5, borderColor: "divider" }
                }
              >
                <Tabs
                  orientation={
                    formAttributes.verticalTabs ? "vertical" : "horizontal"
                  }
                  variant="fullWidth"
                  value={value}
                  onChange={handleChange}
                  aria-label="Tabs"
                >
                  {formAttributes.tabs.map((tab) => {
                    return (
                      <Tab
                        key={tab.id}
                        icon={tab.icon}
                        wrapped={false}
                        sx={{
                          justifyContent: formAttributes.verticalTabs
                            ? "flex-start"
                            : "",
                          marginBottom: "1px !important",
                          height: "56px !important",
                          backgroundColor: value === tab.id ? "info.main" : "",
                        }}
                        label={tab.name}
                        {...a11yProps(tab.id)}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
            )}
            <Grid
              item
              xs={
                formAttributes.verticalTabs
                  ? formAttributes.tabSize
                    ? 12 - formAttributes.tabSize
                    : 8
                  : 12
              }
              sx={
                dialogSize == "full"
                  ? { height: "calc(100vh - 132px)", overflow: "scroll" }
                  : {}
              }
            >
              <Grid container spacing={3}>
                {columns &&
                  columns.map((column) => {
                    if (
                      column.allowInForm &&
                      (!hasTabs ||
                        (value == 0 && !column.tabId) ||
                        (value != 0 && value === column.tabId))
                    ) {
                      fieldCount++;
                      let displayHeading = "";
                      let groupHeading = prevGroupHeading;
                      prevGroupHeading = column.groupHeading;

                      if (
                        prevGroupHeading !== groupHeading &&
                        formAttributes.enableGroupHeading
                      ) {
                        i = 0;
                        displayHeading = column.groupHeading;
                      } else {
                        groupHeading = "";
                      }

                      if (i >= columnCount) {
                        i = 0;
                      }
                      if (column.width) {
                        i = i + column.width / columnCount;
                      } else {
                        i++;
                      }
                      return isSettingsMode
                        ? settingsLayout(fieldCount, displayHeading, column, i)
                        : formLayout(fieldCount, displayHeading, column, i);
                    }
                  })}
              </Grid>
            </Grid>
            {enableFormButtons && (
              <Grid
                item
                xs={12}
                sx={
                  formAttributes.verticalTabs
                    ? { borderTop: 5, borderColor: "divider" }
                    : {}
                }
              >
                <DialogActions>
                  <MDButton color="success" autoFocus type="submit">
                    Apply
                  </MDButton>
                </DialogActions>
              </Grid>
            )}
            {enableAgentFormButtons && (
              <Grid
                item
                xs={12}
                sx={
                  formAttributes.verticalTabs
                    ? { borderTop: 5, borderColor: "divider" }
                    : {}
                }
              >
                <DialogActions>
                  <MDButton color="success" autoFocus type="submit">
                    Submit
                  </MDButton>
                  {/* <MDButton
                    color="info"
                    autoFocus
                    onClick={() => {
                      handleSend("Send Message To");
                    }}
                  >
                    Send Message
                  </MDButton>
                  <MDButton
                    color="info"
                    autoFocus
                    onClick={() => {
                      handleSend("Send Email To");
                    }}
                  >
                    Send Email
                  </MDButton> */}
                </DialogActions>
              </Grid>
            )}
            {enableActionButtons && (
              <Grid
                item
                xs={12}
                sx={
                  formAttributes.verticalTabs
                    ? { borderTop: 5, borderColor: "divider" }
                    : {}
                }
              >
                <DialogActions>
                  <MDButton onClick={onReset} color="secondary">
                    Set to Default
                  </MDButton>
                  <MDButton color="success" autoFocus type="submit">
                    Apply
                  </MDButton>
                </DialogActions>
              </Grid>
            )}
          </Grid>
        </form>
      </MDBox>
    </Card>
  );
}
