import React, { useRef, useEffect, useState } from "react";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { IconButton } from "@material-ui/core";
import { CardActionArea } from "@mui/material";
import Icon from "@mui/material/Icon";
import { ChevronRightRounded, Delete } from "@material-ui/icons";

import {
  ChevronLeftRounded,
  ExpandLess,
  ExpandMore,
  MoreVert,
  MoveUp,
} from "@mui/icons-material";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const NodeLabel = ({
  nodeDatum,
  foreignObjectProps,
  toggleNode,
  deleteRow,
  shiftNode,
  addChild,
  onOpenForm,
  editProperty,
  onOpenMenu,
  onNavigate,
  onTransferNode
}) => {
  const hasChildren = nodeDatum.children && nodeDatum.children.length > 0;
  const [isHovering, setIsHovering] = useState(false);
  const enableShifting =
    nodeDatum.apiResponseType != "ON-SUCCESS" &&
    nodeDatum.apiResponseType != "ON-FAIL";

  const getNodeIcon = () => {
    if (nodeDatum.nodeType === "TREE" || nodeDatum.key === "P") {
      return <Icon>account_tree</Icon>;
    } else if (nodeDatum.nodeType === "EXT") {
      return <Icon>phone_forwarded</Icon>;
    } else if (nodeDatum.nodeType === "PF") {
      return <Icon>play_arrow</Icon>;
    } else if (nodeDatum.nodeType === "LTS") {
      return <Icon>link</Icon>;
    } else if (nodeDatum.nodeType === "API") {
      return <Icon>keyboard</Icon>;
    } else if (nodeDatum.nodeType === "ON-SUCCESS") {
      return <Icon>done</Icon>;
    } else if (nodeDatum.nodeType === "ON-FAIL") {
      return <Icon>close</Icon>;
    }
  };

  const getChildIcon = () => {
    if (nodeDatum.apiResponseType === "ON-SUCCESS") {
      return <Icon>done</Icon>;
    } else if (nodeDatum.apiResponseType === "ON-FAIL") {
      return <Icon>close</Icon>;
    }
  };

  const getNodeHeader = () => {
    if (nodeDatum.apiResponseType === "ON-SUCCESS") {
      return "(S)";
    } else if (nodeDatum.apiResponseType === "ON-FAIL") {
      return "(F)";
    } else if (nodeDatum.key === "P") {
      return "Start";
    } else if (nodeDatum.nodeType === "TREE") {
      return nodeDatum.dtmf;
    } else if (nodeDatum.nodeType === "EXT") {
      return nodeDatum.dtmf;
    } else if (nodeDatum.nodeType === "PF") {
      return nodeDatum.dtmf;
    } else if (nodeDatum.nodeType === "LTS") {
      return nodeDatum.dtmf;
    } else if (nodeDatum.nodeType === "API") {
      if (nodeDatum.siblingCount >= 2) {
        return nodeDatum.dtmf;
      } else {
        return "VAL";
      }
    } else if (nodeDatum.nodeType === "ON-SUCCESS") {
      return "VAL";
    } else if (nodeDatum.nodeType === "ON-FAIL") {
      return "VAL";
    }

  };

  const getNodeLabel = () => {
    let label;

    if (nodeDatum.apiResponseType && nodeDatum.apiResponseType == nodeDatum.nodeType) {
      if (nodeDatum.apiResponseType === "ON-SUCCESS") {
        return "Success";
      } else if (nodeDatum.apiResponseType === "ON-FAIL") {
        return "Fail";
      }
    } else {
      if (nodeDatum.properties) {
        if (nodeDatum.nodeType === "LTS" && nodeDatum.properties.ltsReferenceCode) {
          label = "Ref:" + nodeDatum.properties.ltsReferenceCode;
        } else if (nodeDatum.nodeType === "EXT" && nodeDatum.properties.extensionForwardTo) {
          label = nodeDatum.properties.extensionForwardTo;
        } else if (nodeDatum.properties.description) {
          label = nodeDatum.properties.description;
          } else if (nodeDatum.properties.code) {
          label = nodeDatum.properties.code;
           } else {
            label = nodeDatum.dtmf;
           }
      } else {
        label = nodeDatum.dtmf;
      }
    }

    if (nodeDatum.key === "P") {
      return label ? label : "Start";
    } else if (nodeDatum.nodeType === "ON-SUCCESS") {
      return "Success";
    } else if (nodeDatum.nodeType === "ON-FAIL") {
      return "Fail";
    } else {
      return label;
    }
  };

  return (
    <g>
      <foreignObject {...foreignObjectProps}>
        <Card>
          <MDBox
            sx={{
              backgroundColor: "dark.main",
              borderRadius: "5px",
              boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.3)",
              textAlign: "center",
            }}
            variant="gradient"
            coloredShadow="error"
          >
            <MDBox
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              sx={{
                position: "relative",
                borderBottom: hasChildren
                  ? "3px solid green"
                  : "3px solid blue",
                borderRadius: "5px",
                backgroundColor: hasChildren ? "green" : "blue",
              }}
            >
              <MDTypography align="center" variant="h6">
                {isHovering ? "" : getChildIcon()}{" "}
                {isHovering ? "" : getNodeIcon()} {getNodeHeader()}
              </MDTypography>
              <>
                {nodeDatum.dtmf && enableShifting && (
                  <>
                    <IconButton
                      onClick={() => shiftNode(nodeDatum.key, -1)}
                      style={{
                        position: "absolute",
                        top: 3,
                        left: -2,
                        padding: 0,
                        display: isHovering ? "inline-block" : "none",
                      }}
                    >
                      <ChevronLeftRounded fontSize="small" color="error" />
                    </IconButton>
                    <IconButton
                      onClick={() => shiftNode(nodeDatum.key, 1)}
                      style={{
                        position: "absolute",
                        top: 3,
                        left: 20,
                        padding: 0,
                        display: isHovering ? "inline-block" : "none",
                      }}
                    >
                      <ChevronRightRounded fontSize="small" color="error" />
                    </IconButton>
                    <IconButton
                      onClick={() => deleteRow(nodeDatum.key)}
                      style={{
                        position: "absolute",
                        top: 3,
                        right: -2,
                        padding: 0,
                        display: isHovering ? "inline-block" : "none",
                      }}
                    >
                      <Delete fontSize="small" color="error" />
                    </IconButton>
                  </>
                )}

                {nodeDatum.dtmf && (
                  <IconButton
                    onClick={() => deleteRow(nodeDatum.key)}
                    style={{
                      position: "absolute",
                      top: 3,
                      right: -2,
                      padding: 0,
                      display: isHovering ? "inline-block" : "none",
                    }}
                  >
                    <Delete fontSize="small" color="error" />
                  </IconButton>
                )}
              </>
            </MDBox>
            <MDBox
              onClick={() => {
                editProperty(nodeDatum.key);
              }}
            >
              <MDTypography align="center" variant="caption">
                {getNodeLabel()}
              </MDTypography>
            </MDBox>

            <CardActionArea
              style={{ borderTop: "1px solid black" }}
              component="div"
            >
              {!nodeDatum.__rd3t.collapsed && hasChildren && (
                <IconButton size="small" onClick={toggleNode}>
                  <ExpandMore color="success"></ExpandMore>
                </IconButton>
              )}
              {nodeDatum.__rd3t.collapsed && hasChildren && (
                <IconButton size="small" onClick={toggleNode}>
                  <ExpandLess color="primary"></ExpandLess>
                </IconButton>
              )}
              <IconButton
                edge="end"
                size="small"
                textAlign="end"
                onClick={(event) => {
                  onOpenMenu(event, nodeDatum);
                }}
              >
                <MoreVert color="success"></MoreVert>
              </IconButton>

              {!(nodeDatum?.nodeType == "ON-SUCCESS" || nodeDatum?.nodeType == "ON-FAIL") &&
                <IconButton
                  edge="end"
                  size="small"
                  textAlign="end"
                  onClick={(event) => {
                    onTransferNode(event, nodeDatum);
                  }}
                >
                  <MoveUp color="success"></MoveUp>
                </IconButton>}

              {nodeDatum.nodeType && nodeDatum.nodeType == "EXT" ? (
                <>
                  <IconButton
                    onClick={() => onNavigate(nodeDatum)}
                    style={{
                      position: "absolute",
                      top: 8,
                      left: 120,
                      padding: 0,
                    }}
                  >
                    <ArrowCircleRightIcon fontSize="small" color="white" />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
            </CardActionArea>
          </MDBox>
        </Card>
      </foreignObject>
    </g>
  );
};

export default NodeLabel;