import React from "react";
import variables from "globals/variables";

function ConferencePortal(){
    window.open(`${variables.others.conferencePortal}/clouduc/login`);
    window.location.reload(false);
}

export default ConferencePortal;


