/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

import { useEffect, useState } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { Link } from "@mui/material";
// @mui material components
import { Breadcrumbs as MuiBreadcrumbs, Tooltip } from "@mui/material";
import { application } from "globals/endpoints";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider, Menu } from "@mui/material";
import UCNotificationItem from "application/components/UCNotificationItem";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

function Breadcrumbs({ description, title, light }) {
  const [switchServerMenu, setSwitchServerMenu] = useState(null);
  const [nodeList, setNodeList] = useState([]);
  const [rluList, setRluList] = useState([]);
  const [serverName, setServerName] = useState("");
  const [serverIp, setServerIp] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await application.post("/nodeConfiguration/list");
        const data = response.data.data[0];

        if (data) {
          setNodeList(data.nodeConfiguration);
          setRluList(data.controlRluServer);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleOpen = (event) => {
    setSwitchServerMenu(event.currentTarget);
  };

  const handleClose = () => {
    setSwitchServerMenu(null);
  };

  let Domain = window.location.hostname;
  let isDomain = !/^\d+\.\d+\.\d+\.\d+$/.test(Domain);

  const handleSwitch = (serverIp, serverName) => () => {
    setServerIp(serverIp);
    setServerName(serverName);
    setSwitchServerMenu(null);
    let domainParts = window.location.hostname.split(".");
    domainParts.shift();
    let currentDomain = serverName + "." + domainParts.join(".");

    application.defaults.baseURL = serverIp
      ? window.location.protocol +
        "//" +
        `${isDomain ? currentDomain : serverIp}` +
        "/services/api/v2"
      : variables.api.services;
  };

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MDBox>
        <Menu
          anchorEl={switchServerMenu}
          open={Boolean(switchServerMenu)}
          onClose={handleClose}
        >
          <MDTypography style={{ textAlign: "center" }}>
            Node Server
          </MDTypography>
          <Divider />
          {nodeList.map((item, index) => (
            <Link
              to="javascript:void(0)"
              key={item.nodeIp}
              onClick={handleSwitch(item.nodeIp, item.nodeName)}
            >
              <UCNotificationItem
                title={
                  item.nodeName != null || item.nodeName != ""
                    ? item.nodeName
                    : item.nodeIp
                }
              />
            </Link>
          ))}
          <Divider />
          <MDTypography style={{ textAlign: "center" }}>
            Rlu Server
          </MDTypography>
          <Divider />
          {rluList.map((item, index) => (
            <Link
              to="javascript:void(0)"
              key={item.virtualIp}
              onClick={handleSwitch(item.virtualIp, item.rluCode)}
            >
              <UCNotificationItem
                title={
                  item.rluCode != null || item.rluCode != ""
                    ? item.rluCode
                    : item.virtualIp
                }
              />
            </Link>
          ))}
        </Menu>
      </MDBox>
      <Tooltip
        arrow
        title={serverName == "" || serverIp == "" ? Domain : serverIp}
        placement="bottom-start"
      >
        <MDTypography
          fontWeight="bold"
          textTransform="capitalize"
          variant="h6"
          color={light ? "white" : "dark"}
          noWrap
          onClick={handleOpen}
          style={{ cursor: "pointer" }}
        >
          {serverIp == "" || serverName == "" ? Domain : serverName}
        </MDTypography>
      </Tooltip>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")} : {description}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
