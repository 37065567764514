import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "/callDispositionLead/list",
  csvDownload: "/callDispositionLead/downloadCSV",
  pdfDownload: "/callDispositionLead/generateAndDownloadPdf",
};

const getColumns = () => {
  return [
    {
      Header: "CreatedOn",
      accessor: "createdOn",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign",
      accessor: "campaign",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "AgentId",
      accessor: "agentId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "AgentName",
      accessor: "agentName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CustomerName",
      accessor: "customerName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "PhoneNumber",
      accessor: "phoneNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dateTime: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "AccountNumber",
      accessor: "accountNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "EmailId",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Disposition",
      accessor: "disposition",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
