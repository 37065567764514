import { useEffect, useState,} from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLNotification from "application/components/CTLNotification";

function QueueAgent({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  setQueueAgent,
  queueupdateList,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;

  const [openFilter, setOpenFilter] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onAgent: onOpenAgentForm,
      });
  }, [activePage]);

  useEffect(() => {
    setUpdateList(Date.now());
  }, [ivrExtension, queueupdateList]);

  useEffect(() => {
    setQueueAgent(selectedRow);
  }, [selectedRow]);

  const QAgentoperationURLS = {
    agentQueueList: `/agent/listQueue/${ivrExtension?.code?.queue}`,
  };

  function onOpenAgentForm() {
    setOpenForm(true);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  const editRow = (row) => {
    const dataToEdit = { ...row };

    dataToEdit.parentLocation = row?.parentLocation?.id
      ? row.parentLocation.id
      : null;

    setFormData(dataToEdit);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onSelectionChange = (rowId) => {
    setSelectedRow(rowId);
  };
  const onChange = () => {
    setUpdateList(Date.now());
  };

  return (
    <MDBox>
      <CTLTable
        apiURL={QAgentoperationURLS.agentQueueList}
        onSelectionChange={onSelectionChange}
        columns={getColumns(deleteRow, editRow, onChange)}
        entriesPerPage="false"
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="name"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={true}
        updateList={updateList}
        disablePagination={true}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default QueueAgent;
