import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";

export default [
    {
        Header: "Description",
        accessor: "description",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation: "Description",
        defaultValue: "",
        dataUnit: "",
        tabId: 0,
    },
    {
        Header: "Reference Code",
        accessor: "code",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Unique code defined by user to create link from some other step",
        defaultValue: "",
        dataUnit: "",
        tabId: 0,
    },
    {
        Header: "Welcome File",
        accessor: "welcomeFile",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "select",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Select a file from media centre or upload a new file to play welcome announcement to the caller",
        defaultValue: "",
        dataUnit: "",
        tabId: 0,
        controlType: "select",
        options: {
            keyField: "fileName",
            keyDescription: "fileName",
            values: [],
            url: "autoAttendant/fileList",
        },
    },
    {
        Header: "Introduction File",
        accessor: "introFile",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "medialFile",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Select a file from media centre or upload a new file to play welcome introduction to the caller",
        defaultValue: "",
        dataUnit: "",
        tabId: 0,
        controlType: "select",
        options: {
            keyField: "fileName",
            keyDescription: "fileName",
            values: [],
            url: "autoAttendant/fileList",
        },
    },
    {
        Header: "Default Transfer Day",
        accessor: "defaultTransferDay",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Use this configuration to define extension to which call should be transferred automatically during office hours",
        defaultValue: "",
        dataUnit: "Extension",
        tabId: 0,
    },
    {
        Header: "Default Transfer Night",
        accessor: "defaultTransferNight",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Use this configuration to define extension to which call should be transferred automatically during office hours. If this configuration is not defined then day time extension will be applied used to transfer the call",
        defaultValue: "",
        dataUnit: "Extension",
        tabId: 0,
    },
    {
        Header: "Operator during Day",
        accessor: "operatorDay",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Use this configuration to define the operator extension for the flow.",
        defaultValue: "",
        dataUnit: "Extension",
        tabId: 0,
    },
    {
        Header: "Operator For Night",
        accessor: "operatorNight",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Use this configuration to define the operator extension for the flow.  If this configuration is not defined then day time operator will be applied used to transfer the call.",
        defaultValue: "",
        dataUnit: "Extension",
        tabId: 0,
    },
    {
        Header: "Wait Time",
        accessor: "waitTime",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "integer",
        Cell: ({ value }) => <DefaultCell value={value} />,
        validation: formControl.string().digitsOnly().nullable(true),
        detailedExplanation:
            "Number of seconds that system will wait for before triggering no input event",
        defaultValue: "30",
        dataUnit: "Seconds",
        tabId: 1,
    },
    {
        Header: "No Input File Name",
        accessor: "noInputFileName",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "medialFile",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Select file from media center / upload new file to play prompt for no input provided. The prompt will be played after wait time expires",
        defaultValue: "",
        dataUnit: "",
        tabId: 1,
        controlType: "select",
        options: {
            keyField: "fileName",
            keyDescription: "fileName",
            values: [],
            url: "autoAttendant/fileList",
        },
    },
    {
        Header: "Maximum length of input",
        accessor: "maxInput",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "integer",
        Cell: ({ value }) => <DefaultCell value={value} />,
        validation: formControl.string().digitsOnly().nullable(true),
        detailedExplanation:
            "Use this value to define the maximum number of digits allowed.",
        defaultValue: "1",
        dataUnit: "Digits",
        tabId: 1,
    },
    {
        Header: "Invalid File Name",
        accessor: "invalidFileName",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "medialFile",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Select file from media center / upload new file to play prompt for not selecting a valid input",
        defaultValue: "",
        dataUnit: "",
        tabId: 1,
        controlType: "select",
        options: {
            keyField: "fileName",
            keyDescription: "fileName",
            values: [],
            url: "autoAttendant/fileList",
        },
    },
    {
        Header: "Maximum Retry",
        accessor: "maxRetry",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "integer",
        Cell: ({ value }) => <DefaultCell value={value} />,
        validation: formControl.string().digitsOnly().nullable(true),
        detailedExplanation:
            "Maximum number retries allowed before Maximum Retry event.",
        defaultValue: "",
        dataUnit: "",
        tabId: 2,
    },
    {
        Header: "Maximum Retry File Name",
        accessor: "maxRetryFileName",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "mediaFile",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Select file from media center / upload new file to play prompt when user has exceeded maximum tries of providing valid input",
        defaultValue: "",
        dataUnit: "",
        tabId: 2,
        controlType: "select",
        options: {
            keyField: "fileName",
            keyDescription: "fileName",
            values: [],
            url: "autoAttendant/fileList",
        },
    },
];