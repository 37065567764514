import React, { useEffect, useRef, useState } from "react";
import {
  Icon,
  Grid,
  ButtonGroup,
  Tooltip,
  Divider,
  Button,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  InputAdornment,
  Select,
} from "@mui/material";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import {
  Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  RegistererState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions,
  Web,
  InvitationRejectOptions,
  LogLevel,
  SessionDescriptionHandlerOptions,
} from "sip.js";
import MDTypography from "components/MDTypography";
import ring from "assets/sound/ringing.wav";
import ringBack from "assets/sound/ringback_tone.mp3";
import variables from "globals/variables";
import MDButton from "components/MDButton";
import loggerFactory from "globals/logger/logger-factory";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import SIPPhoneService from "application/components/SIPPhone/SIPPhoneService";
import {
  useMaterialUIController,
  setCallState,
  setClickToCall,
  setAgentData,
} from "context";
import { application } from "globals/endpoints";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CallIcon from "@mui/icons-material/Call";
import { TryRounded } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function ManualDailingBox({
  setResponseData,
  setCheckSaveForm,
  setUserStatus,
  activePage,
}) {
  const componentName = "SIPPhone";
  const [agentStatus, setAgentStatus] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [registration, setRegistration] = useState("Not Registered");
  const [inCall, setInCall] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [isDialing, setIsDialing] = useState(false);
  const [dialNumber, setDialNumber] = useState("");
  const [consultationCall, setConsultationCall] = useState(null);
  const [callStatus, setCallStatus] = useState("");
  const [dialId, setDialId] = useState();
  const [timer, setTimer] = useState("0:0:0");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [showMergeButton, setShowMergeButton] = useState(false);
  const [showMuteButton, setShowMuteButton] = useState(false);
  const [showHoldButton, setShowHoldButton] = useState(false);
  const [showConferenceButton, setShowConferenceButton] = useState(false);
  const [showBlindTransferButton, setShowBlindTransferButton] = useState(false);
  const [showConsultTransferButton, setShowConsultTransferButton] =
    useState(false);
  const [showManualButton, setShowManualButton] = useState(false);
  const [showAddCallButton, setShowAddCallButton] = useState(false);
  const [showStartCallButton, setShowStartCallButton] = useState(false);
  const [showCrmButton, setShowCrmButton] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [position, setPosition] = useState("");
  const [isLogin, setIsLogin] = useState();
  const [formData, setFormData] = useState();
  const [numberData, setNumberData] = useState("");
  const [callTypeForm, setCallType] = useState("")
  const prevAgentStatusRef = useRef();

  const handleBeforeUnload = (event) => {
    const message = "Are you sure you want to leave?";
    event.returnValue = message;
    return message;
  };

  let sipPhoneButtonData = JSON.parse(
    AuthenticationService.getSipPhoneButton()
  );

  useEffect(() => {
    if (sipPhoneButtonData) {
      setShowMuteButton(sipPhoneButtonData.mute);
      setShowHoldButton(sipPhoneButtonData.hold);
      setShowBlindTransferButton(sipPhoneButtonData.blindTransfer);
      setShowAddCallButton(
        sipPhoneButtonData.thirdPartyConference ||
          sipPhoneButtonData.consultTransfer
      );
      setShowConferenceButton(sipPhoneButtonData.thirdPartyConference);
      setShowConsultTransferButton(sipPhoneButtonData.consultTransfer);
      setShowManualButton(sipPhoneButtonData.manualCall);
      setShowStartCallButton(sipPhoneButtonData.startCall);
      setShowCrmButton(sipPhoneButtonData.crm);
    }
  }, [sipPhoneButtonData]);

  useEffect(() => {
    if (formData) {
      if (!inCall && formData.dispositionUrl !== "") {
        setResponseData(null);
        setAgentData(dispatch, "");
        if (
          agentStatus.status !== "On Break" &&
          agentStatus.status !== "Available (On Demand) for manual" &&
          agentStatus.status !== "Available for manual"
        ) {
          application.post("/agentStatus/create", { status: "Start Call" });
        }
      }
    }
  }, [inCall, formData, agentStatus]);


  useEffect(() => {
    if (inCall) {
      bye();
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activePage]);

  const handleKeyPress = (event) => {
    setDialNumber(event.target.value);
  };

  let talkStart = null;
  let timerThread = null;

  const [controller, dispatch] = useMaterialUIController();
  const { callState, darkMode, clickToCall } = controller;

  const updateCallState = (callInfo) => {
    loggerFactory.debug(componentName, "Dispatching call state", callInfo);
    setCallState(dispatch, callInfo);
  };

  const audioRinging = new Audio(ring);
  audioRinging.loop = true;

  const ringBackTone = new Audio(ringBack);
  ringBackTone.loop = true;

  function callIsRinging(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.play();
    callInfo.isRinging = true;
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsDialing(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    callInfo.isDialing = true;
    callInfo.dialNumber = "";
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsConnected(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    startTimer();

    audioRinging.pause();
    callInfo.inCall = true;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = callStatus;
    callInfo.dialNumber = "";

    updateCallState(callInfo);
  }

  function callIsTerminated() {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };
    audioRinging.pause();

    callInfo.inCall = false;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = "";
    callInfo.dialNumber = "";

    stopTimer();
    updateCallState(callInfo);
  }

  function openForIncomingCall(invitation) {
    const messageFrom = invitation.incomingInviteRequest.message.from;
    callIsRinging("Incoming Call-" + messageFrom.uri.normal.user);
    SIPPhoneService.inviteSession = invitation;

    invitation.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Initial:
          callIsRinging();
          break;
        case SessionState.Establishing:
          callIsRinging();
          break;
        case SessionState.Established:
          const messageTalk =
            SIPPhoneService.inviteSession.incomingInviteRequest.message.from;
            setCallType("");
            setNumberData(messageTalk.uri.normal.user);
          callIsConnected("Talking : " + messageTalk.uri.normal.user);

          const remoteAudioStream = new MediaStream();
          const remoteVideoStream = new MediaStream();
          const localVideoStream = new MediaStream();

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getSenders()
            .forEach((sender) => {
              if (sender.track) {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                }
              }
            });

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getReceivers()
            .forEach((receiver) => {
              if (receiver.track) {
                if (receiver.track.kind === "audio") {
                  remoteAudioStream.addTrack(receiver.track);
                }
                if (receiver.track.kind === "video") {
                  remoteVideoStream.addTrack(receiver.track);
                }
              }
            });
          SIPPhoneService.remoteAudioStream = remoteAudioStream;

          SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.play();

          SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
          SIPPhoneService.remoteVideoMedia.play();
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          callIsTerminated();
          SIPPhoneService.remoteAudioMedia.srcObject = null;
          SIPPhoneService.remoteAudioMedia.pause();

          SIPPhoneService.remoteVideoMedia.srcObject = null;
          SIPPhoneService.remoteVideoMedia.pause();

          SIPPhoneService.localVideoMedia.srcObject = null;
          SIPPhoneService.localVideoMedia.pause();

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  }

  function updateTimer() {
    const total = Date.parse(new Date()) - Date.parse(talkStart);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setTimer(`${hours}:${minutes}:${seconds}`);
  }

  function startTimer() {
    talkStart = new Date();
    timerThread = setInterval(() => {
      updateTimer();
    }, 1000);
  }

  function stopTimer() {
    talkStart = null;

    if (timerThread) {
      clearInterval(timerThread);
      timerThread = null;
    }
  }

  function answerCall(asVideoCall) {
    SIPPhoneService.inviteSession.accept({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: asVideoCall,
        },
      },
    });
    if (dialId){
      application
        .post(`leadUpload/update/onAnswer/${dialId}`)
        .then((response) => {
          loggerFactory.info(
            componentName,
            "lead & call status update : ",
            response
          );
        })
        .catch((error) => {
          loggerFactory.error(
            componentName,
            "failed to update lead & call status",
            error
          );
        });
      }
  }

  function updateRegistrationStatus(status) {
    setRegistration(status);
  }

  function bye() {
    if (consultationCall) {
      if(SIPPhoneService.consultCallRinging) {
      consultationCall.cancel();
      } else {
      consultationCall.bye();
      }
    } else if (SIPPhoneService.inviteSession) {
      SIPPhoneService.inviteSession.bye();
    }
  }

  function cancel() {
    SIPPhoneService.inviteSession.cancel();
  }

  function rejectCall() {
    SIPPhoneService.inviteSession.reject({
      statusCode: 486,
    });
  }

  function mute() {
    console.log(SIPPhoneService.currentSessionAudio);
    SIPPhoneService.currentSessionAudio.track.enabled = false;
    setIsMute(true);
  }

  function unMute() {
    console.log(SIPPhoneService.currentSessionAudio);
    SIPPhoneService.currentSessionAudio.track.enabled = true;
    setIsMute(false);
  }

  function hold() {
    const options = {
      sessionDescriptionHandlerModifiers: [Web.holdModifier],
    };
    SIPPhoneService.inviteSession.invite(options);
    setIsOnHold(true);
  }

  function unHold() {
    const options = {
      sessionDescriptionHandlerModifiers: [],
    };
    SIPPhoneService.inviteSession.invite(options);
    setIsOnHold(false);
  }

  function blindTransfer() {
    if (dialNumber != "") {
      const target = UserAgent.makeURI(
        "sip:" + dialNumber + "@" + variables.sip.domain
      );
      SIPPhoneService.inviteSession.refer(target);
      setDialNumber("");
    } else {
      handleSnackbarOpen(
        "right",
        "warning",
        "Please dial a number for blind transfer."
      );
    }
  }

  function addNewCall() {
    if (dialNumber != "") {
      if (SIPPhoneService.inviteSession) {
        const target = UserAgent.makeURI(
          `sip:${dialNumber}@${variables.sip.domain}`
        );
        const consultationCall = new Inviter(SIPPhoneService.userAgent, target);
        callIsDialing("Talking : " + dialNumber);
        setDialNumber("");
        hold();
        consultationCall.invite({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: false,
            },
          },
        });
        consultationCall.stateChange.addListener((newState) => {
           switch (newState) {
             case SessionState.Establishing: {
                SIPPhoneService.consultCallRinging = true;
               ringBackTone.play();
           }
           break;

             case SessionState.Established:
              SIPPhoneService.consultCallRinging = false;
               ringBackTone.pause();
               const remoteAudioStream = new MediaStream();

               consultationCall.sessionDescriptionHandler.peerConnection
                 .getReceivers()
                 .forEach((receiver) => {
                   if (receiver.track) {
                     if (receiver.track.kind === "audio") {
                       remoteAudioStream.addTrack(receiver.track);
                     }
                   }
                 });
               SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
               SIPPhoneService.remoteAudioMedia.play();
               break;

             case SessionState.Terminated: {
              SIPPhoneService.consultCallRinging = false;
                             ringBackTone.pause();

               SIPPhoneService.remoteAudioMedia.srcObject =
                 SIPPhoneService.remoteAudioStream;
               SIPPhoneService.remoteAudioMedia.play();
               unHold();
               setShowMergeButton(false);
               setConsultationCall(null);
               callIsDialing(
                 "Talking : " +
                   SIPPhoneService.inviteSession.incomingInviteRequest.message
                     .from.uri.normal.user
               );
               break;
             }
           }
        });
        setConsultationCall(consultationCall);
        setShowMergeButton(true);
      }
    } else {
      handleSnackbarOpen(
        "right",
        "warning",
        "Please dial a number for consult transfer."
      );
    }
  }

  function consultationCallTransfer() {
    if (SIPPhoneService.inviteSession && consultationCall) {
      const localSession = SIPPhoneService.inviteSession;
      const remoteSession = consultationCall;
      if (localSession && remoteSession) {
        localSession.refer(remoteSession);
        unHold();
        setDialNumber("");
        setConsultationCall(null);
        setShowMergeButton(false);
      } else {
        console.error(
          "Error in consultationCallsMerge: Local session or remote session is undefined"
        );
      }
    } else {
      console.error(
        "Error in consultationCallsMerge: SIPPhoneService.inviteSession or consultationCall is undefined"
      );
    }
  }
  function thirdPartyConferenceCall() {
    if (SIPPhoneService.inviteSession && consultationCall) {
      if (consultationCall) {
        consultationCall.info({
          requestOptions: {
            body: {
              contentDisposition: "render",
              contentType: "application/dtmf-relay",
              content: "Signal=5\r\nDuration=1000",
            },
          },
        });
      }else{
         console.error(
           "Error in thirdPartyConferenceCall: Local session or remote session is undefined"
         );
      }
      setConsultationCall(null);
      callIsDialing("Conference Call");
    }
    setShowMergeButton(false);
    
  }

  function keyClicked(key) {
    // if (inCall) {
    //   const options = {
    //     requestOptions: {
    //       body: {
    //         contentDisposition: "render",
    //         contentType: "application/dtmf-relay",
    //         content: `Signal=${key}\r\nDuration=1000`,
    //       },
    //     },
    //   };
    //   SIPPhoneService.inviteSession.info(options);
    // } else {
    setDialNumber(dialNumber + key);
    // }
  }

  function dial(videoCall, forceDial) {
   
    let toDial = forceDial ? forceDial : dialNumber;
    if (toDial) {
      setNumberData(dialNumber);
      setCallType("dial");
      const body = {
        phoneNumber: dialNumber,
        callType: "dial",
      };
      application
        .post("/leadUpload/update", body)
        .then((response) => {
          setResponseData(response.data.data);
          setDialId(response.data.data.dialId);
          setAgentData(dispatch, response.data.data);
          loggerFactory.debug(
            componentName,
            "Agent From Data : ",
            response.data.data
          );
        })
        .catch((error) => {
          loggerFactory.debug(
            componentName,
            " unable to get agent from data : ",
            error
          );
        });
      if (AuthenticationService.getDeskPhoneStatus()) {
        application.post("/externalApi/call", {
          caller: AuthenticationService.getExtensionNumber(),
          callee: dialNumber,
        });
        SIPPhoneService.lastNumberDialed = toDial;
        setDialNumber("");
      } else {
        SIPPhoneService.lastNumberDialed = toDial;

        const number = "sip:" + toDial + "@" + variables.sip.domain;
        const target = UserAgent.makeURI(number);
        setDialNumber("");
        if (clickToCall) {
          setClickToCall(dispatch, "");
        }
        SIPPhoneService.inviteSession = new Inviter(
          SIPPhoneService.userAgent,
          target
        );
        SIPPhoneService.inviteSession.invite({
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: videoCall,
            },
          },
        });
        SIPPhoneService.inviteSession.stateChange.addListener((newState) => {
          switch (newState) {
            case SessionState.Establishing:
              ringBackTone.play();
              if (
                agentStatus.status == "Available (On Demand) for manual" ||
                agentStatus.status == "Available for manual"
              ){
                 application.post("agent/updateDialingStatus", {
                   dialingStatus: "Ring",
                 });
              }
                if (toDial === SIPPhoneService.swipeCode) {
                  callIsDialing("Call Swipe Initiated");
                } else {
                  callIsDialing("Dialing-" + toDial);
                }
              break;

            case SessionState.Established:
              ringBackTone.pause();
               if (
                 agentStatus.status == "Available (On Demand) for manual" ||
                 agentStatus.status == "Available for manual"
               ) {
                 application.post("agent/updateDialingStatus", {
                   dialingStatus: "Talk",
                 });
               }
              if (toDial === SIPPhoneService.swipeCode) {
                callIsConnected("Call Swiped");
              } else {
                callIsConnected(`Talking : ${toDial}`);
              }
              const remoteAudioStream = new MediaStream();
              const remoteVideoStream = new MediaStream();
              const localVideoStream = new MediaStream();

              SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
                .getSenders()
                .forEach((sender) => {
                  if (sender.track.kind === "audio") {
                    SIPPhoneService.currentSessionAudio = sender;
                  }
                  if (sender.track.kind === "video") {
                    SIPPhoneService.currentSessionVideo = sender;
                    localVideoStream.addTrack(sender.track);
                  }

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                });

              SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
                .getReceivers()
                .forEach((receiver) => {
                  if (receiver.track) {
                    if (receiver.track.kind === "audio") {
                      remoteAudioStream.addTrack(receiver.track);
                    }
                    if (receiver.track.kind === "video") {
                      remoteVideoStream.addTrack(receiver.track);
                    }
                  }
                });

              SIPPhoneService.remoteAudioStream = remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
              SIPPhoneService.remoteAudioMedia.play();

              SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
              SIPPhoneService.remoteVideoMedia.play();
              break;
            case SessionState.Terminated:
              ringBackTone.pause();
              callIsTerminated();
               if (
                 agentStatus.status == "Available (On Demand) for manual" ||
                 agentStatus.status == "Available for manual"
               ) {
                 application.post("agent/updateDialingStatus", {
                   dialingStatus: "",
                 });
               }

              SIPPhoneService.remoteAudioMedia.srcObject = null;
              SIPPhoneService.remoteAudioMedia.pause();

              SIPPhoneService.remoteVideoMedia.srcObject = null;
              SIPPhoneService.remoteVideoMedia.pause();

              SIPPhoneService.localVideoMedia.srcObject = null;
              SIPPhoneService.localVideoMedia.pause();

              break;
            default:
              break;
          }
        });
      }
    } else {
      if (SIPPhoneService.lastNumberDialed) {
        setDialNumber(SIPPhoneService.lastNumberDialed);
      }
    }
  }

  function onInvite(invitation) {
    console.log("invitation -", invitation);
    console.log("session -", SIPPhoneService.inviteSession);
    if (inCall) {
      rejectCall();
    } else {
      const body = {
        phoneNumber:
          invitation.incomingInviteRequest.message.from.uri.normal.user,
        callType: "",
      };
      application
        .post("/leadUpload/update", body)
        .then((response) => {
          openForIncomingCall(invitation);
          setResponseData(response.data.data);
          setFormData(response.data.data);
          setDialId(response.data.data.dialId);
          setAgentData(dispatch, response.data.data);
          loggerFactory.debug(
            componentName,
            "Agent From Data : ",
            response.data.data
          );
        })
        .catch((error) => {
          loggerFactory.debug(
            componentName,
            " unable to get agent from data : ",
            error
          );
        });
    }
  }

  useEffect(() => {
    if (!SIPPhoneService.callbacks.invite) {
      SIPPhoneService.callbacks.invite = onInvite;
      SIPPhoneService.callbacks.registration = updateRegistrationStatus;
      SIPPhoneService.remoteAudioMedia = document.getElementById("remoteAudio");
      SIPPhoneService.remoteVideoMedia = document.getElementById("remoteVideo");
      SIPPhoneService.localVideoMedia = document.getElementById("localVideo");
    }
    setRegistration(SIPPhoneService.registeredState);
    loggerFactory.debug(componentName, "State Update", callState);
    if (callState) {
      setCallStatus(callState.callStatus);
      setDialNumber(callState.dialNumber);
      setInCall(callState.inCall);
      setIsDialing(callState.isDialing);
      setIsMute(callState.isMute);
      setIsOnHold(callState.isOnHold);
      setIsRinging(callState.isRinging);
      setCheckSaveForm(!callState.inCall && !callState.isRinging);
    }
  }, [callState]);

  useEffect(() => {
    setDialNumber(clickToCall);
    dial(false, clickToCall);
    loggerFactory.debug(componentName, "Event clickToCall ", clickToCall);
  }, [clickToCall]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (newStatus) => {
    setActiveButton("Pause");
    setDialNumber("");
    const payload = { status: newStatus };
    application
      .post("/agentStatus/create", payload)
      .then((response) => {
        loggerFactory.debug(
          componentName,
          "Agent Status update",
          response.data.messageDetail
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    handleClose();
  };

  const handleClickAgent = (name) => {
    setActiveButton(name);
    const payload = { status: name };
    application
      .post("/agentStatus/create", payload)
      .then((response) => {
        loggerFactory.debug(
          componentName,
          "Agent Status update",
          response.data.messageDetail
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    if (name === "Manual Dial") {
      setButtonDisabled(false);
    } else if (name === "Start Call") {
      if (showManualButton) {
        setButtonDisabled(false);
      }
    } else if (name === "Off-Hook") {
      setButtonDisabled(false);
    }
  };

  const getOptionList = () => {
    application
      .post("/userStatusMaster/listAll")
      .then((response) => {
        setOptionList(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getContactList = () => {
    application
      .post("/extensions/listAllAgent")
      .then((response) => {
        setContactList(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getAgentStatus = () => {
    const fullName = AuthenticationService.getUserName();
    application
      .post(`/agent/${fullName}`)
      .then((response) => {
        const data = response.data.data;
        if (data.status == "Logged In") {
          setIsLogin(data.status);
        }
        // if (
        //   data.status == "Logged In" ||
        //   data.status == "Logged Out" ||
        //   data.status == "On Break"
        // ) {
        //   setButtonDisabled(true);
        // } else {
        //   setButtonDisabled(false);
        // }
        if (data.status == "Ring" && isRinging) {
          setAgentStatus(data);
        } else {
          setAgentStatus(data);
        }
        setUserStatus(data.status);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getAgentStatus();
  }, [isRinging]);

  useEffect(() => {
    if (prevAgentStatusRef.current !== agentStatus.status) {
      handleSnackbarOpen(
        "center",
        "success",
        `Agent status is ${agentStatus.status}`
      );
    }
    if (
      agentStatus.status == "Available" ||
      agentStatus.status == "Available (On Demand)"
    ) {
      setActiveButton("Start Call");
    } else if (agentStatus.status == "On Break") {
      setActiveButton("Pause");
    } else if (agentStatus.status == "Off-Hook") {
      setActiveButton("Off-Hook");
    } else if (
      agentStatus.status == "Available for manual" ||
      agentStatus.status == "Available (On Demand) for manual"
    ) {
      setActiveButton("Manual Dial");
      setButtonDisabled(false);
    } else {
      setActiveButton(null);
    }
    prevAgentStatusRef.current = agentStatus.status;
  }, [agentStatus]);

  useEffect(() => {
    getOptionList();
    getAgentStatus();
    const intervalId = setInterval(() => {
      getAgentStatus();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClickIcon = (e, buttonName) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      showManualButton &&
      !inCall &&
      !isDialing &&
      !isRinging &&
      agentStatus.status != "Available for manual" &&
      agentStatus.status != "Available (On Demand) for manual"
    ) {
      if (dialNumber == "" && agentStatus.status == "Off-Hook") {
        const payload = { status: "Start Call" };
        application
          .post("/agentStatus/create", payload)
          .then((response) => {
            loggerFactory.debug(
              componentName,
              "Agent Status update",
              response.data.messageDetail
            );
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        if (agentStatus.status) {
          const payload = { status: "Off-Hook" };
          application
            .post("/agentStatus/create", payload)
            .then((response) => {
              loggerFactory.debug(
                componentName,
                "Agent Status update",
                response.data.messageDetail
              );
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  }, [dialNumber]);

  const [contactOpen, setContactOpen] = useState(null);

  const handleIconClick = (event) => {
    getContactList();
    setContactOpen(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    setDialNumber(value);
    setContactOpen(null);
  };

  const handleSelectContact = () => {
    setContactOpen(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (position, color, message) => {
    setSnackbarOpen(true);
    setPosition(position);
    setColor(color);
    setMessage(message);
  };

  function handleLoadForm() {
        const body = {
          phoneNumber: numberData,
          callType: callTypeForm,
        };
        application
          .post("/leadUpload/update", body)
          .then((response) => {
            setResponseData(response.data.data);
            setFormData(response.data.data);
            setDialId(response.data.data.dialId);
            setAgentData(dispatch, response.data.data);
            loggerFactory.debug(
              componentName,
              "Agent From Data : ",
              response.data.data
            );
          })
          .catch((error) => {
            loggerFactory.debug(
              componentName,
              " unable to get agent from data : ",
              error
            );
          });  
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: position }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert variant="filled" severity={color}>
          {message}
        </MuiAlert>
      </Snackbar>
      <MDBox p={1}>
        {showCrmButton && (  <MDButton
          fullWidth
          color="info"
          style={{ marginBottom: "5px" }}
          onClick={() => handleLoadForm()}
        >
          CRM Form
        </MDButton>)}
        <Grid container spacing={1} alignItems="center" justify="space-between">
          <Grid item>
            <MDBox m={1}>
              <Avatar />
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox style={{ display: "flex", alignItems: "center" }}>
              <MDTypography
                textTransform="capitalize"
                variant="h6"
                color="info"
              >
                {AuthenticationService.getFullName()} &nbsp;&nbsp;
              </MDTypography>
            </MDBox>
          </Grid>
          <Tooltip title="Phone Registration Status">
            <MDButton
              style={{
                borderRadius: "50%",
                marginLeft: "auto",
              }}
              color={registration === "Registered" ? "success" : "error"}
            >
              {AuthenticationService.getExtensionNumber()}
            </MDButton>
          </Tooltip>
        </Grid>
        <MDTypography
          style={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          <strong> Agent Status </strong>:&nbsp;{" "}
          <span style={{ color: `${darkMode ? "yellow" : "black"}` }}>
            {agentStatus.status}
          </span>
        </MDTypography>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {optionList.map((item) => (
            <MenuItem onClick={() => handleStatusChange(item.description)}>
              {item.description}
            </MenuItem>
          ))}
        </Menu>
        <Divider />
        <MDTypography
          style={{
            textAlign: "center",
            minHeight: "30px",
          }}
          variant="h6"
        >
          {callStatus}
        </MDTypography>
        <MDBox id="video-block" style={{ width: "auto", display: "none" }}>
          <video id="remoteVideo" style={{ width: "50%" }}></video>
          <video id="localVideo" style={{ width: "50%" }}></video>
        </MDBox>
        <MDTypography
          variant="h6"
          style={{
            textAlign: "center",
            marginBottom: "5px",
            minHeight: "30px",
          }}
        >
          {inCall && <span>Duration: {timer}</span>}
        </MDTypography>
        <audio id="remoteAudio">
          <p>Your browser doesn't support HTML5 audio.</p>
        </audio>
        {contactOpen ? (
          <Select
            fullWidth
            open={contactOpen}
            onClose={handleSelectContact}
            onChange={(e) => handleMenuItemClick(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            style={{ height: 50 }}
          >
            {contactList &&
              contactList.map((item) => (
                <MenuItem key={item.extension} value={item.extension}>
                  {item.name == null ? item.extension : item.name}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <MDInput
            fullWidth
            style={{ marginBottom: "20px" }}
            value={dialNumber}
            onChange={handleKeyPress}
            InputProps={{
              readOnly: isButtonDisabled,
              endAdornment: (
                <InputAdornment position="end">
                  {/* {!isButtonDisabled ? (
                    <Tooltip title="Contact List">
                      <IconButton color="text" onClick={handleIconClick}>
                        <AccountBoxIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null} */}
                </InputAdornment>
              ),
            }}
          ></MDInput>
        )}
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="1"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("1")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              1
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="2"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("2")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              2
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="3"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("3")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              3
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="4"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("4")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              4
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="5"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("5")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              5
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="6"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("6")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              6
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="7"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("7")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              7
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="8"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("8")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              8
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="9"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("9")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              9
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="*"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("*")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              *
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="0"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("0")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              0
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton
              id="#"
              color={darkMode ? "dark" : "light"}
              onClick={() => keyClicked("#")}
              disabled={isButtonDisabled}
              style={{ fontSize: "large", padding: "0px" }}
            >
              #
            </MDButton>
          </Grid>
        </Grid>
        <MDBox
          style={{
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          <ButtonGroup>
            {isRinging && (
              <Tooltip title="Answer Call">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(false)}
                >
                  <Icon color="text">phone</Icon>
                </MDButton>
              </Tooltip>
            )}
            {/* {isRinging && !inCall && (
              <Tooltip title="Reject Call">
                <MDButton
                  color="error"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => {
                    rejectCall();
                  }}
                >
                  <Icon color="text">call_end</Icon>
                </MDButton>
              </Tooltip>
            )} */}

            {!inCall && !isRinging && !isDialing && (
              <Tooltip title="Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => dial(false)}
                >
                  <Icon color="success" fontSize="large">
                    phone
                  </Icon>
                </MDButton>
              </Tooltip>
            )}

            {isDialing && !inCall && (
              <Tooltip title="Cancel Call">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => cancel()}
                >
                  <Icon color="error">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && (
              <Tooltip title="Disconnect">
                <MDButton
                  color="error"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => bye()}
                >
                  <Icon color="text">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && showMuteButton && !isMute && (
              <Tooltip title="Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => mute()}
                >
                  <Icon color="error">mic_off</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && showMuteButton && isMute && (
              <Tooltip title="Un Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unMute()}
                >
                  <Icon color="error">mic</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && showHoldButton && !isOnHold && (
              <Tooltip title="Hold">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => hold()}
                >
                  <Icon color="warning">phone_paused</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && showHoldButton && isOnHold && (
              <Tooltip title="Un Hold">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unHold()}
                >
                  <Icon color="success">phone_in_talk</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && showAddCallButton && !showMergeButton && (
              <Tooltip title="Add">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => addNewCall()}
                >
                  <Icon color="success">person_add_icon</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && showConsultTransferButton && showMergeButton && (
              <Tooltip title={"Consultation Transfer"}>
                <MDButton
                  color="success"
                  variant="contained"
                  id="merge-call"
                  size="medium"
                  onClick={() => consultationCallTransfer()}
                >
                  <Icon color="info">"send_to_mobile_icon"</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && showConferenceButton && showMergeButton && (
              <Tooltip title="Third Party Conference">
                <MDButton
                  color="success"
                  variant="contained"
                  id="merge-call"
                  size="medium"
                  onClick={() => thirdPartyConferenceCall()}
                >
                  <Icon color="info">merge_icon</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && showBlindTransferButton && (
              <Tooltip title="Blind Transfer">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => blindTransfer()}
                >
                  <Icon color="success">forward_icon</Icon>
                </MDButton>
              </Tooltip>
            )}

            {dialNumber.length > 0 && (
              <Tooltip title="clear">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => setDialNumber("")}
                >
                  <Icon size="large" color="warning">
                    clear
                  </Icon>
                </MDButton>
              </Tooltip>
            )}
          </ButtonGroup>
        </MDBox>
        <Divider />
        <MDBox
          style={{
            textAlign: "center",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonGroup variant="contained">
            {showStartCallButton && (
              <Button
                style={{
                  color: "white",
                  backgroundColor:
                    activeButton === "Start Call" ? "#23a12b" : "gray",
                }}
                fullWidth
                size="small"
                name="Start Call"
                onClick={(e) => handleClickAgent(e.target.name)}
              >
                <Icon onClick={(e) => handleClickIcon(e, "Start Call")}>
                  play_circle_outline_icon
                </Icon>{" "}
                &nbsp; Start Call
              </Button>
            )}
            <Button
              style={{
                color: "white",
                backgroundColor: activeButton === "Pause" ? "#23a12b" : "gray",
              }}
              fullWidth
              size="small"
              name="Pause"
              onClick={handleClick}
            >
              <Icon onClick={(e) => handleClickIcon(e, "Pause")}>
                paused_icon
              </Icon>{" "}
              &nbsp; Break / Pause
            </Button>
          </ButtonGroup>
        </MDBox>
        <MDBox
          style={{
            textAlign: "center",
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonGroup variant="contained">
            {showManualButton && (
              <Button
                style={{
                  color: "white",
                  height: "45px",
                  backgroundColor:
                    activeButton === "Manual Dial" ? "#23a12b" : "gray",
                }}
                fullWidth
                onClick={(e) => handleClickAgent(e.target.name)}
                size="small"
                name="Manual Dial"
              >
                <Icon onClick={(e) => handleClickIcon(e, "Manual Dial")}>
                  dialpad_icon
                </Icon>{" "}
                &nbsp; Manual Dial
              </Button>
            )}
            {/* <Button
              style={{
                color: "white",
                backgroundColor: activeButton === "Preview" ? "#23a12b" : "gray",
              }}
              fullWidth
              size="small"
              name="Preview"
              onClick={(e) => handleClickAgent(e.target.name)}
            >
              <Icon onClick={(e) => handleClickIcon(e, "Preview")}>
                preview_icon
              </Icon>{" "}
              &nbsp; Preview Dial
            </Button> */}
          </ButtonGroup>
        </MDBox>
      </MDBox>
    </>
  );
}
