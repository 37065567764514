import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { userDefinedWidgetMenus } from "globals/configuration/nmsDashboard";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: userDefinedWidgetMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "barChart/list",
  update: "barChart/update",
  create: "barChart/create",
  delete: "barChart/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "API URL",
      accessor: "apiURL",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required")
    },
    {
      Header: "Chart Type",
      accessor: "chartType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required")
    },
    {
      Header: "Legend Field",
      accessor: "legendField",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required")
    },
    {
      Header: "Legend Field Data Type",
      accessor: "legendFieldDataType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "dataType",
        keyDescription: "dataType",
        url: "widget/dataTypeList",
      },
    },
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required")
    },
    {
      Header: "Refresh Interval",
      accessor: "refreshInterval",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Value Field",
      accessor: "valueField",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required")
    },
    {
      Header: "value Field Data Type",
      accessor: "valueFieldDataType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "dataType",
        keyDescription: "dataType",
        url: "widget/dataTypeList",
      },
    },
    {
      Header: "Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      groupHeading: "Category Service",
      options: {
        keyField: "categoryCode",
        keyDescription: "categoryName",
        values: [],
        url: "categoryService/dropdownCategoryCode/list",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
