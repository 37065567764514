import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";
import {
  getColumns,
  Attributes,
  operationURLS,
} from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";

function HostNameConfiguration({ onLoad, pageName }) {
  const componentName = "default.host-name.configuration";
  const [formData, setFormData] = useState(null);
  const [updateList, setUpdateList] = useState(Date.now());
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    application
      .post(operationURLS.list)
      .then((response) => {
        let dataToDisplay = response.data.data[0];
        loggerFactory.debug(componentName, "Data Received From PRI File", dataToDisplay);
        setFormData(dataToDisplay);
      })
      .catch((error) => {
        setFormData([]);
      });
  }, []);

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          openSuccessSB();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    } 
    setOpenForm(false);
  }


  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Nodes"
        columns={getColumns(null, null)}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default HostNameConfiguration;
