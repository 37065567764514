export const DROPDOWN_OPTIONS = {
  piechart: "Piechart",
  doughnutchart: "Doughnutchart",
  barchart: "Barchart",
  linechart: "Linechart",
  exportToCSV: "Export to CSV",
  exportToPDF: "Export to PDF",
  delete: "Delete",
};

export const PIECHART_DROPDOWN_LIST = [
  DROPDOWN_OPTIONS.doughnutchart,
  DROPDOWN_OPTIONS.barchart,
  DROPDOWN_OPTIONS.linechart,
  DROPDOWN_OPTIONS.delete,
];

export const DOUGHNUTCHART_DROPDOWN_LIST = [
  DROPDOWN_OPTIONS.piechart,
  DROPDOWN_OPTIONS.barchart,
  DROPDOWN_OPTIONS.linechart,
  DROPDOWN_OPTIONS.delete,
];

export const BARCHART_DROPDOWN_LIST = [
  DROPDOWN_OPTIONS.piechart,
  DROPDOWN_OPTIONS.doughnutchart,
  DROPDOWN_OPTIONS.linechart,
  DROPDOWN_OPTIONS.delete,
];

export const LINECHART_DROPDOWN_LIST = [
  DROPDOWN_OPTIONS.piechart,
  DROPDOWN_OPTIONS.doughnutchart,
  DROPDOWN_OPTIONS.barchart,
  DROPDOWN_OPTIONS.delete,
];

export const CALENDAR_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const GRAPH_TOPOLOGY_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const LOCATION_GRAPH_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const NETWORK_TOPOLOGY_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const LOCATION_TREE_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const COUNTER_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const SMART_TOPOLOGY_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const IFRAME_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const VIDEOSTREAM_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const SPEEDOMETER_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const TIMESERIES_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const MULTISERIES_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const CUSTOM_DROPDOWN_LIST = [DROPDOWN_OPTIONS.delete];

export const TABLE_DROPDOWN_LIST = [
  DROPDOWN_OPTIONS.exportToCSV,
  DROPDOWN_OPTIONS.exportToPDF,
  DROPDOWN_OPTIONS.delete,
];
