import CTLForwardFax from "application/components/CTLForwordFax";
import UpdateAsRead from "application/components/UpdateAsRead";
import CTLFaxFileDownload from "application/components/CTLFaxFileDownload";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import SendFaxAction from "application/components/SendFaxAction";



const Attributes = {
  enableAddButton: false,
  enableAdvanceSearch: true,
  enableMultipleDeleteButton: false,
  enableMarkAsReadButton: false,
  enableUploadFaxButton: true,
  modes: [],
};

const operationURLS = {
  list: "/fax/list",
  delete: "fax/delete",
  update: "fax/update"
};

const getColumns = (markAsRead, onDelete, onEdit) => {
  return [
    {
      Header: "Fax Received On",
      accessor: "recordDate",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    // {
    //   Header: "Initially Sent By",
    //   accessor: "sourceName",
    //   displayInList: true,
    //   allowFilter: false,
    //   allowInForm: false,
    //   dataType: "string",
    //   Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    // },
    {
      Header: "Fax Transferred By",
      //accessor: "transferBy",sourceName
      accessor: "sourceName",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Fax Transferred On",
      accessor: "transferredOn",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Download File",
      accessor: "filename",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      allowInForm: false,
      Cell: ({ row, value }) =>
        <CTLFaxFileDownload row={row} value={value} />,
    },
    {
      Header: "Fax Forward",
      accessor: "sendfax",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => <CTLForwardFax value={value} row={row} />,
    },
    // {
    //   Header: "Send Fax",
    //   accessor: "id",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ value, row }) => <SendFaxAction
    //     row={row}
    //     value={value}
    //     onEdit={onEdit}
    //   />
    // },
    {
      Header: "Action",
      accessor: "rowid",
      displayInList: true,
      Cell: ({ row, value }) => (
        <UpdateAsRead
          row={row}
          value={value}
          markAsRead={markAsRead}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
