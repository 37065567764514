import { useState } from "react";
import styles from "../css/Topology.module.css";
import PopupLocationAssets from "../PopupLocationAssets";
import ContextMenu from "../ContextMenu";

function Node({
  isDarkMode,
  nodeDatum,
  descendantsData,
  placeholder,
  identifier,
  color,
  handleTreeDataConfiguration,
  categoryData,
  severityData,
  isEditMode,
  isAdmin,
}) {
  const [popupLocationAssetsOpen, setPopupLocationAssetsOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
    event.stopPropagation();
  };

  const handleClose = () => setContextMenu(null);

  const handlePopupLocationAssetsOpen = () => setPopupLocationAssetsOpen(true);

  const handleNodeClick = () => {
    if (nodeDatum.children.length === 0 && nodeDatum.isSubtreeOpen) {
      handlePopupLocationAssetsOpen();
      return;
    }

    handleTreeDataConfiguration(nodeDatum.id);
  };

  const handlePopupLocationAssetsClose = () => {
    setPopupLocationAssetsOpen(false);
    setContextMenu(null);
  };

  return (
    <>
      <g
        className={styles.icon}
        onClick={handleNodeClick}
        onContextMenu={handleContextMenu}
      >
        <circle
          fill={isDarkMode ? "#202940" : "white"}
          strokeWidth={0}
          r={nodeDatum.showHighlighted ? "37" : "30"}
        />
        {!nodeDatum.hideSummary && (
          <g>
            <circle
              strokeWidth="0"
              r="10"
              fill="#5bed37"
              transform={
                nodeDatum.showHighlighted
                  ? "translate(-60,-60)"
                  : "translate(-60,-50)"
              }
            />
            <text
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform={
                nodeDatum.showHighlighted
                  ? "translate(-45,-48)"
                  : "translate(-45,-38)"
              }
              fontSize={37}
            >
              {descendantsData[nodeDatum.id].active}
            </text>
            <circle
              strokeWidth="0"
              r="10"
              fill="#ed4242"
              transform={
                nodeDatum.showHighlighted
                  ? "translate(50,-60)"
                  : "translate(50,-50)"
              }
            />
            <text
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform={
                nodeDatum.showHighlighted
                  ? "translate(65,-48)"
                  : "translate(65,-38)"
              }
              fontSize={37}
            >
              {descendantsData[nodeDatum.id].inactive}
            </text>
          </g>
        )}
        <g id={placeholder + identifier}>
          <circle fill="transparent" strokeWidth={0} r="60" />
          {nodeDatum.showHighlighted && (
            <circle stroke="tomato" strokeWidth="2" fill="transparent" r="37" />
          )}
          {!nodeDatum.isSubtreeOpen && nodeDatum.subtree.length > 0 && (
            <circle stroke="aqua" strokeWidth="1.8" fill="transparent" r="28" />
          )}
          <circle stroke="black" strokeWidth="1.5" fill={color} r="18" />
        </g>
        <g>
          <text
            fill={isDarkMode ? "white" : "black"}
            strokeWidth=".2"
            transform={
              nodeDatum.showHighlighted
                ? "translate(-45,70)"
                : "translate(-45,60)"
            }
            fontSize={35}
          >
            {nodeDatum.name}
          </text>
        </g>
      </g>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        handlePopupLocationAssetsOpen={handlePopupLocationAssetsOpen}
      />
      <PopupLocationAssets
        popupLocationAssetsOpen={popupLocationAssetsOpen}
        handlePopupLocationAssetsClose={handlePopupLocationAssetsClose}
        location={nodeDatum.name}
        descendantsData={descendantsData}
        categoryData={categoryData}
        severityData={severityData}
        isEditMode={isEditMode}
        isAdmin={isAdmin}
      />
    </>
  );
}

export default Node;
