import { v4 as uuidV4 } from "uuid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { telemetry } from "globals/endpoints";

function ContextMenu({
  contextMenuData,
  contextMenu,
  handleContextMenuClose,
  assetId,
  ipAddress,
  handlePopupOpen,
  assetName,
}) {
  const handleOptionSelected = (option) => {
    if (option === "dashboard") {
      window.assetId = assetId;
      window.assetName = assetName;
      window.imageName = contextMenuData.imageName;
      // window.imageName = "https://www.researchgate.net/publication/329809351/figure/fig1/AS:705916676632584@1545315059862/Autonomous-terrestrial-rover-platform-84-cm-436-cm-35-cm-equipped-with.png";
      window.imageWidth = contextMenuData.width;
      // window.imageWidth = 1110;
      window.imageHeight = contextMenuData.height;
      // window.imageHeight = 718.5;
      handlePopupOpen();
    } else alert(`You have clicked option ${option}`);
    handleContextMenuClose();
  };

  const baseURL = "https://telemetry.coraltele.com";

  const handleAction = async (apiURL, key = "") => {
    const dollar = "$";
    let URL = null;
    URL = apiURL.replace(`${dollar}{{baseURL}}`, baseURL);
    URL = URL.replace("{id}", assetId);
    URL = URL.replace("{{ipAddress}}", ipAddress);
    URL = URL.replace("{{ipAddres}}", ipAddress);

    if (key === "mgmt") {
      window.open(URL, "_blank", "width=500, height=500");
    } else {
      try {
        const actionResponse = await telemetry.post(URL);
        alert(actionResponse.data);
      } catch (e) {
        alert(e.message);
      }
    }

    handleContextMenuClose();
  };

  const handleManagementInterface = async (
    managementURL,
    hasManagementInterface,
  ) => {
    if (managementURL) handleAction(managementURL, "mgmt");
    else if (hasManagementInterface)
      handleOptionSelected("Management Interface");
  };

  return contextMenuData
    ? (contextMenuData.hasManagementInterface ||
        contextMenuData.managementURL ||
        contextMenuData.hasSelfDashboard ||
        contextMenuData.actions.length > 0) && (
        <Menu
          open={contextMenu !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {(contextMenuData.hasManagementInterface ||
            contextMenuData.managementURL) && (
            <MenuItem
              key={uuidV4()}
              onClick={() =>
                handleManagementInterface(
                  contextMenuData.managementURL,
                  contextMenuData.hasManagementInterface,
                )
              }
            >
              Management Interface
            </MenuItem>
          )}
          {contextMenuData.hasSelfDashboard && (
            <MenuItem
              key={uuidV4()}
              onClick={() => handleOptionSelected("dashboard")}
            >
              Dashboard
            </MenuItem>
          )}
          {contextMenuData.actions.map((action) => (
            <MenuItem
              key={uuidV4()}
              onClick={() => handleAction(action.apiURL)}
            >
              {action.actionName}
            </MenuItem>
          ))}
        </Menu>
      )
    : null;
}

export default ContextMenu;
