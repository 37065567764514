import Draggable from "react-draggable";
import MDBox from "components/MDBox";
import SENSOR_CONFIGS from "layouts/nms/configs/sensorConfigs";


function Sensor({ isAdmin, isEditMode, sensor, handleSensorDragStop }) {
  return (
    <Draggable
      position={{
        x: sensor.configuration.sensorX,
        y: sensor.configuration.sensorY,
      }}
      onStop={(e, ui) => handleSensorDragStop(ui.lastX, ui.lastY, sensor)}
      disabled={!isAdmin || !isEditMode}
    >
      <MDBox
        position="absolute"
        component="div"
        width="1.1em"
        height="1.1em"
        sx={{
          cursor: "pointer",
          backgroundColor: SENSOR_CONFIGS[sensor.sensorStatus],
          borderRadius: "50%",
        }}
        border="2px solid black"
      />
    </Draggable>
  );
}

export default Sensor;

