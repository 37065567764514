import Menu from "@mui/material/Menu";
import UCNotificationItem from "application/components/UCNotificationItem";
import Icon from "@mui/material/Icon";

export default function ({ hasChildren, selectedMenu, menu, onMenuClicked }) {
    return (
        <Menu
            anchorEl={selectedMenu != null ? selectedMenu.event.target : null}
            anchorReference={null}
            onMouseLeave={() => {
                onMenuClicked();
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={selectedMenu != null && Boolean(selectedMenu)}
            onClose={() => onMenuClicked()}
            sx={{ mt: 2 }}
        >
            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("ADD", menu.node, "ON-SUCCESS");
                }}
                icon={<Icon>done</Icon>}
                title="On Success"
            />
            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("ADD", menu.node, "ON-FAIL");
                }}
                icon={<Icon>close</Icon>}
                title="On Fail"
            />
        </Menu>
    );
}