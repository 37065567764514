import Draggable from "react-draggable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

function Label({
  isAdmin,
  isEditMode,
  location,
  handleLabelDragStop,
}) {
  const { darkMode } = useMaterialUIController()[0];

  return (
    <>
      <Draggable
        position={{
          x: location.configuration.labelX,
          y: location.configuration.labelY,
        }}
        onStop={(e, ui) => handleLabelDragStop(ui.lastX, ui.lastY, location)}
        disabled={!isAdmin || !isEditMode}
      >
        <MDBox
          sx={{
            cursor: "pointer",
            writingMode:
              location.configuration.labelOrientation === "vertical"
                ? "vertical-lr"
                : "",
          }}
          position="absolute"
        >
          {location.configuration.labelText.length > 0 ? <MDTypography
            sx={{
              color: "black",
              backgroundColor: darkMode ? "#000" : "#FFF",
              paddingInline: "0.3em",
              borderRadius: "0.5em",
              fontSize: `${location.configuration.labelFontSize / 10}em`,
              textOrientation:
                location.configuration.labelOrientation === "vertical"
                  ? "upright"
                  : "",
            }}
            fontWeight="bold"
          >
            {location.configuration.labelText}
          </MDTypography> : null}
        </MDBox>
      </Draggable>
    </>
  );
}

export default Label;
