import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MenuItem from "@mui/material/MenuItem";

function SelectImg({
  label,
  name,
  value,
  onChange,
  error,
  success,
  url,
  options,
  keyField,
  keyDescription,
  accessorKeyField,
  accessorValueField,
}) {
  const [listOptions, setListOptions] = useState(options);

  useEffect(() => {
    if (url) {
      application.post(url).then((response) => {
        setListOptions(response.data.data);
      });
    }
  }, []);

  return (
    <>
      <MDTypography variant="caption">{label}</MDTypography>
      <Select
        fullWidth
        name={name}
        value={accessorKeyField ? value[accessorKeyField] : value}
        onChange={onChange}
        sx={{
          marginBottom: "4px",
          "& option": {
            color: "#344767",
          },
        }}
        style={{ height: "50px" }}
      >
        {listOptions &&
          listOptions.map((option) => (
            <MenuItem
              key={option[keyField]}
              defaultValue="Layout1"
              value={option[keyField]}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div>{option[keyDescription]}</div>
              <div>
                <img
                  style={{ width: "80px", height: "30px" }}
                  src={option.imageSrc}
                  alt="SmartAssetImage"
                />
              </div>
            </MenuItem>
          ))}
      </Select>
    </>
  );
}

export default SelectImg;
