import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "lg",
  columns: 3,
  settingsMode: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/configuration/getHostname",
  update: "/configuration/updateHostname",
};

const getColumns = () => {
  return [
    {
      Header: "Host Name",
      accessor: "hostname",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      detailedExplanation: " Use this configuration to configure Host Name ",
    },
  ];
};

export { getColumns, Attributes, operationURLS };
