import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import AuthenticationService from "../../AuthenticationService";

const operationURLS = {
  update: "user/changePassword",
};

function isStrongPassword(password) {
  // Password must have at least one uppercase letter, one lowercase letter, one digit, and one special character
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/;
  return strongPasswordRegex.test(password);
}

function ChangePassword() {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const isComplexPassword = AuthenticationService.getPasswordComplexity();

  const update = () => {
    if (isComplexPassword === true) {
      if (newPassword !== confirmPassword) {
        setNotify({
          isOpen: true,
          message: "New Password and Confirm Password do not match.",
          type: "error",
        });
        return;
      }

      if (!isStrongPassword(newPassword)) {
        setNotify({
          isOpen: true,
          message:
            "Password must have at least one uppercase letter, one lowercase letter, one digit, and one special character.",
          type: "error",
        });
        return;
      }
    }

    application
      .post(operationURLS.update, {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.message + " " + response.data.messageDetail,
          type: "success",
        });
        navigate("/site/log-out");
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
        });
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Visibility & VisibilityOff Password
  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(true);
  };

  const handleHideNewPassword = () => {
    setShowNewPassword(false);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(true);
  };

  const handleHideConfirmPassword = () => {
    setShowConfirmPassword(false);
  };

  const handleBack = () => {
    navigate("/app/home");
  };

  return (
    <DashboardLayout>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}
      >
        <Card sx={{}}>
          <MDBox
            variant="gradient"
            bgColor="secondary"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
              Change Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type={showPassword ? "text" : "password"}
                  label="Old Password"
                  variant="standard"
                  fullWidth
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={handleShowPassword}
                          onMouseUp={handleHidePassword}
                          edge="end"
                          style={{ margin: "1px" }}
                        >
                          {showPassword ? (
                            <Visibility color="success" />
                          ) : (
                            <VisibilityOff color="info" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  type={showNewPassword ? "text" : "password"}
                  label="New Password"
                  variant="standard"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={handleShowNewPassword}
                          onMouseUp={handleHideNewPassword}
                          edge="end"
                          style={{ margin: "1px" }}
                        >
                          {showNewPassword ? (
                            <Visibility color="success" />
                          ) : (
                            <VisibilityOff color="info" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirm New Password"
                  variant="standard"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={handleShowConfirmPassword}
                          onMouseUp={handleHideConfirmPassword}
                          edge="end"
                          style={{ margin: "1px" }}
                        >
                          {showConfirmPassword ? (
                            <Visibility color="success" />
                          ) : (
                            <VisibilityOff color="info" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox mt={6} mb={1} display="flex" justifyContent="center">
                <MDButton
                  variant="gradient"
                  fullWidth
                  color="info"
                  onClick={update}
                >
                  Update
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </DashboardLayout>
  );
}

export default ChangePassword;
