const Attributes = {
    enableNMSSelect: true,
  modes: [
    {
      iconName: "menu",
      id: "scada.Asset",
      name: "Scada Asset",
      description: "Scada Asset",
      url: "home/app/scadaAsset",
    },
    {
      id: "category",
      iconName: "category",
      name: "Category",
      description: "Category",
      url: "home/app/Category",
    },
    {
      iconName: "navigation",
      id: "Location",
      name: "Location",
      description: "Location",
      url: "home/app/Location",
    },
    {
      iconName: "ping",
      id: "Service.Master",
      name: "Service Master",
      description: "Service Master",
      url: "home/app/ServiceMaster",
    },
    {
      iconName: "category",
      id: "Category.Service",
      name: "Category Service",
      description: "Category Service",
      url: "home/app/CategoryService",
    },
    {
      iconName: "dns",
      id: "configuration.subnet-master",
      name: "Discover-Subnet-Master",
      url: "home/app/configurations/subnet-master",
      description: "Subnet-Master",
    },
  ],
};
const operationURLS = {
    list:"/dashboard/assetDashboard",
  };

const getColumns = () => {
  return [];
};

export { Attributes, operationURLS };
