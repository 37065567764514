import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";


const Attributes = {
  formSize: "lg",
  columns: 3,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: true,
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const onChangeISD = (event, formik) => {
  if (event.target.checked === true) {
    formik.setFieldValue('field_std', true);
    formik.setFieldValue('field_local', true);
    formik.setFieldValue('field_ic', true);
  }
 
}

const onChangeSTD = (event, formik) => {
  if (event.target.checked === true) {
    formik.setFieldValue('field_local', true);
    formik.setFieldValue('field_ic', true);
  }
  if(event.target.checked === false) {
    formik.setFieldValue('field_isd', false);
  }
}

const onChangeLOCAL = (event, formik) => {
  if (event.target.checked === true) {
    formik.setFieldValue('field_ic', true);
  }
  if(event.target.checked === false) {
    formik.setFieldValue('field_isd', false);
    formik.setFieldValue('field_std', false);
  }
}

const onChangeIC = (event , formik) => {
  if (event.target.checked === false) {
    formik.setFieldValue('field_isd', false);
    formik.setFieldValue('field_std', false);
    formik.setFieldValue('field_local', false);
  }
}

const operationURLS = {
  list: "/defaultFile/list ",
  create: "/defaultFile/create ",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "ISD",
      accessor: "isd",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,

      defaultValue: "Off",
      dataUnit: "",
      onChange: onChangeISD,
    },
    {
      Header: "STD",
      accessor: "std",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        " Use this configuration to configure outbound STD dialling rights",
      defaultValue: "Off",
      dataUnit: "",
      onChange: onChangeSTD,
    },
    {
      Header: "LOCAL",
      accessor: "local",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use this configuration to configure outbound Local dialling rights",
      defaultValue: "Off",
      dataUnit: "",
      onChange: onChangeLOCAL,
    },
    {
      Header: "IC",
      accessor: "ic",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use this configuration to configure outbound Intercom dialling rights",
      defaultValue: "Off",
      dataUnit: "",
      onChange: onChangeIC,
    },
    {
      Header: "VM",
      accessor: "vm",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        " Use this configuration to configure Voice Mail rights",
      defaultValue: "Off",
      dataUnit: "",
    },
    {
      Header: "DND",
      accessor: "dnd",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use this configuration to configure Do Not Disturbed rights",
      defaultValue: "Off",
      dataUnit: "",
    },
    {
      Header: "Allow VideoCall",
      accessor: "videoCall",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use this configuration to configure Allow Video Call  rights",
      defaultValue: "Off",
      dataUnit: "",
    },
    {
      Header: "Recording",
      accessor: "record",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,

      detailedExplanation:
        "Use this configuration to configure Call Recording rights",
      defaultValue: "Off",
      dataUnit: "",
    },
    {
      Header: "Set Call Waiting Status",
      accessor: "callWaitingStatus",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "0", description: "Default" },
          { id: "2", description: "On" },
          { id: "1", description: "Off" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
      detailedExplanation: "",
      defaultValue: "30",
      dataUnit: "Seconds:",
    },
    {
      Header: "Set Day Inboundcos",
      accessor: "dayInboundcos",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"select",
      options: {
        keyField: "id",
        keyDescription: "cos",
        values: [],
        url: "/inboundCos/list/cos",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      detailedExplanation: "",
      defaultValue: "",
      dataUnit: "",
    },
    {
      Header: "Set Night Inboundcos",
      accessor: "nightInboundcos",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"select",
      options: {
        keyField: "id",
        keyDescription: "cos",
        values: [],
        url: "/inboundCos/list/cos",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      detailedExplanation: "",
      defaultValue: "",
      dataUnit: "",
    },
    {
      Header: "Set Day Outboundcos",
      accessor: "dayOutboundcos",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"select",
      options: {
        keyField: "id",
        keyDescription: "cos",
        values: [],
        url: "/outboundCos/list/cos",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      detailedExplanation: "",
      defaultValue: "",
      dataUnit: "",
    },
    {
      Header: "Set Night Outboundcos",
      accessor: "nightOutboundcos",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"select",
      options: {
        keyField: "id",
        keyDescription: "cos",
        values: [],
        url: "/outboundCos/list/cos",
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      detailedExplanation: "",
      defaultValue: "",
      dataUnit: "",
    },
    {
      Header: "Set Sip Password",
      accessor: "sipPassword",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType:"password",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").max(50, "Must be 50 characters or less").lenthDefination("Invalid input"),
      detailedExplanation: "Use this configuration to configure SIP Password",
      defaultValue: "",
      dataUnit: "",
    },
    {
      Header: "Set Ring Time",
      accessor: "ringTime",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
      detailedExplanation:
        "Use this configuration to configure how long a phone will ring before triggering no answer action if left empty.",
      defaultValue: "30",
      dataUnit: "",
    },
  ];
};

export { getColumns, Attributes, operationURLS };
