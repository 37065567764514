function helper(data, locationTreeType) {
  return data.map((node) => {
    const key = `${locationTreeType}-${node.id}`;

    const isNodeSubtreeOpen =
      JSON.parse(localStorage.getItem(key)) !== null
        ? JSON.parse(localStorage.getItem(key))
        : true;

    const children = node.children.filter((child) => child.children.length > 0);

    return {
      ...node,
      isSubtreeOpen: isNodeSubtreeOpen,
      children:
        children.length > 0 && isNodeSubtreeOpen ? helper(children, locationTreeType) : [],
      subtree: children.length > 0 ? helper(children, locationTreeType) : [],
    };
  });
}

export default function locationTreeDataTransform(data, locationTreeType) {
  return helper(data, locationTreeType);
}
