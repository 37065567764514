import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MenuCards from "./MenuCards";
import Grid from "@mui/material/Grid";
import { application } from "globals/endpoints";
import CTLIfram from "application/components/CTLifram";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import loggerFactory from "globals/logger/logger-factory";
import { generateServicesEndPoint } from "globals/endpoints";
import MediaGatewayCard from "./MediaGatewayCard";

function NodeConfiguration() {
  const componentName = "Node Configuration";
  const [tabValue, settabValue] = useState(0);
  const [nodeConfigurationLists, setNodeConfigurationList] = useState([]);
  const [rluData, setRluData] = useState([]);
  const [openIfram, setOpenIfram] = useState(false);
  const [pathUrl, setPathUrl] = useState("");
  const [descriptionTab, setDescriptionTab] = useState("");
  const [description, setDescription] = useState("");
  const [gatewayData, setGatewayData] = useState([]);
  let findUrl = window.location.href;

  const handleChange = (event, newValue) => {
    settabValue(newValue);
  };

  async function handleOpenManager(applicationName, connectInfo) {
    let urlScheme = window.location.protocol;
    let isDomain = window.location.hostname.toLowerCase().match(/[a-z]/i);

    let domainParts = window.location.hostname.split(".");
    domainParts.shift();
    let currentDomain = connectInfo.nodeName + "." + domainParts.join(".");

    loggerFactory.debug(componentName, " Node Clicked ", connectInfo.nodeIP);
    loggerFactory.debug(componentName, " URL Scheme ", urlScheme);
    loggerFactory.debug(componentName, " Is Domain ", isDomain);

    generateServicesEndPoint(
      urlScheme,
      isDomain ? currentDomain : connectInfo.nodeIP
    )
      .post("/userToken/create", { createdBy: "API" })
      .then((response) => {
        loggerFactory.debug(componentName, " Token ", response.data.data);
        let managerURL = `${urlScheme}//${
          isDomain ? currentDomain : connectInfo.nodeIP
        }/${applicationName.toLowerCase()}/access?appURL=${
          connectInfo.appURL
        }&authKey=${response.data.data}`;
        console.log(managerURL);
        if (isDomain) {
          setPathUrl(managerURL);
          setOpenIfram(true);
          setDescription(connectInfo.nodeName);
          setDescriptionTab(applicationName);
        } else {
          const popupFeatures = "fullscreen=yes resizable=yes,scrollbars=yes";
          window.open(
            managerURL,
            `${applicationName} - ${connectInfo.nodeName}`,
            popupFeatures
          );
        }
      })
      .catch((error) => {
        console.log("unable to load cdr list data : ", error);
      });
  }

  function handleClose() {
    setOpenIfram(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await application.post("/nodeConfiguration/list");
        const data = response.data.data[0];

        if (data) {
          setNodeConfigurationList(data.nodeConfiguration);
          setRluData(data.controlRluServer);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    MidiaGatewayData();
  }, []);

  // Fetch MediaGateway Data
  function MidiaGatewayData() {
    application
      .post("/SCADAAsset/mediaGateway")
      .then((res) => {
        setGatewayData(res.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  return (
    <>
      <MDBox sx={{ width: "49%" }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="RLU Server" />
          {nodeConfigurationLists.length > 0 && <Tab label="Node Server" />}
          {gatewayData.length > 0 && <Tab label="Media Gateway Server" />}
        </Tabs>
      </MDBox>
      {tabValue === 0 && (
        <MDBox m={1}>
          <Grid container spacing={3}>
            {rluData.map((item, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <MenuCards
                  findUrl={findUrl}
                  color="info"
                  icon="settings"
                  title={item.rluCode}
                  manager="manager"
                  sysConfig="sys Config"
                  handleOpenManager={() =>
                    handleOpenManager("Manager", {
                      nodeName: item.rluCode,
                      nodeIP: item.virtualIp,
                      appURL: "app/home/configure",
                    })
                  }
                  handleOpenSysConfig={() =>
                    handleOpenManager("SysConfig", {
                      nodeName: item.rluCode,
                      nodeIP: item.virtualIp,
                      appURL: "app/home/dashboard",
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
      {tabValue === 1 && (
        <MDBox m={2}>
          <Grid container spacing={3}>
            {nodeConfigurationLists.map((item) => (
              <Grid item xs={12} md={6} lg={3} key={item.nodeIp}>
                <MenuCards
                  color="info"
                  icon="settings"
                  title={item.nodeName}
                  manager="manager"
                  sysConfig="sys Config"
                  handleOpenManager={() =>
                    handleOpenManager("Manager", {
                      nodeName: item.nodeName,
                      nodeIP: item.nodeIp,
                      appURL: "app/home/configure",
                    })
                  }
                  handleOpenSysConfig={() =>
                    handleOpenManager("SysConfig", {
                      nodeName: item.nodeName,
                      nodeIP: item.nodeIp,
                      appURL: "app/home/dashboard",
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
      {tabValue === 2 && (
        <MDBox m={2}>
          <Grid container spacing={3}>
            {gatewayData.map((item) => (
              <Grid item xs={12} md={6} lg={3} key={item.nodeIp}>
                <MediaGatewayCard
                  color="info"
                  icon="route"
                  title={item.nodeName}
                  id={item.id}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
      <CTLIfram
        handleClose={handleClose}
        openIfram={openIfram}
        entityName={"Call Server Programming"}
        descriptionTab={descriptionTab}
        description={description}
        url={pathUrl}
        tabValue={tabValue}
      />
    </>
  );
}

export default NodeConfiguration;
