import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";
import { useState } from "react";
import {
  useMaterialUIController,
  setShowCategoryEmail,
  setShowCategorySMS,
} from "context";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "category/list",
  create: "category/create",
  update: "category/update",
  delete: "category/delete",
};

const getColumns = (onDelete, onEdit) => {
  const [controller, dispatch] = useMaterialUIController();
  const { showCategoryEmail, showCategorySMS } = controller;

  const onChangeEmail = (event, formik) => {
    setShowCategoryEmail(dispatch, event.target.checked);
  };

  const onChangeSMS = (event, formik) => {
    setShowCategorySMS(dispatch, event.target.checked);
  };

  return [
    {
      Header: "Category Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      disabled: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Management",
      accessor: "hasManagementInterface",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Management URL",
      accessor: "managementURL",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Height",
      accessor: "height",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Image",
      accessor: "imageName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Width",
      accessor: "width",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Has Self Dashboard",
      accessor: "hasSelfDashboard",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Send Email",
      accessor: "sendEmail",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      onChange: onChangeEmail,
    },
    {
      Header: "Send SMS",
      accessor: "sendSMS",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      onChange: onChangeSMS,
    },
    {
      Header: "Email Address",
      accessor: "emailId",
      width: 12,
      displayInList: true,
      allowInForm: showCategoryEmail,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNo",
      width: 12,
      displayInList: true,
      allowInForm: showCategorySMS,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "extension",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
