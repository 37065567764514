import { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import { useMaterialUIController, setQueueValue } from "context";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";
import { getQueueColumns, queueAttributes, queueOperationURLS } from "./model";

function QueueConfiguration({
  onLoad,
  registerCallBacks,
  updateTableList,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, queueValue, ivrExtension } = controller;
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    QueueDetail(ivrExtension?.code?.id);
  }, [ivrExtension]);

  useEffect(() => {
    queueAttributes && onLoad(queueAttributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
      });
  }, [activePage]);

  const QueueDetail = (id) => {
    application
      .post(`${queueOperationURLS.qlist}/${id}`)
      .then((response) => {
        const result = response.data.data;
        setQueueValue(dispatch, result);
      })
      .catch((err) => {
        console.error("error: ", err);
      });
  };

  useEffect(() => {
    setFormData(queueValue);
  }, [queueValue]);

  useEffect(() => {
    setUpdateList(Date.now());
  }, [updateTableList]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onSaveForm(data) {
    const id = ivrExtension?.code?.id;
    data = { ...data, systemName:queueValue.systemName ,id:id
    };
    application
      .post(queueOperationURLS.queueUpdate, data)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.message,
          type: "success",
          pagename: "Call-Center",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.message,
          type: "error",
          pagename: "Call-Center",
        });
      });
  }

  return (
    <>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Queue"
        columns={getQueueColumns(null, null)}
        openForm={true}
        onCloseForm={null}
        formData={formData}
        formAttributes={queueAttributes}
        onSaveForm={onSaveForm}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default QueueConfiguration;
