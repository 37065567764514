import { Card, Grid, Divider } from "@mui/material";
import UCNotificationItem from "application/components/UCNotificationItem";
import { Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState, useRef } from "react";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLNotification from "application/components/CTLNotification";
import Box from "@mui/material/Box";
import MDButton from "components/MDButton";
import { application } from "globals/endpoints";

export default function CDRS({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  onCall,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, tableValue } = controller;
  const [selectedButton, setSelectedButton] = useState();
  const [formData, setFormData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [updateList, setUpdateList] = useState(Date.now());
  const [columns, setColumns] = useState(getColumns());
  const [listOptions, setListOptions] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage, tableValue]);

  function DownloadData() {
    setOpenForm(true);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  useEffect(() => {
    application.post(operationURLS.groupList).then((response) => {
      setListOptions(response.data.data);
      if (response.data.data && response.data.data.length > 0) {
        setSelectedButton(response.data.data[0].groupCode);
        const defaultFilter = {
          direction: "",
          dataType: "",
          fieldName: "extGroupCode",
          value: `${response.data.data[0].groupCode}`,
        };
        setAdvancedFilters([defaultFilter]);
      } else {
        setAdvancedFilters([]);
        setNotify({
          isOpen: true,
          message: "There is no data to display.",
          type: "info",
          pagename: pageName,
          status: "",
        });
      }
    });
  }, []);

  const handleClickButton = (menuItem) => {
    setSelectedButton(menuItem.groupCode);
      setAdvancedFilters([
        {
            direction: "",
            dataType: "",
            fieldName : "extGroupCode",
            value: `${menuItem.groupCode}`,
        },
       
      ]);
    };

  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <Card style={{ height: "100%", width: "100%" }}>
          {listOptions &&
            listOptions.map((menuItem, index) => (
              <MDBox key={index} style={{ padding: "5px" }}>
                <MDButton
                  onClick={() => handleClickButton(menuItem)}
                  fullWidth
                  color={
                    selectedButton === menuItem.groupCode ? "info" : "secondary"
                  }
                >
                  {menuItem.groupName}
                </MDButton>
              </MDBox>
            ))}
        </Card>
      </Grid>
      <Grid item xs={11}>
        <Card style={{ height: "100%", width: "100%" }}>
          <CTLTable
            apiURL={operationURLS.list}
            columns={columns}
            advancedFilters={advancedFilters}
            entriesPerPage={false}
            canSearch
            defaultSortDirection="desc"
            defaultSortColumn="id"
            pageId={pageId}
            searchText={searchText}
            hasRowSelection={false}
            updateList={updateList}
            refetchInterval={Attributes.refetchInterval}
            onCall={onCall}
          />
          <CTLNotification notify={notify} setNotify={setNotify} />
        </Card>
      </Grid>
    </Grid>
  );
}
