import { createElement } from "react";
import locationrecordsListColumns from "./locationAssetsListColumns";
import getColumnsDetails from "./configs/columnsDetails";
import {
  getColumnsConfig,
  getColumnsConfigLocalStorage,
} from "./configs/columnsConfig";
import { useMaterialUIController } from "context";
import { TABLE_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import { useMemo, useState, useEffect } from "react";
import getFilteredItems from "./configs/filterItems";
import Dropdown from "../../Dropdown";
import Filter from "./Filter";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import ASSETS_CATEGORIES from "../../configs/assetsConfigs";
import "./css/index.css";
import getNMSComponent from "globals/nms-components";

function Table({
  location,
  descendantsData,
  categoryData,
  severityData,
  isAdmin,
  isEditMode,
  tableStateKey,
}) {
  const [smartAssetPopupOpen, setSmartAssetPopupOpen] = useState(false);
  const [contextMenuData, setContextMenuData] = useState(null);
  const [row, setRow] = useState(null);
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(`tableState-${tableStateKey}`)) || {},
  );

  const handleSmartAssetPopupOpen = () => {
    setSmartAssetPopupOpen(true);
  };

  const handleSmartAssetPopupClose = () => {
    setSmartAssetPopupOpen(false);
  };

  let componentRender = null;
  if (row) componentRender = getNMSComponent(row.category);

  const handleRowClick = (row) => {
    setRow(row);
    const category = categoryData.filter(
      (category) => category.code === row.category,
    )[0];
    if (category) setContextMenuData(category);
    if (category && true) handleSmartAssetPopupOpen();
  };

  useEffect(() => {
    localStorage.setItem(
      `tableState-${tableStateKey}`,
      JSON.stringify(tableState),
    );
  }, [tableState]);

  const columns = locationrecordsListColumns;
  const columnsDetails = getColumnsDetails(columns);

  const recordColumns =
    getColumnsConfigLocalStorage(tableState.nextOrder) ||
    getColumnsConfig(columns);

  const darkModeContext = useMaterialUIController();

  let tableData = [];
  Object.keys(descendantsData).forEach((recordID) => {
    if (
      descendantsData[recordID].parentLocations.includes(location) &&
      descendantsData[recordID].category !== ASSETS_CATEGORIES.LOCATION
    )
      tableData.push(descendantsData[recordID]);
  });
  tableData = tableData.slice().sort((a, b) => {
    if (a.severity > b.severity) return -1;
    if (a.severity < b.severity) return 1;
  });

  const tableRecords = tableData.map((record) => {
    const newTransformedRecord = {};
    Object.keys(record).forEach((recordProp) => {
      if (columnsDetails[recordProp] && columnsDetails[recordProp].display) {
        if (recordProp === "status")
          newTransformedRecord[recordProp] = record[recordProp] ? "Up" : "Down";
        else if (
          !record[recordProp] &&
          columnsDetails[recordProp].type === "epoch"
        )
          newTransformedRecord[recordProp] = "";
        else if (
          !record[recordProp] &&
          columnsDetails[recordProp].type !== "severity"
        )
          newTransformedRecord[recordProp] = "NULL";
        else if (columnsDetails[recordProp].type === "epoch") {
          const date = new Date(record[recordProp] * 1000);
          newTransformedRecord[recordProp] = date.toLocaleString("en-IN");
        } else if (columnsDetails[recordProp].type === "severity") {
          const targetNodeSeverity = record[recordProp];
          const severityObj = severityData.filter(
            (severity) => severity.severityLevel === targetNodeSeverity,
          )[0];

          if (!severityObj)
            console.log(
              `No configuration found for severity level ${targetNodeSeverity}`,
            );

          const severityColor = severityObj.color;
          newTransformedRecord[recordProp] = (
            <p
              style={{
                backgroundColor: severityColor,
                color: "#000",
                padding: ".5em",
                fontWeight: "500",
                borderRadius: ".5em",
              }}
            >
              {severityObj.severityName}
            </p>
          );
          newTransformedRecord.severityText = severityObj.severityName;
        } else newTransformedRecord[recordProp] = record[recordProp];
      }
    });

    return newTransformedRecord;
  });

  const primaryLabel = `Assets at ${location}`;
  const dropdownOptions = TABLE_DROPDOWN_LIST.slice(0, -1);

  // Creating custom dark theme
  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark",
  );

  // Filter feature for the ServerTable
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = getFilteredItems(
    tableRecords,
    columnsDetails,
    filterText,
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value.toLowerCase())}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  // dropdown feature for the ServerTable
  const dropdown = useMemo(
    () =>
      dropdownOptions.length > 0 && (
        <Dropdown
          options={dropdownOptions}
          filteredItems={filteredItems}
          assetColumns={recordColumns}
          primaryLabel={primaryLabel}
        />
      ),
    [filteredItems, tableState],
  );

  const table = useMemo(
    () => (
      <>
        <DataTable
          data={filteredItems}
          columns={recordColumns}
          onRowClicked={(row) => handleRowClick(row)}
          responsive
          pagination
          paginationPerPage={tableState.currentRowsPerPage || 10}
          striped
          theme={darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"}
          title={<MDTypography variant="h6">{primaryLabel}</MDTypography>}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
          fixedHeader
          actions={dropdown}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          fixedHeaderScrollHeight={`${(window.innerHeight / 100) * 66}px`}
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setTableState({ ...tableState, currentRowsPerPage })
          }
          onColumnOrderChange={(nextOrder) =>
            setTableState({ ...tableState, nextOrder })
          }
        />
        {row &&
          smartAssetPopupOpen &&
          componentRender &&
          contextMenuData &&
          createElement(componentRender, {
            open: smartAssetPopupOpen,
            handleClose: handleSmartAssetPopupClose,
            isAdmin: isAdmin,
            isEditMode: isEditMode,
            primaryLabel: `${row.category}/${row.name}`,
            assetId: row.id,
            assetIpAddress: row.ipAddress,
            assetImageName: `/images/${contextMenuData.imageName}`,
            assetImageWidth: contextMenuData.width,
            assetImageHeight: contextMenuData.height,
            category: row.category,
          })}
      </>
    ),
    [
      filterText,
      location,
      tableState,
      row,
      contextMenuData,
      smartAssetPopupOpen,
      componentRender,
    ],
  );

  return <Card>{table}</Card>;
}

export default Table;
