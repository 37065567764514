import Menu from "@mui/material/Menu";
import UCNotificationItem from "application/components/UCNotificationItem";
import Icon from "@mui/material/Icon";

export default function ({ hasChildren, selectedMenu, menu, onMenuClicked }) {
    return (
        <Menu
            anchorEl={selectedMenu != null ? selectedMenu.event.target : null}
            anchorReference={null}
            onMouseLeave={() => {
                onMenuClicked();
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={selectedMenu != null && Boolean(selectedMenu)}
            onClose={() => onMenuClicked()}
            sx={{ mt: 2 }}
        >
            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("UPDATE", menu.node, "TREE");
                }}
                icon={<Icon>account_tree</Icon>}
                title="Convert to Tree"
            />

            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("UPDATE", menu.node, "EXT");
                }}
                icon={<Icon>phone_forwarded</Icon>}
                title="Convert to Extension"
            />

            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("UPDATE", menu.node, "PF");
                }}
                icon={<Icon>play_arrow</Icon>}
                title="Convert to Play File"
            />

            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("UPDATE", menu.node, "LTS");
                }}
                icon={<Icon>link</Icon>}
                title="Convert to Loop To Step"
            />

            <UCNotificationItem
                onClick={() => {
                    onMenuClicked("UPDATE", menu.node, "API");
                }}
                icon={<Icon>keyboard</Icon>}
                title="Convert to User Input"
            />
        </Menu>
    );
}