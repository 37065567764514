import Graph from "../Graph";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Legend from "../Legend";
import Dropdown from "../../../Dropdown";
import renderSvgNode from "../renderSvgNode";
import renderSvgLink from "../renderSvgLink";
import { GRAPH_TOPOLOGY_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import SelectCategoryFilter from "../SelectCategoryFilter";
import AssetFilter from "../AssetFilter";
import DisplayOptionsLegend from "../DisplayOptionsLegend";

function RenderGraphTopology({
  isEditMode,
  isAdmin,
  primaryLabel,
  secondaryLabel,
  data,
  descendantsData,
  categoryData,
  severityData,
  isDarkMode,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  index,
  error,
  colorFilter,
  handleColorFilterChange,
  asset,
  handleSearchAsset,
  assetCategory,
  handleAssetCategoryChange,
  toggleRefreshGraph,
  displayOptions,
  handleDisplayOptionsChange,
}) {
  const renderedNode = renderSvgNode(
    descendantsData,
    isDarkMode,
    categoryData,
    severityData,
    displayOptions,
    colorFilter,
    asset,
    assetCategory,
    isAdmin,
    isEditMode,
    1,
  );

  const renderedLink = renderSvgLink(isDarkMode);

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" width="100%">
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        <MDBox display="flex">
          <SelectCategoryFilter
            categories={categoryData.map(
              (categoryConfig) => categoryConfig.code,
            )}
            assetCategory={assetCategory}
            handleAssetCategoryChange={handleAssetCategoryChange}
            isDarkMode={isDarkMode}
          />
          <AssetFilter asset={asset} handleSearchAsset={handleSearchAsset} />
          {error}
          {isEditMode && isAdmin && (
            <Dropdown
              handleDeleteWidget={handleDeleteWidget}
              masterContainerId={masterContainerId}
              cellId={cellId}
              index={index}
              options={GRAPH_TOPOLOGY_DROPDOWN_LIST}
            />
          )}
        </MDBox>
      </MDBox>
      {Object.keys(data).length > 0 && (
        <DisplayOptionsLegend
          title="Display Options"
          isDarkMode={isDarkMode}
          displayOptions={displayOptions}
          handleDisplayOptionsChange={handleDisplayOptionsChange}
          categories={[
            {
              optionName: "locationName",
              name: "Location Name",
            },
            {
              optionName: "location",
              name: "Location",
            },
            {
              optionName: "asset",
              name: "Asset",
            },
          ]}
        />
      )}
      {Object.keys(data).length > 0 && (
        <Legend
          title="Severity"
          isDarkMode={isDarkMode}
          colorFilter={colorFilter}
          handleColorFilterChange={handleColorFilterChange}
          level={1}
          categories={severityData.map((severity) => ({
            color: severity.color,
            colorName: severity.color,
            name: severity.severityName,
          }))}
        />
      )}
      {Object.keys(data).length > 0 && (
        <Graph
          data={data}
          NodeComponent={renderedNode}
          LineComponent={renderedLink}
          nodeDistance={50}
          zoomDepth={4}
          hoverOpacity={0.2}
          enableDrag
          pullIn
          toggleRefreshGraph={toggleRefreshGraph}
        />
      )}
    </>
  );
}

export default RenderGraphTopology;
