import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import Agent from "../agent";
import { application } from "globals/endpoints";
import { useMaterialUIController, setQueueValue } from "context";
import MDTypography from "components/MDTypography";
import CTLTable from "application/components/CTLTable";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";
import { getQueueColumns, queueAttributes, queueOperationURLS } from "./model";

function Queue({ onLoad, searchText, registerCallBacks, updateTableList }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, queueValue, ivrExtension } = controller;
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    QueueDetail(ivrExtension.code);
  }, [ivrExtension]);

  useEffect(() => {
    queueAttributes && onLoad(queueAttributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
      });
  }, [activePage]);

  const QueueDetail = (id) => {
    application
      .post(`${queueOperationURLS.qlist}/${id}`)
      .then((response) => {
        const result = response.data.data;
        setQueueValue(dispatch, result);
      })
      .catch((err) => {
        console.error("error: ", err);
      });
  };

  useEffect(() => {
    setFormData(queueValue);
  }, [queueValue]);

  useEffect(() => {
    setUpdateList(Date.now());
  }, [updateTableList]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onSaveForm(queueValue) {
    application
      .post(queueOperationURLS.queueUpdate, queueValue)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: "Call-Center",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Call-Center",
        });
      });
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Stack spacing={1} direction="column" sx={{ marginBottom: 20 }}>
          <Card
            style={{
              margin: 1,
              overflow: "scroll",
              overflowX: "hidden",
              maxHeight: 260,
              marginBottom: 20,
            }}
          >
            <MDTypography sx={{ marginLeft: 10, marginBottom: 2 }}>
              Queue Setting
            </MDTypography>
            <CTLBaseForm
              enableFormButtons={true}
              entityName="Queue"
              columns={getQueueColumns(null, null)}
              openForm={true}
              onCloseForm={null}
              formData={formData}
              formAttributes={queueAttributes}
              onSaveForm={onSaveForm}
            />
          </Card>
          <CTLNotification notify={notify} setNotify={setNotify} />
          <Agent onLoad={onLoad} updateTableList={updateList} />
        </Stack>
      </Stack>
    </>
  );
}

export default Queue;
