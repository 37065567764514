import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import CTLDashboardLayout from "application/components/CTLDashboardLayout";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";

function Extension({ onLoad, mode, pageId, defaultSortColumn, defaultSortDirection, searchText, registerCallBacks }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({
      openAdvancedFilter: onOpenFilter
    });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onAgree = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="Extension"
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLAdvancedFilter
        entityName="Extension"
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}

      />

      <CTLTable
        apiURL="/extensions/extension/list"
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="extension"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={true}
      />
    </MDBox>
  );
}

export default Extension;
