import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { useState } from "react";
import {
  useMaterialUIController,
  setSundayDisabled,
  setMondayDisabled,
  setTuesdayDisabled,
  setWednesdayDisabled,
  setThursdayDisabled,
  setFridayDisabled,
  setSaturdayDisabled,
} from "context";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  formSize: "md",
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  queueUpdate: "/queue/update",
  qlist: "/queue",
};

const getColumns = (onDelete, onEdit) => {
  const [requiredField, setRequiredField] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const {
    sundayDisabled,
    mondayDisabled,
    tuesdayDisabled,
    wednesdayDisabled,
    thursdayDisabled,
    fridayDisabled,
    saturdayDisabled,
  } = controller;

  const checkselect = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      setRequiredField(formControl.string().nullable().required("Required"));
    } else {
      setRequiredField("");
    }
  };

  const onChangeSunday = (event, formik) => {
    setSundayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_sunFromTime", null);
      formik.setFieldValue("field_sunToTime", null);
    }
  };

  const onChangeMonday = (event, formik) => {
    setMondayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_monFromTime", null);
      formik.setFieldValue("field_monToTime", null);
    }
  };

  const onChangeTuesday = (event, formik) => {
    setTuesdayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_tueFromTime", null);
      formik.setFieldValue("field_tueToTime", null);
    }
  };

  const onChangeWednesday = (event, formik) => {
    setWednesdayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_wedFromTime", null);
      formik.setFieldValue("field_wedToTime", null);
    }
  };

  const onChangeThursday = (event, formik) => {
    setThursdayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_thuFromTime", null);
      formik.setFieldValue("field_thuToTime", null);
    }
  };

  const onChangeFriday = (event, formik) => {
    setFridayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_friFromTime", null);
      formik.setFieldValue("field_friToTime", null);
    }
  };

  const onChangeSaturday = (event, formik) => {
    setSaturdayDisabled(dispatch, event.target.checked == false);
    if (event.target.checked == false) {
      formik.setFieldValue("field_satFromTime", null);
      formik.setFieldValue("field_satToTime", null);
    }
  };

  return [
    {
      Header: "Time Zone",
      accessor: "timezone",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      toUpperCase: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "From Time",
      accessor: "fromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "toTime",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().nullable().required("Required"),
      onChange: checkselect,
      width: 4,
    },
    {
      Header: "To Time",
      accessor: "toTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().nullable().required("Required"),
      width: 4,
    },
    {
      Header: "Non Business Hours Application",
      accessor: "nonWorkingApp",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Non Business Hours Transfer No",
      accessor: "nonWorkingData",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Non Business Hour Call",
      accessor: "nbhCallMode",
      displayInList: false,
      allowFilter: false,
      allowInForm: true,
      width: 4,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "0", description: "Hang Up" },
          { id: "1", description: "Transfer" },
        ],
      },
      validation: formControl.string().required("Required").nullable(true),
    },
    {
      Header: "Sunday",
      accessor: "dsun",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: requiredField,
      onChange: onChangeSunday,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Sunday From Time",
      accessor: "sunFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: sundayDisabled,
      setChange: "sunToTime",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Sunday To Time",
      accessor: "sunToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: sundayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Monday",
      accessor: "dmon",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: requiredField,
      onChange: onChangeMonday,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Monday From Time",
      accessor: "monFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "monToTime",
      disabled: mondayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Monday To Time",
      accessor: "monToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: mondayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Tuesday",
      accessor: "dtue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: requiredField,
      onChange: onChangeTuesday,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Tuesday From Time",
      accessor: "tueFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "tueToTime",
      disabled: tuesdayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Tuesday To Time",
      accessor: "tueToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: tuesdayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Wednesday",
      accessor: "dwed",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: requiredField,
      onChange: onChangeWednesday,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Wednesday From Time",
      accessor: "wedFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "wedToTime",
      disabled: wednesdayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Wednesday To Time",
      accessor: "wedToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: wednesdayDisabled,
      width: 4,

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Thursday",
      accessor: "dthu",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: requiredField,
      onChange: onChangeThursday,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Thrusday From Time",
      accessor: "thuFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "thuToTime",
      disabled: thursdayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Thursday To Time",
      accessor: "thuToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: thursdayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Friday",
      accessor: "dfri",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: requiredField,
      onChange: onChangeFriday,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Friday From Time",
      accessor: "friFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "friToTime",
      disabled: fridayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Friday To Time",
      accessor: "friToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      disabled: fridayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Saturday",
      accessor: "dsat",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: requiredField,
      onChange: onChangeSaturday,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Saturday From Time",
      accessor: "satFromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "time",
      setChange: "satToTime",
      disabled: saturdayDisabled,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 4,
    },
    {
      Header: "Saturday To Time",
      accessor: "satToTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      controlType: "time",
      dataType: "string",
      disabled: saturdayDisabled,
      width: 4,

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
