import React, { useRef, useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { Stack } from "@mui/system";
import DeleteConfirmation from "../DeleteConfirmation";
import MDBox from "components/MDBox";
import { Card, Drawer } from "@mui/material";
import MDTypography from "components/MDTypography";
import { IconButton } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "App.css";
import MDButton from "components/MDButton";
import { makeStyles } from "@material-ui/styles";
import CTLBaseForm from "../CTLBaseForm";
import {
  getColumns,
  getAttributes,
  Attributes,
  nextDTMF,
  prevDTMF,
  dtmfValue,
  EmptyTreeData,
} from "./model";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import TreeNode from "./TreeNode";
import {
  useMaterialUIController,
  setIvrExtension,
  setUpdateListFlag,
  setShowIVRPlayFile,
} from "context";
import TreeMenu from "./menus/TreeMenu";
import APIMenu from "./menus/APIMenu";
import DefaultMenu from "./menus/DefaultMenu";

import Menu from "@mui/material/Menu";
import UCNotificationItem from "application/components/UCNotificationItem";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles({
  drawerPaper: {
    width: "600px !important",
    margin: "0 !important",
    borderRadius: "unset !important",
    height: "100vh !important",
  },
});

export default function CTLTree({ ivrDetails }) {
  const [controller, dispatch] = useMaterialUIController();
  const { ivrExtension } = controller;

  const componentName = "CTLTree";
  const classes = useStyles();
  const treeContainer = useRef();
  const tree = useRef();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [data, setData] = useState({});
  const [deleteKey, setDeleteKey] = useState("");
  const [propertyKey, setPropertyKey] = useState("");
  const [formType, setFormType] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [treeKey, setTreeKey] = useState(Math.random());

  const [formData, setFormData] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [referNodeMenu, setReferNodeMenu] = useState(null);

  const closeSuccessSB = () => setSuccessSB(false);
  const closeErrorSB = () => setErrorSB(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    setTreeKey(Math.random());
    if (ivrDetails == null) {
      setData({});
    } else {
      setData(ivrDetails);
      getCodeValues(ivrDetails);
    }
  }, [ivrDetails]);

  const deleteRow = (key) => {
    setDeleteKey(key);
  };

  const shiftNode = (key, direction) => {
    const treeData = { ...data };
    shiftNodeInTree(treeData, key, direction);
    updateTreeData(treeData);
  };

  const shiftNodeInTree = (node, key, direction) => {
    let indexToShift = -1;
    let index = -1;
    const keySelected = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      0: false,
      "*": false,
      "#": false,
    };
    node.children.map((item) => {
      index++;
      keySelected[item.dtmf] = true;
      if (item.key == key) {
        indexToShift = index;
      } else {
        shiftNodeInTree(item, key, direction);
      }
    });

    if (indexToShift != -1) {
      if (direction == 1) {
        let newDTMF = nextDTMF[node.children[indexToShift].dtmf];
        while (newDTMF && keySelected[newDTMF]) {
          newDTMF = nextDTMF[newDTMF];

          if (!newDTMF) {
            break;
          }
        }

        if (newDTMF) {
          node.children[indexToShift].dtmf = newDTMF;
          node.children[indexToShift].dtmfValue = dtmfValue[newDTMF];

          let newNode = { ...node.children[indexToShift] };
          node.children.splice(indexToShift, 1);
          let i = 0;
          for (
            ;
            i < node.children.length &&
            node.children[i].dtmfValue < newNode.dtmfValue;
            i++
          );
          node.children.splice(i, 0, newNode);
        }
      } else {
        let newDTMF = prevDTMF[node.children[indexToShift].dtmf];

        while (newDTMF && keySelected[newDTMF]) {
          newDTMF = prevDTMF[newDTMF];

          if (!newDTMF) {
            break;
          }
        }

        if (newDTMF) {
          node.children[indexToShift].dtmf = newDTMF;
          node.children[indexToShift].dtmfValue = dtmfValue[newDTMF];

          let newNode = { ...node.children[indexToShift] };
          node.children.splice(indexToShift, 1);

          let i = 0;
          for (
            ;
            i < node.children.length &&
            node.children[i].dtmfValue < newNode.dtmfValue;
            i++
          );
          node.children.splice(i, 0, newNode);
        }
      }
    }
  };

  const menuClicked = (action, node, actionType) => {
    setOpenMenu(null);
    if (action === "ADD") {
      addChild(node, actionType);
    } else if (action === "UPDATE") {
      updateNodeType(node, actionType);
    }
  };

  function renderMenu() {
    let menuDetails = { ...openMenu };
    let menuType = "TREE";

    const hasChildren =
      menuDetails &&
      menuDetails.node &&
      menuDetails.node.children &&
      menuDetails.node.children.length > 0;

    if (menuDetails && menuDetails.node && menuDetails.node.key != "P") {
      menuType = menuDetails.node.nodeType;
    }

    if (menuType == "TREE") {
      return (
        <TreeMenu
          hasChildren={hasChildren}
          selectedMenu={openMenu}
          menu={menuDetails}
          onMenuClicked={menuClicked}
        />
      );
    } else if (menuType == "API") {
      return (
        <APIMenu
          hasChildren={hasChildren}
          selectedMenu={openMenu}
          menu={menuDetails}
          onMenuClicked={menuClicked}
        />
      );
    } else if (
      (menuType == "EXT" ||
        menuType == "PF" ||
        menuType == "LTS" ||
        menuType == "API" ||
        menuType == "ON-SUCCESS" ||
        menuType == "ON-FAIL") &&
      !hasChildren
    ) {
      return (
        <DefaultMenu
          hasChildren={hasChildren}
          selectedMenu={openMenu}
          menu={menuDetails}
          onMenuClicked={menuClicked}
        />
      );
    } else {
      return <></>;
    }
  }

  function transferNode(nodeToShift, parentNode) {
    deleteNode(nodeToShift.key);
    nodeToShift.apiResponseType = null;
    addChild(parentNode, nodeToShift);
  }

  function renderTransferNodeMenu() {
    let menuDetails = { ...referNodeMenu };
    let referCodes = getCodeValues(data);

    if (menuDetails?.node) {
      return (
        <Menu
          anchorEl={referNodeMenu != null ? referNodeMenu.event.target : null}
          anchorReference={null}
          onMouseLeave={() => {
            setReferNodeMenu(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={referNodeMenu != null && Boolean(referNodeMenu)}
          onClose={() => setReferNodeMenu(null)}
          sx={{ mt: 2 }}
        >
          {referCodes.map((data) => {
            if (data.properties.code != menuDetails?.node?.properties?.code) {
              return (
                <UCNotificationItem
                  key={data.properties.code}
                  onClick={() => {
                    setReferNodeMenu(null);
                    let nodeToShift = JSON.parse(
                      JSON.stringify(menuDetails.node)
                    );
                    transferNode(nodeToShift, data);
                  }}
                  icon={<Icon>east</Icon>}
                  title={
                    data.properties.description
                      ? data.properties.description
                      : data.properties.code
                  }
                />
              );
            }
          })}
        </Menu>
      );
    }
  }

  const editProperty = (key) => {
    let keyData = findKey(key, data);
    let nodeType = key == "P" ? "TREE" : keyData.nodeType;

    if (nodeType != "ON-SUCCESS" && nodeType != "ON-FAIL") {
      if (!keyData.properties) {
        keyData.properties = {
          id: key,
        };
      }
      if (keyData.properties.cpm == true) {
        setShowIVRPlayFile(dispatch, false);
      } else {
        setShowIVRPlayFile(dispatch, true);
      }
      setFormData(keyData.properties);
      setFormType(nodeType);
      setPropertyKey(key);
    }
  };

  function findKey(key, data) {
    if (data.key == key) {
      return data;
    } else if (data.children && data.children.length > 0) {
      let i;
      let returnValue;
      for (i = 0; i < data.children.length; i++) {
        returnValue = findKey(key, data.children[i]);
        if (returnValue) {
          break;
        }
      }
      return returnValue;
    }
  }

  const closeProperty = () => {
    setPropertyKey("");
    setFormType("");
    setFormData(null);
  };

  const onAgree = () => {
    deleteNode(deleteKey);
    setDeleteKey("");
  };

  const deleteNode = (key) => {
    const treeData = { ...data };
    deleteFromTree(treeData, key);
    updateTreeData(treeData);
  };

  const deleteFromTree = (node, key) => {
    let index = -1;
    let indexToDelete = -1;
    node.children.map((item) => {
      index++;
      if (item.key == key) {
        indexToDelete = index;
      } else {
        deleteFromTree(item, key);
      }
    });

    if (indexToDelete != -1) {
      node.children.splice(indexToDelete, 1);
    }

    node.children.map((item) => {
      item.siblingCount = node.children.length;
    });
  };

  const onCancel = () => {
    setDeleteKey("");
  };

  const updateTreeData = (treeData) => {
    setData(treeData);
  };

  function getCodeValues(data) {
    const codeValues = [];

    traverse(codeValues, data);
    codeValues.sort((a, b) =>
      (a.properties.description
        ? a.properties.description
        : a.properties.code
      ).localeCompare(
        b.properties.description ? b.properties.description : b.properties.code
      )
    );

    return codeValues;
  }

  function traverse(codeValues, data) {
    if (data?.children && data?.children.length > 0) {
      data.children.forEach((child) => {
        child.siblingCount = data.children.length;
        traverse(codeValues, child);
      });
    }

    if (
      data?.properties &&
      data?.properties.code &&
      (data?.nodeType == "TREE" || data?.key == "P")
    ) {
      codeValues.push(data);
    }
  }

  const addChild = (nodeDatum, childType, currentNode) => {
    const cacheData = { ...data };
    const parentNode = currentNode ? currentNode : cacheData;
    let dtmf = "1";

    if (parentNode.key === nodeDatum.key) {
      if (parentNode.nodeType === "API") {
        let nodeExist = false;
        parentNode.children.map((node) => {
          if (node.apiResponseType == childType) {
            loggerFactory.error(
              componentName,
              "API Response already present as option"
            );
            setNotify({
              isOpen: true,
              message: componentName,
              type: "error",
              pagename: pageName,
              status: "API Response already present as option",
            });
            nodeExist = true;
          }
        });
        if (nodeExist) {
          return;
        }
      }

      if (nodeDatum.children && nodeDatum.children.length > 0) {
        const lastDTMF = nodeDatum.children[nodeDatum.children.length - 1].dtmf;
        if (lastDTMF == "#") {
          dtmf = "";
        } else {
          dtmf = nextDTMF[lastDTMF];
        }
      }

      if (dtmf) {
        if (typeof childType == "string") {
          parentNode.children.push({
            dtmf: dtmf,
            dtmfValue: dtmfValue[dtmf],
            name: dtmf,
            key: `${Date.now()}`,
            nodeType: childType,
            apiResponseType:
              childType == "ON-SUCCESS" || childType == "ON-FAIL"
                ? childType
                : "",
            children: [],
          });
        } else {
          childType.dtmf = dtmf;
          childType.dtmfValue = dtmfValue[dtmf];
          childType.name = dtmf;
          parentNode.children.push(childType);
        }
        parentNode.children.map((node) => {
          node.siblingCount = parentNode.children.length;
        });
        updateTreeData(cacheData);
      }
    } else {
      parentNode.children.map((node) => {
        addChild(nodeDatum, childType, node);
      });
    }
  };

  const updateNodeType = (nodeDatum, childType) => {
    const cacheData = { ...data };

    updateNode(cacheData, nodeDatum.key, "nodeType", childType);

    updateTreeData(cacheData);
  };

  const updateNode = (node, key, propertyName, value) => {
    if (node.key === key) {
      node[propertyName] = value;
    } else {
      node.children.map((node) => {
        updateNode(node, key, propertyName, value);
      });
    }
  };

  useEffect(() => {
    updateTreeData(EmptyTreeData);
  }, []);

  useEffect(() => {
    if (treeContainer.current) {
      setDimensions(treeContainer.current.getBoundingClientRect());
    }
  }, [treeContainer]);

  useEffect(() => {
    setTranslate({
      x: dimensions.width / 2,
      y: dimensions.height / 5,
    });
  }, [dimensions]);

  function handleSubmitClick() {
    handleSubmit();
    setUpdateListFlag(dispatch, true);
  }

  function handleSubmit() {
    let ivrNodes = [];
    let nodeTree = { ...data };

    generateNodeList(ivrNodes, nodeTree, 1, ivrDetails.ivrGroup);

    nodeTree["ivrNodes"] = ivrNodes;
    nodeTree["ivrGroup"] = ivrDetails.ivrGroup;

    const jsonData = JSON.stringify(nodeTree);
    loggerFactory.info(componentName, "IVR-Data", jsonData);
    try {
      application
        .post("/autoAttendant/update", nodeTree)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Done",
            type: "success",
            pagename: pageName,
            status: "Successfully update",
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.message || "An error occurred",
            type: "error",
            pagename: pageName,
            status: "Failed to update",
          });
        });
    } catch (error) {
      setNotify({
        isOpen: true,
        message: error.message || "An error occurred",
        type: "error",
        pagename: pageName,
        status: "Failed to update",
      });
    }
  }

  function generateNodeList(ivrNodes, nodeTree, level, levelId) {
    let i;

    let dtmfValue = "0000" + nodeTree.dtmfValue;

    let currentLevel =
      levelId +
      (nodeTree.dtmfValue
        ? "_" + dtmfValue.substring(dtmfValue.length - 3)
        : "");

    if (nodeTree.children && nodeTree.children.length > 0) {
      loggerFactory.info(
        componentName,
        `Each Element Data Step : ${level} ${currentLevel} ${nodeTree.dtmfValue} ${nodeTree.key} ${nodeTree.name} Parent : ${levelId} Value : ${currentLevel} `
      );

      ivrNodes.push({
        parentLevelId: levelId,
        levelId: currentLevel,
        key: nodeTree.key,
        name: nodeTree.name,
        dtmf: nodeTree.dtmf,
        dtmfValue: nodeTree.dtmfValue,
        nodeType: nodeTree.nodeType,
        apiResponseType: nodeTree.apiResponseType,
        properties: { ...nodeTree.properties },
      });

      for (i = 0; i < nodeTree.children.length; i++) {
        generateNodeList(
          ivrNodes,
          nodeTree.children[i],
          level + 1,
          currentLevel
        );
      }
    } else {
      ivrNodes.push({
        parentLevelId: levelId,
        levelId: currentLevel,
        key: nodeTree.key,
        name: nodeTree.name,
        dtmf: nodeTree.dtmf,
        dtmfValue: nodeTree.dtmfValue,
        nodeType: nodeTree.nodeType,
        apiResponseType: nodeTree.apiResponseType,
        properties: { ...nodeTree.properties },
      });
      loggerFactory.info(
        componentName,
        `Each Element Data Step : ${level} ${currentLevel} ${nodeTree.dtmfValue} ${nodeTree.key} ${nodeTree.name} Parent : ${levelId} Value : ${nodeTree.value}`
      );
    }
  }

  function onOpenForm() {
    setOpenForm(true);
  }

  function onOpenMenu(event, node) {
    setOpenMenu({ event, node });
  }

  function onNavigate(node) {
    handleSubmit();
    setIvrExtension(dispatch, {
      code: node.properties?.extensionForwardTo,
      changedOn: Date.now(),
    });
    console.log(node);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  const [zoom, setZoom] = useState(1);

  const increase = () => {
    setZoom(zoom + 0.1);
  };

  const decrease = () => {
    if (zoom > 0.5) {
      setZoom(zoom - 0.1);
    }
  };

  function onTransferNode(event, node) {
    setReferNodeMenu({ event, node });
  }

  const pageName = "IVR";

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const onSaveForm = (dataToSave) => {
    const cacheData = { ...data };
    updateIVRData(cacheData, dataToSave);
    setData(cacheData);
    closeProperty();
  };

  function updateIVRData(cacheData, dataToSave) {
    if (cacheData.key === dataToSave.id) {
      cacheData["properties"] = dataToSave;
    } else if (cacheData.children && cacheData.children.length > 0) {
      let i;
      for (i = 0; i < cacheData.children.length; i++) {
        updateIVRData(cacheData.children[i], dataToSave);
      }
    }
  }

  function getNodeType(nodeType) {
    switch (nodeType) {
      case "TREE":
        return "Add Tree";
      case "EXT":
        return "Extension";
      case "PF":
        return "Play File";
      case "LTS":
        return "Loop To Step";
      case "API":
        return "User Input";
      default:
        return "";
    }
  }
  return (
    <>
      <Drawer
        style={{ whiteSpace: "break-spaces" }}
        anchor="right"
        open={propertyKey !== ""}
        onClose={() => closeProperty()}
        classes={{ paper: classes.drawerPaper }}
      >
        <MDTypography
          align="center"
          sx={{ marginTop: "10px", borderBottom: 5, borderColor: "divider" }}
        >
          {`IVR Flow ${getNodeType(formType)}`}
        </MDTypography>

        <CTLBaseForm
          enableActionButtons={true}
          entityName="IVR Flow"
          columns={getColumns(formType, propertyKey, data)}
          openForm={true}
          onSaveForm={onSaveForm}
          onCloseForm={null}
          formData={formData}
          formAttributes={getAttributes(formType, propertyKey)}
          dialogSize="full"
        />
      </Drawer>
      {renderSuccessSB}
      {renderErrorSB}
      <Stack direction="column">
        <Card sx={{ backgroundColor: "grey", width: 50, borderRadius: 2 }}>
          <IconButton onClick={increase}>
            <AddIcon color="text"></AddIcon>
          </IconButton>
          <IconButton onClick={decrease}>
            <RemoveIcon color="text" onClick={decrease}></RemoveIcon>
          </IconButton>
        </Card>
      </Stack>

      <MDBox ref={treeContainer} style={{ height: 600 }}>
        <Tree
          key={treeKey}
          data={data}
          entityName="IVR"
          ref={tree}
          translate={translate}
          transitionDuration={0}
          zoom={zoom}
          zoomable={false}
          pathFunc="step"
          orientation="vertical"
          depthFactor="150"
          nodeSize={{ x: 200 }}
          allowForeignObjects
          renderCustomNodeElement={({ nodeDatum, toggleNode }) =>
            TreeNode(
              nodeDatum,
              toggleNode,
              addChild,
              deleteRow,
              shiftNode,
              onOpenForm,
              editProperty,
              onOpenMenu,
              onNavigate,
              onTransferNode
            )
          }
          shouldCollapseNeighborNodes={false}
          linkClass="my-link-class"
        />
      </MDBox>
      <MDButton
        style={{ margin: "auto", display: "block" }}
        onClick={handleSubmitClick}
        color="success"
      >
        Submit
      </MDButton>
      <CTLNotification notify={notify} setNotify={setNotify} />
      {renderMenu()}
      {renderTransferNodeMenu()}
      <DeleteConfirmation
        confirmDelete={deleteKey !== ""}
        onAgree={onAgree}
        onCancel={onCancel}
      />
    </>
  );
}
