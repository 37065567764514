import MDTypography from "components/MDTypography";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { tooltip } from "leaflet";


function CTLAlarmCell({ value, suffix }) {
  if (value === null) {
    suffix = "";
  } else {
    suffix = <tooltip title ={value}><AccessAlarmIcon fontSize="medium" color="success"/></tooltip>;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLAlarmCell.defaultProps = {
  suffix: "",
};

export default CTLAlarmCell;
