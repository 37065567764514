import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DisplayOptionsLegend({
  title,
  categories,
  isDarkMode,
  displayOptions,
  handleDisplayOptionsChange,
}) {
  const renderItems = categories.map(({ optionName, name }, index) => (
    <MDBox
      key={index}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      pr={2}
    >
      <MDBox display="flex" alignItems="center">
        <Checkbox
          name={optionName}
          onChange={(e) => handleDisplayOptionsChange(e)}
          checked={displayOptions[optionName]}
        />
        <MDBox display="flex" flexDirection="column" mt={1}>
          <MDTypography
            variant="button"
            sx={({ palette: { white, dark } }) => ({
              color: isDarkMode ? white.main : dark.main,
            })}
            fontWeight="medium"
            gutterBottom
          >
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card
      sx={{
        position: "absolute",
        right: "1.3em",
        top: "4em",
        zIndex: 2,
      }}
    >
      <MDTypography
        variant="h6"
        fontWeight="medium"
        textTransform="capitalize"
        pt={2}
        px={2}
      >
        {title}
      </MDTypography>
      <MDBox component="ul" display="flex" flexDirection="column" p={1}>
        {renderItems}
      </MDBox>
    </Card>
  );
}

export default DisplayOptionsLegend;
