import AudioPlayAction from "application/components/AudioPlayAction";
import UpdateAsRead from "application/components/UpdateAsRead";
import CTLVoicemailUnReadCell from "application/components/CTLVoicemailUnreadCell";
import CTLForwardVoiceMail from "application/components/CTLForwardVoiceMail";
import CTLVoicemailDateCell from "application/components/CTLVoicemailDataCell";

const Attributes = {
  enableMultipleDeleteButton: false,
  enableMarkAsReadButton: false,
  enableAdvanceSearch: true,
  refetchInterval: 5000,
  modes: [],
};

const operationURLS = {
  list: "voicemail/list",
  update: "voicemail/update",
  delete: "voicemail/delete",
};

const getColumns = (markAsRead, onDelete, onCall, onEdit) => {
  return [
    {
      Header: "Play",
      accessor: "id",
      displayInList: true,
      Cell: ({ row, value }) => <AudioPlayAction row={row} value={value} />,
    },
    {
      Header: "Received On",
      accessor: "createdOn",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ row, value }) => (
        <CTLVoicemailDateCell row={row} value={value} />
      ),
    },
    // {
    //   Header: "Caller Ext",
    //   accessor: "userName",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ row, value }) => <CTLVoicemailUnReadCell row={row} value={value} />,
    // },
    {
      Header: "Sender Extension",
      accessor: "sourceNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <CTLVoicemailUnReadCell row={row} value={value} />
      ),
    },

    {
      Header: "Sender Name",
      accessor: "sourceName",
      displayInList: true,
      allowFilter: false,
      dataType: "date",
      Cell: ({ row, value }) => (
        <CTLVoicemailUnReadCell row={row} value={value} />
      ),
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <CTLVoicemailUnReadCell row={row} value={value} />
      ),
    },
    //  {
    //   Header: "Remarks",
    //   accessor: "remarks",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ value }) => <CTLVoicemailUnReadCell value={value} onCall={onCall} />,
    //  },
    {
      Header: "Forword Voicemail",
      accessor: "rowId",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLForwardVoiceMail row={row} value={value} />,
    },
    //  {
    //   Header: "Send VaoceMail",
    //   accessor: "",
    //   displayInList: true,
    //   Cell: ({ row, value }) => (
    //     <SendVoiceMailByEmail
    //       row={row}
    //       value={value}
    //     />
    //   ),
    // },
    {
      Header: "Action",
      accessor: "readOn",
      displayInList: true,
      Cell: ({ row, value }) => (
        <UpdateAsRead
          row={row}
          value={value}
          markAsRead={markAsRead}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, operationURLS, Attributes };
