import tippy, { roundArrow, inlinePositioning } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/backdrop.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/themes/material.css";

export default function Tooltip(id, nodeDatum) {
  tippy(`#${id}`, {
    content: `<table style="border: none; font-weight: 500;">
                <tr>
                  <td style="color: aqua; margin-right: 1em;">Cateory</td>
                  <td>&nbsp;${nodeDatum.category}</td>
                </tr>
                <tr>
                  <td style="color: aqua;">Name</td>
                  <td>&nbsp;${nodeDatum.name}</td>
                </tr>
                <tr>
                  <td style="color: aqua;">Location</td>
                  <td>&nbsp;${nodeDatum.location}</td>
                </tr>
              </table>`,
    allowHTML: true,
    animation: "shift-away",
    arrow: roundArrow,
    delay: [200, null],
    duration: 300,
    inertia: true,
    inlinePositioning: true,
    plugins: [inlinePositioning],
    theme: "material",
  });
}
