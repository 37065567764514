import { extensionMenus } from "globals/configuration/extension";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: extensionMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
    create: "outboundCos/create",
    update: "outboundCos/update",
    list: "outboundCos/list",
    delete: "outboundCos/delete",
  };

export { Attributes, operationURLS };
