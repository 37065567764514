import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";

export default function CTLIfram({
  tabValue,
  descriptionTab,
  description,
  entityName,
  openIfram,
  handleClose,
  url,
}) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    setOpen(openIfram);
  }, [openIfram]);

  return (
    <MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="100%"
        classes={{ dialog }}
        PaperProps={{
          style: {
            height: "100%",
          },
        }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          {tabValue != null &&
            tabValue !== "" &&
            (tabValue === 0
              ? `RLU:  ${description} : ${descriptionTab}`
              : `Node: ${description} : ${descriptionTab}`)}

          {entityName && <span>{entityName}</span>}
          <IconButton
            color="error"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            overflow: "hidden",
          })}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "hidden",
            }}
            src={url}
          ></iframe>
        </DialogContent>
      </Dialog>
    </MDBox>
  );
}
