const userDefinedWidgetMenus = [
  {
    icon: "show_chart_icon",
    key: "lineChart",
    name: "Line Chart",
    description: "Line-Chart",
    url: "home/app/lineChart",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "bar_chart_icon",
    key: "barChart",
    name: "Bar Chart",
    description: "Bar-Chart",
    url: "home/app/barChart",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "hub_icon",
    key: "Topology",
    name: "Topology",
    description: "Topology",
    url: "home/app/Topology",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "dashboard",
    key: "DashBoard",
    name: "DashBoard",
    description: "DashBoard",
    url: "home/app/DashBoard",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "filter_frames_icon",
    key: "IFrame",
    name: "IFrame",
    description: "IFrame",
    url: "home/app/IFrame",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "calendar_month_icon",
    key: "Calendar",
    name: "Calendar",
    description: "Calendar",
    url: "home/app/Calendar",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "hub_icon",
    key: "Smart Topology",
    name: "Smart Topology",
    description: "Smart-Topology",
    url: "home/app/SmartTopology",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "insights_icon",
    key: "Graph Topology",
    name: "Graph Topology",
    description: "Graph-Topology",
    url: "home/app/GraphTopology",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "smart_display_icon",
    key: "VideoStream",
    name: "VideoStream",
    description: "VideoStream",
    url: "home/app/VideoStream",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "hub_icon",
    key: "TopologyNoLeaf",
    name: "TopologyNoLeaf",
    description: "Topology-No-Leaf",
    url: "home/app/TopologyNoLeaf",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "hub_icon",
    key: "GraphTopologyNoLeaf",
    name: "GraphTopologyNoLeaf",
    description: "GraphTopology-No-Leaf",
    url: "home/app/GraphTopologyNoLeaf",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "speed_icon",
    key: "SpeedoMeter",
    name: "Speedo Meter",
    description: "Speedo-Meter",
    url: "home/app/SpeedoMeter",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "table",
    key: "table",
    name: "Table",
    description: "Table",
    url: "home/app/table",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "bar_chart_icon ",
    key: "pieChart",
    name: "Pie Chart",
    description: "Pie Chart",
    url: "home/app/pie-chart",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "insights_icon",
    key: "doughnutChart",
    name: "Doughnut Chart",
    description: "Doughnut Chart",
    url: "home/app/doughnut-chart",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "table",
    key: "serverPaginatedTable",
    name: "Server Paginated Table",
    description: "Server Paginated Table",
    url: "home/app/server-paginated-table",
    disabled: false,
    productionReady: true,
  },
];

export { userDefinedWidgetMenus };
