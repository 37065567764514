import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { Columns, Attributes } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";

function Alerts({ onLoad, mode, pageId, defaultSortColumn, defaultSortDirection, searchText }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    Attributes && onLoad(Attributes);
  }, [activePage]);

  const deleteRow = () => {
    setConfirmDelete(true);
  }

  const onAgree = () => {
    setConfirmDelete(false);
  }

  const onCancel = () => {
    setConfirmDelete(false);
  }
  return (

    <MDBox>
      <div>{mode}</div>
      <DeleteConfirmation 
        confirmDelete={confirmDelete} 
        onAgree={onAgree} 
        onCancel={onCancel} />

      <CTLTable
        apiURL="/alertDetail/getFilterDataV2" 
        columns={Columns} 
        entriesPerPage={false} 
        canSearch 
        defaultSortColumn="asc"
        defaultSortDirection="alertDate"
        pageId={pageId} 
        searchText={searchText}
        hasRowSelection={false}  />
    </MDBox>

  );
}
export default Alerts;
