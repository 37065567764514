import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { cdrs } from "globals/configuration/cdrs";
import formControl from "globals/form-control";

const Attributes = {
  enableAddButton: true,
  modes: cdrs.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "extGroup/list",
  create: "extGroup/create",
  update: "extGroup/update",
  delete: "extGroup/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Group Code",
      accessor: "groupCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      disabled: "true",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      width: 6,
    },
    {
      Header: "Group Name",
      accessor: "groupName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      width: 6,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
