import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import React, { useEffect } from "react";
import CTLDialogContent from "../styles/CTLDialogContent";
import MDTypography from "components/MDTypography";

function CTLConferenceDelete({ confirmDelete, onAgree, onCancel, eventData }) {
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    setOpen(confirmDelete);
  }, [confirmDelete]);

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Are you sure you want to delete the Conference
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDTypography> {eventData && eventData.roomName}</MDTypography>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={onCancel} color="secondary">
            Disagree
          </MDButton>
          <MDButton onClick={() => onAgree(eventData)} color="error" autoFocus>
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CTLConferenceDelete;
