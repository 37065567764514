import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const nmsQuickMenus = [
  // {
  //   icon: (
  //     <Tooltip title="Menu's Dashboard">
  //       <Icon fontSize="medium">view_carousel</Icon>
  //     </Tooltip>
  //   ),
  //   name: "Menu's Dashboard",
  //   key: "nmsmenu-dashboard",
  //   url: "/app/nmsmenudashboard/home",
  //   description: "NMS Menu Dashboard",
  // },
  {
    icon: (
      <Tooltip title="Default Dashboard">
        <Icon fontSize="medium">dashboard</Icon>
      </Tooltip>
    ),
    name: "Menu's Dashboard",
    key: "nmsmenu-dashboard",
    url: "/app/nmsmenudashboard/home",
    description: "NMS Menu Dashboard",
  },
];

export { nmsQuickMenus };
