import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
    formSize: "lg",
    columns: 1,  
    enableAddButton: false,
    enablePublishButton: false,
    modes: adminMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
          disabled: item.disabled,
          productionReady: item.productionReady,
        };
      }),
    settingsMode: true,
    messages: {
        success: "Updated successfully",
        error: "Unable to update data"
    }
};

const operationURLS = {
    list: "/systemConfig/list",
    update: "/systemConfig/update",
    register: null,
    delete: null,
    publish: null,
};

const getColumns = (onDelete, onEdit) => {
    return [
        {
            Header: "Ring timer",
            accessor: "ringTimer",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure how long a phone will ring before triggering no answer action",
            defaultValue: "30",
            dataUnit: "Seconds"
        },
        {
            Header: "No Answer Forward",
            accessor: "noAnsForwardTimer",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure how long a phone will ring before Forwarding the call to No answer forward number(if configured at extension level)",
            defaultValue: "30",
            dataUnit: "Seconds"
        },
        {
            Header: "Transfer Recall Timer",
            accessor: "transRecallTimer",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure how long a phone will ring on transferred extension, before it is transferred back to the transferrer.",
            defaultValue: "30",
            dataUnit: "Seconds"
        },
        {
            Header: "Park Expiry Interval",
            accessor: "parkExpiryInterval",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure how long a call will be parked before which it is transferred back to the extension wha has parked the call.",
            defaultValue: "300",
            dataUnit: "Seconds"
        },
        {
            Header: "Hold Expiry Interval",
            accessor: "holdExpiryInterval",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure how long a call will be on hold before which is dropped.",
            defaultValue: "300",
            dataUnit: "Seconds"
        },
        {
            Header: "Alarm Ring Timer",
            accessor: "alarmRingTimer",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure how long a call will ring for an alarm alert call before it gets disconnected.",
            defaultValue: "60",
            dataUnit: "Seconds"
        },
        {
            Header: "Three party Conference beep Timer",
            accessor: "tpcBeepInterval",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "integer",
            Cell: ({ value }) => <DefaultCell value={value} />,
            validation: formControl.string().required('Required').digitsOnly(),
            detailedExplanation: "Use this timer to configure the beep interval for 3 party conference to indicate operator presence",
            defaultValue: "30",
            dataUnit: "Seconds"
        }
    ];
};

export { getColumns, Attributes, operationURLS };
