import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "lg",
  columns: 3,
  settingsMode: true,
  enablePriPublish: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),

  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/priFile/list",
  create: "/priFile/create",
  pripublish: "/priFile/publish",
  publish: "/priFile/publish",
};

const getColumns = () => {
  return [
    {
      Header: "Card",
      accessor: "card",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      controlType: "select",
      dataType: "string",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "AKOM" }],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Card Value",
    },
    {
      Header: "Modes",
      accessor: "mode",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      controlType: "select",
      dataType: "string",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "MASTER" }, { description: "SLAVE" }],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Mode Value",
    },
    // {
    //   Header: "Incoming Call Routing",
    //   accessor: "incomingCallRouting",
    //   displayInList: true,
    //   allowFilter: true,
    //   allowInForm: true,
    //   displayInDeleteConfirmation: true,
    //   dataType: "string",
    //   validation: formControl.string().required("Required"),
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    //   detailedExplanation:
    //     " Use this configuration to configure Incoming Call Routing",
    // },
    // {
    //   Header: "Outgoing Call Routing",
    //   accessor: "outgoingCallRouting",
    //   displayInList: true,
    //   allowFilter: true,
    //   allowInForm: true,
    //   displayInDeleteConfirmation: true,
    //   dataType: "string",
    //   validation: formControl.string().required("Required"),
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    //   detailedExplanation:
    //     " Use this configuration to configure Outgoing Call Routing",
    // },
    {
      Header: "Trunk Gateway IP",
      accessor: "trunkGatewayIp",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().required("Required").ipv4(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        " Use this configuration to configure Trunk Gateway IP",
    },
  ];
};

export { getColumns, Attributes, operationURLS };
