import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

function SaveConfirmation({
  openSaveConfirmation,
  handleSaveConfirmationClose,
  setIsFormOpen,
  handleSaveChanges,
}) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  return (
    <Dialog
      maxWidth="xl"
      open={openSaveConfirmation}
      onClose={handleSaveConfirmationClose}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        Confirm changes
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <DialogContentText>
          You have unsaved changes. Do you want to save?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <MDButton
          variant="gradient"
          color="success"
          onClick={handleSaveChanges}
        >
          Yes
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          onClick={() => {
            handleSaveConfirmationClose();
            setIsFormOpen(false);
          }}
        >
          No
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default SaveConfirmation;
