import { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Draggable } from "@hello-pangea/dnd";
import MDTypography from "components/MDTypography";
import { SMART_TOPOLOGY_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import Dropdown from "../../Dropdown";
import RenderSmartTopology from "./RenderSmartTopology";
import ContextMenu from "../ContextMenu";
import { useGetTopologyDataQuery, useGetDataQuery } from "features/apiSlice";
import topologyData from "../../data/topologyData";
import nodeCount from "../../utils/nodeCount";
import { Backdrop, CircularProgress } from "@mui/material";
import UploadImageFile from "./UploadImageFile";
import { useUploadFileMutation } from "features/apiSlice";
import SeverityContext from "../../context/severityContext";

function SmartTopology({
  widget,
  cellWidgetInfo,
  index,
  containerType,
  widgetHeight,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let refreshInterval = null;
  let getCategoryDataURL = null;
  let threshold = 50;
  if (widget) {
    getDataURL = widget.getDataURL;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
    getCategoryDataURL = process.env.REACT_APP_CATEGORY_LIST_ENDPOINT;
    threshold = widget.thresholdLower || widget.thresholdUpper || 50;
  }

  const smartTopologyQuery = getDataURL
    ? useGetTopologyDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const categoryListQuery = getCategoryDataURL
    ? useGetDataQuery(getCategoryDataURL)
    : {};

  const severityListQuery = useContext(SeverityContext);

  const [contextMenu, setContextMenu] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [parentRefCurrent, setParentRefCurrent] = useState(null);
  const [uploadFile] = useUploadFileMutation();
  const [isUploading, setIsUploading] = useState(false);

  const handleImageFileChange = (e) => {
    setIsUploading(true);
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", cellWidgetInfo.id);

    uploadFile(formData)
      .unwrap()
      .then((res) => {
        setIsUploading(false);
        console.log(res.messageDetail);
      })
      .catch((err) => {
        setIsUploading(false);
        console.log(err);
      });
  };

  const handleParentRefCurrent = (current) => setParentRefCurrent(current);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let secondaryLabel = null;
  let data = null;
  let descendantsData = null;
  let categoryData = null;
  let severityData = null;
  let error = null;
  let imageName = null;
  let uploadImageFile = null;
  if (widget) {
    if (smartTopologyQuery.data && categoryListQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      data = smartTopologyQuery.data;
      descendantsData = nodeCount(smartTopologyQuery.data);
      categoryData = categoryListQuery.data;
      severityData = severityListQuery.data ? severityListQuery.data : [];
      imageName = widget.imageName;
      uploadImageFile = (
        <UploadImageFile handleImageFileChange={handleImageFileChange} />
      );
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      data = [];
      descendantsData = {};
      categoryData = [];
      severityData = [];
      imageName = null;
      uploadImageFile = null;
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    data = topologyData;
    descendantsData = nodeCount(topologyData);
    categoryData = [];
    severityData = [];
    imageName = "SmartTopologyMap.png";
    uploadImageFile = null;
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
  }

  const smartTopologyContainer = (provided = { dragHandleProps: {} }) => (
    <MDBox p={2} position="relative">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
        {...provided.dragHandleProps}
      >
        <Icon fontSize="small">hub</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {isUploading && <MDBox sx={{ marginLeft: "auto" }}>
          <CircularProgress color="white" size={20} />
        </MDBox>}
        {isEditMode && !isUploading && uploadImageFile}
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={SMART_TOPOLOGY_DROPDOWN_LIST}
          />
        )}
      </MDBox>
      {Object.keys(descendantsData).length > 0 && imageName && (
        <MDBox id="parent">
          <RenderSmartTopology
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            data={data}
            descendantsData={descendantsData}
            categoryData={categoryData}
            severityData={severityData}
            threshold={threshold}
            setIsSaving={setIsSaving}
            smartTopologyQuery={smartTopologyQuery}
            parentRefCurrent={parentRefCurrent}
            handleParentRefCurrent={handleParentRefCurrent}
            imageName={imageName}
          />
          <Backdrop
            open={isSaving}
            sx={{ color: "#ccc", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress disableShrink color="white" />
          </Backdrop>
        </MDBox>
      )}
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
          }}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {smartTopologyContainer(provided)}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
        cursor: "context-menu",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {smartTopologyContainer()}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default SmartTopology;
