import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDBox from "components/MDBox";

function CustomDatePicker({ startDate, endDate, setStartDate, setEndDate }) {
  return (
    <MDBox display="flex" ml="auto" mt={1}>
      <MDBox>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            openTo="day"
            views={["day", "month", "year"]}
            disabled
            label="from"
            value={dayjs(startDate)}
            onChange={() => {}}
            renderInput={(params) => <TextField size="small" {...params} helperText={null} />}
          />
        </LocalizationProvider>
      </MDBox>
      <MDBox ml={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            openTo="day"
            views={["day", "month", "year"]}
            label="to"
            value={dayjs(endDate)}
            onChange={(newValue) => {
              if (newValue && newValue.$D && newValue.$M && newValue.$y) {
                setStartDate(`${newValue.$y - 1}-01-01`);
                setEndDate(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
              }
            }}
            renderInput={(params) => <TextField size="small" {...params} helperText={null} />}
          />
        </LocalizationProvider>
      </MDBox>
    </MDBox>
  );
}

export default CustomDatePicker;
