import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import styles from "../css/Topology.module.css";

function Zoom({ zoomIn, zoomOut }) {
  return (
    <MDBox position="absolute" right="50%" bottom="-7%" display="flex">
      <button type="button" onClick={zoomIn} className={`${styles.btn} ${styles.add}`}>
        <Icon fontSize="small">add</Icon>
      </button>
      <button type="button" onClick={zoomOut} className={`${styles.btn} ${styles.minus}`}>
        <Icon fontSize="small">remove</Icon>
      </button>
    </MDBox>
  );
}

export default Zoom;
