import { useEffect, useState , useRef} from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLSendMessage from "application/components/CTLSendMessage";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import CTLNotification from "application/components/CTLNotification";

function BroadcastsMessage({ onLoad, pageId, searchText, registerCallBacks,pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const { darkMode } = controller;
  //notifications
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const selectedRowsRef = useRef([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const [openMessageForm, setOpenMessageForm] = useState(false); 

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onSendMessage: onOpenMessageBox,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

 

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = () => {
    setSelectedRow([]);
    setConfirmDelete(true);
  };

  const onAgree = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onSelectionChange = (ids) => {
    setSelectedRows(ids);
  
  };

   useEffect(() => {
     selectedRowsRef.current = selectedRows;
   }, [selectedRows]);

   function onOpenMessageBox() {
     if ( selectedRowsRef.current.length !== 0) {
       setOpenMessageForm(true);
     } else {
       setNotify({
         isOpen: true,
         message: "Error",
         status: "Please select a user to send Broadcasts Message",
         type: "error",
         pagename: pageName,
       });
     }
  }

  function onCloseMessageBox() {
    setOpenMessageForm(false);
  }

  const onSendClick = (data) => {
    application
      .post(operationURLS.messageSend, {
        destinationUser: selectedRows,
        message: data,
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
        setSelectedRows([]); 
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.status,
          message: response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
    onCloseMessageBox();
  };  

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Delivered"
      content="Message Sent Successfully"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );


  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Undelivered"
      content="Unable To Send. Contact Administrator"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <MDBox mt={4}>
      <DeleteConfirmation
        entityName="BroadcastsMessage"
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLAdvancedFilter
        entityName="BroadcastsMessage"
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLSendMessage
        onSendClick={onSendClick}
        onSendMessage={openMessageForm}
        onCloseMessageBox={onCloseMessageBox}
      />

      <CTLTable
        onSelectionChange={onSelectionChange}
        apiURL="customerAccount/list"
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={true}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
      />
       {renderSuccessSB}
        {renderErrorSB}
        <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default BroadcastsMessage;
