import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Collapse } from "@mui/material";

function DisplayOptionsLegend({
  title,
  categories,
  isDarkMode,
  displayOptions,
  handleDisplayOptionsChange,
  showLegends,
  layout,
}) {
  const renderItems = categories.map(({ optionName, name }, index) => (
    <MDBox
      key={index}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      pr={2}
    >
      <MDBox display="flex" alignItems="center">
        <Checkbox
          name={optionName}
          onChange={(e) => handleDisplayOptionsChange(e)}
          checked={displayOptions[optionName]}
        />
        <MDBox display="flex" flexDirection="column" mt={1}>
          <MDTypography
            variant="button"
            sx={({ palette: { white, dark } }) => ({
              color: isDarkMode ? white.main : dark.main,
            })}
            fontWeight="medium"
            gutterBottom
          >
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  ));

  return (
    <Collapse orientation="horizontal" in={showLegends}>
      <Card
        sx={{
          position: "absolute",
          right: layout === "preset" ? "1.1em" : ".9em",
          top: layout === "preset" ? "5.8em" : "5em",
          zIndex: 2,
        }}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          pt={2}
          px={2}
        >
          {title}
        </MDTypography>
        <MDBox component="ul" display="flex" flexDirection="column" p={1}>
          {renderItems}
        </MDBox>
      </Card>
    </Collapse>
  );
}

export default DisplayOptionsLegend;
