import { useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import { Draggable } from "@hello-pangea/dnd";
import { useMaterialUIController } from "context";
import "./css/index.css";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetDataQuery } from "features/apiSlice";
import Dropdown from "../../../Dropdown";
import { tableData, dummyColumns } from "../../../data/tableData";
import Filter from "./Filter";
import getColumnsConfig from "./configs/columnsConfig";
import getColumnsDetails from "./configs/columnsDetails";
import getFilteredItems from "./configs/filterItems";
import tableActionHandle from "../../../utils/tableActionHandle";
import PopUp from "../../../PopUp";
import { TABLE_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import SeverityContext from "../../../context/severityContext";
import getTableWidgetDynamicColumns from "./configs/tableWidgetDynamicColumns";
import IconButton from "@mui/material/IconButton";

function ServerTable({
  widgetHeight,
  widget,
  cellWidgetInfo,
  index,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  containerType,
  handleBreadcrumbs,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let refreshInterval = 0;
  let actions = [];
  let columns = dummyColumns;
  if (widget) {
    getDataURL = widget.getDataURL;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
    actions = widget.actions ? widget.actions : [];
    columns = widget.columns
      ? getTableWidgetDynamicColumns(widget.columns)
      : [];
  }

  const columnsDetails = getColumnsDetails(columns);
  const assetColumns = getColumnsConfig(columns);

  const serverTableQuery = getDataURL
    ? useGetDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const severityListQuery = useContext(SeverityContext);

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpDashboardId, setPopUpDashboardId] = useState(null);
  const [isTableCollapsed, setIsTableCollapsed] = useState(false);
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem("tableState-serverTableWidget")) || {},
  );
  const darkModeContext = useMaterialUIController();

  useEffect(() => {
    localStorage.setItem(
      "tableState-serverTableWidget",
      JSON.stringify(tableState),
    );
  }, [tableState]);

  let serverTableData = null;
  if (serverTableQuery.data && severityListQuery.data)
    serverTableData = serverTableQuery.data.map((asset) => {
      const newTransformedAsset = {};
      Object.keys(asset).forEach((assetProp) => {
        if (columnsDetails[assetProp] && columnsDetails[assetProp].display) {
          if (assetProp === "status")
            newTransformedAsset[assetProp] = asset[assetProp] ? "Up" : "Down";
          else if (columnsDetails[assetProp].type === "epoch") {
            const date = new Date(asset[assetProp] * 1000);
            newTransformedAsset[assetProp] = date.toLocaleString("en-IN");
          } else if (columnsDetails[assetProp].type === "severity") {
            const targetNodeSeverity = asset[assetProp];
            const severityObj = severityListQuery.data.filter(
              (severity) => severity.severityLevel === targetNodeSeverity,
            )[0];

            if (!severityObj)
              console.log(
                `No configuration found for severity level ${targetNodeSeverity}`,
              );

            const severityColor = severityObj.color;
            newTransformedAsset[assetProp] = (
              <p
                style={{
                  backgroundColor: severityColor,
                  color: "#000",
                  padding: ".5em",
                  fontWeight: "500",
                  borderRadius: ".5em",
                }}
              >
                {severityObj.severityName}
              </p>
            );
            newTransformedAsset.severityText = severityObj.severityName;
          } else newTransformedAsset[assetProp] = asset[assetProp];
        }
      });

      return newTransformedAsset;
    });
  else serverTableData = tableData;

  let primaryLabel = null;
  let data = null;
  let dropdownOptions = [];
  let error = null;
  if (widget) {
    if (serverTableQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      data = serverTableData;
      if (isAdmin)
        dropdownOptions = isEditMode
          ? TABLE_DROPDOWN_LIST
          : TABLE_DROPDOWN_LIST.slice(0, -1);
      else dropdownOptions = TABLE_DROPDOWN_LIST.slice(0, -1);
    } else {
      primaryLabel = "Unable to update the widget";
      data = [];
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
      if (isAdmin)
        dropdownOptions = isEditMode
          ? TABLE_DROPDOWN_LIST.slice(TABLE_DROPDOWN_LIST.length - 1)
          : [];
      else dropdownOptions = [];
    }
  } else {
    primaryLabel = "Widget ID not configured";
    data = serverTableData;
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
    if (isAdmin)
      dropdownOptions = isEditMode
        ? TABLE_DROPDOWN_LIST
        : TABLE_DROPDOWN_LIST.slice(0, -1);
    else dropdownOptions = TABLE_DROPDOWN_LIST.slice(0, -1);
  }

  // Creating custom dark theme
  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark",
  );

  // Filter feature for the ServerTable
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = getFilteredItems(data, columnsDetails, filterText);

  // dropdown feature for the ServerTable
  const header = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <MDBox display="flex">
        <Filter
          onFilter={(e) => setFilterText(() => e.target.value.toLowerCase())}
          onClear={handleClear}
          filterText={filterText}
        />
        {error}
        {dropdownOptions.length > 0 && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={dropdownOptions}
            filteredItems={filteredItems}
            assetColumns={assetColumns}
            primaryLabel={primaryLabel}
          />
        )}
      </MDBox>
    );
  }, [filteredItems, filterText, resetPaginationToggle]);

  // Generating unique ID for draggable widget
  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  const handleToggleCollapse = () => setIsTableCollapsed((prev) => !prev);

  const dataTable = useMemo(
    () => (
      <>
        <DataTable
          data={filteredItems}
          columns={assetColumns}
          onRowClicked={(row) =>
            tableActionHandle(
              row,
              actions,
              setPopUpOpen,
              setPopUpDashboardId,
              handleBreadcrumbs,
            )
          }
          onRowDoubleClicked={(row) =>
            tableActionHandle(
              row,
              actions,
              setPopUpOpen,
              setPopUpDashboardId,
              handleBreadcrumbs,
            )
          }
          responsive
          pagination
          paginationPerPage={tableState.currentRowsPerPage || 10}
          striped
          theme={darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"}
          title={
            <MDBox display="flex" alignItems="center" ml={-2}>
              <IconButton onClick={handleToggleCollapse}>
                <Icon
                  sx={{ color: darkModeContext[0].darkMode ? "#FFF" : "#000" }}
                >
                  {isTableCollapsed ? "expand_more" : "expand_less"}
                </Icon>
              </IconButton>
              <MDTypography variant="h6">{primaryLabel}</MDTypography>
            </MDBox>
          }
          defaultSortFieldId="id"
          paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
          fixedHeader
          fixedHeaderScrollHeight={widgetHeight || "300px"}
          actions={isTableCollapsed ? null : header}
          persistTableHead
          onChangeRowsPerPage={(currentRowsPerPage) =>
            setTableState({ ...tableState, currentRowsPerPage })
          }
          customStyles={{
            table: {
              style: {
                display: isTableCollapsed ? "none" : "block",
              },
            },
            pagination: {
              style: {
                display: isTableCollapsed ? "none" : "flex",
              },
            },
          }}
        />
        {widget && (
          <PopUp
            open={popUpOpen}
            isAdmin={isAdmin}
            handleClose={() => setPopUpOpen(false)}
            dashboardId={popUpDashboardId}
          />
        )}
      </>
    ),
    [serverTableQuery.data, filterText, popUpOpen, isTableCollapsed],
  );

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            margin: ".5em",
            flexGrow: "1",
            flexBasis: "0",
            padding: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {dataTable}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        margin: ".5em",
        flexGrow: "1",
        flexBasis: "0",
        padding: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
      }}
    >
      {dataTable}
    </Card>
  );
}

export default ServerTable;
