import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DataTable from "features/Tables/DataTable";

// Data
import TableDefinition from "./table-definition";

function Files() {
  return (
    
      <MDBox my={3}>
        <Card>
          <DataTable table={TableDefinition} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
  );
}

export default Files;
