import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";
import formControl from "globals/form-control";

const AgentUpdateAttributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  settingsMode: false,
  enableAddUpdateButton: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const AgentUpdateOperationURLS = {
  agentUpdate: "/agent/update",
};

const getAgentUpdateColumns = (onEdit,onDelete) => {
  return [
    {
      Header: "Agent From",
      accessor: "from",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent To",
      accessor: "to",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent/User ID",
      accessor: "userName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header:"Agent/User Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().emailOnly().nullable(),
    },
    {
      Header: "Phone Number",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Position",
      accessor: "position",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue",
      accessor: "queue",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getAgentUpdateColumns, AgentUpdateAttributes, AgentUpdateOperationURLS };
