export default function getSpeedometerNeedleColor(
  needleValue,
  min,
  thresholdLower,
  thresholdUpper,
) {
  let needleColor = null;

  if (needleValue >= min && needleValue <= thresholdLower)
    needleColor = "#4CAF50";
  else if (needleValue > thresholdLower && needleValue <= thresholdUpper)
    needleColor = "#fb8c00";
  else needleColor = "#e91e63";

  return needleColor;
}
