import DefaultCell from "application/components/DefaultCell";

export default [
    {
        Header: "Description",
        accessor: "description",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation: "Description",
        defaultValue: "",
        dataUnit: "",
        tabId: 0,
    },
    {
        Header: "Reference Code",
        accessor: "code",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation:
            "Unique code defined by user to create link from some other step",
        defaultValue: "",
        dataUnit: "",
        tabId: 0,
    },
    {
        Header: "Forward To",
        accessor: "extensionForwardTo",
        displayInList: true,
        allowFilter: true,
        allowInForm: true,
        displayInDeleteConfirmation: true,
        dataType: "String",
        Cell: ({ value }) => <DefaultCell value={value} />,
        detailedExplanation: "Forward to number",
        defaultValue: "",
        dataUnit: "Extension",
        tabId: 0,
    },
]