import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import AuthenticationService from "application/modules/authentication/AuthenticationService";

function TelephoneCell({ value, onCall }) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value && AuthenticationService.getExtensionNumber() != value && <IconButton color="info" onClick={() => onCall && onCall(value)}>
        <CallIcon></CallIcon>
      </IconButton>
      }
      {value}
    </MDTypography>
  );
}

TelephoneCell.defaultProps = {
  suffix: "",
};

export default TelephoneCell;
