import { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import jsPDF from "jspdf";
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Stack } from "@mui/system";

const operationURLS = {
  cdrReport: "hms/cdr",
};

const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
};

const cellStyle = {
  border: ".5px solid black",
  padding: "2px",
  textAlign: "left",
};

export default function CdrReport({
  row,
  openCdr,
  onCloseCdr,
  formData,
  entityDiscription,
  entityName,
}) {
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [cdrList, setCdrList] = useState([]);

  const CdrList = () => {
    application
      .post(operationURLS.cdrReport, {
        billingUuid: formData.uuid,
      })
      .then((response) => {
        setCdrList(response.data.data);
      })
      .catch((error) => {
           console.log("unable to load cdr list data : ",error )
      });
  };

  useEffect(() => {
    CdrList();
    setOpen(openCdr);
  }, [openCdr]);

  const handleClickClose = () => {
    setOpen(onCloseCdr);
  };

  const handleDownload = () => {
    const rows = cdrList;
    const headers = [
      { label: "ID", key: "id" },
      { label: "Uuid", key: "uuid" },
      { label: "CallerIdNumber", key: "callerIdNumber" },
      { label: "CallerIdName", key: "callerIdName" },
      { label: "DidNumber", key: "didNumber" },
      { label: "DodNumber", key: "dodNumber" },
      { label: "Direction", key: "direction" },
      { label: "DestinationNumber", key: "destinationNumber" },
      { label: "StartStamp", key: "startStamp" },
      { label: "AnswerStamp", key: "answerStamp" },
      { label: "EndStamp", key: "endStamp" },
      { label: "BillSec", key: "billSec" },
      { label: "Duration", key: "duration" },
      { label: "CallType", key: "callType" },
      { label: "Pulse", key: "pulse" },
      { label: "CallCost", key: "callCost" },
      { label: "BillingUuid", key: "billingUuid" },
    ];

    const csvData = [
      headers.map((h) => h.label).join(","),
      ...rows.map((row) => headers.map((h) => row[h.key]).join(",")),
    ].join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "cdr-report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Dialog open={openCdr} maxWidth="xl" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          {entityName}:{entityDiscription}
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <table style={tableStyle}>
            <tr>
              <th style={cellStyle}>CallerIdNumber</th>
              <th style={cellStyle}>CallerIdName</th>
              <th style={cellStyle}>DidNumber</th>
              <th style={cellStyle}>DodNumber</th>
              <th style={cellStyle}>Direction</th>
              <th style={cellStyle}> DestinationNumber</th>
              <th style={cellStyle}>StartStamp</th>
              <th style={cellStyle}>AnswerStamp</th>
              <th style={cellStyle}>EndStamp</th>
              <th style={cellStyle}>Duration</th>
              <th style={cellStyle}>BillSec</th>
              <th style={cellStyle}>CallType</th>
              <th style={cellStyle}>Pulse</th>
              <th style={cellStyle}>CallCost</th>
            </tr>
            <tbody>
              {cdrList.map((item) => {
                return (
                  <tr>
                    <td style={cellStyle}>{item.callerIdNumber}</td>
                    <td style={cellStyle}>{item.callerIdName}</td>
                    <td style={cellStyle}>{item.didNumber}</td>
                    <td style={cellStyle}>{item.dodNumber}</td>
                    <td style={cellStyle}>{item.direction}</td>
                    <td style={cellStyle}> {item.destinationNumber}</td>
                    <td style={cellStyle}>{item.startStamp}</td>
                    <td style={cellStyle}> {item.answerStamp}</td>
                    <td style={cellStyle}>{item.endStamp}</td>
                    <td style={cellStyle}>{item.duration}</td>
                    <td style={cellStyle}>{item.billSec}</td>
                    <td style={cellStyle}>{item.callType}</td>
                    <td style={cellStyle}>{item.pulse}</td>
                    <td style={cellStyle}>{item.callCost}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <Stack direction="row" spacing={2}>
            <MDButton onClick={handleClickClose} color="secondary">
              Cancel
            </MDButton>
            <tooltip title="CSV File">
              <MDButton color="success" autoFocus onClick={handleDownload}>
                <FileDownloadIcon />
              </MDButton>
            </tooltip>
          </Stack>
          {/* <tooltip title="PDF File">
          <MDButton color="success" autoFocus onClick={handlePdfDownload}>
            <PictureAsPdfIcon />
          </MDButton>
          </tooltip> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
