import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

function Confirmation({
  confirmationType,
  openConfirmationDialog,
  handleUndoLayout,
  handleConfirmationDialogClose,
}) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  return (
    <Dialog
      maxWidth="xl"
      open={openConfirmationDialog}
      onClose={handleConfirmationDialogClose}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        {confirmationType === "undo" ? "Undo" : "Reset"}
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <DialogContentText>
          Do you want to {confirmationType === "undo" ? "Undo" : "Reset"}{" "}
          changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.light.main,
        })}
      >
        <MDButton variant="gradient" color="success" onClick={handleUndoLayout}>
          Yes
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          onClick={handleConfirmationDialogClose}
        >
          No
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Confirmation;
