function Target({ widget, data }) {
  // This function component acts as a wrapper to all the custom widget components
  // that can be called via a switch statement based on the custom widget type.
  // The wrapper receives the widget configuration and the widget data
  // that can be used for rendering the custom widget.
  // Below is an example of how custom widget components can be invoked:
  //   switch (widget.widgetType) {
  //     case "abc":
  //       return <Comp1 data={data} widget={widget} />;
  //     case "def":
  //       return <Comp2 data={data} widget={widget} />;
  //     case "ghi":
  //       return <Comp3 data={data} widget={widget} />;
  //     .
  //     .
  //     .
  //     default:
  //       return null;
  //   }
}

export default Target;
