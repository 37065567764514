import { useState } from "react";
import Tree from "react-d3-tree";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import nodeCount from "../../../utils/nodeCount";
import Legend from "../Legend";
import Dropdown from "../../../Dropdown";
import renderSvgNode from "../renderSvgNode";
import Zoom from "../Zoom";
import { NETWORK_TOPOLOGY_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
// import * as workerScript from "../worker";

function RenderTopology({
  isEditMode,
  isAdmin,
  primaryLabel,
  secondaryLabel,
  data,
  categoryData,
  threshold,
  isDarkMode,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  index,
  translate,
  error,
}) {
  const descendantsData = nodeCount(data);

  const renderedNode = renderSvgNode(
    descendantsData,
    isDarkMode,
    categoryData,
    threshold,
    isAdmin,
  );

  const [zoomLevel, setZoomLevel] = useState(0.5);
  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(1, prevZoomLevel + 0.1));
  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(0.1, prevZoomLevel - 0.1));

  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      // Target node has no children -> this link leads to a leaf node.
      return isDarkMode ? "link__to-leaf-dark" : "link__to-leaf-light";
    }

    // Style it as a link connecting two branch nodes by default.
    return isDarkMode ? "link__to-branch-dark" : "link__to-branch-light";
  };

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" width="100%">
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={NETWORK_TOPOLOGY_DROPDOWN_LIST}
          />
        )}
      </MDBox>
      {data.length > 0 && <Zoom zoomIn={zoomIn} zoomOut={zoomOut} />}
      {data.length > 0 && (
        <Legend
          title="Status threshold"
          isDarkMode={isDarkMode}
          categories={[
            {
              color: "#e91e63",
              name: "All Inactive",
            },
            {
              color: "#FFA500",
              name: `1-${threshold}% Active`,
            },
            {
              color: "#FFFF00",
              name: `${threshold + 1}-99% Active`,
            },
            {
              color: "#4CAF50",
              name: "All Active",
            },
          ]}
        />
      )}
      {data.length > 0 && (
        <Tree
          data={data[0]}
          translate={translate}
          transitionDuration={0}
          zoom={zoomLevel}
          separation={{ nonSiblings: 1.5, siblings: 1.2 }}
          depthFactor={
            data[0].children.length * 100 < 500
              ? "300"
              : data[0].children.length * 50
          }
          renderCustomNodeElement={renderedNode}
          shouldCollapseNeighborNodes
          pathClassFunc={getDynamicPathClass}
          linkClass="my-link-class"
        />
      )}
    </>
  );
}

export default RenderTopology;
