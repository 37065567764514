// Recursive algorithm for computing active and inactive child nodes for a specific node in the topology tree.
const map = {};
function mapUpdate(root, path, parentLocations) {
  const rootObj = {
    ...root,
    active: 0,
    inactive: 0,
    status: root.status,
    severity: root.severity,
    path: path,
    parentLocations: parentLocations,
    children: null,
  };

  root.children.forEach((node) => {
    const nodeObj = mapUpdate(
      node,
      [...path, node.id],
      [...parentLocations, node.name],
    );

    if (nodeObj.summarize) {
      rootObj.active += nodeObj.active;
      rootObj.inactive += nodeObj.inactive;
    }

    rootObj.severity = Math.max(rootObj.severity, nodeObj.severity);
  });

  if (root.category !== "Location") {
    rootObj.active += root.status;
    rootObj.inactive += !root.status;
  }

  map[root.id.toString()] = rootObj;

  return rootObj;
}

function nodeCount(data) {
  data.forEach((node) => {
    mapUpdate(node, [node.id], [node.name]);
  });
  return map;
}

export default nodeCount;
