import React, {useState} from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

export default function CTLColorPicker ( {getValues , formik , column}){

     const [displayColorPicker, setDisplayColorPicker] = useState(false);
     const [color, setColor] = useState({
       r: "241",
       g: "112",
       b: "19",
       a: "1",
     });

     const handleClick = () => {
       setDisplayColorPicker(!displayColorPicker);
     };

     const handleClose = () => {
       setDisplayColorPicker(false);
     };

     const handleChange = (color) => {
       setColor(color.rgb);
       formik.handleChange({
         target: { name: `field_${column.accessor}`, value: color.hex },
       });
     };

     const styles = reactCSS({
       default: {
         color: {
           width: "80px",
           height: "20px",
           borderRadius: "2px",
           background: `${getValues(column.accessor)}`,
         },
         swatch: {
           padding: "2px",
           background: "#fff",
           borderRadius: "1px",
           boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
           display: "inline-block",
           cursor: "pointer",
         },
         popover: {
           position: "absolute",
           xIndex: "2",
           left: "40%",
           top: "0px",
         },
       },
     });
    return (
      <>
        <div>
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>
          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleClose} />
              <SketchPicker
                color={getValues(column.accessor)}
                onChange={handleChange}
              />
            </div>
          ) : null}
        </div>
      </>
    );
}