import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ResponsiveCalendar } from "@nivo/calendar";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Draggable } from "@hello-pangea/dnd";
import { useMaterialUIController } from "context";
import { useGetDataQuery } from "features/apiSlice";
import Dropdown from "../../Dropdown";
import calendarData from "../../data/calendarData";
import { CALENDAR_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import DatePicker from "../../CustomDatePicker";
import ContextMenu from "../ContextMenu";

function Calendar({
  widgetHeight,
  widget,
  cellWidgetInfo,
  index,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  containerType,
  isEditMode,
  isAdmin,
}) {
  const currentDate = new Date();
  const year = currentDate.getFullYear().toString();
  const month = `0${currentDate.getMonth() + 1}`.slice(-2);
  const day = `0${currentDate.getDate()}`.slice(-2);
  const formattedDate = `${year}-${month}-${day}`;

  const [startDate, setStartDate] = useState(`${year - 1}-01-01`);
  const [endDate, setEndDate] = useState(formattedDate);
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  let getDataURL = null;
  let refreshInterval = 0;
  if (widget) {
    getDataURL = `${widget.getDataURL}/${startDate}/${endDate}`;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
  }

  const calendarQuery = getDataURL
    ? useGetDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const draggableId = `${cellWidgetInfo.widgetType}/////${cellWidgetInfo.id}/////${uuidv4()}`;

  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  let primaryLabel = null;
  let secondaryLabel = null;
  let data = null;
  let error = null;
  if (widget) {
    if (calendarQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      data = calendarQuery.data;
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      data = "";
      error = (
        <Icon sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    data = calendarData;
    error = (
      <Icon sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  const renderChart = (
    <MDBox p={2} position="relative" height={data && parseInt(widgetHeight, 10) * 12}>
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">appsoutage</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={CALENDAR_DROPDOWN_LIST}
          />
        )}
      </MDBox>
      {data && (
        <ResponsiveCalendar
          theme={{ textColor: isDarkMode ? "#FFF" : "#000" }}
          data={data}
          from={startDate.substring(0, 4)}
          to={endDate.substring(0, 4)}
          emptyColor={isDarkMode ? "#202940" : "#eeeeee"}
          colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
          monthBorderColor="#ffffff"
          margin={{
            top: 30,
            right: 60,
            bottom: 20,
            left: 60,
          }}
          height={parseInt(widgetHeight, 10) * 9}
          yearSpacing={30}
          monthSpacing={10}
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              translateY: -100,
              translateX: -70,
              itemCount: 4,
              itemWidth: 40,
              itemHeight: 30,
              itemDirection: "left-to-right",
            },
          ]}
        />
      )}
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderChart}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
        cursor: "context-menu",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {renderChart}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Calendar;
