import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import PlayAction from "application/components/PlayAction";


const Attributes = {
  enableAddButton: true,
  modes: [
    
  ],
};

const getColumns = (onDelete) =>{ 
return[
  {
    Header: " ",
    accessor: "id",
    displayInList: true,
    Cell: ({ row, value }) => <PlayAction row={row} value={value} />,
    displayInList: true,
  },
  {
    Header: "Direction",
    accessor: "direction",
    displayInList: true,
    allowFilter:false,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Caller Name",
    accessor: "callerIdName",
    displayInList: true,
    allowFilter:false,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Caller Number",
    accessor: "callerIdNumber",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Destination Number",
    accessor: "destinationNumber",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Destination Name",
    accessor: "destinationName",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Start Time",
    accessor: "startStamp",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Answer Time",
    accessor: "answerStamp",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "End Time",
    accessor: "endStamp",
    displayInList: true,
    allowFilter:false,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Talk Time",
    accessor: "billSec",
    displayInList: true,
    allowFilter:false,
    dataType: "string",
    displayInList: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
];
}


export {getColumns, Attributes  };
