import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Collapse } from "@mui/material";

function Legend({
  title,
  isDarkMode,
  colorFilter,
  handleColorFilterChange,
  level,
  showLegends,
  categories,
}) {
  const renderItems = categories.map(({ color, colorName, name }) => (
    <MDBox
      key={name}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      pr={2}
    >
      <MDBox display="flex" alignItems="center">
        {level === 1 && (
          <Checkbox
            name={colorName}
            onChange={(e) => handleColorFilterChange(e)}
            checked={colorFilter[colorName]}
          />
        )}
        <MDBox
          display="grid"
          alignItems="center"
          justifyContent="center"
          borderRadius="sm"
          shadow="md"
          color="white"
          width="1.2rem"
          height="1.2rem"
          mr={2}
          variant="gradient"
          fontSize="0.875rem"
          sx={{ backgroundColor: color }}
        />
        <MDBox display="flex" flexDirection="column" mt={1}>
          <MDTypography
            variant="button"
            sx={({ palette: { white, dark } }) => ({
              color: isDarkMode ? white.main : dark.main,
            })}
            fontWeight="medium"
            gutterBottom
          >
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  ));

  return (
    <Collapse orientation="horizontal" in={showLegends}>
      <Card
        sx={{
          position: "absolute",
          right: level === 1 ? ".9em" : "1.7em",
          bottom: level === 1 ? "-2em" : "4.5em",
          zIndex: 2,
        }}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          pt={2}
          px={2}
        >
          {title}
        </MDTypography>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          px={level === 1 ? 1 : 2}
          py={1}
        >
          {renderItems}
        </MDBox>
      </Card>
    </Collapse>
  );
}

export default Legend;
