import { divIcon } from "leaflet";
import { Marker, Popup } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Card,
  Divider,
  Fab,
  IconButton,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { Delete, FmdBad, FmdGood, LocalHospital } from "@mui/icons-material";
import { application, telemetry } from "globals/endpoints";
import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import loggerFactory from "globals/logger/logger-factory";
import { Grid, Icon } from "@material-ui/core";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import "../../../App.css";
import { useMaterialUIController } from "context";
import dialog from "assets/theme/components/dialog";
import CTLDialogContent from "../styles/CTLDialogContent";
import L from "leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MyLocationIcon from "@mui/icons-material/MyLocation";
React.useLayoutEffect = React.useEffect;

function AreaSelect({ onAreaSelect }) {
  useEffect(() => {
    if (!map) {
      map = L.map("map");
      if (map) {
        map.selectArea.enable();

        map.on("areaselected", (e) => {
          onAreaSelect(e.bounds);
        });
      }
    }
  }, [onAreaSelect]);

  return null;
}

export default function Suraksha({ onCall }) {
  console.log("Location : " + AuthenticationService.getLocation());
  const mapRef = useRef();
  const mapContainerRef = useRef(null);
  const componentName = "MAP";
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [pins, setPins] = useState();
  const [northZoneColor, setNorthZoneColor] = useState("success");
  const [southZoneColor, setSouthZoneColor] = useState("success");
  const [selectedZone, setSelectedZone] = useState("");
  const [messageToBroadcast, setMessageToBroadcast] = useState("");
  const [homeLocation, setHomeLocation] = useState(
    AuthenticationService.getLocation()
  );
  const [locationUpdateColor, setLocationUpdateColor] = useState("warn");
  const [data, setData] = useState();

  useQuery(
    "geoTags",
    async () => {
      const response = await telemetry.get("/geoTagging/list");
      loggerFactory.debug(componentName, "Data Received", response.data.data);

      setData(response.data.data);
    },
    {
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map(mapContainerRef.current).setView(
        homeLocation,
        window.mapDefaultZoom
      );
      //`https://tile.openstreetmap.org/{z}/{x}/{y}.png`
      mapRef.current = map;
      const osm = L.tileLayer(`${window.mapURL}/tile/{z}/{x}/{y}.png`, {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      });
      osm.addTo(map);
      function addPinToMap(map, location) {
        const customIcon = L.divIcon({
          html: pin,
          iconSize: [0, 0],
        });

        L.marker(location, { icon: customIcon }).addTo(map);
      }

      // const locationToAddPin = [homeLocation[0], homeLocation[1]];
      // addPinToMap(map, locationToAddPin);

      const drawnItems = new L.FeatureGroup();
      map.addLayer(drawnItems);

      const drawControl = new L.Control.Draw({
        position: "topright",
        edit: {
          featureGroup: drawnItems,
          onCreated: _onCreated,
          onDeleted: _onDeleted,
        },
        draw: {
          rectangle: true,
          polyline: false,
          circle: false,
          marker: false,
          polygon: false,
          circlemarker: false,
        },
      });

      map.addControl(drawControl);

      map.on("draw:created", _onCreated);
      map.on("draw:deleted", _onDeleted);

      map.on("draw:drawstart", function (e) {
        drawnItems.clearLayers();
      });

      map.on("draw:created", function (e) {
        const type = e.layerType;
        const layer = e.layer;

        if (type === "rectangle") {
          const bounds = layer.getBounds();
          const topLeft = bounds.getNorthWest();
          const topRight = bounds.getNorthEast();
          const bottomLeft = bounds.getSouthWest();
          const bottomRight = bounds.getSouthEast();

           console.log("Rectangle corners:");
           console.log(
             "Corner 1 (Top Left): " + topLeft.lat + ", " + topLeft.lng
           );
           console.log(
             "Corner 2 (Top Right): " + topRight.lat + ", " + topRight.lng
           );
           console.log(
             "Corner 3 (Bottom Right): " +
               bottomRight.lat +
               ", " +
               bottomRight.lng
           );
           console.log(
             "Corner 4 (Bottom Left): " + bottomLeft.lat + ", " + bottomLeft.lng
           );


          drawnItems.addLayer(layer);
        }
      });
    }
  }, [homeLocation, window.mapDefaultZoom]);
  

  useEffect(() => {
    if (mapRef.current && data) {

        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            mapRef.current.removeLayer(layer);
          }
        });
        
      const markers = L.layerGroup().addTo(mapRef.current);
      data.forEach((marker) => {
        if (marker.geoX && marker.geoY) {
          const markerLocation = [
            parseFloat(marker.geoX),
            parseFloat(marker.geoY),
          ];
          const customIcon = getMarkerIcon(marker);

          const customMarker = L.marker(markerLocation, {
            icon: customIcon,
          });

          let popupContent = "";

          if (marker.data) {
            popupContent = `
            <div>
              <h3 style="color: black;">${marker.data.name}</h3>
              <p style="color: black;">
                <hr />
                <strong>Lat Long:</strong> ${marker.geoX}, ${marker.geoY}<br />
                <strong>Blood Group:</strong> ${
                  marker.data.bloodGroup || "N/A"
                }<br /> <hr />
                <strong>Medical Condition:</strong> ${
                  marker.data.medicalDetails || "N/A"
                }<br /> <hr />
                <strong>Home Address:</strong> ${
                  marker.data.homeAddress || "N/A"
                }<br /> <hr />
                <strong>Office Address:</strong> ${
                  marker.data.officeAddress || "N/A"
                }<br /> <hr />
                <strong>Primary Contact:</strong> ${
                  marker.data.primaryEmergencyContactName || "N/A"
                } ${
              marker.data.primaryEmergencyContactNumber || "N/A"
            }<br /> <hr />
                <strong>Secondary Contact:</strong> ${
                  marker.data.secondaryEmergencyContactName || "N/A"
                } ${marker.data.secondaryEmergencyContactNumber || "N/A"}<br />
                <hr />
               ${
                 marker.emergencyStatus
                   ? `<button style="background-color: green; color: white; padding:5px; margin: auto; display: block; 
                border-radius: 10px; cursor: pointer; margin-top: 10px;"  onclick="window.handleClearEmergency('${marker.id}')">Clear Emergency</button>`
                   : ""
               }
              </p>
               ${
                 marker.tagType !== "imsi"
                   ? `<button style="background-color: red; color: white; padding:5px; margin: auto; display: block; border-radius: 10px; cursor: pointer;" onclick="window.handlePinDelete('${marker.id}')">
                    Delete
                </button>`
                   : ""
               }
            </div>`;
            
          } else {
            popupContent = `
            <div>
              <h3 style="color: black;">${marker.name}</h3>
              <p style="color: black;">
                <hr />
                <strong>Lat Long:</strong> ${marker.geoX}, ${marker.geoY}
              </p>
            </div>`;
          }

          customMarker.bindPopup(popupContent);
          customMarker.addTo(markers);
        }
      });
    }
  }, [data]);

  const iconIMSIActive = renderToStaticMarkup(
    <FmdGood color="success" fontSize="large" />
  );

  const pin = renderToStaticMarkup(
    <MyLocationIcon color="info" fontSize="small" />
  );

  const iconIMSIEmergency = renderToStaticMarkup(
    <LocalHospital color="error" fontSize="large" />
  );

  const iconIMSIInActive = renderToStaticMarkup(
    <FmdGood color="warning" fontSize="large" />
  );

  const iconEnemy = renderToStaticMarkup(
    <FmdBad color="error" fontSize="large" />
  );

  const markerIMSIActive = divIcon({
    html: iconIMSIActive,
  });

  const markerIMSIInActive = divIcon({
    html: iconIMSIInActive,
  });

  const markerEnemy = divIcon({
    html: iconEnemy,
  });

  const markerEmergency = divIcon({
    html: iconIMSIEmergency,
  });

  const northZoneCodeBlueClick = () => {
    setNorthZoneColor("error");

    application.post("/gis/callZone/nz");

    setTimeout(() => {
      setNorthZoneColor("success");
    }, 10000);
  };

  window.handleClearEmergency = (imsi) => {
    telemetry.post(`/geoTagging/clearEmergency/${imsi}`);
  };

  window.handlePinDelete = (id) => {
    telemetry.post(`/geoTagging/delete/${id}`);
  };

  const northZonePAClick = () => {
    onCall(window.northZone.pa);
  };

  const northZoneCallEmergency = () => {
    onCall(window.northZone.emService);
  };

  const northZoneSendMessage = () => {
    setSelectedZone("nz");
  };

  const southZoneCodeBlueClick = () => {
    setSouthZoneColor("error");

    application.post("/gis/callZone/sz");

    setTimeout(() => {
      setSouthZoneColor("success");
    }, 10000);
  };

  const southZonePAClick = () => {
    onCall(window.southZone.pa);
  };

  const southZoneCallEmergency = () => {
    onCall(window.southZone.emService);
  };

  const southZoneSendMessage = () => {
    setSelectedZone("sz");
  };

  const onCloseForm = () => {
    setSelectedZone("");
    setMessageToBroadcast("");
  };

  const _onCreated = (e) => {
    let type = e.layerType;
    let layer = e.layer;
    if (type === "marker") {
      console.log("_onCreated: marker created", e);
    } else {
      console.log("_onCreated: something else created:", type, e);
    }

    console.log("Geojson", layer.toGeoJSON());
    console.log("Bounds", layer.getBounds());

    application.post("/gis/areaSelected", layer.getBounds());
  };

  const _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer((layer) => {
      numDeleted += 1;
    });
    console.log(`onDeleted: removed ${numDeleted} layers`, e);
  };

  const handleSaveButton = () => {
    telemetry.post("/geoTagging/textMessage", {
      zone: selectedZone,
      message: messageToBroadcast,
    });

    setSelectedZone("");
    setMessageToBroadcast("");
  };

  const handleMessageChange = (event) => {
    setMessageToBroadcast(event.target.value);
  };

  const lockOnCurrentLocation = () => {
    getLocation();
  };

  const navigateToLocation = () => {
    mapRef.current.setView(homeLocation, window.mapDefaultZoom);
  };

  const updateMAPLocation = (newLocation) => {
    mapRef.current.setView(newLocation, window.mapDefaultZoom);
  };

  function getLocation() {
    if (navigator.geolocation) {
      setLocationUpdateColor("info");
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    setLocationUpdateColor("warn");
    console.log([position.coords.latitude, position.coords.longitude]);
    updateMAPLocation([position.coords.latitude, position.coords.longitude]);
    setHomeLocation([position.coords.latitude, position.coords.longitude]);
    AuthenticationService.setLocation(
      position.coords.latitude,
      position.coords.longitude
    );
  }

  function getMarkerIcon(marker) {
    let customIcon;

    if (marker.tagType === "imsi") {
      if (marker.emergencyStatus) {
        customIcon = markerEmergency;
      } else {
        customIcon = marker.status ? markerIMSIActive : markerIMSIInActive;
      }
    } else {
      customIcon = markerEnemy;
    }
    customIcon.options.iconSize = [0, 0];
    customIcon.options.iconAnchor = [20, 15];
    return customIcon;
  }

  return (
    <Card>
      <Dialog
        open={selectedZone}
        onClose={onCloseForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Broadcast message to {selectedZone}
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue=""
            value={messageToBroadcast}
            onChange={handleMessageChange}
          />
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={onCloseForm} color="secondary">
            Cancel
          </MDButton>
          <MDButton
            color="success"
            autoFocus
            onClick={handleSaveButton}
            endIcon={<Icon>send</Icon>}
          >
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item xs={window.mapDisplayDispatcher ? 10 : 12}>
          {homeLocation && (
            <div
              id="map"
              ref={mapContainerRef}
              style={{ height: "calc(100vh - 150px)" }}
              center={homeLocation}
              zoom={window.mapDefaultZoom}
            >
              <AreaSelect
                onAreaSelect={(coordinates) => {
                  application.post("/gis/areaSelected", coordinates);
                }}
              />
            </div>
          )}
        </Grid>
        {window.mapDisplayDispatcher && (
          <Grid item xs={2}>
            <MDBox p={2} style={{ textAlign: "center" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDButton
                    color={northZoneColor}
                    onClick={northZoneCodeBlueClick}
                    endIcon={<Icon>crisis_alert</Icon>}
                  >
                    North Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    color={southZoneColor}
                    onClick={southZoneCodeBlueClick}
                    endIcon={<Icon>crisis_alert</Icon>}
                  >
                    South Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>

                <Grid item xs={12}>
                  <MDButton
                    color="success"
                    onClick={northZonePAClick}
                    endIcon={<Icon>campaign</Icon>}
                  >
                    North Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    color="success"
                    onClick={southZonePAClick}
                    endIcon={<Icon>campaign</Icon>}
                  >
                    South Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>

                <Grid item xs={12}>
                  <MDButton
                    color="success"
                    onClick={northZoneCallEmergency}
                    endIcon={<Icon>call</Icon>}
                  >
                    North Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    color="success"
                    onClick={southZoneCallEmergency}
                    endIcon={<Icon>call</Icon>}
                  >
                    South Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>

                <Grid item xs={12}>
                  <MDButton
                    color="info"
                    onClick={northZoneSendMessage}
                    endIcon={<Icon>feedback</Icon>}
                  >
                    North Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    color="info"
                    onClick={southZoneSendMessage}
                    endIcon={<Icon>feedback</Icon>}
                  >
                    South Zone
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>

                <Grid item xs={12}>
                  <MDButton
                    color="warn"
                    onClick={navigateToLocation}
                    endIcon={<Icon>my_location</Icon>}
                  >
                    Navigate to home location
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <MDButton
                    color={locationUpdateColor}
                    onClick={lockOnCurrentLocation}
                    endIcon={<Icon>maps_home_work</Icon>}
                  >
                    Anchor to current location as home
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
