import { useState } from "react";
import MDTypography from "components/MDTypography";
import {Tooltip } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import CTLNotification from "application/components/CTLNotification";
import variables from "globals/variables";
import Button from "@material-ui/core/Button";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import axios from "axios";
function CTLDownloadFile({ row, customerCDR, paCdr }) {
  const uuid1 = row?.original?.uuid1;
  const uuid = row?.original?.uuid;
  const filename = row?.original?.recordingFile;
  let str = filename || "";
  let index = str.lastIndexOf("/");
  let extracted = str.slice(index + 1, str.length);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  let fileDownloadURL;
  if (customerCDR) {
    fileDownloadURL = `${variables.app.services}auth/downloadRecordingFile/customerCdr/${uuid1}`;
  } else if (paCdr) {
    fileDownloadURL = `${variables.app.services}auth/downloadRecordingFile/paCdr/${uuid}`;
  } else {
    fileDownloadURL = `${variables.app.services}auth/downloadRecordingFile/lastAgentCdr/${uuid}`;
  }

  const onDownloadFile = () => {
    axios({
      url: fileDownloadURL,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", extracted);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      <Tooltip title="DownloadFile">
        {filename ? (
          <Button
            variant="contained"
            color="primary"
            style={{ margin: "10px" }}
            onClick={() => onDownloadFile()}
          >
            <DownloadingIcon></DownloadingIcon>
          </Button>
        ) : (
          <Button variant="contained" color="error" style={{ margin: "10px" }}>
            <DoNotDisturbAltOutlinedIcon color="error" />
          </Button>
        )}
      </Tooltip>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDTypography>
  );
}

export default CTLDownloadFile;
