import { forwardRef, useState } from "react";
import { Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import RenderPopupTopology from "./RenderPopupTopology";
import useCenteredTree from "./Helpers/useCenteredTree";
import "../css/links.css";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function PopupTopology({
  nodeDatum,
  categoryData,
  descendantsData,
  severityData,
  popupTopologyOpen,
  handlePopupTopologyClose,
  isAdmin,
  isEditMode,
}) {
  const [translate, containerRef] = useCenteredTree();
  const [asset, setAsset] = useState("");
  const [showLegends, setShowLegends] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0.5);

  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  const handleSearchAsset = (filterText) => setAsset(filterText);

  const handleShowLegends = () => setShowLegends((prev) => !prev);

  const handleZoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(1, prevZoomLevel + 0.1));
  const handleZoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(0.1, prevZoomLevel - 0.1));

  return (
    <Dialog
      onContextMenu={(e) => e.stopPropagation()}
      open={popupTopologyOpen}
      onClose={handlePopupTopologyClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        Sub-Network Topology
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
        ref={containerRef}
      >
        {Object.keys(nodeDatum).length > 0 && (
          <RenderPopupTopology
            data={nodeDatum}
            categoryData={categoryData}
            descendantsData={descendantsData}
            severityData={severityData}
            isDarkMode={isDarkMode}
            translate={translate}
            asset={asset}
            handleSearchAsset={handleSearchAsset}
            showLegends={showLegends}
            handleShowLegends={handleShowLegends}
            zoomLevel={zoomLevel}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <MDButton
          color="primary"
          type="button"
          onClick={handlePopupTopologyClose}
          sx={{ marginInline: "1em" }}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default PopupTopology;
