import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import DialogContainer from "layouts/nms/DynamicFormWrapper/DialogContainer";

function JSONFileCreation({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [code, setCode] = useState("");
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [openPopup, setOpenPopup] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formCode, setFormCode] = useState("");
  const [formName, setFormName] = useState("");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    console.log(row);
    application
      .post(operationURLS.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    }
    setOpenForm(false);
  }
  function onCloseForm() {
    setOpenForm(false);
  }

  function onFormSave(row) {
   setIsFormOpen(true);
   setFormCode(row.formCode);
   setFormName(row.formName);
  }

  function onClosePopup() {
    setOpenPopup(false);
  }

  function onDynamicFormSave(rows) {
    application
        .post(`form/${rows.formCode}`,{"content":rows.content})
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:"Error",
            type: "error",
            pagename: pageName,
            status: "Unable to save form json !!",
          });
        });
  }

  return (
    <MDBox>
      <DialogContainer
        formCode={formCode}
        formName={formName}
        dynamicFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        onDynamicFormSave={onDynamicFormSave}
      />

      <DeleteConfirmation
        entityName="JSON Form"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow, onFormSave)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLBaseDialogForm
        entityName="JSON Form"
        columns={getColumns(deleteRow, editRow, onFormSave)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLAdvancedFilter
        entityName="JSON Form"
        columns={getColumns(deleteRow, editRow, onFormSave)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow, onFormSave)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="id"
        pageId={pageId}
        list
        hasRowSelection={false}
        updateList={updateList}
        searchText={searchText}
      />

      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default JSONFileCreation;
