import { useEffect, useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import "./css/index.css";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import Filter from "./Filter";
import getColumnsConfig from "./configs/columnsConfig";
import getColumnsDetails from "./configs/columnsDetails";
import getFilteredItems from "./configs/filterItems";
import { addWidgetTableColumns } from "./columns";

function AddWidgetTable({ addWidgetTableData, widgetIdsHandle }) {
  const columnsDetails = getColumnsDetails(addWidgetTableColumns);
  const renderTableColumns = getColumnsConfig(addWidgetTableColumns);

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem("add-widget-table-state")) || {},
  );
  const darkModeContext = useMaterialUIController();

  useEffect(() => {
    localStorage.setItem("add-widget-table-state", JSON.stringify(tableState));
  }, [tableState]);

  const widgetTableData = addWidgetTableData.map((widget) => {
    const newTransformedWidget = {};

    Object.keys(widget).forEach((widgetProp) => {
      if (columnsDetails[widgetProp] && columnsDetails[widgetProp].display)
        newTransformedWidget[widgetProp] = widget[widgetProp];
    });

    return newTransformedWidget;
  });

  // Creating custom dark theme
  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark",
  );

  // Filter feature for the ServerTable
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = getFilteredItems(
    widgetTableData,
    columnsDetails,
    filterText,
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) =>
          setFilterText(() => {
            const text = e.target.value.toLowerCase();
            return text;
          })
        }
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const dataTable = (
    <DataTable
      data={filteredItems}
      progressPending={addWidgetTableData.length === 0}
      columns={renderTableColumns}
      responsive
      pagination
      paginationPerPage={tableState.currentRowsPerPage || 10}
      striped
      theme={darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"}
      title={<MDTypography variant="h6">Widget list</MDTypography>}
      defaultSortFieldId="id"
      paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
      fixedHeader
      fixedHeaderScrollHeight="300px"
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      persistTableHead
      onChangeRowsPerPage={(currentRowsPerPage) =>
        setTableState({ ...tableState, currentRowsPerPage })
      }
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => {
        widgetIdsHandle(
          selectedRows.map((row) => ({
            label: row.id,
            value: row.name,
          })),
        );
      }}
    />
  );

  return (
    <Card
      sx={{
        padding: ".5em",
        width: "56vw",
      }}
    >
      {dataTable}
    </Card>
  );
}

export default AddWidgetTable;
