import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function CTLDashboardGroupCell({ value }) {
  const [listOptions, setListOptions] = useState([]);

  useEffect(() => {
    application.post("/dashboardGroup/listAll").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);

  const matchedData = listOptions.find((option) => option.id === value);

  return (
    <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
      {matchedData && (
        <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
          &nbsp;&nbsp;&nbsp;
          {matchedData.name}
        </MDTypography>
      )}
    </MDTypography>
  );
}

export default CTLDashboardGroupCell;
