import CTLBooleanCell from "application/components/CTLBooleanCell";
import CTLCategoryServiceCell from "application/components/CTLCategoryServiceCell";
import CTLNotificationBooleanCell from "application/components/CTLNotificationBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),

  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "categoryService/list",
  create: "categoryService/create",
  update: "categoryService/update",
  delete: "categoryService/delete/",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Service Code",
      accessor: "serviceCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "select",
      groupHeading: "Category Service",
      options: {
        keyField: "serviceCode",
        keyDescription: "serviceName",
        values: [],
        url: "categoryService/dropdownServiceCode/list",
      },
    },

    {
      Header: "Catgeory Code",
      accessor: "categoryCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      groupHeading: "Category Service",
      options: {
        keyField: "categoryCode",
        keyDescription: "categoryName",
        values: [],
        url: "categoryService/dropdownCategoryCode/list",
      },
    },
    {
      Header: "Severity",
      accessor: "severity",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLCategoryServiceCell value={value} />,
      dataType: "string",
      controlType: "selectWithColor",
      options: {
        keyField: "severityLevel",
        keyDescription1: "severityName",
        keyDescription2: "color",
        values: [],
        url: "severity/listAll",
      },
    },
    {
      Header: "Alert Description",
      accessor: "alertDescription",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      groupHeading: "Category Service",
    },
    {
      Header: "Notification",
      accessor: "disableNotification",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <CTLNotificationBooleanCell value={value} />,
      dataType: "boolean",
      controlType: "boolean",
      groupHeading: "Category Service",
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
