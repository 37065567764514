import React, { useEffect, useState } from "react";
import axios from "axios";
import MDBox from "components/MDBox";
import DataTable from "react-data-table-component";
import { useMaterialUIController, setActivePage } from "context";
import { getColumns, Attributes, operationURLS } from "./model";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { application } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";
import CTLTable from "application/components/CTLTable";

function CallBack({ onLoad, mode, pageId, defaultSortColumn, defaultSortDirection, searchText, registerCallBacks, onCall }) {
  const componentName = "uc.callback";

  const [controller] = useMaterialUIController();
  const { activePage, darkMode } = controller;

  const [data, setData] = React.useState([]);

  const Url = "/externalApi/callBack";

  function apiData() {
    application.post(Url).then((response) => {
      loggerFactory.debug(componentName, "Data Fetched", response.data.data);
      if (response.data.data.length > 0) {
        setData(response.data.data);
      }
    })
      .catch((err) => {
   
      });
  }

  useEffect(() => {
    Attributes && onLoad(Attributes);
  }, [activePage]);


  return (
    <MDBox sx={(theme) => CTLDialogContent(theme, { darkMode })}>
      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(null, null, onCall)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="nodeName"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        disablePagination={true}
        disableSort={true}
        refetchInterval={Attributes.refetchInterval}
        onCall={onCall}
      />

    </MDBox>
  );
}

export default CallBack;
