import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";

export default [
  {
    Header: "Description",
    accessor: "description",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Description",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "Reference Code",
    accessor: "code",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Unique code defined by user to create link from some other step",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "Enable multilingual",
    accessor: "ivrLanguage",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    controlType: "boolean",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Use this to configure multilingual IVR. Use Media Centre from IRISCloud to upload multilingual IVR",
    defaultValue: false,
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "Welcome File",
    accessor: "welcomeFile",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "select",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select a file from media centre or upload a new file to play welcome announcement to the caller",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Introduction File",
    accessor: "introFile",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select a file from media centre or upload a new file to play welcome introduction to the caller",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Default Transfer Day",
    accessor: "defaultTransferDay",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Use this configuration to define extension to which call should be transferred automatically during office hours",
    defaultValue: "",
    dataUnit: "Extension",
    tabId: 0,
  },
  {
    Header: "Default Transfer Night",
    accessor: "defaultTransferNight",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Use this configuration to define extension to which call should be transferred automatically during office hours. If this configuration is not defined then day time extension will be applied used to transfer the call",
    defaultValue: "",
    dataUnit: "Extension",
    tabId: 0,
  },
  {
    Header: "Operator during Day",
    accessor: "operatorDay",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Use this configuration to define the operator extension for the flow.",
    defaultValue: "",
    dataUnit: "Extension",
    tabId: 0,
  },
  {
    Header: "Operator For Night",
    accessor: "operatorNight",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Use this configuration to define the operator extension for the flow.  If this configuration is not defined then day time operator will be applied used to transfer the call.",
    defaultValue: "",
    dataUnit: "Extension",
    tabId: 0,
  },
  {
    Header: "Wait Time",
    accessor: "waitTime",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "integer",
    Cell: ({ value }) => <DefaultCell value={value} />,
    validation: formControl.string().digitsOnly().nullable(true),
    detailedExplanation:
      "Number of seconds that system will wait for before triggering no input event",
    defaultValue: "30",
    dataUnit: "Seconds",
    tabId: 1,
  },
  {
    Header: "No Input File Name",
    accessor: "noInputFileName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select file from media center / upload new file to play prompt for no input provided. The prompt will be played after wait time expires",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Maximum length of input",
    accessor: "maxInput",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "integer",
    Cell: ({ value }) => <DefaultCell value={value} />,
    validation: formControl.string().digitsOnly().nullable(true),
    detailedExplanation:
      "Use this value to define the maximum number of digits allowed.",
    defaultValue: "1",
    dataUnit: "Digits",
    tabId: 1,
  },
  {
    Header: "Invalid File Name",
    accessor: "invalidFileName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select file from media center / upload new file to play prompt for not selecting a valid input",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Maximum Retry",
    accessor: "maxRetry",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "integer",
    Cell: ({ value }) => <DefaultCell value={value} />,
    validation: formControl.string().digitsOnly().nullable(true),
    detailedExplanation:
      "Maximum number retries allowed before Maximum Retry event.",
    defaultValue: "",
    dataUnit: "",
    tabId: 2,
  },
  {
    Header: "Maximum Retry File Name",
    accessor: "maxRetryFileName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "mediaFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select file from media center / upload new file to play prompt when user has exceeded maximum tries of providing valid input",
    defaultValue: "",
    dataUnit: "",
    tabId: 2,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Language Announcement ",
    accessor: "languageAnnouncement",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "mediaFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "File to be played for language selection",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Default Language",
    accessor: "defaultLanguage",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Default Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "First Alternate Language",
    accessor: "alternateLanguage1",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "First Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Second Alternate Language",
    accessor: "alternateLanguage2",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Second Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Third Alternate Language",
    accessor: "alternateLanguage3",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Third Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Fourth Alternate Language",
    accessor: "alternateLanguage4",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Fourth Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Fifth Alternate Language",
    accessor: "alternateLanguage5",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Fifth Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Sixth Alternate Language",
    accessor: "alternateLanguage6",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Sixth Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Seventh Alternate Language",
    accessor: "alternateLanguage7",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Seventh Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Eighth Alternate Language",
    accessor: "alternateLanguage8",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Eighth Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Ninth Alternate Language",
    accessor: "alternateLanguage9",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Ninth Alternate Language for IVR",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
    controlType: "select",
    options: {
      keyField: "language",
      keyDescription: "language",
      values: [],
      url: "ivr/languages",
    },
  },
  {
    Header: "Default API URL",
    accessor: "apiURL",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "API URL",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
  {
    Header: "API Type",
    accessor: "apiType",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Type of API SOAP / RESTFul",
    defaultValue: "",
    dataUnit: "",
    controlType: "select",
    options: {
      keyField: "id",
      keyDescription: "description",
      values: [
        { id: "SOAP", description: "SOAP" },
        { id: "REST", description: "REST" },
      ],
    },
    tabId: 4,
  },
  {
    Header: "Authorisation Type",
    accessor: "apiAuthType",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Type of Authentication",
    defaultValue: "",
    dataUnit: "",
    controlType: "select",
    options: {
      keyField: "id",
      keyDescription: "description",
      values: [
        { id: "no-auth", description: "Authorsation not required" },
        { id: "api-key", description: "API Key" },
        { id: "bearer-token", description: "Bearer Token" },
        { id: "basic", description: "Basic Auth" },
      ],
    },
    tabId: 4,
  },
  {
    Header: "Auth User Name / Header",
    accessor: "apiAuthUserName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Enter User name / Authorisation Header name",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
  {
    Header: "Auth Password / Key",
    accessor: "apiAuthPassword",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Enter User Password / Authorisation Key.",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
];
