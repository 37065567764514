export default {
    currentLogLevel: window.defaultLogLevel,
    displayTrace: window.displayTrace,
    setLogLevel: function (logLevel) {
        switch (logLevel) {
            case "error": this.currentLogLevel = 0; break;
            case "warn": this.currentLogLevel = 1; break;
            case "info": this.currentLogLevel = 2; break;
            case "debug": this.currentLogLevel = 3; break;
            case "trace": this.currentLogLevel = 4; break;
            case "silent": this.currentLogLevel = -1; break;
        }
    },
    error: function (component, message, data) {
        if (this.currentLogLevel >= 0) {
            if (!data) {
                data = "";
            }

            console.error(`%cApplication : ${component} : ERROR : ${message} ${data}`, 'color: red');
        }
    },
    warn: function (component, message, data) {
        if (this.currentLogLevel >= 1) {
            if (!data) {
                data = "";
            }

            console.warn(`%cApplication : ${component} : WARN : ${message} ${data}`, 'color: orange');
        }
    },
    info: function (component, message, data) {
        if (this.currentLogLevel >= 2) {
            if (!data) {
                data = "";
            }
            console.info(`%cApplication : ${component} : INFO : ${message} ${data}`, 'color: blue');
        }
    },
    debug: function (component, message, data) {
        if (this.currentLogLevel >= 3) {
            if (!data) {
                data = "";
            }

            console.log(`%cApplication : ${component} : DEBUG : ${message} ${data}`, 'color: green');
        }
    },
    trace: function (component, message, data) {
        if (this.currentLogLevel >= 4) {
            if (!data) {
                data = "";
            }

            console.log(`%cApplication : ${component} : TRACE : ${message} ${data}`, 'color: black');
        }
    }
}