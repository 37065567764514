import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import { TbTableRow } from "react-icons/tb";
import { Download } from "@mui/icons-material";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { IoFunnel } from "react-icons/io5";
import Filter from "../Filter";

export default function MediacenterDialogueConfig({
  handleClickOpen,
  rows,
  isNewTabGroupCreated,
  isExistingTabGroupSelected,
  isNewHeadingGroupCreated,
  isExistingHeadingGroupSelected,
  setIsNewTabGroupCreated,
  setIsExistingTabGroupSelected,
  setIsNewHeadingGroupCreated,
  setIsExistingHeadingGroupSelected,
  createHeadingGroup,
  selectTabGroup,
  handleChangeTabGroupName,
  handleChangeHeadingGroupType,
  handleSelectTabGroupType,
  setSelectTabGroup,
  setCreateHeaderName,
  setCreateHeadingGroup,
  setCreateTabGroupName,
  handleSaveGroup,
  openCreateTableDiologue,
  setOpenCreateTableDiologue,
  handleDownloadJSON,
  uniqueHeadingTypes,
  uniqueTabIDTypes,
  isAddingInExistingTabGroup,
  isAddingInExistingHeadingGroup,
  setIsAddingInExistingTabGroup,
  setIsAddingInExistingHeadingGroup,
  searchText,
  handleClearSearchText,
  handleOpenSearchDiologue,
  isShowSearchBox,
  handleSearchText,
  handleCloseSearchDiologue,
  setIsShowSearchBox,
}) {
  const { darkMode } = useMaterialUIController()[0];

  const handleClose = () => {
    setSelectTabGroup(1);
    setCreateHeaderName("");
    setCreateHeadingGroup("");
    setCreateTabGroupName("");

    setOpenCreateTableDiologue(false);
    setIsExistingTabGroupSelected(false);
    setIsNewTabGroupCreated(false);
    setIsExistingHeadingGroupSelected(false);
    setIsNewHeadingGroupCreated(false);
    setIsAddingInExistingTabGroup(false);
    setIsAddingInExistingHeadingGroup(false);
  };

  const handleChangeGroupName = (e) => {
    setCreateHeaderName(e.target.value);
  };

  const handleAddInExistingTabGroup = (e) => {
    setIsExistingTabGroupSelected(true);
  };

  const handleAddInNewTabGroup = (e) => {
    setIsNewTabGroupCreated(true);
  };

  const handleAddInExistingHeadingGroup = (e) => {
    setIsExistingHeadingGroupSelected(true);
  };

  const handleAddInNewHeadingGroup = (e) => {
    setIsNewHeadingGroupCreated(true);
  };

  const buttonStyles = {
    backgroundColor: darkMode ? "#1a2035" : "#fff",
    color: darkMode ? "white" : "#202940",
    height: "100%",
    width: "100%",
    border: "none",
    cursor: "pointer",
  };

  return (
    <>
      {rows.length > 0 && (
        <Box
          sx={{
            display: "flex",
            marginLeft: "15px",
            alignItems: "center",
          }}
        >
          <button style={buttonStyles} onClick={handleClickOpen}>
            <TbTableRow fontSize="medium" />
          </button>
          <button
            style={{
              ...buttonStyles,
              marginLeft: "8px",
            }}
            onClick={handleDownloadJSON}
          >
            <Download fontSize="medium" />
          </button>
          <button
            style={{
              ...buttonStyles,
              marginLeft: "8px",
            }}
          >
            {rows.length > 0 && (
              <IoFunnel
                fontSize="medium"
                fill={searchText !== "" ? "lightGreen" : "#FFF"}
                onClick={() =>
                  searchText !== ""
                    ? handleClearSearchText()
                    : handleOpenSearchDiologue()
                }
              />
            )}
          </button>
          {isShowSearchBox && (
            <Filter
              handleSearchText={handleSearchText}
              isShowSearchBox={isShowSearchBox}
              setIsShowSearchBox={setIsShowSearchBox}
              handleClearSearchText={handleClearSearchText}
            />
          )}
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCreateTableDiologue}
        onClose={handleClose}
      >
        <DialogTitle
          textAlign="center"
          sx={{
            color: darkMode ? "#fff" : "#000",
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
          }}
        >
          Create tab
        </DialogTitle>
        {!isExistingTabGroupSelected && !isNewTabGroupCreated && (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "30px",
              padding: "10px 30px 20px 30px",
              backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            }}
          >
            {rows.length > 0 && (
              <MDButton color="info" onClick={handleAddInExistingTabGroup}>
                Add record in existing tab group
              </MDButton>
            )}
            <MDButton color="primary" onClick={handleAddInNewTabGroup}>
              Add record in a new tab group
            </MDButton>
          </MDBox>
        )}
        {isExistingTabGroupSelected && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              padding: "10px 30px 20px 30px",
              backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            }}
          >
            {!isAddingInExistingTabGroup && (
              <FormControl fullWidth size="large">
                <InputLabel
                  id="select-tabid-types-label"
                  sx={{ padding: "0px", minHeight: "10px" }}
                >
                  Select TabID group
                </InputLabel>
                <Select
                  labelId="select-tabid-types-label"
                  id="Select TabID group"
                  value={selectTabGroup}
                  label="Select TabID group"
                  onChange={handleSelectTabGroupType}
                  sx={{ backgroundColor: "white", padding: "0.8em" }}
                >
                  {uniqueTabIDTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {rows.find((row) => row.tabId === item).tabName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!isExistingHeadingGroupSelected && !isNewHeadingGroupCreated && (
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "30px",
                  padding: "10px 30px 20px 30px",
                  backgroundColor: darkMode ? "#202940" : "#FFFFFF",
                }}
              >
                {rows.length > 0 && (
                  <MDButton
                    color="info"
                    onClick={handleAddInExistingHeadingGroup}
                  >
                    Add record in existing heading group
                  </MDButton>
                )}
                <MDButton color="primary" onClick={handleAddInNewHeadingGroup}>
                  Add record in a new heading group
                </MDButton>
              </MDBox>
            )}
            {isExistingHeadingGroupSelected &&
              !isAddingInExistingHeadingGroup && (
                <FormControl fullWidth size="large">
                  <InputLabel
                    id="select-heading-group-types-label"
                    sx={{ padding: "0px", minHeight: "10px" }}
                  >
                    Select Heading group
                  </InputLabel>
                  <Select
                    labelId="select-heading-group-types-label"
                    id="demo-simple-select"
                    value={createHeadingGroup}
                    label="Select Heading group"
                    onChange={handleChangeHeadingGroupType}
                    sx={{ backgroundColor: "white", padding: "0.8em" }}
                  >
                    {uniqueHeadingTypes.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            {isNewHeadingGroupCreated && (
              <MDInput
                size="large"
                label="Heading group"
                variant="outlined"
                onChange={handleChangeHeadingGroupType}
              />
            )}
            {(isExistingHeadingGroupSelected || isNewHeadingGroupCreated) && (
              <TextField
                size="large"
                sx={{ backgroundColor: "white", width: "100%" }}
                label="Field name"
                variant="outlined"
                onChange={handleChangeGroupName}
              />
            )}
          </Box>
        )}
        {isNewTabGroupCreated && (
          <MDBox
            sx={{
              backgroundColor: darkMode ? "#202940" : "#FFFFFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
              padding: "10px 30px 20px 30px",
            }}
          >
            <MDInput
              size="large"
              label="Tab group name"
              variant="outlined"
              onChange={handleChangeTabGroupName}
            />
            <MDInput
              size="large"
              label="Heading group name"
              variant="outlined"
              onChange={handleChangeHeadingGroupType}
            />
            <MDInput
              size="large"
              label="Field name"
              variant="outlined"
              onChange={handleChangeGroupName}
            />
          </MDBox>
        )}
        <DialogActions
          sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}
        >
          {(isExistingTabGroupSelected || isNewTabGroupCreated) && (
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleSaveGroup}
            >
              Save
            </MDButton>
          )}
          <MDButton
            variant="gradient"
            color={isExistingTabGroupSelected ? "light" : "info"}
            size="small"
            onClick={handleClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
