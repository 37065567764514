import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enablePublishButton: true,
  enableFilePreViewButton: true,
  enableAdvanceSearch: true,
  enableGroupHeading: false,
  verticalTabs: false,
  tabSize: 4,
  modes: [
    {
      id: "configuration.cluster",
      name: "Nodes",
      iconName: "workspaces",
      url: "home/app/configurations/cluster",
      description: "Cluster Nodes",
    },
    {
      id: "configuration.wans",
      name: "Wans",
      iconName: "wifi_channel",
      url: "home/app/configurations/wans",
      description: "Wans",
    },
  ],
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/routes/list",
  update: "/routes/update",
  register: "/routes/register ",
  delete: "/routes/delete",
  publish: "/configuration/publish",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "IP",
      accessor: "ip",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType:"ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").ipv4(),
    },
    {
      Header: "GW",
      accessor: "gateway",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").ipv4(),
    },
    {
      Header: "INTERFACE",
      accessor: "interfaces",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "CIDR",
      accessor: "cidr",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "NODES",
      accessor: "nodes",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
