import { useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Dropdown from "../../Dropdown";
import { IFRAME_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import ContextMenu from "../ContextMenu";

function Iframe({
  widget,
  cellWidgetInfo,
  index,
  containerType,
  widgetHeight,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  if (widget) {
    getDataURL = widget.getDataURL;
  }

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let iFrameURL = null;
  let error = null;
  if (widget) {
    primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
    iFrameURL = getDataURL;
  } else {
    primaryLabel = "Widget ID not configured";
    iFrameURL = "https://idronam.com/";
    error = (
      <Icon sx={{ color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  const styles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
    cursor: "context-menu",
  };
  if (containerType === "vertical") styles.height = widgetHeight;

  const renderIFrame = (
    <MDBox position="relative" p={2} height="90%">
      <MDBox
        variant="gradient"
        bgColor="info"
        color="white"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="2.5rem"
        height="2.5rem"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">crop_landscape</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end">
        <MDTypography variant="h4" mr={1}>
          {primaryLabel}
        </MDTypography>
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={IFRAME_DROPDOWN_LIST}
          />
        )}
      </MDBox>
      <iframe
        title={primaryLabel}
        frameborder="0"
        src={iFrameURL}
        width="100%"
        height="100%"
      />
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={styles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderIFrame}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card sx={styles} onContextMenu={handleContextMenu} id={pdfExportId}>
      {renderIFrame}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Iframe;
