import DefaultCell from "application/components/DefaultCell";
import VoicePickerPlayAudio from "application/components/voicePickerPlayAdios";
import VoicePickerFileDownload from "application/components/voicePickerFileDownload";

const Attributes = {
  enableAdvanceSearch: true,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableDownloadAllButton: true,
  enableDownloadPdf: true,
  modes: [],
};

const operationURLS = {
  list: "/eVoicePicker/list",
  csvDownload:"/eVoicePicker/downloadCSV",
  pdfDownload: "/eVoicePicker/generateAndDownloadPdf",
};

const getColumns = () => {
  return [
    {
      Header: "Location",
      accessor: "location",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Direction",
      accessor: "direction",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Recording Type",
      accessor: "coralApp",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Caller ",
      accessor: "callerIdNumber",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Caller Name",
      accessor: "callerName",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },

    {
      Header: "Destination Number",
      accessor: "destinationNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Destination Name",
      accessor: "destName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },

    {
      Header: "File Time",
      accessor: "fileTime",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startStamp",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Answer Time",
      accessor: "answerStamp",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "End Time",
      accessor: "endStamp",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },

    {
      Header: "Talk Time ",
      accessor: "billSec",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => <DefaultCell value={value} row={row} />,
    },
    {
      Header: "Play",
      accessor: "id",
      displayInList: true,
      Cell: ({ row, value }) => (
        <VoicePickerPlayAudio row={row} value={value} />
      ),
    },
    {
      Header: "Download File",
      accessor: "",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      allowInForm: false,
      Cell: ({ row, value }) => (
        <VoicePickerFileDownload row={row} value={value} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
