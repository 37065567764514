import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 2,
  enableAddButton: true,
  tabSize: 4,
  modes: [
    {
      iconName: "settings",
      id: "uc.conference.setting",
      name: "Conference Setting",
      description: "conference Setting",
      url: "home/app/uc/ConferenceSetting",
    },
  ],
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/conferenceParticipant/list",
  update: "/conferenceParticipant/update",
  create: "/conferenceParticipant/create ",
  delete: "/conferenceParticipant/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Participant Name",
      accessor: "participantName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
    },
    {
      Header: "Extension Number",
      accessor: "extensions",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      controlType: "select",
      dataType: "string",
      disabled: true,
      options: {
        keyField: "extension",
        keyDescription: "extension",
        values: [],
        url: "/conferenceParticipant/list/extension",
      },
     
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly(),
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly(),
    }, 
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().emailOnly().nullable(),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
