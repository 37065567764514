export default function topologyDataTransform(data, topologyType = "") {
  if (data.length === 0) return { nodes: [], links: [] };
  const nodes = [];
  const links = [];

  function helper({ ...node }) {
    nodes.push(node);
    node.children.forEach((child) => {
      if (child.children.length === 0 && topologyType === "location-graph")
        return;

      links.push({ source: node.id, target: child.id });
      helper(child);
    });
  }

  helper(data[0]);
  return { nodes, links };
}
