import { forwardRef } from "react";
import { Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Table from "../../Table";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function PopupLocationAssets({
  popupLocationAssetsOpen,
  handlePopupLocationAssetsClose,
  location,
  descendantsData,
  categoryData,
  severityData,
  isAdmin,
  isEditMode,
}) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  return (
    <Dialog
      onContextMenu={(e) => e.stopPropagation()}
      open={popupLocationAssetsOpen}
      onClose={handlePopupLocationAssetsClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <Table
          location={location}
          descendantsData={descendantsData}
          categoryData={categoryData}
          severityData={severityData}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          tableStateKey="smartTopology"
        />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <MDButton
          color="primary"
          type="button"
          onClick={handlePopupLocationAssetsClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default PopupLocationAssets;
