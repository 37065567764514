const hmsAdminMenus = [
  {
    icon: "hotel",
    key: "configuration.hotel",
    name: "Hotel",
    url: "home/app/configurations/hotel",
    description: "Room Details",
    disabled: false,
    productionReady: true
  },
];

export { hmsAdminMenus };
