import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { IconButton } from "@material-ui/core";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Select } from "@mui/material";
import { application, externalCallServer } from "globals/endpoints";
import SendIcon from "@mui/icons-material/Send";
import CTLNotification from "application/components/CTLNotification";
import FormField from "layouts/applications/wizard/components/FormField";
import MDBox from "components/MDBox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Checkbox, ListItemText } from "@mui/material";
import MDTypography from "components/MDTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const operationURLS = {
  fileUpload: "/fax/upload",
  selectUserList: "/fax/unLoggedUser/list",
};

export default function CTLFileExport({
  entityName,
  openFileUpload,
  onCloseFileUpload,
  setUpdateList,
  setOpenFileUpload,
  onUploadFax,
}) {
  const [open, setOpen] = React.useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [selectedFilePath, setSelectedFilePath] = useState("");
  const [userSelect, setUserSelect] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setOpen(openFileUpload);
    UserDropDownList();
  }, [openFileUpload]);

  const handleClose = () => {
    setOpen(onCloseFileUpload);
    setSelectedFilePath("");
    setUserSelect("");
  };

  // File Upload for handlechange 
  const handleFileChange = (e) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setSelectedFilePath(selectedFile ? selectedFile.name : "");
    }
  };

  // Dropdown for handleSelec
  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValuesArray = Array.isArray(value) ? value : [value];

    setUserSelect(selectedValuesArray);
  };

  // Get Dropdown value or Discription by api 
  const UserDropDownList = () => {
    application
      .post(operationURLS.selectUserList)
      .then((response) => {
        setUserList(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  // File Upload
  const onUploadFile = () => {
    if (!file) {
      setNotify({
        isOpen: true,
        pagename: "Sent Fax Status",
        message: "File Can Not Be Empty:\nKindly select the 'tiff' Image.",
        type: "error",
      });
      return;
    }

    if (userSelect.length === 0) {
      setNotify({
        isOpen: true,
        pagename: "Sent Fax Status",
        message: "Users Has Not Been Selected:\nKindly Select The User.",
        type: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("extension", JSON.stringify(userSelect));
    externalCallServer
      .post(operationURLS.fileUpload, formData)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Sent Fax Status",
          status: response.data.status,
        });
        setUserSelect([]);
        setUpdateList(Date.now());
        setTimeout(() => {
          onCloseFileUpload()
        }, 1000);
        setFile(null);
        setSelectedFilePath("");
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            ",  " +
            error.response.data.messageDetail,
          type: "error",
          // status: error.response.status,
        });
      });
  };
  return (
    <Dialog
      open={open}
      onClose={onCloseFileUpload}
      aria-labelledby="forward-message-dialog-title"
      aria-describedby="forward-message-dialog-description"
      maxWidth="md"
      classes={{ dialog }}
    >
      <DialogTitle
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        id="forward-message-dialog-title"
      >
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <MDTypography>{entityName}</MDTypography>
        </MDBox>
      </DialogTitle>

      <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl fullWidth>
            <InputLabel>Select-User</InputLabel>
            <Select
              sx={{
                height: 50,
                width: 130,
                "& .MuiMenuItem-root": {
                  justifyContent: "flex-end",
                },
              }}
              multiple
              value={userSelect || []}
              onChange={handleSelect}
              label="Select-User"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => (selected || []).join(", ")}
            >
              {userList.map((item) => (
                <MenuItem key={item.extension} value={item.extension}>
                  <Checkbox checked={userSelect.includes(item.extension)} />
                  {item.profileId}: {item.extension}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton>
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{ height: 50, width: 100 }}
              >
                <AttachmentIcon
                  style={{ fontSize: "40px", marginRight: "1px" }}
                />
                <span style={{ paddingLeft: "6px" }}>Attach File</span>
              </Button>
            </label>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              className={classes.input}
              onChange={handleFileChange}
            />
          </IconButton>
        </Box>
        <MDBox sx={{ height: "50px", width: "250px" }}>
          <FormField
            id="standard-basic"
            variant="standard"
            value={selectedFilePath}
            onChange={(e) => setSelectedFilePath(e.target.value)}
          />
        </MDBox>
      </DialogContent>

      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <Stack direction="row" spacing={5}>
          <MDButton onClick={handleClose} color="secondary">
            Cancel
          </MDButton>

          <MDButton
            color="success"
            onClick={onUploadFile}
            endIcon={<SendIcon />}
          >
            Send
          </MDButton>
        </Stack>
      </DialogActions>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Dialog>
  );
}
