import Icc4Popup from "application/categoryPopup/Icc4Popup";
import UnIdentifiedPopup from "application/categoryPopup/UnIdentifiedPopup";
import PhonePopup from "application/categoryPopup/PhonePopup";

const getNMSComponent = (category) => {
  if (category == "ICC4") {
    return Icc4Popup;
  } else if (category == "IP6LP") {
    return PhonePopup;
  } else if (category == "IP2LP") {
    return PhonePopup;
  } else if (category == "VIP") {
    return PhonePopup;
  } else if (category == "AHD") {
    return PhonePopup;
  } else if (category == "VHD") {
    return PhonePopup;
  } else if (category == "IP2LG") {
    return PhonePopup;
  } else {
    return UnIdentifiedPopup;
  }
};

export default getNMSComponent;
