import CTLParticipantsAction from "application/components/CTLParticipantActions";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 2,
  tabSize: 4,
  modes: [],
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/conferenceParticipant/list",
  update: "/conferenceParticipant/update",
  create: "/conferenceParticipant/create ",
  delete: "/conferenceParticipant/delete",
};

const getColumns = ( onMute, onRaiseHand ) => {
  return [
    {
      Header: "Participant Name",
      accessor: "participantName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
    },
    {
      Header: "Extension Number",
      accessor: "extensions",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      controlType: "select",
      dataType: "string",
      disabled: true,
      options: {
        keyField: "extension",
        keyDescription: "extension",
        values: [],
        url: "/conferenceParticipant/list/extension",
      },
     
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly(),
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().digitsOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().emailOnly().nullable(),
    },

    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
   
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onRaiseHand={onRaiseHand}
          onMute={onMute}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
