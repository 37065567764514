import { Card } from "@mui/material";
import { externalCallServer, application } from "globals/endpoints";
import React, { useState } from "react";
import { useQuery } from "react-query";
import loggerFactory from "globals/logger/logger-factory";
import { Grid, Icon } from "@material-ui/core";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

React.useLayoutEffect = React.useEffect;

export default function UserZone({ onCall }) {
  const componentName = "MAP";
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [data, setData] = useState();

  useQuery(
    "UserZone",
    async () => {
      const response = await application.post("/userZone/listAll");
      loggerFactory.debug(componentName, "Data Received", response.data.data);
      setData(response.data.data);
    },
    {
      refetchInterval: 5000,
    }
  );


  function zoneClick(code) {
    externalCallServer.post("/externalApi/zonePaging", {
      caller: AuthenticationService.getExtensionNumber(),
      callee: code,
      predefinedAction: "",
      callerUser: AuthenticationService.getFullName()
    })
  }

  return (
    <Card>
      <Grid container>
        <Grid item xs={window.mapDisplayDispatcher ? 10 : 12}>
          <img
            style={{ width: "100%", height: "100%" }}
            src="/images/Presentation1111.jpg"
            alt="SmartAssetImage"
          />
        </Grid>
        <Grid item xs={2}>
          <MDBox p={2} style={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              {data &&
                data.map((item) => (
                  <Grid item xs={12} key={item.id}>
                    <MDButton
                      color="success"
                      fullWidth
                      onClick={() => zoneClick(item.code)}
                      endIcon={<Icon>call</Icon>}
                    >
                      {item.description}
                    </MDButton>
                  </Grid>
                ))}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}
