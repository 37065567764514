import { forwardRef } from "react";
import { Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMaterialUIController } from "context";
import DynamicForm from "../DynamicForm";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function DialogContainer({
  formName,
  formCode,
  dynamicFormOpen,
  setIsFormOpen,
}) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  return (
    <Dialog open={dynamicFormOpen} fullScreen TransitionComponent={Transition}>
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        {formName}
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <DynamicForm formCode={formCode} setIsFormOpen={setIsFormOpen} />
      </DialogContent>
    </Dialog>
  );
}

export default DialogContainer;
