import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { v4 as uuidV4 } from "uuid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Breadcrumbs({ icon, title, route, light, setBreadcrumbsList }) {
  const routes = route.slice(0, -1);

  return (
    <MDBox mb={2} mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <MDTypography
          component="span"
          variant="body2"
          color={light ? "white" : "dark"}
          opacity={light ? 0.8 : 0.5}
          sx={{ lineHeight: 0, cursor: "pointer" }}
        >
          <Icon>{icon}</Icon>
        </MDTypography>
        {routes.map((el, index) => (
          <MDTypography
            key={uuidV4()}
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0, cursor: "pointer" }}
            onClick={() =>
              setBreadcrumbsList((prevBreadcrumbsList) =>
                prevBreadcrumbsList.filter((breadcrumb, ind) => ind <= index)
              )
            }
          >
            {el.dashboardType}
          </MDTypography>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
}

export default Breadcrumbs;
