import DefaultCell from "application/components/DefaultCell";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { agentReportMoreVert } from "globals/configuration/agentReportMoreVert";

const getAgent = AuthenticationService.allowAgentMenus();

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableRefresh:true,
  enableExitButton: getAgent ? true : false,
  modes: agentReportMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/lastAgentCdr/list",
  pdfDownload: "/lastAgentCdr/generateAndDownloadPdf",
  csvDownload: "/lastAgentCdr/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign Name",
      accessor: "campaignName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Customer Number",
      accessor: "customerNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Hangup Cause",
      accessor: "hangupCause",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Duration",
      accessor: "talkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent Name",
      accessor: "agentName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dateTime: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
