import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import { getAgentColumns, agentOperationURLS, agentAttributes } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import MDTypography from "components/MDTypography";
import {
  getUpdateColumns,
  updateAttributes,
  updateOperationURLS,
} from "./updateModel";

function Agent({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  onOpenForm,
  updateTableList,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    agentAttributes && onLoad(agentAttributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
      });
  }, [activePage]);

  useEffect(() => {
    setUpdateList(Date.now());
  }, [updateTableList]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };
  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };
  const onAgree = (row) => {
    application
      .post(agentOperationURLS.delete + "/" + row.id)
      .then((response) => {
        setUpdateList(Date.now()); 
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Agent Setting",
        });
      })
      .catch((error) => {
        setOpenForm(false);
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: "Agent Setting",
        });
      });
    setConfirmDelete(false);
  };
  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };
  function onAgentSaveForm(data) {
    if (data.id) {
      application
        .post(updateOperationURLS.agentUpdate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Agent Setting",
          });
        })
        .catch((error) => {
          setOpenForm(false);
          setNotify({
            isOpen: true,
            message: error.response.data.messageDetail,
            type: "error",
            pagename: "Agent Setting",
          });
        });
    } else {
      application
        .post(agentOperationURLS.agentList, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Agent Setting",
          });
        })
        .catch((error) => {
          setOpenForm(false);
          setNotify({
            isOpen: true,
            message: error.response.data.messageDetail,
            type: "error",
            pagename: "Agent Setting",
          });
        });
    }
    setOpenForm(false);
  }

  function onCloseAgentForm() {
    setOpenForm(false);
  }
  return (
    <>
      <MDBox>
        <DeleteConfirmation
          entityName="Call-Center"
          data={selectedRow}
          columns={getAgentColumns(editRow, deleteRow)}
          confirmDelete={confirmDelete}
          onAgree={onAgree}
          onCancel={onCancel}
        />
        <CTLBaseDialogForm
          entityName="Call-Center"
          columns={getUpdateColumns(editRow)}
          openForm={openForm}
          onSaveForm={onAgentSaveForm}
          onCloseForm={onCloseAgentForm}
          formData={formData}
          pageAttributes={agentAttributes}
        />
        <Card
          style={{
            margin: 1,
            overflow: "scroll",
            overflowX: "hidden",
            maxHeight: 240,
            marginLeft: "auto",
            marginRight: 0,
          }}
        >
          <MDTypography sx={{ marginLeft: 1 }}>Agent Setting</MDTypography>
          <CTLTable
            apiURL={agentOperationURLS.agentList} 
            columns={getAgentColumns(editRow, deleteRow)}
            entriesPerPage="false"
            pageId={pageId}
            searchText={searchText}
            hasRowSelection={false}
            updateList={updateList}
            disablePagination={true}
            refetchInterval={agentAttributes.refetchInterval}
          />
        </Card>
        <CTLNotification notify={notify} setNotify={setNotify} />
      </MDBox>
    </>
  );
}
export default Agent;
