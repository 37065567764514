import { useMemo, useState } from "react";
import { DragDropContext } from "@hello-pangea/dnd";
import { Backdrop, CircularProgress, Fab, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import RestartAlt from "@mui/icons-material/RestartAlt";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import { useSaveLayoutMutation } from "features/apiSlice";
import RenderContainer from "../utils/RenderContainer";
import computeInnerContentColumns from "../utils/InnerContentWidth";
import CONTAINER_TYPES from "../configs/containersConfigs";
import { useGetDataQuery } from "features/apiSlice";
import SeverityContext from "../context/severityContext";
import Confirmation from "../Confirmation";

function Layout({
  isAdmin,
  username,
  populatedLayoutData,
  widgetsDataObj,
  handleBreadcrumbs,
  popupMode,
  dashboardId,
}) {
  const [saveLayout, response] = useSaveLayoutMutation();
  const [layoutData, setLayoutData] = useState(populatedLayoutData);
  const [widgetsDataObj2, setWidgetsDataObj2] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");

  const severityListQuery = useGetDataQuery(
    process.env.REACT_APP_SEVERITY_LIST_ENDPOINT,
  );

  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);

  const handleDeleteWidget = (masterContainerId, cellId, index) => {
    setLayoutData((prevLayoutData) => ({
      ...prevLayoutData,
      [masterContainerId]: prevLayoutData[masterContainerId].map((cell) =>
        cell.celId === cellId
          ? {
              ...cell,
              widgetIds: cell.widgetIds.filter(
                (widgetId, ind) => ind !== index,
              ),
            }
          : cell,
      ),
    }));
  };

  const handleOnDrag = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const sourceMasterContainerId = source.droppableId.split("/")[0];
    const sourceChildContainerId = source.droppableId.split("/")[1];
    const destinationMasterContainerId = destination.droppableId.split("/")[0];
    const destinationChildContainerId = destination.droppableId.split("/")[1];

    const draggedWidgetType = draggableId.split("/////")[0];
    const draggedWidgetId = draggableId.split("/////")[1];

    const sourceMasterContainer = layoutData[sourceMasterContainerId];
    const sourceChildContainer = sourceMasterContainer.filter(
      (cell) => cell.celId === sourceChildContainerId,
    )[0];
    const destinationMasterContainer = layoutData[destinationMasterContainerId];
    const destinationChildContainer = destinationMasterContainer.filter(
      (cell) => cell.celId === destinationChildContainerId,
    )[0];

    const newSourceChildContainerWidgetIds = [
      ...sourceChildContainer.widgetIds,
    ];
    newSourceChildContainerWidgetIds.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      newSourceChildContainerWidgetIds.splice(destination.index, 0, {
        id: draggedWidgetId,
        widgetType: draggedWidgetType,
      });

      setLayoutData((prevLayoutData) => ({
        ...prevLayoutData,
        [sourceMasterContainerId]: prevLayoutData[sourceMasterContainerId].map(
          (cell) =>
            cell.celId === sourceChildContainerId
              ? {
                  ...sourceChildContainer,
                  widgetIds: newSourceChildContainerWidgetIds,
                }
              : cell,
        ),
      }));
    } else if (
      sourceMasterContainerId === destinationMasterContainerId &&
      sourceChildContainerId !== destinationChildContainerId
    ) {
      const newDestinationChildContainerWidgetIds = [
        ...destinationChildContainer.widgetIds,
      ];

      newDestinationChildContainerWidgetIds.splice(destination.index, 0, {
        id: draggedWidgetId,
        widgetType: draggedWidgetType,
      });

      setLayoutData((prevLayoutData) => ({
        ...prevLayoutData,
        [sourceMasterContainerId]: prevLayoutData[sourceMasterContainerId].map(
          (cell) => {
            if (cell.celId === sourceChildContainerId)
              return {
                ...sourceChildContainer,
                widgetIds: newSourceChildContainerWidgetIds,
              };
            if (cell.celId === destinationChildContainerId)
              return {
                ...destinationChildContainer,
                widgetIds: newDestinationChildContainerWidgetIds,
              };

            return cell;
          },
        ),
      }));
    } else {
      const newDestinationChildContainerWidgetIds = [
        ...destinationChildContainer.widgetIds,
      ];

      newDestinationChildContainerWidgetIds.splice(destination.index, 0, {
        id: draggedWidgetId,
        widgetType: draggedWidgetType,
      });

      setLayoutData((prevLayoutData) => ({
        ...prevLayoutData,
        [sourceMasterContainerId]: prevLayoutData[sourceMasterContainerId].map(
          (cell) =>
            cell.celId === sourceChildContainerId
              ? {
                  ...sourceChildContainer,
                  widgetIds: newSourceChildContainerWidgetIds,
                }
              : cell,
        ),
        [destinationMasterContainerId]: prevLayoutData[
          destinationMasterContainerId
        ].map((cell) =>
          cell.celId === destinationChildContainerId
            ? {
                ...destinationChildContainer,
                widgetIds: newDestinationChildContainerWidgetIds,
              }
            : cell,
        ),
      }));
    }
  };

  const handleEdit = () => {
    if (isEditMode) {
      setIsSaving(true);
      saveLayout({
        userName: username,
        widgetId: dashboardId,
        dashboard: layoutData,
      })
        .unwrap()
        .then((res) => {
          setIsSaving(false);
          setLayoutData(res.data.dashboard);
          toggleSnackbar();
        })
        .catch((err) => {
          setIsSaving(false);
          console.log(err);
        });
    }
    setIsEditMode((prevEditMode) => !prevEditMode);
  };

  const handleLayoutChangeConfirmation = (newConfirmationType) => {
    setConfirmationType(newConfirmationType);
    setOpenConfirmationDialog(true);
  }

  const handleUndoLayout = () => {
    if(confirmationType === "undo"){
      setLayoutData(populatedLayoutData);
    } else {
      setIsSaving(true);
      saveLayout({
        userName: username,
        widgetId: dashboardId,
        dashboard: null,
      })
        .unwrap()
        .then(() => {
          setIsSaving(false);
          setLayoutData(populatedLayoutData);
          toggleSnackbar();
        })
        .catch((err) => {
          setIsSaving(false);
          console.log(err);
        });
    }
    setIsEditMode(false);
    setOpenConfirmationDialog(false);
  };

  const handleConfirmationDialogClose = () => {
    setOpenConfirmationDialog(false);
  }

  return useMemo(
    () => (
      <SeverityContext.Provider value={severityListQuery}>
        {!popupMode && isAdmin && isEditMode && (
          <MDBox
            position="fixed"
            right="1.8em"
            bottom="5em"
            zIndex={99}
            sx={{ cursor: "pointer" }}
            onClick={() => handleLayoutChangeConfirmation("undo")}
          >
            <Fab color="secondary" size="medium">
              <CancelIcon fontSize="medium" />
            </Fab>
          </MDBox>
        )}
        {!popupMode && isAdmin && isEditMode && (
          <MDBox
            position="fixed"
            right="1.8em"
            bottom="1.8em"
            zIndex={99}
            sx={{ cursor: "pointer" }}
            onClick={() => handleLayoutChangeConfirmation("reset")}
          >
            <Fab color="primary" size="medium">
              <RestartAlt fontSize="medium" />
            </Fab>
          </MDBox>
        )}
        <Confirmation
          confirmationType={confirmationType}
          openConfirmationDialog={openConfirmationDialog}
          handleUndoLayout={handleUndoLayout}
          handleConfirmationDialogClose={handleConfirmationDialogClose}
        />
        {!popupMode && isAdmin && (
          <MDBox
            position="fixed"
            right="1.8em"
            bottom={isEditMode ? "8.2em" : "1.8em"}
            zIndex={99}
            sx={{ cursor: "pointer" }}
            onClick={handleEdit}
          >
            <Fab color="secondary" size="medium">
              {isEditMode ? (
                <SaveIcon fontSize="medium" />
              ) : (
                <EditIcon fontSize="medium" />
              )}
            </Fab>
          </MDBox>
        )}
        <Backdrop
          open={isSaving}
          sx={{ color: "#ccc", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress disableShrink color="white" />
        </Backdrop>
        <MDSnackbar
          color="success"
          icon="done"
          title="Done"
          dateTime=""
          content="Layout Saved Successfully!"
          open={showSnackbar}
          close={toggleSnackbar}
        />
        <DragDropContext onDragEnd={handleOnDrag}>
          {layoutData.header && layoutData.header.length > 0 && (
            <Grid>
              {RenderContainer(
                CONTAINER_TYPES.header,
                layoutData,
                setLayoutData,
                handleDeleteWidget,
                widgetsDataObj,
                handleBreadcrumbs,
                widgetsDataObj2,
                setWidgetsDataObj2,
                isEditMode,
                isAdmin,
              )}
            </Grid>
          )}
          <Grid container>
            {layoutData.leftSidebar &&
              layoutData.leftSidebar.length > 0 &&
              RenderContainer(
                CONTAINER_TYPES.leftSidebar,
                layoutData,
                setLayoutData,
                handleDeleteWidget,
                widgetsDataObj,
                handleBreadcrumbs,
                widgetsDataObj2,
                setWidgetsDataObj2,
                isEditMode,
                isAdmin,
              )}
            <Grid
              item
              xs={computeInnerContentColumns("xs", layoutData)}
              sm={computeInnerContentColumns("sm", layoutData)}
              md={computeInnerContentColumns("md", layoutData)}
            >
              {layoutData.innerHeader &&
                layoutData.innerHeader.length > 0 &&
                RenderContainer(
                  CONTAINER_TYPES.innerHeader,
                  layoutData,
                  setLayoutData,
                  handleDeleteWidget,
                  widgetsDataObj,
                  handleBreadcrumbs,
                  widgetsDataObj2,
                  setWidgetsDataObj2,
                  isEditMode,
                  isAdmin,
                )}
              <Grid item container>
                {layoutData.innerLeftSidebar &&
                  layoutData.innerLeftSidebar.length > 0 &&
                  RenderContainer(
                    CONTAINER_TYPES.innerLeftSidebar,
                    layoutData,
                    setLayoutData,
                    handleDeleteWidget,
                    widgetsDataObj,
                    handleBreadcrumbs,
                    widgetsDataObj2,
                    setWidgetsDataObj2,
                    isEditMode,
                    isAdmin,
                  )}
                {layoutData.center &&
                  layoutData.center.length > 0 &&
                  RenderContainer(
                    CONTAINER_TYPES.center,
                    layoutData,
                    setLayoutData,
                    handleDeleteWidget,
                    widgetsDataObj,
                    handleBreadcrumbs,
                    widgetsDataObj2,
                    setWidgetsDataObj2,
                    isEditMode,
                    isAdmin,
                  )}
                {layoutData.innerRightSidebar &&
                  layoutData.innerRightSidebar.length > 0 &&
                  RenderContainer(
                    CONTAINER_TYPES.innerRightSidebar,
                    layoutData,
                    setLayoutData,
                    handleDeleteWidget,
                    widgetsDataObj,
                    handleBreadcrumbs,
                    widgetsDataObj2,
                    setWidgetsDataObj2,
                    isEditMode,
                    isAdmin,
                  )}
              </Grid>
              {layoutData.innerFooter &&
                layoutData.innerFooter.length > 0 &&
                RenderContainer(
                  CONTAINER_TYPES.innerFooter,
                  layoutData,
                  setLayoutData,
                  handleDeleteWidget,
                  widgetsDataObj,
                  handleBreadcrumbs,
                  widgetsDataObj2,
                  setWidgetsDataObj2,
                  isEditMode,
                  isAdmin,
                )}
            </Grid>
            {layoutData.rightSidebar &&
              layoutData.rightSidebar.length > 0 &&
              RenderContainer(
                CONTAINER_TYPES.rightSidebar,
                layoutData,
                setLayoutData,
                handleDeleteWidget,
                widgetsDataObj,
                handleBreadcrumbs,
                widgetsDataObj2,
                setWidgetsDataObj2,
                isEditMode,
                isAdmin,
              )}
          </Grid>
          {layoutData.footer && layoutData.footer.length > 0 && (
            <Grid>
              {RenderContainer(
                CONTAINER_TYPES.footer,
                layoutData,
                setLayoutData,
                handleDeleteWidget,
                widgetsDataObj,
                handleBreadcrumbs,
                widgetsDataObj2,
                setWidgetsDataObj2,
                isEditMode,
                isAdmin,
              )}
            </Grid>
          )}
        </DragDropContext>
      </SeverityContext.Provider>
    ),
    [
      populatedLayoutData,
      layoutData,
      widgetsDataObj,
      widgetsDataObj2,
      isEditMode,
      isSaving,
      showSnackbar,
      severityListQuery.data,
      openConfirmationDialog,
    ],
  );
}

export default Layout;
