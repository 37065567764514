const DashboardConfigMenu = [
  {
    icon: "dashboard",
    key: "user.dashboard",
    name: "User-Dashboard",
    url: "home/app/configurations/user/dashboard",
    description: "user-Dashboard",
    productionReady: true,
  },
];
export { DashboardConfigMenu };
