import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import { getColumn, Attribute } from "./nmsSettingModel";
import DeleteConfirmation from "application/components/DeleteConfirmation";
//import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLClusterNodeStatus from "application/components/CTLClusterNodeStatus";
import CTLFileExport from "application/components/CTLFileExport";
import CTLNotification from "application/components/CTLNotification";
//import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";

function Cluster({
  onLoad,
  mode,
  pageId,
  pageName,
  defaultSortColumn,
  defaultSortDirection,
  searchText,
  registerCallBacks,
}) {
  const componentName = "pbx.configuration.settings.Cluster";
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openNmsSettingForm, setOpenNmsSettingForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [fileExport, setFileExport] = useState(false);
  const [nodeStatus, setNodeStatus] = useState(false);
  const [openConfigure, setOpenConfigure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openpublishData, setOpenPublishData] = useState(false);
  const [showContent, setShowContent] = useState(null);
  const [publishData, setPublishData] = useState({});
  const [filePreViewData, setFilePreViewData] = useState({});
  const [PreViewButton, setPreViewButton] = useState(false);
  const [RebootButton, setRebootButton] = useState(false);

  const handleOpen = () => setOpenPublishData(true);
  const handleClose = () => {
    setOpenPublishData(false);
  };
  const handleReset = () => {
    SystemReset();
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onPublish: onPublish,
        onFileExport: onOpenFileBox,
        onFileImport: onDownloadFile,
        onFilePreView: onFilePreView,
      });
  }, [activePage]);

  // System Reset Functinality
  function SystemReset() {
    application
      .post(operationURLS.sytemReset)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });
  }

  const handleCloseConfigure = () => {
    setOpenConfigure(false);
  };

  // Open Model For File Upload
  function onOpenFileBox() {
    setFileExport(true);
  }

  function onCloseFileExport() {
    setFileExport(false);
  }

  //  Open Model For Dropdown Cluster Node Multiple Selected
  const NodeStatusOpen = (formData) => {
    setFormData(formData);
    formData.nodeCscf ? setNodeStatus(true) : setNodeStatus(false);
  };

  function onCloseNodeStatus() {
    setNodeStatus(false);
  }

  // for open Advance filter
  function onOpenFilter() {
    setOpenFilter(true);
  }

  // Click Add Button after Open DialogBox for Create Cluster
  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  // For Advance Filter
  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  // For Open Delete Confirmation Box
  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  // For Open Edit Model  Box
  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const nmsSetting = (row) => {
    setFormData(row);
    setOpenNmsSettingForm(true);
  };

  // File PreView in Cluster
  const onFilePreView = () => {
    setShowContent("filePreview");
    application
      .post(operationURLS.publish, { isPreview: true })
      .then((response) => {
        setTimeout(() => {
          setFilePreViewData(response.data.data);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
          handleOpen();
          setRebootButton(false);
          setPreViewButton(true);
        }, 2000);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // For Coral File  Publish

  const onPublish = () => {
    setOpenConfigure(true);
  };

  const onConfirmPublish = () => {
    setOpenConfigure(false);
    setLoading(true);
    setShowContent("publish");
    application
      .post(operationURLS.publish, { isPreview: false })
      .then((response) => {
        setTimeout(() => {
          setPublishData(response.data.data);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
          handleOpen();
          setPreViewButton(false);
          setRebootButton(true);
        }, 2000);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        id: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  /// Create & Update Cluster
  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    } else {
      application
        .post(operationURLS.register, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
      setOpenForm(true);
    }
    setOpenForm(false);
    setOpenNmsSettingForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
    setOpenNmsSettingForm(false);
  }

  // For Coral File Download in json
  function onDownloadFile() {
    application
      .post(operationURLS.fileExport)
      .then((response) => {
        const responseData = response.data;
        const blob = new Blob([JSON.stringify(responseData)], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Coral.json";
        link.click();
        URL.revokeObjectURL(url);
        setNotify({
          isOpen: true,
          message: "File Download Successfully",
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "Unabled To Download File ",
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });
  }

  return (
    <MDBox>
      <CTLClusterNodeStatus
        entityName="Nodes-Status"
        onCloseNodeStatus={onCloseNodeStatus}
        pageAttributes={Attributes}
        columns={getColumns(NodeStatusOpen)}
        formData={formData}
        nodeStatus={nodeStatus}
        setUpdateList={setUpdateList}
      />
      <CTLFileExport
        entityName="File-Upload"
        fileExport={fileExport}
        onCloseFileExport={onCloseFileExport}
        pageAttributes={Attributes}
        onFileExport={onOpenFileBox}
        setUpdateList={setUpdateList}
        setFileExport={setFileExport}
      />
      <CTLBaseDialogForm
        enableDefaultButton={true}
        entityName="Nodes"
        columns={getColumns(deleteRow, editRow, nmsSetting)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <CTLBaseDialogForm
        enableDefaultButton={true}
        entityName="NMS Settings"
        columns={getColumn(nmsSetting)}
        openForm={openNmsSettingForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attribute}
      />

      <DeleteConfirmation
        entityName="Nodes"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />
      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(
          deleteRow,
          editRow,
          NodeStatusOpen,
          formData,
          nmsSetting
        )}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="nodeName"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
      <MDBox>
        <div>
          <Dialog
            PaperProps={{
              sx: {
                maxWidth: "60%",
                maxHeight: "80%",
              },
            }}
            open={openpublishData}
            classes={{ dialog }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={(theme) => CTLDialogContent(theme, { darkMode })}
            >
              <MDBox
                sx={{ display: "flex", justifyContent: "center" }}
                color="white"
              >
                {RebootButton ? (
                  <MDTypography>
                    Configure-Node-Runing-Successfully{" "}
                  </MDTypography>
                ) : (
                  <MDTypography>Configuration-File </MDTypography>
                )}
              </MDBox>
            </DialogTitle>
            <hr></hr>
            {showContent === "publish" && (
              <DialogContent
                sx={(theme) => ({
                  ...CTLDialogContent(theme, { darkMode }),
                  width: "100%",
                  whiteSpace: "pre-wrap",
                })}
              >
                {onPublish && typeof publishData === "string" && (
                  <pre>
                    {publishData.split("\n").map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </pre>
                )}
              </DialogContent>
            )}

            {showContent === "filePreview" && (
              <DialogContent
                sx={(theme) => ({
                  ...CTLDialogContent(theme, { darkMode }),
                  width: "100%",
                  whiteSpace: "pre-wrap",
                })}
              >
                {onFilePreView && typeof filePreViewData === "string" && (
                  <pre>
                    {filePreViewData.split("\n").map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </pre>
                )}
              </DialogContent>
            )}
            <DialogActions
              sx={(theme) => ({
                ...CTLDialogContent(theme, { darkMode }),
                justifyContent: "center",
              })}
            >
              {PreViewButton ? (
                <MDButton onClick={handleClose} color="secondary">
                  Close
                </MDButton>
              ) : (
                ""
              )}
              {RebootButton ? (
                <>
                  <MDButton onClick={handleClose} color="secondary">
                    Close
                  </MDButton>
                  <MDButton onClick={handleReset} color="error">
                    System-Reboot
                  </MDButton>
                </>
              ) : null}
            </DialogActions>
            <CTLNotification notify={notify} setNotify={setNotify} />
          </Dialog>
        </div>
        <MDBox>
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </MDBox>

        <Dialog open={openConfigure} maxWidth="md" classes={{ dialog }}>
          <DialogTitle
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
            id="alert-dialog-title"
          >
            Confirmation
          </DialogTitle>
          <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            Are you sure you want to Run the Configure Node?
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton onClick={handleCloseConfigure} color="secondary">
              Cancel
            </MDButton>
            <MDButton onClick={onConfirmPublish} color="success" autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </MDBox>
  );
}

export default Cluster;
