import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
} from "@mui/material";
import MDButton from "components/MDButton";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";


const operationURLS = {
  Nodelist: "/configuration/list/nodes",
  NodeUpdate: "/configuration/update/node",
  Clusterlist: "/configuration/list",
};

export default function CTLClusterNodeStatus({
  onCloseNodeStatus,
  entityName,
  entityDescription,
  nodeStatus,
  pageAttributes,
  formData,
  setUpdateList,
}) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [node, setNode] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const maxWidth =
    pageAttributes && pageAttributes.formSize ? pageAttributes.formSize : "lg";

  const pageName = "Cluster";

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function NodeList() {
    application
      .post(operationURLS.Nodelist)
      .then((response) => {
        let dataToDisplay = response.data.data;
        setNode(dataToDisplay);
        console.loh("Node List Get Successfully")
      })
      .catch((error) => {
       console.log("Ubable to Fetch Node List")
      });
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(value);
  };

  useEffect(() => {
    setOpen(nodeStatus);
    NodeList();
  }, [nodeStatus]);

  const handleClickClose = () => {
    setOpen(onCloseNodeStatus);
    setSelectedValues([]);
  };

  const handleNodeUpdate = () => {
    if (formData && formData.id) {
      const data = {
        id: formData.id,
        nodeName: selectedValues,
      };

      application
        .post(operationURLS.NodeUpdate, data)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: 'success',
            pagename: "Cluster",
            status: response.data.status,
          });
          setUpdateList(Date.now());
          setSelectedValues([]);
          onCloseNodeStatus();
          
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.message + " " + error.response.messageDetail,
            type: 'error',
            pagename: "Cluster",
            status: error.response.status,
          });
        });
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "380px",
          height: "300px",
        },
      }}
      open={open}
      classes={{ dialog }}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        {entityName}: {entityDescription}
      </DialogTitle>
      <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <FormControl sx={{ m: 2, width: 300 }}>
          <InputLabel>{entityName}</InputLabel>
          <Select
            id="demo-multiple-checkbox"
            multiple
            MenuProps={MenuProps}
            value={selectedValues}
            label={entityName}
            onChange={handleChange}
            renderValue={(selected) => selected.join(",")}
            sx={{ width: "12", height: "50px" }}
          >
            {node && node.length > 0 ? (
              node.map((item) => (
                <MenuItem
                  key={item.nodeName}
                  value={item.nodeName}
                  sx={{
                    height: "40px",
                    maxWidth: "500px",
                  }}
                >
                  <Checkbox checked={selectedValues.includes(item.nodeName)} />
                  <ListItemText primary={item.nodeName} />
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No nodes available</MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          ...CTLDialogContent(theme, { darkMode }),
          justifyContent: "center",
        })}
      >
        <MDButton onClick={handleClickClose} color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleNodeUpdate} color="success" autoFocus>
          Apply
        </MDButton>
      </DialogActions>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Dialog>
  );
}
