import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";

function TimeIntervalDropdown({ timeInterval, handleTimeIntervalChange }) {
  return (
    <FormControl sx={{ position: "absolute", top: "1em", right: "3em" }}>
      <InputLabel id="demo-select-small-label">Interval</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={timeInterval}
        label="Interval"
        size="small"
        onChange={handleTimeIntervalChange}
        sx={{ padding: ".5em" }}
      >
        <MenuItem value={1}>1 min</MenuItem>
        <MenuItem value={3}>3 min</MenuItem>
        <MenuItem value={5}>5 min</MenuItem>
      </Select>
    </FormControl>
  );
}

export default TimeIntervalDropdown;
