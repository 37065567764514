import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import RoomMasterCell from "application/components/roomMasterCell";
import GroupMasterCell from "application/components/userGroupMasterCell";
import { userMenus } from "globals/configuration/user-management";

const Attributes = {
  formSize: "md",
  columns: 2,
  enableAddButton: true,
  enableMultipleUpdate: false,
  modes: userMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLSS = {
  multiUpdate: "user/multiUpdate/user",
};

const getMultipleUpdateColumns = (onDelete, onEdit, onView) => {
  return [
    // {
    //   Header: "Room",
    //   accessor: "nodeId",
    //   displayInList: true,
    //   allowInForm: true,
    //   allowFilter: true,
    //   dataType: "string",
    //   controlType: "multipleSelect",
    //   Cell: ({ value,row }) => <RoomMasterCell value={value}  row={row} />,
    //   options: {
    //     keyField: "id",
    //     keyDescription: "nodeName",
    //     values: [],
    //     url: "configuration/list/nodes",
    //   },
    // },
    {
      Header: "Group",
      accessor: "group",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "multipleSelect",
      Cell: ({ value }) => <GroupMasterCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "code",
        values: [],
        url: "/userGroupMaster/listAll",
      },
    },
    {
      Header: "Role",
      accessor: "role",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      controlType: "multipleSelect",
      // validation: formControl.required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "role/dropdownList",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
          onView={onView}
        />
      ),
    },
  ];
};

export { getMultipleUpdateColumns, Attributes, operationURLSS };
