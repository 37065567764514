import DefaultCell from "application/components/DefaultCell";
import CTLQueue from "application/components/CTLQueue";

const queueAttributes = {
  formSize: "md",
  columns: 4,
  enableAddButton: false,
  enableAcdDetails: true,
  selectUrl: "/queue/list/acdList",
  modes: [],
};

const queueOperationURLS = {
  acdList: "queue/list/acdList",
  queueUpdate: "/queue/update",
  qlist: "/queue",
};

const getQueueColumns = (onQueue) => {
  return [
    {
      Header: "ACD Number",
      accessor: "acdNumber",
      displayInList: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Name",
      accessor: "queueName",
      displayInList: false,
      allowInForm: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Short Code",
      accessor: "shortCode",
      displayInList: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "MOH Sound",
      accessor: "mohSound",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Pace Ratio",
      accessor: "paceRatio",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Time Base Score",
      accessor: "timeBaseScore",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max Wait Time",
      accessor: "maxWaitTime",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max Wait Time with No Agent",
      accessor: "maxWaitTimeWithNoAgent",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max Wait Time with No Agent Time Reached",
      accessor: "maxWaitTimeWithNoAgentTimeReached",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Disposition URL",
      accessor: "dispositionURL",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Extension Number",
      accessor: "extensionNumber",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tire Rule Wait",
      accessor: "tireRuleWait",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "From Time",
      accessor: "fromTime",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Time",
      accessor: "endTime",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Transfer Number",
      accessor: "callTransferNumber",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller ID ",
      accessor: "callerID ",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Attend Wait Time",
      accessor: "callAttendWaitTime",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Greeting Message",
      accessor: "greetingMessage",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "NBH Message ",
      accessor: "NBHMessage ",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "No Agent Message ",
      accessor: "noAgentMessage ",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Message",
      accessor: "queueMessage",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Day",
      accessor: "day",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Back Message",
      accessor: "callBackMessage",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "SMS Text",
      accessor: "SMSText",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Third Pary URL",
      accessor: "thirdParyURL",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DisCard Abandoned After",
      accessor: "discardAbandonedAfter",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Play Position",
      accessor: "playPosition",
      displayInList: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tier Rules Wait Second",
      accessor: "tierRuleWaitSecond",
      displayInList: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dialing Active",
      accessor: "dialingActive",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Send SMS",
      accessor: "sendSMS",
      displayInList: false,
      allowInForm: false,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Is Disabled",
      accessor: "isDisabled",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "RBT While Ringinging To Agent",
      accessor: "RBTWhileRingingingToAgent",
      displayInList: false,
      allowInForm: false,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "No Wait No Idle Agent",
      accessor: "noWaitNoIdleAgent",
      displayInList: false,
      allowInForm: false,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Allow Pickup Call ",
      accessor: "allowPickupCall ",
      displayInList: false,
      allowInForm: false,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Abandoned Resume Allowed",
      accessor: "abandonedResumeAllowed",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "record",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tier Rules Apply",
      accessor: "tierRulesApply",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tier Rules Wait Multiply Level",
      accessor: "tierRuleWaitMultiplyLevel",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tier Rule No Agent No Wait",
      accessor: "tierRuleNoAgentNoWait",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dialing NBH Hangup ",
      accessor: "dialingNBHHangup ",
      displayInList: false,
      allowInForm: false,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Is Auto ans",
      accessor: "isAutoAns",
      displayInList: false,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Transfer Mode",
      accessor: "transferMode",
      displayInList: false,
      allowInForm: false,
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "1", description: "Default" },
          { id: "2", description: "On" },
          { id: "3", description: "Off" },
        ],
      },
    },
    {
      Header: "Non Business Hours Call",
      accessor: "nonBusinessHoursCall",
      displayInList: false,
      allowInForm: false,
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "1", description: "Default" },
          { id: "2", description: "On" },
          { id: "3", description: "Off" },
        ],
      },
    },
    {
      Header: "Strategy",
      accessor: "strategy",
      displayInList: false,
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "1", description: "Default" },
          { id: "2", description: "On" },
          { id: "3", description: "Off" },
        ],
      },
    },
    {
      Header: "System Name",
      accessor: "systemName",
      displayInList: false,
      allowInForm: true,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "2", description: "Acd Queue 2" },
          { id: "3", description: "Acd Queue 3" },
          { id: "4", description: "Acd Queue 4" },
          { id: "5", description: "Acd Queue 5" },
          { id: "6", description: "Acd Queue 6" },
          { id: "7", description: "Acd Queue 7" },
          { id: "8", description: "Acd Queue 8" },
          { id: "9", description: "Acd Queue 9" },
          { id: "10", description: "Acd Queue 10" },
          { id: "11", description: "Acd Queue 11" },
          { id: "12", description: "Acd Queue 12" },
          { id: "13", description: "Acd Queue 13" },
          { id: "14", description: "Acd Queue 14" },
          { id: "15", description: "Acd Queue 15" },
          { id: "16", description: "Acd Queue 16" },
          { id: "17", description: "Acd Queue 17" },
          { id: "18", description: "Acd Queue 18" },
          { id: "19", description: "Acd Queue 19" },
          { id: "20", description: "Acd Queue 20" },
          { id: "21", description: "Acd Queue 21" },
          { id: "22", description: "Acd Queue 22" },
          { id: "23", description: "Acd Queue 23" },
          { id: "24", description: "Acd Queue 24" },
          { id: "25", description: "Acd Queue 25" },
          { id: "26", description: "Acd Queue 26" },
          { id: "27", description: "Acd Queue 27" },
          { id: "28", description: "Acd Queue 28" },
          { id: "29", description: "Acd Queue 29" },
          { id: "30", description: "Acd Queue 30" },
          { id: "31", description: "Acd Queue 31" },
          { id: "32", description: "Acd Queue 32" },
          { id: "33", description: "Acd Queue 33" },
          { id: "34", description: "Acd Queue 34" },
          { id: "35", description: "Acd Queue 35" },
          { id: "36", description: "Acd Queue 36" },
          { id: "37", description: "Acd Queue 37" },
          { id: "38", description: "Acd Queue 38" },
          { id: "39", description: "Acd Queue 39" },
          { id: "40", description: "Acd Queue 40" },
          { id: "41", description: "Acd Queue 41" },
          { id: "42", description: "Acd Queue 42" },
          { id: "43", description: "Acd Queue 43" },
          { id: "44", description: "Acd Queue 44" },
          { id: "45", description: "Acd Queue 45" },
          { id: "46", description: "Acd Queue 46" },
          { id: "47", description: "Acd Queue 47" },
          { id: "48", description: "Acd Queue 48" },
          { id: "49", description: "Acd Queue 49" },
          { id: "50", description: "Acd Queue 50" },
          { id: "51", description: "Acd Queue 51" },
          { id: "52", description: "Acd Queue 52" },
          { id: "53", description: "Acd Queue 53" },
          { id: "54", description: "Acd Queue 54" },
          { id: "55", description: "Acd Queue 55" },
          { id: "56", description: "Acd Queue 56" },
          { id: "57", description: "Acd Queue 57" },
          { id: "58", description: "Acd Queue 58" },
          { id: "59", description: "Acd Queue 59" },
          { id: "60", description: "Acd Queue 60" },
          { id: "61", description: "Acd Queue 61" },
          { id: "62", description: "Acd Queue 62" },
          { id: "63", description: "Acd Queue 63" },
          { id: "64", description: "Acd Queue 64" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <CTLQueue row={row} value={value} onQueue={onQueue} />
      ),
    },
  ];
};

export { getQueueColumns, queueAttributes, queueOperationURLS };
