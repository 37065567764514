import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";

const Columns = [
  {
    Header: "Ip-Address",
    accessor: "ip",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Alert-Type",
    accessor: "alertType",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Message",
    accessor: "message",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Message-Detail",
    accessor: "messageDetail",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Alert-Date",
    accessor: "alertDate",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "",
    accessor: "id",
    Cell: ({ value }) => <RowAction value={value} />,
  },
];

const Attributes = {
  enableAddButton: false,
  modes: [],
};

export { Columns, Attributes };
