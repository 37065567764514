

const ucNotificationMenus = [
  {
    icon:"phone_missed",
    key: "uc.missed-calls",
    color: "error",
    name: "Missed calls",
    description: "Missed incoming calls",
    url: "home/app/uc/missed-calls",
    messageType: "4",
    count: "",
  },
  {
    icon: "voicemail",
    key: "uc.voicemails",
    color: "secondary",
    name: "Voicemail",
    description: "Voice mails",
    url: "home/app/uce/voicemails",
    messageType: "1",
    count: "",
  },
  // {
  //   icon: (
  //     <Tooltip title="Call Back">
  //       <Icon fontSize="small">phone_callback</Icon>
  //     </Tooltip>
  //   ),
  //   key: "uc.call-back",
  //   color: "warning",
  //   name: "Call Back",
  //   description: "Callbacks set for you by different users",
  //   url: "home/app/uc/callback",
  //   messageType: "5",
  //   count: "",
  // },
  {
    icon: "message",
    key: "uc.messages",
    color: "primary",
    name: "Messages",
    description: "Messages sent / received",
    url: "home/app/uc/messages",
    messageType: "6",
    count: "",
  },

  {
    icon:"fax",
    key: "uc.fax",
    color: "success",
    name: "Fax",
    description: "Send and Recieved Fax",
    url: "home/app/uc/Fax",
    messageType: "2",
    count: "",
  },
  {
    icon: "list",
    color: "warning",
    key: "uc.history",
    name: "CDR",
    description: "Last 50 calls",
    url: "home/app/uc/cdr",
  },

  {
    icon: "contacts",
    color: "error",
    key: "uc.contacts",
    name: "Contacts",
    description: "Phone Book",
    url: "home/app/uc/contacts",
  },
  {
    icon: "contact_phone",
    color: "success",
    key: "uc.personal.phoneBook",
    name: "Personal Contacts",
    description: "Personal Contacts",
    url: "home/app/personalPhonBook",
  },

  {
    icon: "account_box",
    color: "info",
    key: "uc.settings",
    name: "User Profile",
    description: "User Profile",
    url: "home/app/uc/settings",
  },
  {
    icon: "message",
    color: "success",
    key: "uc.broadcasts.message",
    name: " Broadcasts Message",
    description: "Broadcasts Message",
    url: "home/app/broadcastsMessage",
  },
  {
    icon: "fax",
    color: "warning",
    key: "uc.sendFax.details",
    name: "Sent-Fax-Status",
    description: "Sent-Fax-Status",
    url: "home/app/Sent-Fax-Status",
  },
  {
    icon: "link",
    color: "secondary",
    key: "uc.conference.portal",
    name: "Conference Portal",
    description: "conference portal",
    url: "home/app/uc/ConferencePortal",
  },
  // {
  //   icon: "settings",
  //   color: "primary",
  //   key: "uc.conference.setting",
  //   name: "Conference Setting",
  //   description: "conference Setting",
  //   url: "home/app/uc/ConferenceSetting",
  // },
  // {
  //   icon: "groups",
  //   color: "info",
  //   key: "uc.Myconference",
  //   name: "My-Conference",
  //   description: "My-Conference",
  //   url: "home/app/uc/Myconference",
  // },
];

export { ucNotificationMenus };
