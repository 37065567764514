import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MDTypography from "components/MDTypography";
import { application } from "globals/endpoints";
import MDButton from "components/MDButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MDSnackbar from "components/MDSnackbar";
import CTLNotification from "application/components/CTLNotification";

function Comparison({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [name, setName] = useState("All Configuration");
  const [show, setShow] = useState(null);
  const [defaultButton, setDefaultButton] = useState("onAllList");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onNoDefaultComparison: DefaultComparison,
        onDefaultComparison: NoDefaultComparison,
        onAllList: onAllList,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  function DefaultComparison() {
    const yesData = [
      { direction: "", dataType: "", fieldName: "difference", value: "yes" },
    ];
    setName("Default Configuration ");
    setAdvancedFilters(yesData);
    setShow("DefaultComparison");
    setDefaultButton(null);
  }

  function NoDefaultComparison() {
    const noData = [
      { direction: "", dataType: "", fieldName: "difference", value: "no" },
    ];
    setName("Change Configuration");
    setAdvancedFilters(noData);
    setShow("NoDefaultComparison");
    setDefaultButton(null);
  }

  function onAllList() {
    const AllData = [{ direction: "", dataType: "", fieldName: "", value: "" }];
    setName("All Configuration");
    setAdvancedFilters(AllData);
    setShow("onAllList");
    setDefaultButton("onAllList");
  }

  // Default and NoDefault And All Data Download

  function DownloadTableData(downloadType) {
    let value = "";
    if (show === "DefaultComparison") {
      value = "yes";
    } else if (show === "NoDefaultComparison") {
      value = "no";
    }
    const requestBody = {
      sortBy: "extension",
      search: "",
      sortDataType: "string",
      advancedFilters: [
        {
          direction: "",
          dataType: "",
          fieldName: "difference",
          value: value,
        },
      ],
    };
    application
      .post(operationURLS.list, requestBody)
      .then((response) => {
        if (response.data.data.currentPageData.length === 0) {
          openErrorSB("Invalid download type");
          return;
        }
  
        if (downloadType === "csv") {
          const headers = Object.keys(response.data.data.currentPageData[0]); // Get the keys as headers
          const csvData =
            "data:text/csv;charset=utf-8," +
            headers.join(",") +
            "\n" +
            response.data.data.currentPageData
              .map((row) =>
                headers
                  .map((header) => {
                    let value = row[header];
                    if (header === "ringTime") {
                      value = value === 0 ? "0" : value;
                    } else {
                      value = value === 0 || value === false ? "off" : value === 1 || value === true ? "on" : value;
                    }
                    return value;
                  })
                  .join(",")
              )
              .join("\n");
          const encodedUri = encodeURI(csvData);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "Report.csv");
          document.body.appendChild(link);
          link.click();
        } else {
          console.error("Invalid download type");
        }
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.status,
          message: response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
  }
  
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );
  return (
    <MDBox>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <MDTypography sx={{ textAlign: "center" }}>{name}</MDTypography>
        </CardContent>
      </Card>

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns()}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="extension"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
      />
      <CTLAdvancedFilter
        entityName="Nodes"
        columns={getColumns()}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />
      <MDBox display="flex" justifyContent="right" margin="10px">
        {show === "DefaultComparison" && (
          <MDBox>
            <tooltip title="DefaultComparison in CSV File">
              <MDButton
                color="success"
                onClick={() => DownloadTableData("csv")}
                style={{ marginRight: "10px" }}
              >
                <FileDownloadIcon />
              </MDButton>
            </tooltip>
          </MDBox>
        )}
        {show === "NoDefaultComparison" && (
          <MDBox>
            <tooltip title="Changed Configuration in CSV File">
              <MDButton
                color="success"
                onClick={() => DownloadTableData("csv")}
                style={{ marginRight: "10px" }}
              >
                <FileDownloadIcon />
              </MDButton>
            </tooltip>
          </MDBox>
        )}
        {defaultButton === "onAllList" && (
          <MDBox>
            <tooltip title="onAllList in CSV File">
              <MDButton
                color="success"
                onClick={() => DownloadTableData("csv")}
                style={{ marginRight: "10px" }}
              >
                <FileDownloadIcon />
              </MDButton>
            </tooltip>
          </MDBox>
        )}
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default Comparison;
