import React from "react";
import MDBox from "components/MDBox";
import Button from "@material-ui/core/Button";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import ClearIcon from "@material-ui/icons/Clear";

export default function CTLAdvancedFilterShow({advancedFilters,onClearFilter }) {
 
 
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let snackbarMessage = "Filter applied";

  if (advancedFilters?.length > 0) {

    for (let i = 0; i < advancedFilters.length; i++) {
      const filter = advancedFilters[i];
      snackbarMessage += ` ${filter.direction} ${filter.fieldName} ${filter.value}`;

      if (i !== advancedFilters.length - 1) {
        snackbarMessage += ",";
      }
    }
  }

  const action = (
      <Button color="primary" size="small" onClick={onClearFilter}>
        Remove Filter <ClearIcon style={{ paddingLeft:"5px"}} />
      </Button>
  );

  return (
    <MDBox
      style={{ display: "flex", fontSize: "14px" }}
      sx={(theme) => CTLDialogContent(theme, { darkMode })}
    >
      {advancedFilters?.length > 0 && (
        <SnackbarContent
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          style={{ color: darkMode ? "red": "red", backgroundColor: "transparent", boxShadow: "none" , fontSize:"15px" }}
          message={snackbarMessage}
          action={action}
        />
      )}
    </MDBox>
  );
}
