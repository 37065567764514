import chartsActionHandle from "../../../../utils/chartsActionHandle";
import chartColors from "../../chartColors";

const { gradients } = chartColors;

function configs(
  chartLabels,
  data,
  cutout = 25,
  showLegends,
  actions,
  setPopUpOpen,
  setPopUpDashboardId,
  handleBreadcrumbs,
  isDarkMode,
  legendTooltipId,
) {
  const backgroundColors = [];

  data.backgroundColors.forEach((color) => {
    if (color === "info") {
      backgroundColors.push(gradients.info.main);
    } else {
      backgroundColors.push(gradients[color].state);
    }
  });

  const legendCallback = (legendItem) => {
    // Handle displaying a custom tooltip when hovering over a legend item
    const index = legendItem.index;
    const label = chartLabels[index];
    const value = data.datasets[index];

    return `${label}: ${value}`;
  };

  return {
    data: {
      labels: chartLabels,
      datasets: [
        {
          label: data.label,
          weight: 9,
          cutout,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 0.5,
          backgroundColor: backgroundColors,
          fill: false,
          data: data.datasets,
        },
      ],
    },
    options: {
      onClick: (event, element) =>
        chartsActionHandle(
          element,
          actions,
          chartLabels,
          setPopUpOpen,
          setPopUpDashboardId,
          handleBreadcrumbs,
        ),
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          display: showLegends,
          onHover: (event, legendItem) => {
            // Display the tooltip when hovering over a legend item
            const tooltip = document.getElementById(legendTooltipId);
            tooltip.innerHTML = legendCallback(legendItem);
            tooltip.style.display = "block";
            tooltip.style.color = "#000";
            tooltip.style.fontSize = ".6em";
            tooltip.style.backgroundColor = "#fff3b0";
            tooltip.style.paddingInline = ".4em";
            tooltip.style.paddingBlock = ".1em";
            tooltip.style.borderRadius = "5px";

            // Position the tooltip near the legend item
            // const legendBox = legendItem.chart.legend.legendHitBoxes[index];
            tooltip.style.left = event.x + "px";
            tooltip.style.top = event.y + 20 + "px";
          },
          onLeave: () => {
            // Hide the tooltip when the mouse leaves the legend
            const tooltip = document.getElementById(legendTooltipId);
            tooltip.style.display = "none";
          },
          labels: {
            boxWidth: 15,
            boxHeight: 15,
            color: isDarkMode && "#FFFFFF",
            generateLabels(chart) {
              const { labels } = chart.data;
              return labels.map((label, i) => {
                const legendLabel = `${chart.data.datasets[0].data[i]}: ${label}`;

                return {
                  index: i,
                  fullText: legendLabel,
                  text:
                    legendLabel.length >= 9
                      ? `${legendLabel.slice(0, 9)}...`
                      : legendLabel,
                  fillStyle: chart.data.datasets[0].backgroundColor[i],
                  strokeStyle: chart.data.datasets[0].backgroundColor[i],
                  lineWidth: 1,
                };
              });
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
