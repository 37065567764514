import { application } from "globals/endpoints"
import { useEffect, useState } from "react";


const operationURLS = {
    list: "autoAttendant/list",
  };
  



export default function ivrList(){
    const [ivrList, setIvrList] = useState([]);

    const List = () => {
      application
        .post(operationURLS.list, {
        
        })
        .then((response) => {
            setIvrList(response.data.data);
          
        })
        .catch((error) => {
         
        });
    };
  
    useEffect(() => {
      List();
    }, []);

    return(
        <>

        </>
    )
}