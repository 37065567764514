import { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import nmsMenuDashboard from "../nmsMenuDashboard";

function CTLNMSSelect() {
  const [list, setList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const operationURLS = {
    selectList: "/SCADAAsset/list/assets",
  };
  const selectedlist = () => {
    application
      .post(operationURLS.selectList, {
        assetId: 99,
      })
      .then((response) => {
        setList(response.data.data);
      });
  };

  useEffect(() => {
    selectedlist();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(value);
  };
  return (
    <>
      <Box>
        <FormControl sx={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label">Select Asset</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Asset"
            value={selectedValues}
            onChange={handleChange}
            sx={{ height: "50px" }}
            MenuProps={MenuProps}
          >
            <MenuItem key="" value="=">
              Select
            </MenuItem>
            {list.map((item) => (
              <MenuItem key={item.nodeName} value={item.id}>
                {item.nodeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <nmsMenuDashboard selectedValues={selectedValues}/>
        
      </Box>
    </>
  );
}

export default CTLNMSSelect;


