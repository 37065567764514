import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";
import CopyContent from "application/components/CTLCopyContent";
import CDRPlayAudio from "application/components/CTLCDRAudioCell";
import CTLFaxFileDownload from "application/components/CTLFaxFileDownload";
import CTLDownloadFile from "application/components/CTLDownloadFile";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,

  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/customerCDR/list",
  pdfDownload: "/customerCDR/generateAndDownloadPdf",
  csvDownload: "/customerCDR/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "ACD Number",
      accessor: "queue",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "ACD Strategy",
      accessor: "queueStrategy",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CALL  DIRECTION",
      accessor: "callDirection",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "QUEUE TYPE",
      accessor: "queueType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answered Operator Name",
      accessor: "answeredByName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answered Operator Extension",
      accessor: "answeredBy",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CALLER NUMBER",
      accessor: "customerNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Name",
      accessor: "customerName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      displayInList: false,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answer Time",
      accessor: "answerTime",
      displayInList: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Time",
      accessor: "endTime",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      pdf: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Wait Time",
      accessor: "waitTime",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "agentTalkTime",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "Call Type",
    //   accessor: "callType",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   displayInDeleteConfirmation: true,
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    // },
    {
      Header: "Pulse",
      accessor: "pulse",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Cost",
      accessor: "cost",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Transfer Type",
      accessor: "transferType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Transfered By",
      accessor: "transferBy",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Transfered To",
      accessor: "transferTo",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Transfered Status",
      accessor: "transferStatus",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "UUID",
      accessor: "uuid",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value, row }) => <CopyContent value={value} row={row} />,
    },
    {
      Header: "Play",
      accessor: "uuid1",
      displayInList: true,
      Cell: ({ row }) => <CDRPlayAudio row={row} />,
    },
    {
      Header: "Download File",
      accessor: "",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      allowInForm: false,
      Cell: ({ row, value }) => (
        <CTLDownloadFile row={row} value={value} customerCDR="customerCDR" />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
