import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";

function CampaignTypeCell({ value }) {
   const values = [
        { id: "0", description: "General" },
        { id: "1", description: "Abandoned" },
        { id: "2", description: "NBH" },
        { id: "3", description: "Blaster" },
        { id: "4", description: "Announcement" },
        { id: "5", description: "Preview Dialing Type 1" },
        { id: "6", description: "Preview Dialing Type 2" },
        { id: "7", description: "Preview Dialing Type 3" },
      ];
   
      const matchedRole = values.find((option) =>  option.id == value);
    return (
      <>
       
          <MDTypography variant="caption" fontWeight="medium" color="text">
          {matchedRole == undefined ? "NA" : (matchedRole && matchedRole.description) }
          </MDTypography>
    
      </>
    );
  }

export default CampaignTypeCell;

