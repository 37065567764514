import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enablePrintButton: true,
  enableGroupHeading: false,
  enableAcdDetails: true,
    selectUrl: "/queue/list/acdList",
  modes: [],
  tabs: [
    {
      id: 0,
      name: "Ivr Settings",
    },
  ],
};

const operationURLS = {
  register: "autoAttendant/create",
  list: "autoAttendant/list",
  
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
    {
      Header: "Extension No",
      accessor: "extension",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      // validation: formControl.string().required("Required"),
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
    {
      Header: "Welcome File",
      accessor: "welcomefile",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      // validation: formControl.string().required("Required"),
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
    {
      Header: "DOD No",
      accessor: "dodno",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
    {
      Header: "DID No",
      accessor: "didno",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Settings",
    },
    {
      Header: "Intro File",
      accessor: "introfile",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Ivr Settings",
    },

    {
      Header: "Day/Night",
      accessor: "day/night",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
    {
      Header: "Parent",
      accessor: "isParent",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Ivr Settings",
    },
  ];
};

export { getColumns, Attributes, operationURLS };
