export default function cytoscapeDataTransform(data, topologyType = "") {
  if (data.length === 0) return { nodes: [], edges: [] };
  const nodes = [];
  const edges = [];

  function helper(node) {
    nodes.push({
      data: {
        ...node,
        label: node.name,
      },
      position: JSON.parse(localStorage.getItem(node.id)) || { x: 0, y: 0 },
    });
    node.children.forEach((child) => {
      if (child.children.length === 0 && topologyType === "location-graph")
        return;

      edges.push({ data: { source: node.id, target: child.id } });
      helper(child);
    });
  }

  helper(data[0]);
  return { nodes, edges };
}
