import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function RoomMasterCell({ row }) {
  const [listOptions, setListOptions] = useState([]);

  useEffect(() => {
    application.post("/configuration/list/nodes").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);

  const matchedRoles = listOptions && listOptions.filter((option) => {
    return (
      option.id !== null &&
      row.original.nodeId &&
      row.original.nodeId.includes(option.id)
    );
  });
  return (
    <>
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {matchedRoles &&
          matchedRoles.map((item) => item.nodeName).join(", ")}
      </MDTypography>
    </>
  );
}

export default RoomMasterCell;
