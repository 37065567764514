import React, { useState } from "react";
import {
  Button,
  TextField,
  Stack,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { application } from "globals/endpoints";
import CTLNotification from "../CTLNotification";

const operationURLS = {
  upload: "/conferenceRoom/upload",
};

export default function FileUpload({ onFileChange }) {
  const [file, setFile] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const handleSendClick = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("type", "default");

      application.post(operationURLS.upload, formData);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file.name);
      setSelectedFile(file);
    }
  };

  return (
    <>
      <input
        type="file"
        accept=".wav"
        style={{ display: "none" }}
        onChange={handleFileChange}
        id="file-input"
      />
      <Stack direction="row" spacing={1}>
        <tooltip title="Please Select the File">
          <TextField
            variant="outlined"
            label="Selected File"
            value={file}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end" color="error">
                  <label htmlFor="file-input">
                    <CloudUploadIcon style={{ color: "red" }} />
                  </label>
                </InputAdornment>
              ),
            }}
          />
        </tooltip>
        <Button
          variant="contained"
          style={{ height: "45px" }}
          color="primary"
          onClick={handleSendClick}
        >
          Upload File
        </Button>
      </Stack>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
