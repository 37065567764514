import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from '@mui/material';

import ConfiguratorRoot from 'features/Configurator/ConfiguratorRoot';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { Paper, styled, TableCell, TableRow, Typography } from "@material-ui/core";
import { useMaterialUIController } from "context";

import CTLDialogContent from '../styles/CTLDialogContent';

import dialog from 'assets/theme/components/dialog';
import MDButton from 'components/MDButton';

export default function DeleteConfirmation({ confirmDelete, onAgree, onCancel, entityName, columns, data }) {
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    setOpen(confirmDelete);
  }, [confirmDelete])


  const deleteRow = () => {
    onAgree(data);
  }
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      classes={{ dialog }}
    >
      <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })} id="alert-dialog-title"> <Icon color='error' style={{ paddingTop: "5px" }} fontSize='large'>warning_amber</Icon> Are you sure you want to delete the selected {entityName}</DialogTitle>
      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <MDButton onClick={onCancel} color="secondary">
          Disagree
        </MDButton>
        <MDButton onClick={deleteRow} color="error" autoFocus>
          Agree
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}