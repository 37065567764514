import Tooltip from "../Tooltip";
import LevelOneTopologyNode from "../LevelOneTopologyNode";
import LevelTwoTopologyNode from "../PopupTopology/LevelTwoTopologyNode";
import ASSETS_CATEGORIES from "../../../configs/assetsConfigs";

export default function renderSvgNode(
  descendantsData,
  isDarkMode,
  categoryData,
  severityData,
  displayOptions,
  colorFilter,
  asset,
  assetCategory,
  isAdmin,
  isEditMode,
  level,
) {
  return (utilities) => {
    const nodeDatum = utilities.nodeDatum
      ? utilities.nodeDatum
      : utilities.node;

    const targetNodeSeverity = descendantsData[nodeDatum.id].severity || 0;
    const severityObj = severityData.filter(
      (severity) => severity.severityLevel === targetNodeSeverity,
    )[0];

    if (!severityObj)
      console.log(
        `No configuration found for severity level ${targetNodeSeverity}`,
      );

    const severityColor = severityObj.color;

    const graphTopologyNodesColor =
      (assetCategory === "All Categories" ||
        nodeDatum.category === assetCategory) &&
      (nodeDatum.name.toLowerCase().startsWith(asset.toLowerCase()) ||
        asset === "") &&
      colorFilter[severityColor] &&
      displayOptions[
        nodeDatum.category === ASSETS_CATEGORIES.LOCATION ? "location" : "asset"
      ]
        ? severityColor
        : "lightgrey";

    const networkTopologyNodesColor =
      nodeDatum.name.toLowerCase().startsWith(asset.toLowerCase()) ||
      asset === ""
        ? severityColor
        : "lightgrey";

    const placeholder = level === 1 ? "firstLevelId" : "secondLevelId";
    const identifier = nodeDatum.id;
    Tooltip(placeholder + identifier, nodeDatum);

    const deviceIcon =
      level === 1 ? (
        <LevelOneTopologyNode
          isDarkMode={isDarkMode}
          nodeDatum={nodeDatum}
          descendantsData={descendantsData}
          color={graphTopologyNodesColor}
          contextMenuData={
            categoryData.filter(
              (menuData) => menuData.code === nodeDatum.category,
            )[0]
          }
          categoryData={categoryData}
          severityData={severityData}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          handleDisableGraphTopoDrag={utilities.handleDisableGraphTopoDrag}
          handleEnableGraphTopoDrag={utilities.handleEnableGraphTopoDrag}
          displayOptions={displayOptions}
        />
      ) : (
        <LevelTwoTopologyNode
          toggleNode={utilities.toggleNode}
          isDarkMode={isDarkMode}
          nodeDatum={nodeDatum}
          descendantsData={descendantsData}
          placeholder={placeholder}
          identifier={identifier}
          color={networkTopologyNodesColor}
          contextMenuData={
            categoryData.filter(
              (menuData) => menuData.code === nodeDatum.category,
            )[0]
          }
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
      );
    return deviceIcon;
  };
}
