import { extensionMenus } from "globals/configuration/extension";

const Attributes = {
  formSize: "md",
  columns: 2,
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: extensionMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  create: "manufacturer/create",
  update: "manufacturer/update",
  list: "/manufacturer/filteredList",
  delete: "manufacturer/delete",
};

export { Attributes, operationURLS };
