import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";
import { IconButton } from "@mui/material";

function CTLCategoryServiceCell({ value }) {
  const [listOptions, setListOptions] = useState([]);

  useEffect(() => {
    application.post("severity/listAll").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);

  const matchedSeverity = listOptions.find(
    (option) => option.severityLevel === value
  );

  return (
    <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
      <IconButton
        style={{
          backgroundColor: matchedSeverity ? matchedSeverity.color : "",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        }}
      ></IconButton>
      {matchedSeverity && (
        <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
          &nbsp;&nbsp;&nbsp;
          {matchedSeverity.severityName.toUpperCase()}
        </MDTypography>
      )}
    </MDTypography>
  );
}



export default CTLCategoryServiceCell;
