import { v4 as uuidV4 } from "uuid";
import Wrapper from "layouts/nms/Wrapper";
function getDummyAlertsWidget(getDataURL, primaryLabel) {
  return {
    primaryLabel,
    secondaryLabel: null,
    getDataURL,
    setDataURL: null,
    apiCode: null,
    hasWebSocketUpdate: null,
    refreshInterval: 60000,
    thresholdLower: null,
    thresholdUpper: null,
    location: null,
    legendField: null,
    valueField: null,
    columns: [
      {
        field: "id",
        heading: "ID",
        type: "number",
        display: false,
      },
      {
        field: "assetId",
        heading: "Asset Id",
        type: "number",
        display: false,
      },
      {
        field: "severity",
        heading: "Severity",
        type: "severity",
        display: true,
      },
      {
        field: "categoryCode",
        heading: "Category",
        type: "string",
        display: true,
      },
      {
        field: "serviceCode",
        heading: "Service",
        type: "string",
        display: true,
      },
      {
        field: "ipAddress",
        heading: "IP Address",
        type: "string",
        display: true,
      },
      {
        field: "nodeName",
        heading: "Name",
        type: "string",
        display: true,
      },
      {
        field: "status",
        heading: "Status",
        type: "boolean",
        display: true,
      },
      {
        field: "alertGeneratedOn",
        heading: "Alert Generated On",
        type: "epoch",
        display: true,
      },
    ],
    actions: null,
    legendFieldDataType: null,
    valueFieldDataType: null,
    widgetType: "table",
    showLegends: null,
    imageName: null,
    width: null,
    height: null,
    category: null,
    min: null,
    max: null,
  };
}

function ActiveAlerts({ assetId }) {
  return (
    <Wrapper
      key={uuidV4()}
      id="1"
      isAdmin
      username="nkhanna"
      dashboardType="dashboard"
      popupMode
      isSensorsDashboard
      sensorsWidgetList={[
        {
          id: "AssetAlerts",
          data: getDummyAlertsWidget(
            `${process.env.REACT_APP_ASSET_ACTIVE_ALERTS_ENDPOINT}${assetId}`,
            "Asset Alerts",
          ),
        }]}
    />
  );
}

export default ActiveAlerts;
