import ASSETS_CATEGORIES from "../../../../configs/assetsConfigs";

function Icon({ category, color }) {
  let icon = null;
  
  switch (category) {
    case ASSETS_CATEGORIES.LOCATION:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        />
      );
      break;
    case ASSETS_CATEGORIES.L2:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M13 22h8v-7h-3v-4h-5V9h3V2H8v7h3v2H6v4H3v7h8v-7H8v-2h8v2h-3z"
        />
      );
      break;
    case ASSETS_CATEGORIES.L3:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M13 22h8v-7h-3v-4h-5V9h3V2H8v7h3v2H6v4H3v7h8v-7H8v-2h8v2h-3z"
        />
      );
      break;
    case ASSETS_CATEGORIES.L3_SWITCH:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M13 22h8v-7h-3v-4h-5V9h3V2H8v7h3v2H6v4H3v7h8v-7H8v-2h8v2h-3z"
        />
      );
      break;
    case ASSETS_CATEGORIES.IP_PHONE:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23.99 3H0v18h24l-.01-18zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.IP2LP:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23.99 3H0v18h24l-.01-18zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.IP2LG:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23.99 3H0v18h24l-.01-18zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.IP6LP:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23.99 3H0v18h24l-.01-18zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.VIP:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23.99 3H0v18h24l-.01-18zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.ICC3:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23 3H1v16h7v2h8v-2h7V3zm-2 14H3V5h18v12zm-2-9H8v2h11V8zm0 4H8v2h11v-2zM7 8H5v2h2V8zm0 4H5v2h2v-2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.ICC4:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M23 3H1v16h7v2h8v-2h7V3zm-2 14H3V5h18v12zm-2-9H8v2h11V8zm0 4H8v2h11v-2zM7 8H5v2h2V8zm0 4H5v2h2v-2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.CALL_SERVER:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.SERVER:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.FEATURE_SERVER:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.ENODEB:
      icon = (
        <>
          <path
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            d="m7.3 14.7 1.2-1.2c-1-1-1.5-2.3-1.5-3.5 0-1.3.5-2.6 1.5-3.5L7.3 5.3c-1.3 1.3-2 3-2 4.7s.7 3.4 2 4.7zM19.1 2.9l-1.2 1.2c1.6 1.6 2.4 3.8 2.4 5.9 0 2.1-.8 4.3-2.4 5.9l1.2 1.2c2-2 2.9-4.5 2.9-7.1 0-2.6-1-5.1-2.9-7.1z"
          />
          ;
          <path
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            d="M6.1 4.1 4.9 2.9C3 4.9 2 7.4 2 10c0 2.6 1 5.1 2.9 7.1l1.2-1.2c-1.6-1.6-2.4-3.8-2.4-5.9 0-2.1.8-4.3 2.4-5.9zm10.6 10.6c1.3-1.3 2-3 2-4.7-.1-1.7-.7-3.4-2-4.7l-1.2 1.2c1 1 1.5 2.3 1.5 3.5 0 1.3-.5 2.6-1.5 3.5l1.2 1.2zM14.5 10c0-1.38-1.12-2.5-2.5-2.5S9.5 8.62 9.5 10c0 .76.34 1.42.87 1.88L7 22h2l.67-2h4.67l.66 2h2l-3.37-10.12c.53-.46.87-1.12.87-1.88zm-4.17 8L12 13l1.67 5h-3.34z"
          />
          ;
        </>
      );
      break;
    case ASSETS_CATEGORIES.IMSI:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M2 7h2v7H2V7zm18 3h2v7h-2v-7zM6 2v20h12V2H6zm10 15H8V7h8v10z"
        />
      );
      break;
    case ASSETS_CATEGORIES.HELP_POINT:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M20 15v6c-3.28 0-6.35-.89-9-2.43A17.999 17.999 0 0 1 4.43 12C2.89 9.35 2 6.28 2 3h6l1 5-2.9 2.9c1.43 2.5 3.5 4.57 6 6L15 14l5 1zm-6-9h2V4h-2v2zm-1 3h2V7h-2v2zm-2-3h2V4h-2v2zm7 1h-2v2h2V7zm1-3h-2v2h2V4zm2 3h-2v2h2V7zm1-3h-2v2h2V4zm-8 8h2v-2h-2v2zm-3 0h2v-2h-2v2zm8-2h-2v2h2v-2zm3 0h-2v2h2v-2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.UN_IDENTIFIED:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8zm6 6h-4v-4h4v4z"
        />
      );
      break;
    case ASSETS_CATEGORIES.ROVER:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M18.92 9.01C18.72 8.42 18.16 8 17.5 8h-11c-.66 0-1.21.42-1.42 1.01L3 15v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 10h10.29l1.04 3H5.81l1.04-3zM6 17.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S8.33 19 7.5 19 6 18.33 6 17.5zm9 0c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5zM9.41 5 8 6.41l-3-3L6.41 2l3 3zM16 6.41 14.59 5l3-3L19 3.41l-3 3zM13 5h-2V0h2v5z"
        />
      );
      break;
    case ASSETS_CATEGORIES.PC:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M20 18c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z"
        />
      );
      break;
    case ASSETS_CATEGORIES.LAPTOP:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M20 18c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z"
        />
      );
      break;
    case ASSETS_CATEGORIES.AHD:
      icon = (
        <>
          <path
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"
          ></path>
          <circle
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            cx="15"
            cy="13"
            r="1"
          ></circle>
          <circle
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            cx="9"
            cy="13"
            r="1"
          ></circle>
          <path
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            d="M18 11.03C17.52 8.18 15.04 6 12.05 6c-3.03 0-6.29 2.51-6.03 6.45 2.47-1.01 4.33-3.21 4.86-5.89 1.31 2.63 4 4.44 7.12 4.47z"
          />
        </>
      );
      break;
    case ASSETS_CATEGORIES.VHD:
      icon = (
        <>
          <path
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            d="M21 3H3c-1.1 0-2 .9-2 2v8h2V5h18v16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
          />
          <circle
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            cx="9"
            cy="10"
            r="4"
          />
          <path
            fill={color}
            strokeWidth={color === "yellow" ? ".5" : "0"}
            stroke="black"
            d="M15.39 16.56C13.71 15.7 11.53 15 9 15s-4.71.7-6.39 1.56C1.61 17.07 1 18.1 1 19.22V22h16v-2.78c0-1.12-.61-2.15-1.61-2.66z"
          />
        </>
      );
      break;
    case ASSETS_CATEGORIES.GW:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="m20.2 5.9.8-.8C19.6 3.7 17.8 3 16 3s-3.6.7-5 2.1l.8.8C13 4.8 14.5 4.2 16 4.2s3 .6 4.2 1.7zm-.9.8c-.9-.9-2.1-1.4-3.3-1.4s-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1l.8-.8zM19 13h-2V9h-2v4H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM8 18H6v-2h2v2zm3.5 0h-2v-2h2v2zm3.5 0h-2v-2h2v2z"
        />
      );
      break;
    case ASSETS_CATEGORIES.TRUNK_GATEWAY:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="m20.2 5.9.8-.8C19.6 3.7 17.8 3 16 3s-3.6.7-5 2.1l.8.8C13 4.8 14.5 4.2 16 4.2s3 .6 4.2 1.7zm-.9.8c-.9-.9-2.1-1.4-3.3-1.4s-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1l.8-.8zM19 13h-2V9h-2v4H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM8 18H6v-2h2v2zm3.5 0h-2v-2h2v2zm3.5 0h-2v-2h2v2z"
        />
      );
      break;
    default:
      icon = (
        <path
          fill={color}
          strokeWidth={color === "yellow" ? ".5" : "0"}
          stroke="black"
          d="M6 22h12l-6-6-6 6zM23 3H1v16h6v-2H3V5h18v12h-4v2h6V3z"
        />
      );
      break;
  }

  return icon;
}

export default Icon;
