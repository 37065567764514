const kmAdminMenus = [
  {
    icon: "timer",
    key: "configuration.timer",
    name: "Configuration Timer",
    url: "home/app/configurations/timer",
    description: "Configuration Timer",
  },
  {
    icon: "call",
    key: "uc.External.api.call.log",
    name: "External API call log",
    description: "external api call log",
    url: "home/app/uc/External-API-call-log",
    messageType: "",
    count: "",
  },
];

export { kmAdminMenus };
