import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
function AlertDelete({ isDelete, setIsDelete, handleConfirmDelete, name }) {
  const handleClose = () => {
    setIsDelete(false);
  };
  const { darkMode } = useMaterialUIController()[0];

  return (
    <Box>
      <Dialog
        // fullWidth={fullWidth}
        maxWidth="sm"
        open={isDelete}
        onClose={handleClose}
      >
        {/* <DialogTitle textAlign="center"> </DialogTitle> */}
        <MDBox
          sx={{
            backgroundColor: darkMode ? "#202940" : "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "30rem",
            paddingBottom: "20px",
          }}
        >
          <Box sx={{ fontSize: "5rem", color: "red" }}>
            <FiAlertCircle />
          </Box>
          <MDTypography variant="h5" sx={{ fontWeight: "600" }}>
            {" "}
            Do you want to Delete this {name}?
          </MDTypography>
        </MDBox>

        <DialogActions
          sx={{ backgroundColor: darkMode ? "#202940" : "#FFFFFF" }}
        >
          <MDBox mr={5} pb={2} display="flex" gap={2}>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={handleConfirmDelete}
            >
              Confirm
            </MDButton>
            <MDButton
              variant="gradient"
              color="light"
              size="medium"
              onClick={handleClose}
            >
              Close
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AlertDelete;
