import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import CTLDashboardGroupCell from "application/components/CTLDashboardGroupCell";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: false,
  enableGroupHeading: false,
  enableApiAccess : true,
  verticalTabs: false,
  tabSize: 4,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "/dashboard/list",
  update: "/dashboard/update",
  register: "/dashboard/create ",
  delete: "/dashboard/delete",
  accessKey: "/user/createAPIKey",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl
      .string().nullable().required("Required"),
    },
    {
      Header: "DashboardGroup",
      accessor: "dashboardGroup",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      width: 6,
      Cell: ({ value }) => <CTLDashboardGroupCell value={value} />,
      tabId: 0,
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "/dashboardGroup/listAll",
      },
    },
    {
      Header: "Dashboard Layout",
      accessor: "dashboardLayout",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "imgSelect",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          {
            id: "dashboard-l1",
            description: "Layout 1",
            imageSrc: "/images/layout1.png",
          },
          {
            id: "dashboard-l2",
            description: "Layout 2",
            imageSrc: "/images/layout2.png",
          },
          {
            id: "dashboard-l3",
            description: "Layout 3",
            imageSrc: "/images/layout3.png",
          },
          {
            id: "dashboard-l4",
            description: "Layout 4",
            imageSrc: "/images/layout4.png",
          },
        ],
      },
    },
    {
      Header: "Sensor",
      accessor: "sensor",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "LegendFieldDataType",
      accessor: "legendFieldDataType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "ValueFieldDataType",
      accessor: "valueFieldDataType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "WidgetType",
      accessor: "widgetType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Api",
      accessor: "api",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "RefreshInterval",
      accessor: "refreshInterval",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "ChartType",
      accessor: "chartType",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
    },
    {
      Header: "ImageName",
      accessor: "imageName",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Default Dashboard",
      accessor: "isDefaultDashboard",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Enabled",
      accessor: "enabled",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "export",
      accessor: "export",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      width: 4,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "HasWebSocketUpdate",
      accessor: "hasWebSocketUpdate",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
