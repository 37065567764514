import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";

const getColumns = (onView) => {
  return [
    {
      Header: "Campaign Name",
      accessor: "campaignName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Lead Id",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Date of Lead Uplaod",
      accessor: "recordCreatedOn",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "File",
      accessor: "filename",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "uniqueLeadUpload",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onView={onView}
        />
      ),
    },
  ];
};

export { getColumns };
