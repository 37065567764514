const renderSvgLink = (isDarkMode) =>
  function ({ link, ...restProps }) {
    return (
      <>
        <g strokeWidth=".6" stroke={isDarkMode ? "white" : "black"}>
          <line {...restProps} markerEnd="url(#SvgjsMarker1216)" />
        </g>
        <defs>
          <marker
            markerWidth="25"
            markerHeight="25"
            refX="300"
            refY="16"
            viewBox="0 0 33 33"
            orient="auto"
            id="SvgjsMarker1216"
            fill={isDarkMode ? "white" : "black"}
          >
            <polygon points="0,33 16.5,16.5 0,0 33,16.5"></polygon>
          </marker>
        </defs>
      </>
    );
  };

export default renderSvgLink;
