import { useCallback, useState } from "react";

export default function useCenteredTree() {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 8, y: height / 2.2 });
    }
  }, []);
  return [translate, containerRef];
}
