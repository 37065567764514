import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";

const Attributes = {
  enableAddButton: true,
  modes: [
    {
      id: "generalSettings",
      name: "General Settings",
      iconName: "add",
    },
    {
      id: "codeBlue",
      name: "Code Blue",
      iconName: "folder",
    },
    {
      id: "spacs",
      name: "SPACS",
      iconName: "folder",
    },
  ],
};

const getColumns = (onDelete) => {
  return [
    { Header: "Extension no", accessor: "extension",displayInList: true, allowFilter:true, dataType: "string", displayInDeleteConfirmation: true, dataType: "string", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Name", accessor: "name", displayInList: true, allowFilter:true, dataType: "string", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "", accessor: "id",  displayInList: true, Cell: ({ row, value }) => <RowAction row={row} value={value} onDelete={onDelete} /> }
  ]
};

export { getColumns, Attributes };
