import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import MDSnackbar from "components/MDSnackbar";

function CategoryService({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onAgree = (row) => {
    application.post(operationURLS.delete, {
      id: [row.id]
    }).then((response) => {
      setUpdateList(Date.now());
    });

    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  function onCloseForm() {
    setOpenForm(false);
  }

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          openSuccessSB();
        })
        .catch((error) => {
          console.error('Error updating data:', error);
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          openSuccessSB();
        })
        .catch((error) => {
          openErrorSB();
        });
    }
    setOpenForm(false);
  }



  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );


  return (
    <MDBox>
      <DeleteConfirmation
        entityName="Category_Service"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLBaseDialogForm
        entityName="Category Service"
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLAdvancedFilter
        entityName="Category Service"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="serviceCode"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
      />
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default CategoryService;
