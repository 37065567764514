import MDTypography from "components/MDTypography";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

function CTLBooleanUser({ value, suffix }) {
  if (value === 1) {
    suffix = <CheckIcon fontSize="small" />;
  }
  if (value === 0) {
    suffix = <CloseIcon fontSize="small" />;
  } else if (value === null) {
    suffix = <NotInterestedIcon fontSize="small" />;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="white">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLBooleanUser.defaultProps = {
  suffix: "",
};

export default CTLBooleanUser;
