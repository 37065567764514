const nodeMenus = [
    {
        icon: "workspaces",
        key: "configuration.cluster",
        name: "Nodes",
        url: "home/app/configurations/cluster",
        description: "Cluster Nodes",
        disabled: false, 
        productionReady : true
      },
  ];
  
  export { nodeMenus };
  