const paMenu = [
  {
    icon: "receipt",
    key: "pareport",
    name: "PA Reports",
    url: "home/app/configurations/PAreport",
    description: "PA-Reports",
    disabled: false, 
    productionReady : true
  },
];
export default paMenu;

