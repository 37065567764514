import { useState } from "react";
import styles from "../../css/Topology.module.css";
import ContextMenu from "../ContextMenu";
import Icon from "../../Icon";
import ASSETS_CATEGORIES from "../../../../../configs/assetsConfigs";

function LevelTwoTopologyNode({
  toggleNode,
  isDarkMode,
  nodeDatum,
  descendantsData,
  placeholder,
  identifier,
  color,
  contextMenuData,
}) {
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };
  const handleClose = () => setContextMenu(null);

  const handleSmartAssetPopupOpen = () => {
    setSmartAssetPopupOpen(true);
    handleClose();
  };

  const handleDashboard = () => {
    if (nodeDatum.category === ASSETS_CATEGORIES.LOCATION) return;
    handleSmartAssetPopupOpen();
  };

  return (
    <>
      <g
        className={styles.icon}
        onClick={() => toggleNode()}
        onContextMenu={handleContextMenu}
        style={{ cursor: "context-menu" }}
      >
        <circle
          fill={isDarkMode ? "#1a2035" : "white"}
          strokeWidth={0}
          r="35"
        />
        {nodeDatum.children.length > 0 && (
          <g>
            <circle
              strokeWidth="0"
              r="10"
              fill="#5bed37"
              transform="translate(-60,-50)"
            />
            <text
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform="translate(-48,-43)"
            >
              {descendantsData[nodeDatum.id].active}
            </text>
            <circle
              strokeWidth="0"
              r="10"
              fill="#ed4242"
              transform="translate(10,-50)"
            />
            <text
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform="translate(25,-43)"
            >
              {descendantsData[nodeDatum.id].inactive}
            </text>
          </g>
        )}
        <g id={placeholder + identifier}>
          <circle fill="transparent" strokeWidth={0} r="60" />
          <g strokeWidth={0} transform="translate(-35,-36) scale(3)">
            <Icon category={nodeDatum.category} color={color} />
          </g>
        </g>
        <text
          fill={isDarkMode ? "white" : "black"}
          strokeWidth=".2"
          transform="translate(-35,60)"
        >
          {nodeDatum.name}
        </text>
        <ContextMenu
          contextMenuData={contextMenuData}
          contextMenu={contextMenu}
          handleClose={handleClose}
          assetId={nodeDatum.id}
          ipAddress={nodeDatum.ipAddress}
          handleDashboard={handleDashboard}
        />
      </g>
    </>
  );
}

export default LevelTwoTopologyNode;
