import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import CampaignGatewayCell from "application/components/campaignGatewayCell";
import CampaignTypeCell from "application/components/campaignTypeCell";
import CampaignAutoAttendantCell from "application/components/campaignAutoAttendantCell";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const updateAttributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enablePaste: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  tabSize: 2,
  settingsMode: false,
  enableAcdDetails: true,
  selectUrl: "/queue/list/acdList",
  tabs: [
    {
      id: 0,
      name: "General Settings",
    },
    {
      id: 1,
      name: "Adavance Settings",
    },
  ],
};

const updateOperationURLS = {
  list: "/campaign/list",
  update: "campaign/update",
  create: "/campaign/create",
  delete: "campaign/delete",
  paste: "/campaign/create",
};

const getUpdateColumns = (onEdit) => {
  return [
    {
      Header: "Campaign Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 0,
    },
    {
      Header: "Campaign Short Code",
      accessor: "shortCode",
      displayInList: true,
      allowInForm: true,
      noneditable: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      tabId: 0,
    },
    {
      Header: "From Date",
      accessor: "fromDate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "date",
      setChange: "toDate",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 0,
    },
    {
      Header: "To Date",
      accessor: "toDate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 0,
    },
    {
      Header: "From Time",
      accessor: "fromTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "time",
      setChange: "toTime",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 0,
    },
    {
      Header: "To Time",
      accessor: "toTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      controlType: "time",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 0,
    },
    {
      Header: "Campaign Type",
      accessor: "campaignType",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "0", description: "General" },
          { id: "1", description: "Abandoned" },
          { id: "2", description: "NBH" },
          { id: "3", description: "Blaster" },
          { id: "4", description: "Announcement" },
          { id: "5", description: "Preview Dialing Type 1" },
          { id: "6", description: "Preview Dialing Type 2" },
          { id: "7", description: "Preview Dialing Type 3" },
        ],
      },
      Cell: ({ value }) => <CampaignTypeCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 0,
    },
    {
      Header: "Autoattendant No",
      accessor: "autoAttendantId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CampaignAutoAttendantCell value={value} />,
      width: 6,
      tabId: 0,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "extension",
        values: [],
        url: "autoAttendant/listAll",
      },
    },
    {
      Header: "Gateway",
      accessor: "gatewayId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "gwCode",
        values: [],
        url: "gateway/listAll",
      },
      Cell: ({ value }) => <CampaignGatewayCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 12,
      tabId: 0,
    },
    {
      Header: "Call Load",
      accessor: "callLoad",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Total Try",
      accessor: "totalTry",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "No Answer Cause",
      accessor: "noAnswerCause",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "No Answer Interval",
      accessor: "noAnswerInterval",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl
        .string()
        .required("Required")
        .digitsOnly()
        .nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Busy Cause",
      accessor: "busyCause",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Busy Interval",
      accessor: "busyInterval",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl
        .string()
        .required("Required")
        .digitsOnly()
        .nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Not Reachable Cause",
      accessor: "notReachableCause",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Not Reachable Interval",
      accessor: "notReachableInterval",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl
        .string()
        .required("Required")
        .digitsOnly()
        .nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Stop dialing",
      accessor: "isDisabled",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      controlType: "boolean",
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Notification Level1 (%) ",
      accessor: "notificationLevel1",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.number().numberFrom1to100(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Notification Level2 (%) ",
      accessor: "notificationLevel2",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.number().numberFrom1to100(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Notification Level3 (%) ",
      accessor: "notificationLevel3",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.number().numberFrom1to100(),
      width: 6,
      tabId: 1,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getUpdateColumns, updateAttributes, updateOperationURLS };
