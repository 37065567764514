import React from "react";
import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Paper, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import SendIcon from "@material-ui/icons/Send";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const Chat = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const SenderBubble = ({ name, message, time, avatarSrc }) => (
    <MDBox>
      <Paper
        elevation={3}
        style={{
          background: darkMode ? "#154c79" : "#b7c4e8",
          marginLeft: "40px",
          padding: "2px",
          marginBottom: "7px",
          display: "flex",
          alignItems: "left",
          borderRadius: "20px 20px 20px 20px",
        }}
      >
        <ListItemText
          align="right"
          primary={
            <MDTypography
              variant="caption"
              style={{
                color: darkMode ? "#fff" : "#000",
              }}
            >
              {name}
            </MDTypography>
          }
          secondary={
            <>
              <MDTypography
                variant="body"
                style={{
                  color: darkMode ? "#fff" : "#000",
                }}
              >
                {message}
              </MDTypography>

              <MDTypography
                variant="caption"
                style={{
                  color: darkMode ? "#fff" : "#000",
                  marginLeft: "5px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {time}
              </MDTypography>
            </>
          }
        />
        &nbsp;
        <ListItemAvatar style={{ padding: "5px" }}>
          <Avatar alt={name} src={avatarSrc} />
        </ListItemAvatar>
      </Paper>
    </MDBox>
  );

  const ReceiverBubble = ({ name, message, time, avatarSrc }) => (
    <Paper
      elevation={3}
      style={{
        background: darkMode ? "#063970" : "#e6e9f0",
        color: darkMode ? "#fff" : "#000",
        padding: "2px",
        marginBottom: "7px",
        display: "flex",
        alignItems: "right",
        marginRight: "40px",
        borderRadius: "20px 20px 20px 20px",
      }}
    >
      <ListItemAvatar style={{ padding: "8px" }}>
        <Avatar alt={name} src={avatarSrc} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <MDTypography
            variant="caption"
            style={{
              color: darkMode ? "#fff" : "#000",
            }}
          >
            {name}
          </MDTypography>
        }
        secondary={
          <>
            <MDTypography
              variant="body"
              style={{
                color: darkMode ? "#fff" : "#000",
              }}
            >
              {message}
            </MDTypography>
            <MDTypography
              variant="caption"
              style={{
                color: darkMode ? "#fff" : "#000",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "5px",
              }}
            >
              {time}
            </MDTypography>
          </>
        }
      />
    </Paper>
  );

  return (
    <MDBox
      style={{
        minHeight: "calc(100vh - 130px)",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
      }}
    >
      <MDBox flex="1" overflow="auto" maxHeight="calc(100vh - 180px)">
        <ReceiverBubble
          name="Alice"
          message="Hey, I am Good! What about you?"
          time="09:31"
          avatarSrc="https://material-ui.com/static/images/avatar/3.jpg"
        />
        <SenderBubble
          name="John Doe"
          message="Hey man, What's up?"
          time="09:30"
          avatarSrc="https://material-ui.com/static/images/avatar/1.jpg"
        />
        <ReceiverBubble
          name="Alice"
          message="Hey, I am Good! What about you?"
          time="09:31"
          avatarSrc="https://material-ui.com/static/images/avatar/3.jpg"
        />
        <SenderBubble
          name="John Doe"
          message="Cool. I am good, let's catch up!"
          time="10:30"
          avatarSrc="https://material-ui.com/static/images/avatar/1.jpg"
        />
        <ReceiverBubble
          name="Alice"
          message="Ok let's meet! Ok let's meet!"
          time="10:31"
          avatarSrc="https://material-ui.com/static/images/avatar/3.jpg"
        />
      </MDBox>
      <Grid container spacing={1} margin={1}>
        <Grid item xs={10}>
          <TextField
            id="outlined-basic"
            label="Type your message"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={2}>
          <MDButton
            color="info"
            aria-label="send message"
            variant="contained"
            style={{ width: "100%", height: "100%" }}
            onClick={() => {}}
          >
            <SendIcon />
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Chat;
