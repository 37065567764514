export default function getSpeedometerLimits(speedometerData) {
  const min = speedometerData.filter(
    (item) => item.attributeType === "minValue",
  )[0];
  const max = speedometerData.filter(
    (item) => item.attributeType === "maxValue",
  )[0];
  const lower = speedometerData.filter(
    (item) => item.attributeType === "lowerThreshold",
  )[0];
  const upper = speedometerData.filter(
    (item) => item.attributeType === "upperThreshold",
  )[0];
  const current = speedometerData.filter(
    (item) => item.attributeType === "currentValue",
  )[0];

  const minValue = min ? parseFloat(min.attributeValue) : 0;
  const maxValue = max ? parseFloat(max.attributeValue) : 100;
  let lowerValue = null;
  let upperValue = null;

  if(lower && upper){
    lowerValue = parseFloat(lower.attributeValue);
    upperValue = parseFloat(upper.attributeValue);
  } else if (lower){
    lowerValue = parseFloat(lower.attributeValue);
    upperValue = parseFloat(lower.attributeValue);
  } else if (upper){
    lowerValue = parseFloat(upper.attributeValue);
    upperValue = parseFloat(upper.attributeValue);
  } else {
    lowerValue = parseFloat(max.attributeType);
    upperValue = parseFloat(max.attributeType);
  }

  return {
    minValue,
    maxValue,
    lowerValue,
    upperValue,
    currentValue: parseFloat(current.attributeValue),
  }
}
