import { useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Draggable } from "@hello-pangea/dnd";
import { useMaterialUIController } from "context";
import { useGetTopologyDataQuery, useGetDataQuery } from "features/apiSlice";
import topologyData from "../../data/topologyData";
import RenderGraphTopology from "./RenderGraphTopology";
import nodeCount from "../../utils/nodeCount";
import ContextMenu from "../ContextMenu";
import SeverityContext from "../../context/severityContext";

function CGraph({
  widgetHeight,
  widget,
  cellWidgetInfo,
  index,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  containerType,
  isEditMode,
  isAdmin,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [isNodeRightClicked, setIsNodeRightClicked] = useState(false);

  const handleIsNodeRightClicked = (value) => setIsNodeRightClicked(value);

  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  const handleContextMenu = (event) => {
    event.preventDefault();

    if (isNodeRightClicked) return;
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  let getDataURL = null;
  let getCategoryDataURL = null;
  let refreshInterval = 0;
  if (widget) {
    getDataURL = widget.getDataURL;
    getCategoryDataURL = process.env.REACT_APP_CATEGORY_LIST_ENDPOINT;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
  }

  const topologyQuery = getDataURL
    ? useGetTopologyDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const categoryListQuery = getCategoryDataURL
    ? useGetDataQuery(getCategoryDataURL)
    : {};

  const severityListQuery = useContext(SeverityContext);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let secondaryLabel = null;
  let data = null;
  let descendantsData = null;
  let categoryData = null;
  let severityData = null;
  let error = null;
  if (widget) {
    if (topologyQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      data = topologyQuery.data;
      descendantsData = nodeCount(topologyQuery.data);
      categoryData = categoryListQuery.data ? categoryListQuery.data : [];
      severityData = severityListQuery.data ? severityListQuery.data : [];
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      data = [];
      descendantsData = {};
      categoryData = [];
      severityData = [];
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    data = topologyData;
    descendantsData = nodeCount(topologyData);
    categoryData = [];
    severityData = [];
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
  }

  const [displayOptions, setDisplayOptions] = useState(
    JSON.parse(localStorage.getItem("displayOptions")) || {
      locationName: true,
      location: true,
      asset: false,
    },
  );

  const [colorFilter, setColorFilter] = useState({});
  const [asset, setAsset] = useState("");
  const [assetCategory, setAssetCategory] = useState("All Categories");
  const [layout, setLayout] = useState("cose");

  useEffect(() => {
    if (severityData)
      setColorFilter(() => {
        const colorFilter = {};
        severityData.forEach((severity) => {
          colorFilter[severity.color] = true;
        });
        return colorFilter;
      });
  }, [severityData]);

  const handleDisplayOptionsChange = (event) =>
    setDisplayOptions((prevDisplayOptions) => ({
      ...prevDisplayOptions,
      [event.target.name]: event.target.checked,
    }));

  useEffect(() => {
    localStorage.setItem("displayOptions", JSON.stringify(displayOptions));
  }, [displayOptions]);

  const handleColorFilterChange = (event) =>
    setColorFilter((prevColorFilter) => ({
      ...prevColorFilter,
      [event.target.name]: event.target.checked,
    }));

  const handleSearchAsset = (filterText) => setAsset(filterText);

  const handleAssetCategoryChange = (event) =>
    setAssetCategory(event.target.value);

  const handleLayoutChange = (event) => setLayout(event.target.value);

  const graphTopologyContainer = (provided = { dragHandleProps: {} }) => (
    <MDBox p={2} position="relative" height="90%">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
        {...provided.dragHandleProps}
      >
        <Icon fontSize="small">hub</Icon>
      </MDBox>
      {useMemo(
        () =>
          Object.keys(colorFilter).length > 0 && (
            <RenderGraphTopology
              isEditMode={isEditMode}
              isAdmin={isAdmin}
              primaryLabel={primaryLabel}
              secondaryLabel={secondaryLabel}
              data={data}
              descendantsData={descendantsData}
              categoryData={categoryData}
              severityData={severityData}
              isDarkMode={isDarkMode}
              masterContainerId={masterContainerId}
              cellId={cellId}
              handleDeleteWidget={handleDeleteWidget}
              index={index}
              error={error}
              colorFilter={colorFilter}
              handleColorFilterChange={handleColorFilterChange}
              asset={asset}
              handleSearchAsset={handleSearchAsset}
              assetCategory={assetCategory}
              handleAssetCategoryChange={handleAssetCategoryChange}
              displayOptions={displayOptions}
              handleDisplayOptionsChange={handleDisplayOptionsChange}
              layout={layout}
              handleLayoutChange={handleLayoutChange}
              topologyQuery={topologyQuery}
              handleIsNodeRightClicked={handleIsNodeRightClicked}
            />
          ),
        [
          topologyQuery.data,
          categoryListQuery.data,
          severityListQuery.data,
          displayOptions,
          colorFilter,
          asset,
          assetCategory,
          layout,
        ],
      )}
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
            height:
              Object.keys(data).length > 0
                ? parseInt(widgetHeight, 10) * 12
                : parseInt(widgetHeight, 10) * 4,
          }}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {graphTopologyContainer(provided)}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
        height:
          Object.keys(data).length > 0
            ? parseInt(widgetHeight, 10) * 12
            : parseInt(widgetHeight, 10) * 4,
        cursor: "context-menu",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {graphTopologyContainer()}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default CGraph;
