import { agentMenu } from "globals/configuration/agent/agentMenu";
import { agentReportMoreVert } from "globals/configuration/agentReportMoreVert";

const agentAttributes = {
  formSize: "md",
  columns: 3,
  settingsMode: false,
  enableChatButton: false,
  modes: agentReportMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

if (window.isCustomer) {
  agentAttributes.enableChatButton = true;
  agentAttributes.modes = agentMenu.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  });
}

const agentOperationURLS = {
  agentCreate: "/agent/create",
  create: "/dynamicPopUpData/create",
};

export { agentAttributes, agentOperationURLS };
