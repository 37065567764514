const kanAdminMenus = [
    {
        icon: "menu",
        key: "scada.Asset",
        name: "Scada Asset",
        description: "Scada Assest",
        url: "home/app/scadaAsset",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "category",
        key: "category",
        name: "Category",
        description: "Category",
        url: "home/app/Category",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "navigation",
        key: "Location",
        name: "Location",
        description: "Location",
        url: "home/app/Location",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "ping",
        key: "Service.Master",
        name: "Service Master",
        description: "Service Master",
        url: "home/app/ServiceMaster",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "category",
        key: "Category.Service",
        name: "Category Service",
        description: "Category Service",
        url: "home/app/CategoryService",
        disabled: false,
        productionReady: true,
      },
      {
        icon: "dns",
        key: "configuration.subnet-master",
        name: "Discover-Subnet-Master",
        url: "home/app/configurations/subnet-master",
        description: "Subnet-Master",
        productionReady: true,
        disabled: false,
      },
      {
        icon: "crisis_alert",
        key: "configuration.nms-alert-history",
        name: "Nms-Alert-History",
        url: "home/app/configurations/nms-alert-history",
        description: "Nms-Alert-History",
        productionReady: true,
        disabled: false,
      },
      {
        icon: "phone",
        key: "configuration.telephonyDeployment",
        name: "Telephony-Deployment",
        description: "Telephony-Deployment",
        url: "home/app/configuration/telephonyDeployment",
        disabled: false,
        productionReady: true,
      },
  ];
  
  export { kanAdminMenus };
  