import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";

function SecurityConfiguration({ onLoad, pageName }) {
  const componentName = "Security Configuration";
  const [formData, setFormData] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  function onSaveForm(data) {
    loggerFactory.debug(componentName, "Data to update", data);
    application
      .post(operationURLS.update, data)
      .then((response) => {
        setNotify({
          isOpen: true,
          type: "success",
          pagename: pageName,
          message: response.data.messageDetail,
        });
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          loggerFactory.debug(
            componentName,
            "Unable to save the data.",
            error
          );
        }
        setNotify({
          isOpen: true,
          status: error.response.status,
          message: response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
  }

  useEffect(() => {
    Attributes && onLoad(Attributes);
    application.post(operationURLS.list).then((response) => {
      let dataToDisplay = response.data.data[0];
      loggerFactory.debug(componentName, "Data Received", dataToDisplay);
      setFormData(dataToDisplay);
    });
  }, []);
  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Security Configuration"
        columns={getColumns(null, null)}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify}/>
    </MDBox>
  );
}

export default SecurityConfiguration;
