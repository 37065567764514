import MDTypography from "components/MDTypography";

function StaticDropDownCell({ value, suffix }) {
  let suffixText = suffix;

  if (value >= 2 && value <= 64) {
    suffixText = `Acd Queue ${value}`;
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffixText && (
        <MDTypography variant="caption" fontWeight="medium" color="text">
          &nbsp;&nbsp;{suffixText}
        </MDTypography>
      )}
    </MDTypography>
  );
}

StaticDropDownCell.defaultProps = {
  suffix: "",
};

export default StaticDropDownCell;
