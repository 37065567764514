import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";


const getUpdateColumns = (onDelete, onEdit) => {
  return [
 
    {
      Header: "State",
      accessor: "state",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "Unknown", description: "Unknown" },
          { id: "No Answer", description: "No Answer" },
          { id: "Ready", description: "Ready" },
          { id: "Offering", description: "Offering" },
          { id: "Active Inbound", description: "Active Inbound" },
          { id: "Standby", description: "Standby" },
          { id: "Not Assigned", description: "Not Assigned" },
        ],
      },
    },
    {
      Header: "position",
      accessor: "position",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,

    }, 
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getUpdateColumns };
