import DefaultCell from "application/components/DefaultCell";
import UpdateAsRead from "application/components/UpdateAsRead";
import CTLUnReadCell from "application/components/CTLUnReadCell";

const Attributes = {
  refetchInterval: 5000,
  enableMultipleDeleteButton: true,
  enableMarkAsReadButton: true,
  enableAdvanceSearch: true,
  modes: [],
};

const operationURLS = {
  list: "/messageDetail/list",
  delete: "/messageDetail/delete",
  update:"/messageDetail/update",
};

const getColumns = (markAsRead,  onDelete) => {
  return [
    {
      Header: "Received On",
      accessor: "recordDate",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Caller Ext",
      accessor: "sourceNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Caller",
      accessor: "destinationName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Message",
      accessor: "message",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "",
      accessor: "isMessageRead",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <UpdateAsRead
          row={row}
          value={value}
          markAsRead={markAsRead}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
