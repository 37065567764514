import { useState } from "react";
import Tree from "react-d3-tree";
import Legend from "../../Legend";
import Zoom from "../Zoom";
import renderSvgNode from "../../renderSvgNode";
import AssetFilter from "../../AssetFilter";

function RenderPopupTopology({
  data,
  categoryData,
  descendantsData,
  severityData,
  asset,
  handleSearchAsset,
  isDarkMode,
  translate,
  isAdmin,
  isEditMode,
}) {
  const [zoomLevel, setZoomLevel] = useState(0.5);

  const renderedNode = renderSvgNode(
    descendantsData,
    isDarkMode,
    categoryData,
    severityData,
    {},
    {},
    asset,
    "",
    isAdmin,
    isEditMode,
    2,
  );

  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(1, prevZoomLevel + 0.1));
  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(0.1, prevZoomLevel - 0.1));

  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      // Target node has no children -> this link leads to a leaf node.
      return isDarkMode ? "link__to-leaf-dark" : "link__to-leaf-light";
    }

    // Style it as a link connecting two branch nodes by default.
    return isDarkMode ? "link__to-branch-dark" : "link__to-branch-light";
  };

  return (
    <>
      <AssetFilter asset={asset} handleSearchAsset={handleSearchAsset} level={2}/>
      {data.length > 0 && <Zoom zoomIn={zoomIn} zoomOut={zoomOut} />}
      {data.length > 0 && (
        <Legend
          title="Severity"
          isDarkMode={isDarkMode}
          level={2}
          categories={severityData.map((severity) => ({
            color: severity.color,
            colorName: severity.color,
            name: severity.severityName,
          }))}
        />
      )}
      {data.length > 0 && (
        <Tree
          data={data[0]}
          transitionDuration={0}
          translate={translate}
          zoom={zoomLevel}
          separation={{ nonSiblings: 1.5, siblings: 1.2 }}
          depthFactor={
            data[0].children.length * 100 < 500
              ? "300"
              : data[0].children.length * 50
          }
          renderCustomNodeElement={renderedNode}
          shouldCollapseNeighborNodes
          pathClassFunc={getDynamicPathClass}
          linkClass="my-link-class"
        />
      )}
    </>
  );
}

export default RenderPopupTopology;
