import { drag } from "d3-drag";
import { select } from "d3-selection";
import { dragended, dragged, dragstarted, dragsubject } from "./events";

export const addHoverOpacity = (node, link, hoverOpacity) => {
  node
    .on("mouseover", function (d) {
      node.style("opacity", hoverOpacity);
      select(this).style("opacity", "1");
      link.style("opacity", (link_d) =>
        link_d.source.id === d.id || link_d.target.id === d.id
          ? "1"
          : hoverOpacity,
      );
    })
    .on("mouseout", function (d) {
      node.style("opacity", "1");
      link.style("opacity", "1");
    });
  return { node, link };
};

export const addDrag = (node, simulation, enableDrag, pullIn) => {
  if (enableDrag) {
    node.call(
      drag()
        .subject(() => dragsubject(simulation))
        .on("start", () => dragstarted(simulation))
        .on("drag", dragged)
        .on("end", pullIn ? () => dragended(simulation) : null),
    );
  } else {
    node.call(
      drag()
        .subject(() => dragsubject(simulation))
        .on("start", null)
        .on("drag", null)
        .on("end", null),
    );
  }
  return node;
};
