import Tooltip from "../Tooltip";
import Node from "../Node";

export default function renderSvgNode(
  descendantsData,
  categoryData,
  severityData,
  isDarkMode,
  handleTreeDataConfiguration,
  isEditMode,
  isAdmin,
) {
  return ({ nodeDatum }) => {
    const targetNodeSeverity = descendantsData[nodeDatum.id].severity || 0;
    const severityObj = severityData.filter(
      (severity) => severity.severityLevel === targetNodeSeverity,
    )[0];

    if (!severityObj)
      console.log(
        `No configuration found for severity level ${targetNodeSeverity}`,
      );

    const color = severityObj.color;
    const placeholder = "ID";
    const identifier = nodeDatum.id;
    Tooltip(placeholder + identifier, nodeDatum);
    const deviceIcon = (
      <Node
        isDarkMode={isDarkMode}
        nodeDatum={nodeDatum}
        descendantsData={descendantsData}
        placeholder={placeholder}
        identifier={identifier}
        color={color}
        handleTreeDataConfiguration={handleTreeDataConfiguration}
        categoryData={categoryData}
        severityData={severityData}
        isEditMode={isEditMode}
        isAdmin={isAdmin}
      />
    );
    return deviceIcon;
  };
}
