import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import MDButton from "components/MDButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useMaterialUIController } from "context";
import React, { useEffect } from "react";

function CTLConferenceCancel({
  confirmCancel,
  handleAgreeCancel,
  onCancel,
  eventData,
}) {
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    setOpen(confirmCancel);
  }, [confirmCancel]);

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
        >
          Are you sure you want to Cancel the Conference
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
           <Typography variant="h5">
            {eventData && eventData.roomName}
          </Typography>
          <Typography variant="subtitle2" display="flex" alignItems="center">
            <CalendarTodayIcon sx={{ marginRight: 1 }} />
             {eventData && eventData.meetingScheduledDate}
          </Typography>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={onCancel} color="secondary">
            Disagree
          </MDButton>
          <MDButton
            onClick={() => handleAgreeCancel(eventData)}
            color="error"
            autoFocus
          >
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CTLConferenceCancel;