import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";

function UserGroupMaster({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }
  const editRow = (row) => {
    const dataToEdit = { ...row };

    dataToEdit.parentLocation = row?.parentLocation?.id
      ? row.parentLocation.id
      : null;

    setFormData(dataToEdit);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            // status: error.response.status,
          });
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            // status: error.response.status,
          });
        });
    }
    setOpenForm(false);
  }
  function onCloseForm() {
    setOpenForm(false);
  }

  return (
    <MDBox>
      <CTLBaseDialogForm
        entityName="User Group Master"
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default UserGroupMaster;
