import MDTypography from "components/MDTypography";
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

function CTLRoomStatusCell({ value, suffix }) {
  if (value === 0) {
    suffix = (
      <tooltip title="Available & Clean">
        <CheckCircleIcon fontSize="medium" color="success" />
      </tooltip>
    );
  }
  if (value === 1) {
    suffix = (
      <tooltip title="Available & Dirty">
        <CancelIcon fontSize="medium" color="success" />
      </tooltip>
    );
  }
  if (value === 2) {
    suffix = (
      <tooltip title="Booked & Clean">
        <CircleIcon fontSize="medium" color="warning" />
      </tooltip>
    );
  }
  if (value === 3) {
    suffix = (
      <tooltip title="Booked & Dirty">
        <CircleIcon fontSize="medium" color="error" />
      </tooltip>
    );
  }
  if (value === 4) {
    suffix = (
      <tooltip title="Temp.Out & Clean">
        <ArrowCircleRightIcon fontSize="medium" style={{ color: "DarkGoldenRod" }} />
      </tooltip>
    );
  } else if (value === 5) {
    suffix = (
      <tooltip title="Temp.Out & Dirty">
        <ArrowCircleRightIcon fontSize="medium"  style={{ color: "Violet" }} />
      </tooltip>
    );
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLRoomStatusCell.defaultProps = {
  suffix: "",
};

export default CTLRoomStatusCell;
