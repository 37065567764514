import MDTypography from "components/MDTypography";

function CTLDefaultComparisonCell({ value, suffix }) {
  if (value === 1) {
    suffix = "On";
  }
  if (value === 0) {
    suffix = "Off";
  }
  if (value === true) {
    suffix = "On";
  }
  if (value === false) {
    suffix = "Off";
  } 
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLDefaultComparisonCell.defaultProps = {
  suffix: "",
};

export default CTLDefaultComparisonCell;
