import React, { useEffect, useState } from "react";
import { kaReducer, Table } from "ka-table";
import { deleteRow, updateCellValue } from "ka-table/actionCreators";
import { DataType, EditingMode } from "ka-table/enums";
import MediacenterDialogueConfig from "./MediaCenterDialogConfig";
import AlertDelete from "./AlertDelete";
import EmptyContainer from "./EmptyContainer";
import { search } from "ka-table/actionCreators";
import { Delete } from "@mui/icons-material";
import { Checkbox, TextField } from "@mui/material";
import "./Demo.scss";
import { useMaterialUIController } from "context";
import { saveAs } from "file-saver";
import { Select, MenuItem, Menu } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import { v4 as uuidV4 } from "uuid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import "./css/style.css";
import DropdownPopup from "./DropdownPopup";
import {
  useGetFormDataQuery,
  useSaveFormDataMutation,
} from "features/dynamicFormApiSlice";
import MDButton from "components/MDButton";
import SaveConfirmation from "./SaveConfirmationDialog";
import { useGetDropdownDataMutation } from "features/dynamicFormApiSlice";
// import MoveRecordDialog from "./MoveRecordDialog";

const columns = [
  {
    key: "Header",
    title: "Field name",
    width: 90,
    columnType: DataType.String,
  },
  {
    key: "accessor",
    title: "Accessor",
    width: 110,
    columnType: DataType.String,
  },
  {
    key: "displayInList",
    title: "Display in list",
    width: 110,
    columnType: DataType.Boolean,
  },
  {
    key: "allowInForm",
    title: "Allow in form",
    width: 110,
    columnType: DataType.Boolean,
  },
  {
    key: "allowFilter",
    title: "Allow filter",
    width: 110,
    columnType: DataType.Boolean,
  },
  {
    key: "isMandatory",
    title: "Mandatory",
    width: 110,
    columnType: DataType.Boolean,
  },
  {
    key: "hiddenForm",
    title: "Hidden form",
    width: 110,
    columnType: DataType.Boolean,
  },
  {
    key: "dataType",
    title: "Data type",
    width: 110,
    columnType: DataType.String,
  },
  {
    key: "controlType",
    title: "Control type",
    width: 120,
    columnType: DataType.String,
  },
  { key: "tabId", title: "Tab ID", width: 90, dataType: DataType.Number },
  {
    key: "tabName",
    title: "Tab Name",
    width: 90,
    dataType: DataType.String,
    visible: false,
  },
  {
    key: "groupHeading",
    title: "Group heading",
    width: 110,
    columnType: DataType.String,
  },
  {
    key: "onChange",
    title: "On change",
    width: 110,
    columnType: DataType.String,
  },
  { key: ":delete", width: 110, title: "Delete" },
];

function DynamicForm({ formCode, setIsFormOpen }) {
  const formDataQuery = useGetFormDataQuery(
    `${process.env.REACT_APP_FORM_DATA_ENDPOINT}/${formCode}`,
    {
      refetchOnReconnect: true,
    },
  );

  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [createHeaderName, setCreateHeaderName] = useState("");
  const [createTabGroupName, setCreateTabGroupName] = useState("");
  const [createHeadingGroup, setCreateHeadingGroup] = useState("");
  const [selectTabGroup, setSelectTabGroup] = useState();
  const [openCreateTableDiologue, setOpenCreateTableDiologue] = useState(false);
  const [isExistingTabGroupSelected, setIsExistingTabGroupSelected] =
    useState(false);
  const [isNewTabGroupCreated, setIsNewTabGroupCreated] = React.useState(false);
  const [isExistingHeadingGroupSelected, setIsExistingHeadingGroupSelected] =
    React.useState(false);
  const [isNewHeadingGroupCreated, setIsNewHeadingGroupCreated] =
    React.useState(false);
  const [isShowSearchBox, setIsShowSearchBox] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const { darkMode } = useMaterialUIController()[0];
  const [isConfirmDeleteRow, setIsConfirmDeleteRow] = React.useState(false);
  const [deleteRowDetails, setDeleteRowDetails] = React.useState({});
  const [contextMenu, setContextMenu] = React.useState(null);
  const [movableRowData, setMovableRowData] = React.useState({});
  const [moveUpMoveDownCheck, setMoveUpMoveDownCheck] = React.useState({});
  const [isAddingInExistingTabGroup, setIsAddingInExistingTabGroup] =
    React.useState(false);
  const [isAddingInExistingHeadingGroup, setIsAddingInExistingHeadingGroup] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const [openMoveRecordDialog, setOpenMoveRecordDialog] = React.useState(false);
  // const [selectHeader, setSelectHeader] = React.useState("");
  // const [recordPosition, setRecordPosition] = React.useState("below");
  const [tableProps, changeTableProps] = useState({});
  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);
  const [saveFormData, response] = useSaveFormDataMutation();
  const [sourceRow, setSourceRow] = useState(null);

  useEffect(() => {
    if (formDataQuery.data) {
      setRows(formDataQuery.data.content || []);
      setBackupRows(formDataQuery.data.content || []);
      changeTableProps(() =>
        formDataQuery.data.content
          ? {
              data: formDataQuery.data.content,
              columns: columns.map((item) => item),
              editableCells: formDataQuery.data.content.flatMap((item) => {
                return [
                  { columnKey: "Header", rowKeyValue: item.id },
                  { columnKey: "accessor", rowKeyValue: item.id },
                  { columnKey: "displayInList", rowKeyValue: item.id },
                  { columnKey: "allowInForm", rowKeyValue: item.id },
                  { columnKey: "allowFilter", rowKeyValue: item.id },
                  { columnKey: "isMandatory", rowKeyValue: item.id },
                  { columnKey: "hiddenForm", rowKeyValue: item.id },
                  { columnKey: "dataType", rowKeyValue: item.id },
                  { columnKey: "controlType", rowKeyValue: item.id },
                  { columnKey: "groupHeading", rowKeyValue: item.id },
                  { columnKey: "tabId", rowKeyValue: item.id },
                  { columnKey: "tabName", rowKeyValue: item.id },
                  { columnKey: "onChange", rowKeyValue: item.id },
                ];
              }),
              editingMode: EditingMode.Cell,
              rowKeyField: "id",

              search: ({ searchText, rowData, column }) => {
                if (column.key === "passed") {
                  return (
                    (searchText === "false" && !rowData.passed) ||
                    (searchText === "true" && rowData.passed)
                  );
                }
              },
              searchText: "",
            }
          : {
              data: rows,
              columns: columns.map((item) => item),
              editableCells: rows.flatMap((item) => {
                return [
                  { columnKey: "Header", rowKeyValue: item.id },
                  { columnKey: "accessor", rowKeyValue: item.id },
                  { columnKey: "displayInList", rowKeyValue: item.id },
                  { columnKey: "allowInForm", rowKeyValue: item.id },
                  { columnKey: "allowFilter", rowKeyValue: item.id },
                  { columnKey: "isMandatory", rowKeyValue: item.id },
                  { columnKey: "hiddenForm", rowKeyValue: item.id },
                  { columnKey: "dataType", rowKeyValue: item.id },
                  { columnKey: "controlType", rowKeyValue: item.id },
                  { columnKey: "groupHeading", rowKeyValue: item.id },
                  { columnKey: "tabId", rowKeyValue: item.id },
                  { columnKey: "tabName", rowKeyValue: item.id },
                  { columnKey: "onChange", rowKeyValue: item.id },
                ];
              }),
              editingMode: EditingMode.Cell,
              rowKeyField: "id",

              search: ({ searchText, rowData, column }) => {
                if (column.key === "passed") {
                  return (
                    (searchText === "false" && !rowData.passed) ||
                    (searchText === "true" && rowData.passed)
                  );
                }
              },
              searchText: "",
            },
      );
    }
  }, [formDataQuery.data]);

  const uniqueTabIDTypes = [...new Set(rows.map((item) => item.tabId))];

  const uniqueHeadingTypes = [
    ...new Set(
      rows
        .filter((item) => item.tabId === selectTabGroup)
        .map((item) => item.groupHeading),
    ),
  ];

  // const uniqueHeaders = [
  //   ...new Set(
  //     rows
  //       .filter((item) => item.groupHeading === createHeadingGroup)
  //       .map((item) => item.header),
  //   ),
  // ];

  const handlePopupDynamicFormClose = () => {
    if (JSON.stringify(rows) !== JSON.stringify(backupRows))
      setOpenSaveConfirmation(true);
    else setIsFormOpen(false);
  };

  const handleSaveConfirmationClose = () => setOpenSaveConfirmation(false);

  const handleSaveChanges = () => {
    saveFormData({
      content: rows,
      path: `${process.env.REACT_APP_FORM_DATA_ENDPOINT}/${formCode}`,
    })
      .unwrap()
      .then((res) => {
        setOpenSaveConfirmation(false);
        setIsFormOpen(false);
      })
      .catch((err) => {
        setOpenSaveConfirmation(false);
        console.log(err);
      });
  };

  const dispatch = (action) => {
    changeTableProps((prevState) => kaReducer(prevState, action));
  };

  const DeleteRowComponent = ({ rowKeyValue, deleteItemName }) => {
    const handleDeleteRow = () => {
      setDeleteRowDetails({ rowKeyValue, deleteItemName });
      setIsConfirmDeleteRow(true);
    };

    return (
      <Delete
        fontSize="small"
        sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
        onClick={handleDeleteRow}
      />
    );
  };

  const handleConfirmDeleteRow = () => {
    dispatch(deleteRow(deleteRowDetails.rowKeyValue));

    const deletedRow = rows.find(
      (row) => deleteRowDetails.rowKeyValue === row.id,
    );
    const filteredRows = rows.filter(
      (row) => deleteRowDetails.rowKeyValue !== row.id,
    );

    const isDeletedTabidPresent = filteredRows.find(
      (item) => item.tabId === deletedRow.tabId,
    );

    if (!isDeletedTabidPresent) {
      filteredRows.forEach((item, index) => {
        if (item.tabId > deletedRow.tabId)
          filteredRows[index] = { ...item, tabId: item.tabId - 1 };
      });
    }

    filteredRows.forEach((item, index) => {
      filteredRows[index] = { ...item, rowId: index + 1 };
    });

    setRows(filteredRows);

    changeTableProps((prevProps) => ({
      ...prevProps,
      data: filteredRows,
    }));

    setIsConfirmDeleteRow(false);
    setDeleteRowDetails({});
  };

  const handleDownloadJSON = () => {
    const tabIds = rows.map((row) => ({
      id: row.tabId,
      name: row.tabName,
    }));
    const tabsBlob = new Blob([JSON.stringify(tabIds)], {
      type: "application/json",
    });
    saveAs(tabsBlob, "Tabs.json");

    const rowsBlob = new Blob([JSON.stringify(rows.map((row) => row))], {
      type: "application/json",
    });
    saveAs(rowsBlob, "Forms.json");
  };

  const handleSaveGroup = () => {
    if (
      createHeaderName === "" ||
      createHeadingGroup === "" ||
      (isNewTabGroupCreated && createTabGroupName === "")
    ) {
      alert("Please fill all the input fields");
      return;
    }

    const newRow = {
      id: uuidV4(), // this bug may arise in media center
      tabId: isNewTabGroupCreated
        ? rows.length > 0
          ? rows[rows.length - 1].tabId + 1
          : 0
        : selectTabGroup,
      tabName: isNewTabGroupCreated
        ? createTabGroupName
        : rows.find((item) => item.tabId === selectTabGroup).tabName,
      groupHeading: createHeadingGroup,
      Header: createHeaderName,
      accessor: "Enter text",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      isMandatory: false,
      hiddenForm: false,
      dataType: "Select data type",
      controlType: "Select control type",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: null,
        url: null,
      },
      onChange: null,
    };

    const newEditableCells = columns.map((column) => ({
      columnKey: column.key,
      rowKeyValue: newRow.id,
    }));

    const filteredEditableCells = newEditableCells.filter(
      (item) => item.columnKey !== ":delete",
    );

    if (isNewTabGroupCreated) {
      const previousRows = [...rows, { ...newRow, rowId: rows.length + 1 }];
      setRows(previousRows);
      changeTableProps((prevState) => ({
        ...prevState,
        data: previousRows,
        editableCells: [...prevState.editableCells, ...filteredEditableCells],
      }));
    } else {
      const previousRows = [...rows];
      let lastPositionInGroup = null;
      if (isNewHeadingGroupCreated) {
        lastPositionInGroup = previousRows.findLastIndex(
          (item) => item.tabId === newRow.tabId,
        );
      } else {
        lastPositionInGroup = previousRows.findLastIndex(
          (item) =>
            item.tabId === newRow.tabId &&
            item.groupHeading === newRow.groupHeading,
        );
      }

      previousRows.splice(lastPositionInGroup + 1, 0, newRow);

      previousRows.forEach((item, index) => {
        previousRows[index] = { ...item, rowId: index + 1 };
      });
      setRows(previousRows);
      changeTableProps((prevState) => ({
        ...prevState,
        data: previousRows,
        editableCells: [...prevState.editableCells, ...filteredEditableCells],
      }));
    }

    setSelectTabGroup(1);
    setCreateHeaderName("");
    setCreateHeadingGroup("");
    setCreateTabGroupName("");

    setOpenCreateTableDiologue(false);
    setIsExistingTabGroupSelected(false);
    setIsNewTabGroupCreated(false);
    setIsExistingHeadingGroupSelected(false);
    setIsNewHeadingGroupCreated(false);
    setIsAddingInExistingTabGroup(false);
    setIsAddingInExistingHeadingGroup(false);
  };

  const handleNewData = () => {
    setOpenCreateTableDiologue(true);
  };

  const handleClickOpen = () => {
    setOpenCreateTableDiologue(true);
  };

  const handleSearchText = (e) => {
    dispatch(search(e.currentTarget.value));
    setSearchText(e.currentTarget.value);
  };

  const handleOpenSearchDiologue = () => {
    setIsShowSearchBox(true);
  };

  const handleCloseSearchDiologue = () => {
    setIsShowSearchBox(false);
  };

  const handleClearSearchText = () => {
    setSearchText("");
    dispatch(search(""));
  };

  const handleCheckboxToggle = (e, rowData, columnKey) => {
    setRows((prevRows) => {
      return prevRows.map((row) =>
        row.id === rowData.id ? { ...row, [columnKey]: e.target.checked } : row,
      );
    });
    changeTableProps((prevProps) => ({
      ...prevProps,
      data: prevProps.data.map((row) =>
        row.id === rowData.id ? { ...row, [columnKey]: e.target.checked } : row,
      ),
    }));
  };

  // const handleOpenDialog = () => {
  //   setOpenMoveRecordDialog(true);
  //   setContextMenu(null);
  // };

  // const handleDialogClose = () => setOpenMoveRecordDialog(false);

  const handleChangeTabGroupName = (e) => {
    setCreateTabGroupName(e.target.value);
  };

  const handleSelectTabGroupType = (e) => {
    setSelectTabGroup(e.target.value);
  };

  const handleChangeHeadingGroupType = (e) => {
    setCreateHeadingGroup(e.target.value);
  };

  // const handleChangeHeader = (e) => {
  //   setSelectHeader(e.target.value);
  // };

  // const handleRecordPositionChange = (e) => {
  //   setRecordPosition(e.target.value);
  // };

  // const handleMoveRecord = () => {};

  const handleMoveRecordUp = () => {
    setContextMenu(null);
    const previousRows = [...rows];
    const { rowId } = movableRowData;

    previousRows[rowId - 2] = { ...previousRows[rowId - 2], rowId: rowId };

    const rowToBeSwapped = previousRows.splice(rowId - 1, 1);

    previousRows.splice(rowId - 2, 0, {
      ...rowToBeSwapped[0],
      rowId: rowId - 1,
    });

    setRows(previousRows);
    changeTableProps((prevProps) => ({
      ...prevProps,
      data: previousRows,
    }));
  };

  const handleMoveRecordDown = () => {
    setContextMenu(null);
    const previousRows = [...rows];
    const { rowId } = movableRowData;

    previousRows[rowId] = { ...previousRows[rowId], rowId: rowId };

    const rowToBeSwapped = previousRows.splice(rowId - 1, 1);

    previousRows.splice(rowId, 0, {
      ...rowToBeSwapped[0],
      rowId: rowId + 1,
    });

    setRows(previousRows);
    changeTableProps((prevProps) => ({
      ...prevProps,
      data: previousRows,
    }));
  };

  const handleContextMenu = (e, rowData) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: e.clientX + 2,
            mouseY: e.clientY - 6,
          }
        : null,
    );
    handleMoveUpMoveDownCheck(rowData);
    setMovableRowData(rowData);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
    setContextMenu(null);
  };

  const handleMoveUpMoveDownCheck = (rowData) => {
    const { tabId, groupHeading, Header } = rowData;
    const targetGroup = rows.filter(
      (item) => item.tabId === tabId && item.groupHeading === groupHeading,
    );

    const len = targetGroup.length;

    const movableRecordIndex = targetGroup.findIndex(
      (item) => item.Header === Header,
    );

    setMoveUpMoveDownCheck({
      moveUp: movableRecordIndex > 0,
      moveDown: movableRecordIndex < len - 1,
    });
  };

  const handleMoveRecord = (targetTabId, targetGroupHeading, targetHeader) => {
    const { tabId, groupHeading, Header } = movableRowData;

    const targetTabName = rows.find(
      (item) => item.tabId === targetTabId,
    ).tabName;

    const rowsWithDeletedRecord = rows.filter(
      (item) =>
        !(
          item.tabId === tabId &&
          item.groupHeading === groupHeading &&
          item.Header === Header
        ),
    );

    const targetIndex = rowsWithDeletedRecord.findIndex(
      (item) =>
        item.tabId === targetTabId &&
        item.tabName === targetTabName &&
        item.groupHeading === targetGroupHeading &&
        item.Header === targetHeader,
    );

    rowsWithDeletedRecord.splice(targetIndex, 0, {
      ...movableRowData,
      tabId: targetTabId,
      tabName: targetTabName,
      groupHeading: targetGroupHeading,
    });

    const isDeletedTabidPresent = rowsWithDeletedRecord.find(
      (item) => item.tabId === tabId,
    );

    if (!isDeletedTabidPresent) {
      rowsWithDeletedRecord.forEach((item, index) => {
        if (item.tabId > tabId)
          rowsWithDeletedRecord[index] = { ...item, tabId: item.tabId - 1 };
      });
    }

    rowsWithDeletedRecord.forEach((item, index) => {
      rowsWithDeletedRecord[index] = { ...item, rowId: index + 1 };
    });

    setRows(rowsWithDeletedRecord);

    changeTableProps((prevProps) => ({
      ...prevProps,
      data: rowsWithDeletedRecord,
    }));

    setContextMenu(null);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "12",
            left: "0",
            marginTop: "14.5px",
            zIndex: "1000",
          }}
        >
          <MediacenterDialogueConfig
            handleClickOpen={handleClickOpen}
            isNewTabGroupCreated={isNewTabGroupCreated}
            isExistingTabGroupSelected={isExistingTabGroupSelected}
            isNewHeadingGroupCreated={isNewHeadingGroupCreated}
            isExistingHeadingGroupSelected={isExistingHeadingGroupSelected}
            setIsNewTabGroupCreated={setIsNewTabGroupCreated}
            setIsExistingTabGroupSelected={setIsExistingTabGroupSelected}
            setIsNewHeadingGroupCreated={setIsNewHeadingGroupCreated}
            setIsExistingHeadingGroupSelected={
              setIsExistingHeadingGroupSelected
            }
            createHeadingGroup={createHeadingGroup}
            selectTabGroup={selectTabGroup}
            rows={rows}
            handleSaveGroup={handleSaveGroup}
            handleChangeTabGroupName={handleChangeTabGroupName}
            handleChangeHeadingGroupType={handleChangeHeadingGroupType}
            handleSelectTabGroupType={handleSelectTabGroupType}
            setSelectTabGroup={setSelectTabGroup}
            setCreateHeaderName={setCreateHeaderName}
            setCreateHeadingGroup={setCreateHeadingGroup}
            setCreateTabGroupName={setCreateTabGroupName}
            openCreateTableDiologue={openCreateTableDiologue}
            setOpenCreateTableDiologue={setOpenCreateTableDiologue}
            handleDownloadJSON={handleDownloadJSON}
            uniqueHeadingTypes={uniqueHeadingTypes}
            uniqueTabIDTypes={uniqueTabIDTypes}
            isAddingInExistingTabGroup={isAddingInExistingTabGroup}
            isAddingInExistingHeadingGroup={isAddingInExistingHeadingGroup}
            setIsAddingInExistingTabGroup={setIsAddingInExistingTabGroup}
            setIsAddingInExistingHeadingGroup={
              setIsAddingInExistingHeadingGroup
            }
            searchText={searchText}
            handleClearSearchText={handleClearSearchText}
            handleOpenSearchDiologue={handleOpenSearchDiologue}
            isShowSearchBox={isShowSearchBox}
            handleSearchText={handleSearchText}
            handleCloseSearchDiologue={handleCloseSearchDiologue}
            setIsShowSearchBox={setIsShowSearchBox}
          />
        </div>
        {rows.length > 0 && (
          <>
            <div className={darkMode ? "darkTheme" : "lightTheme"}>
              <Table
                {...tableProps}
                dispatch={dispatch}
                columns={columns?.map((c) => ({
                  ...c,
                  visible: c.visible !== false,
                }))}
                rowReordering
                rowKeyField="id"
                groups={[{ columnKey: "tabId" }, { columnKey: "groupHeading" }]}
                childComponents={{
                  cellEditor: {
                    content: ({ rowData, column, rowKeyValue, dispatch }) => {
                      const { columnType, key } = column;
                      const {
                        Header,
                        dataType,
                        controlType,
                        onChange,
                        accessor,
                        displayInList,
                        allowInForm,
                        allowFilter,
                        isMandatory,
                        hiddenForm,
                        options,
                        id,
                      } = rowData;

                      let cellData = "";
                      if (key === "Header") cellData = Header;
                      else if (key === "dataType") cellData = dataType;
                      else if (key === "controlType") cellData = controlType;
                      else if (key === "onChange") cellData = onChange;
                      else if (key === "accessor") cellData = accessor;

                      let checked = false;
                      if (key === "displayInList") checked = displayInList;
                      else if (key === "allowInForm") checked = allowInForm;
                      else if (key === "allowFilter") checked = allowFilter;
                      else if (key === "isMandatory") checked = isMandatory;
                      else if (key === "hiddenForm") checked = hiddenForm;

                      const [newCellData, setNewCellData] = useState(cellData);
                      const [toggleField, setToggleField] = useState(false);
                      const [newChecked, setNewChecked] = useState(checked);
                      const [openDropdownPopup, setOpenDropdownPopup] =
                        useState(false);
                      const [tags, setTags] = useState(() => {
                        return options.values && options.url === null
                          ? options.values.map((value, idx) => ({
                              id: value.id,
                              text: `${value.id}: ${value.description}`,
                              className:
                                idx === 0 ? "first-tag-item" : "tag-item",
                            }))
                          : [];
                      });
                      const [tagKey, setTagKey] = useState("");
                      const [tagText, setTagText] = useState("");
                      const [apiURL, setApiURL] = useState(() =>
                        options.url ? options.url : "",
                      );
                      const [isLoading, setIsLoading] = useState(false);
                      const [apiData, setApiData] = useState([]);
                      const [selectedKeyField, setSelectedKeyField] = useState(
                        () =>
                          options.url && options.keyField
                            ? options.keyField
                            : "Select key field",
                      );
                      const [
                        selectedKeyDescription,
                        setSelectedKeyDescription,
                      ] = useState(() =>
                        options.url && options.keyDescription
                          ? options.keyDescription
                          : "Select key description",
                      );
                      const [isStatic, setIsStatic] = useState(
                        () => options.url === null,
                      );

                      const handleDropdownPopupOpen = () => {
                        setOpenDropdownPopup(true);
                      };

                      const handleDropdownPopupClose = () => {
                        setOpenDropdownPopup(false);
                      };

                      const handleTagKeyInputChange = (e) =>
                        setTagKey(e.target.value);
                      const handleTagTextInputChange = (e) =>
                        setTagText(e.target.value);

                      const closeEditor = (editorType, value) => {
                        dispatch(
                          updateCellValue(rowKeyValue, key, newCellData),
                        );
                        setRows((prevRows) =>
                          prevRows.map((row) =>
                            row.id === rowKeyValue
                              ? {
                                  ...row,
                                  [key]:
                                    editorType === "select"
                                      ? value
                                      : newCellData,
                                }
                              : row,
                          ),
                        );
                        changeTableProps((prevProps) => ({
                          ...prevProps,
                          data: prevProps.data.map((row) =>
                            row.id === rowKeyValue
                              ? {
                                  ...row,
                                  [key]:
                                    editorType === "select"
                                      ? value
                                      : newCellData,
                                }
                              : row,
                          ),
                        }));
                        setToggleField(false);
                      };

                      const handleRowsValuesChanges = (tags, id) => {
                        const newRows = rows.map((row) =>
                          row.id === id
                            ? {
                                ...row,
                                options: {
                                  ...row.options,
                                  keyField: "id",
                                  keyDescription: "description",
                                  values: tags,
                                  url: null,
                                },
                              }
                            : row,
                        );
                        setRows(newRows);
                        changeTableProps((prevProps) => ({
                          ...prevProps,
                          data: newRows,
                        }));
                      };

                      const handleRowsKeyFieldChange = (keyField) => {
                        setTags([]);
                        const newRows = rows.map((row) =>
                          row.id === id
                            ? {
                                ...row,
                                options: {
                                  ...row.options,
                                  keyField,
                                  values: null,
                                  url: apiURL,
                                },
                              }
                            : row,
                        );
                        setRows(newRows);
                        changeTableProps((prevProps) => ({
                          ...prevProps,
                          data: newRows,
                        }));
                      };

                      const handleRowsKeyDescriptionChange = (
                        keyDescription,
                      ) => {
                        setTags([]);
                        const newRows = rows.map((row) =>
                          row.id === id
                            ? {
                                ...row,
                                options: {
                                  ...row.options,
                                  keyDescription,
                                  values: null,
                                  url: apiURL,
                                },
                              }
                            : row,
                        );
                        setRows(newRows);
                        changeTableProps((prevProps) => ({
                          ...prevProps,
                          data: newRows,
                        }));
                      };

                      const handleDelete = (i) => {
                        let newTags = null;
                        if (i === 0) {
                          newTags = tags
                            .map((tag, idx) =>
                              idx === 1
                                ? { ...tag, className: "first-tag-item" }
                                : tag,
                            )
                            .filter((tag, index) => index !== i);
                          setTags(newTags);
                        } else {
                          newTags = tags.filter((tag, index) => index !== i);
                          setTags(newTags);
                        }

                        handleRowsValuesChanges(
                          newTags.map((newTag) => ({
                            id: newTag.id,
                            description: newTag.text,
                          })),
                          id,
                        );
                      };

                      const handleAddition = (tag) => {
                        setSelectedKeyField("Select key field");
                        setSelectedKeyDescription("Select key description");
                        setApiURL("");
                        setApiData([]);

                        let newTags = null;
                        if (tags.length === 0) {
                          newTags = [{ ...tag, className: "first-tag-item" }];
                          setTags(newTags);
                        } else {
                          newTags = [
                            ...tags,
                            { ...tag, className: "tag-item" },
                          ];
                          setTags(newTags);
                        }

                        handleRowsValuesChanges(
                          newTags.map((newTag) => ({
                            id: newTag.id,
                            description: newTag.text.split(": ")[1],
                          })),
                          id,
                        );

                        setTagKey("");
                        setTagText("");
                      };

                      const handleAddTag = () => {
                        if (tagKey === "" || tagText === "") {
                          alert("Kindly fill both the input fields.");
                          return;
                        }

                        handleAddition({
                          id: tagKey,
                          text: `${tagKey}: ${tagText}`,
                        });
                      };

                      const handleDrag = (tag, currPos, newPos) => {
                        let newTags = tags.slice();

                        newTags.splice(currPos, 1);
                        newTags.splice(newPos, 0, tag);

                        newTags = newTags.map((tag, idx) =>
                          idx === 0
                            ? { ...tag, className: "first-tag-item" }
                            : { ...tag, className: "tag-item" },
                        );

                        setTags(newTags);

                        handleRowsValuesChanges(
                          newTags.map((newTag) => ({
                            id: newTag.id,
                            description: newTag.text,
                          })),
                          id,
                        );
                      };

                      const handleDropdownOptionsChange = () =>
                        setIsStatic((prevIsStatic) => !prevIsStatic);

                      const handleApiUrlChange = (e) =>
                        setApiURL(e.target.value);

                      const handleSelectKeyFieldChange = (e) => {
                        setSelectedKeyField(e.target.value);
                        handleRowsKeyFieldChange(e.target.value);
                      };

                      const handleSelectKeyDescriptionChange = (e) => {
                        setSelectedKeyDescription(e.target.value);
                        handleRowsKeyDescriptionChange(e.target.value);
                      };

                      const [dropDownData, response] =
                        useGetDropdownDataMutation();

                      const handleApiUrlSubmit = async (e) => {
                        e.preventDefault();
                        if (apiURL === "") {
                          alert("Kindly fill the input field.");
                          return;
                        }

                        setIsLoading(true);
                        setApiData([]);
                        dropDownData(apiURL)
                          .unwrap()
                          .then((res) => {
                            const options = Object.keys(res[0]);
                            setApiData(options);

                            const targetKeyField = options.find(
                              (option) => option === selectedKeyField,
                            );
                            const targetKeyDescription = options.find(
                              (option) => option === selectedKeyDescription,
                            );

                            if (!targetKeyField)
                              setSelectedKeyField("Select key field");
                            if (!targetKeyDescription)
                              setSelectedKeyDescription(
                                "Select key description",
                              );

                            setIsLoading(false);
                          })
                          .catch((err) => {
                            console.log(err);
                            alert("Kindly enter a valid API URL.");
                            setApiURL("");
                            setIsLoading(false);
                          });
                      };

                      if (key === "dataType") {
                        return toggleField ? (
                          <Select
                            labelId="select-data-type-label"
                            id="Select data type"
                            value={newCellData}
                            label="Select data type"
                            size="small"
                            onChange={(e) => {
                              setNewCellData(e.target.value);
                              closeEditor("select", e.target.value);
                            }}
                            onBlur={(e) => {
                              setNewCellData(e.target.value);
                              closeEditor("select", e.target.value);
                            }}
                            autoFocus
                            defaultOpen
                            sx={{ backgroundColor: "white", padding: "0.8em" }}
                          >
                            <MenuItem value="Select data type">
                              Select data type
                            </MenuItem>
                            <MenuItem value="Integer">Integer</MenuItem>
                            <MenuItem value="string">String</MenuItem>
                            <MenuItem value="IPv4">IPv4</MenuItem>
                            <MenuItem value="IPv6">IPv6</MenuItem>
                            <MenuItem value="Boolean">Boolean</MenuItem>
                            <MenuItem value="StringNumber">
                              StringNumber
                            </MenuItem>
                            <MenuItem value="Date">Date</MenuItem>
                            <MenuItem value="DateTime">DateTime</MenuItem>
                            <MenuItem value="Password">Password</MenuItem>
                          </Select>
                        ) : (
                          <MDBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "#FFF",
                            }}
                          >
                            <div onClick={() => setToggleField(true)}>
                              {newCellData === ""
                                ? "Select data type"
                                : newCellData}
                            </div>
                          </MDBox>
                        );
                      } else if (key === "controlType") {
                        return toggleField ? (
                          <Select
                            labelId="select-data-type-label"
                            id="Select data type"
                            value={newCellData}
                            label="Select control type"
                            size="small"
                            onChange={(e) => {
                              setNewCellData(e.target.value);
                              closeEditor("select", e.target.value);
                            }}
                            onBlur={(e) => {
                              setNewCellData(e.target.value);
                              closeEditor("select", e.target.value);
                            }}
                            autoFocus
                            defaultOpen
                            sx={{ backgroundColor: "white", padding: "0.8em" }}
                          >
                            <MenuItem value="Select control type">
                              Select control type
                            </MenuItem>
                            <MenuItem value="textField">Textfield</MenuItem>
                            <MenuItem value="multipleSelect">
                              Multiple select
                            </MenuItem>
                            <MenuItem value="select">Select</MenuItem>
                            <MenuItem value="boolean">Boolean</MenuItem>
                            <MenuItem value="password">Password</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="time">Time</MenuItem>
                            <MenuItem value="ip">IP</MenuItem>
                            <MenuItem value="ipv6">IPv6</MenuItem>
                            <MenuItem value="selectWithColor">
                              Select with color
                            </MenuItem>
                            <MenuItem value="imgSelect">Image select</MenuItem>
                            <MenuItem value="colorPicker">
                              Color picker
                            </MenuItem>
                          </Select>
                        ) : (
                          <>
                            <MDBox
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#FFF",
                              }}
                            >
                              <div onClick={() => setToggleField(true)}>
                                {newCellData === ""
                                  ? "Select control type"
                                  : newCellData}
                              </div>
                              {(newCellData === "select" ||
                                newCellData === "multipleSelect") && (
                                <IconButton onClick={handleDropdownPopupOpen}>
                                  <Icon fontSize="small" color="secondary">
                                    settings
                                  </Icon>
                                </IconButton>
                              )}
                            </MDBox>
                            <DropdownPopup
                              openDropdownPopup={openDropdownPopup}
                              handleDropdownPopupClose={
                                handleDropdownPopupClose
                              }
                              handleDelete={handleDelete}
                              handleDrag={handleDrag}
                              handleDropdownOptionsChange={
                                handleDropdownOptionsChange
                              }
                              handleTagKeyInputChange={handleTagKeyInputChange}
                              handleTagTextInputChange={
                                handleTagTextInputChange
                              }
                              handleAddTag={handleAddTag}
                              handleApiUrlChange={handleApiUrlChange}
                              handleApiUrlSubmit={handleApiUrlSubmit}
                              handleSelectKeyFieldChange={
                                handleSelectKeyFieldChange
                              }
                              handleSelectKeyDescriptionChange={
                                handleSelectKeyDescriptionChange
                              }
                              isStatic={isStatic}
                              tags={tags}
                              tagKey={tagKey}
                              tagText={tagText}
                              apiURL={apiURL}
                              isLoading={isLoading}
                              apiData={apiData}
                              selectedKeyField={selectedKeyField}
                              selectedKeyDescription={selectedKeyDescription}
                            />
                          </>
                        );
                      } else if (columnType === DataType.Boolean) {
                        return (
                          <Checkbox
                            checked={newChecked}
                            onChange={(e) => {
                              handleCheckboxToggle(e, rowData, key);
                              setNewChecked(e.target.checked);
                            }}
                          />
                        );
                      } else if (columnType === DataType.String) {
                        return toggleField ? (
                          <TextField
                            id="ka-cell"
                            label="Enter text"
                            size="small"
                            autoFocus
                            value={
                              newCellData === "Enter text" ||
                              newCellData === null
                                ? ""
                                : newCellData
                            }
                            onBlur={closeEditor}
                            onChange={(e) =>
                              setNewCellData(() =>
                                e.target.value === "" ? null : e.target.value,
                              )
                            }
                          />
                        ) : (
                          <div onClick={() => setToggleField(true)}>
                            {newCellData === "" || newCellData === null
                              ? "Enter text"
                              : newCellData}
                          </div>
                        );
                      }
                    },
                  },
                  noDataRow: {
                    content: () =>
                      rows.length === 0 ? (
                        "No Data Found"
                      ) : (
                        <h2 style={{ color: "#fff" }}>No Data Found</h2>
                      ),
                  },
                  dataRow: {
                    elementAttributes: ({ rowData }) => ({
                      style: {
                        backgroundColor: darkMode ? "#202940" : "#FFFFFF",
                        color: darkMode ? "white" : "black",
                      },
                      onContextMenu: (e) => handleContextMenu(e, rowData),
                      onDrag: () => {
                        if (!sourceRow) setSourceRow(rowData);
                      },
                      onDropCapture: () => {
                        const newRows = [...rows];

                        const targetRow = newRows[rowData.rowId - 1];

                        const removedSourceRow = newRows.splice(
                          sourceRow.rowId - 1,
                          1,
                        );

                        if (targetRow.rowId < sourceRow.rowId)
                          newRows.splice(rowData.rowId - 1, 0, {
                            ...removedSourceRow[0],
                            tabId: targetRow.tabId,
                            tabName: targetRow.tabName,
                            groupHeading: targetRow.groupHeading,
                          });
                        else
                          newRows.splice(rowData.rowId - 2, 0, {
                            ...removedSourceRow[0],
                            tabId: targetRow.tabId,
                            tabName: targetRow.tabName,
                            groupHeading: targetRow.groupHeading,
                          });

                        const rowsWithUpdatedRowIds = newRows.map(
                          (row, idx) => ({ ...row, rowId: idx + 1 }),
                        );

                        setRows(rowsWithUpdatedRowIds);
                        changeTableProps((prevProps) => ({
                          ...prevProps,
                          data: rowsWithUpdatedRowIds,
                        }));
                        setSourceRow(null);
                      },
                    }),
                  },
                  cellText: {
                    content: (props) => {
                      switch (props.column?.key) {
                        case ":delete":
                          return (
                            <DeleteRowComponent
                              rowKeyValue={props.rowKeyValue}
                              deleteItemName={props.rowData.name}
                            />
                          );
                      }
                    },
                    elementAttributes: ({ rowData }) => ({
                      style: {
                        color: darkMode ? "white" : "black",
                      },
                    }),
                  },
                  groupCell: {
                    content: (props) => {
                      if (props.column.key === "tabId") {
                        const tabId = parseInt(props.text.split(" ")[2]);
                        const tabName = rows.find(
                          (item) => item.tabId === tabId,
                        ).tabName;

                        const [newTabName, setNewTabName] = useState(tabName);
                        const [toggleField, setToggleField] = useState(false);

                        const closeEditor = () => {
                          setToggleField(false);
                          setRows((prevRows) =>
                            prevRows.map((row) =>
                              row.tabId === tabId
                                ? { ...row, tabName: newTabName }
                                : row,
                            ),
                          );
                          changeTableProps((prevProps) => ({
                            ...prevProps,
                            data: prevProps.data.map((row) =>
                              row.tabId === tabId
                                ? { ...row, tabName: newTabName }
                                : row,
                            ),
                          }));
                        };

                        const handleDeleteTabGroup = () => {
                          const filteredRows = rows.filter(
                            (item) => item.tabId !== tabId,
                          );

                          const rowsWithUpdatedRowIds = filteredRows.map(
                            (row, idx) => ({ ...row, rowId: idx + 1 }),
                          );

                          setRows(rowsWithUpdatedRowIds);
                          changeTableProps((prevProps) => ({
                            ...prevProps,
                            data: rowsWithUpdatedRowIds,
                          }));
                        };

                        return (
                          <>
                            Tab: &nbsp;
                            {toggleField ? (
                              <TextField
                                id="ka-cell-group"
                                label="Enter text"
                                size="small"
                                autoFocus
                                value={
                                  newTabName === "Enter text" ? "" : newTabName
                                }
                                onBlur={closeEditor}
                                onChange={(e) => setNewTabName(e.target.value)}
                              />
                            ) : (
                              <div onClick={() => setToggleField(true)}>
                                {newTabName === "" ? "Enter text" : newTabName}
                              </div>
                            )}
                            <Tooltip
                              title="Add new tab group"
                              arrow={false}
                              placement="top"
                            >
                              <IconButton
                                onClick={() => {
                                  setSelectTabGroup(tabId);
                                  setIsAddingInExistingTabGroup(true);
                                  setOpenCreateTableDiologue(true);
                                  setIsExistingTabGroupSelected(true);
                                  setIsNewHeadingGroupCreated(true);
                                }}
                              >
                                <Icon color="secondary">add</Icon>
                              </IconButton>
                            </Tooltip>
                            <Delete
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                marginLeft: "auto",
                                "&:hover": { color: "red" },
                              }}
                              onClick={handleDeleteTabGroup}
                            />
                          </>
                        );
                      }
                      if (props.column.key === "groupHeading") {
                        const oldGroupHeadingTokens = props.text.split(" ");
                        const oldHeading = oldGroupHeadingTokens
                          .slice(2)
                          .join(" ");

                        const [newHeadingGroup, setNewHeadingGroup] =
                          useState(oldHeading);
                        const [toggleField, setToggleField] = useState(false);

                        const closeEditor = () => {
                          setToggleField(false);
                          setRows((prevRows) =>
                            prevRows.map((row) =>
                              row.groupHeading === oldHeading
                                ? { ...row, groupHeading: newHeadingGroup }
                                : row,
                            ),
                          );
                          changeTableProps((prevProps) => ({
                            ...prevProps,
                            data: prevProps.data.map((row) =>
                              row.groupHeading === oldHeading
                                ? { ...row, groupHeading: newHeadingGroup }
                                : row,
                            ),
                          }));
                        };

                        const handleDeleteHeadingGroup = () => {
                          const filteredRows = rows.filter(
                            (item) => item.groupHeading !== newHeadingGroup,
                          );
                          const rowsWithUpdatedRowIds = filteredRows.map(
                            (row, idx) => ({ ...row, rowId: idx + 1 }),
                          );
                          setRows(rowsWithUpdatedRowIds);
                          changeTableProps((prevProps) => ({
                            ...prevProps,
                            data: rowsWithUpdatedRowIds,
                          }));
                        };

                        return (
                          <>
                            Group heading: &nbsp;
                            {toggleField ? (
                              <TextField
                                id="ka-cell-group"
                                label="Enter text"
                                size="small"
                                autoFocus
                                value={
                                  newHeadingGroup === "Enter text"
                                    ? ""
                                    : newHeadingGroup
                                }
                                onBlur={closeEditor}
                                onChange={(e) =>
                                  setNewHeadingGroup(e.target.value)
                                }
                              />
                            ) : (
                              <div onClick={() => setToggleField(true)}>
                                {newHeadingGroup === ""
                                  ? "Enter text"
                                  : newHeadingGroup}
                              </div>
                            )}
                            <Tooltip
                              title="Add new heading group"
                              arrow={false}
                              placement="top"
                            >
                              <IconButton
                                onClick={() => {
                                  setSelectTabGroup(props.groupItems[0].tabId);
                                  setCreateHeadingGroup(newHeadingGroup);
                                  setOpenCreateTableDiologue(true);
                                  setIsAddingInExistingTabGroup(true);
                                  setIsAddingInExistingHeadingGroup(true);
                                  setIsExistingTabGroupSelected(true);
                                  setIsExistingHeadingGroupSelected(true);
                                }}
                              >
                                <Icon color="secondary">add</Icon>
                              </IconButton>
                            </Tooltip>
                            <Delete
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                marginLeft: "auto",
                                "&:hover": { color: "red" },
                              }}
                              onClick={handleDeleteHeadingGroup}
                            />
                          </>
                        );
                      }
                    },
                    elementAttributes: () => ({
                      style: {
                        backgroundColor: darkMode ? "#1a2035" : "#FAFAFA",
                        color: darkMode ? "#fff" : "#000",
                        border: darkMode
                          ? "2px solid #202940"
                          : "2px solid #f1f1f1",
                      },
                    }),
                  },
                  groupExpandButton: {
                    elementAttributes: () => ({
                      style: {
                        fill: !darkMode ? "#000" : "white",
                      },
                    }),
                  },
                  headCell: {
                    elementAttributes: () => ({
                      style: {
                        backgroundColor: darkMode ? "#1a2035" : "#ffffff",
                        color: darkMode ? "#fff" : "#000",
                      },
                    }),
                  },
                }}
              />
              {isConfirmDeleteRow && (
                <AlertDelete
                  name={deleteRowDetails.deleteItemName}
                  handleConfirmDelete={handleConfirmDeleteRow}
                  isDelete={setIsConfirmDeleteRow}
                  setIsDelete={setIsConfirmDeleteRow}
                />
              )}
              <Menu
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
                anchorEl={anchorEl}
                open={contextMenu !== null}
                onClose={handleContextMenuClose}
              >
                {moveUpMoveDownCheck.moveUp && (
                  <MenuItem onClick={handleMoveRecordUp}>Move up</MenuItem>
                )}
                {moveUpMoveDownCheck.moveDown && (
                  <MenuItem onClick={handleMoveRecordDown}>Move down</MenuItem>
                )}
                <NestedMenuItem label="Move" parentMenuOpen={open}>
                  {uniqueTabIDTypes.map((tabId) => {
                    const uniqueGroupHeadings = [
                      ...new Set(
                        rows
                          .filter((item) => item.tabId === tabId)
                          .map((item) => item.groupHeading),
                      ),
                    ];

                    const { tabName } = rows.find(
                      (item) => item.tabId === tabId,
                    );
                    return (
                      <NestedMenuItem
                        key={uuidV4()}
                        label={tabName}
                        parentMenuOpen={open}
                      >
                        {uniqueGroupHeadings.map((groupHeading) => {
                          const headers = [
                            ...new Set(
                              rows
                                .filter(
                                  (item) => item.groupHeading === groupHeading,
                                )
                                .map((item) => item.Header),
                            ),
                          ];

                          return (
                            <NestedMenuItem
                              key={uuidV4()}
                              label={groupHeading}
                              parentMenuOpen={open}
                            >
                              {headers
                                .filter(
                                  (item) =>
                                    !(
                                      movableRowData.tabId === tabId &&
                                      movableRowData.groupHeading ===
                                        groupHeading &&
                                      movableRowData.Header === item
                                    ),
                                )
                                .map((header) => (
                                  <MenuItem
                                    key={uuidV4()}
                                    onClick={() =>
                                      handleMoveRecord(
                                        tabId,
                                        groupHeading,
                                        header,
                                      )
                                    }
                                  >
                                    {header}
                                  </MenuItem>
                                ))}
                            </NestedMenuItem>
                          );
                        })}
                      </NestedMenuItem>
                    );
                  })}
                </NestedMenuItem>
              </Menu>
              {/* <MoveRecordDialog
              openMoveRecordDialog={openMoveRecordDialog}
              handleDialogClose={handleDialogClose}
              selectTabGroup={selectTabGroup}
              handleSelectTabGroupType={handleSelectTabGroupType}
              uniqueTabIDTypes={uniqueTabIDTypes}
              createHeadingGroup={createHeadingGroup}
              handleChangeHeadingGroupType={handleChangeHeadingGroupType}
              uniqueHeadingTypes={uniqueHeadingTypes}
              selectHeader={selectHeader}
              handleChangeHeader={handleChangeHeader}
              uniqueHeaders={uniqueHeaders}
              recordPosition={recordPosition}
              handleRecordPositionChange={handleRecordPositionChange}
              handleMoveRecord={handleMoveRecord}
            /> */}
            </div>
          </>
        )}
        {rows.length === 0 && <EmptyContainer handleNewData={handleNewData} />}
      </div>
      <MDBox
        sx={{ position: "absolute", right: "1em", bottom: "1em" }}
        display="flex"
        justifyContent="flex-end"
      >
        {rows.length > 0 && (
          <MDButton
            color="success"
            type="button"
            sx={{ marginRight: "1.5em" }}
            onClick={handleSaveChanges}
          >
            Save
          </MDButton>
        )}
        <MDButton
          color="primary"
          type="button"
          onClick={handlePopupDynamicFormClose}
        >
          Close
        </MDButton>
      </MDBox>
      <SaveConfirmation
        openSaveConfirmation={openSaveConfirmation}
        handleSaveConfirmationClose={handleSaveConfirmationClose}
        setIsFormOpen={setIsFormOpen}
        handleSaveChanges={handleSaveChanges}
      />
    </>
  );
}

export default DynamicForm;
