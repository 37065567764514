import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enablePublishButton: true,
  enableFilePreViewButton: true,
  enableAdvanceSearch: true,
  enableGroupHeading: false,
  tabSize: 4,
  modes: [
    {
      id: "configuration.routes",
      name: "Routes",
      iconName: "route",
      url: "home/app/configurations/routes",
      description: "Routes",
    },
    {
      id: "configuration.cluster",
      name: "Nodes",
      iconName: "workspaces",
      url: "home/app/configurations/cluster",
      description: "Cluster Nodes",
    },
  ],
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/wAns/list ",
  update: "/wAns/update ",
  register: "/wAns/register",
  delete: "/wAns/delete",
  publish: "/configuration/publish",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "NAME",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "IP-LOCAL",
      accessor: "ipLocal",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      controlType:"ip",
      displayInDeleteConfirmation: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").ipv4(),
    },
    {
      Header: "IP-PUBLIC",
      accessor: "ipPublic",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      controlType:"ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").ipv4(),
    },
    {
      Header: "IP-REMOTE",
      accessor: "ipRemote",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType:"ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4(),
    },
    {
      Header: "WAN-TYPE",
      accessor: "wanType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType:"select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "Type1" }, { description: "Type2" }],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
