import { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { application } from "globals/endpoints";
import CTLTableContent from "./CTLTableContent";
import MDBox from "components/MDBox";

const linearStyle = makeStyles((theme) => ({
  root: {
    width: "50%",
    position: "absolute",
    left: "100px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function CTLTable({
  onSelectionChange,
  columns,
  apiURL,
  defaultSortColumn,
  defaultSortDirection,
  pageId,
  searchText,
  hasRowSelection,
  advancedFilters,
  updateList,
  disablePagination,
  disableSort,
  refetchInterval,
}) {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({ sortBy: "", sortDirection: "" });
  const [ids, setIds] = useState([]);
  const classes = linearStyle();

  const fetchData = useCallback((pageSize, newPage, sortInfo) => {
    fetchAPIData(pageSize, newPage, sortInfo);
  });
  const fetchAPIData = (pageSize, newPage, sortInfo) => {
    try {
      const pageDefinition = {
        currentPage: newPage,
        pageSize: pageSize,
        sortDirection: sortInfo.desc ? "desc" : "asc",
        sortBy: sortInfo.id,
      };
      setLoading(true);

      application
        .post(apiURL, pageDefinition)
        .then((response) => {
          setRows(response.data.data.currentPageData);
          setRowCount(response.data.data.totalRecords);
          setPageIndex(response.data.data.currentPage);
          setSortInfo({
            sortBy: pageDefinition.sortBy,
            sortDirection: pageDefinition.sortDirection,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(10, 0);
  }, []);

  // callback function to handle SelectedId
  function handleIdData(rowSelectionIds) {
    setIds(rowSelectionIds);
    onSelectionChange && onSelectionChange(ids);
  }

  return (
    <MDBox>
      <CTLTableContent
        onSelectedIDdata={handleIdData}
        table={{ columns: columns }}
        rowCount={rowCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        sortInfo={sortInfo}
        apiURL={apiURL}
        defaultSortColumn={defaultSortColumn}
        defaultSortDirection={defaultSortDirection}
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={hasRowSelection}
        advancedFilters={advancedFilters}
        updateList={updateList}
        disablePagination={disablePagination}
        disableSort={disableSort}
        refetchInterval={refetchInterval}
      />
    </MDBox>
  );
}
export default CTLTable;
