import { createApi } from "@reduxjs/toolkit/query/react";
// import { Client } from "@stomp/stompjs";
// import updateTreeNode from "layouts/dyotis/NMS/utils/updateTreeNode";
import { telemetry } from "globals/endpoints";
import NotificationManager from "globals/websocket/WebSocket";

export const coralApi = createApi({
  reducerPath: "coralApi",
  baseQuery: async (args) => {
    try {
      if (typeof args == "string") {
        const result = await telemetry(args);
        return result;
      } else {
        const { url, method, body } = args;
        const result = await telemetry({ url, method, data: body });
        return result;
      }
    } catch (axiosError) {
      return { error: axiosError.response?.data || axiosError.message };
    }
  },
  tagTypes: [
    "saveLayout",
    "categoryList",
    "locations",
    "smartTopology",
    "formData",
  ],
  endpoints: (builder) => ({
    getData: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
    }),
    getLayout: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      providesTags: ["saveLayout"],
    }),
    saveLayout: builder.mutation({
      query: (payload) => ({
        url: "/dashboard/saveDashboard",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["saveLayout"],
    }),
    getCategoryList: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      providesTags: ["categoryList"],
    }),
    saveCategorySensors: builder.mutation({
      query: (payload) => ({
        url: "/categorySensor/update",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["categoryList"],
    }),
    getLocations: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      providesTags: ["locations"],
    }),
    saveLocations: builder.mutation({
      query: (payload) => ({
        url: "/location/update",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["locations"],
    }),
    uploadFile: builder.mutation({
      query: (payload) => ({
        url: "/widget/uploadFile",
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["smartTopology"],
    }),
    getPopulatedWidgetListData: builder.query({
      queryFn: (widgetIds) => {
        const promises = widgetIds.map(async (id) => {
          if (id === 999999997) {
            const dummySmartAssetWidget = {
              primaryLabel: "Smart Asset",
              secondaryLabel: null,
              getDataURL: null,
              setDataURL: null,
              apiCode: null,
              hasWebSocketUpdate: null,
              refreshInterval: null,
              thresholdLower: null,
              thresholdUpper: null,
              location: null,
              legendField: null,
              valueField: null,
              columns: null,
              actions: null,
              legendFieldDataType: null,
              valueFieldDataType: null,
              widgetType: "smartAsset",
              showLegends: null,
              imageName: null,
              width: 1110,
              height: 718.5,
            };

            return { id, data: dummySmartAssetWidget };
          }
          if (id === 999999998) {
            const dummySensorPopupWidget = {
              primaryLabel: window.sensorType,
              secondaryLabel: null,
              getDataURL: window.dynamicURL,
              setDataURL: null,
              apiCode: null,
              hasWebSocketUpdate: null,
              refreshInterval: window.updateInterval,
              thresholdLower: null,
              thresholdUpper: null,
              location: null,
              legendField: "eventEpoch",
              valueField: "attributeValue",
              columns: null,
              actions: null,
              legendFieldDataType: "string",
              valueFieldDataType: "epoch",
              widgetType: "line-chart",
              showLegends: null,
              assetId: null,
              assetName: null,
              imageName: null,
              width: null,
              height: null,
            };

            return { id, data: dummySensorPopupWidget };
          }
          if (id === "Dummy-ID") return { id: "Dummy-ID", data: null };
          const response = await telemetry(`/dashboard/widget/${id}`);
          return {
            id,
            data: response.data.data,
          };
        });
        return Promise.all(promises).then((results) => ({ data: results }));
      },
      providesTags: ["smartTopology"],
    }),
    getTopologyData: builder.query({
      query: (getDataURL) => getDataURL,
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      // async onCacheEntryAdded(
      //   arg,
      //   { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      // ) {
      //   const ws = new Client({
      //     brokerURL: "wss://telemetry.coraltele.com/app/v2/messaging/messages",
      //     reconnectDelay: 0,
      //   });
      //   try {
      //     await cacheDataLoaded;
      //     ws.onConnect = function () {
      //       ws.subscribe("/app/v2/messaging/topic/events", (message) => {
      //         const outage = JSON.parse(message.body);
      //         updateCachedData((draft) => updateTreeNode(draft, outage));
      //       });
      //     };

      //     ws.activate();
      //   } catch (error) {
      //     console.log(error);
      //   }
      //   await cacheEntryRemoved;
      // },
    }),
    getNotificationData: builder.query({
      queryFn() {
        return { data: [] };
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded;

          setTimeout(() => {
            NotificationManager.releaseAllSubscriptions();
            NotificationManager.receiveMessageFor(
              "events",
              telemetryLogWS,
              "notification-stack",
            );
          }, 3000);

          const telemetryLogWS = (message) => {
            const outage = JSON.parse(message.body);
            updateCachedData((draft) => {
              if (draft.length > 0) draft.pop();
              draft.push(outage);
            });
          };

          // const getDummyNotification = () => {
          //   const possibleStatuses = ["critical", "warning", "ok", "major", "minor"];
          //   const randomIndex = Math.floor(Math.random() * possibleStatuses.length);
          //   const dummyNotification = {
          //     nodeId: 582,
          //     oid: "",
          //     nodeName: "/ PORT",
          //     ipAddress: "192.168.10.65",
          //     status: possibleStatuses[randomIndex],
          //     statusDetails: "",
          //     slotId: 16,
          //     slotName: "192.168.10.253",
          //     stackId: null,
          //     configuredLocation: "T0002",
          //     deviceMessage: null,
          //     serviceCode: "ICMP",
          //     severity: 4
          //   };
          //   updateCachedData((draft) => {
          //     if (draft.length > 0) draft.pop();
          //     draft.push(dummyNotification);
          //   });
          // };

          // setInterval(() => getDummyNotification(), 4000);
        } catch (error) {
          console.log(error);
        }
        await cacheEntryRemoved;
      },
    }),
  }),
});

export const {
  useGetDataQuery,
  useGetCategoryListQuery,
  useSaveCategorySensorsMutation,
  useGetLocationsQuery,
  useSaveLocationsMutation,
  useGetPopulatedWidgetListDataQuery,
  useGetTopologyDataQuery,
  useGetLayoutQuery,
  useSaveLayoutMutation,
  useGetNotificationDataQuery,
  useUploadFileMutation,
} = coralApi;
