import { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import renderSvgNode from "./renderSvgNode";
import Zoom from "./Zoom";
import { Fab } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Orientation from "./Orientation";
import TreeSlider from "./TreeSlider";
import MDBox from "components/MDBox";

function Topology({
  data,
  descendantsData,
  categoryData,
  severityData,
  isDarkMode,
  translate,
  toggleRefreshLocationTree,
  isEditMode,
  isAdmin,
}) {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (data) setTreeData(data);
  }, [data]);

  const updatedTreeData = (prev, id) => {
    return prev.map((node) => {
      const key = `locationTree-${node.id}`;
      node.id === id
        ? localStorage.setItem(key, !node.isSubtreeOpen)
        : localStorage.setItem(key, node.isSubtreeOpen);
      return {
        ...node,
        isSubtreeOpen:
          node.id === id ? !node.isSubtreeOpen : node.isSubtreeOpen,
        children:
          node.id === id
            ? node.children.length === 0
              ? node.subtree
              : []
            : updatedTreeData(node.children, id),
      };
    });
  };

  const handleTreeDataConfiguration = (id) => {
    setTreeData((prev) => updatedTreeData(prev, id));
  };

  const renderedNode = renderSvgNode(
    descendantsData,
    categoryData,
    severityData,
    isDarkMode,
    handleTreeDataConfiguration,
    isEditMode,
    isAdmin,
  );

  const [zoomLevel, setZoomLevel] = useState(
    JSON.parse(localStorage.getItem("locationTreeZoomLevel")) || 0.4,
  );
  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(1, prevZoomLevel + 0.1));
  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(0.1, prevZoomLevel - 0.1));

  useEffect(
    () =>
      localStorage.setItem("locationTreeZoomLevel", JSON.stringify(zoomLevel)),
    [zoomLevel],
  );

  const [orientation, setOrientation] = useState(
    JSON.parse(localStorage.getItem("locationTreeOrientation")) || "horizontal",
  );

  const handleOrientationChange = (event) => setOrientation(event.target.value);

  useEffect(
    () =>
      localStorage.setItem(
        "locationTreeOrientation",
        JSON.stringify(orientation),
      ),
    [orientation],
  );

  const [nodesSpacing, setNodesSpacing] = useState(
    JSON.parse(localStorage.getItem("locationTreeSiblingNodesSpacing")) || 1.55,
  );

  const [depthFactor, setDepthFactor] = useState(
    JSON.parse(localStorage.getItem("locationTreeDepthFactor")) || 300,
  );

  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      // Target node has no children -> this link leads to a leaf node.
      return isDarkMode ? "link__to-leaf-dark" : "link__to-leaf-light";
    }

    // Style it as a link connecting two branch nodes by default.
    return isDarkMode ? "link__to-branch-dark" : "link__to-branch-light";
  };

  return (
    <>
      <Fab
        color="info"
        onClick={toggleRefreshLocationTree}
        sx={{
          zIndex: "2",
          position: "absolute",
          bottom: "-3em",
          left: "1.2em",
        }}
        size="small"
      >
        <RestartAltIcon
          sx={{ marginBottom: ".07em", marginleft: ".03em" }}
          fontSize="medium"
        />
      </Fab>
      <MDBox display="flex" position="absolute" top=".7em" right="6em">
        <TreeSlider
          sliderHeading="Sibling Nodes Spacing"
          sliderValue={nodesSpacing}
          defaultValue={1.55}
          minValue={1}
          maxValue={5}
          step={0.01}
          handleSliderChange={(e, val) => {
            setNodesSpacing(val);
            localStorage.setItem("locationTreeSiblingNodesSpacing", val);
          }}
        />
        <TreeSlider
          sliderHeading="Depth Factor"
          sliderValue={depthFactor}
          defaultValue={300}
          minValue={50}
          maxValue={2000}
          step={1}
          handleSliderChange={(e, val) => {
            setDepthFactor(val);
            localStorage.setItem("locationTreeDepthFactor", val);
          }}
        />
      </MDBox>
      <Orientation
        orientation={orientation}
        handleOrientationChange={handleOrientationChange}
      />
      <Zoom zoomIn={zoomIn} zoomOut={zoomOut} />
      {treeData.length > 0 && (
        <Tree
          data={treeData[0]}
          translate={
            JSON.parse(
              localStorage.getItem("locationTreeTranslationCoordinates"),
            ) || translate
          }
          transitionDuration={0}
          zoom={zoomLevel}
          separation={{ nonSiblings: nodesSpacing, siblings: nodesSpacing }}
          depthFactor={depthFactor}
          renderCustomNodeElement={renderedNode}
          shouldCollapseNeighborNodes
          pathClassFunc={getDynamicPathClass}
          linkClass="my-link-class"
          onUpdate={({ translate, zoom }) => {
            localStorage.setItem(
              "locationTreeTranslationCoordinates",
              JSON.stringify(translate),
            );
            setZoomLevel(zoom);
          }}
          zoomable={false}
          orientation={orientation}
        />
      )}
    </>
  );
}

export default Topology;
