const getVariables = () => {
    if (window.overrideURLS) {
        return {
            "api": {
                "services": BASE_URL_API
            },
            "app": {
                "services": BASE_URL_APP
            }
        }
    } else {
        if (process.env.REACT_APP_MODE === "ui-dev") {
            return {
                "api": {
                    "services": "http://api.coraltele.com/services/api/v2/",
                    "telemetry": "http://api.coraltele.com/telemetry/api/v2/",
                },
                "app": {
                    "services": "http://api.coraltele.com/services/app/v2/",
                    "telemetry": "http://api.coraltele.com/telemetry/app/v2/",
                },
                "base": {
                    "services": "http://api.coraltele.com/services/",
                    "telemetry": "http://api.coraltele.com/telemetry/",
                },
                "webSocket": {
                    "services": "http://api.coraltele.com/services/app/v2/messaging/messages",
                    "telemetry": "http://api.coraltele.com/telemetry/app/v2/messaging/messages",
                },
                "events": {
                    "services": "/app/v2/",
                    "telemetry": "/app/v2/",
                },
                "sip" : {
                    "domain" : "ucdemo.coraltele.com",
                    "webRTCServer" : "wss://ucdemo.coraltele.com:7443"
                },
                "others" : {
                    "conferencePortal" : "https://192.168.250.191"
                }
            }
        } else if (process.env.REACT_APP_MODE === "ui-local") {
            return {
                "api": {
                    "services": `http://${process.env.REACT_APP_LOCAL_IP}:8996/api/v2/`,
                    "telemetry": `http://${process.env.REACT_APP_LOCAL_IP}:8998/api/v2/`
                },
                "app": {
                    "services": `http://${process.env.REACT_APP_LOCAL_IP}:8996/app/v2/`,
                    "telemetry": `http://${process.env.REACT_APP_LOCAL_IP}:8998/app/v2/`
                },
                "base": {
                    "services": `http://${process.env.REACT_APP_LOCAL_IP}:8996/`,
                    "telemetry": `http://${process.env.REACT_APP_LOCAL_IP}:8998/`
                }, 
                "webSocket": {
                    "services": `http://${process.env.REACT_APP_LOCAL_IP}:8996/app/v2/messaging/messages`,
                    "telemetry": `http://${process.env.REACT_APP_LOCAL_IP}:8998/app/v2/messaging/messages`
                },
                "events": {
                    "services": "/app/v2/",
                    "telemetry": "/app/v2/",
                },
                "sip" : {
                    "domain" : "cctest.coraltele.com",
                    "webRTCServer" : "wss://cctest.coraltele.com:7443"
                },
                "others" : {
                    "conferencePortal" : "https://192.168.250.191"
                }
            }
        }  else if (process.env.REACT_APP_MODE === "ui-test") {
            return {
                "api": {
                    "services": "http://test.coraltele.com/services/api/v2/",
                    "telemetry": "http://test.coraltele.com/telemetry/api/v2/"
                },
                "app": {
                    "services": "http://test.coraltele.com/services/app/v2/",
                    "telemetry": "http://test.coraltele.com/telemetry/app/v2/"
                },
                "base": {
                    "services": "http://test.coraltele.com/services/",
                    "telemetry": "http://test.coraltele.com/telemetry/"
                }, 
                "webSocket": {
                    "services": "http://test.coraltele.com/services/app/v2/messaging/messages",
                    "telemetry": "http://test.coraltele.com/telemetry/app/v2/messaging/messages"
                },
                "events": {
                    "services": "/app/v2/",
                    "telemetry": "/app/v2/",
                },
                "sip" : {
                    "domain" : "ucdemo.coraltele.com",
                    "webRTCServer" : "wss://ucdemo.coraltele.com:7443"
                },
                "others" : {
                    "conferencePortal" : "https://192.168.250.191"
                }
            }
        } else {
            return {
                "api": {
                    "services": window.location.origin + "/services/api/v2/",
                    "telemetry": window.location.origin + "/telemetry/api/v2/"
                },
                "app": {
                    "services": window.location.origin + "/services/app/v2/",
                    "telemetry": window.location.origin + "/telemetry/app/v2/"
                },
                "base": {
                    "services": window.location.origin + "/services/",
                    "telemetry": window.location.origin + "/telemetry/"
                },
                "webSocket": {
                    "services": window.location.origin + "/services/app/v2/messaging/messages",
                    "telemetry": window.location.origin + "/telemetry/app/v2/messaging/messages"
                },
                "events": {
                    "services": "/app/v2/",
                    "telemetry": "/app/v2/",
                },
                "sip" : {
                    "domain" :  window.location.hostname,
                    "webRTCServer" : window.location.protocol.replace("http", "ws") + "//" + window.location.hostname + ":7443"
                },
                "others" : {
                    "conferencePortal" : window.location.origin
                }
            }
        }
    }
}

const variables = getVariables();

export default variables;