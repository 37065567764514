import { useContext, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Draggable } from "@hello-pangea/dnd";
import { useMaterialUIController } from "context";
import { useGetTopologyDataQuery, useGetDataQuery } from "features/apiSlice";
import topologyData from "../../data/topologyData";
import GraphTopology from "./GraphTopology";
import topologyDataTransform from "../../utils/topologyDataTransform";
import nodeCount from "../../utils/nodeCount";
import ContextMenu from "../ContextMenu";
import MDTypography from "components/MDTypography";
import { LOCATION_GRAPH_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import Dropdown from "../../Dropdown";
import SeverityContext from "../../context/severityContext";

function LocationGraph({
  widgetHeight,
  widget,
  cellWidgetInfo,
  index,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  containerType,
  isEditMode,
  isAdmin,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const darkModeContext = useMaterialUIController();

  const isDarkMode = darkModeContext[0].darkMode;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  let getDataURL = null;
  let getCategoryDataURL = null;
  let refreshInterval = 0;
  if (widget) {
    getDataURL = widget.getDataURL;
    getCategoryDataURL = process.env.REACT_APP_CATEGORY_LIST_ENDPOINT;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
  }

  const topologyQuery = getDataURL
    ? useGetTopologyDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const categoryListQuery = getCategoryDataURL
    ? useGetDataQuery(getCategoryDataURL)
    : {};

  const severityListQuery = useContext(SeverityContext);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let secondaryLabel = null;
  let data = null;
  let descendantsData = null;
  let categoryData = null;
  let severityData = null;
  let error = null;
  if (widget) {
    if (topologyQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      data = topologyDataTransform(topologyQuery.data, "location-graph");
      descendantsData = nodeCount(topologyQuery.data);
      categoryData = categoryListQuery.data ? categoryListQuery.data : [];
      severityData = severityListQuery.data ? severityListQuery.data : [];
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      data = {};
      descendantsData = {};
      categoryData = [];
      severityData = [];
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    data = topologyDataTransform(topologyData, "location-graph");
    descendantsData = nodeCount(topologyData);
    categoryData = [];
    severityData = [];
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
  }

  const [refreshLocationGraph, setRefreshLocationGraph] = useState(false);

  const toggleRefreshLocationGraph = () =>
    setRefreshLocationGraph((prev) => !prev);

  const graphTopologyContainer = (provided = { dragHandleProps: {} }) => (
    <MDBox p={2} position="relative" height="90%">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
        {...provided.dragHandleProps}
      >
        <Icon fontSize="small">hub</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%">
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {error}
        <MDBox position="absolute" right=".9em">
          {isEditMode && isAdmin && (
            <Dropdown
              handleDeleteWidget={handleDeleteWidget}
              masterContainerId={masterContainerId}
              cellId={cellId}
              index={index}
              options={LOCATION_GRAPH_DROPDOWN_LIST}
            />
          )}
        </MDBox>
      </MDBox>
      {useMemo(
        () => (
          <GraphTopology
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            data={data}
            descendantsData={descendantsData}
            categoryData={categoryData}
            severityData={severityData}
            isDarkMode={isDarkMode}
            toggleRefreshLocationGraph={toggleRefreshLocationGraph}
          />
        ),
        [
          topologyQuery.data,
          categoryListQuery.data,
          severityListQuery.data,
          refreshLocationGraph,
        ],
      )}
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
            height:
              Object.keys(data).length > 0
                ? parseInt(widgetHeight, 10) * 12
                : parseInt(widgetHeight, 10) * 4,
          }}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {graphTopologyContainer(provided)}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
        height:
          Object.keys(data).length > 0
            ? parseInt(widgetHeight, 10) * 12
            : parseInt(widgetHeight, 10) * 4,
        cursor: "context-menu",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {graphTopologyContainer()}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default LocationGraph;
