import { jsPDF as JsPDF } from "jspdf";
import "jspdf-autotable";
import coralTelecom from "../../../../assets/images/CoralTelecom.png";

export default function downloadPDF(data, columns, name) {
  const modifiedColumns = columns.slice(0, 12);

  // Create a new jsPDF document
  const doc = new JsPDF({ orientation: "landscape" });

  // Set the logo image
  const logoWidth = 40;
  const logoHeight = 25
  const logoX = doc.internal.pageSize.getWidth() - logoWidth - 14;
  const logoY = 10;
  doc.addImage(coralTelecom, "PNG", logoX, logoY, logoWidth, logoHeight);

  // Set the heading
  const headingFontSize = 18;
  const headingX = 14;
  const headingY = 15;
  doc.setFontSize(headingFontSize);
  doc.text(name, headingX, headingY, { align: "left" });

  // Set the timestamp
  const timestamp = new Date().toLocaleString("en-IN"); // Get current timestamp
  const timestampFontSize = 12;
  const timestampX = 14;
  const timestampY = headingY + headingFontSize;
  doc.setFontSize(timestampFontSize);
  doc.text(timestamp, timestampX, timestampY, { align: "left" });

  // Add the data to the document
  doc.autoTable({
    startY: Math.max(logoY + logoHeight + 10, timestampY + 10), // Start the table below the logo and timestamp
    head: [modifiedColumns.map((column) => column.name)],
    body: data.map((record) => {
      let severity = null;
      if(record.severity === "🟢") severity = 0;
      else if(record.severity === "🟡") severity = 1;
      else if(record.severity === "🟠") severity = 2;
      else if(record.severity === "🔴") severity = 3;
      else if(record.severity === "🟤") severity = 4;
      
      return { ...record, severity };
    }),
    columns: modifiedColumns.map((column) => ({
      header: column.name,
      dataKey: column.id.split("///")[0],
    })),
  });

  // Save the PDF
  doc.save(`${name}.pdf`);
}
