import { v4 as uuidV4 } from "uuid";
import Wrapper from "layouts/nms/Wrapper";
import { useGetDataQuery } from "features/apiSlice";

function Sensors({ assetId }) {
  const assetSensorListQuery = useGetDataQuery(`/sensor/list/${assetId}`);

  const data = assetSensorListQuery.data;

  let sensorsWidgetList = null;
  if (data) {
    sensorsWidgetList = data.map((sensor) => ({
      id: sensor.provisioningId,
      data: {
        primaryLabel: sensor.sensorCode || sensor.sensorType || "Sensor label",
        secondaryLabel: null,
        getDataURL: "/metrics/data/f8798bf22e9017a17bf4aa16b5a0417b",
        setDataURL: null,
        apiCode: null,
        hasWebSocketUpdate: null,
        refreshInterval: sensor.updateInterval || 1000,
        thresholdLower: null,
        thresholdUpper: null,
        location: null,
        legendField: "eventEpoch",
        valueField: "attributeValue",
        columns: null,
        actions: null,
        legendFieldDataType: "epoch",
        valueFieldDataType: "number",
        widgetType: "line-chart",
        showLegends: null,
        imageName: null,
        width: null,
        height: null,
        category: null,
        min: null,
        max: null,
      },
    }));
  }

  return data ? (
    <Wrapper
      key={uuidV4()}
      id="1"
      isAdmin
      username="nkhanna"
      dashboardType="dashboard"
      popupMode
      isSensorsDashboard
      sensorsWidgetList={sensorsWidgetList}
    />
  ) : null;
}

export default Sensors;
