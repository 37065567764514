import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";

function ExternalAPI_Call({ onLoad, mode, pageId, searchText, registerCallBacks, onCall }) {

  const componentName = "ExternalAPI_Call";

  const [controller] = useMaterialUIController();
  const { activePage } = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);

  useEffect(() => {
    
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);



  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = () => {
    setConfirmDelete(true);
  };

  const onAgree = () => {
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setConfirmDelete(false);
  };



  return (
    <MDBox>
      <DeleteConfirmation
        entityName="ExternalAPI_Call"
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(deleteRow)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="changeEpoch"
        pageId={pageId}
        searchText={searchText}
        refetchInterval={Attributes.refetchInterval}
      />
      
      <CTLAdvancedFilter
        entityName="ExternalAPI_Call"
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />
    </MDBox>
  );
}

export default ExternalAPI_Call;
