import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import { MenuItem, Select } from "@mui/material";
import MDTypography from "components/MDTypography";

function CTLSelectWithColor({
  label,
  name,
  value,
  onChange,
  error,
  success,
  url,
  options,
  keyField,
  keyDescription1,
  keyDescription2,
  accessorKeyField,

}) {
  const [listOptions, setListOptions] = useState(options);
   
  useEffect(() => {
    if (url) {
      application.post(url).then((response) => {
        setListOptions(response.data.data);
      });
    }
  }, [url]);

  return (
    <div>
      <MDTypography variant="caption">{label}</MDTypography>
      <Select
        fullWidth
        name={name}
        value={accessorKeyField ? value[accessorKeyField] : value}
        onChange={onChange}
        style={{height:"60px",}}
        sx={{
          marginBottom: "4px",
          "& option": {
            color: "#344767",
          },
        }}
      >
        {listOptions.map((option) => (
          <MenuItem key={option[keyField]} value={option[keyField]}>
            <div
              style={{
                backgroundColor: option[keyDescription2],
                width: "40px",
                height: "20px",
                borderRadius: "10%",
              }}
            ></div>
            &nbsp;
            {option[keyDescription1]}{" "}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default CTLSelectWithColor;
