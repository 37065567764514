import { useState } from "react";
import { Droppable } from "@hello-pangea/dnd";
import { Grid, useMediaQuery } from "@mui/material";
import ContainerLayout from "../configs/ContainerLayout";
import widgetsSelected from "../configs/widgetsSelection";

function InnerHeader({
  masterContainerId,
  cellData,
  cellId,
  setLayoutData,
  handleDeleteWidget,
  widgetsDataObj,
  handleBreadcrumbs,
  widgetsDataObj2,
  setWidgetsDataObj2,
  isEditMode,
  isAdmin,
}) {
  const widgets = widgetsSelected(
    masterContainerId,
    cellId,
    handleDeleteWidget,
    cellData,
    setLayoutData,
    "horizontal",
    widgetsDataObj,
    handleBreadcrumbs,
    widgetsDataObj2,
    isEditMode,
    isAdmin
  );

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const droppableId = `${masterContainerId}/${cellId}`;

  return (
    <Droppable droppableId={droppableId} direction={matches ? "horizontal" : "vertical"}>
      {(provided) => (
        <Grid
          item
          minHeight={
            matches &&
            (widgets.length > 0 || !isEditMode ? "" : cellData.height)
          }
          position="relative"
          container={matches}
          border={isEditMode && "1px solid #ccc"}
          borderRadius={3}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <ContainerLayout
            isPopUpOpen={isPopUpOpen}
            setIsPopUpOpen={setIsPopUpOpen}
            masterContainerId={masterContainerId}
            cellId={cellId}
            setLayoutData={setLayoutData}
            widgets={widgets}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );
}

export default InnerHeader;
