export default function getTableWidgetDynamicColumns(columns) {
  return columns.find((column) => column.type.toLowerCase() === "severity")
    ? [
        ...columns,
        {
          field: "severityText",
          heading: "Severity Text",
          type: "string",
          display: true,
        },
      ]
    : [...columns];
}
