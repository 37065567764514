import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import {
  getAgentUpdateColumns,
  AgentUpdateAttributes,
  AgentUpdateOperationURLS,
} from "../agentUpdateModel";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import ViewQueue from "../../viewQueue";

function NonQueueAgent({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  setNonQueueAgent,
  queueupdateList,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [selectedRow, setSelectedRow] = useState([]);
  const [view, setView] = useState(false);
  const [viewData, setViewData] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const NQAgentoperationURLS = {
    agentNonQueue: `/agent/list/${ivrExtension?.code?.queue}`,
  };
  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onAgent: onOpenAgentForm,
      });
  }, [activePage]);

  function onView(row) {
    setView(true);
    setViewData(row);
  }

  function onCloseView() {
    setView(false);
    setViewData();
  }

  useEffect(() => {
    setUpdateList(Date.now());
  }, [ivrExtension, queueupdateList]);

  useEffect(() => {
    setNonQueueAgent(selectedRow);
  }, [selectedRow]);

  function onOpenAgentForm() {
    setOpenForm(true);
  }

  function onCloseAgentForm() {
    setOpenForm(false);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseAgentForm() {
    setOpenForm(false);
  }

  function onAgentSaveForm(data) {
    if (data.id) {
      application
        .post(AgentUpdateOperationURLS.agentUpdate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Call-Center:Agent-Setting",
          });
        })
        .catch((error) => {
          setOpenForm(false);
          setNotify({
            isOpen: true,
            message: error.response.data.messageDetail,
            type: "error",
            pagename: "Call-Center:Agent-Setting",
          });
        });
    }
    setOpenForm(false);
  }

  function onCloseAgentForm() {
    setOpenForm(false);
  }

  const onSelectionChange = (rowId) => {
    setSelectedRow(rowId);
  };

  return (
    <MDBox>
      <ViewQueue
        entityName="Agent Associated with Queue"
        view={view}
        onCloseView={onCloseView}
        pageAttributes={Attributes}
        viewData={viewData}
      />
      <CTLBaseDialogForm
        entityName="Call-Center:Agent"
        columns={getAgentUpdateColumns(onView)}
        onSaveForm={onAgentSaveForm}
        onCloseForm={onCloseAgentForm}
        formData={formData}
        pageAttributes={AgentUpdateAttributes}
      />
      <CTLTable
        apiURL={NQAgentoperationURLS.agentNonQueue}
        columns={getColumns(onView)}
        onSelectionChange={onSelectionChange}
        entriesPerPage="false"
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={true}
        updateList={updateList}
        disablePagination={true}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default NonQueueAgent;
