import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { useMaterialUIController } from "context";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PreviewIcon from "@mui/icons-material/Preview";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import { application } from "globals/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import {   Table, TableBody, TableCell, TableContainer, TableRow,  } from '@mui/material';


export default function ViewQueue({
  entityName,
  onCloseView,
  onView,
  view,
  row,
  viewData,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setOpen(view);
    if (view) {
      getQueue();
    }
  }, [view]);

  const handleClose = () => {
    setOpen(false);
    onCloseView();
  };

  const getQueue = () => {
    const agId = viewData?.id;
    application
      .post(`/agent/queueByAgent/${agId}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <tooltip title="View">
        {onView && (
          <IconButton color="info" onClick={() => onView(row.original)}>
            <PreviewIcon />
          </IconButton>
        )}
      </tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth className={dialog}>
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography>{entityName}</MDTypography>
            <MDButton onClick={handleClose} color="error">
              <CloseIcon />
            </MDButton>
          </MDBox>
        </DialogTitle>
         <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
      <Divider />
      {data.length > 0 ? (
        <>
          <TableContainer >
            <Table>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{color : darkMode ? '#ffffff' : 'gray'}}>{item.queueName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography>"Not associated with any queue"</Typography>
      )}
    </DialogContent>
      </Dialog>
    </>
  );
}
