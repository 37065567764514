import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PrintIcon from "@mui/icons-material/Print";

function MoreVertAction({ row, onPrintPdf, onDirtyCleanStatus, onEdit }) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {onPrintPdf && (
        <tooltip title="CDR Report">
          <IconButton color="info" onClick={() => onPrintPdf(row.original)}>
            <PrintIcon></PrintIcon>
          </IconButton>
        </tooltip>
      )}

      {onEdit && (
        <tooltip title="Update">
          <IconButton color="success" onClick={() => onEdit(row.original)}>
            <OpenInNewIcon></OpenInNewIcon>
          </IconButton>
        </tooltip>
      )}

      {onDirtyCleanStatus && (
        <tooltip title="Dirty/Clean">
          <IconButton
            color="primary"
            onClick={() => onDirtyCleanStatus(row.original)}
          >
            <CleaningServicesIcon></CleaningServicesIcon>
          </IconButton>
        </tooltip>
      )}
    </MDTypography>
  );
}

export default MoreVertAction;
