import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  formSize: "md",
  enableAddButton: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/queueTL/list",
  create: "/queueTL/create",
  update: "/queueTL/update",
  delete: "/queueTL/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Team Leader",
      accessor: "teamLeaderId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "userName",
        keyDescription: "userName",
        values: [],
        url: "/queueTL/listTL",
      },
    },
    {
      Header: "Queue List",
      accessor: "queue",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "multipleSelect",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "/queue/listAll",
      },
    },
    {
      Header: "Queue List",
      accessor: "queueDescription",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "multipleSelect",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "/queue/listAll",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
