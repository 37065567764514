import DefaultCell from "application/components/DefaultCell";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "lg",
  columns: 3,
  settingsMode: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/callServerManagerFile/list",
  update: "/callServerManagerFile/update",
};

const getColumns = () => {
  return [
    {
      Header: "Mail.Username",
      accessor: "mailUsername",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Username",
    },
    {
      Header: "Mail.Password",
      accessor: "mailPassword",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      controlType: "password",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Password",
    },

    {
      Header: "Mail.Server",
      accessor: "mailServer",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Server",
    },
    {
      Header: "Mail.Port",
      accessor: "mailPort",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Port",
    },
    {
      Header: "Mail.Protocol",
      accessor: "mailProtocol",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Protocol",
    },
    {
      Header: "Mail.Name",
      accessor: "mailName",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Name",
    },
    {
      Header: "Mail.From",
      accessor: "mailFrom",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure From",
    },
    {
      Header: "Mail.Smtpauth",
      accessor: "mailSmtPAuth",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      controlType: "boolean",
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure Smtpauth",
    },
    {
      Header: "Mail.TTLS ",
      accessor: "mailTTls",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation: " Use this configuration to configure TTLS",
    },
  ];
};

export { getColumns, Attributes, operationURLS };
