import DefaultCell from "application/components/DefaultCell";
import DefaultComparisonCell from "application/components/CTLDefaultComparisonCell";
import CTLSelectCell from "application/components/CTLSelectCell";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "md",
  enableAddButton: false,
  enableDefaulComparisontButton: true,
  enableNoDefaultComparisonButton: true,
  enableAllList: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Download Success File",
    error: "Unable to Download Fite",
  },
};

const operationURLS = {
  list: "/defaultComparison/list",
};

const getColumns = () => {
  return [
    {
      Header: "Extension No",
      accessor: "extension",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 12,
    },
    {
      Header: "Extension Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 12,
    },
    {
      Header: "ISD",
      accessor: "isd",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },
    {
      Header: "STD",
      accessor: "std",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },
    {
      Header: "Local",
      accessor: "local",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },

    {
      Header: "VM	",
      accessor: "vm",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },
    {
      Header: "DND",
      accessor: "dnd",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },
    {
      Header: "Intercom",
      accessor: "ic",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },

    {
      Header: "Video Call",
      accessor: "enableVideoCall",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },
    {
      Header: "Day InBound COS",
      accessor: "dayInboundCos",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Day OutBound COS",
      accessor: "dayOutboundCos",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Night InBound COS",
      accessor: "nightInboundCos",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Night OutBound COS",
      accessor: "nightOutboundCos",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Ring Time",
      accessor: "ringTime",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "record",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultComparisonCell value={value} />,
    },
    {
      Header: "Call Waiting Status",
      accessor: "callWaitingStatus",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLSelectCell value={value} />,
    },
    {
      Header: "SIP Password",
      accessor: "sipPassword",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
