import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "md",
  enableAddButton: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled : item.disabled,
      productionReady : item.productionReady
    };
  })
};

const operationURLS = {
  list: "form/list",
  update: "form/update",
  create: "form/create",
  delete: "form/delete",
  read : "form/createJson"
};

const getColumns = (onDelete, onEdit , onFormSave) => {
  return [
    {
      Header: "Form Name",
      accessor: "formName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Form Code",
      accessor: "formCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
          onFormSave={onFormSave}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
