import DefaultCell from "application/components/DefaultCell";
import TelephoneCell from "application/components/TelephoneCell";

const Attributes = {
  enableAddButton: false,
  refetchInterval:5000,
  modes: [
  ],
};

const operationURLS = {
  list: "/externalApi/callBack",
};

const getColumns = (onDelete, onEdit, onCall) => {
  return [
    {
      Header: "Created On",
      accessor: "createdOn",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "By",
      accessor: "fromUser",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
    },
    {
      Header: "Last Attempted",
      accessor: "updatettime_Attemptcallback",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    }
  ];
}

export { getColumns, Attributes, operationURLS };
