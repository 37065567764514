import CTLSelectCell from "application/components/CTLSelectCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";
import formControl from "globals/form-control";
import { column } from "stylis";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "location/list",
  update: "location/update",
  create: "location/create",
  delete: "location/delete/",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Background Image URL",
      accessor: "backgroundImageURL",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      groupHeading: "Location",
    },
    {
      Header: "Location Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      disabled: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Location Code Is Required"),
      width: 6,
      groupHeading: "Location",
    },
    {
      Header: "Location Name",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Location Code Is Required"),
      width: 6,
      groupHeading: "Location",
    },
    {
      Header: "Parent Location",
      accessor: "parentLocation",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <CTLSelectCell value={value} columns={column} />,
      width: 6,
      groupHeading: "location",
      options: {
        keyField: "locationId",
        keyDescription: "locationName",
        values: [],
        url: "location/dropdown/list",
      },
    },

    {
      Header: "X Coordinate",
      accessor: "xcoordinate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      groupHeading: "Location",
    },
    {
      Header: "Y Coordinate",
      accessor: "ycoordinate",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
      groupHeading: "Location",
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
