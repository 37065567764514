import CTLBooleanUser from "application/components/CTLBooleanforUser";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

let AgentMenu = AuthenticationService.allowAgentMenus();
const Attributes = {
  formSize: "md",
  columns: 3,
  modes: [],
  settingsMode: true,
  enableExitButton: AgentMenu ? true : false,
};

const operationURLS = {
  list: "user/list",
  update: "user/update",
};

const getColumns = (onEdit) => {
  return [
    {
      Header: "User ID",
      accessor: "username",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      disabled: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Contact No./Extension No.",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      disabled: true,
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().emailOnly(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4(),
    },
    {
      Header: "Active",
      accessor: "enabled",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanUser value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
