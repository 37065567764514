import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";

function SystemEmailConfiguration({ onLoad, pageName, registerCallBacks }) {
 const [formData, setFormData] = useState(null);
 const [notify, setNotify] = useState({
 isOpen: false,
 message: "",
 type: "",
 pagename: "",
 status: "",
 });

 useEffect(() => {
 Attributes && onLoad(Attributes);
 registerCallBacks && registerCallBacks({});
 application
 .post(operationURLS.list)
 .then((response) => {
 let dataToDisplay = response.data.data[0];
 setFormData(dataToDisplay);
 })
 .catch((error) => {
 setFormData([]);
 });
 }, []);

 function onSaveForm(data) {
 application
 .post(operationURLS.update, data)
 .then((response) => {
 setNotify({
 isOpen: true,
 message: response.data.messageDetail,
 type: "success",
 pagename: pageName,
 status: response.data.status,
 });
 return application.post(operationURLS.list);
 })
 .then((response) => {
 let dataToDisplay = response.data.data[0];
 setFormData(dataToDisplay);
 })
 .catch((error) => {
 setNotify({
 isOpen: true,
 status: error.response.data.status,
 message: response.data.messageDetail,
 type: "error",
 pagename: pageName,
 });
 });
 }

 return (
 <MDBox>
 <CTLBaseForm
 enableFormButtons={true}
 entityName="SystemEmailConfiguration"
 columns={getColumns(null, null)}
 openForm={true}
 onSaveForm={onSaveForm}
 onCloseForm={null}
 formData={formData}
 formAttributes={Attributes}
 />
 <CTLNotification notify={notify} setNotify={setNotify} />
 </MDBox>
 );
}

export default SystemEmailConfiguration;