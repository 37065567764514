import { useEffect } from "react";

import MDBox from "components/MDBox";

import CallBack from "application/modules/uc/call-back";

import { useMaterialUIController, setLayout } from "context";
import NMSAlerts from "application/modules/site/nms/alerts";


function DefaultUnAuthorisedPage() {
    const [controller, dispatch] = useMaterialUIController();
    const { activePage } = controller;

    useEffect(() => {
        setLayout(dispatch, "page");
    }, []);

    const renderComponent = (currentPage) => {
        switch (currentPage) {
            case "app-nms-alerts":
                return (<NMSAlerts />);
                break;

            default:
                return (<NMSAlerts />);
        }
    }

    return (
        <MDBox
            width="100vw"
            height="100%"
            minHeight="100vh"
            sx={{ overflowX: "hidden" }}
        >
            {renderComponent(activePage.key)}
        </MDBox>
    );
}

export default DefaultUnAuthorisedPage;