import { Slider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function TreeSlider({
  sliderHeading,
  sliderValue,
  defaultValue,
  minValue,
  maxValue,
  step,
  handleSliderChange,
}) {
  return (
    <MDBox
      sx={{
        width: "11em",
        marginRight: "2em",
      }}
    >
      <MDTypography variant="text" fontWeight="regular" color="text">
        {sliderHeading}
      </MDTypography>
      <Slider
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
        value={sliderValue}
        onChange={handleSliderChange}
        step={step}
      />
    </MDBox>
  );
}

export default TreeSlider;
