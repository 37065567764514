import { useEffect } from "react";
import { useGetNotificationDataQuery } from "features/apiSlice";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useGetDataQuery } from "features/apiSlice";

export default function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const severityListQuery = useGetDataQuery(
    process.env.REACT_APP_SEVERITY_LIST_ENDPOINT
  );
  const notificationDataQuery = useGetNotificationDataQuery("");
  useEffect(() => {
    if (
      severityListQuery.data &&
      notificationDataQuery.data &&
      notificationDataQuery.data.length > 0
    ) {
      const { nodeName, status, serviceCode } = notificationDataQuery.data[0];
      const severityList = severityListQuery.data;

      let variant = null;
      let variantColor = null;
      let textColor = null;
      let notificationMessage = null;
      let isPersistableNotification = false;
      if (typeof status === "string") {
        const transformedStatus = status.toUpperCase();

        isPersistableNotification =
          transformedStatus === "ERROR" ||
          transformedStatus === "CRITICAL" ||
          transformedStatus === "FATAL";
        isPersistableNotification = transformedStatus !== "OK";

        const severityObj = severityList.find(
          (severity) =>
            severity.severityName.toUpperCase() === transformedStatus
        );

        const textObj = severityList.find(
          (severity) => severity.severityName.toUpperCase() === "TEXT"
        );

        textColor = textObj ? textObj.color : "#FFF";

        if (severityObj) {
          variant = severityObj.severityName;
          variantColor = severityObj.color;
        } else {
          variant = "default";
          variantColor = "#000";
        }
        notificationMessage = `${serviceCode}-${nodeName} is in status ${transformedStatus}`;
      } else if (typeof status === "boolean") {
        isPersistableNotification = !status;
        variant = status ? "success" : "error";
        notificationMessage = `${serviceCode}-${nodeName} is ${
          status ? "up" : "down"
        }`;
      }
      enqueueSnackbar(notificationMessage, {
        variant,
        style: {
          color: "#000",
          backgroundColor: "#FFF",
          borderLeft: `1em solid ${variantColor}`,
          color: textColor,
          backgroundColor: variantColor,
        },
        persist: isPersistableNotification,
        autoHideDuration: process.env.REACT_APP_NOTIFICATION_AUTO_HIDE_DURATION,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        action: (key) => (
          <Button
           // sx={{ color: variantColor }}
            sx={{ color: textColor }}
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            Dismiss
          </Button>
        ),
      });
    }
  }, [notificationDataQuery.data]);
}
