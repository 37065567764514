const reportMenus = [
  {
    icon: "edit",
    key: "configuration.billing-cdr",
    name: "Billing CDR",
    url: "home/app/billing/Cdr",
    description: "Billing-CDR",
    productionReady: true,
  },
  {
    icon: "summarize_icon",
    key: "csm_reports",
    name: "CMS Reports",
    url: "home/app/cms-reports",
    description: "CMS Reports",
    productionReady: true,
  },
  {
    icon: "article",
    key: "callDisposition",
    name: "Disposition Analysis-Campaign",
    url: "home/app/callDisposition",
    description: "CallDisposition Report",
    productionReady: true,
  },
  {
    icon: "analytics_icon  ",
    key: "campaignSummary",
    name: "Campaign Summary",
    url: "home/app/campaignSummary",
    description: "Campaign Summary",
    productionReady: true,
  },
  {
    icon: "crisis_alert",
    key: "configuration.nms-alert-history",
    name: "Nms-Alert-History",
    url: "home/app/configurations/nms-alert-history",
    description: "Nms-Alert-History",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "user-login-logout",
    name: "Agent-LogIn-LogOut",
    url: "home/app/configurations/user-login-logout",
    description: "Agent-Login-Logout Report",
    productionReady: true,
  },
  {
    icon: "summarize_icon",
    key: "Customer.CDRreport",
    name: "Customer CDR Rreport",
    url: "home/app/configurations/CustomerCDRreport",
    description: "Customer CDR report",
    productionReady: true,
  },
  {
    icon: "folder_copy",
    key: "Operator.Summary",
    name: "Operator Summary",
    url: "home/app/configurations/Operator Summary",
    description: "Operator.Summary Report",
    productionReady: true,
  },
  {
    icon: "receipt",
    key: "pareport",
    name: "PA Reports",
    url: "home/app/configurations/PAreport",
    description: "PA-Reports",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-performance",
    name: "Daily Performance Report",
    url: "home/app/billing/agent-performance",
    description: "Agent Daily Performance Report",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-DailyReport",
    name: "Daily Report",
    url: "home/app/billing/agent-dailyReport",
    description: "Agent Daily Report",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "group-abandoned-call",
    name: "Group Abandoned Call",
    url: "home/app/configurations/report/GroupAbandonedCall",
    description: "Group Abandoned Call",
    productionReady: true,
  },
];

export { reportMenus };
