import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const Menus = [
  {
    icon: (
      <Tooltip title="Change Password">
        <Icon fontSize="medium">lockReset</Icon>
      </Tooltip>
    ),
    name: "Change Password",
    key: "Change-password",
    url: "/app/ChangePassword",
    description: "Change Password",
  },
];

export { Menus };
