import { useEffect, useRef, useState } from "react";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import calculateSmartAssetMarkerPosition from "layouts/nms/utils/calculateSmartAssetMarkerPosition";
import FolderIcon from "@mui/icons-material/Folder";
import CircleIcon from "@mui/icons-material/Circle";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

const VerticalText = ({ text }) => {
  const letters = text?.split("");
  return (
    <>
      {letters?.map((letter, index) => (
        <div key={index} style={{ textTransform: "capitalize" }}>
          {letter}
        </div>
      ))}
    </>
  );
};

function AssetImage({
  isAdmin,
  isEditMode,
  assetImageName,
  bufferLeft,
  bufferTop,
  handleAssignSensorPopupOpen,
  handleParentRefCurrent,
  assetSensorListQuery,
  category,
  assetIpAddress,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuEvent, setContextMenuEvent] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const parent = document.getElementById("smartAssetParent");
    handleParentRefCurrent(parent);
  }, []);

  const handleImageClick = (event) => {
    if (!imageRef.current) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateSmartAssetMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    handleAssignSensorPopupOpen(left, top);

    setContextMenu(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    setContextMenuEvent(event);
  };

  const handleClick = () => {
    const popupFeatures = "fullscreen=yes,resizable=yes,scrollbars=yes";
    window.open(`http://${assetIpAddress}`, "_blank", popupFeatures);
  };

  return (
    <MDBox onContextMenu={handleContextMenu} sx={{ cursor: "context-menu" }}>
      <div>
        {category == "ICC4" ? (
          <>
            <img
              style={{ width: "100%", height: "620px" }}
              src={assetImageName}
              alt="SmartAssetImage"
              ref={imageRef}
            />
            {generateMarkerElements()}
          </>
        ) : (
          <>
            {" "}
            <MDBox
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FolderIcon color="secondary" fontSize="large" />
            </MDBox>
            <div style={{ marginTop: "15px" }}>{generateMarkerElements()}</div>
          </>
        )}
      </div>
      {isAdmin && isEditMode && (
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => handleImageClick(contextMenuEvent)}>
            Assign sensor
          </MenuItem>
        </Menu>
      )}
    </MDBox>
  );

  function generateMarkerElements() {
    const data = assetSensorListQuery?.data;

    return data?.map((item, index) => {
      let color;
      switch (item.sensorStatus) {
        case 0:
          color = "green";
          break;
        case 1:
          color = "yellow";
          break;
        case 2:
          color = "red";
          break;
        default:
          color = "gray";
      }

      let left;
      switch (item.sensorCode) {
        case "SLOT-8":
          left = "2%";
          break;
        case "SLOT01":
          left = "7%";
          break;
        case "SLOT02":
          left = "12%";
          break;
        case "SLOT03":
          left = "17%";
          break;
        case "SLOT04":
          left = "22%";
          break;
        case "SLOT05":
          left = "27%";
          break;
        case "SLOT06":
          left = "32%";
          break;
        case "SLOT07":
          left = "37%";
          break;
        case "SLOT08":
          left = "42%";
          break;
        case "SLOT-10":
          left = "47%";
          break;
        case "SLOT-9":
          left = "52%";
          break;
        case "SLOT09":
          left = "57%";
          break;
        case "SLOT10":
          left = "62%";
          break;
        case "SLOT11":
          left = "67%";
          break;
        case "SLOT12":
          left = "72%";
          break;
        case "SLOT13":
          left = "77%";
          break;
        case "SLOT14":
          left = "82%";
          break;
        case "SLOT15":
          left = "87%";
          break;
        case "SLOT16":
          left = "92%";
          break;
        case "SLOT-7":
          left = "97%";
          break;
        default:
          left = "null";
      }

      if (
        category == "ICC4" &&
        (item.sensorType == "CPU1" || item.sensorType == "CPU2")
      ) {
        return (
          <React.Fragment key={index}>
            {item.sensorStatusName != "ABSENT" && (
              <div
                style={{
                  position: "absolute",
                  top: "1.5%",
                  left: left,
                  color: color,
                  fontSize: "24px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "translateZ(24px)",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <CircleIcon />
                </div>
              </div>
            )}
            {item.sensorStatusName != "ABSENT" && (
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: left,
                  color: "white",
                  fontSize: "18px",
                }}
              >
                <VerticalText text={item.sensorType} />
                {item.sensorStatusName == "MASTER" && (
                  <VerticalText text={`-${data[0].sensorType}`} />
                )}
              </div>
            )}
          </React.Fragment>
        );
      } else if (
        (category == "ICC4" && item.sensorType == "PS1") ||
        item.sensorType == "PS2"
      ) {
        return (
          <React.Fragment key={index}>
            {item.sensorStatusName != "ABSENT" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "1.5%",
                    left: left,
                    color: color,
                    fontSize: "24px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: "translateZ(24px)",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <CircleIcon />
                  </div>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: left,
                    color: "white",
                    fontSize: "18px",
                  }}
                >
                  <VerticalText text={item.sensorType} />
                </div>
              </>
            )}
          </React.Fragment>
        );
      } else if (category == "ICC4" && left != "null") {
        return (
          <React.Fragment key={index}>
            {item.sensorType !== "NONE" && (
              <div
                style={{
                  position: "absolute",
                  top: "1.5%",
                  left: left,
                  color: color,
                  fontSize: "24px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "translateZ(24px)",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <CircleIcon />
                </div>
              </div>
            )}
            <div
              style={{
                position: "absolute",
                top: "45%",
                left: left,
                color: "white",
                fontSize: "18px",
              }}
            >
              <VerticalText text={item.sensorType} />
            </div>
          </React.Fragment>
        );
      } else if (item.sensorCode == "ICMP") {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                style={{
                  alignItems: "center",
                  padding: "15px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 1.3)",
                }}
              >
                <MDTypography
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                >
                  {" "}
                  <div
                    style={{
                      position: "absolute",
                      marginTop: "8px",
                      left: "46%",
                      color: color,
                      fontSize: "24px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        transform: "translateZ(24px)",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <CircleIcon />
                    </div>
                  </div>
                  {item.sensorType}
                </MDTypography>
              </Card>
            </Grid>
          </Grid>
        );
      }
    });
  }
}

export default AssetImage;
