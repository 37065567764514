import React, { useEffect } from "react";
import { Dialog, DialogActions, DialogTitle, Icon } from "@mui/material";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";

export default function CTLConfirmationBox({
  confirmationBox,
  onAgree,
  onCancel,
}) {
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    setOpen(confirmationBox);
  }, [confirmationBox]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      classes={{ dialog }}
    >
      <DialogTitle
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        id="alert-dialog-title"
      >
        {" "}
        <Icon color="error" style={{ paddingTop: "5px" }} fontSize="large">
          warning_amber
        </Icon>{" "}
        Are you certain about generating a new API key? Please note that it will
        override the current key.
      </DialogTitle>
      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <MDButton onClick={onCancel} color="secondary">
          Disagree
        </MDButton>
        <MDButton onClick={onAgree} color="error" autoFocus>
          Agree
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
