import { v4 as uuidV4 } from "uuid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function SelectCategoryFilter({
  categories,
  assetCategory,
  handleAssetCategoryChange,
  isDarkMode,
}) {
  return (
    <FormControl
      sx={{ minWidth: 200, marginTop: ".1em", marginRight: ".4em" }}
      size="large"
    >
      <InputLabel
        id="category-label"
        sx={{ color: isDarkMode && "#FFF" }}
      >
        Category filter
      </InputLabel>
      <Select
        labelId="category-label"
        id="category"
        value={assetCategory}
        onChange={handleAssetCategoryChange}
        label="Category filter"
        sx={{
          color: isDarkMode && "#FFF",
          padding: ".5em",
        }}
      >
        <MenuItem value="All Categories">All Categories</MenuItem>
        {categories.map((category) => (
          <MenuItem key={uuidV4()} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectCategoryFilter;
