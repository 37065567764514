import DefaultCell from "application/components/DefaultCell";


const getUpdateColumns = () => {
  return [
    {
      Header: "Team Leader",
      accessor: "teamLeaderId",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      disabled: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "userName",
        keyDescription: "userName",
        values: [],
        url: "/queueTL/listTL",
      },
    },
    {
      Header: "Queue List",
      accessor: "queueData",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "integer",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "/queue/listAll",
      },
    },
   
   
  ];
};

export { getUpdateColumns  };
