import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Notification from "application/components/Notification";
import AuthenticationService from "../AuthenticationService";
// Authentication layout components
import IllustrationLayout from "./layout";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// Image
import appleIcon from "assets/images/signInLogo.png";
import {
  useMaterialUIController,
  setActivePage,
  setAuthentication,
  setLayout,
  setUserName,
  setPassWord
} from "context";
import { externalCallServer } from "globals/endpoints";
import CryptoJS from "crypto-js"; // Import the crypto-js library for encryption
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";

function Illustration(props) {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [captchaSuccess, setCaptchaSuccess] = useState(false);





  useEffect(() => {
    AuthenticationService.logout();
    setAuthentication(dispatch, false);
  
  }, []);

  async function login() {
    setUserName(dispatch, username);
    setPassWord(dispatch, password);
    try {
      const encryptedUsername = encryptData(username, window.secretKey);
      const encryptedPassword = encryptData(password, window.secretKey);
  
      const response = await AuthenticationService.login(
        encryptedUsername,
        encryptedPassword
      );
  
      AuthenticationService.storeAuthenticationDetails(
        response.data.data?.sipPhoneButton,
        response.data.data.token,
        response.data.data.refreshToken,
        response.data.data.userName,
        response.data.data.fullName,
        response.data.data.roles,
        response.data.data.menu,
        response.data.data.coralApplication,
        {
          extensionName: response.data.data.extensionName,
          extension: response.data.data.extension,
          sipPassword: response.data.data.sipPassword,
        },
        response.data.data.rluCode,
        response.data.data.rluIp,
        response.data.data.pwdComplication
      );
  
      if (props.login) {
        props.onLogin(true);
      }
  
      activePage.key = "sign.in";
      activePage.authenticationStatus = true;
      setActivePage(dispatch, activePage);
      setAuthentication(dispatch, true);
      setLayout(dispatch, "dashboard");
      externalCallServer.defaults.baseURL = AuthenticationService.getRLUIpAddress();
      navigate("/app/home", { replace: true });
  
      const payload = { status: "Login" };
      await application.post("/agentStatus/create", payload);
    } catch (error) {
      setNotify({
        isOpen: true,
        message:
          error.response?.status === 400
            ? error.response.data.message +
              " " +
              error.response.data.messageDetail
            : error.message,
        type: "error",
      });
  
      if (
        error.response?.status === 400 &&
        error.response.data.data?.requiredChangePassword === true
      ) {
        AuthenticationService.setChangePasswordRequired("true");
        setTimeout(() => {
          navigate("/app/ChangerequiredPassword");
        }, 3000);
      } else {
        console.error("Error:", error);
      }
    }
  }
  

  const [showPassword, setShowPassword] = useState(false);

  // Visibility & VisibilityOff Password
  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  // Function to encrypt data with a random IV
  const encryptData = (data, secretKey) => {
    const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
    const ciphertext = CryptoJS.AES.encrypt(
      data,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return iv.concat(ciphertext.ciphertext).toString(CryptoJS.enc.Base64);
  };

  const signInButtonColor = window.captcha
    ? captchaSuccess
      ? "info"
      : "error"
    : "info";
  const ButtonDisable = window.captcha ? !captchaSuccess : false;

  return (
    <IllustrationLayout
      title="Login"
      description="Enter your username and password to login"
      illustration={appleIcon}
    >
      <MDBox component="form" role="form" onKeyPress={handleKeyPress}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="User Name"
            fullWidth
            onChange={(e) => setUsername(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </MDBox>
        <MDBox mb={2} display="flex" alignItems="center">
          <MDInput
            type={showPassword ? "text" : "password"}
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={handleShowPassword}
                    onMouseUp={handleHidePassword}
                    edge="end"
                    style={{ margin: "1px" }}
                    color="info"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyPress={handleKeyPress}
          />
        </MDBox>
        {window.captcha && (
          <MDBox sx={{ display: "flex", justifyContent: "center" }}>
            <Captcha
              onKeyPress={handleKeyPress}
              value={captchaSuccess}
              onChange={(value) => setCaptchaSuccess(value)}
              onRefresh={() => console.log("!1111")}
            />
          </MDBox>
        )}
        <MDBox mt={2} mb={1}>
          <MDButton
            disabled={ButtonDisable}
            variant="gradient"
            color={signInButtonColor}
            size="large"
            fullWidth
            onClick={login}
          >
            Login
          </MDButton>
        </MDBox>
        <MDBox
          mb={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MDTypography variant="caption">
            {" " + process.env.REACT_APP_VERSION}
          </MDTypography>
        </MDBox>
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </IllustrationLayout>
  );
}

export default Illustration;
