const WidgetConfigMenu = [
  {
    icon: "show_chart_icon",
    key: "widgetConfiguration",
    name: "Widget Configuration",
    description: "Widget Configuration",
    url: "home/app/widgetConfiguration",
    disabled: false,
    productionReady: true,
  },
  
  {
    icon: "table",
    key: "table",
    name: "Table",
    description: "Table",
    url: "home/app/table",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "bar_chart_icon ",
    key: "pieChart",
    name: "Pie Chart",
    description: "Pie Chart",
    url: "home/app/pie-chart",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "insights_icon",
    key: "doughnutChart",
    name: "Doughnut Chart",
    description: "Doughnut Chart",
    url: "home/app/doughnut-chart",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "table",
    key: "serverPaginatedTable",
    name: "Server Paginated Table",
    description: "Server Paginated Table",
    url: "home/app/server-paginated-table",
    disabled: false,
    productionReady: true,
  },
];
export default WidgetConfigMenu;
