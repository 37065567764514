import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@mui/material/Alert";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  DialogActions,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Card,
  IconButton,
  Snackbar,
} from "@mui/material";
import CTLDialogContent from "../styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MDButton from "components/MDButton";
import TableViewIcon from "@mui/icons-material/TableView";
import DataTable, { createTheme } from "react-data-table-component";
import colors from "assets/theme-dark/base/colors";

const initialColumn = {
  heading: "",
  field: "",
  type: "",
  display: true,
};

const CTLJsonTable = ({ row, value, onSave, onClose }) => {
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [newColumn, setNewColumn] = useState({ ...initialColumn });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const darkModeContext = useMaterialUIController();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [position, setPosition] = useState("");

  const handleSnackbarOpen = (position, color, message) => {
    setSnackbarOpen(true);
    setPosition(position);
    setColor(color);
    setMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    try {
      const parsedValue = JSON.parse(value || []);
      setColumns(parsedValue);
    } catch (error) {
      console.error("Error parsing value:", error);
    }
  }, [value]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose(true);
    setOpen(false);
  };

  const handleAddColumn = () => {
    if (
      newColumn.heading === "" ||
      newColumn.field === "" ||
      newColumn.type === ""
    ) {
      handleSnackbarOpen(
        "right",
        "warning",
        "Please fill in all required fields."
      );
    } else {
      const column = {
        ...newColumn,
      };
      setColumns([...columns, column]);
      setNewColumn({ ...initialColumn });
      handleSnackbarOpen("right", "success", "Successfully create Column..");
    }
  };

  const handleRemoveColumn = (index) => {
    const updatedColumns = [...columns];
    updatedColumns.splice(index, 1);
    setColumns(updatedColumns);
    handleSnackbarOpen("right", "error", "successfully remove");
  };

  createTheme(
    "modifiedDarkTheme",
    {
      striped: {
        default: colors.background.default,
      },
      selected: {
        default: colors.background.default,
      },
      background: {
        default: colors.background.card,
      },
    },
    "dark"
  );

  const header = [
    { name: "Heading", selector: (row) => row.heading, sortable: true },
    { name: "Field", selector: (row) => row.field, sortable: true },
    { name: "Type", selector: (row) => row.type, sortable: true },
    {
      name: "Display",
      selector: (row) => row.display,
      sortable: true,
      cell: (row) => <span>{row.display ? "True" : "False"}</span>,
    },
    {
      name: "Action",
      cell: (row) => (
        <IconButton color="error" onClick={() => handleRemoveColumn(row.index)}>
          <DeleteForeverIcon style={{ cursor: "pointer" }} />
        </IconButton>
      ),
    },
  ];

  const handleSave = () => {
    onSave(columns, row.original);
    setOpen(false);
  };

  return (
    <div>
      <tooltip title={"Add Column"}>
        <IconButton color="info" onClick={handleClickOpen}>
          <TableViewIcon />
        </IconButton>
      </tooltip>
      <Dialog
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        onClose={handleClose}
        open={open}
        maxWidth={"100px"}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Add Column
          <IconButton
            color="error"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <Card>
            <Card style={{ padding: "10px" }}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    label="Heading Name"
                    fullWidth
                    required
                    value={newColumn.heading}
                    onChange={(e) =>
                      setNewColumn({ ...newColumn, heading: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Field Name"
                    fullWidth
                    required
                    value={newColumn.field}
                    onChange={(e) =>
                      setNewColumn({ ...newColumn, field: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Data Type"
                    fullWidth
                    required
                    value={newColumn.type}
                    onChange={(e) =>
                      setNewColumn({ ...newColumn, type: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl>
                    <InputLabel id="display-label">Display</InputLabel>
                    <Select
                      labelId="display-label"
                      id="display"
                      value={newColumn.display}
                      label={"Display"}
                      onChange={(e) =>
                        setNewColumn({ ...newColumn, display: e.target.value })
                      }
                      fullWidth
                      style={{ height: "55px", width: "200px" }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    variant="contained"
                    color="info"
                    onClick={handleAddColumn}
                    style={{ marginLeft: "15px", height: "55px" }}
                  >
                    Add Column
                  </MDButton>
                </Grid>
              </Grid>
            </Card>

            <DataTable
              columns={header}
              data={columns}
              responsive
              pagination
              striped
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10]}
              theme={
                darkModeContext[0].darkMode ? "modifiedDarkTheme" : "default"
              }
              fixedHeader
              persistTableHead
              fixedHeaderScrollHeight={`${(window.innerHeight / 100) * 66}px`}
            />
          </Card>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton variant="contained" color="success" onClick={handleSave}>
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: position }}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
      >
        <MuiAlert variant="filled" severity={color}>
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CTLJsonTable;
