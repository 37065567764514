import MDTypography from "components/MDTypography";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

function CTLVoicemailUnReadCell({ value, suffix, row }) {
  const truncatedValue =
    value?.length > 20 ? value.slice(0, 20) + "..." : value;
  const unRead = row?.original?.readOn;
  const fontWeight = unRead === 0 || unRead === null ? "bold" : "small";


  return (
    <CustomWidthTooltip title={value}>
      <MDTypography
        variant="caption"
        fontWeight={fontWeight}

      // style={{ fontSize }}
      >
        {truncatedValue}
        {suffix && (
          <MDTypography
            variant="caption"
            fontWeight={fontWeight}

          // style={{ fontWeight }}
          >
            &nbsp;&nbsp;{suffix}
          </MDTypography>
        )}
      </MDTypography>
    </CustomWidthTooltip>
  );
}

CTLVoicemailUnReadCell.defaultProps = {
  suffix: "",
};

export default CTLVoicemailUnReadCell;
