import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const generalQueueAttribute = {
  formSize: "md",
  columns: 4,
  enableAddButton: true,
  enableAcdDetails: true,
  selectUrl: "/queue/list/acdList",
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const generalQueueOperationURLS = {
  queueCreate: "/queue/create",
  queueUpdate: "/queue/update",
  qlist: "/queue/",
  acdDetails: "/queue/list/acdList",
};

const generalQueueUpdateColumns = (onEdit) => {
  return [
    {
      Header: "Strategy",
      accessor: "strategy",
      displayInList: false,
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      width: 6,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          {
            id: "longest-ideal-agent",
            description: "Longest Ideal Agentault",
          },
          { id: "round-robin", description: "Round Robin" },
          { id: "top-down", description: "Top Down" },
          {
            id: "agent-with-least-talk-time",
            description: "Agent With Least Talk Time",
          },
          {
            id: "agent-with-fewest-calls",
            description: "Agent with Fewest Calls",
          },
          {
            id: "sequentially-by-agent-order",
            description: "Sequentially By Agent Order",
          },
          { id: "random", description: "Random" },
          { id: "ring-progressively", description: "Ring Progressively" },
        ],
      },
    },
    {
      Header: "System Name",
      accessor: "systemName",
      displayInList: false,
      allowInForm: true,
      controlType: "select",
      width: 6,
      disabled: true,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "1", description: "Acd Queue 1" },
          { id: "2", description: "Acd Queue 2" },
          { id: "3", description: "Acd Queue 3" },
          { id: "4", description: "Acd Queue 4" },
          { id: "5", description: "Acd Queue 5" },
          { id: "6", description: "Acd Queue 6" },
          { id: "7", description: "Acd Queue 7" },
          { id: "8", description: "Acd Queue 8" },
          { id: "9", description: "Acd Queue 9" },
          { id: "10", description: "Acd Queue 10" },
          { id: "11", description: "Acd Queue 11" },
          { id: "12", description: "Acd Queue 12" },
          { id: "13", description: "Acd Queue 13" },
          { id: "14", description: "Acd Queue 14" },
          { id: "15", description: "Acd Queue 15" },
          { id: "16", description: "Acd Queue 16" },
          { id: "17", description: "Acd Queue 17" },
          { id: "18", description: "Acd Queue 18" },
          { id: "19", description: "Acd Queue 19" },
          { id: "20", description: "Acd Queue 20" },
          { id: "21", description: "Acd Queue 21" },
          { id: "22", description: "Acd Queue 22" },
          { id: "23", description: "Acd Queue 23" },
          { id: "24", description: "Acd Queue 24" },
          { id: "25", description: "Acd Queue 25" },
          { id: "26", description: "Acd Queue 26" },
          { id: "27", description: "Acd Queue 27" },
          { id: "28", description: "Acd Queue 28" },
          { id: "29", description: "Acd Queue 29" },
          { id: "30", description: "Acd Queue 30" },
          { id: "31", description: "Acd Queue 31" },
          { id: "32", description: "Acd Queue 32" },
          { id: "33", description: "Acd Queue 33" },
          { id: "34", description: "Acd Queue 34" },
          { id: "35", description: "Acd Queue 35" },
          { id: "36", description: "Acd Queue 36" },
          { id: "37", description: "Acd Queue 37" },
          { id: "38", description: "Acd Queue 38" },
          { id: "39", description: "Acd Queue 39" },
          { id: "40", description: "Acd Queue 40" },
          { id: "41", description: "Acd Queue 41" },
          { id: "42", description: "Acd Queue 42" },
          { id: "43", description: "Acd Queue 43" },
          { id: "44", description: "Acd Queue 44" },
          { id: "45", description: "Acd Queue 45" },
          { id: "46", description: "Acd Queue 46" },
          { id: "47", description: "Acd Queue 47" },
          { id: "48", description: "Acd Queue 48" },
          { id: "49", description: "Acd Queue 49" },
          { id: "50", description: "Acd Queue 50" },
          { id: "51", description: "Acd Queue 51" },
          { id: "52", description: "Acd Queue 52" },
          { id: "53", description: "Acd Queue 53" },
          { id: "54", description: "Acd Queue 54" },
          { id: "55", description: "Acd Queue 55" },
          { id: "56", description: "Acd Queue 56" },
          { id: "57", description: "Acd Queue 57" },
          { id: "58", description: "Acd Queue 58" },
          { id: "59", description: "Acd Queue 59" },
          { id: "60", description: "Acd Queue 60" },
          { id: "61", description: "Acd Queue 61" },
          { id: "62", description: "Acd Queue 62" },
          { id: "63", description: "Acd Queue 63" },
          { id: "64", description: "Acd Queue 64" },
        ],
      },
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Shortcode",
      accessor: "shortCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DidNumber",
      accessor: "didNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DodNumber",
      accessor: "dodNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
    },

    {
      Header: "Non Businees Hours Message",
      accessor: "nbhMessage",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "No Agent Message",
      accessor: "noAgentMessage",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Queue Message",
      accessor: "queueMessage",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Back Message",
      accessor: "callBackMessage",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
    },
    {
      Header: "MOH Sound",
      accessor: "mohSound",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      controlType: "fileUpload",
      fileTypeAccept:".mp3, .wav",
      uploadUrl: "queue/upload",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Recording",
      accessor: "record",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
  ];
};

export {
  generalQueueUpdateColumns,
  generalQueueAttribute,
  generalQueueOperationURLS,
};
