import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Draggable } from "@hello-pangea/dnd";
import { useMaterialUIController } from "context";
import "./css/links.css";
import { useGetTopologyDataQuery, useGetDataQuery } from "features/apiSlice";
import topologyData from "../../data/topologyData";
import RenderTopology from "./RenderTopology";
import useCenteredTree from "./Helpers/useCenteredTree";
import ContextMenu from "../ContextMenu";

function Topology({
  widgetHeight,
  widget,
  cellWidgetInfo,
  index,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  containerType,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let getCategoryDataURL = null;
  let refreshInterval = 0;
  let threshold = null;
  if (widget) {
    getDataURL = widget.getDataURL;
    getCategoryDataURL = process.env.REACT_APP_CATEGORY_LIST_ENDPOINT;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
    threshold = widget.thresholdLower || widget.thresholdUpper || 50;
  }

  const topologyQuery = getDataURL
    ? useGetTopologyDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const categoryListQuery = getCategoryDataURL
    ? useGetDataQuery(getCategoryDataURL)
    : {};

  const [translate, containerRef] = useCenteredTree();

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  let primaryLabel = null;
  let secondaryLabel = null;
  let data = null;
  let categoryData = null;
  let error = null;
  if (widget) {
    if (topologyQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      data = topologyQuery.data;
      categoryData = categoryListQuery.data ? categoryListQuery.data : [];
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      data = [];
      categoryData = [];
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    data = topologyData;
    categoryData = [];
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
  }

  const topologyContainer = (provided = { dragHandleProps: {} }) => (
    <MDBox p={2} position="relative" height="90%" ref={containerRef}>
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
        {...provided.dragHandleProps}
      >
        <Icon fontSize="small">hub</Icon>
      </MDBox>
      <RenderTopology
        isEditMode={isEditMode}
        isAdmin={isAdmin}
        primaryLabel={primaryLabel}
        secondaryLabel={secondaryLabel}
        data={data}
        categoryData={categoryData}
        threshold={threshold}
        isDarkMode={isDarkMode}
        masterContainerId={masterContainerId}
        cellId={cellId}
        handleDeleteWidget={handleDeleteWidget}
        index={index}
        translate={translate}
        error={error}
      />
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
            height:
              data.length > 0
                ? parseInt(widgetHeight, 10) * 12
                : parseInt(widgetHeight, 10) * 4,
          }}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {topologyContainer(provided)}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
        height:
          data.length > 0
            ? parseInt(widgetHeight, 10) * 12
            : parseInt(widgetHeight, 10) * 4,
        cursor: "context-menu",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {topologyContainer()}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Topology;
