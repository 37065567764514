import TelephoneCell from "application/components/TelephoneCell";
import DefaultCell from "application/components/DefaultCell";
const Attributes = {
  enableAddButton: false,
  refetchInterval: 5000,
  enableAdvanceSearch: true,
  modes: [],
};

const getColumns = (onDelete, onCall) => {
  return [
    {
      Header: "S. No",
      accessor: "id",
      displayInList: true,
      dataType: "long",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Received On",
      accessor: "startStamp",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Number",
      accessor: "callerIdNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
    },
    {
      Header: "Name",
      accessor: "callerIdName",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dialed To",
      accessor: "destinationNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      displayInList: true,
      allowFilter: false,
      dataType: "long",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Hangup Cause",
      accessor: "hangupCause",
      displayInList: false,
      allowFilter: false,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes };
