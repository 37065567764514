import DefaultCell from "application/components/DefaultCell";

export default function (data) {
    const codeValues = [];

    function getCodeValues(data) {

        traverse(data);
        codeValues.sort((a, b) => a.description.localeCompare(b.description));

        return codeValues;
    }

    function traverse(data) {
        if (data?.children && data?.children.length > 0) {
            data.children.forEach((child) => {
                traverse(child);
            });
        }

        if (data?.properties && data?.properties.code) {
            codeValues.push({
                id: data.properties.code,
                description: data.properties.code,
            });
        }
    }

    return [
        {
            Header: "Description",
            accessor: "description",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "String",
            Cell: ({ value }) => <DefaultCell value={value} />,
            detailedExplanation: "Description",
            defaultValue: "",
            dataUnit: "",
            tabId: 0,
        },
        {
            Header: "Reference Code",
            accessor: "code",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            Cell: ({ value }) => <DefaultCell value={value} />,
            detailedExplanation:
                "Unique code defined by user to create link from some other step",
            defaultValue: "",
            dataUnit: "",
        },
        {
            Header: "Reference Code",
            accessor: "ltsReferenceCode",
            displayInList: true,
            allowFilter: true,
            allowInForm: true,
            displayInDeleteConfirmation: true,
            dataType: "String",
            Cell: ({ value }) => <DefaultCell value={value} />,
            detailedExplanation: "Loop to a Reference",
            defaultValue: "",
            dataUnit: "Code",
            tabId: 0,
            controlType: "select",
            options: {
                keyField: "id",
                keyDescription: "description",
                values: getCodeValues(data),
            },
        },
    ];
}