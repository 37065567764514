

import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import React from "react";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import MDButton from "components/MDButton";
import dialog from "assets/theme/components/dialog";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";

function Voicemail({
  onLoad,
  mode,
  pageId,
  searchText,
  registerCallBacks,
  onCall,
  pageName,
}) {

  const [controller, dispatch] = useMaterialUIController();
  const { activePage, darkMode } = controller;
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowsRef = useRef([]);
  const [openForm, setOpenForm] = useState(false);
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });


  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onMarkasRead: multipleUpdate,
        onMultipleDelete: multipleDelete,
      });
  }, [activePage]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setUpdateList(Date.now());
    setOpen(false);
  };


  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  function onCloseForm() {
    setOpenForm(false);
  }


  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const onSelectionChange = (rowId) => {
    setSelectedRows(rowId);
  };

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);


  const onDelete = (row) => {
    selectedRowsRef.current = [row.id];
    Delete();
  };
  
  const onAgree = () => {
    Delete();
    setOpen(false);
  };

  
  const Update = () => {
    const requestUpdateId = {
      stringId: selectedRowsRef.current,
    };
    application
      .post(operationURLS.update, requestUpdateId)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
        setSelectedRows([]);
        selectedRowsRef.current = [];
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.data.status,
        });
      });
  };

  const Delete = () => {
    const requestDeleteId = {
      stringId: selectedRowsRef.current,
    };
    application
      .post(operationURLS.delete, requestDeleteId)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "VoiceMail",
          status: response.data.status,
        });
        setSelectedRows([]);
        selectedRowsRef.current = [];
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: "VoiceMail",
          status: error.response.data.status,
        });
      });
  };

  const multipleUpdate = () => {
    if (
      selectedRowsRef.current != null &&
      selectedRowsRef.current.length !== 0
    ) {
      Update();
    } else {
      setNotify({
        isOpen: true,
        message: " Error",
        type: "error",
        pagename: pageName,
        status: "Select at least one VoiceMail to update read ",
      });
    }
  };


  const multipleDelete = () => {
    if (
      selectedRowsRef.current != null &&
      selectedRowsRef.current.length !== 0
    ) {
      handleClickOpen();
    } else {
      setNotify({
        isOpen: true,
        message: "Error  ",
        type: "error",
        pagename: pageName,
        status: "Select at least one VoiceMail to delete ",
      });
    }
  };

  const markAsRead = (row) => {
    selectedRowsRef.current = [row.id];
    if (row.readOn == 0 || row.readOn == null) {
      Update();
    } else {
      setNotify({
        isOpen: true,
        message: "Error  ",
        type: "error",
        pagename: pageName,
        status: "Already read ",
      });
    }
  };

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="Voicemail"
        data={selectedRows}
        columns={getColumns(markAsRead, onDelete)}
      />
      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(markAsRead, onDelete, onCall, editRow)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="createdOn"
        pageId={pageId}
        searchText={searchText}
        onSelectionChange={onSelectionChange}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
      />

      <CTLAdvancedFilter
        entityName="Voicemail"
        columns={getColumns(markAsRead, onDelete)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />

      <Dialog open={open} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Are you sure you want to delete the selected Voicemail
        </DialogTitle>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClickClose} color="secondary">
            Disagree
          </MDButton>
          <MDButton onClick={onAgree} color="error" autoFocus>
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>

      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default Voicemail;