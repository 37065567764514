import DefaultCell from "application/components/DefaultCell";


const Attributes = {
  enableAddButton: false,
  enableSendButton: true,
  refetchInterval:5000,
  modes: [
    
  ],
};

const operationURLS = {
  messageSend: "/broadcastMessageDetail/create",
};



const getColumns = () =>{ 
return[
  {
    Header: " S.No",
    accessor: "id",
    displayInList: true,
    Cell: ({ row, value }) => <DefaultCell row={row} value={value} />,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    displayInList: true,
    allowFilter:false,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    displayInList: true,
    allowFilter:false,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
 
  {
    Header: "Designation",
    accessor: "designation",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Ext. No.",
    accessor: "extension",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  
  {
    Header: "Off. No.",
    accessor: "phoneOffice",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },

  {
    Header: "Home No.",
    accessor: "phoneHome",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Mobile",
    accessor: "mobileNumber",
    displayInList: true,
    allowFilter:true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
 
];
}


export {getColumns, Attributes , operationURLS  };
