import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function CampaignGatewayCell({ value }) {
  const [listOptions, setListOptions] = useState([]);
  useEffect(() => {
    application.post("gateway/listAll").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);
  const matchedRole = listOptions?.find((option) => option.id === value);
  return (
    <>
      {matchedRole && (
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {matchedRole?.gwCode}
        </MDTypography>
      )}
    </>
  );
}

export default CampaignGatewayCell;
