import React from "react";
import MDTypography from "components/MDTypography";

export default function CTLDate({ value }) {
  let d = new Date(0);
  
  if(value?.toString().length===10){
    d.setUTCSeconds(value);
  }else{
    d.setUTCSeconds(value/1000);
  }
 
  let n = new Date()
  n.setTime(d.valueOf() - 60000 * d.getTimezoneOffset());

  if(value === null){
    return null;
  }
  if(value === 0){
    return null;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {n.toUTCString().replace("GMT", "")}
    </MDTypography>
  );
}

CTLDate.defaultProps = {
  suffix: "",
};
