import React, { useEffect, useState } from "react";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import PreviewIcon from "@mui/icons-material/Preview";
import { application } from "globals/endpoints";
import { useMaterialUIController, setQueueValue } from "context";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";
import dialog from "assets/theme/components/dialog";
import { makeStyles } from "@material-ui/core/styles";
import CTLDialogContent from "../styles/CTLDialogContent";
import CTLNotification from "application/components/CTLNotification";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const operationURLSS = {
  qlist: "/queue",
  qdelete: "/queue/delete",
  list: "/queue/list/acdList",
};

export default function CTLQueue({ row, value, onQueue, onDelete }) {
  const ID = row.original.id;
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();

  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  function onClose() {
    setOpen(false);
  }

  const Storeid = (id) => {
    sessionStorage.setItem("id", JSON.stringify(id));
    setId(id);
  };

  const QueueDetail = () => {
    if (id) {
      application
        .post(`${operationURLSS.qlist}/${id}`)
        .then((response) => {
          const result = response.data.data;
          setQueueValue(dispatch, result);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    }
  };

  const getlist = () => {
    application
      .post(operationURLSS.list)
      .then((res) => {
        const updatedData = res.data.data;
        setData(updatedData);
      })
      .catch((error) => {
        console.error("Error while fetching list data: ", error);
      });
  };

  const handleDelete = (ID) => {
    application
      .post(`${operationURLSS.qdelete}/${ID}`)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Call-Center",
        });
        getlist();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Call-Center",
        });
      });
  };

  const handleConfirmDelete = (idToDelete) => {
    handleDelete(idToDelete);
    onClose();
  };

  useEffect(() => {
    QueueDetail();
  }, [id, data]);

  return (
    <>
      {value ? (
        <>
          <IconButton
            color="error"
            onClick={() => {
              Storeid(value);
            }}
          >
            <PreviewIcon />
          </IconButton>
        </>
      ) : (
        <IconButton color="error" onClick={() => onQueue(row.original)}>
          <FavoriteBorderIcon />
        </IconButton>
      )}

      <IconButton color="error" onClick={() => setOpen(true)}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={open} maxWidth="md" classes={{ dialog }}>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Are you sure you want to delete the selected ACD
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={onClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton
            onClick={() => handleConfirmDelete(row.original.id)}
            color="error"
            autoFocus
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
