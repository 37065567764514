import GroupMasterCell from "application/components/userGroupMasterCell";
import UserMasterCell from "application/components/userGroupMastercell2";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: [],
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  settingsMode: false,
};

const operationURLS = {
  list: "userGroup/list",
  update: "userGroup/update",
  create: "userGroup/create",
  delete: "userGroup/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "User ID",
      accessor: "users",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <UserMasterCell value={value} />,
      width: 6,
      options: {
        keyField: "id",
        keyDescription: "username",
        values: [],
        url: "/user/dropdownList",
      },
    },
    {
      Header: "Group",
      accessor: "group",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "multipleSelect",
      Cell: ({ value }) => <GroupMasterCell value={value} />,
      width: 6,
      options: {
        keyField: "id",
        keyDescription: "code",
        values: [],
        url: "/userGroupMaster/listAll",
      },
    },
  ];
};

export { getColumns, Attributes, operationURLS };
