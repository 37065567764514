import CTLACDCell from "application/components/CTLACDCell";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { useState } from "react";
import formControl from "globals/form-control";

const Attributes = {
  defaultValueToolTip: false,
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableAddAgentButton: true,
  enableAcdDetails: true,
  selectUrl: "/queue/list/acdList",
  modes: [],
  settingsMode: false,
  messages: {
    success: "created successfully",
    error: "Unable to create"
}
};

const operationURLS = {
  queueCreate: "/queue/create",
};

const getColumns = (onEdit) => {

  const [requireddidNumber, setRequireddidNumber] = useState("");
  const [requiredodNumber, setRequireddodNumber] = useState("");
  const checkselect = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "0") {
      setRequireddidNumber(formControl.string().required("Required"));
      setRequireddodNumber(""); 
    } else if (selectedValue === "1") {
      setRequireddidNumber("");
      setRequireddodNumber(formControl.string().required("Required"));
    } 
    else if (selectedValue === "2") {
      setRequireddidNumber(formControl.string().required("Required"));
      setRequireddodNumber(formControl.string().required("Required"));
    } else {
      setRequireddidNumber("");
      setRequireddodNumber("");
    }
  };


  return [
    {
      Header: "ACD Type",
      accessor: "acdType",
      displayInList: false,
      allowFilter: false,
      allowInForm: true,
      width:6,
      Cell: ({ value }) => <CTLACDCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "0", description: "InBound" },
          { id: "1", description: "OutBound" },
          { id: "2", description: "Blanded" },
        ],
      },
      validation: formControl.string().required("Required"),
      onChange: checkselect,
    },
    {
      Header: "ACD Number",
      accessor: "acdNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    // {
    //   Header: "Shortcode",
    //   accessor: "shortCode",
    //   displayInList: true,
    //   allowInForm: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   width:6,
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    //   validation: formControl.string().required("Required"),
    // },
    {
      Header: "DidNumber",
      accessor: "didNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      validation: requireddidNumber,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DodNumber",
      accessor: "dodNumber",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      validation: requiredodNumber,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width:6,
    },
    // {
    //   Header: "CreateAgent",
    //   accessor: "CREATE",
    //   displayInList: false,
    //   allowInForm: true,
    //   allowFilter: true,
    //   dataType: "string",
    //  controlType:"boolean",
    //   Cell: ({ value }) => <CTLBooleanCell value={value} />,
    // },
    {
      Header: "Agent From",
      accessor: "from",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    {
      Header: "Agent To",
      accessor: "to",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
    },
    // {
    //   Header: "MOH Sound",
    //   accessor: "mohSound",
    //   displayInList: false,
    //   allowInForm: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   width:6,
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    // },
    //{
      // Header: "Recording",
      // accessor: "record",
      // displayInList: false,
      // allowInForm: true,
      // allowFilter: false,
      // dataType: "string",
      // controlType:"boolean",
      // width:6,
      // Cell: ({ value }) => <CTLBooleanCell value={value} />,
    // },
    {
      Header: "action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          // onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
