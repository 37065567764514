import React, { useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import FormField from "layouts/applications/wizard/components/FormField";
import EditRoadIcon from "@mui/icons-material/EditRoad";

const operationURLS = {
  update: "/tiers/update/position",
};

export default function DataSequence({ row, onChange }) {
  let position = row.original.position;
  let Id = row.original.id;
  let Queue = row.original.queue
  const [controller] = useMaterialUIController();
  const { darkMode ,ivrExtension} = controller;
  const [updatedPosition, setUpdatedPosition] = useState(position);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });



  function getSequencing() {
    const requestBody = {
      id: Id,
      position: updatedPosition,
      queue:Queue
    };
    application
      .post(operationURLS.update, requestBody)
      .then((response) => {
        setNotify({
          isOpen: true,
          status: response.data.messageDetail,
          type: "success",
          pagename: "Call Center:Agent",
        });
        onChange();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.message,
          type: "error",
          pagename: "Call Center : Agnet",
        });
      });
  }

  const handleChange = (event) => {
    const newPosition = event?.target?.value;
    if (/^\d*$/.test(newPosition)) {
      setUpdatedPosition(newPosition);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      getSequencing();
    }
  };

  return (
    <>
      <Stack direction="row">
        <FormField
          style={{ width: "50px", marginRight: "1px" }}
          value={updatedPosition}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          color="success"
        />
        <tooltip title="update position">
          <IconButton color="success" onClick={() => getSequencing()}>
            <EditRoadIcon />
          </IconButton>
        </tooltip>
      </Stack>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
