const surakshaMenu = [
  {
    icon: "map",
    key: "nms.maps",
    name: "Suraksha",
    url: "home/app/nms/map",
    description: "MAP",
    disabled: false,
    productionReady : true
  },
];

export { surakshaMenu };
