import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { application } from "globals/endpoints";
import React, { useEffect, useState } from "react";
import CTLNotification from "../CTLNotification";
import CTLDialogContent from "../styles/CTLDialogContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValue = {
  id: 0,
  roomName: "",
  didMapping: "",
  meetingScheduledDate: "",
  scheduledFromTime: "",
  scheduledToTime: "",
  detail:"",
  participantList: [],
  conferenceType: "",
  maxParticipants: 0,
  beepInterval: 0,
  retryCount: 0,
  retryDelay: 0,
  rptt: false,
  voiceRecognition: false,
  passwordRequired: false,
  nameAnnouncementRequired: false,
  recording: false,
  createdBy: "",
  createdOn: "",
  meetingType: "",
};
function CTLConferenceForm({
  open,
  setOpen,
  operationURLS,
  selectedStartDate,
  selectedEndDate,
  createConference,
  apiFormData,
  setApiFormData,
  schedule,
  setSchedule,
}) {
  const [conferenceNumberList, setConferenceNumberList] = useState();
  const [maxWidth, setMaxWidth] = useState("lg");
  const [isShow, setIsShow] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [conferenceSettingData, setConferenceSettingData] = useState();
  const [formData, setFormData] = useState(initialValue);
  const [guest, setGuest] = useState();
  const [alignment, setAlignment] = React.useState("Individual");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await application.post(operationURLS.list);
        let dataToDisplay = response.data?.data[0];
        setConferenceSettingData(dataToDisplay);
      } catch (error) {
        console.error("Failed to load calendar settings data-", error);
      }
      try {
        const response = await application.post(
          operationURLS.conferenceNumberList
        );
        setConferenceNumberList(response?.data?.data);
      } catch (error) {
        console.error(
          "Failed to load conferenceNumberList dropdown list-",
          error
        );
      }
      try {
        const response = await application.post(operationURLS.groupList);
        setGuest([response.data.data]);
      } catch (error) {
        console.error("Failed to load Guest dropdown list-", error);
      }
    };

    fetchData();

    if (apiFormData) {
      if (schedule) {
        setFormData(() => ({
          modId: apiFormData.modId,
          meetingScheduledDate: apiFormData.meetingScheduledDate,
          scheduledFromTime: apiFormData.scheduledFromTime,
          scheduledToTime: apiFormData.scheduledToTime,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          id: apiFormData.id,
          roomName: apiFormData.roomName,
          detail: apiFormData.detail,
          didMapping: apiFormData.didMapping,
          meetingScheduledDate: apiFormData.meetingScheduledDate,
          scheduledFromTime: apiFormData.scheduledFromTime,
          scheduledToTime: apiFormData.scheduledToTime,
          participantList: apiFormData.participantList,
          conferenceType: apiFormData.conferenceType,
          beepInterval: apiFormData.beepInterval,
          maxParticipants: apiFormData.maxParticipants,
          retryCount: apiFormData.retryCount,
          retryDelay: apiFormData.retryDelay,
          rptt: apiFormData.rptt,
          voiceRecognition: apiFormData.voiceRecognition,
          passwordRequired: apiFormData.passwordRequired,
          nameAnnouncementRequired: apiFormData.nameAnnouncementRequired,
          recording: apiFormData.recording,
          meetingType: apiFormData.meetingType,
          createdBy: apiFormData.createdBy,
          createdOn: apiFormData.createdOn,
          coralConfGreetingsPath: apiFormData.coralConfGreetingsPath,
          participantName: apiFormData.participantName,
          emailId: apiFormData.emailId,
          mobileNumber: apiFormData.mobileNumber,
          extensions: apiFormData.mobileNumber,
          accessCode: apiFormData.accessCode,
          status: apiFormData.status,
          modId: apiFormData.modId,
          pid: apiFormData.pid,
        }));
      }
    } else if (conferenceSettingData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: 0,
        conferenceType: conferenceSettingData.conferenceType,
        beepInterval: conferenceSettingData.beepInterval,
        maxParticipants: conferenceSettingData.maxParticipants,
        retryCount: conferenceSettingData.retryCount,
        retryDelay: conferenceSettingData.retryDelay,
        rptt: conferenceSettingData.rptt,
        voiceRecognition: conferenceSettingData.voiceRecognition,
        passwordRequired: conferenceSettingData.passwordRequired,
        nameAnnouncementRequired:
          conferenceSettingData.nameAnnouncementRequired,
        recording: conferenceSettingData.recording,
        meetingType: conferenceSettingData.meetingType,
        createdBy: conferenceSettingData.createdBy,
        createdOn: conferenceSettingData.createdOn,
      }));
    }
  }, [selectedStartDate, apiFormData]);

  useEffect(() => {
    if (selectedStartDate && selectedStartDate !== "") {
      var originalStartDate = new Date(selectedStartDate);
      var toTime;

      if (selectedEndDate && selectedEndDate !== null) {
        var originalEndDate = new Date(selectedEndDate);
        toTime = originalEndDate;
      } else {
        toTime = new Date(originalStartDate.getTime() + 30 * 60000);
      }
      var formattedDate = originalStartDate.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      var formattedFromTime = originalStartDate.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });
      var formattedToTime = toTime.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        meetingScheduledDate: formattedDate,
        scheduledFromTime: formattedFromTime,
        scheduledToTime: formattedToTime,
      }));
    }
  }, [selectedStartDate, selectedEndDate]);

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]:
        value !== undefined && value !== null ? value : prevFormData[fieldName],
    }));
  };

  const handleMore = () => {
    setMaxWidth("xl");
    setIsShow(true);
  };

  const handleCloseMore = () => {
    setMaxWidth("lg");
    setIsShow(false);
  };

  const handleClose = () => {
    setIsShow(false);
    setOpen(false);
    setApiFormData();
    setFormData(initialValue);
    setSchedule(false);
  };

  const handleSave = () => {
    if (schedule) {
      const scheduledFromTime = new Date(
        `2000-01-01T${formData.scheduledFromTime}`
      );
      const scheduledToTime = new Date(
        `2000-01-01T${formData.scheduledToTime}`
      );

      if (scheduledToTime <= scheduledFromTime) {
        setNotify({
          isOpen: true,
          message: "",
          type: "warning",
          pagename: "Conference",
          status:
            "Conference To Time must be greater than Conference From Time",
        });
      } else {
        createConference(formData);
        setOpen(false);
        setSchedule(false);
        setApiFormData();
        setFormData(initialValue);
      }
    } else {
      if (formData.roomName === "") {
        setNotify({
          isOpen: true,
          message: "",
          type: "warning",
          pagename: "Conference",
          status: "Please Input Conference Name",
        });
      } else if (formData.didMapping === "") {
        setNotify({
          isOpen: true,
          message: "",
          type: "warning",
          pagename: "Conference",
          status: "Please Select Conference Number",
        });
      } else if (formData.participantList.length === 0) {
        setNotify({
          isOpen: true,
          message: "",
          type: "warning",
          pagename: "Conference",
          status: "Please select at least one participant",
        });
      } else {
        const scheduledFromTime = new Date(
          `2000-01-01T${formData.scheduledFromTime}`
        );
        const scheduledToTime = new Date(
          `2000-01-01T${formData.scheduledToTime}`
        );

        if (scheduledToTime <= scheduledFromTime) {
          setNotify({
            isOpen: true,
            message: "",
            type: "warning",
            pagename: "Conference",
            status:
              "Conference To Time must be greater than Conference From Time",
          });
        } else {
          createConference(formData);
          setOpen(false);
          setSchedule(false);
          setApiFormData();
          setFormData(initialValue);
        }
      }
    }
  };

  const handleChangeMode = (newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleUserCheckboxChange = (userId, isChecked, groupId) => {
    let participantLists = { ...formData.participantList };

    if (!participantLists[groupId]) {
      participantLists[groupId] = [];
    }

    let participantList = [...participantLists[groupId]];

    if (isChecked && !participantList.includes(userId)) {
      participantList.push(userId);
    } else if (!isChecked) {
      participantList = participantList.filter((id) => id !== userId);
    }

    participantLists[groupId] = participantList;

    handleChange("participantLists", participantLists);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };


  const scheduledToTime = new Date(`2000-01-01T${formData.scheduledToTime}`);
  const scheduledFromTime = new Date(`2000-01-01T${formData.scheduledFromTime}`);
  const timeDifferenceMs = scheduledToTime - scheduledFromTime;
  let timeDifferenceMinutes = Math.abs(timeDifferenceMs / (1000 * 60));
  const hours = Math.floor(timeDifferenceMinutes / 60);
  const minutes = timeDifferenceMinutes % 60;
  let timeDifferenceStr = "";
  if (hours > 0) {
    timeDifferenceStr += `${hours} h`;
  }
  if (minutes > 0) {
    timeDifferenceStr += ` ${minutes} m`;
  }

  return (
    <>
      <Dialog open={open} maxWidth={maxWidth} fullWidth onClose={handleClose}>
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {schedule
            ? `Re-Schedule Conference - ${apiFormData.roomName}`
            : "Create New Meeting"}
          &nbsp;&nbsp;
          {!schedule && (
            <ToggleButtonGroup
              color={alignment == "Individual" ? "info" : "error"}
            >
              <MDButton
                variant={alignment == "Individual" ? "contained" : "outlined"}
                color="info"
                onClick={() => handleChangeMode("Individual")}
              >
                Individual Mode
              </MDButton>
              <MDButton
                variant={alignment == "connected" ? "contained" : "outlined"}
                color="info"
                onClick={() => handleChangeMode("connected")}
              >
                Connected Mode
              </MDButton>
            </ToggleButtonGroup>
          )}
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <Grid sx={{ p: 1 }} container spacing={2} direction="row">
            {schedule ? (
              <>
                {" "}
                <Grid item xs={6}>
                  <TextField
                    label="Meeting Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    fullWidth
                    value={formData.meetingScheduledDate}
                    onChange={(event) =>
                      handleChange("meetingScheduledDate", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentDate(),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="From Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    value={formData.scheduledFromTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("scheduledFromTime", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="To Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    value={formData.scheduledToTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("scheduledToTime", event.target.value)
                    }
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Conference Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formData.roomName}
                    onChange={(event) =>
                      handleChange("roomName", event.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Add participants</InputLabel>
                    <Select
                      label="Add participants"
                      multiple
                      value={formData.participantList || []}
                      onChange={(event) =>
                        handleChange("participantList", event.target.value)
                      }
                      style={{ height: "55px" }}
                      fullWidth
                      renderValue={(selected) => {
                        const uniqueSelected = [...new Set(selected)];
                        const selectedUsernames = [];
                        uniqueSelected.forEach((id) => {
                          const group = guest[0].group.find((group) =>
                            group.userList.some((user) => user.id === id)
                          );
                          if (group) {
                            const selectedUser = group.userList.find(
                              (user) => user.id === id
                            );
                            if (selectedUser) {
                              selectedUsernames.push(selectedUser.username);
                            }
                          } else {
                            const user = guest[0].userList.find(
                              (user) => user.id === id
                            );
                            if (user) {
                              selectedUsernames.push(user.username);
                            }
                          }
                        });
                        return selectedUsernames.join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled>
                        <ListItemAvatar>
                          <Avatar>
                            <GroupsIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <Typography>
                          <ListItemText primary={"Group List"} />
                        </Typography>
                      </MenuItem>
                      {guest &&
                        guest[0].group.map((group, index) => [
                          <MenuItem key={group.groupName}>
                            <Typography>
                              <ListItemText
                                primary={group.groupName.toUpperCase()}
                              />
                            </Typography>
                          </MenuItem>,
                          ...group.userList.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              <Checkbox
                                checked={formData?.participantList?.includes(
                                  user.id
                                )}
                                onChange={(e) =>
                                  handleUserCheckboxChange(
                                    user.id,
                                    e.target.checked,
                                    group.groupName
                                  )
                                }
                              />
                              <ListItemText primary={user.username} />
                            </MenuItem>
                          )),
                        ])}
                      <MenuItem disabled>
                        <ListItemAvatar>
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <Typography>
                          <ListItemText primary={"User List"} />
                        </Typography>
                      </MenuItem>
                      {guest &&
                        guest[0]?.userList.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            <Checkbox
                              checked={formData?.participantList?.includes(
                                user.id
                              )}
                              onChange={(e) =>
                                handleUserCheckboxChange(
                                  user.id,
                                  e.target.checked,
                                  null
                                )
                              }
                            />
                            <ListItemText primary={user.username} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    label="Meeting Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    fullWidth
                    variant="standard"
                    value={formData.meetingScheduledDate}
                    onChange={(event) =>
                      handleChange("meetingScheduledDate", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentDate(),
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    label="From Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    variant="standard"
                    value={formData.scheduledFromTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("scheduledFromTime", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentTime(),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="To Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="time"
                    variant="standard"
                    value={formData.scheduledToTime}
                    fullWidth
                    onChange={(event) =>
                      handleChange("scheduledToTime", event.target.value)
                    }
                    inputProps={{
                      min: getCurrentTime(),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="  "
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={timeDifferenceStr}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel>Add Location *</InputLabel>
                    <Select
                      style={{ height: "55px" }}
                      label="Add Location*"
                      value={formData.didMapping}
                      onChange={(event) =>
                        handleChange("didMapping", event.target.value)
                      }
                      fullWidth
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {conferenceNumberList &&
                        conferenceNumberList.map((item, index) => (
                          <MenuItem key={index} value={item.extension}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Details"
                    fullWidth
                    type="text"
                    placeholder="Type details for this new meeting"
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    maxRows={4}
                    minRows={4}
                    value={formData.detail}
                    onChange={(event) =>
                      handleChange("detail", event.target.value)
                    }
                  />
                </Grid>
                {isShow ? (
                  <>
                    <Grid item xs={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Conference Mode
                        </InputLabel>
                        <Select
                          label="Conference Mode"
                          value={formData && formData.conferenceType}
                          onChange={(event) =>
                            handleChange("conferenceType", event.target.value)
                          }
                          fullWidth
                          style={{ height: "55px" }}
                        >
                          {" "}
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="a">audio</MenuItem>
                          <MenuItem value="v">video</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Conference Type
                        </InputLabel>
                        <Select
                          label="Conference Type"
                          value={formData && formData.meetingType}
                          onChange={(event) =>
                            handleChange("meetingType", event.target.value)
                          }
                          fullWidth
                          style={{ height: "55px" }}
                        >
                          {" "}
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="Dial Assisted">
                            Dialer Assisted
                          </MenuItem>
                          <MenuItem value="Meet Me Conference">
                            Meet Me Conference
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {[
                      {
                        label: "Maximum Participant Allowed",
                        fieldName: "maxParticipants",
                      },
                      { label: "Beep Interval", fieldName: "beepInterval" },
                      { label: "Retry In Number", fieldName: "retryCount" },
                      { label: "Retry After MS", fieldName: "retryDelay" },
                      {
                        label: "Required Permission To Talk",
                        fieldName: "rptt",
                        type: "switch",
                      },
                      {
                        label: "Voice Recognition",
                        fieldName: "voiceRecognition",
                        type: "switch",
                      },
                      {
                        label: "Password Required",
                        fieldName: "passwordRequired",
                        type: "switch",
                      },
                      {
                        label: "Name Announcement Required",
                        fieldName: "nameAnnouncementRequired",
                        type: "switch",
                      },
                      {
                        label: "Recording",
                        fieldName: "recording",
                        type: "switch",
                      },
                    ].map(
                      (field, index) =>
                        field && (
                          <Grid
                            key={index}
                            item
                            xs={field.type === "switch" ? 4 : 4}
                          >
                            {field.type === "switch" ? (
                              <>
                                <MDTypography variant="caption">
                                  {field.label}
                                </MDTypography>
                                <Switch
                                  checked={
                                    formData && formData[field.fieldName]
                                  }
                                  onChange={(event) =>
                                    handleChange(
                                      field.fieldName,
                                      event.target.checked
                                    )
                                  }
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      bgcolor:
                                        formData && formData[field.fieldName]
                                          ? "#4CAF50"
                                          : "#F44335",
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              <TextField
                                label={field.label}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type={field.type || "text"}
                                value={formData[field.fieldName]}
                                fullWidth
                                onChange={(event) =>
                                  handleChange(
                                    field.fieldName,
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          </Grid>
                        )
                    )}
                  </>
                ) : null}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {!schedule && (
            <MDButton
              color={isShow ? "secondary" : "info"}
              onClick={isShow ? handleCloseMore : handleMore}
            >
              {isShow ? "Close options" : "More options"}
            </MDButton>
          )}
          <MDButton color="error" onClick={handleClose}>
            Close
          </MDButton>
          <MDButton color="success" onClick={handleSave}>
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default CTLConferenceForm;
