import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import styles from "../css/Topology.module.css";

function Zoom({ handleZoomIncrease, handleZoomDecrease }) {
  return (
    <MDBox
      display="flex"
      position="absolute"
      right="50%"
      bottom="-2em"
      zIndex="2"
    >
      <button
        type="button"
        onClick={handleZoomIncrease}
        className={`${styles.btn} ${styles.add}`}
      >
        <Icon fontSize="small">add</Icon>
      </button>
      <button
        type="button"
        onClick={handleZoomDecrease}
        className={`${styles.btn} ${styles.minus}`}
      >
        <Icon fontSize="small">remove</Icon>
      </button>
    </MDBox>
  );
}

export default Zoom;
