import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "telephonyDeployment/list",
  update: "telephonyDeployment/update",
  create: "telephonyDeployment/create",
  delete: "telephonyDeployment/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Node IP",
      accessor: "nodeIp",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().ipv4(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Priority",
      accessor: "priority",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "RLU Code",
      accessor: "rluCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Server Code",
      accessor: "serverCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Virtual IP",
      accessor: "virtualIp",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().ipv4(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
