const cdrs = [
  {
    icon: "graphic_eq_icon",
    key: "cdrs",
    name: "CDRS",
    url: "home/app/configurations/cdrs",
    description: "CDRS",
    disabled: false,
    productionReady: false,
  },
  {
    icon: "group-icon ",
    key: "cdrsGroup",
    name: "CDRS-Group",
    url: "home/app/cdrs/group",
    description: "CDRS-Group",
    disabled: false,
    productionReady: false,
  },
];

export { cdrs };
