import React, { useEffect } from "react";
import Chat from ".";
import MDButton from "components/MDButton";
import { Card, Divider, Drawer } from "@mui/material";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  drawerPaper: {
    width: "430px !important",
    margin: "0 !important",
    borderRadius: "unset !important",
    height: "100vh !important",
  },
});

export default function ChatWindow({ openChat, onCloseChat }) {
  const [state, setState] = React.useState({
    left: false,
  });

  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onCloseChat();
    setState({ ...state, [anchor]: open });
  };

  const handleClose = () => {
    setState({ ...state, left: false });
    onCloseChat();
  };

  useEffect(() => {
    if (openChat) {
      setState({ ...state, left: true });
    } else {
      setState({ ...state, left: false });
    }
  }, [openChat]);

  return (
    <Card>
      {["left"].map((anchor) => (
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <Card>
            <MDButton
              variant
              style={{
                textAlign: "center",
                color: "info",
              }}
            >
              <Avatar src="https://material-ui.com/static/images/avatar/1.jpg" />{" "}
            </MDButton>
            <Divider />
            <Chat />
          </Card>
        </Drawer>
      ))}
    </Card>
  );
}
