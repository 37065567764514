import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Legend from "../Legend";
import Dropdown from "../../../Dropdown";
import { GRAPH_TOPOLOGY_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import SelectCategoryFilter from "../SelectCategoryFilter";
import AssetFilter from "../AssetFilter";
import DisplayOptionsLegend from "../DisplayOptionsLegend";
import CytoscapeComponent from "react-cytoscapejs";
import { v4 as uuidv4 } from "uuid";
import ASSETS_CATEGORIES from "../../../configs/assetsConfigs";
import GraphLayoutFilter from "../GraphLayoutFilter";
import { useEffect, useMemo, useRef, useState, createElement } from "react";
import cytoscapeDataTransform from "../../../utils/cytoscapeDataTransform";
import DatasetFilter from "../DatasetFilter";
import ShowLegendsSwitch from "../ShowLegendsSwitch";
import ContextMenu from "../ContextMenu";
import PopupTopology from "../PopupTopology";
import PopupLocationAssets from "../PopupLocationAssets";
import getNMSComponent from "globals/nms-components";
import variables from "globals/variables";
import { useMaterialUIController } from "context";

function RenderGraphTopology({
  isEditMode,
  isAdmin,
  primaryLabel,
  secondaryLabel,
  data,
  descendantsData,
  categoryData,
  severityData,
  isDarkMode,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  index,
  error,
  colorFilter,
  handleColorFilterChange,
  asset,
  handleSearchAsset,
  assetCategory,
  handleAssetCategoryChange,
  displayOptions,
  handleDisplayOptionsChange,
  layout,
  handleLayoutChange,
  topologyQuery,
  handleIsNodeRightClicked,
}) {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [datasetType, setDatasetType] = useState("location-graph");
  const [transformedData, setTransformedData] = useState(null);
  const [showLegends, setShowLegends] = useState(false);
  const [nodeData, setNodeData] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [popupTopologyOpen, setPopupTopologyOpen] = useState(false);
  const [popupLocationAssetsOpen, setPopupLocationAssetsOpen] = useState(false);
  const [smartAssetPopupOpen, setSmartAssetPopupOpen] = useState(false);

  const containerRef = useRef(null);

  const { darkMode } = useMaterialUIController()[0];

  const handleContextMenu = (event) => {
    if (!popupTopologyOpen) {
      handleIsNodeRightClicked(true);
      setNodeData(event.target._private.data);
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.originalEvent.clientX + 2,
              mouseY: event.originalEvent.clientY - 6,
            }
          : null,
      );
    }
  };
  const handleClose = () => {
    setContextMenu(null);
    handleIsNodeRightClicked(false);
  };

  const handlePopupTopologyOpen = () => {
    setPopupTopologyOpen(true);
    handleClose();
  };
  const handlePopupTopologyClose = () => setPopupTopologyOpen(false);

  const handleSmartAssetPopupOpen = () => {
    setSmartAssetPopupOpen(true);
    handleClose();
  };
  const handleSmartAssetPopupClose = () => setSmartAssetPopupOpen(false);

  const handleManagementInterface = (managementURL, nodeDatum) => {
    if (managementURL === null) {
      alert("No Management Interface URL found for this asset.");
      handleClose();
      handleEnableGraphTopoDrag();
      return;
    }

    const baseURL = variables.api.telemetry;
    const dollar = "$";
    let URL = null;
    URL = managementURL.replace(`${dollar}{{baseURL}}`, baseURL);
    URL = URL.replace("{id}", nodeDatum.id);
    URL = URL.replace("{{ipAddress}}", nodeDatum.ipAddress);
    URL = URL.replace("{{ipAddres}}", nodeDatum.ipAddress);

    window.open(URL, "_blank");
    handleClose();
    handleEnableGraphTopoDrag();
  };

  const handleDashboard = () => handleSmartAssetPopupOpen();

  let componentRender = null;
  let contextMenuData = null;
  if (nodeData) {
    componentRender = getNMSComponent(nodeData.category);
    contextMenuData = categoryData.filter(
      (menuData) => menuData.code === nodeData.category,
    )[0];
  }

  const handlePopupLocationAssetsOpen = (event) => {
    setNodeData(event.target._private.data);
    setPopupLocationAssetsOpen(true);
  };

  const handlePopupLocationAssetsClose = () =>
    setPopupLocationAssetsOpen(false);

  const handleShowLegends = () => setShowLegends((prev) => !prev);

  const handleDatasetTypeChange = (event) => setDatasetType(event.target.value);

  useEffect(() => {
    const newTransformedData = cytoscapeDataTransform(data, datasetType);
    setTransformedData(newTransformedData);
  }, [
    datasetType,
    layout,
    assetCategory,
    asset,
    displayOptions,
    colorFilter,
    topologyQuery,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, layout]);

  const styleSheet = [
    {
      selector: "node",
      style: {
        backgroundColor: (node) => {
          const targetNodeSeverity =
            descendantsData[node._private.data.id].severity || 0;
          const severityObj = severityData.filter(
            (severity) => severity.severityLevel === targetNodeSeverity,
          )[0];

          if (!severityObj)
            console.log(
              `No configuration found for severity level ${targetNodeSeverity}`,
            );

          const severityColor = severityObj.color;

          return (assetCategory === "All Categories" ||
            assetCategory === node._private.data.category) &&
            (node._private.data.name
              .toLowerCase()
              .startsWith(asset.toLowerCase()) ||
              asset === "") &&
            colorFilter[severityColor] &&
            displayOptions[
              node._private.data.category === ASSETS_CATEGORIES.LOCATION
                ? "location"
                : "asset"
            ]
            ? severityColor
            : "lightgrey";
        },
        label: (node) =>
          node._private.data.category === ASSETS_CATEGORIES.LOCATION &&
          displayOptions["locationName"]
            ? node._private.data.name
            : "",
        "border-width": "2px",
        "border-color": "#000",
        color: darkMode ? "#fff" : "#000",
      },
    },
    // {
    //   selector: "node:selected",
    //   style: {
    //     border: "2px solid transparent",
    //     // "border-color": "aqua",
    //     "box-sizing": "border-box",
    //     // "border-opacity": "0.5",
    //     // "background-color": "#77828C",
    //     // "text-outline-color": "#77828C",
    //   },
    // },
    // {
    //   selector: "node[category='Location']",
    //   style: {
    //     shape: "rectangle",
    //   },
    // },
    {
      selector: "edge",
      style: {
        width: 0.6,
        "line-color": darkMode ? "yellow" : "#000",
        "target-arrow-color": darkMode ? "yellow" : "#000",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier",
      },
    },
  ];

  return (
    <MDBox ref={containerRef} width="100%" height="100%">
      {transformedData && (
        <>
          <MDBox display="flex" justifyContent="space-between" width="100%">
            <MDBox ml={6}>
              <MDTypography variant="h6">{primaryLabel}</MDTypography>
              <MDTypography component="div" variant="button" color="text">
                {secondaryLabel}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <DatasetFilter
                datasetType={datasetType}
                handleDatasetTypeChange={handleDatasetTypeChange}
                layout={layout}
                transformedData={transformedData}
                isDarkMode={isDarkMode}
              />
              <GraphLayoutFilter
                layout={layout}
                handleLayoutChange={handleLayoutChange}
                isDarkMode={isDarkMode}
              />
              <SelectCategoryFilter
                categories={categoryData.map(
                  (categoryConfig) => categoryConfig.code,
                )}
                assetCategory={assetCategory}
                handleAssetCategoryChange={handleAssetCategoryChange}
                isDarkMode={isDarkMode}
              />
              <AssetFilter
                asset={asset}
                handleSearchAsset={handleSearchAsset}
                level={1}
              />
              {error}
              {isEditMode && isAdmin && (
                <Dropdown
                  handleDeleteWidget={handleDeleteWidget}
                  masterContainerId={masterContainerId}
                  cellId={cellId}
                  index={index}
                  options={GRAPH_TOPOLOGY_DROPDOWN_LIST}
                />
              )}
            </MDBox>
          </MDBox>
          <ShowLegendsSwitch
            showLegends={showLegends}
            handleShowLegends={handleShowLegends}
            level={1}
          />
          <DisplayOptionsLegend
            title="Display Options"
            isDarkMode={isDarkMode}
            displayOptions={displayOptions}
            handleDisplayOptionsChange={handleDisplayOptionsChange}
            showLegends={showLegends}
            categories={[
              {
                optionName: "locationName",
                name: "Location Name",
              },
              {
                optionName: "location",
                name: "Location",
              },
              {
                optionName: "asset",
                name: "Asset",
              },
            ]}
          />
          <Legend
            title="Severity"
            isDarkMode={isDarkMode}
            colorFilter={colorFilter}
            handleColorFilterChange={handleColorFilterChange}
            level={1}
            showLegends={showLegends}
            categories={severityData.map((severity) => ({
              color: severity.color,
              colorName: severity.color,
              name: severity.severityName,
            }))}
          />
        </>
      )}
      {useMemo(
        () =>
          transformedData && (
            <CytoscapeComponent
              key={uuidv4()}
              style={{ width: "100%", height: "100%" }}
              elements={CytoscapeComponent.normalizeElements(transformedData)}
              maxZoom={3}
              minZoom={0.5}
              boxSelectionEnabled
              layout={{
                name: layout,
                fit: true,
                animate: false,
              }}
              stylesheet={styleSheet}
              cy={(cy) => {
                cy.on("cxttap", "node", (evt) => handleContextMenu(evt));

                cy.on("tap", "node", (evt) =>
                  handlePopupLocationAssetsOpen(evt),
                );

                cy.on("mouseover", "node", (event) => {
                  const nodeId = event.target._private.data.id;

                  const tooltipDiv = document.createElement("div");
                  tooltipDiv.className = "cytoscape-tooltip";
                  tooltipDiv.style.position = "absolute";
                  tooltipDiv.style.paddingBlock = "-.2em";
                  tooltipDiv.style.paddingInline = ".3em";
                  tooltipDiv.style.borderRadius = ".2em";
                  tooltipDiv.style.backgroundColor = darkMode ? "#fff" : "#edf6f9";
                  tooltipDiv.style.left = `${event.renderedPosition.x + 310}px`;
                  tooltipDiv.style.top = `${event.renderedPosition.y + 60}px`;

                  const tooltipInactiveDiv = document.createElement("div");
                  tooltipInactiveDiv.style.display = "flex";
                  tooltipInactiveDiv.style.alignItems = "center";

                  const tooltipInactiveDivCircle =
                    document.createElement("div");
                  tooltipInactiveDivCircle.style.width = ".5em";
                  tooltipInactiveDivCircle.style.height = ".5em";
                  tooltipInactiveDivCircle.style.borderRadius = "50%";
                  tooltipInactiveDivCircle.style.backgroundColor = "red";
                  tooltipInactiveDiv.appendChild(tooltipInactiveDivCircle);

                  const tooltipInactiveDivText = document.createElement("p");
                  tooltipInactiveDivText.style.marginLeft = ".5em";
                  tooltipInactiveDivText.style.fontSize = "1rem";
                  tooltipInactiveDivText.style.fontWeight = "800";
                  tooltipInactiveDivText.style.color = "#344767";
                  tooltipInactiveDivText.textContent =
                    descendantsData[nodeId].inactive;
                  tooltipInactiveDiv.appendChild(tooltipInactiveDivText);

                  tooltipDiv.appendChild(tooltipInactiveDiv);

                  const tooltipActiveDiv = document.createElement("div");
                  tooltipActiveDiv.style.display = "flex";
                  tooltipActiveDiv.style.alignItems = "center";
                  tooltipActiveDiv.style.marginTop = "-.4em";

                  const tooltipActiveDivCircle = document.createElement("div");
                  tooltipActiveDivCircle.style.width = ".5em";
                  tooltipActiveDivCircle.style.height = ".5em";
                  tooltipActiveDivCircle.style.borderRadius = "50%";
                  tooltipActiveDivCircle.style.backgroundColor = "green";
                  tooltipActiveDiv.appendChild(tooltipActiveDivCircle);

                  const tooltipActiveDivText = document.createElement("p");
                  tooltipActiveDivText.style.marginLeft = ".5em";
                  tooltipActiveDivText.style.fontSize = "1rem";
                  tooltipActiveDivText.style.fontWeight = "800";
                  tooltipActiveDivText.style.color = "#344767";
                  tooltipActiveDivText.textContent =
                    descendantsData[nodeId].active;
                  tooltipActiveDiv.appendChild(tooltipActiveDivText);

                  tooltipDiv.appendChild(tooltipActiveDiv);

                  document.body.appendChild(tooltipDiv);
                });

                cy.on("mouseout", "node", () => {
                  const tooltipDivs = document.getElementsByClassName(
                    "cytoscape-tooltip",
                  );
                  console.log(tooltipDivs)
                  for(let tooltipDiv of tooltipDivs){
                    if (tooltipDiv) tooltipDiv.remove();
                  }
                });
              }}
            />
          ),
        [transformedData, layout, width, height],
      )}
      {nodeData && (
        <>
          <ContextMenu
            contextMenuData={
              categoryData.filter(
                (menuData) => menuData.code === nodeData.category,
              )[0]
            }
            contextMenu={contextMenu}
            handleClose={handleClose}
            nodeDatum={nodeData}
            handlePopupTopologyOpen={handlePopupTopologyOpen}
            handleManagementInterface={handleManagementInterface}
            handleDashboard={handleDashboard}
          />
          <PopupTopology
            nodeDatum={nodeData}
            categoryData={categoryData}
            descendantsData={descendantsData}
            severityData={severityData}
            popupTopologyOpen={popupTopologyOpen}
            handlePopupTopologyClose={handlePopupTopologyClose}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
          />
        </>
      )}
      {nodeData && (
        <PopupLocationAssets
          popupLocationAssetsOpen={popupLocationAssetsOpen}
          handlePopupLocationAssetsClose={handlePopupLocationAssetsClose}
          location={nodeData.name}
          descendantsData={descendantsData}
          categoryData={categoryData}
          severityData={severityData}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
      )}
      {nodeData &&
        smartAssetPopupOpen &&
        createElement(componentRender, {
          open: smartAssetPopupOpen,
          handleClose: handleSmartAssetPopupClose,
          isAdmin: isAdmin,
          isEditMode: isEditMode,
          primaryLabel: `${nodeData.category}/${nodeData.name}`,
          assetId: nodeData.id,
          assetIpAddress: nodeData.ipAddress,
          assetImageName: `/images/${contextMenuData.imageName}`,
          assetImageWidth: contextMenuData.width,
          assetImageHeight: contextMenuData.height,
          category: nodeData.category,
        })}
    </MDBox>
  );
}

export default RenderGraphTopology;
