import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";

const Attribute = {
  formSize: "lg",
  modes: [],
};

const getColumn = () => {
  return [
    {
      Header: "Scada Port",
      accessor: "scAdaPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      defaultValue: 162,
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
    },
    {
      Header: "Scada Servers",
      accessor: "scadaServers",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 6,
    },
    {
      Header: "Server RLU Code",
      accessor: "serverRluCode",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      width: 6,
    },
    {
      Header: "SNMP Trap Receive IP",
      accessor: "snmpTrapReceiveIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "SNMP Trap Receive-Port",
      accessor: "snmpTrapReceivePort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "SNMP Trap Send IP",
      accessor: "snmpTrapSendIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "SNMP Trap Send Port",
      accessor: "snmpTrapSendPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "SNMP Event Send IP",
      accessor: "snmpEventSendIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().ipv4().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "SNMP Event Send Port",
      accessor: "snmpEventSendPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "Central NMS Redis IP",
      accessor: "centralNmsRedisIp",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "Central NMS Redis Port",
      accessor: "centralNmsRedisPort",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable().nullable(),
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "Domain/Virtual IP",
      accessor: "Domain_VirtualIP",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      validation: formControl.string().MultipleIP().nullable(),
      MultipleIP: true,
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 6,
    },
    {
      Header: "Discovery Duration",
      accessor: "discoveryDuration",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 60,
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 2.9,
    },
    {
      Header: "Discovery Timeout",
      accessor: "discoveryTimeout",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 1000,
      Cell: ({ value }) => <DefaultCell value={value} />,

      width: 2.9,
    },
    {
      Header: "ICMP Check Threads",
      accessor: "icmpCheckThreads",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 10,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 2.9,
    },
    {
      Header: "IP Count Each Thread",
      accessor: "ipCountEachThread",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "integer",
      validation: formControl.string().digitsOnly().nullable(),
      defaultValue: 5,
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: 2.9,
    },
  ];
};

export { getColumn, Attribute };
