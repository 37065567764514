import DataSequence from "application/components/CTLDataSequence";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "tiers/list",
  create: "tiers/create",
  update: "tiers/update",
  delete: "tiers/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Queue",
      accessor: "queue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      dataType: "string",
      controlType: "select",
      filterControlType: "select",
      options: {
        keyField: "systemName",
        keyDescription: "description",
        values: [],
        url: "queue/listAll",
      },
    },
    {
      Header: "Agent",
      accessor: "agent",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      filterControlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "name",
        keyDescription: "agentFullName",
        values: [],
        url: "agent/listAll",
      },
    },
    {
      Header: "State",
      accessor: "state",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "Unknown", description: "Unknown" },
          { id: "No Answer", description: "No Answer" },
          { id: "Ready", description: "Ready" },
          { id: "Offering", description: "Offering" },
          { id: "Active Inbound", description: "Active Inbound" },
          { id: "Standby", description: "Standby" },
          { id: "Not Assigned", description: "Not Assigned" },
        ],
      },
    },
    {
      Header: "position",
      accessor: "position",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
