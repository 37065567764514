import axios from "axios";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import variables from "globals/variables";

const application = axios.create({
    baseURL: variables.api.services
});

const telemetry = axios.create({
  baseURL: variables.api.telemetry,
});

const externalCallServer = axios.create({
  baseURL: `${AuthenticationService.getRLUIpAddress()}`,
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${AuthenticationService.getAuthenticationToken()}`;

  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error.response?.status === 401) {
    const originalRequest = error.config;
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      return refreshTokenRequest().then(() => {
        return axios(originalRequest);
      });
    } else {
      window.location = "/site/log-out";
    }
  } else if (error.response?.status === 403) {
    window.location = "/site/log-out";
  }
  return Promise.reject(error);
};

const refreshTokenRequest = () => {
  return axios
    .post(`${variables.api.services}user/refreshToken`, null, {
      headers: {
        Authorization: `Refresh-Bearer ${AuthenticationService.getRefreshToken()}`,
      },
    })
    .then((response) => {
      AuthenticationService.setRefreshedToken(
        response.data.data.token,
        response.data.data.refreshToken
      );
      return Promise.resolve();
    })
    .catch((error) => {
      window.location = "/site/log-out";
    });
};

application.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

application.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

externalCallServer.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

externalCallServer.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

telemetry.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

telemetry.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

const generateServicesEndPoint = (urlScheme, ipAddress) => {
  let baseURL = `${urlScheme}//${ipAddress}/services/api/v2/`;
  const endPoint = axios.create({
    baseURL: baseURL,
  });

  endPoint.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  endPoint.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  return endPoint;
};

const generateTelemetryEndPoint = (urlScheme, ipAddress) => {
  const endPoint = axios.create({
    baseURL: `${urlScheme}//${ipAddress}/telemetry/api/v2/`,
  });

  endPoint.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  endPoint.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );

  return endPoint;
};

export {
  application,
  telemetry,
  externalCallServer,
  generateServicesEndPoint,
  generateTelemetryEndPoint,
};
