import { useState, useEffect, useRef } from "react";
import { select } from "d3-selection";
import { forceLink, forceManyBody, forceSimulation } from "d3-force";
import { tick } from "./events";
import { addDrag, addHoverOpacity } from "./interactions";
import Draggable from "react-draggable";
import Zoom from "../Zoom";
import MDBox from "components/MDBox";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Fab } from "@mui/material";

function Graph({
  data,
  nodeDistance,
  NodeComponent,
  LineComponent,
  pullIn,
  zoomDepth,
  enableDrag,
  hoverOpacity,
  toggleRefreshGraph,
  id = "GraphTree_container",
  ...restProps
}) {
  const svgRef = useRef();
  const [disableGraphTopoDrag, setDisableGraphTopoDrag] = useState(false);
  const [graphTopoConfig, setGraphTopoConfig] = useState(
    JSON.parse(localStorage.getItem("graphTopoConfig")) || {
      kVal: 1,
      xVal: 0,
      yVal: 0,
    },
  );

  useEffect(() => {
    localStorage.setItem("graphTopoConfig", JSON.stringify(graphTopoConfig));
  }, [graphTopoConfig]);

  useEffect(() => {
    if (!data) {
      return null;
    }

    const svg = select(`#${id}`);
    const link = svg.selectAll("._graphLine").data(data.links);
    const node = svg.selectAll("._graphNode").data(data.nodes);

    const simulation = forceSimulation(data.nodes)
      .force(
        "link",
        forceLink()
          .id(function (d) {
            return d.id;
          })
          .distance(nodeDistance)
          .strength(1)
          .links(data.links),
      )
      .force("charge", forceManyBody().distanceMax(300).strength(-50))
      .on("tick", () => tick(node, link))
      .tick(400);

    // add interactions
    addHoverOpacity(node, link, hoverOpacity);
    addDrag(node, simulation, enableDrag, pullIn);

    return () => {
      simulation.stop();
    };
  }, [
    data,
    nodeDistance,
    NodeComponent,
    LineComponent,
    pullIn,
    zoomDepth,
    enableDrag,
    hoverOpacity,
  ]);

  if (!data) {
    return null;
  }

  const handleDisableGraphTopoDrag = () => setDisableGraphTopoDrag(true);

  const handleEnableGraphTopoDrag = () => setDisableGraphTopoDrag(false);

  const handleZoomIncrease = () => {
    if (graphTopoConfig.kVal < 10) {
      const updatedGraphTopoConfig = {
        ...graphTopoConfig,
        kVal: graphTopoConfig.kVal + 0.2,
      };
      setGraphTopoConfig(updatedGraphTopoConfig);
    }
  };
  const handleZoomDecrease = () => {
    if (graphTopoConfig.kVal > 0.5) {
      const updatedGraphTopoConfig = {
        ...graphTopoConfig,
        kVal: graphTopoConfig.kVal - 0.15,
      };
      setGraphTopoConfig(updatedGraphTopoConfig);
    }
  };

  const handleDrag = (e, ui) => {
    const updatedGraphTopoConfig = {
      ...graphTopoConfig,
      xVal: ui.lastX,
      yVal: ui.lastY,
    };
    setGraphTopoConfig(updatedGraphTopoConfig);
  };

  const handleResetView = () => {
    const updatedGraphTopoConfig = {
      kVal: 1,
      xVal: 0,
      yVal: 0,
    };
    setGraphTopoConfig(updatedGraphTopoConfig);
    toggleRefreshGraph();
  };

  return (
    <MDBox width="100%" height="100%" sx={{ overflow: "hidden" }}>
      <Zoom
        handleZoomIncrease={handleZoomIncrease}
        handleZoomDecrease={handleZoomDecrease}
      />
      <Fab
        color="info"
        onClick={handleResetView}
        sx={{
          zIndex: "2",
          position: "absolute",
          bottom: "-3em",
          left: "1.2em",
        }}
        size="small"
      >
        <RestartAltIcon
          sx={{ marginBottom: ".07em", marginleft: ".03em" }}
          fontSize="medium"
        />
      </Fab>
      <Draggable
        onStop={handleDrag}
        position={{ x: graphTopoConfig.xVal, y: graphTopoConfig.yVal }}
        disabled={disableGraphTopoDrag}
      >
        <svg
          id={id}
          width="100%"
          height="100%"
          {...restProps}
          ref={svgRef}
          viewBox="-350 -450 800 800"
        >
          <g
            className="_graphZoom"
            transform={`scale(${graphTopoConfig.kVal})`}
          >
            {data.links.map((link, i) => {
              return LineComponent ? (
                <LineComponent link={link} key={i} className="_graphLine" />
              ) : (
                <line stroke="grey" key={i} className="_graphLine" />
              );
            })}
            {data.nodes.map((node, i) => {
              return (
                <g key={i} className="_graphNode">
                  {NodeComponent ? (
                    <NodeComponent
                      node={node}
                      handleDisableGraphTopoDrag={handleDisableGraphTopoDrag}
                      handleEnableGraphTopoDrag={handleEnableGraphTopoDrag}
                    />
                  ) : (
                    <circle fill="black" r={10} />
                  )}
                </g>
              );
            })}
          </g>
        </svg>
      </Draggable>
    </MDBox>
  );
}

Graph.defaultProps = {
  nodeDistance: 100,
  zoomDepth: 0,
  hoverOpacity: 1,
};

export default Graph;
