import MDTypography from "components/MDTypography";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PanToolIcon from '@mui/icons-material/PanTool';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';

function CTLParticipantsAction({ ActionType, value, suffix }) {
  if (ActionType == "Speaker") {
    suffix = (
      <tooltip title="Speaker">
        <VolumeUpIcon fontSize="medium" color="error" />
      </tooltip>
    );
  }
  if (ActionType == "mute") {
    suffix = (
      <tooltip title="mute">
        <VolumeOffIcon fontSize="medium" color="warning" />
      </tooltip>
    );
  }
  if (ActionType == "Raise Hand") {
    suffix = (
      <tooltip title="Raise Hand">
        <PanToolIcon fontSize="medium" color="success" />
      </tooltip>
    );
  } else if (ActionType == "Hold Call") {
    suffix = (
      <tooltip title="Hold Call">
        <PhonePausedIcon fontSize="medium" color="info" />
      </tooltip>
    );
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLParticipantsAction.defaultProps = {
  suffix: "",
};

export default CTLParticipantsAction;