import AuthenticationService from "application/modules/authentication/AuthenticationService";

const groupMenu = AuthenticationService.getGroupMenus();

const moreVert = [
  {
    icon: "summarize",
    key: "agent-performance",
    name: "Daily Performance Report",
    url: "home/app/billing/agent-performance",
    description: "Agent Reports",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-DailyReport",
    name: "Daily Report",
    url: "home/app/billing/agent-dailyReport",
    description: "Agent Reports",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "group-abandoned-call",
    name: "Group Abandoned Call",
    url: "home/app/configurations/report/GroupAbandonedCall",
    description: "Agent Reports",
    productionReady: true,
  },
  {
    icon: "call_received_icon",
    key: "inboundReport",
    name: "Last 10 - Inbound Call",
    url: "home/app/configurations/report/inboundReport",
    description: "Agent Reports",
    productionReady: true,
  },
  {
    icon: "tty_icon",
    key: "outboundReport",
    name: "Last 10 - Outbound Call",
    url: "home/app/configurations/report/outboundReport",
    description: "Agent Reports",
    productionReady: true,
  },
  {
    icon: "call_missed_icon",
    key: "missedCallReport",
    name: "Last 10 - Missed Call",
    url: "home/app/configurations/report/missedCallReport",
    description: "Agent Reports",
    productionReady: true,
  },
];

const filteredAgentReportMoreVert = moreVert.filter((menu) =>
  groupMenu?.find(
    (groupMenu) =>
      groupMenu.name === "Agent Dashboard" &&
      groupMenu.menus.some(
        (item) =>
          item.menuName === menu.name &&
          (item.product === "more" || item.product === "both")
      )
  )
);

const agentReportMoreVert =
  filteredAgentReportMoreVert.length === 0 ||
  filteredAgentReportMoreVert == null
    ? moreVert.map((menu) => ({
        icon: menu.icon,
        key: menu.key,
        name: menu.name,
        url: menu.url,
        description: menu.description,
        productionReady: menu.productionReady,
      }))
    : filteredAgentReportMoreVert?.map((menu) => ({
        icon: menu.icon,
        key: menu.key,
        name: menu.name,
        url: menu.url,
        description: menu.description,
        productionReady: menu.productionReady,
      }));

agentReportMoreVert.sort((a, b) => {
  const menu =
    groupMenu && groupMenu.find((menu) => menu.name === "Agent Dashboard");
  const sequenceA =
    menu?.menus.find((item) => item.menuName === a.name)?.sequence || 0;
  const sequenceB =
    menu?.menus.find((item) => item.menuName === b.name)?.sequence || 0;
  return sequenceA - sequenceB;
});

export { agentReportMoreVert };
