import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import ReactPlayer from "react-player";
import CTLDialogContent from "../styles/CTLDialogContent";
import { useMaterialUIController } from "context";
import variables from "globals/variables";

export default function PlayAction(row) {
  const id = row.row.original.id;
 

  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <SmartDisplayIcon />
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Video Recording...
         
          <CloseIcon
            onClick={handleClose}
            style={{
            marginLeft:"350px",
            position: "absolute" }}
          />
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <ReactPlayer
            url={`${variables.app.services}/auth/videoRecordingStream/${id}`}
            width="100%"
            height="400px"
            controls={true}
            playing={true}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
