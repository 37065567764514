const agentMenu = [
  {
    icon: "reports",
    key: "Daily Performance",
    name: "Daily Performance",
    url: "home/app/agentdashboard",
    description: "Daily Performance",
  },
  {
    icon: "report",
    key: "Daily Report",
    name: "Daily Report",
    url: "home/app/agentdashboard",
  },
  {
    icon: "call",
    key: "Last 10 Calls",
    name: "Last 10 Calls",
    url: "home/app/agentdashboard",
  },
  {
    icon: "groups",
    key: "Group's Abandoned Calls",
    name: "Group's Abandoned Calls",
    url: "home/app/agentdashboard",
  },
];

export { agentMenu };
