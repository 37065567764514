import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FormControl, InputLabel, Box, styled } from "@mui/material";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { application } from "globals/endpoints";

const operationURLS = {
  updatestatus: "/customerAccount/update/userStatus",
  userStatuslist: "/userStatus/list",
};

export default function CTLUserStatus() {
  const UserName = AuthenticationService.getFullName();

  const [status, setStatus] = useState([]);
  const [data, setData] = useState([]);
  const [lable, setLable] = useState("");
  const [staticLable, setStaticLable] = useState(false);

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus);
    setStaticLable(true);
    if (selectedStatus !== "") {
      UserStatusUpdate(selectedStatus);
    }
  };

  useEffect(() => {
    const UserStatusList = async () => {
      try {
        const response = await application.post(operationURLS.userStatuslist);
        const userStatuses = response.data?.data || [];
        setData(userStatuses);

        const matchingData = userStatuses.find(
          (value) =>
            value.profileId === UserName && value.currentStatus === "yes"
        );

        if (matchingData) {
          setLable(matchingData.description);
        } else {
          setLable("");
        }
      } catch (error) {
        console.log("Error", error);
      }
    };

    UserStatusList();
  }, [UserName]);

  const UserStatusUpdate = (selectedStatus) => {
    const requestBody = {
      username: UserName,
      userStatus: selectedStatus,
    };
    application
      .post(operationURLS.updatestatus, requestBody)
      .then((response) => {})
      .catch((error) => {
        console.log("Error:", error);
      });
  };
  return (
    <FormControl sx={{ minWidth: 30 }}>
      {staticLable !== true ? (
        <InputLabel>{lable}</InputLabel>
      ) : (
        <InputLabel>Status</InputLabel>
      )}

      <Select
        native
        labelId="demo-controlled-open-select-label"
        id="status"
        value={status}
        onChange={handleChange}
        label={staticLable !== true ? lable : "Status"}
        sx={{
          marginBottom: "3px",
          "& option": {
            color: "#344767",
          },
        }}
      >
        <option value=""></option>
        {data?.map((value, index) => {
          return (
            <option key={index} value={value?.status}>
              {value.description}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}
