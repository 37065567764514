import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function GroupMasterCell({ value }) {
 
  const [listOptions, setListOptions] = useState([]);
  useEffect(() => {
    application.post("/userGroupMaster/listAll").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);
const matchedRoles = listOptions && listOptions.filter((option) => {
  return option.id !== null && value && value.includes(option.id);
});

  return (
    <>
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {matchedRoles && matchedRoles.map((role) => role.code).join(", ")}
      </MDTypography>
    </>
  );
}

export default GroupMasterCell;
