import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMaterialUIController } from "context";
import FormField from "layouts/applications/wizard/components/FormField";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import loggerFactory from "globals/logger/logger-factory";
import CTLAdvancedFilterShow from "../CTLAdvancedFilterShow";
import CTLSelect from "../CTLSelect";

export default function CTLAdvancedFilter({
  applyFilter,
  onApplyFilter,
  onCloseFilter,
  entityName,
  columns,
  advancedFilters,
}) {
  const componentName = "Advance Filter";
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const initialValues = {
    from: {
      string: {},
      date: {},
    },
    to: {
      string: {},
      date: {},
    },
  };

  const validationSchema = yup.object();

  columns &&
    columns.forEach((column) => {
      if (column.allowFilter) {
        initialValues.from[`${column.dataType}`][`${column.accessor}`] = "";
        initialValues.to[`${column.dataType}`][`${column.accessor}`] = "";
      }
    });

  const filterValues = [];
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      loggerFactory.debug(componentName, "Advance Filter Applied", values);

      for (const fromTo in values) {
        loggerFactory.debug(componentName, "From/To Value : ", fromTo);
        for (const dataType in values[fromTo]) {
          loggerFactory.debug(componentName, "Data Type Value : ", dataType);
          for (const field in values[fromTo][dataType]) {
            if (values[fromTo][dataType][field]) {
              const direction = fromTo; // Use direction here

              loggerFactory.debug(componentName, "Field : ", {
                field,
                value: values[fromTo][dataType][field],
              });

              filterValues.push({
                direction,
                dataType,
                fieldName: field,
                value: values[fromTo][dataType][field],
              });
            }
          }
        }
      }

      loggerFactory.debug(componentName, "Data to Filter", filterValues);

      onApplyFilter(filterValues, null, 2);
    },
  });

  useEffect(() => {
    setOpen(applyFilter);
  }, [applyFilter]);

  const getValues = (subset, dataType, fieldName) => {
    if (formik.values[subset] && formik.values[subset][dataType]) {
      return formik.values[subset][dataType][fieldName] || "";
    } else {
      return "";
    }
  };

  const handleChange = (e, column) => {
    const { name, value } = e.target;
    const [subset, dataType, fieldName] = name.split(".");
    formik.handleChange(e);

    // Check if the field is in "from" subset and update "to" accordingly
    if (subset === "from") {
      formik.setFieldValue(`to.${dataType}.${fieldName}`, value);
    }
  };

  function onClearFilter() {
    formik.resetForm();
    formik.handleSubmit();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseFilter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          {entityName} : Advanced Filter
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            {columns &&
              columns.map((column) => {
                if (column.allowFilter) {
                  return (
                    <Grid key={column.accessor} container spacing={1} mb={2}>
                      {column.filterControlType === "select" ? (
                        <>
                          <Grid item xs={6}>
                            <CTLSelect
                              label={`From ${column.Header}`}
                              name={`from.${column.dataType}.${column.accessor}`}
                              value={getValues(
                                "from",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={(e) => handleChange(e, column)}
                              error={
                                formik.touched[
                                  `from.${column.dataType}.${column.accessor}`
                                ] &&
                                formik.errors[
                                  `from.${column.dataType}.${column.accessor}`
                                ]
                              }
                              success={
                                !formik.touched[
                                  `from.${column.dataType}.${column.accessor}`
                                ] ||
                                !formik.errors[
                                  `from.${column.dataType}.${column.accessor}`
                                ]
                              }
                              options={
                                column.options && column.options.values
                                  ? column.options.values
                                  : []
                              }
                              keyField={
                                column.options && column.options.keyField
                                  ? column.options.keyField
                                  : ""
                              }
                              keyDescription={
                                column.options && column.options.keyDescription
                                  ? column.options.keyDescription
                                  : ""
                              }
                              accessorKeyField={
                                column.options &&
                                column.options.accessorKeyField
                                  ? column.options.accessorKeyField
                                  : ""
                              }
                              accessorValueField={
                                column.options &&
                                column.options.accessorValueField
                                  ? column.options.accessorValueField
                                  : ""
                              }
                              url={
                                column.options && column.options.url
                                  ? column.options.url
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CTLSelect
                              label={`To ${column.Header}`}
                              name={`to.${column.dataType}.${column.accessor}`}
                              value={getValues(
                                "to",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={(e) => handleChange(e, column)}
                              error={
                                formik.touched[
                                  `to.${column.dataType}.${column.accessor}`
                                ] &&
                                formik.errors[
                                  `to.${column.dataType}.${column.accessor}`
                                ]
                              }
                              success={
                                !formik.touched[
                                  `to.${column.dataType}.${column.accessor}`
                                ] ||
                                !formik.errors[
                                  `to.${column.dataType}.${column.accessor}`
                                ]
                              }
                              options={
                                column.options && column.options.values
                                  ? column.options.values
                                  : []
                              }
                              keyField={
                                column.options && column.options.keyField
                                  ? column.options.keyField
                                  : ""
                              }
                              keyDescription={
                                column.options && column.options.keyDescription
                                  ? column.options.keyDescription
                                  : ""
                              }
                              accessorKeyField={
                                column.options &&
                                column.options.accessorKeyField
                                  ? column.options.accessorKeyField
                                  : ""
                              }
                              accessorValueField={
                                column.options &&
                                column.options.accessorValueField
                                  ? column.options.accessorValueField
                                  : ""
                              }
                              url={
                                column.options && column.options.url
                                  ? column.options.url
                                  : ""
                              }
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={6}>
                            <FormField
                              id={`from.${column.dataType}.${column.accessor}`}
                              name={`from.${column.dataType}.${column.accessor}`}
                              label={`From ${column.Header}`}
                              value={getValues(
                                "from",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={(e) => handleChange(e, column)}
                              type={
                                column.dataType === "string"
                                  ? "text"
                                  : column.dateTime
                                  ? "datetime-local"
                                  : "date"
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id={`to.${column.dataType}.${column.accessor}`}
                              name={`to.${column.dataType}.${column.accessor}`}
                              label={`To ${column.Header}`}
                              value={getValues(
                                "to",
                                column.dataType,
                                column.accessor
                              )}
                              onChange={formik.handleChange}
                              type={
                                column.dataType === "string"
                                  ? "text"
                                  : column.dateTime
                                  ? "datetime-local"
                                  : "date"
                              }
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  );
                }
              })}
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton onClick={onClearFilter} color="error">
              Clear
            </MDButton>
            <MDButton onClick={onCloseFilter} color="secondary">
              Cancel
            </MDButton>
            <MDButton color="success" autoFocus type="submit">
              Apply
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>

      <CTLAdvancedFilterShow
        advancedFilters={advancedFilters}
        onClearFilter={onClearFilter}
      />
    </>
  );
}
