import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,

  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/complaintDetail/list",
  pdfDownload:"/complaintDetail/generateAndDownloadPdf",
  csvDownload:"/complaintDetail/downloadCSV"
};

const getColumns = () => {
  return [
    {
      Header: "Location",
      accessor: "rluLocation",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Type",
      accessor: "complaintType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Number",
      accessor: "complaintNo",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Time",
      accessor: "complaintTime",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Extension",
      accessor: "ext",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Complaint Description",
      accessor: "complaintDesc",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Close On",
      accessor: "closedOn",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Close By",
      accessor: "closedBy",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "currentStatus",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Remark",
      accessor: "agentRemarks",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Latitude",
      accessor: "latitude",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Longitude",
      accessor: "longitude",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
