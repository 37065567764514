import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";
import { getColumns, Attributes, operationURLS, PhoneServiceValidation } from "./model";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";

function PbxConfiguration({ onLoad, pageName }) {
  const componentName = "default.pbx.configuration";
  const [formData, setFormData] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    application.post(operationURLS.list).then((response) => {
      let dataToDisplay = response.data.data[0];
      loggerFactory.debug(componentName, "Data Received", dataToDisplay);
      setFormData(dataToDisplay);
    }).catch((error) => {
      setFormData([]);
    });
  }, []);

  function onSaveForm(data) {
    loggerFactory.debug(componentName, "Data to create", data);
    application
      .post(operationURLS.create, data)
      .then((response) => {
         setNotify({
            isOpen: true,
            message: response.data.messageDetail ,
            type: "success",
            status:response.data.status,
            pagename: pageName,
          });
        return application.post(operationURLS.list);
      })
      .then((response) => {
        let dataToDisplay = response.data.data[0];
        setFormData(dataToDisplay);
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          loggerFactory.debug(
            componentName,
            "Unable to create the file data.",
            error
          );
        }
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          status:error.response.status,
          pagename: pageName,
        });
      });
  }

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  return (
    <MDBox>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="Nodes"
        columns={getColumns(null, null)}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
        <CTLNotification notify={notify} setNotify={setNotify} />
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default PbxConfiguration;
