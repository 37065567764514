export default function getFilteredItems(data, columnsDetails, filterText) {
  const lowerCasedFilterText = filterText.toLowerCase();

  let filteredItems = [];
  Object.keys(columnsDetails).forEach((field) => {
    const currentFilteredItems = data.filter((record) => {
      return (
        columnsDetails[field].display &&
        record[field] &&
        record[field].toString().toLowerCase().startsWith(lowerCasedFilterText)
      );
    });
    if (currentFilteredItems.length > filteredItems.length)
      filteredItems = currentFilteredItems;
  });

  return filteredItems;
}
