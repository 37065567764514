export default function cytoscapeGraphDataTransform(
  data,
  topologyType = "",
  locationData,
) {
  if (data.length === 0) return { nodes: [], edges: [] };
  const nodes = [];
  const edges = [];

  function helper(node) {
    const location = locationData.find((loc) => loc.uuid === node.id);

    nodes.push({
      data: {
        ...node,
        label: node.name,
        nodeLocationId: location ? location.id : "",
      },
      position: location
        ? {
            x: location.xcoordinate ? parseFloat(location.xcoordinate) : 0,
            y: location.ycoordinate ? parseFloat(location.ycoordinate) : 0,
          }
        : { x: 0, y: 0 },
    });
    node.children.forEach((child) => {
      if (child.children.length === 0 && topologyType === "location-graph")
        return;

      edges.push({ data: { source: node.id, target: child.id } });
      helper(child);
    });
  }

  helper(data[0]);
  return { nodes, edges };
}
