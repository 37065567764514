import MDTypography from "components/MDTypography";
import StarIcon from "@mui/icons-material/Star";

function CTLPremiumCell({ value, suffix }) {
  if (value === true) {
    suffix = <StarIcon fontSize="small" color="warning" />;
  } else if (value === false) {
    suffix = null;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLPremiumCell.defaultProps = {
  suffix: "",
};

export default CTLPremiumCell;
