import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import { Stack } from "@mui/material";
import Queue from "./components/queue";
import { getColumns, Attributes, operationURLS } from "./model";

import { getAgentColumns, agentOperationURLS } from "./components/agent/model";

function CallCenter({ onLoad, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openAgentForm, setAgentOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
        onAgent: onOpenAgentForm,
      });
  }, [activePage]);

  function onOpenAgentForm() {
    setAgentOpenForm(true);
  }

  function onCloseAgentForm() {
    setAgentOpenForm(false);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }
  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  //for queue

  function queueOnSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.queueUpdate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      application
        .post(operationURLS.queueCreate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status: error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            message: error.response.data.message,
          });
        });
    }
    setOpenForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  // for agent

  function agentOnSaveForm(data) {
    if (data.id) {
      application
        .post(agentOperationURLS.agentUpdate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        });
    } else {
      application
        .post(agentOperationURLS.agentCreate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setOpenForm(false);
          setNotify({
            isOpen: true,
            message: error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
          });
        });
    }
    setAgentOpenForm(false);
  }

  return (
    <>
      <Stack spacing={1} direction="row">
        <MDBox>
          <CTLBaseDialogForm
            entityName="Call-Center"
            columns={getColumns(deleteRow, editRow)}
            openForm={openForm}
            onSaveForm={queueOnSaveForm}
            onCloseForm={onCloseForm}
            formData={formData}
            pageAttributes={Attributes}
          />
          <CTLBaseDialogForm
            entityName="Call-Center"
            columns={getAgentColumns(deleteRow, editRow)}
            openForm={openAgentForm}
            onSaveForm={agentOnSaveForm}
            onCloseForm={onCloseAgentForm}
            formData={formData}
            pageAttributes={Attributes}
          />
          <CTLNotification notify={notify} setNotify={setNotify} />
        </MDBox>
        <Queue onLoad={onLoad} updateTableList={updateList} />
      </Stack>
    </>
  );
}

export default CallCenter;
