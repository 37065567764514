import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setValue, setAction } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import MDButton from "components/MDButton";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dialog from "assets/theme/components/dialog";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function sipTrunk({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const componentName = "sipTrunk";

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, sipTrunkSelect } = controller;
  const { activePage, value, action, tableValue } = controller;
  const [formData, setFormData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [updateList, setUpdateList] = useState([Date.now()]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const [openConfigure, setOpenConfigure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openpublishData, setOpenPublishData] = useState(false);
  const [showContent, setShowContent] = useState(null);
  const [publishData, setPublishData] = useState({});
  const [filePreViewData, setFilePreViewData] = useState({});

  const handleOpen = () => setOpenPublishData(true);
  const handleClose = () => setOpenPublishData(false);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onPublish: onPublish,
        onFilePreView: onFilePreView,
      });
  }, [activePage]);

  const operationURLS = {
    list: `/sipFile/${sipTrunkSelect}/list`,
    save: `/sipFile/${sipTrunkSelect}/create`,
    publish: "/configuration/publish",
  };


  useEffect(() => {
    setUpdateList(Date.now());
  }, [sipTrunkSelect]);

  const handleCloseConfigure = () => {
    setOpenConfigure(false);
  };

  
  const onFilePreView = () => {
    setShowContent("filePreview");
    application
      .post(operationURLS.publish, { isPreview: true })
      .then((response) => {
        setTimeout(() => {
          setFilePreViewData(response.data.data);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
          handleOpen();
        }, 2000);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // For Coral File  Publish
  const onPublish = () => {
    setOpenConfigure(true);
  };

  
  const onConfirmPublish = () => {
    setOpenConfigure(false);
    setLoading(true);
    setShowContent("publish");
    application
      .post(operationURLS.publish, { isPreview: false })
      .then((response) => {
        setTimeout(() => {
          setPublishData(response.data.data);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
          handleOpen();
        }, 2000);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    if (row.isDelete == false ) {
       setNotify({
        isOpen: true,
        pagename: "SIP Trunk",
        message: "Error",
        type: "error",
        status: "Unable to delete ",
      });
    } else {
      setSelectedRow(row);
      setConfirmDelete(true);
    }
  };

  const onCancel = () => {
    setConfirmDelete(false);
  };

  const onAgree = (selectedRow) => {
    setValue(dispatch, selectedRow);
    setAction(dispatch, "delete");
    setConfirmDelete(false);
    setNotify({
      isOpen: true,
      pagename: "SIP Trunk",
      message: "Done",
      type: "success",
      status: "Successfully Deleted",
    });
  };

  const editRow = (row) => {
    setFormData(row);
    setAction(dispatch, "update");
    setOpenForm(true);
  };

  function onOpenForm() {
    setFormData({ eventTime: Date.now() });
    setOpenForm(true);
    setAction(dispatch, "create");
  }

  function onSaveForm(data) {
    if (action === "create") {
      if (tableValue.some((item) => item.name === data.name)) {
        setNotify({
          isOpen: true,
          pagename: "SIP Trunk",
          message: "Name already exists.",
          type: "error",
          status: "Error",
        });
      } else {
        setValue(dispatch, data);
        setOpenForm(false);
        setNotify({
          isOpen: true,
          pagename: "SIP Trunk",
          message: "Successfully saved.",
          type: "success",
          status: "Done",
        });
      }
    } else {
      setValue(dispatch, data);
      setOpenForm(false);
      setNotify({
        isOpen: true,
        pagename: "SIP Trunk",
        message: "Successfully saved.",
        type: "success",
        status: "Done",
      });
    }
  }

  function onCloseForm() {
    setAction(dispatch, "");
    setValue(dispatch, null);
    setOpenForm(false);
  }

  function handleSubmit() {
    const finalData = {
      sipTrunk: tableValue,
    };
    application.post(operationURLS.save, finalData).then((response) => {
      if (response.data.status == "OK") {
        setNotify({
          isOpen: true,
          pagename: "SIP Trunk",
          message: response.data.messageDetail,
          type: "success",
          status: response.data.status,
        });
      } else {
        setNotify({
          isOpen: true,
          pagename: "SIP Trunk",
          message: response.data.messageDetail,
          type: "error",
          status: response.data.status,
        });
      }
    });
  }

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="SIP Trunk"
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLAdvancedFilter
        entityName="sipTrunk"
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLBaseDialogForm
        entityName="SIP Trunk"
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <CTLTable
        apiURL={operationURLS.list}
        columns={getColumns(deleteRow, editRow)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="name"
        pageId={pageId}
        disablePagination={true}
        updateList={updateList}
        searchText={searchText}
        refetchInterval={Attributes.refetchInterval}
      />
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "right",
          margin: "20px",
        }}
      >
        <MDButton color="success" onClick={handleSubmit}>
          Apply
        </MDButton>
      </MDBox>
      <CTLNotification notify={notify} setNotify={setNotify} />
      <div>
        <Dialog
          PaperProps={{
            sx: {
              maxWidth: "80%",
              maxHeight: "80%",
            },
          }}
          open={openpublishData}
          classes={{ dialog }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={(theme) => CTLDialogContent(theme, { darkMode })}
          >
            <MDBox
              sx={{ display: "flex", justifyContent: "center" }}
              color="white"
            >
              Configure-Node Response
            </MDBox>
          </DialogTitle>
          <hr></hr>
          {showContent === "publish" && (
            <DialogContent
              sx={(theme) => ({
                ...CTLDialogContent(theme, { darkMode }),
                width: "100%",
                whiteSpace: "pre-wrap",
              })}
            >
              {onPublish && typeof publishData === "string" && (
                <pre>
                  {publishData.split("\n").map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </pre>
              )}
            </DialogContent>
          )}

          {showContent === "filePreview" && (
            <DialogContent
              sx={(theme) => ({
                ...CTLDialogContent(theme, { darkMode }),
                width: "100%",
                whiteSpace: "pre-wrap",
              })}
            >
              {onFilePreView && typeof filePreViewData === "string" && (
                <pre>
                  {filePreViewData.split("\n").map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </pre>
              )}
            </DialogContent>
          )}
          <DialogActions
            sx={(theme) => ({
              ...CTLDialogContent(theme, { darkMode }),
              justifyContent: "center",
            })}
          >
            <MDButton onClick={handleClose} color="secondary">
              Ok
            </MDButton>
          </DialogActions>
          <CTLNotification notify={notify} setNotify={setNotify} />
        </Dialog>
      </div>
      <MDBox>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </MDBox>

      <Dialog open={openConfigure} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Confirmation
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Are you sure you want to Run the Configure Node?
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleCloseConfigure} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={onConfirmPublish} color="success" autoFocus>
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default sipTrunk;
