import {
  useGetLayoutQuery,
  useGetPopulatedWidgetListDataQuery,
} from "features/apiSlice";
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import Layout from "../Layout";
import defaultLayout from "../utils/defaultLayout";
import { v4 as uuidv4 } from "uuid";

function Dashboard({
  id,
  isAdmin,
  username,
  popupMode,
  handleBreadcrumbs,
  isSensorsDashboard,
  sensorsWidgetList,
}) {
  const dashboardLayoutQuery = useGetLayoutQuery(
    `${process.env.REACT_APP_DASHBOARD_LAYOUT_ENDPOINT}${id}`,
    {
      refetchOnReconnect: true,
    },
  );

  const dashboardLayoutData = dashboardLayoutQuery.data;
  let driverLayoutData = null;
  let allTileWidgetIds = [];
  let allMasterContainersEmpty = true;

  if (dashboardLayoutData) {
    if (
      dashboardLayoutData.customLayout &&
      Object.keys(dashboardLayoutData.customLayout).length > 0
    )
      driverLayoutData = dashboardLayoutData.customLayout;
    else driverLayoutData = dashboardLayoutData.defaultLayout;

    Object.keys(driverLayoutData).forEach((masterContainerId) => {
      if (
        Array.isArray(driverLayoutData[masterContainerId]) &&
        driverLayoutData[masterContainerId].length > 0
      )
        allMasterContainersEmpty = false;
    });

    if (allMasterContainersEmpty) {
      if (isSensorsDashboard) {
        driverLayoutData = defaultLayout(
          id,
          isSensorsDashboard,
          sensorsWidgetList,
        );
        allTileWidgetIds = sensorsWidgetList.map((sensor) => sensor.id);
      } else {
        driverLayoutData = defaultLayout(id);
        allTileWidgetIds.push(id);
      }
    } else
      Object.keys(driverLayoutData).forEach((masterContainerId) => {
        if(Array.isArray(driverLayoutData[masterContainerId])){
          driverLayoutData[masterContainerId].forEach((cell) => {
            cell.widgetIds.forEach((widgetId) =>
              allTileWidgetIds.push(widgetId.id),
            );
          });
        }
      });
  }

  const widgetsListPopulatingQuery = useGetPopulatedWidgetListDataQuery(
    Array.from(new Set(allTileWidgetIds)),
    {
      skip: allTileWidgetIds.length === 0 || isSensorsDashboard,
    },
  );

  const populatedWidgetsList = !isSensorsDashboard
    ? widgetsListPopulatingQuery.data
    : dashboardLayoutData
    ? sensorsWidgetList
    : null;

  const widgetsDataObj = {};
  if (populatedWidgetsList) {
    populatedWidgetsList.forEach((widget) => {
      widgetsDataObj[widget.id] = widget.data;
    });
  }

  let layoutData = {};
  if (
    populatedWidgetsList ||
    (!allMasterContainersEmpty && allTileWidgetIds.length === 0)
  ) {
    if (allMasterContainersEmpty) {
      const singleContainer = driverLayoutData.center[0];
      layoutData.center = [
        {
          ...singleContainer,
          widgetIds: singleContainer.widgetIds.map((widgetId) => ({
            id: widgetId,
            widgetType: widgetsDataObj[widgetId].widgetType,
          })),
        },
      ];
    } else layoutData = driverLayoutData;
  }

  if (
    populatedWidgetsList ||
    (!allMasterContainersEmpty && allTileWidgetIds.length === 0)
  )
    return (
      <Layout
        key={uuidv4()}
        dashboardId={id}
        populatedLayoutData={layoutData}
        widgetsDataObj={widgetsDataObj}
        handleBreadcrumbs={handleBreadcrumbs}
        isAdmin={isAdmin}
        username={username}
        popupMode={popupMode}
      />
    );
  else if (dashboardLayoutQuery.isError) {
    if (dashboardLayoutQuery.error === "FETCH_ERROR")
      console.log(dashboardLayoutQuery.error);
    else
      console.log(
        `Request failed with status code ${dashboardLayoutQuery.error}`,
      );
    return null;
  }
  return (
    <MDBox display="flex" justifyContent="center" mt="25%">
      <CircularProgress disableShrink color="info" />
    </MDBox>
  );
}

export default Dashboard;
