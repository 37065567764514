import { useState } from "react";
import MDTypography from "components/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import CTLNotification from "application/components/CTLNotification";
import variables from "globals/variables";
import axios from "axios";
function VoicePickerFileDownload({ row }) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const filename = row.original.ccRecordFilename;
  let str = filename;
  let index = str.lastIndexOf("/");
  let extracted = str.slice(index + 1, str.length)

  const operationURLSS = {
    fileDownload: `${variables.app.services}auth/downloadMp3?path=${filename}`,
  }

  // Download Voice Picker MP3 File
  function onDownloadFile() {
    axios
      .get(operationURLSS.fileDownload, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const blobData = new Blob([response.data]);
          const url = window.URL.createObjectURL(blobData);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${extracted}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setNotify({
            isOpen: true,
            message: 'MP3 File Download Successful',
            type: 'success',
          });
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setNotify({
          isOpen: true,
          message: 'Unable To Download MP3 File',
          type: 'error',
          pagename: 'voice picker',
          status: error.response ? error.response.status : null,
        });
      });
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      <Tooltip title="DownloadFile">
        <IconButton color="error" onClick={() => onDownloadFile()}>
          <DownloadingIcon></DownloadingIcon>
        </IconButton>
      </Tooltip>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDTypography>
  );
}

export default VoicePickerFileDownload;



