import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import { Dialog, DialogActions, DialogTitle, } from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";

function FaxDetails({
  onLoad,
  pageName,
  pageId,
  searchText,
  registerCallBacks
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, darkMode } = controller;
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowsRef = useRef([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        onMultipleDelete: multipleDelete,
      });
  }, [activePage]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setUpdateList(Date.now());
    setOpen(false);
  };


  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }


  const onSelectionChange = (rowId) => {
    setSelectedRows(rowId);
  };

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  // for delete record 
  const Delete = () => {
    const requestData = { intId: selectedRowsRef.current, };  
    setUpdateList(Date.now());
    application
      .post(operationURLS.delete, requestData)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
        setSelectedRows([]);
        selectedRowsRef.current = [];
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.data.status,
        });
      });
  }
  const onAgree = () => {
    Delete();
    setOpen(false);
  };


  const multipleDelete = () => {
    if (
      selectedRowsRef.current != null &&
      selectedRowsRef.current.length !== 0
    ) {
      handleClickOpen();
    } else {
      setNotify({
        isOpen: true,
        message: "Error",
        type: "error",
        pagename: pageName,
        status: "Select at least one Fax to delete",
      });
    }
  };
  const onDelete = (row) => {
    selectedRowsRef.current = [row.id];
    Delete();
  };
  return (
    <MDBox>
      <DeleteConfirmation
        entityName="Fax-Details"
        data={selectedRow}
        columns={getColumns(onDelete)}
      />

      <CTLTable
        onSelectionChange={onSelectionChange}
        apiURL={operationURLS.list}
        columns={getColumns(onDelete)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="desc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
      />

      <CTLAdvancedFilter
        entityName="Fax-Details"
        columns={getColumns(onDelete)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />

      <Dialog open={open} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Are you sure you want to delete the selected Fax-Details
        </DialogTitle>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClickClose} color="secondary">
            Disagree
          </MDButton>
          <MDButton onClick={onAgree} color="error" autoFocus>
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default FaxDetails;
