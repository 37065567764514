import { useState } from "react";
import { v4 as uuidV4 } from "uuid";
import Breadcrumbs from "../Breadcrumbs";
import Dashboard from "../dashboard";

function Wrapper({ id, isAdmin, username, dashboardType, popupMode, isSensorsDashboard, sensorsWidgetList }) {
  const [breadcrumbsList, setBreadcrumbsList] = useState([
    { id, dashboardType },
  ]);

  const currentDashboard = breadcrumbsList[breadcrumbsList.length - 1];

  const handleBreadcrumbs = (targetWidgetId, targetDashboardType) =>
    setBreadcrumbsList((prevBreadcrumbsList) => [
      ...prevBreadcrumbsList,
      { id: targetWidgetId, dashboardType: targetDashboardType },
    ]);

  return (
    <>
      {!popupMode && (
        <Breadcrumbs
          icon="home"
          title={currentDashboard.dashboardType}
          route={breadcrumbsList}
          setBreadcrumbsList={setBreadcrumbsList}
        />
      )}
      <Dashboard
        key={uuidV4()}
        id={currentDashboard.id}
        isAdmin={isAdmin}
        username={username}
        popupMode={popupMode}
        handleBreadcrumbs={handleBreadcrumbs}
        isSensorsDashboard={isSensorsDashboard}
        sensorsWidgetList={sensorsWidgetList}
      />
    </>
  );
}

export default Wrapper;
