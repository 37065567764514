import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MapImage from "../MapImage";
import AssignLocationPopup from "../AssignLocationPopup";
import Location from "../Location";
import Label from "../Label";
import {
  useGetLocationsQuery,
  useSaveLocationsMutation,
} from "features/apiSlice";
import { SMART_TOPOLOGY_MARKER_SIZE } from "../../../configs/smartTopologyConfigs";

const DEFAULT_BUFFER_LEFT = 8;
const DEFAULT_BUFFER_TOP = 7;

function RenderSmartTopology({
  isAdmin,
  isEditMode,
  data,
  descendantsData,
  categoryData,
  severityData,
  threshold,
  setIsSaving,
  smartTopologyQuery,
  parentRefCurrent,
  handleParentRefCurrent,
  imageName,
  bufferLeft = DEFAULT_BUFFER_LEFT,
  bufferTop = DEFAULT_BUFFER_TOP,
}) {
  const [droppedLocations, setDroppedLocations] = useState([]);
  const [undroppedLocations, setUndroppedLocations] = useState([]);
  const [assignLocationPopupOpen, setAssignLocationPopupOpen] = useState(false);
  const [currentLocationCoordinates, setCurrentLocationCoordinates] =
    useState(null);
  const [saveLocations] = useSaveLocationsMutation();

  const locationListQuery = useGetLocationsQuery(process.env.REACT_APP_LOCATIONS_LIST_ENDPOINT);

  const markerSize = SMART_TOPOLOGY_MARKER_SIZE;

  useEffect(() => {
    if (locationListQuery.data && parentRefCurrent) {
      setDroppedLocations(
        locationListQuery.data
          .filter(
            (location) =>
              location.xcoordinate !== "" &&
              location.ycoordinate !== "" &&
              location.configuration !== "" &&
              location.configuration !== null,
            location.configuration !== "null",
          )
          .map((location) => {
            const configuration = JSON.parse(location.configuration);

            return {
              ...location,
              xcoordinate:
                (parseFloat(location.xcoordinate) *
                  parentRefCurrent.offsetWidth) /
                100,
              ycoordinate:
                (parseFloat(location.ycoordinate) *
                  parentRefCurrent.offsetHeight) /
                100,
              configuration: {
                ...configuration,
                labelX:
                  (configuration.labelX * parentRefCurrent.offsetWidth) / 100,
                labelY:
                  (configuration.labelY * parentRefCurrent.offsetHeight) / 100,
              },
            };
          }),
      );
      setUndroppedLocations(
        locationListQuery.data.filter(
          (location) =>
            location.xcoordinate === "" || location.ycoordinate === "",
        ),
      );
    }
  }, [locationListQuery.data, parentRefCurrent]);

  const handleSaveConfiguration = (newLocation) => {
    const locationXPercentage =
      (newLocation.xcoordinate * 100) / parentRefCurrent.offsetWidth;
    const locationYPercentage =
      (newLocation.ycoordinate * 100) / parentRefCurrent.offsetHeight;
    const labelXPercentage =
      (newLocation.configuration.labelX * 100) / parentRefCurrent.offsetWidth;
    const labelYPercentage =
      (newLocation.configuration.labelY * 100) / parentRefCurrent.offsetHeight;

    if (
      locationXPercentage < 0 ||
      locationXPercentage > 100 ||
      locationYPercentage < 0 ||
      locationYPercentage > 100 ||
      labelXPercentage < 0 ||
      labelXPercentage > 100 ||
      labelYPercentage < 0 ||
      labelYPercentage > 100
    ) {
      alert("Invalid location coordinates");
      return;
    }

    setIsSaving(true);
    saveLocations({
      id: newLocation.id,
      xcoordinate: newLocation.xcoordinate !== "" ? locationXPercentage : "",
      ycoordinate: newLocation.ycoordinate !== "" ? locationYPercentage : "",
      configuration:
        newLocation.configuration !== "" &&
        newLocation.configuration !== null &&
        newLocation.configuration !== "null"
          ? JSON.stringify({
              ...newLocation.configuration,
              labelX: labelXPercentage,
              labelY: labelYPercentage,
            })
          : "",
    })
      .unwrap()
      .then(() => {
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
        console.log(err);
      });
  };

  const handleAssignLocationPopupOpen = (top, left) => {
    setCurrentLocationCoordinates({
      locationX: left,
      locationY: top,
    });
    setAssignLocationPopupOpen(true);
  };

  const handleAssignLocationPopupClose = (droppedLocation) => {
    const newLocation = {
      ...droppedLocation,
      xcoordinate:
        parseFloat(currentLocationCoordinates.locationX.toFixed(2)) +
        markerSize / 2,
      ycoordinate:
        parseFloat(currentLocationCoordinates.locationY.toFixed(2)) +
        markerSize / 2,
      configuration: {
        labelX: parseFloat(currentLocationCoordinates.locationX.toFixed(2)),
        labelY:
          parseFloat(currentLocationCoordinates.locationY.toFixed(2)) + 25,
        labelText: droppedLocation.name || droppedLocation.code || "Add label",
        labelOrientation: "horizontal",
        labelFontSize: 7,
      },
    };
    setUndroppedLocations((prevUndroppedLocations) =>
      prevUndroppedLocations.filter(
        (location) => location.uuid !== droppedLocation.uuid,
      ),
    );
    setDroppedLocations((prevDroppedLocations) => [
      ...prevDroppedLocations,
      newLocation,
    ]);
    setCurrentLocationCoordinates(null);
    setAssignLocationPopupOpen(false);
    handleSaveConfiguration(newLocation);
  };

  const handleLabelDragStop = (lastX, lastY, labelAssociatedLocation) => {
    const newLocation = {
      ...labelAssociatedLocation,
      configuration: {
        ...labelAssociatedLocation.configuration,
        labelX: lastX,
        labelY: lastY,
      },
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.map((location) =>
        location.uuid === labelAssociatedLocation.uuid ? newLocation : location,
      ),
    );
    handleSaveConfiguration(newLocation);
  };

  const handleLocationDragStop = (lastX, lastY, draggedLocation) => {
    const newLocation = {
      ...draggedLocation,
      xcoordinate: lastX + markerSize / 2,
      ycoordinate: lastY + markerSize / 2,
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.map((location) =>
        location.uuid === draggedLocation.uuid ? newLocation : location,
      ),
    );
    handleSaveConfiguration(newLocation);
  };

  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    // Load the image
    const image = new Image();
    image.src = `/images/widgets/${imageName}`;

    // Once the image is loaded, calculate the aspect ratio
    image.onload = () => {
      const { width, height } = image;
      const aspectRatioValue = width / height;
      setAspectRatio(aspectRatioValue);
    };
  }, []);

  return (
    aspectRatio && (
      <MDBox
        position="relative"
        height={
          parentRefCurrent ? parentRefCurrent.offsetWidth / aspectRatio : "0px"
        }
      >
        <MapImage
          isAdmin={isAdmin}
          isEditMode={isEditMode}
          handleAssignLocationPopupOpen={handleAssignLocationPopupOpen}
          bufferLeft={bufferLeft}
          bufferTop={bufferTop}
          handleParentRefCurrent={handleParentRefCurrent}
          imageSrc={`/images/widgets/${imageName}`}
        />
        <AssignLocationPopup
          undroppedLocations={undroppedLocations}
          assignLocationPopupOpen={assignLocationPopupOpen}
          setAssignLocationPopupOpen={setAssignLocationPopupOpen}
          handleAssignLocationPopupClose={handleAssignLocationPopupClose}
          setCurrentLocationCoordinates={setCurrentLocationCoordinates}
        />
        {droppedLocations.map((location) => (
          <Location
            key={location.uuid}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            location={location}
            descendantsData={descendantsData}
            categoryData={categoryData}
            severityData={severityData}
            data={data}
            threshold={threshold}
            handleLocationDragStop={handleLocationDragStop}
            setDroppedLocations={setDroppedLocations}
            setUndroppedLocations={setUndroppedLocations}
            handleSaveConfiguration={handleSaveConfiguration}
            smartTopologyQuery={smartTopologyQuery}
          />
        ))}
        {droppedLocations.map((location) => (
          <Label
            key={location.uuid}
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            location={location}
            handleLabelDragStop={handleLabelDragStop}
          />
        ))}
      </MDBox>
    )
  );
}

export default RenderSmartTopology;
