import { Settings } from "@material-ui/icons";
import { PunchClockOutlined, TryOutlined, Webhook } from "@mui/icons-material";
import ApiIcon from "@mui/icons-material/Api";

  
export default [
  {
    id: 0,
    name: "Generals",
    icon: <Settings></Settings>,
  },
  {
    id: 1,
    name: "Input Configuration",
    icon: <PunchClockOutlined></PunchClockOutlined>,
  },
  {
    id: 2,
    name: "Input Retry",
    icon: <TryOutlined></TryOutlined>,
  },
  {
    id: 3,
    name: "API Response",
    icon: <ApiIcon></ApiIcon>,
  },
  {
    id: 4,
    name: "API (Override Default)",
    icon: <Webhook></Webhook>,
  },
];