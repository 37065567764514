const multiseriesData = {
  label: [
    "1:00",
    "2:00",
    "3:00",
    "4:00",
    "5:00",
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
  ],
  series: [
    {
      seriesName: "CPU Load %",
      seriesType: "line-chart",
      values: ["91", "16", "52", "10", "1", "98", "53", "90", "74", "85"],
    },
    {
      seriesName: "Memory consumption %",
      seriesType: "line-chart",
      values: ["75", "27", "38", "80", "86", "89", "51", "94", "88", "34"],
    },
    {
      seriesName: "Process Count",
      seriesType: "bar",
      values: ["57", "6", "75", "3", "13", "58", "84", "55", "56", "43"],
    },
  ],
};

export default multiseriesData;
