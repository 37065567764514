import { forwardRef, useState } from "react";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function AssignLocationPopup({
  undroppedLocations,
  assignLocationPopupOpen,
  setAssignLocationPopupOpen,
  handleAssignLocationPopupClose,
  setCurrentLocationCoordinates,
}) {
  const initialChosenLocation = {
    uuid: null,
    name: null,
    code: null,
  };
  const [chosenLocation, setChosenLocation] = useState(initialChosenLocation);
  const { darkMode } = useMaterialUIController()[0];

  const handleLocationChange = (event, selectedOption) =>
    setChosenLocation(selectedOption);

  return (
    <Dialog
      open={assignLocationPopupOpen}
      TransitionComponent={Transition}
      onClose={() => {
        setCurrentLocationCoordinates(null);
        setAssignLocationPopupOpen(false);
      }}
      fullWidth
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.card
            : theme.palette.white.main,
        })}
      >
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionLabel={(option) => `${option.code}/${option.name}`}
          autoComplete
          filterSelectedOptions
          onChange={handleLocationChange}
          options={undroppedLocations.map((location) => ({
            uuid: location.uuid,
            name: location.name,
            code: location.code,
          }))}
          renderTags={() => (
            <Chip
              variant="outlined"
              label={`${chosenLocation.option.label}-${chosenLocation.option.value}`}
            />
          )}
          renderInput={(params) => (
            <TextField
              color={darkMode ? "white" : "black"}
              sx={(theme) => ({
                marginTop: ".5em",
                backgroundColor: darkMode
                  ? theme.palette.dark.main
                  : theme.palette.white.main,
                "& input, & input::placeholder": {
                  color: darkMode ? theme.palette.text.main : "",
                },
              })}
              {...params}
              variant="filled"
              placeholder="Please select a location"
            />
          )}
        />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.card
            : theme.palette.white.main,
        })}
      >
        <MDButton
          disabled={
            !chosenLocation ||
            !chosenLocation.uuid ||
            !chosenLocation.code ||
            !chosenLocation.name
          }
          variant="gradient"
          color="success"
          onClick={() =>
            handleAssignLocationPopupClose(
              undroppedLocations.filter(
                (location) => location.uuid === chosenLocation.uuid,
              )[0],
            )
          }
        >
          Assign
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AssignLocationPopup;
