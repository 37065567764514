import React from "react";
import MDTypography from "components/MDTypography";

export default function CTLVoicemailDateCell({ value }) {
  let d = new Date(0);
  d.setUTCSeconds(value);
  let n = new Date();
  n.setTime(d.valueOf() - 60000 * d.getTimezoneOffset());

  if (value === null) {
    return null;
  }
  if (value === 0) {
    return null;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text" fontSize="small">
      {n.toUTCString().replace("GMT", "")}
    </MDTypography>
  );
}

CTLVoicemailDateCell.defaultProps = {
  suffix: "",
};
