const CONTAINER_TYPES = {
  header: "header",
  footer: "footer",
  leftSidebar: "leftSidebar",
  rightSidebar: "rightSidebar",
  innerHeader: "innerHeader",
  innerFooter: "innerFooter",
  innerLeftSidebar: "innerLeftSidebar",
  innerRightSidebar: "innerRightSidebar",
  center: "center",
};

export default CONTAINER_TYPES;
