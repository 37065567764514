import { extensionMenus } from "globals/configuration/extension";

const Attributess = {
  formSize: "xs",
  columns: 1,
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: extensionMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLSS = {
  list: "extensions/list",
  singleUpdate: "extensions/update/assignedUser",
};

const getupdateColumns = (onAssigned) => {
  return [
    
    {
      Header: "Assigned Phone ",
      accessor: "extension",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "extension",
        keyDescription: "extension",
        url: "extensions/listAll/extensionTypePhone",
      },
    },
  
    {
      Header: "Action",
      accessor: "id",
      displayInList: false,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onAssigned={onAssigned}
        />
      ),
    },
  ];
};


export { operationURLSS,getupdateColumns,Attributess };
