import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";
import CopyContent from "application/components/CTLCopyContent";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

const getPAReport = AuthenticationService.allowPAMenu();

const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,
  modes: getPAReport
    ? []
    : reportMenus.map((item) => {
        return {
          id: item.key,
          name: item.name,
          iconName: item.icon,
          url: item.url,
          description: item.description,
        };
      }),
};

const operationURLS = {
  list: "paCDR/list",
  pdfDownload: "/agentSummary/generateAndDownloadPdf",
  csvDownload: "paCDR/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "PA Number",
      accessor: "paNumber",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Participant Number",
      accessor: "participantNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Participant Type",
      accessor: "participantType",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Answer Time",
      accessor: "answerTime",
      displayInList: true,
      allowFilter: false,
      dateTime: true,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "End Time",
      accessor: "endTime",
      displayInList: true,
      allowFilter: false,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      pdf: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Time",
      accessor: "talkTime",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Call Status",
      accessor: "callStatus",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "UUID",
      accessor: "uuid",
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value, row }) => <CopyContent value={value} row={row} />,
    },
    // {
    //   Header: "Play",
    //   accessor: "",
    //   displayInList: true,
    //   Cell: ({ row }) => <PAPlayAudio row={row} />,
    // },
    // {
    //   Header: "Download File",
    //   accessor: "",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   allowInForm: false,
    //   Cell: ({ row, value }) =>
    //     <CTLDownloadFile row={row} value={value} paCdr="paCdr"/>,
    // },
  ];
};

export { getColumns, Attributes, operationURLS };
