import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Legend from "../Legend";
import Dropdown from "../../../Dropdown";
import { GRAPH_TOPOLOGY_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import SelectCategoryFilter from "../SelectCategoryFilter";
import AssetFilter from "../AssetFilter";
import DisplayOptionsLegend from "../DisplayOptionsLegend";
import CytoscapeComponent from "react-cytoscapejs";
import { v4 as uuidv4 } from "uuid";
import ASSETS_CATEGORIES from "../../../configs/assetsConfigs";
import GraphLayoutFilter from "../GraphLayoutFilter";
import { useEffect, useMemo, useState, createElement } from "react";
import cytoscapeGraphDataTransform from "../../../utils/cytoscapeGraphDataTransform";
import DatasetFilter from "../DatasetFilter";
import ShowLegendsSwitch from "../ShowLegendsSwitch";
import ContextMenu from "../ContextMenu";
import PopupTopology from "../PopupTopology";
import PopupLocationAssets from "../PopupLocationAssets";
import getNMSComponent from "globals/nms-components";
import variables from "globals/variables";
import { useMaterialUIController } from "context";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSaveLocationsMutation } from "features/apiSlice";
import MapImage from "../MapImage";

function RenderGraphTopology({
  isEditMode,
  isAdmin,
  primaryLabel,
  secondaryLabel,
  data,
  descendantsData,
  categoryData,
  severityData,
  locationData,
  isDarkMode,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  index,
  error,
  colorFilter,
  handleColorFilterChange,
  asset,
  handleSearchAsset,
  assetCategory,
  handleAssetCategoryChange,
  displayOptions,
  handleDisplayOptionsChange,
  layout,
  handleLayoutChange,
  setLayout,
  topologyQuery,
  locationListQuery,
  handleIsNodeRightClicked,
  isUploading,
  uploadImageFile,
  imageName,
  parentRefCurrent,
  handleParentRefCurrent,
}) {
  const [datasetType, setDatasetType] = useState("location-graph");
  const [transformedData, setTransformedData] = useState(null);
  const [showLegends, setShowLegends] = useState(false);
  const [nodeData, setNodeData] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [popupTopologyOpen, setPopupTopologyOpen] = useState(false);
  const [popupLocationAssetsOpen, setPopupLocationAssetsOpen] = useState(false);
  const [smartAssetPopupOpen, setSmartAssetPopupOpen] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveLocations] = useSaveLocationsMutation();
  const { darkMode } = useMaterialUIController()[0];

  let componentRender = null;
  let contextMenuData = null;
  if (nodeData) {
    componentRender = getNMSComponent(nodeData.category);
    contextMenuData = categoryData.filter(
      (menuData) => menuData.code === nodeData.category,
    )[0];
  }

  const handleContextMenu = (event) => {
    if (!popupTopologyOpen) {
      handleIsNodeRightClicked(true);
      setNodeData(event.target._private.data);
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.originalEvent.clientX + 2,
              mouseY: event.originalEvent.clientY - 6,
            }
          : null,
      );
    }
  };

  const handleClose = () => {
    setContextMenu(null);
    handleIsNodeRightClicked(false);
  };

  const handlePopupTopologyOpen = () => {
    setPopupTopologyOpen(true);
    handleClose();
  };

  const handlePopupTopologyClose = () => setPopupTopologyOpen(false);

  const handleSmartAssetPopupOpen = () => {
    setSmartAssetPopupOpen(true);
    handleClose();
  };
  const handleSmartAssetPopupClose = () => setSmartAssetPopupOpen(false);

  const handleManagementInterface = (managementURL, nodeDatum) => {
    if (managementURL === null) {
      alert("No Management Interface URL found for this asset.");
      handleClose();
      handleEnableGraphTopoDrag();
      return;
    }

    const baseURL = variables.api.telemetry;
    const dollar = "$";
    let URL = null;
    URL = managementURL.replace(`${dollar}{{baseURL}}`, baseURL);
    URL = URL.replace("{id}", nodeDatum.id);
    URL = URL.replace("{{ipAddress}}", nodeDatum.ipAddress);
    URL = URL.replace("{{ipAddres}}", nodeDatum.ipAddress);

    window.open(URL, "_blank");
    handleClose();
    handleEnableGraphTopoDrag();
  };

  const handleDashboard = () => handleSmartAssetPopupOpen();

  const handlePopupLocationAssetsOpen = (event) => {
    setNodeData(event.target._private.data);
    setPopupLocationAssetsOpen(true);
  };

  const handlePopupLocationAssetsClose = () =>
    setPopupLocationAssetsOpen(false);

  const handleShowLegends = () => setShowLegends((prev) => !prev);

  const handleDatasetTypeChange = (event) => {
    if (layout === "preset") {
      setLayout("breadthfirst");
      return;
    }
    setDatasetType(event.target.value);
  };

  useEffect(() => {
    const newTransformedData = cytoscapeGraphDataTransform(
      data,
      datasetType,
      locationData,
    );
    setTransformedData(newTransformedData);
  }, [
    datasetType,
    layout,
    assetCategory,
    asset,
    displayOptions,
    colorFilter,
    topologyQuery.data,
    locationListQuery.data,
  ]);

  useEffect(() => {
    const image = new Image();
    image.src = `/images/widgets/${imageName}`;

    image.onload = () => {
      const { width, height } = image;
      const aspectRatioValue = width / height;
      setAspectRatio(aspectRatioValue);
    };
  }, []);

  const styleSheet = [
    {
      selector: "node",
      style: {
        backgroundColor: (node) => {
          const targetNodeSeverity =
            descendantsData[node._private.data.id].severity || 0;
          const severityObj = severityData.filter(
            (severity) => severity.severityLevel === targetNodeSeverity,
          )[0];

          if (!severityObj)
            console.log(
              `No configuration found for severity level ${targetNodeSeverity}`,
            );

          const severityColor = severityObj.color;

          return (assetCategory === "All Categories" ||
            assetCategory === node._private.data.category) &&
            (node._private.data.name
              .toLowerCase()
              .startsWith(asset.toLowerCase()) ||
              asset === "") &&
            colorFilter[severityColor] &&
            displayOptions[
              node._private.data.category === ASSETS_CATEGORIES.LOCATION
                ? "location"
                : "asset"
            ]
            ? severityColor
            : "lightgrey";
        },
        label: (node) =>
          node._private.data.category === ASSETS_CATEGORIES.LOCATION &&
          displayOptions["locationName"]
            ? node._private.data.name
            : "",
        "border-width": "2px",
        "border-color": "#000",
        color: layout === "preset" ? "#FFF" : darkMode ? "#fff" : "#000",
        width: layout === "preset" && "1em",
        height: layout === "preset" && "1em",
        "text-background-color": "#000",
        "text-background-opacity": layout === "preset" ? "1" : "0",
        "text-background-padding": layout === "preset" && ".2em",
        "text-margin-y": layout === "preset" && "-.5em",
        fontWeight: "bold",
      },
    },
    {
      selector: "edge",
      style: {
        width: 0.6,
        "line-color": darkMode ? "yellow" : "#000",
        "target-arrow-color": darkMode ? "yellow" : "#000",
        "target-arrow-shape": "triangle",
        "curve-style": "bezier",
        opacity: layout === "preset" ? "0" : "1",
      },
    },
  ];

  return (
    <>
      {transformedData && (
        <MDBox
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <MDBox ml={6}>
            <MDTypography variant="h6">{primaryLabel}</MDTypography>
            <MDTypography component="div" variant="button" color="text">
              {secondaryLabel}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDBox display="flex">
              {/* <DatasetFilter
              datasetType={datasetType}
              handleDatasetTypeChange={handleDatasetTypeChange}
              layout={layout}
              transformedData={transformedData}
              isDarkMode={isDarkMode}
            /> */}
              {/* <GraphLayoutFilter
              transformedData={transformedData}
              layout={layout}
              handleLayoutChange={handleLayoutChange}
              isDarkMode={isDarkMode}
            /> */}
              <SelectCategoryFilter
                categories={categoryData.map(
                  (categoryConfig) => categoryConfig.code,
                )}
                assetCategory={assetCategory}
                handleAssetCategoryChange={handleAssetCategoryChange}
                isDarkMode={isDarkMode}
              />
              <AssetFilter
                asset={asset}
                handleSearchAsset={handleSearchAsset}
                level={1}
              />
              {isUploading && (
                <MDBox sx={{ marginLeft: "auto" }}>
                  <CircularProgress color="white" size={20} />
                </MDBox>
              )}
              {error}
              {isEditMode && isAdmin && (
                <Dropdown
                  handleDeleteWidget={handleDeleteWidget}
                  masterContainerId={masterContainerId}
                  cellId={cellId}
                  index={index}
                  options={GRAPH_TOPOLOGY_DROPDOWN_LIST}
                />
              )}
              <DisplayOptionsLegend
                title="Display Options"
                isDarkMode={isDarkMode}
                displayOptions={displayOptions}
                handleDisplayOptionsChange={handleDisplayOptionsChange}
                showLegends={showLegends}
                layout={layout}
                categories={[
                  {
                    optionName: "locationName",
                    name: "Location Name",
                  },
                  {
                    optionName: "location",
                    name: "Location",
                  },
                  {
                    optionName: "asset",
                    name: "Asset",
                  },
                ]}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="end" my={1}>
              {isEditMode && !isUploading && uploadImageFile}
              <ShowLegendsSwitch
                showLegends={showLegends}
                handleShowLegends={handleShowLegends}
                level={1}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      )}
      <MDBox
        position={layout === "preset" && "relative"}
        height={
          layout === "preset"
            ? aspectRatio && parentRefCurrent
              ? parentRefCurrent.offsetWidth / aspectRatio
              : "0px"
            : "100%"
        }
      >
        {layout === "preset" && (
          <MapImage
            handleParentRefCurrent={handleParentRefCurrent}
            imageSrc={`/images/widgets/${imageName}`}
          />
        )}
        <Legend
          title="Severity"
          isDarkMode={isDarkMode}
          colorFilter={colorFilter}
          handleColorFilterChange={handleColorFilterChange}
          level={1}
          showLegends={showLegends}
          layout={layout}
          categories={severityData.map((severity) => ({
            color: severity.color,
            colorName: severity.color,
            name: severity.severityName,
          }))}
        />
        {useMemo(
          () =>
            transformedData &&
            parentRefCurrent &&
            aspectRatio && (
              <CytoscapeComponent
                key={uuidv4()}
                style={{ width: "100%", height: "100%" }}
                elements={CytoscapeComponent.normalizeElements(transformedData)}
                maxZoom={3}
                minZoom={0.5}
                boxSelectionEnabled
                layout={{
                  name: layout,
                  fit: layout !== "preset",
                  animate: false,
                  positions:
                    layout === "preset"
                      ? (node) => {
                          return node._private.position.x === 0 &&
                            node._private.position.y === 0
                            ? {
                                x: parentRefCurrent.offsetWidth / 2,
                                y: parentRefCurrent.offsetHeight / 2,
                              }
                            : {
                                x:
                                  (node._private.position.x *
                                    parentRefCurrent.offsetWidth) /
                                    100 +
                                  parentRefCurrent.offsetWidth / 2,
                                y:
                                  (node._private.position.y *
                                    parentRefCurrent.offsetHeight) /
                                    100 +
                                  parentRefCurrent.offsetHeight / 2,
                              };
                        }
                      : undefined,
                }}
                stylesheet={styleSheet}
                cy={(cy) => {
                  if (layout === "preset") {
                    cy.userZoomingEnabled(false);
                    cy.userPanningEnabled(false);
                    cy.on("tapend", "node", (evt) => {
                      if (!isEditMode) {
                        setTransformedData(transformedData);
                        return;
                      }

                      setIsSaving(true);
                      const node = evt.target;
                      const { x, y } = node._private.position;
                      saveLocations({
                        id: node._private.data.nodeLocationId,
                        xcoordinate:
                          ((x - parentRefCurrent.offsetWidth / 2) * 100) /
                          parentRefCurrent.offsetWidth,
                        ycoordinate:
                          ((y - parentRefCurrent.offsetHeight / 2) * 100) /
                          parentRefCurrent.offsetHeight,
                        configuration: "",
                      })
                        .unwrap()
                        .then(() => {
                          setIsSaving(false);
                        })
                        .catch((err) => {
                          setIsSaving(false);
                          console.log(err);
                        });
                    });
                  }
                  cy.on("cxttap", "node", (evt) => handleContextMenu(evt));

                  cy.on("tap", "node", (evt) =>
                    handlePopupLocationAssetsOpen(evt),
                  );

                  cy.on("mouseover", "node", (event) => {
                    const nodeId = event.target._private.data.id;

                    const tooltipDiv = document.createElement("div");
                    tooltipDiv.className = "cytoscape-tooltip";
                    tooltipDiv.style.position = "absolute";
                    tooltipDiv.style.paddingBlock = "-.2em";
                    tooltipDiv.style.paddingInline = ".3em";
                    tooltipDiv.style.borderRadius = ".2em";
                    tooltipDiv.style.backgroundColor = darkMode
                      ? "#fff"
                      : "#edf6f9";
                    tooltipDiv.style.left = `${
                      event.renderedPosition.x + 310
                    }px`;
                    tooltipDiv.style.top = `${event.renderedPosition.y + 110}px`;

                    const tooltipInactiveDiv = document.createElement("div");
                    tooltipInactiveDiv.style.display = "flex";
                    tooltipInactiveDiv.style.alignItems = "center";

                    const tooltipInactiveDivCircle =
                      document.createElement("div");
                    tooltipInactiveDivCircle.style.width = ".5em";
                    tooltipInactiveDivCircle.style.height = ".5em";
                    tooltipInactiveDivCircle.style.borderRadius = "50%";
                    tooltipInactiveDivCircle.style.backgroundColor = "red";
                    tooltipInactiveDiv.appendChild(tooltipInactiveDivCircle);

                    const tooltipInactiveDivText = document.createElement("p");
                    tooltipInactiveDivText.style.marginLeft = ".5em";
                    tooltipInactiveDivText.style.fontSize = "1rem";
                    tooltipInactiveDivText.style.fontWeight = "800";
                    tooltipInactiveDivText.style.color = "#344767";
                    tooltipInactiveDivText.textContent =
                      descendantsData[nodeId].inactive;
                    tooltipInactiveDiv.appendChild(tooltipInactiveDivText);

                    tooltipDiv.appendChild(tooltipInactiveDiv);

                    const tooltipActiveDiv = document.createElement("div");
                    tooltipActiveDiv.style.display = "flex";
                    tooltipActiveDiv.style.alignItems = "center";
                    tooltipActiveDiv.style.marginTop = "-.4em";

                    const tooltipActiveDivCircle =
                      document.createElement("div");
                    tooltipActiveDivCircle.style.width = ".5em";
                    tooltipActiveDivCircle.style.height = ".5em";
                    tooltipActiveDivCircle.style.borderRadius = "50%";
                    tooltipActiveDivCircle.style.backgroundColor = "green";
                    tooltipActiveDiv.appendChild(tooltipActiveDivCircle);

                    const tooltipActiveDivText = document.createElement("p");
                    tooltipActiveDivText.style.marginLeft = ".5em";
                    tooltipActiveDivText.style.fontSize = "1rem";
                    tooltipActiveDivText.style.fontWeight = "800";
                    tooltipActiveDivText.style.color = "#344767";
                    tooltipActiveDivText.textContent =
                      descendantsData[nodeId].active;
                    tooltipActiveDiv.appendChild(tooltipActiveDivText);

                    tooltipDiv.appendChild(tooltipActiveDiv);

                    document.body.appendChild(tooltipDiv);
                  });

                  cy.on("mouseout", "node", () => {
                    const tooltipDivs =
                      document.getElementsByClassName("cytoscape-tooltip");
                    for (let tooltipDiv of tooltipDivs) {
                      if (tooltipDiv) tooltipDiv.remove();
                    }
                  });
                }}
              />
            ),
          [transformedData, layout, parentRefCurrent, aspectRatio],
        )}
        {nodeData && (
          <>
            <ContextMenu
              contextMenuData={
                categoryData.filter(
                  (menuData) => menuData.code === nodeData.category,
                )[0]
              }
              contextMenu={contextMenu}
              handleClose={handleClose}
              nodeDatum={nodeData}
              handlePopupTopologyOpen={handlePopupTopologyOpen}
              handleManagementInterface={handleManagementInterface}
              handleDashboard={handleDashboard}
            />
            <PopupTopology
              nodeDatum={nodeData}
              categoryData={categoryData}
              descendantsData={descendantsData}
              severityData={severityData}
              popupTopologyOpen={popupTopologyOpen}
              handlePopupTopologyClose={handlePopupTopologyClose}
              isAdmin={isAdmin}
              isEditMode={isEditMode}
            />
            <PopupLocationAssets
              popupLocationAssetsOpen={popupLocationAssetsOpen}
              handlePopupLocationAssetsClose={handlePopupLocationAssetsClose}
              location={nodeData.name}
              descendantsData={descendantsData}
              categoryData={categoryData}
              severityData={severityData}
              isAdmin={isAdmin}
              isEditMode={isEditMode}
            />
          </>
        )}
        {nodeData &&
          smartAssetPopupOpen &&
          createElement(componentRender, {
            open: smartAssetPopupOpen,
            handleClose: handleSmartAssetPopupClose,
            isAdmin: isAdmin,
            isEditMode: isEditMode,
            primaryLabel: `${nodeData.category}/${nodeData.name}`,
            assetId: nodeData.id,
            assetIpAddress: nodeData.ipAddress,
            assetImageName: `/images/${contextMenuData.imageName}`,
            assetImageWidth: contextMenuData.width,
            assetImageHeight: contextMenuData.height,
            category: nodeData.category,
          })}
      </MDBox>
      <Backdrop
        open={isSaving}
        sx={{
          color: "#ccc",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress disableShrink color="white" />
      </Backdrop>
    </>
  );
}

export default RenderGraphTopology;
