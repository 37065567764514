const adminMenus = [
  
  {
    icon: "list",
    key: "pbx.extension",
    name: "Extensions",
    url: "home/app/pbx/extension",
    description: "Subscriber List",
    disabled: true,
  },
  {
    icon: "ondemand_video",
    key: "video.recording",
    name: "Video Recording",
    url: "home/app/video/recording",
    description: "video recording",
    disabled: true,
  },
  {
    icon: "route",
    key: "configuration.routes",
    name: "Routes",
    url: "home/app/configurations/routes",
    description: "Routes",
    disabled: true,
    productionReady: true,
  },
  {
    icon: " wifi_channel",
    key: "configuration.wans",
    name: "Wans",
    url: "home/app/configurations/wans",
    description: "Wans",
    disabled: true,
    productionReady: true,
  },
  {
    icon: "timer",
    key: "configuration.timer",
    name: "Configuration Timer",
    url: "home/app/configurations/timer",
    description: "Configuration Timer",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "account_circle",
    key: "change-Usert-Profile",
    name: "Change-User-Profile",
    url: "home/app/configurations/changeAgentProfile",
    description: "Change User Profile",
    productionReady: true,
    disabled: true,
  },
  {
    icon: "person",
    key: "configuration.UserZoneConfiguration",
    name: "User Zone Configuration",
    url: "home/app/configurations/UserZoneConfiguration",
    description: "User-Zone",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "map",
    key: "nms.maps",
    name: "Suraksha",
    url: "home/app/nms/map",
    description: "MAP",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "call",
    key: "uc.External.api.call.log",
    name: "External API call log",
    description: "external api call log",
    url: "home/app/uc/External-API-call-log",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "settings",
    key: "default.extension.services",
    name: "Default extension services",
    description: "Default extension services",
    url: "home/app/default/extension/services",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "settings",
    key: "configuration.priconfiguration",
    name: "PRI Configuration",
    description: "pri_configuration",
    url: "home/app/configurations/priconfiguration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "compare",
    key: "configuration.comparison",
    name: "Comparison Page ",
    description: "Default Configuration Comparison Page ",
    url: "home/app/configurations/comparison",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "phone",
    key: "configuration.siptrunk",
    name: "Sip Trunk",
    description: "Default Sip Trunk",
    url: "home/app/configurations/siptrunk",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "hub",
    key: "configuration.system-configuration",
    name: "System-Configuration",
    url: "home/app/configurations/system-configuration",
    description: "System-Configuration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "map",
    key: "user.zone",
    name: "User Zone",
    url: "home/app/user-zone",
    description: "User-Zone",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "person",
    key: "userStatus",
    name: "User Status",
    url: "home/app/user-status",
    description: "User-Status",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "security",
    key: "security.configuration",
    name: "Security-Configuration",
    url: "home/app/securityConfiguration",
    description: "Security Configuration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "upload_file",
    key: "configuration.JSONFileCreation",
    name: "JSON File Creation",
    url: "home/app/configurations/JSONFileCreation",
    description: "JSON File Creation",
    productionReady: false,
    disabled: false,
  },
  // {
  //   icon: "admin",
  //   key: "Campaign",
  //   name: "Campaign",
  //   url: "home/app/Campaign",
  //   description: "Campaign",
  //   productionReady: true,
  // },
];

export { adminMenus };
