// const timeseriesData = [
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3900",
//     eventEpoch: 1691418540000,
//     receivedEpoch: 1691418540000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3500",
//     eventEpoch: 1691418300000,
//     receivedEpoch: 1691418300000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3500",
//     eventEpoch: 1691417520000,
//     receivedEpoch: 1691417520000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3500",
//     eventEpoch: 1691416260000,
//     receivedEpoch: 1691416260000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3400",
//     eventEpoch: 1691416140000,
//     receivedEpoch: 1691416140000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3500",
//     eventEpoch: 1691415900000,
//     receivedEpoch: 1691415900000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3400",
//     eventEpoch: 1691415720000,
//     receivedEpoch: 1691415720000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691415420000,
//     receivedEpoch: 1691415420000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691415000000,
//     receivedEpoch: 1691415000000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0100",
//     eventEpoch: 1691414820000,
//     receivedEpoch: 1691414820000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0100",
//     eventEpoch: 1691414640000,
//     receivedEpoch: 1691414640000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0100",
//     eventEpoch: 1691414400000,
//     receivedEpoch: 1691414400000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691414100000,
//     receivedEpoch: 1691414100000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691413980000,
//     receivedEpoch: 1691413980000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691413500000,
//     receivedEpoch: 1691413500000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691413320000,
//     receivedEpoch: 1691413320000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691413200000,
//     receivedEpoch: 1691413200000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691413080000,
//     receivedEpoch: 1691413080000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691412360000,
//     receivedEpoch: 1691412360000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691412300000,
//     receivedEpoch: 1691412300000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2800",
//     eventEpoch: 1691412120000,
//     receivedEpoch: 1691412120000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691411580000,
//     receivedEpoch: 1691411580000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691411400000,
//     receivedEpoch: 1691411400000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691411340000,
//     receivedEpoch: 1691411340000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691411160000,
//     receivedEpoch: 1691411160000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691410980000,
//     receivedEpoch: 1691410980000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1900",
//     eventEpoch: 1691410620000,
//     receivedEpoch: 1691410620000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691410500000,
//     receivedEpoch: 1691410500000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691410440000,
//     receivedEpoch: 1691410440000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691410260000,
//     receivedEpoch: 1691410260000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691409840000,
//     receivedEpoch: 1691409840000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691409660000,
//     receivedEpoch: 1691409660000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691409600000,
//     receivedEpoch: 1691409600000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691409420000,
//     receivedEpoch: 1691409420000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691409300000,
//     receivedEpoch: 1691409300000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3400",
//     eventEpoch: 1691408880000,
//     receivedEpoch: 1691408880000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3400",
//     eventEpoch: 1691408820000,
//     receivedEpoch: 1691408820000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691408760000,
//     receivedEpoch: 1691408760000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3400",
//     eventEpoch: 1691408400000,
//     receivedEpoch: 1691408400000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691408340000,
//     receivedEpoch: 1691408340000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691408040000,
//     receivedEpoch: 1691408040000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691407800000,
//     receivedEpoch: 1691407800000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3000",
//     eventEpoch: 1691407740000,
//     receivedEpoch: 1691407740000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3600",
//     eventEpoch: 1691407560000,
//     receivedEpoch: 1691407560000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691407380000,
//     receivedEpoch: 1691407380000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691407260000,
//     receivedEpoch: 1691407260000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691407020000,
//     receivedEpoch: 1691407020000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691406780000,
//     receivedEpoch: 1691406780000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2500",
//     eventEpoch: 1691406600000,
//     receivedEpoch: 1691406600000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2500",
//     eventEpoch: 1691406540000,
//     receivedEpoch: 1691406540000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2600",
//     eventEpoch: 1691406480000,
//     receivedEpoch: 1691406480000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0000",
//     eventEpoch: 1691406060000,
//     receivedEpoch: 1691406060000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0000",
//     eventEpoch: 1691405820000,
//     receivedEpoch: 1691405820000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691405160000,
//     receivedEpoch: 1691405160000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0000",
//     eventEpoch: 1691404980000,
//     receivedEpoch: 1691404980000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691404920000,
//     receivedEpoch: 1691404920000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.3330",
//     eventEpoch: 1691404860000,
//     receivedEpoch: 1691404860000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691404800000,
//     receivedEpoch: 1691404800000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1690",
//     eventEpoch: 1691404500000,
//     receivedEpoch: 1691404500000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0000",
//     eventEpoch: 1691404440000,
//     receivedEpoch: 1691404440000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "145.6810",
//     eventEpoch: 1691404320000,
//     receivedEpoch: 1691404320000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691404260000,
//     receivedEpoch: 1691404260000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691404200000,
//     receivedEpoch: 1691404200000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1800",
//     eventEpoch: 1691404140000,
//     receivedEpoch: 1691404140000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1750",
//     eventEpoch: 1691404080000,
//     receivedEpoch: 1691404080000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1550",
//     eventEpoch: 1691404020000,
//     receivedEpoch: 1691404020000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691403960000,
//     receivedEpoch: 1691403960000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2050",
//     eventEpoch: 1691403900000,
//     receivedEpoch: 1691403900000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1000",
//     eventEpoch: 1691403840000,
//     receivedEpoch: 1691403840000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1600",
//     eventEpoch: 1691403780000,
//     receivedEpoch: 1691403780000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1100",
//     eventEpoch: 1691403720000,
//     receivedEpoch: 1691403720000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0900",
//     eventEpoch: 1691403660000,
//     receivedEpoch: 1691403660000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0100",
//     eventEpoch: 1691403600000,
//     receivedEpoch: 1691403600000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0400",
//     eventEpoch: 1691403540000,
//     receivedEpoch: 1691403540000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0400",
//     eventEpoch: 1691403480000,
//     receivedEpoch: 1691403480000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0500",
//     eventEpoch: 1691403420000,
//     receivedEpoch: 1691403420000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0250",
//     eventEpoch: 1691403360000,
//     receivedEpoch: 1691403360000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0400",
//     eventEpoch: 1691403300000,
//     receivedEpoch: 1691403300000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1550",
//     eventEpoch: 1691403240000,
//     receivedEpoch: 1691403240000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1750",
//     eventEpoch: 1691403180000,
//     receivedEpoch: 1691403180000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2000",
//     eventEpoch: 1691403120000,
//     receivedEpoch: 1691403120000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1900",
//     eventEpoch: 1691403060000,
//     receivedEpoch: 1691403060000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1700",
//     eventEpoch: 1691403000000,
//     receivedEpoch: 1691403000000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1300",
//     eventEpoch: 1691402940000,
//     receivedEpoch: 1691402940000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1400",
//     eventEpoch: 1691402880000,
//     receivedEpoch: 1691402880000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1550",
//     eventEpoch: 1691402820000,
//     receivedEpoch: 1691402820000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1310",
//     eventEpoch: 1691402760000,
//     receivedEpoch: 1691402760000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1300",
//     eventEpoch: 1691402700000,
//     receivedEpoch: 1691402700000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0900",
//     eventEpoch: 1691402640000,
//     receivedEpoch: 1691402640000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.0800",
//     eventEpoch: 1691402580000,
//     receivedEpoch: 1691402580000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1000",
//     eventEpoch: 1691402520000,
//     receivedEpoch: 1691402520000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1000",
//     eventEpoch: 1691402460000,
//     receivedEpoch: 1691402460000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1250",
//     eventEpoch: 1691402400000,
//     receivedEpoch: 1691402400000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1550",
//     eventEpoch: 1691402340000,
//     receivedEpoch: 1691402340000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1700",
//     eventEpoch: 1691402280000,
//     receivedEpoch: 1691402280000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1700",
//     eventEpoch: 1691402220000,
//     receivedEpoch: 1691402220000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1900",
//     eventEpoch: 1691402160000,
//     receivedEpoch: 1691402160000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1900",
//     eventEpoch: 1691402100000,
//     receivedEpoch: 1691402100000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.1900",
//     eventEpoch: 1691402040000,
//     receivedEpoch: 1691402040000,
//   },
//   {
//     attributeName: "timeseries",
//     attributeValue: "146.2500",
//     eventEpoch: 1691401980000,
//     receivedEpoch: 1691401980000,
//   },
// ];

// export default timeseriesData;

const data = [
  {
    attributeName: "timeseries",
    attributeValue: ["146.3900", "201.432"],
    eventEpoch: 1691418540000,
    receivedEpoch: 1691418540000,
  },
  {
    attributeName: "timeseries",
    attributeValue: ["146.3500", "0"],
    eventEpoch: 1691418300000,
    receivedEpoch: 1691418300000,
  },
  {
    attributeName: "timeseries",
    attributeValue: ["146.3500", "234"],
    eventEpoch: 1691417520000,
    receivedEpoch: 1691417520000,
  },
  {
    attributeName: "timeseries",
    attributeValue: ["146.3500", "280.324"],
    eventEpoch: 1691416260000,
    receivedEpoch: 1691416260000,
  },
  {
    attributeName: "timeseries",
    attributeValue: ["146.3400", "0"],
    eventEpoch: 1691416140000,
    receivedEpoch: 1691416140000,
  },
];

export default data;