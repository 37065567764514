const nmsMenus = [
  {
    icon: "category",
    key: "category",
    name: "Category",
    description: "Category",
    url: "home/app/Category",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "navigation",
    key: "configuration.SeverityMaster",
    name: "Severity Master",
    description: "Severity Master",
    url: "home/app/severityMaster",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "navigation",
    key: "Location",
    name: "Location",
    description: "Location",
    url: "home/app/Location",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "menu",
    key: "Asset",
    name: "Asset",
    description: "Assest",
    url: "home/app/Asset",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "dns",
    key: "configuration.subnet-master",
    name: "Discover-Subnet-Master",
    url: "home/app/configurations/subnet-master",
    description: "Subnet-Master",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "phone",
    key: "configuration.telephonyDeployment",
    name: "Telephony-Deployment",
    description: "Telephony-Deployment",
    url: "home/app/configuration/telephonyDeployment",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "my_location",
    key: "nms.userLocation",
    name: "User Location",
    url: "home/app/configurations/user-location",
    description: "User Location",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "my_location",
    key: "locationType",
    name: "Location Type",
    url: "home/app/configurations/locationType",
    description: "Location Type",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "ping",
    key: "Service.Master",
    name: "Service Master",
    description: "Service Master",
    url: "home/app/ServiceMaster",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "category",
    key: "Category.Service",
    name: "Category Service",
    description: "Category Service",
    url: "home/app/CategoryService",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "monitor",
    key: "configuration.monitorService",
    name: "Monitor-Service",
    description: "Monitor-Service",
    url: "home/app/configuration/monitorService",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "crisis_alert",
    key: "configuration.nms-alert-history",
    name: "Nms-Alert-History",
    url: "home/app/configurations/nms-alert-history",
    description: "Nms-Alert-History",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "monitor",
    key: "configuration.SystemEmailConfiguration",
    name: "SystemEmailConfiguration",
    description: "System-Email-Configuration",
    url: "home/app/configuration/systemEmailConfiguration",
    disabled: false,
    productionReady: true,
  },
  {
    icon: "email",
    key: "configuration.email-body-configuration",
    name: "Email-Body-Configuration",
    url: "home/app/configurations/email-body-configuration",
    description: "Email-Body-Configuration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "dashboard",
    key: "configuration.WidgetMenuGroup",
    name: "WidgetMenuGroup",
    description: "WidgetMenuGroup",
    url: "home/app/configuration/WidgetMenuGroup",
    disabled: false,
  },
  {
    icon: "dashboard",
    key: "configuration.dashboard",
    name: "Nms-Dashboard",
    url: "home/app/configurations/dashboard",
    description: "Nms-Dashboard",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "sms",
    key: "configuration.system-sms-configuration",
    name: "System-SMS-Configuration",
    url: "home/app/configurations/system-sms-configuration",
    description: "System-SMS-Configuration",
    productionReady: true,
    disabled: false,
  },
  {
    icon: "list_icon",
    name: "User-Defined-Widget",
    description: "User-Defined-Widget",
    productionReady: true,
    submenu: true,
    disabled: false,
  },
  {
    icon: "dns_icon",
    key: "snmp.rules",
    name: "SNMP-Rules",
    description: "SNMP Rules",
    url: "home/app/snmp-rules",
    disabled: false,
  },
  {
    icon: "show_chart_icon",
    key: "widgetConfiguration",
    name: "Widget Configuration",
    description: "Widget Configuration",
    url: "home/app/widgetConfiguration",
    disabled: false,
    productionReady: true,
    disabled: false,
  },
  {
    icon: "on_device_training_icon",
    key: "deviceConfiguration",
    name: "Device Configuration",
    description: "Device Configuration",
    url: "home/app/device-configuration",
    disabled: false,
    productionReady: true,
  },
];

export { nmsMenus };
