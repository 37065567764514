import CTLTexanomyCell from "application/components/CTLTexanomyCell";
import CTLUserStatusCell from "application/components/CTLUserStatusCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  formSize: "md",
  enableAddButton: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "taxonomy/list",
  update: "taxonomy/update",
  create: "taxonomy/create",
  delete: "taxonomy/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "code",
        keyDescription: "description",
        values: [],
        url: "taxonomyCategory/listAll",
      },
      Cell: ({ value }) => <CTLTexanomyCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
