import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "lg",
  columns: 1,
  enableAddButton: false,
  enablePublishButton: false,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: true,
};
const operationURLS = {
  list: "/passwordConfig/listAll",
  update: "/passwordConfig/create",
};
const getColumns = () => {
  return [
    {
      Header: "Enforce Single Login ",
      accessor: "singleLogin",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "boolean",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use this configure for Enforce Single Login",
    },
    {
      Header: "Set Default Password ",
      accessor: "defaultPwd",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "boolean",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use Set Default Password configure for Set The Default Password ",
    },
    {
      Header: "Set Password Complexity",
      accessor: "pwdComplication",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "boolean",
      controlType: "boolean",
      Cell: ({ value }) => <DefaultCell value={value} />,
      detailedExplanation:
        "Use Set Password Complexity configure for Set The Complexity Password",
    },
    {
      Header: "Max Login Attempt",
      accessor: "maxAttempt",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use Max Login Attempt configure  for User Limited Login Attempt   ",
    },
    {
      Header: "Password Changed History ",
      accessor: "pwdChangeHistory",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use this Password Changed History  configure for collect the change Password History ",
    },
    {
      Header: "Accept Password Change",
      accessor: "acceptLastPassword",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use this Accept Password Change to configure for  user change the password ",
    },
    {
      Header: "Last Login Attempt",
      accessor: "lastLogin",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use this  configuration  to User Last Login Attemp ",
      dataUnit: "Day",
    },
    {
      Header: "Credentials Expires",
      accessor: "expiredCredential",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use this configuration for User Credentials Expires",
      dataUnit: "Day",
    },
    {
      Header: "Notice of Password Change",
      accessor: "pwdChangeNotice",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use this configuration to  Notice of Password Change",
      dataUnit: "Day",
    },
    {
      Header: "Ideal Timeout Exit ",
      accessor: "idealTimeout",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      displayInDeleteConfirmation: true,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
      detailedExplanation:
        "Use this configure Ideal Timeout Exit",
      dataUnit: "Minuts",
    },
  ];
};

export { getColumns, Attributes, operationURLS };
