import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormField from "layouts/applications/wizard/components/FormField";

function CTLPassword({ label, name, value, onChange, error, success , disabled  }) {
  const [showPassword, setShowPassword] = useState(false);

  // Visibility &  VisibilityOff Password
  const handleShowPassword = () => {
    setShowPassword(true);
  };

  const handleHidePassword = () => {
    setShowPassword(false);
  };

  return (
    <>
      <FormField
        fullWidth
        type={showPassword ? "text" : "password"}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        success={success}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onMouseDown={handleShowPassword}
                onMouseUp={handleHidePassword}
                edge="end"
                style={{ margin: "1px" }}
                color="info"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default CTLPassword;
