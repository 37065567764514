import DefaultCell from "application/components/DefaultCell";

const TableDefinition = {
  columns: [
    { Header: "S. No",accessor: "id", Cell: ({ value }) => <DefaultCell value={value} />},
    { Header: "Received On",accessor: "date", Cell: ({ value }) => <DefaultCell value={value} />},
    { Header: "Uploaded By",accessor: "uploadedBy", Cell: ({ value }) => <DefaultCell value={value} />},
    { Header: "File Name",accessor: "fileName", Cell: ({ value }) => <DefaultCell value={value} />},
  ],

  rows: [   
  ],
};

export default TableDefinition;
