import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";


const Attributes = {
  formSize: "md",
  enableAddButton: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),

};

const operationURLS = {
  list: "userZone/listAll",
  update: "userZone/update",
  create: "userZone/create",
  delete: "userZone/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
