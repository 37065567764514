import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import TelephoneCell from "application/components/TelephoneCell";
import CTLAddFav from "application/components/CTLAddFav";
import formControl from "globals/form-control";

const Attributes = {
  enableAddButton: true,
  formSize: "md",
  refetchInterval: 5000,
  modes: [],
};

const operationURLS = {
  list: "/ownPhoneBook/list",
  register: "/ownPhoneBook/create",
  update: "/ownPhoneBook/update",
  delete: "/ownPhoneBook/delete",
  toggleFavorite: "/ownPhoneBook/updateFavorite",
};

const getColumns = (onDelete, onEdit, onCall, onFavorite) => {
  return [
    {
      Header: "Action",
      accessor: "favorite",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      allowInForm: false,
      Cell: ({ row, value }) => (
        <CTLAddFav row={row} value={value} onFavorite={onFavorite} />
      ),
    },

    {
      Header: "Full Name",
      accessor: "firstName",
      displayInList: true,
      allowFilter: true,
      displayInDeleteConfirmation: true,
      dataType: "string",
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
    },
    {
      Header: "Designation",
      accessor: "designation",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Home Number",
      accessor: "phoneHome",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      allowInForm: true,
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
      validation: formControl.string().digitsOnly().nullable(),
    },
    {
      Header: "Home Address",
      accessor: "addressOne",
      displayInList: false,
      allowFilter: true,
      dataType: "string",
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Office Number",
      accessor: "phoneOffice",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      allowInForm: true,
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
      validation: formControl
        .string()
        .digitsOnly()
        .required("Required")
        .nullable(),
    },
    {
      Header: "Office Address",
      accessor: "addressTwo",
      displayInList: false,
      allowFilter: true,
      dataType: "string",
      allowInForm: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Company",
      accessor: "company",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Fax",
      accessor: "faxNumber",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().digitsOnly().nullable(),
    },
    {
      Header: "Email",
      accessor: "emailId",
      displayInList: false,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().emailOnly().nullable(),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
