import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { Card } from "@mui/material";


function CallHistory({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  onCall,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = () => {
    setSelectedRow([]);
    setConfirmDelete(true);
  };

  const onAgree = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  return (
    <Card>
      <MDBox>
        <DeleteConfirmation
          entityName="CallHistory"
          data={selectedRow}
          columns={getColumns(deleteRow)}
          confirmDelete={confirmDelete}
          onAgree={onAgree}
          onCancel={onCancel}
        />

        <CTLAdvancedFilter
          entityName="CallHistory"
          columns={getColumns(deleteRow)}
          applyFilter={openFilter}
          onApplyFilter={onApplyFilter}
          onCloseFilter={onCloseFilter}
        />

        <CTLTable
          apiURL="/uc/callHistory/list"
          columns={getColumns(deleteRow, onCall)}
          advancedFilters={advancedFilters}
          entriesPerPage={false}
          canSearch
          defaultSortDirection="desc"
          defaultSortColumn="startStamp"
          pageId={pageId}
          searchText={searchText}
          hasRowSelection={false}
          refetchInterval={Attributes.refetchInterval}
        />
      </MDBox>
    </Card>
  );
}

export default CallHistory;
