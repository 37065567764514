import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { adminMenus } from "globals/configuration/admin";


const Attributes = {
  enableSipSelect: true,
  enableAddButton: true,
  enablePublishButton: true,
  enableFilePreViewButton: true,
  keyField: "name",
  formSize: "md",
  selectUrl:"/sipFile/dropDown",
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      disabled: true,
      isUserGenerated: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      isUserGenerated: true,
      disabled: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Value",
      accessor: "value",
      displayInList: true,
      allowFilter: false,
      isUserGenerated: true,
      allowInForm: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes };
