import { useState } from "react";
import styles from "../css/Topology.module.css";
import ContextMenu from "../ContextMenu";
import PopupTopology from "../PopupTopology";
import PopupLocationAssets from "../PopupLocationAssets";

function LevelOneTopologyNode({
  isDarkMode,
  nodeDatum,
  descendantsData,
  color,
  categoryData,
  severityData,
  isAdmin,
  isEditMode,
  handleDisableGraphTopoDrag,
  handleEnableGraphTopoDrag,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const [popupTopologyOpen, setPopupTopologyOpen] = useState(false);
  const [popupLocationAssetsOpen, setPopupLocationAssetsOpen] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (!popupTopologyOpen) {
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null,
      );
      handleDisableGraphTopoDrag();
      event.stopPropagation();
    }
  };
  const handleClose = () => setContextMenu(null);

  const handlePopupTopologyOpen = () => {
    setPopupTopologyOpen(true);
    handleClose();
  };
  const handlePopupTopologyClose = () => {
    setPopupTopologyOpen(false);
    handleEnableGraphTopoDrag();
  };

  const handleNodeClick = () => {
    handleDisableGraphTopoDrag();
    setPopupLocationAssetsOpen(true);
  };

  const handlePopupLocationAssetsClose = () => {
    setPopupLocationAssetsOpen(false);
    handleEnableGraphTopoDrag();
  };

  let indicatorTimeoutId = null;
  return (
    <>
      <g
        className={styles.icon}
        style={{ cursor: "pointer" }}
        onMouseEnter={() => {
          if (!isIndicatorVisible)
            indicatorTimeoutId = setTimeout(
              () => setIsIndicatorVisible(!isIndicatorVisible),
              300,
            );
        }}
        onMouseLeave={() => {
          clearTimeout(indicatorTimeoutId);
          if (isIndicatorVisible) setIsIndicatorVisible(!isIndicatorVisible);
        }}
      >
        {nodeDatum.level === 1 && (
          <circle
            stroke="purple"
            strokeWidth="1.5"
            fill={isDarkMode ? "#202940" : "white"}
            r="23"
          />
        )}
        {nodeDatum.children.length && isIndicatorVisible && (
          <g>
            <circle
              strokeWidth="0"
              r="5"
              fill="#5bed37"
              transform={
                nodeDatum.level > 1 ? "translate(-9,-37)" : "translate(-9,-47)"
              }
            />
            <text
              fontFamily="sans-serif"
              fontSize=".7em"
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform={
                nodeDatum.level > 1 ? "translate(1,-33)" : "translate(1,-43)"
              }
            >
              {descendantsData[nodeDatum.id].active}
            </text>
            <circle
              strokeWidth="0"
              r="5"
              fill="#ed4242"
              transform={
                nodeDatum.level > 1 ? "translate(-9,-22)" : "translate(-9,-32)"
              }
            />
            <text
              fontFamily="sans-serif"
              fontSize=".7em"
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform={
                nodeDatum.level > 1 ? "translate(1,-18)" : "translate(1,-28)"
              }
            >
              {descendantsData[nodeDatum.id].inactive}
            </text>
          </g>
        )}
        <circle
          onClick={handleNodeClick}
          onContextMenu={handleContextMenu}
          stroke="black"
          strokeWidth="1.5"
          transform="scale(1.3)"
          fill={color}
          r="10"
        />
        <text
          fontFamily="sans-serif"
          fontSize="1em"
          fill={isDarkMode ? "white" : "black"}
          x="-15"
          y="42"
        >
          {nodeDatum.name}
        </text>
        <ContextMenu
          contextMenu={contextMenu}
          handleClose={handleClose}
          nodeDatum={nodeDatum}
          handlePopupTopologyOpen={handlePopupTopologyOpen}
        />
        <PopupTopology
          nodeDatum={nodeDatum}
          categoryData={categoryData}
          descendantsData={descendantsData}
          severityData={severityData}
          popupTopologyOpen={popupTopologyOpen}
          handlePopupTopologyClose={handlePopupTopologyClose}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
        <PopupLocationAssets
          popupLocationAssetsOpen={popupLocationAssetsOpen}
          handlePopupLocationAssetsClose={handlePopupLocationAssetsClose}
          location={nodeDatum.name}
          descendantsData={descendantsData}
          categoryData={categoryData}
          severityData={severityData}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      </g>
    </>
  );
}

export default LevelOneTopologyNode;
