import { useState } from "react";
import MDTypography from "components/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import CTLNotification from "application/components/CTLNotification";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function CopyContent({ row,suffix, value}) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  const UUID = row.original.uuid;
  let domainName = window.location.hostname.toLowerCase();
  let isDomain = domainName.match(/[a-z]/i) !== null;
  const isProtocol = window.location.protocol === "https:";

  const onCopy = (data) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(data.uuid)
        .then(() => {
          setNotify({
            isOpen: true,
            message: "UUID copied Success",
            type: "success",
            pagename: "Customer CDR Report",
          });
        })
        .catch((error) => {
          console.log("Failed to copy UUID ", error);
          setNotify({
            isOpen: true,
            message: "Failed to copy UUID ",
            type: "error",
            pagename: "Customer CDR Report",
          });
        });
    }
  };

  const handleCopy = () => {
    onCopy({ uuid: UUID });
  };

  return (
    <>
      <MDTypography variant="caption" fontWeight="medium" color="text">
    {isProtocol && isDomain ? (
      <Tooltip title="Copy UUID">
        <IconButton color="error" onClick={handleCopy}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    ) : (
      value
    )}
    </MDTypography>
    <CTLNotification notify={notify} setNotify={setNotify} />
  </>

  );
}

export default CopyContent;
