import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import { application } from "globals/endpoints";
import { useMaterialUIController } from "context";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLConferencePhone from "application/components/CTLConferencePhone";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";

function MyConference({ onLoad, registerCallBacks, pageName }) {

  const [showParticipantForm, setShowParticipantForm] = useState(true);
  const [timeLists, setTimeList] = useState([]);
  const [conferenceLists, setconferenceList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, conferenceData } = controller;

  const operationUrl = {
    timeList: "/conference/dateList",
    conferenceList: "/conference/dateTimeList",
  };

  const meetingTimelist = () => {
    application.post(operationUrl.timeList).then((response) => {
      setTimeList(response.data.data);
    });
  };

  const conferenceTimelist = (scheduledDate) => {
    setSelectedDate(scheduledDate);
    setShowParticipantForm(false);
    const datatoSave = {
      scheduledDate: scheduledDate,
    };

    application
      .post(operationUrl.conferenceList, datatoSave)
      .then((response) => {
        setconferenceList(response.data.data);
      });
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    meetingTimelist();
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setOpenForm(true);
    setFormData({
      eventTime: Date.now(),
    });
  }

  function onCloseForm(){
     setOpenForm(false);
  }

  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        intId: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };


  function onSaveForm(data) {
      const dataToSave = {
        configureConferrerId: conferenceData.modId,
        ...data,
      };
      application
        .post(operationURLS.create, dataToSave)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    setOpenForm(false);
  }
  return (
    <>
      <CTLBaseDialogForm
        entityName="Add participant in conference"
        columns={getColumns()}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <MDBox>
        <CTLConferencePhone
          phoneType="moderator"
          onOpenForm={onOpenForm}
          updateList={updateList}
        />
      </MDBox>
    </>
  );
}
export default MyConference;
