import { Card, Divider } from "@mui/material";
import CTLConferencePhone from "application/components/CTLConferencePhone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultItem from "features/Items/DefaultItem";
import { useEffect, useState } from "react";

function NextEvents({ data, phoneType, setPhoneType }) {
  const currentDate = new Date();
  const filteredAndSortedData = data
    .filter((item) => {
      const meetingScheduledDate = new Date(item.meetingScheduledDate);
      meetingScheduledDate.setHours(0, 0, 0, 0);
      const currentDay = new Date(currentDate);
      currentDay.setHours(0, 0, 0, 0);
      return meetingScheduledDate >= currentDay;
    })
    .sort((a, b) => {
      const dateA = new Date(a.meetingScheduledDate);
      const dateB = new Date(b.meetingScheduledDate);
      return dateA - dateB;
    });

  return (
    <Card
      style={{
        height: "calc(100vh - 150px)",
        overflow: "auto",
        position: "relative",
      }}
    >
      <CTLConferencePhone phoneType={phoneType} setPhoneType={setPhoneType} />
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Upcoming Conference
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {filteredAndSortedData.map((item, index) => {
          let color = "dark";
          const currentDateFormatted = currentDate.toISOString().slice(0, 10);
          const eventDateFormatted = new Date(item.meetingScheduledDate)
            .toISOString()
            .slice(0, 10);
          if (eventDateFormatted === currentDateFormatted) {
            color = "success";
          } else if (eventDateFormatted > currentDateFormatted) {
            const oneWeekLater = new Date();
            oneWeekLater.setDate(oneWeekLater.getDate() + 7);
            if (new Date(eventDateFormatted) <= oneWeekLater) {
              color = "warning";
            } else {
              color = "info";
            }
          } else if (eventDateFormatted < currentDateFormatted) {
            color = "error";
          }

          return (
            <MDBox key={index} mt={3.5}>
              <DefaultItem
                color={color}
                icon="notifications"
                title={item.roomName}
                description={`${item.meetingScheduledDate}   From  ${item.scheduledFromTime}  To  ${item.scheduledToTime}`}
              />
            </MDBox>
          );
        })}
      </MDBox>
    </Card>
  );
}

export default NextEvents;
