const callCenterMenus = [
  // {
  //   icon: "call",
  //   key: "callcenter",
  //   name: "Call-Center",
  //   url: "home/app/call-center",
  //   description: "Call-Center List",
  //   // disabled : true
  //   productionReady: false,
  // },
  // {
  //     icon: "callcenter",
  //     key: "configuration.callcenter",
  //     name: "Call-Center",
  //     url: "home/app/configurations/callcenter",
  //     description: "Callcenter Details",
  //     disabled: false,
  //   },
];

export { callCenterMenus };
