const voicePickerMenus = [
    {
        icon: " keyboard_voice",
        key: "configuration.voicePicker",
        name: "Voice Picker",
        url: "home/app/voicePicker",
        description: "Voice Picker",
        disabled: false,
        productionReady: true,
      },
  ];
  
  export { voicePickerMenus };
  