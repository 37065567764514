import computeSidebarColumns from "./SidebarWidth";

export default function computeInnerContentColumns(breakpoint, layoutData) {
  const leftSidebarColumnsSum = layoutData.leftSidebar
    ? computeSidebarColumns(layoutData.leftSidebar, breakpoint)
    : 0;
  const rightSidebarColumnsSum = layoutData.rightSidebar
    ? computeSidebarColumns(layoutData.rightSidebar, breakpoint)
    : 0;

  const bothSidebarsColumnsSum = leftSidebarColumnsSum + rightSidebarColumnsSum;

  return bothSidebarsColumnsSum <= 12 ? 12 - bothSidebarsColumnsSum : 12;
}
