import React from "react";
import MDTypography from "components/MDTypography";

function CTLMeetingType({ value }) {
  let suffix = "";

  if (value === "v") {
    suffix = "video";
  } if (value === "a") {
    suffix = "Audio";
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

export default CTLMeetingType;
