import DefaultCell from "application/components/DefaultCell";
import formControl from "globals/form-control";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import CTLDate from "application/components/CTLDate";
import CTLIntegerCell from "application/components/CTLIntegerCell";
import CTLPremiumCell from "application/components/CTLPremiumCell";
import CTLRoomStatusCell from "application/components/CTLRoomStatusCell";
import MoreVertAction from "application/components/MorevertActions";
import CTLAlarmCell from "application/components/CTLAlarmCell";
import CTLPhoneStatusCell from "application/components/CTLPhoneStatusCell";

const Attributes = {
  formSize: "md",
  columns: 3,
  refetchInterval: 1000,
  modes: [],
  settingsMode:true,
};

const operationURLS = {
  list: "/hms/list",
  update: "/hms/update/id",
  alarm: "/hms/alarm",
  publish: "/configuration/publish",
  status: "/hms/cleanDirty",
};

const getColumns = (onEdit, onPrintPdf, onDirtyCleanStatus) => {
  return [
    {
      Header: "Room Number",
      accessor: "premiumCustomer",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value, row }) => (
        <div>
          {row.original.action}
          <CTLPremiumCell value={value} />
        </div>
      ),
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "ISD",
      accessor: "isd",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "STD",
      accessor: "std",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "LOCAL",
      accessor: "local",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "INTERCOM",
      accessor: "intercom",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "DND",
      accessor: "dnd",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType:"boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Prepaid Budget",
      accessor: "budget",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Group ",
      accessor: "groupType",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").digitsOnly(),
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Checkin Date",
      accessor: "checkInEpoch",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "date",
      Cell: ({ value }) => <CTLDate value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },

    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Room Status",
      accessor: "roomStatus",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLRoomStatusCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Dirty/Clean",
      accessor: "isFeedback",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLDirtyCleanCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Phone Status",
      accessor: "phoneStatus",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLPhoneStatusCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "Cos",
      accessor: "cos",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLIntegerCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
      disabled: true,
    },
    {
      Header: "Registered",
      accessor: "registered",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLIntegerCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
      disabled: true,
    },
    {
      Header: "Alarm Time",
      accessor: "alarmTime",
      displayInList: true,
      allowFilter: true,
      allowInForm: false,
      dataType: "string",
      Cell: ({ value }) => <CTLAlarmCell value={value} />,
      tabId: 0,
      groupHeading: "Hotel Settings",
    },
    {
      Header: "",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <MoreVertAction
          row={row}
          value={value}
          onEdit={onEdit}
          onPrintPdf={onPrintPdf}
          onDirtyCleanStatus={onDirtyCleanStatus}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
