const DirectCallCenterMenu = [
    {
      icon: "connect_without_contact",
      key: "callcenter.configuration",
      name: "Call-Center-Configuration",
      url: "home/app/call-center-configuration",
      description: "Configure All Call Center Setting",
      productionReady: false,
    },
  ];
  export default DirectCallCenterMenu;
  