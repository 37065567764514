import React, { useState, useEffect } from "react";
import SIPPhoneService from "./SIPPhoneService";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import { ButtonGroup, Card, FormControlLabel, FormGroup, Grid, Switch, Icon, IconButton, Tooltip } from "@mui/material";
import ring from "assets/sound/ringing.wav";
import ringBack from "assets/sound/ringback_tone.mp3";
import { useMaterialUIController, setOpenPhone, setCallState, setClickToCall } from "context";
import variables from "globals/variables";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";

import {
    Invitation,
    Inviter,
    InviterOptions,
    Referral,
    Registerer,
    RegistererOptions,
    Session,
    SessionState,
    RegistererState,
    UserAgent,
    UserAgentOptions,
    InvitationAcceptOptions,
    Web,
    InvitationRejectOptions,
    LogLevel
} from "sip.js";
import MDTypography from "components/MDTypography";
import AuthenticationService from "application/modules/authentication/AuthenticationService";


function SIPPhone(props) {
    const componentName = "SIPPhone";
    const [registration, setRegistration] = useState("Not Registered");
    const [inCall, setInCall] = useState(false);
    const [isOnHold, setIsOnHold] = useState(false);
    const [isMute, setIsMute] = useState(false);
    const [isRinging, setIsRinging] = useState(false);
    const [isDialing, setIsDialing] = useState(false);
    const [dialNumber, setDialNumber] = useState("");
    const [callStatus, setCallStatus] = useState("");
    const [phoneDisplay, setPhoneDisplay] = useState("block");
    const [timer, setTimer] = useState('0:0:0');

    let talkStart = null;
    let timerThread = null;

    const [controller, dispatch] = useMaterialUIController();
    const {
        callState,
        clickToCall
    } = controller;

    const updateCallState = (callInfo) => {
        loggerFactory.debug(componentName, "Dispatching call state", callInfo);
        setCallState(dispatch, callInfo)
    };

    const audioRinging = new Audio(ring);
    audioRinging.loop = true;

    const ringBackTone = new Audio(ringBack);
    ringBackTone.loop = true;

    function handleChange(event) {
        setDialNumber(event.target.value);
    }

    function onInvite(invitation) {
        setOpenPhone(dispatch, true);

        openForIncomingCall(invitation);
    }

    function callIsRinging(callStatus) {
        let callInfo = { inCall, isOnHold, isMute, isRinging, isDialing, dialNumber, callStatus }

        audioRinging.play();
        callInfo.isRinging = true;
        if (callStatus) {
            callInfo.callStatus = callStatus;
        }
        updateCallState(callInfo);
    }

    function callIsDialing(callStatus) {
        let callInfo = { inCall, isOnHold, isMute, isRinging, isDialing, dialNumber, callStatus }

        callInfo.isDialing = true;
        callInfo.dialNumber = "";
        if (callStatus) {
            callInfo.callStatus = callStatus;
        }
        updateCallState(callInfo);
    }

    function callIsConnected(callStatus) {
        let callInfo = { inCall, isOnHold, isMute, isRinging, isDialing, dialNumber, callStatus }

        startTimer();

        audioRinging.pause();
        callInfo.inCall = true;
        callInfo.isRinging = false;
        callInfo.isDialing = false;
        callInfo.callStatus = callStatus;
        callInfo.dialNumber = "";

        updateCallState(callInfo);
    }

    function callIsTerminated() {
        let callInfo = { inCall, isOnHold, isMute, isRinging, isDialing, dialNumber, callStatus }

        audioRinging.pause();

        callInfo.inCall = false;
        callInfo.isRinging = false;
        callInfo.isDialing = false;
        callInfo.callStatus = "";
        callInfo.dialNumber = "";

        stopTimer();

        updateCallState(callInfo);
    }

    function openForIncomingCall(invitation) {
        const messageFrom = invitation.incomingInviteRequest.message.from;

        callIsRinging("Incoming call from : " + messageFrom.uri.normal.user + " : " + messageFrom._displayName);
        SIPPhoneService.inviteSession = invitation;

        invitation.stateChange.addListener((state) => {
            switch (state) {
                case SessionState.Initial:
                    callIsRinging();
                    break;
                case SessionState.Establishing:
                    callIsRinging();
                    break;
                case SessionState.Established:
                    const messageTalk = SIPPhoneService.inviteSession.incomingInviteRequest.message.from;

                    callIsConnected("Talking : " + messageTalk.uri.normal.user + " : " + messageTalk._displayName);

                    const remoteAudioStream = new MediaStream();
                    const remoteVideoStream = new MediaStream();
                    const localVideoStream = new MediaStream();

                    SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection.getSenders().forEach((sender) => {
                        if (sender.track) {
                            if (sender.track.kind === "audio") {
                                SIPPhoneService.currentSessionAudio = sender;
                            }
                            if (sender.track.kind === "video") {
                                SIPPhoneService.currentSessionVideo = sender;
                                localVideoStream.addTrack(sender.track);

                                SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                                SIPPhoneService.localVideoMedia.play();
                            }
                        }
                    });

                    SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
                        if (receiver.track) {
                            if (receiver.track.kind === "audio") {
                                remoteAudioStream.addTrack(receiver.track);
                            }
                            if (receiver.track.kind === "video") {
                                remoteVideoStream.addTrack(receiver.track);
                            }
                        }
                    });
                    SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
                    SIPPhoneService.remoteAudioMedia.play();

                    SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
                    SIPPhoneService.remoteVideoMedia.play();
                    break;
                case SessionState.Terminating:
                // fall through
                case SessionState.Terminated:
                    callIsTerminated();
                    SIPPhoneService.remoteAudioMedia.srcObject = null;
                    SIPPhoneService.remoteAudioMedia.pause();

                    SIPPhoneService.remoteVideoMedia.srcObject = null;
                    SIPPhoneService.remoteVideoMedia.pause();

                    SIPPhoneService.localVideoMedia.srcObject = null;
                    SIPPhoneService.localVideoMedia.pause();


                    break;
                default:
                    throw new Error("Unknown session state.");
            }
        });
    }

    function updateTimer() {
        const total = Date.parse(new Date()) - Date.parse(talkStart);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        setTimer(`${hours}:${minutes}:${seconds}`);
    }

    function startTimer() {
        talkStart = new Date();
        timerThread = setInterval(() => {
            updateTimer();
        }, 1000)
    }

    function stopTimer() {
        talkStart = null

        if (timerThread) {
            clearInterval(timerThread);
            timerThread = null;
        }
    }

    function answerCall(asVideoCall) {
        SIPPhoneService.inviteSession.accept({
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: true,
                    video: asVideoCall
                }
            }
        });
    }

    function updateRegistrationStatus(status) {
        setRegistration(status)
    }

    function bye() {
        SIPPhoneService.inviteSession.bye();
    }

    function cancel() {
        SIPPhoneService.inviteSession.cancel();
    }

    function rejectCall() {
        SIPPhoneService.inviteSession.reject({
            statusCode: 486
        });
    }

    function mute() {
        console.log(SIPPhoneService.currentSessionAudio);
        SIPPhoneService.currentSessionAudio.track.enabled = false;
        setIsMute(true);
    }

    function unMute() {
        console.log(SIPPhoneService.currentSessionAudio);
        SIPPhoneService.currentSessionAudio.track.enabled = true;
        setIsMute(false);
    }

    function hold() {
        const options = {
            sessionDescriptionHandlerModifiers: [Web.holdModifier]
        }
        SIPPhoneService.inviteSession.invite(options);
        setIsOnHold(true);
    }


    function unHold() {
        const options = {
            sessionDescriptionHandlerModifiers: []
        }
        SIPPhoneService.inviteSession.invite(options);
        setIsOnHold(false);
    }

    function transfer() {
        const target = UserAgent.makeURI('sip:' + dialNumber + '@' + variables.sip.domain);
        SIPPhoneService.inviteSession.refer(target);
        setDialNumber("");
    }

    function forceTransfer(number) {
        const target = UserAgent.makeURI('sip:' + number + '@' + variables.sip.domain);
        SIPPhoneService.inviteSession.refer(target);
        setDialNumber("");
    }


    function keyClicked(key) {
        if (inCall) {
            const options = {
                requestOptions: {
                    body: {
                        contentDisposition: "render",
                        contentType: "application/dtmf-relay",
                        content: `Signal=${key}\r\nDuration=1000`
                    }
                }
            };
            SIPPhoneService.inviteSession.info(options);
        } else {
            setDialNumber(dialNumber + key);
        }
    }

    function dial(videoCall, forceDial) {
        let toDial = forceDial ? forceDial : dialNumber;

        if (toDial) {
            if (AuthenticationService.getDeskPhoneStatus()) {
                application.post("/externalApi/call", {
                    caller: AuthenticationService.getExtensionNumber(),
                    callee: dialNumber
                })
                SIPPhoneService.lastNumberDialed = toDial;
                setDialNumber("");
            } else {
                SIPPhoneService.lastNumberDialed = toDial;

                const number = 'sip:' + toDial + '@' + variables.sip.domain;
                const target = UserAgent.makeURI(number);
                setDialNumber("");
                if (clickToCall) {
                    setClickToCall(dispatch, "");
                }


                SIPPhoneService.inviteSession = new Inviter(SIPPhoneService.userAgent, target);
                SIPPhoneService.inviteSession.invite({
                    sessionDescriptionHandlerOptions: {
                        constraints: {
                            audio: true,
                            video: videoCall
                        }
                    }
                });



                SIPPhoneService.inviteSession.stateChange.addListener((newState) => {
                    switch (newState) {
                        case SessionState.Establishing:
                            ringBackTone.play();
                            if (toDial === SIPPhoneService.swipeCode) {
                                callIsDialing("Call Swipe Initiated");
                            } else {
                                callIsDialing("Dialing : " + toDial);
                            }
                            break;

                        case SessionState.Established:
                            ringBackTone.pause();
                            if (toDial === SIPPhoneService.swipeCode) {
                                callIsConnected("Call Swiped");
                            } else {
                                callIsConnected(`Talking : ${toDial}`);
                            }
                            const remoteAudioStream = new MediaStream();
                            const remoteVideoStream = new MediaStream();
                            const localVideoStream = new MediaStream();

                            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection.getSenders().forEach((sender) => {
                                if (sender.track.kind === "audio") {
                                    SIPPhoneService.currentSessionAudio = sender;
                                }
                                if (sender.track.kind === "video") {
                                    SIPPhoneService.currentSessionVideo = sender;
                                    localVideoStream.addTrack(sender.track);
                                }

                                SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                                SIPPhoneService.localVideoMedia.play();
                            });

                            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
                                if (receiver.track) {
                                    if (receiver.track.kind === "audio") {
                                        remoteAudioStream.addTrack(receiver.track);
                                    }
                                    if (receiver.track.kind === "video") {
                                        remoteVideoStream.addTrack(receiver.track);
                                    }
                                }
                            });
                            SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
                            SIPPhoneService.remoteAudioMedia.play();

                            SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
                            SIPPhoneService.remoteVideoMedia.play();
                            break;
                        case SessionState.Terminated:
                            ringBackTone.pause();
                            callIsTerminated();

                            SIPPhoneService.remoteAudioMedia.srcObject = null;
                            SIPPhoneService.remoteAudioMedia.pause();

                            SIPPhoneService.remoteVideoMedia.srcObject = null;
                            SIPPhoneService.remoteVideoMedia.pause();

                            SIPPhoneService.localVideoMedia.srcObject = null;
                            SIPPhoneService.localVideoMedia.pause();

                            break;
                        default:
                            break;
                    }
                });
            }
        } else {
            if (SIPPhoneService.lastNumberDialed) {
                setDialNumber(SIPPhoneService.lastNumberDialed);
            }
        }
    }

    useEffect(() => {
        if (!SIPPhoneService.callbacks.invite) {
            SIPPhoneService.callbacks.invite = onInvite;
            SIPPhoneService.callbacks.registration = updateRegistrationStatus;
            SIPPhoneService.remoteAudioMedia = document.getElementById('remoteAudio');
            SIPPhoneService.remoteVideoMedia = document.getElementById('remoteVideo');
            SIPPhoneService.localVideoMedia = document.getElementById('localVideo');
        }
        setRegistration(SIPPhoneService.registeredState);
        loggerFactory.debug(componentName, "State Update", callState);
        if (callState) {
            setCallStatus(callState.callStatus);
            setDialNumber(callState.dialNumber);
            setInCall(callState.inCall);
            setIsDialing(callState.isDialing);
            setIsMute(callState.isMute);
            setIsOnHold(callState.isOnHold);
            setIsRinging(callState.isRinging);
        }
    }, [callState])

    useEffect(() => {
        setDialNumber(clickToCall);
        dial(false, clickToCall);
        loggerFactory.debug(componentName, "Event clickToCall ", clickToCall);
    }, [clickToCall]);

    return (

        <Card id="phone" style={{ width: "480px" }}>
            {!AuthenticationService.getDeskPhoneStatus() && <MDTypography style={{ marginBottom: "10px", marginTop: "10px", textAlign: "center", minHeight: "40px" }}><Icon style={{ marginTop: "5px", marginRight: "5px" }} color={registration === "Registered" ? "success" : "error"}>circle</Icon>{SIPPhoneService.userAgentOptions.authorizationUsername + " " + SIPPhoneService.userAgentOptions.displayName}</MDTypography> }
            {AuthenticationService.getDeskPhoneStatus() && <MDTypography style={{ marginBottom: "10px", marginTop: "10px", textAlign: "center", minHeight: "40px" }}><Icon style={{ marginTop: "5px", marginRight: "5px" }} color={registration === "Registered" ? "Desk Phone" : "Desk Phone"}>circle</Icon>{SIPPhoneService.userAgentOptions.authorizationUsername + " " + SIPPhoneService.userAgentOptions.displayName}</MDTypography> }
            <MDBox style={{ textAlign: "center" }}>
                Desk Phone {AuthenticationService.getDeskPhoneStatus() ? "Enabled" : "Disabled"} <Switch checked={AuthenticationService.getDeskPhoneStatus()} onChange={() => { AuthenticationService.setDeskPhoneStatus(!AuthenticationService.getDeskPhoneStatus()) }}></Switch>
            </MDBox>
            <audio id="remoteAudio">
                <p>Your browser doesn't support HTML5 audio.</p>
            </audio>
            <MDBox id="video-block" style={{ width: "480px" }}>
                <video id="remoteVideo" style={{ width: "50%" }}></video>
                <video id="localVideo" style={{ width: "50%" }}></video>
            </MDBox>

            <MDBox style={{ maxWidth: "480px" }}>
                <MDTypography style={{ marginBottom: "20px", textAlign: "center", minHeight: "40px" }}>{callStatus}</MDTypography>
                {inCall && <MDTypography variant="h6" style={{ marginBottom: "20px", textAlign: "center", minHeight: "40px" }}>Duration : {timer}</MDTypography>}
                <MDInput fullWidth style={{ marginBottom: "20px" }} value={dialNumber} onChange={handleChange} ></MDInput>
                <Grid container spacing={2}>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="1" color="dark" onClick={() => keyClicked("1")}>1</MDButton>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="2" color="dark" onClick={() => keyClicked("2")}>2</MDButton>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="3" color="dark" onClick={() => keyClicked("3")}>3</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="4" color="dark" onClick={() => keyClicked("4")}>4</MDButton>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="5" color="dark" onClick={() => keyClicked("5")}>5</MDButton>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="6" color="dark" onClick={() => keyClicked("6")}>6</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="7" color="dark" onClick={() => keyClicked("7")}>7</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="8" color="dark" onClick={() => keyClicked("8")}>8</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="9" color="dark" onClick={() => keyClicked("9")}>9</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="*" color="dark" onClick={() => keyClicked("*")}>*</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="0" color="dark" onClick={() => keyClicked("0")}>0</MDButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <MDButton id="#" color="dark" onClick={() => keyClicked("#")}>#</MDButton>
                    </Grid>
                </Grid>
                <MDBox style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                    <ButtonGroup>
                        {isRinging && <Tooltip title="Answer Video Call">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => answerCall(true)}>
                                <Icon color="success">videocam</Icon>
                            </MDButton>
                        </Tooltip>}
                        {isRinging && <Tooltip title="Answer Audio Call">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => answerCall(false)}>
                                <Icon color="success">phone</Icon>
                            </MDButton>
                        </Tooltip>}
                        {isRinging && <Tooltip title="Reject Call">
                            <MDButton color="error" variant="contained" id="call" size="medium" onClick={() => rejectCall()}>
                                <Icon color="success">call_end</Icon>
                            </MDButton>
                        </Tooltip>}
                        {!inCall && !isRinging && !isDialing && <Tooltip title="Video Call">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => dial(true)}>
                                <Icon color="success">videocam</Icon>
                            </MDButton>
                        </Tooltip>}
                        {!inCall && !isRinging && !isDialing && <Tooltip title="Call">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => dial(false)}>
                                <Icon color="success">phone</Icon>
                            </MDButton>
                        </Tooltip>}
                        {!inCall && !isRinging && !isDialing && <Tooltip title="Swipe">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => dial(false, SIPPhoneService.swipeCode)}>
                                <Icon color="success">swap_horiz</Icon>
                            </MDButton>
                        </Tooltip>}
                        {!inCall && !isRinging && !isDialing && <Tooltip title="Un Park">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => dial(false, SIPPhoneService.callUnPark)}>
                                <Icon color="success">file_download</Icon>
                            </MDButton>
                        </Tooltip>}
                        {isDialing && <Tooltip title="Cancel Call">
                            <MDButton color="error" variant="contained" id="call" size="medium" onClick={() => cancel()}>
                                <Icon color="success">call_end</Icon>
                            </MDButton>
                        </Tooltip>}

                        {inCall && <Tooltip title="Disconnect">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => bye()}>
                                <Icon color="error">call_end</Icon>
                            </MDButton>
                        </Tooltip>}

                        {inCall && <Tooltip title="Call Park">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => forceTransfer(SIPPhoneService.callPark)}>
                                <Icon color="success">file_upload</Icon>
                            </MDButton>
                        </Tooltip>}

                        {inCall && !isOnHold && <Tooltip title="Hold">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => hold()}>
                                <Icon color="warning">phone_paused</Icon>
                            </MDButton>
                        </Tooltip>}
                        {inCall && isOnHold && <Tooltip title="Un Hold">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => unHold()}>
                                <Icon color="success">phone_in_talk</Icon>
                            </MDButton>
                        </Tooltip>}
                        {inCall && !isMute && <Tooltip title="Mute">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => mute()}>
                                <Icon color="error">mic_off</Icon>
                            </MDButton>
                        </Tooltip>}
                        {inCall && isMute && <Tooltip title="Un Mute">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => unMute()}>
                                <Icon color="error">mic</Icon>
                            </MDButton>
                        </Tooltip>}
                        {inCall && <Tooltip title="Transfer">
                            <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => transfer()}>
                                <Icon color="success">phone_forwarded</Icon>
                            </MDButton>
                        </Tooltip>}
                        {dialNumber.length > 0 && <MDButton color="info" variant="contained" id="call" size="medium" onClick={() => setDialNumber("")}>
                            <Icon color="warning">clear</Icon>
                        </MDButton>}
                    </ButtonGroup>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default SIPPhone