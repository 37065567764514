// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Settings page components
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import { useEffect } from "react";
import { Attributes } from "./model";

function Settings({onLoad}) {
  useEffect(() => {
    Attributes && onLoad(Attributes);
  }, [])

  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicInfo />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>

  );
}

export default Settings;
