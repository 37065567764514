import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function CTLUserStatusCell({ value }) {
 
  const [listOptions, setListOptions] = useState([]);

  useEffect(() => {
    application.post("role/dropdownList").then((response) => {
      setListOptions(response.data.data);
    });
  }, []);

  const matchedRole = listOptions?.find((option) => option.id === value);

  return (
    <>
      {matchedRole &&
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {matchedRole?.description}
      </MDTypography>
      }
    </>
  );
}




export default CTLUserStatusCell;
