import { useCallback, useState } from "react";

export default function useCenteredTree() {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 3, y: height / 1.5 });
    }
  }, []);
  return [translate, containerRef];
}
