import MDSnackbar from "components/MDSnackbar";
import { useEffect, useRef } from "react";

export default function CTLNotification(props) {
  const { notify, setNotify } = props;
  const timerRef = useRef(null);

  const handleClose = (event, reason) => {
    setNotify({
      ...notify,
      isOpen: false,
    });
  };

  useEffect(() => {
    if (notify.isOpen) {
      timerRef.current = setTimeout(() => {
        setNotify({
          ...notify,
          isOpen: false,
        });
      }, 3000);
    } else {
      clearTimeout(timerRef.current);
    }

    return () => clearTimeout(timerRef.current);
  }, [notify]);

  let status = notify.status;
  let message;

  if (status === "OK") {
    message = "200 OK";
  } else if (status === 400) {
    message = " 400 Bad Request";
  } else if (status === 401) {
    message = "401 Unauthorized";
  } else if (status === 402) {
    message = "402 Payment Required";
  } else if (status === 403) {
    message = "403 Forbidden";
  } else if (status === 404) {
    message = "404 Not Found";
  } else if (status === 405) {
    message = "Method Not Allowed";
  } else if (status === 406) {
    message = "406 Not Acceptable";
  } else if (status === 407) {
    message = "407 Proxy Authentication Required";
  } else if (status === 408) {
    message = " 408 Request Timeout";
  } else if (status >= 409 && status <= 699) {
    message = "Server Error. Contact the administrator";
  } else {
    message = status;
  }

  return (
    <>
      <MDSnackbar
        color={notify.type}
        icon={notify.type}
        title={notify.pagename}
        content={message}
        subContent={notify.message}
        status={notify.status}
        open={notify.isOpen}
        close={handleClose}
        autoHideDuration={4000}
      />
    </>
  );
}
