import { useState } from "react";
import styles from "../css/Topology.module.css";
import ContextMenu from "../ContextMenu";
import Icon from "../Icon";
import PopUp from "../../../PopUp";

function Node({
  toggleNode,
  isDarkMode,
  nodeDatum,
  descendantsData,
  placeholder,
  identifier,
  color,
  contextMenuData,
  isAdmin,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleContextMenuClose = () => setContextMenu(null);

  const handlePopupOpen = () => setPopupOpen(true);
  const handlePopupClose = () => setPopupOpen(false);

  return (
    <>
      <g
        className={styles.icon}
        onClick={() => toggleNode()}
        onContextMenu={handleContextMenu}
        style={{ cursor: "context-menu" }}
      >
        <circle
          fill={isDarkMode ? "#202940" : "white"}
          strokeWidth={0}
          r="30"
        />
        {nodeDatum.children.length && (
          <g>
            <circle
              strokeWidth="0"
              r="10"
              fill="#5bed37"
              transform="translate(-60,-50)"
            />
            <text
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform="translate(-48,-43)"
            >
              {descendantsData[nodeDatum.id].active}
            </text>
            <circle
              strokeWidth="0"
              r="10"
              fill="#ed4242"
              transform="translate(10,-50)"
            />
            <text
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform="translate(25,-43)"
            >
              {descendantsData[nodeDatum.id].inactive}
            </text>
          </g>
        )}
        <g id={placeholder + identifier}>
          <circle fill="transparent" strokeWidth={0} r="60" />
          <g strokeWidth={0} transform="translate(-30,-30) scale(2.5)">
            <Icon nodeDatum={nodeDatum} color={color} />
          </g>
        </g>
        <g>
          <text
            fill={isDarkMode ? "white" : "black"}
            strokeWidth=".2"
            transform="translate(-35,60)"
          >
            {nodeDatum.name}
          </text>
        </g>
        <ContextMenu
          contextMenuData={contextMenuData}
          contextMenu={contextMenu}
          handleContextMenuClose={handleContextMenuClose}
          assetId={nodeDatum.id}
          ipAddress={nodeDatum.ipAddress}
          handlePopupOpen={handlePopupOpen}
          assetName={nodeDatum.nodeName}
        />
      </g>
      <PopUp
        open={popupOpen}
        isAdmin={isAdmin}
        handleClose={handlePopupClose}
        dashboardId={999999999}
      />
    </>
  );
}

export default Node;
