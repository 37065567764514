
import DefaultCell from "application/components/DefaultCell";
import ViewQueue from "../../../viewQueue";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";


const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  settingsMode: false,
  enableAddAgentButton: false,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  agentList: "/agent/listQueue",
  delete:"agent/delete",
};
const getColumns = (onView) => {
  return [
       {
      Header: "Agent From",
      accessor: "from",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent To",
      accessor: "to",
      displayInList: false,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Agent/User ID",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header:"Agent/User Name",
      accessor: "agentFullName",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Phone Number",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Position",
      accessor: "position",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "queue",
      displayInList: true,
      allowInForm: false,
      Cell: ({row, value }) => <ViewQueue row={row} value={value} onView={onView}  />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };