import DefaultCell from "application/components/DefaultCell";
import CTLSendFaxStatusCell from "application/components/CTLSendFaxStatusCell";

const Attributes = {
  enableAddButton: false,
  enableMultipleDeleteButton: true,
  enableAdvanceSearch: true,
  refetchInterval: 5000,
  modes: [

  ],
};

const operationURLS = {
  list:"sendFaxDetails/list",
  delete: "/sendFaxDetails/delete",
};

const getColumns = (onDelete) => {
  return [
    {
      Header: "Sent On",
      accessor: "createdOn",
      displayInList: true,
      allowFilter: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Sent By",
      accessor: "fromUser",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Sent On",
      accessor: "toUser",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Create By",
      accessor: "createdBy",
      displayInList: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    // {
    //   Header: "Last Status",
    //   accessor: "lastStatus",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    // },
    // {
    //   Header: "Current Status",
    //   accessor: "faxSuccess",
    //   displayInList: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   Cell: ({ value  ,row}) => <CTLSendFaxStatusCell value={value} row={row} />,
    // },
    {
      Header: "Current Status",
      accessor: "faxSuccess",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLSendFaxStatusCell value={value} />,
    },

    {
      Header: "Fax Progress",
      accessor: "faxProgress",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
    },
    // {
    //   Header: "Action",
    //   accessor: "intId",
    //   displayInList: true,
    //   allowInForm: false,
    //   Cell: ({ row, value }) => (
    //     <RowAction
    //       row={row}
    //       value={value}
    //       onDelete={onDelete}
    //     />
    //   ),
    // },
  ];
}


export { getColumns, Attributes, operationURLS };
