import MDTypography from "components/MDTypography";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import CallEndIcon from "@mui/icons-material/CallEnd";

function CTLIntegerCell({ value, suffix }) {
  if (value === 0) {
    suffix = <CallEndIcon fontSize="small" color="error" />;
  } else if (value === 1) {
    suffix = <PhoneEnabledIcon fontSize="small" color="success" />;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLIntegerCell.defaultProps = {
  suffix: "",
};

export default CTLIntegerCell;
