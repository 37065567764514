import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  useMaterialUIController,
  setActivePage,
  setConferenceData,
} from "context";
import { useEffect, useState } from "react";
import CalendarRoot from "./CalendarRoot";
import PersonIcon from "@mui/icons-material/Person";
import TopicIcon from "@mui/icons-material/Topic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import MDButton from "components/MDButton";
import CTLConferenceDelete from "application/components/CTLConferenceDelete";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import CTLConferenceCancel from "application/components/CTLConferenceCancel";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ConferenceParticipant from "../../conferenceParticipant";

function Calendar({
  setOpen,
  setPhoneType,
  setSelectedStartDate,
  setSelectedEndDate,
  setCalendarDate,
  setApiFormData,
  setCalendarKey,
  calendarKey,
  setSchedule,
  setOpenPopup,
  openPopup,
  operationURLS,
  header,
  ...rest
}) {
  const currentDate = new Date();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [type, setType] = useState("dayGridMonth");
  const [eventData, setEventData] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [calendarType, setCalendarType] = useState("month");
  const [participantList, setParticipantList] = useState(null);
  const [disable, setDisable] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    setCalendarKey(Date.now());
  }, [darkMode]);
  const classNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: classNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  const headerToolbar = {
    left: "today prev,next",
    center: "title",
    right: "dayButton,weekButton,monthButton",
  };

  const handleDatesSet = (info) => {
    setCalendarDate(info);
  };

  const customButtons = {
    weekButton: {
      text: "Week",
      click: () => {
        setType("timeGridWeek");
        setCalendarType("week");
        setCalendarKey(Date.now());
      },
    },
    dayButton: {
      text: "Day",
      click: () => {
        setType("timeGridDay");
        setCalendarType("day");
        setCalendarKey(Date.now());
      },
    },
    monthButton: {
      text: "Month",
      click: () => {
        setType("dayGridMonth");
        setCalendarType("month");
        setCalendarKey(Date.now());
      },
    },
  };

  const handleDateClick = (info) => {
    const clickedDate = info.date;
    if (calendarType == "month") {
      clickedDate.setHours(currentDate.getHours());
      clickedDate.setMinutes(currentDate.getMinutes() + 1);
    }
    if (clickedDate >= currentDate) {
      setOpen(true);
      setSelectedStartDate(clickedDate);
    }
  };

  const handleDateSelect = (info) => {
    const StartDate = info.start;
    const EndDate = info.end;
    setSelectedDate(StartDate);
    if (StartDate >= currentDate) {
      setSelectedStartDate(StartDate);
      setSelectedEndDate(EndDate);
      setOpen(true);
    }
  };

  const handleDateUnselect = () => {
    setSelectedDate(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const handleCancel = () => {
    setConfirmCancel(true);
  };

  const handleAgreeCancel = () => {
    const body = {
      modId: eventData.modId,
    };
    application
      .post(operationURLS.cancel, body)
      .then((response) => {
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
    setCalendarKey(Date.now());
    setConfirmCancel(false);
    setOpenPopup(false);
  };

  const onCancel = () => {
    setConfirmCancel(false);
    setConfirmDelete(false);
  };

  const handleUpdate = () => {
    setOpen(true);
    setApiFormData(eventData);
  };

  const handleSchedule = () => {
    setOpen(true);
    setSchedule(true);
    setApiFormData(eventData);
  };

  const handleEventClick = (info) => {
    const json = JSON.parse(info?.event?._def?.publicId);
    //  setConferenceData(dispatch, json);
    //  if (json?.createdBy == AuthenticationService.getUserName()) {
    //    setPhoneType("moderator");
    //  } else {
    //    setPhoneType("participant");
    //  }

    setEventData(json);

    setOpenPopup(true);
    fetchData(json?.modId);
    if (info.event.start < currentDate) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const fetchData = async (modId) => {
    try {
      const response = await application.post(
        `conferenceParticipant/list/${modId}`
      );
      if (response.data) {
        setParticipantList(response.data.data);
      }
    } catch (error) {
      console.error("Failed to load Participant list-", error);
    }
  };

  const handleEventLeave = () => {
    setOpenPopup(false);
    setParticipantList(null);
  };

  const handleDelete = () => {
    setConfirmDelete(true);
  };
  const onAgree = (data) => {
    application
      .post(operationURLS.delete, {
        intId: [data.id],
      })
      .then((response) => {
        setOpenPopup(false);
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.status,
          type: "error",
          pagename: "Conference",
          status: error.response.data.message,
        });
      });
    setOpenPopup(false);
    setConfirmDelete(false);
    setCalendarKey(Date.now());
  };
  const convertTimeTo12HourFormat = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    return formattedTime;
  };

  const handleParticipantDelete = (data) => {
    const body = {
      intId: [data.id],
    };
    application
      .post(operationURLS.participantDelete, body)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.status,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
        fetchData(eventData?.modId);
        setCalendarKey(Date.now());
        handleEventLeave();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
  };

  const handleEventDrop = (info) => {
    const meetingDetails = JSON.parse(info?.event?._def?.publicId);
    const dropEventStartTime = new Date(info.event.start);
    if (dropEventStartTime > currentDate) {
      if (meetingDetails.createdBy == AuthenticationService.getUserName()) {
        var DropStartDate = new Date(info?.event?.start);
        var DropEndDate = new Date(info?.event?.end);
        var formattedDate = DropStartDate.toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        const adjustedFromHour =
          DropStartDate.getHours() === 24 ? "00" : DropStartDate.getHours();

        const formattedFromTime = `${adjustedFromHour
          .toString()
          .padStart(2, "0")}:${DropStartDate.getMinutes()
          .toString()
          .padStart(2, "0")}`;

        const adjustedToHour =
          DropStartDate.getHours() === 24 ? "00" : DropEndDate.getHours();

        const formattedToTime = `${adjustedToHour
          .toString()
          .padStart(2, "0")}:${DropEndDate.getMinutes()
          .toString()
          .padStart(2, "0")}`;

        meetingDetails.meetingScheduledDate = formattedDate;
        meetingDetails.scheduledFromTime = formattedFromTime;
        meetingDetails.scheduledToTime = formattedToTime;
        application
          .post(operationURLS.update, meetingDetails)
          .then((response) => {
            setCalendarKey(Date.now());
          })
          .catch((error) => {
            setCalendarKey(Date.now());
          });
      } else {
        setCalendarKey(Date.now());
        setNotify({
          isOpen: true,
          message: "",
          type: "info",
          pagename: "Conference Event",
          status: `This conference Created By ${meetingDetails.createdBy}, You can't modify`,
        });
      }
    } else {
      setCalendarKey(Date.now());
      setNotify({
        isOpen: true,
        message: "",
        type: "info",
        pagename: "Conference Event",
        status: `This conference can't drag on past day`,
      });
    }
  };

  const joinConference = (data) => {
       setConferenceData(dispatch, data);
       handleEventLeave();
       setPhoneType("participant");
  };

  const startConference = (data) => {
       setConferenceData(dispatch, data);
       handleEventLeave();
       setPhoneType("moderator");
     
 
  };
  return (
    <Card
      style={{
        maxHeight: "calc(100vh - 150px)",
        minHeight: "calc(100vh - 150px)",
        overflow: "auto",
        position: "relative",
      }}
    >
      {eventData && participantList && (
        <Dialog open={openPopup} onClose={handleEventLeave} fullWidth>
          <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <Grid container spacing={1} direction="row">
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {eventData?.createdBy ==
                  AuthenticationService.getUserName() && (
                  <>
                    <tooltip title="Edit Conference">
                      <MDButton
                        color="info"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleUpdate}
                      >
                        <EditIcon style={{ width: "25px", height: "25px" }} />
                      </MDButton>
                    </tooltip>
                    <tooltip title="Re-Schedule Conference">
                      <MDButton
                        color="success"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleSchedule}
                      >
                        <ScheduleIcon
                          style={{ width: "25px", height: "25px" }}
                        />
                      </MDButton>{" "}
                    </tooltip>
                    <tooltip title="Cancel Conference">
                      <MDButton
                        color="warning"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleCancel}
                      >
                        <CancelIcon style={{ width: "25px", height: "25px" }} />
                      </MDButton>{" "}
                    </tooltip>
                    <tooltip title="Delete Conference">
                      <MDButton
                        color="error"
                        style={{ borderRadius: "20%", padding: "0px" }}
                        onClick={handleDelete}
                      >
                        <DeleteIcon style={{ width: "25px", height: "25px" }} />
                      </MDButton>{" "}
                    </tooltip>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <Typography style={{ fontSize: "20px" }}>
              <TopicIcon sx={{ marginRight: 1 }} color="info" />
              {eventData && eventData.roomName}
            </Typography>
            <Typography
              style={{ fontSize: "17px" }}
              display="flex"
              alignItems="center"
            >
              <CalendarTodayIcon sx={{ marginRight: 1 }} color="info" />
              {eventData && eventData.meetingScheduledDate}
            </Typography>
            <Typography
              style={{ fontSize: "17px" }}
              display="flex"
              alignItems="center"
            >
              <AccessTimeIcon sx={{ marginRight: 1 }} color="info" />
              {convertTimeTo12HourFormat(eventData?.scheduledFromTime)}
              &nbsp;To&nbsp;
              {convertTimeTo12HourFormat(eventData?.scheduledToTime)}
            </Typography>
            <Typography
              style={{ fontSize: "17px" }}
              display="flex"
              alignItems="center"
            >
              <AccountCircleIcon sx={{ marginRight: 1 }} color="info" />
              Create By - {eventData && eventData.createdBy == AuthenticationService.getUserName() ? "You" : eventData.createdBy}
            </Typography>
            {/* <Divider />
            <Typography
              variant="h6"
              align="center"
              style={{ fontSize: "18px" }}
            >
              Participant List
            </Typography>
            {participantList && (
              <Box sx={{ flexGrow: 1, maxHeight: 100 }}>
                <MDBox style={{ marginTop: "30px", marginBottom: "20px" }}>
                  <Grid container spacing={1}>
                    {participantList.map((item) => (
                      <Grid item xs={6} key={item.id}>
                        <Card>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar style={{ margin: "5px" }}>
                                <PersonIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="h6">
                                  {item.participantName}
                                </Typography>
                              }
                            />{" "}
                            {eventData?.createdBy ==
                              AuthenticationService.getUserName() && (
                              <IconButton
                                color="error"
                                aria-label="delete"
                                onClick={() => {
                                  handleParticipantDelete(item);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItem>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Box>
            )} */}
          </DialogContent>
          <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            <MDButton color="secondary" onClick={handleEventLeave}>
              Close
            </MDButton>
            <MDButton
              color={
                eventData?.createdBy == AuthenticationService.getUserName()
                  ? "success"
                  : "info"
              }
              disabled={disable}
              onClick={() => {
                eventData?.createdBy == AuthenticationService.getUserName()
                  ? startConference(eventData)
                  : joinConference(eventData);
              }}
            >
              {eventData?.createdBy == AuthenticationService.getUserName()
                ? "Start Conference"
                : "Join Conference"}
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        {type && (
          <FullCalendar
            {...rest}
            initialDate={selectedDate || new Date()}
            key={calendarKey}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            droppable={true}
            eventDrop={handleEventDrop}
            events={events}
            datesSet={handleDatesSet}
            eventClick={handleEventClick}
            eventLeave={handleEventLeave}
            initialView={type}
            height="100%"
            customButtons={customButtons}
            headerToolbar={headerToolbar}
            dateClick={handleDateClick}
            select={handleDateSelect}
            unselect={handleDateUnselect}
            selectMirror={true}
            nowIndicator={true}
            selectOverlap={false}
            selectMinDistance={2}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: "short",
            }}
            slotLabelDidMount={(args) => {
              const label = args.el;
              label.style.color = darkMode ? "white" : "black";
            }}
          />
        )}
      </CalendarRoot>

      <CTLConferenceDelete
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
        eventData={eventData}
      />
      <CTLConferenceCancel
        confirmCancel={confirmCancel}
        handleAgreeCancel={handleAgreeCancel}
        onCancel={onCancel}
        eventData={eventData}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Card>
  );
}

Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
