import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { application } from "globals/endpoints";

function CTLTexanomyCell({ value }) {
 
  const [listOptions, setListOptions] = useState([]);
   useEffect(() => {
     application.post("taxonomyCategory/listAll").then((response) => {
       setListOptions(response.data.data);
     });
   }, []);

  const matchedRole = listOptions?.find((option) => option.code === value);

  return (
    <>
      {matchedRole &&
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {matchedRole?.description}
      </MDTypography>
      }
    </>
  );
}




export default CTLTexanomyCell;
