import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";

const Attributes = {
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/campaignSummary/list",
  csvDownload:"/campaignSummary/downloadCSV",
  pdfDownload: "/complaintDetail/generateAndDownloadPdf",
};

const getColumns = () => {
  return [
    {
      Header: "Lead Upload Date",
      accessor: "leadUploadDate",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Campaign",
      accessor: "campaign",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Total Upload",
      accessor: "upload",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DNCL",
      accessor: "dnCl",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Duplicate",
      accessor: "duplicate",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "valid Upload",
      accessor: "validUpload",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Last Dial Date",
      accessor: "lastDialedOn",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Dial Pending",
      accessor: "dialPending",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Answered",
      accessor: "answered",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Abondoned",
      accessor: "abondoned",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
