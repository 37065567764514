import Icon from "@mui/material/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const menus = [
  {
    name: "Change Password",
    key: "Change-requiredPassword",
    route: "/app/ChangerequiredPassword",
    icon: <Icon fontSize="medium">password</Icon>,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    status : "Logout",
    route: "/site/log-out",
    noCollapse: true,
    icon: (
      <Tooltip title="log-out">
        <Icon fontSize="medium">logout</Icon>
      </Tooltip>
    ),
  },
];

export default menus;
