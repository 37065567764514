import { useEffect } from "react";
import MDBox from "components/MDBox";

function MapImage({
  handleParentRefCurrent,
  imageSrc,
}) {
  useEffect(() => {
    const parent = document.getElementById("cytoscape-parent");
    handleParentRefCurrent(parent);
  }, []);

  return (
      <MDBox
        component="img"
        position="absolute"
        width="100%"
        height="100%"
        src={imageSrc}
        alt="MapImage"
      />
  );
}

export default MapImage;
