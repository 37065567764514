import React, { useState, useEffect , useRef } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { useMaterialUIController } from "context";
import CTLDialogContent from "../styles/CTLDialogContent";
import MDBox from "components/MDBox";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import CTLNotification from "application/components/CTLNotification";
import FormField from "layouts/applications/wizard/components/FormField";
export default function CTLAgentMessageSend({
  onSendMessage,
  onCloseMessageBox,
  onSendClick,
  buttonClick,
}) {
  const [messageValue, setMessageValue] = useState("");
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const handleChange = (event) => {
    const newText = event.target.value.slice(0, 500);
    setMessageValue(newText);
  };

  const handleSendClick = () => {
    if (messageValue != "") {
      onSendClick(messageValue);
      setMessageValue("");
    } else {
      setNotify({
        isOpen: true,
        message: " Unable to send blank message",
        type: "error",
        pagename: "Broadcasts Message",
        status: "error",
      });
    }
  };

  useEffect(() => {
    setOpen(onSendMessage);
  }, [onSendMessage]);

  return (
    <>
      {" "}
      <Dialog
        open={open}
        onClose={onCloseMessageBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Total Message Input Character : 500
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDBox sx={{ width: 400 }}>
            <TextField
              style={{ marginTop: "10px" }}
              id="outlined-basic"
              label={buttonClick}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Divider />
            <textarea
              onChange={handleChange}
              value={messageValue}
              rows={12}
              multiline
              maxLength={500}
              cols={48}
              style={{ background: "none", color: "#ffffff" }}
            />
          </MDBox>
          <div align="right">
            {" "}
            {500 - messageValue.length} character To left
          </div>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={onCloseMessageBox} color="secondary">
            Cancel
          </MDButton>
          <MDButton
            color="success"
            autoFocus
            type="submit"
            onClick={handleSendClick}
          >
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
