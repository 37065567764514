import MDTypography from "components/MDTypography";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";

function CTLBooleanCell({ value, suffix }) {
  if (value === 1) {
    suffix = <EventAvailableIcon fontSize="small" color="success" />;
  }
  if (value === 0) {
    suffix = <EventBusyIcon fontSize="small" color="error" />;
  }
  if (value === 2) {
    suffix = <EventBusyIcon fontSize="small" color="warning" />;
  }
  if (value === true) {
    suffix = <CheckIcon fontSize="small" color="success"  />;
  }
  if (value === false) {
    suffix = <CloseIcon fontSize="small"  color="error"  />;
  } else if (value === null) {
    suffix = <NotInterestedIcon fontSize="small"  color="warning" />;
  }
  return (
    <MDTypography variant="caption" fontWeight="medium">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium"  >
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLBooleanCell.defaultProps = {
  suffix: "",
};

export default CTLBooleanCell;
