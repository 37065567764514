import { forwardRef, useState } from "react";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function AssignSensorPopup({
  undroppedSensors,
  assignSensorPopupOpen,
  setAssignSensorPopupOpen,
  handleAssignSensorPopupClose,
  setCurrentSensorCoordinates,
}) {
  const initialChosenSensor = {
    provisioningId: null,
    sensorCode: null,
  };
  const [chosenSensor, setChosenSensor] = useState(initialChosenSensor);
  const { darkMode } = useMaterialUIController()[0];

  const handleSensorChange = (event, selectedOption) =>
    setChosenSensor(selectedOption);

  return (
    <Dialog
      open={assignSensorPopupOpen}
      TransitionComponent={Transition}
      onClose={() => {
        setCurrentSensorCoordinates(null);
        setAssignSensorPopupOpen(false);
      }}
      fullWidth
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.card
            : theme.palette.white.main,
        })}
      >
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionLabel={(option) =>
            `${option.sensorCode}/${option.provisioningId}`
          }
          autoComplete
          filterSelectedOptions
          onChange={handleSensorChange}
          options={undroppedSensors.map((sensor) => ({
            provisioningId: sensor.provisioningId,
            sensorCode: sensor.sensorCode,
          }))}
          renderTags={() => (
            <Chip
              variant="outlined"
              label={`${chosenSensor.option.label}-${chosenSensor.option.value}`}
            />
          )}
          renderInput={(params) => (
            <TextField
              color={darkMode ? "white" : "black"}
              sx={(theme) => ({
                marginTop: ".5em",
                backgroundColor: darkMode
                  ? theme.palette.dark.main
                  : theme.palette.white.main,
                "& input, & input::placeholder": {
                  color: darkMode ? theme.palette.text.main : "",
                },
              })}
              {...params}
              variant="filled"
              placeholder="Please select a sensor"
            />
          )}
        />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.card
            : theme.palette.white.main,
        })}
      >
        <MDButton
          disabled={
            !chosenSensor ||
            !chosenSensor.provisioningId ||
            !chosenSensor.sensorCode
          }
          variant="gradient"
          color="success"
          onClick={() =>
            handleAssignSensorPopupClose(
              undroppedSensors.filter(
                (sensor) =>
                  sensor.provisioningId === chosenSensor.provisioningId,
              )[0],
            )
          }
        >
          Assign
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AssignSensorPopup;
