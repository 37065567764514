import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";
import CTLUnReadCell from "application/components/CTLUnReadCell";

const Attributes = {
  formSize: "md",
  columns: 3,

  enableAddButton: true,
  enableAdvanceSearch: false,
  enableGroupHeading: false,
  enableAdvanceSearch: true,
  verticalTabs: false,
  tabSize: 4,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "/conferenceMailText/list",
  update: "/conferenceMailText/update",
  register: "/conferenceMailText/create ",
  delete: "/conferenceMailText/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "EmailBody",
      accessor: "emailBody",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 12,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "EmailGreeting",
      accessor: "emailGreeting",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "EmailSignature",
      accessor: "emailSignature",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "SmsBody",
      accessor: "smsBody",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().emailOnly().nullable(),
    },
    {
      Header: "CreatedBy",
      accessor: "createdBy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "CreatedOn",
      accessor: "createdOn",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "date",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",

      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Subject",
      accessor: "subject",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
