import CTLBooleanUser from "application/components/CTLBooleanforUser";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { userMenus } from "globals/configuration/user-management";
import { useMaterialUIController, setDisablePass } from "context";
import UserrMasterCell from "application/components/userMastercell";
import GroupMasterCell from "application/components/userGroupMasterCell";
import RoomMasterCell from "application/components/roomMasterCell";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableMultipleUpdate: true,
  modes: userMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "user/list",
  update: "user/update",
  create: "user/register",
  delete: "user/delete",
};

const getColumns = (onDelete, onEdit) => {
  const [controller, dispatch] = useMaterialUIController();
  const { disbledPasswordField } = controller;

  const onChangePassword = (event, formik) => {
    setDisablePass(dispatch, event.target.checked);
  };
  return [
    {
      Header: "User ID",
      accessor: "username",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Name",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Contact No./Extension No.",
      accessor: "contactNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Email ID",
      accessor: "emailId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Active",
      accessor: "enabled",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      validation: formControl.string().required("Required"),
      Cell: ({ value }) => <CTLBooleanUser value={value} />,
    },
    {
      Header: "Is Default Password",
      accessor: "defaultPassword",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      onChange: onChangePassword,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "Password",
      accessor: "password",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "password",
      disabled: disbledPasswordField,
      // validation: formControl.string().passwordValidation(),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "Confirm Password",
    //   accessor: "confirmPassword",
    //   displayInList: false,
    //   width: 6,
    //   allowInForm: true,
    //   allowFilter: true,
    //   dataType: "string",
    //   controlType: "password",
    //   disabled: disbledPasswordField,
    //   // validation: formControl.string().passwordValidation(),
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    // },
    // {
    //   Header: "Room",
    //   accessor: "nodeId",
    //   displayInList: true,
    //   allowInForm: true,
    //   allowFilter: true,
    //   dataType: "string",
    //   controlType: "multipleSelect",
    //   Cell: ({ value,row }) => <RoomMasterCell value={value} row={row} />,
    //   options: {
    //     keyField: "id",
    //     keyDescription: "nodeName",
    //     values: [],
    //     url: "configuration/list/nodes",
    //   },
    // },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      width: 6,
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4(),
    },
    {
      Header: "Role",
      accessor: "role",
      displayInList: true,
      allowInForm: true,
      width: 6,
      allowFilter: false,
      dataType: "string",
      controlType: "multipleSelect",
      Cell: ({ value, row }) => <UserrMasterCell value={value} row={row} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "role/dropdownList",
      },
    },
    // {
    //   Header: "Group",
    //   accessor: "group",
    //   displayInList: true,
    //   allowInForm: true,
    //   allowFilter: false,
    //   dataType: "string",
    //   controlType: "multipleSelect",
    //   Cell: ({ value }) => <GroupMasterCell value={value} />,
    //   options: {
    //     keyField: "id",
    //     keyDescription: "code",
    //     values: [],
    //     url: "/userGroupMaster/listAll",
    //   },
    // },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
          // onView={onView}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
