import { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactApexChart from "react-apexcharts";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Draggable } from "@hello-pangea/dnd";
import { useGetDataQuery } from "features/apiSlice";
import Dropdown from "../../../Dropdown";
import configs from "./configs";
import { MULTISERIES_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import ContextMenu from "../../ContextMenu";
import multiseriesData from "../../../data/multiseriesData";
import { useMaterialUIController } from "context";

function Multiseries({
  widget,
  cellWidgetInfo,
  widgetHeight,
  index,
  containerType,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let refreshInterval = 0;
  let legendFieldDataType = "string";
  if (widget) {
    getDataURL = widget.getDataURL;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
    legendFieldDataType = widget.legendFieldDataType;
  }

  const darkModeContext = useMaterialUIController();

  const isDarkMode = darkModeContext[0].darkMode;

  const multiseriesQuery = getDataURL
    ? useGetDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const multiseries = multiseriesQuery.data
    ? multiseriesQuery.data
    : multiseriesData;

  const { series, options, height } = configs(
    multiseries,
    isDarkMode,
    legendFieldDataType,
  );

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let secondaryLabel = null;
  let chart = null;
  let error = null;
  let dropdownOptions = MULTISERIES_DROPDOWN_LIST;
  if (widget) {
    if (multiseriesQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      chart = (
        <MDBox height={widgetHeight}>
          <ReactApexChart series={series} options={options} height={height} />
        </MDBox>
      );
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      chart = "";
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    chart = (
      <MDBox height={widgetHeight}>
        <ReactApexChart series={series} options={options} height={height} />
      </MDBox>
    );
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
  }

  const renderChart = (
    <MDBox p={2} position="relative">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">insights</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={dropdownOptions}
          />
        )}
      </MDBox>
      {useMemo(() => chart, [multiseriesQuery.data])}
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderChart}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {renderChart}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Multiseries;
