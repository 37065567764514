import React, { useEffect, useState } from "react";
import Dashboard from "layouts/nms/dashboard";
import { getColumns, Attributes, operationURLS } from "./model";
import { telemetry } from "globals/endpoints";

function nmsMenuDashboard({
  id,
  onLoad,
  selectedValues,
}) {

  const [widgetId, setWidgetId] = useState(null);
  useEffect(() => {
    Attributes && onLoad(Attributes);
    telemetry.get(`${operationURLS.list}/${id}`)
      .then((response) => {
        let dataToDisplay = response.data.data.dashboards[0].widgetId;
        setWidgetId(dataToDisplay);
      })
      .catch((error) => {
        setWidgetId('');
      });
  }, []);
  
  useEffect(() => {
    setWidgetId(selectedValues);
  }, [selectedValues]);
  return (
    <>
      {widgetId && (
        <Dashboard
          id={widgetId}
          isEditable={true}
          userName="admin"
          dashboardType="dashboard"
        />
      )}
    </>
  );
}

export default nmsMenuDashboard;
