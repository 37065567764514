import { FormControlLabel, Switch } from "@mui/material";

function ShowLegendsSwitch({ showLegends, handleShowLegends, level }) {
  return (
    <FormControlLabel
      control={<Switch checked={showLegends} onChange={handleShowLegends} />}
      label="Show legends"
      sx={{
        position: "absolute",
        right: level === 1 ? ".1em" : ".7em",
        top: level === 1 ? "3em" : "7.5em",
        zIndex: 2,
      }}
    />
  );
}

export default ShowLegendsSwitch;
