import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import variables from "globals/variables";
import loggerFactory from "globals/logger/logger-factory";
import AuthenticationService from "application/modules/authentication/AuthenticationService";

class RealtimeUpdate {
  constructor() {
    this.services = null;
    this.telemetry = null;
    this.connected = false;
  }

  connect() {
    this.services = new Client({
      brokerURL: variables.webSocket.services,
      debug: function (str) {
        loggerFactory.debug(
          this.componentName,
          "Web Socket Event : Services",
          str
        );
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      connectHeaders: {
        Authorization: `Bearer ${AuthenticationService.getAuthenticationToken()}`,
      },
      webSocketFactory: () => {
        const sock = new SockJS(variables.webSocket.services);
        return sock;
      },
      onConnect: () => {
        this.services.subscribe(
          `/app/v2/messaging/topic/events`,
          this.onServiceUpdate
        );
      },
    });

    this.telemetry = new Client({
      brokerURL: variables.webSocket.telemetry,
      debug: function (str) {
        loggerFactory.debug(
          this.componentName,
          "Web Socket Event : Telemetry",
          str
        );
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      connectHeaders: {
        Authorization: `Bearer ${AuthenticationService.getAuthenticationToken()}`,
      },
      webSocketFactory: () => {
        const sock = new SockJS(variables.webSocket.telemetry);
        return sock;
      },
      onConnect: () => {
        this.telemetry.subscribe(
          `/app/v2/messaging/topic/events`,
          this.onNMSUpdate
        );
      },
    });

    this.services.activate();

    this.telemetry.activate();
  }

  receiveMessageFor(topic, callback, callbackId) {
    if (!this.connected) {
      this.connect();
      this.connected = true;
    }

    if (!callbackId) {
      callbackId = topic;
    }

    if (topic === "events") {
      AuthenticationService.nmsCallBacks.set(callbackId, callback);
    }

    return true;
  }

  releaseAllSubscriptions() {
    return true;
  }

  onNMSUpdate(message) {
    if (
      AuthenticationService.nmsCallBacks &&
      AuthenticationService.allownmsMenus()
    ) {
      for (let [key, value] of AuthenticationService.nmsCallBacks) {
        loggerFactory.debug(
          "Message Received ",
          `Message Received on Telemetry Channel for : events : forwarding message to : ${key}`,
          message.body
        );
        value(message);
      }
    }
  }

  onServiceUpdate(message) {
    if (AuthenticationService.nmsCallBacks) {
      for (let [key, value] of AuthenticationService.nmsCallBacks) {
        loggerFactory.debug(
          "Message Received ",
          `Message Received on Service Channel for : events : forwarding message to : ${key}`,
          message.body
        );
        value(message);
      }
    }
  }
}

const NotificationManager = new RealtimeUpdate();

export default NotificationManager;
