import { Language, Settings } from "@material-ui/icons";
import { PunchClockOutlined, TryOutlined, Webhook } from "@mui/icons-material";
import TreeTabs from "../Tabs/TreeTabs";
import APITabs from "../Tabs/APITabs";
import PlayFileTabs from "../Tabs/PlayFileTabs";
import LinkTabs from "../Tabs/LinkTabs";
import ExtensionTabs from "../Tabs/ExtensionTabs";
import DefaultColumns from "../columns/DefaultColumns";
import ExtensionColumns from "../columns/ExtensionColumns";
import PlayFileColumns from "../columns/PlayFileColumns";
import LinkColumns from "../columns/LinkColumns";
import APIColumns from "../columns/APIColumns";
import TreeColumns from "../columns/TreeColumns";


const nextDTMF = {
  1: "2",
  2: "3",
  3: "4",
  4: "5",
  5: "6",
  6: "7",
  7: "8",
  8: "9",
  9: "0",
  0: "*",
  "*": "#",
};

const prevDTMF = {
  2: "1",
  3: "2",
  4: "3",
  5: "4",
  6: "5",
  7: "6",
  8: "7",
  9: "8",
  0: "9",
  "*": "0",
  "#": "*",
};

const dtmfValue = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  0: 10,
  "*": 11,
  "#": 12,
};

const EmptyTreeData = {
  name: "P",
  key: "P",
  dtmf: null,
  children: [],
};

const Attributes = {
  formSize: "lg",
  columns: 1,
  enableAddButton: false,
  enablePublishButton: false,
  modes: [],
  settingsMode: false,
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
  verticalTabs: true,
  tabSize: 5,
  tabs: [
    {
      id: 0,
      name: "General",
      icon: <Settings></Settings>,
    },
    {
      id: 1,
      name: "Input Configuration",
      icon: <PunchClockOutlined></PunchClockOutlined>,
    },
    {
      id: 2,
      name: "Input Retry",
      icon: <TryOutlined></TryOutlined>,
    },
    {
      id: 3,
      name: "Language",
      icon: <Language></Language>,
    },
    {
      id: 4,
      name: "API",
      icon: <Webhook></Webhook>
    }
  ],
};

const getAttributes = (nodeType, propertyKey) => {
  let returnValue = { ...Attributes };

  if (nodeType == "TREE" && propertyKey != "P") {
    returnValue.tabs = TreeTabs;
  } else if (nodeType == "API") {
    returnValue.tabs = APITabs;
  } else if (nodeType == "PF") {
    returnValue.tabs = PlayFileTabs;
  } else if (nodeType == "LTS") {
    returnValue.tabs = LinkTabs;
  } else if (nodeType == "EXT") {
    returnValue.tabs = ExtensionTabs;
  }

  return returnValue;
};

const operationURLS = {
  list: "/systemConfig/list",
  update: "/systemConfig/update",
  register: null,
  delete: null,
  publish: null,
};

const getColumns = (nodeType, propertyKey, data) => {
  if (nodeType == "EXT") {
    return ExtensionColumns;
  } else if (nodeType == "PF") {
    return PlayFileColumns;
  } else if (nodeType == "LTS") {
    return LinkColumns(data);
  } else if (nodeType == "API") {
    return APIColumns();
  } else if (nodeType == "TREE" && propertyKey != "P") {
    return TreeColumns;
  } else {
    return DefaultColumns;
  }
};

export { getColumns, Attributes, getAttributes, operationURLS, nextDTMF, prevDTMF, dtmfValue, EmptyTreeData };
