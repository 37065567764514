


import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: true,
};

function getColorBox(value) {
  return (
    <div style={{ backgroundColor: value, width: "30px", height: "30px", borderRadius:"50%" }}>
    </div>
  );
}


const operationURLS = {
  list: "severity/list",
  update: "severity/update",
  create: "severity/create",
  delete: "severity/delete",
};


const getColumns = (onDelete, onEdit, onColorUpdate) => {
  return [
    {
      Header: "Severity Name",
      accessor: "severityName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Severity Level",
      accessor: "severityLevel",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Color",
      accessor: "color",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "colorPicker",
      Cell: ({ value }) => getColorBox(value),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
