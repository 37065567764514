import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: false,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: true,
};

const operationURLS = {
  list: "/dashboardGroup/list",
  update: "/dashboardGroup/update",
};

const getColumns = (onEdit) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "stringId",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction row={row} value={value} onEdit={onEdit} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
