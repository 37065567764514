import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MDBox from "components/MDBox";
import downloadPDF from "../widgets/tables/ServerTable/exportTypes/pdf";
import downloadCSV from "../widgets/tables/ServerTable/exportTypes/csv";
import { DROPDOWN_OPTIONS } from "../configs/dropdownConfigs";
import { WIDGET_TYPES } from "../configs/widgetsConfigs";

function Dropdown({
  masterContainerId,
  cellId,
  handleDeleteWidget,
  index,
  options,
  filteredItems,
  setLayoutData,
  assetColumns,
  primaryLabel,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdown = (option) => {
    let newWidgetType = null;
    switch (option) {
      case DROPDOWN_OPTIONS.piechart:
        newWidgetType = WIDGET_TYPES.piechart;
        break;
      case DROPDOWN_OPTIONS.doughnutchart:
        newWidgetType = WIDGET_TYPES.doughnutchart;
        break;
      case DROPDOWN_OPTIONS.barchart:
        newWidgetType = WIDGET_TYPES.barchart;
        break;
      case DROPDOWN_OPTIONS.linechart:
        newWidgetType = WIDGET_TYPES.linechart;
        break;
      default:
        break;
    }

    switch (option) {
      case DROPDOWN_OPTIONS.delete:
        handleDeleteWidget(masterContainerId, cellId, index);
        break;
      case DROPDOWN_OPTIONS.exportToCSV:
        downloadCSV(filteredItems, primaryLabel);
        break;
      case DROPDOWN_OPTIONS.exportToPDF:
        downloadPDF(filteredItems, assetColumns, primaryLabel);
        break;
      default:
        // case for switching chart types
        setLayoutData((prevLayoutData) => ({
          ...prevLayoutData,
          [masterContainerId]: prevLayoutData[masterContainerId].map((cell) =>
            cell.celId === cellId
              ? {
                  ...cell,
                  widgetIds: cell.widgetIds.map((widgetId, ind) =>
                    ind === index
                      ? {
                          id: widgetId.id,
                          widgetType: newWidgetType,
                        }
                      : widgetId
                  ),
                }
              : cell
          ),
        }));
        break;
    }

    setAnchorEl(null);
  };

  return (
    <MDBox mt={-1}>
      <IconButton onClick={handleClick}>
        <MoreVertIcon
          sx={() => ({
            color: "#7b809a",
          })}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: "200px",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleDropdown(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </MDBox>
  );
}

export default Dropdown;
