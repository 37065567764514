import CTLDownloadFile from "application/components/CTLDownloadFile";
import ReportPlayAudio from "application/components/CTLPlayAudioCell";
import DefaultCell from "application/components/DefaultCell";
import { agentReportMoreVert } from "globals/configuration/agentReportMoreVert";

const Attributes = {
  enableUpdateTableRecords: true,
  enableDownloadButton: true,
  enableAdvanceSearch: true,
  enableDownloadPdf: true,
  enableExitButton: true,
  enableRefresh:true,
  modes: agentReportMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "receivedCall/list",
  pdfDownload: "/receivedCall/generateAndDownloadPdf",
  csvDownload: "/receivedCall/downloadCSV",
};

const getColumns = () => {
  return [
    // {
    //   Header: "Direction",
    //   accessor: "direction",
    //   displayInList: true,
    //   allowInForm: false,
    //   allowFilter: false,
    //   dataType: "string",
    //   controlType: "boolean",
    //   Cell: ({ value }) => <DefaultCell value={value} />,
    // },
    {
      Header: "Queue",
      accessor: "queueName",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dateTime: true,
      dataType: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Customer Number",
      accessor: "customerNumber",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Talk Duration",
      accessor: "talkDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "integer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Play",
      accessor: "uuid",
      displayInList: true,
      Cell: ({ row, value }) => <ReportPlayAudio row={row} value={value} />,
    },
    {
      Header: "Download File",
      accessor: "",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      allowInForm: false,
      Cell: ({ row, value }) => (
        <CTLDownloadFile row={row} value={value} lastAgentCdr="lastAgentCdr" />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
