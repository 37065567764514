const nodeConfigurationMenus = [
  {
    icon: "workspaces",
    key: "configuration.node",
    name: "Node Configuration",
    url: "home/app/nodeConfiguration",
    description: "Node Configuration",
    disabled: false,
    productionReady: true,
  },
];

export { nodeConfigurationMenus };
