function CTLDialogContent(theme, ownerState) {
    const { palette, functions } = theme;
    const { rgba } = functions;
    const { white, background } = palette;
    const { darkMode } = ownerState;

    return {
        backgroundColor: rgba(darkMode ? background.default : white.main, 0.8),
    }
}

export  default CTLDialogContent
