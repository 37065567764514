import { useState, createElement } from "react";
import styles from "../css/Topology.module.css";
import ContextMenu from "../ContextMenu";
import PopupTopology from "../PopupTopology";
import ASSETS_CATEGORIES from "../../../configs/assetsConfigs";
import variables from "globals/variables";
import getNMSComponent from "globals/nms-components";
import PopupLocationAssets from "../PopupLocationAssets";
import Icon from "../Icon";

function LevelOneTopologyNode({
  isDarkMode,
  nodeDatum,
  descendantsData,
  color,
  contextMenuData,
  categoryData,
  severityData,
  isAdmin,
  isEditMode,
  handleDisableGraphTopoDrag,
  handleEnableGraphTopoDrag,
  displayOptions,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const [popupTopologyOpen, setPopupTopologyOpen] = useState(false);
  const [smartAssetPopupOpen, setSmartAssetPopupOpen] = useState(false);
  const [popupLocationAssetsOpen, setPopupLocationAssetsOpen] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (!popupTopologyOpen) {
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null,
      );
      handleDisableGraphTopoDrag();
      event.stopPropagation();
    }
  };
  const handleClose = () => setContextMenu(null);

  const handlePopupTopologyOpen = () => {
    setPopupTopologyOpen(true);
    handleClose();
  };
  const handlePopupTopologyClose = () => {
    setPopupTopologyOpen(false);
    handleEnableGraphTopoDrag();
  };

  const handleSmartAssetPopupOpen = () => {
    handleDisableGraphTopoDrag();
    setSmartAssetPopupOpen(true);
    handleClose();
  };
  const handleSmartAssetPopupClose = () => {
    setSmartAssetPopupOpen(false);
    handleEnableGraphTopoDrag();
  };

  const handleManagementInterface = (managementURL) => {
    if (managementURL === null) {
      alert("No Management Interface URL found for this asset.");
      handleClose();
      handleEnableGraphTopoDrag();
      return;
    }

    const baseURL = variables.api.telemetry;
    const dollar = "$";
    let URL = null;
    URL = managementURL.replace(`${dollar}{{baseURL}}`, baseURL);
    URL = URL.replace("{id}", nodeDatum.id);
    URL = URL.replace("{{ipAddress}}", nodeDatum.ipAddress);
    URL = URL.replace("{{ipAddres}}", nodeDatum.ipAddress);

    window.open(URL, "_blank");
    handleClose();
    handleEnableGraphTopoDrag();
  };

  const handleDashboard = () => handleSmartAssetPopupOpen();
  const componentRender = getNMSComponent(nodeDatum.category);

  const handleNodeClick = () => {
    handleDisableGraphTopoDrag();
    setPopupLocationAssetsOpen(true);
  };

  const handlePopupLocationAssetsClose = () => {
    setPopupLocationAssetsOpen(false);
    handleEnableGraphTopoDrag();
  };

  let indicatorTimeoutId = null;
  return (
    <>
      <g
        className={styles.icon}
        style={{ cursor: "pointer" }}
        onMouseEnter={() => {
          if (!isIndicatorVisible)
            indicatorTimeoutId = setTimeout(
              () => setIsIndicatorVisible(!isIndicatorVisible),
              300,
            );
        }}
        onMouseLeave={() => {
          clearTimeout(indicatorTimeoutId);
          if (isIndicatorVisible) setIsIndicatorVisible(!isIndicatorVisible);
        }}
      >
        {nodeDatum.level === 1 && (
          <circle
            stroke="purple"
            strokeWidth="1.5"
            fill={isDarkMode ? "#202940" : "white"}
            r="12"
          />
        )}
        {nodeDatum.children.length && isIndicatorVisible && (
          <g>
            <circle
              strokeWidth="0"
              r="5"
              fill="#5bed37"
              transform={
                nodeDatum.level > 1 ? "translate(-9,-50)" : "translate(-9,-55)"
              }
            />
            <text
              fontFamily="sans-serif"
              fontSize=".7em"
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform={
                nodeDatum.level > 1 ? "translate(1,-46)" : "translate(1,-51)"
              }
            >
              {descendantsData[nodeDatum.id].active}
            </text>
            <circle
              strokeWidth="0"
              r="5"
              fill="#ed4242"
              transform={
                nodeDatum.level > 1 ? "translate(-9,-35)" : "translate(-9,-40)"
              }
            />
            <text
              fontFamily="sans-serif"
              fontSize=".7em"
              fill={isDarkMode ? "white" : "black"}
              strokeWidth=".2"
              transform={
                nodeDatum.level > 1 ? "translate(1,-31)" : "translate(1,-36)"
              }
            >
              {descendantsData[nodeDatum.id].inactive}
            </text>
          </g>
        )}
        {nodeDatum.category === ASSETS_CATEGORIES.LOCATION ? (
          <g onClick={handleNodeClick} onContextMenu={handleContextMenu}>
            <circle
              fill="transparent"
              strokeWidth={0}
              r="25"
              transform="translate(0, -15)"
            />
            <g strokeWidth={0} transform="translate(-18,-33) scale(1.5)">
              <Icon category={nodeDatum.category} color={color} />
            </g>
          </g>
        ) : (
          <circle
            onClick={handleNodeClick}
            onContextMenu={handleContextMenu}
            stroke="black"
            strokeWidth=".5"
            transform="scale(1.3)"
            fill={color}
            r="5"
          />
        )}
        {isIndicatorVisible &&
          nodeDatum.category !== ASSETS_CATEGORIES.LOCATION && (
            <text
              fontFamily="sans-serif"
              fontSize=".8em"
              fill={isDarkMode ? "white" : "black"}
              x="-15"
              y={nodeDatum.level === 1 ? "24" : "20"}
            >
              {nodeDatum.name}
            </text>
          )}
        {nodeDatum.category === ASSETS_CATEGORIES.LOCATION &&
          displayOptions.locationName && (
            <text
              fontFamily="sans-serif"
              fontSize=".8em"
              fill={isDarkMode ? "white" : "black"}
              x="-15"
              y={nodeDatum.level === 1 ? "24" : "20"}
            >
              {nodeDatum.name}
            </text>
          )}
        <ContextMenu
          contextMenuData={contextMenuData}
          contextMenu={contextMenu}
          handleClose={handleClose}
          nodeDatum={nodeDatum}
          handlePopupTopologyOpen={handlePopupTopologyOpen}
          handleManagementInterface={handleManagementInterface}
          handleEnableGraphTopoDrag={handleEnableGraphTopoDrag}
          handleDashboard={handleDashboard}
        />
        <PopupTopology
          nodeDatum={nodeDatum}
          categoryData={categoryData}
          descendantsData={descendantsData}
          severityData={severityData}
          popupTopologyOpen={popupTopologyOpen}
          handlePopupTopologyClose={handlePopupTopologyClose}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
        <PopupLocationAssets
          popupLocationAssetsOpen={popupLocationAssetsOpen}
          handlePopupLocationAssetsClose={handlePopupLocationAssetsClose}
          location={nodeDatum.name}
          descendantsData={descendantsData}
          categoryData={categoryData}
          severityData={severityData}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
      </g>
      {smartAssetPopupOpen &&
        componentRender &&
        createElement(componentRender, {
          open: smartAssetPopupOpen,
          handleClose: handleSmartAssetPopupClose,
          isAdmin: isAdmin,
          isEditMode: isEditMode,
          primaryLabel: `${nodeDatum.category}/${nodeDatum.name}`,
          assetId: nodeDatum.id,
          assetIpAddress: nodeDatum.ipAddress,
          assetImageName: `/images/${contextMenuData.imageName}`,
          assetImageWidth: contextMenuData.width,
          assetImageHeight: contextMenuData.height,
          category: nodeDatum.category,
        })}
    </>
  );
}

export default LevelOneTopologyNode;
