import DefaultCell from "application/components/DefaultCell";
import { reportMenus } from "globals/configuration/reports";


const Attributes = {
  enableAddButton: false,
  enableUpdateTableRecords: true,
  enableAdvanceSearch: true,
  enableDownloadButton: true,
  enableDownloadPdf: true,

  modes: reportMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "/acdSummary/list",
  pdfDownload: "/acdSummary/generateAndDownloadPdf", 
  csvDownload: "acdSummary/downloadCSV",
};

const getColumns = () => {
  return [
    {
      Header: "Operator Name",
      accessor: "agentFullName",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Calls",
      accessor: "totalCalls",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Inbound Answered",
      accessor: "totalConnectedWithAgentIncoming",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Operator talk time",
      accessor: "totalCustomerAgentTalkTime",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      dateTime: false,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total OutBound Calls",
      accessor: "totalDialed",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Outbound connected",
      accessor: "totalConnectedWithAgentOutgoing",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Total Outbound Not Connected",
      accessor: "totalNoContact",
      displayInList: true,
      allowFilter: false,
      dataType: "integer",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Date",
      accessor: "recordEpoch",
      displayInList: false,
      displayInReport:false,
      displayinReport: "recordEpoch",
      allowFilter: true,
      dataType: "date",
      dateTime: true,
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
