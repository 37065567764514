import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { useTheme } from "@mui/material/styles";
import Badge from '@mui/material/Badge';
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "features/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import { Stack } from "@mui/system";

function SidenavCollapse({ icon, badge, name, children, active, noCollapse, open, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const theme = useTheme();

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode })
         }
        >
    
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
          >
            <Badge badgeContent={badge} color="error"></Badge>
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { transparentSidenav, whiteSidenav, darkMode })}>{icon}</Icon>
            ) : (
              icon
            )}
             
          </ListItemIcon>
          {/* <MDBox
         
         sx={{justifyContent:"flex-start" , display: "flex" , alignItem :"right"}}
       >
          <MDTypography  variant ="caption">{name}
             </MDTypography>
        </MDBox> */}
      
          </MDBox>
          </ListItem>
          <MDBox>
      </MDBox>
    
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
