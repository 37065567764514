const locationAssetsListColumns = [
  {
    field: "id",
    heading: "ID",
    type: "string",
    display: true,
  },
  {
    field: "severityText",
    heading: "Severity Text",
    type: "string",
    display: true,
  },
  {
    field: "severity",
    heading: "Severity",
    type: "severity",
    display: true,
  },
  {
    field: "name",
    heading: "Node name",
    type: "string",
    display: true,
  },
  {
    field: "category",
    heading: "Category",
    type: "string",
    display: true,
  },
  {
    field: "status",
    heading: "Status",
    type: "boolean",
    display: true,
  },
  {
    field: "ipAddress",
    heading: "IP Address",
    type: "string",
    display: true,
  },
  {
    field: "location",
    heading: "Location",
    type: "string",
    display: false,
  },
  {
    field: "severityUpdatedOn",
    heading: "Severity Updated On",
    type: "epoch",
    display: true,
  },
];

export default locationAssetsListColumns;
