import { useEffect, useState, useRef } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import MDButton from "components/MDButton";
import dialog from "assets/theme/components/dialog";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import CTLForm from "./form";

function userLocation({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const componentName = "User Location";

  const [controller] = useMaterialUIController();
  const { activePage, darkMode } = controller;
  const [formData, setFormData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [updateList, setUpdateList] = useState([Date.now()]);
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowsRef = useRef([]);

  const [open, setOpen] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onMultipleDelete: handleDelete,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }
  function onOpenForm() {
    setOpenForm(true);
  }

  function onCloseForm() {
    setOpenForm(false);
    setFormData({});
  }

  const onSelectionChange = (id) => {
    setSelectedRows(id);
  };

  useEffect(() => {
    selectedRowsRef.current = selectedRows;
  }, [selectedRows]);

  const handleClickClose = () => {
    setUpdateList(Date.now());
    setOpen(false);
  };

  const handleDelete = () => {
    if (
      selectedRowsRef.current != null &&
      selectedRowsRef.current.length !== 0
    ) {
      setOpen(true);
    } else {
      setNotify({
        isOpen: true,
        message: "Error",
        type: "error",
        pagename: pageName,
        status: "Select at least one use to delete",
      });
    }
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    }
    setOpenForm(false);
  }

  function onMultipleDelete(data) {
    application
      .post(operationURLS.delete, data)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message:
            response.data.message + " " + response.data.messageDetail,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onEdit = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const selectedDelete = () => {
    const data = { stringId: selectedRows };
    onMultipleDelete(data);
    setOpen(false);
  };

  const onAgree = (selectedRow) => {
    const data = { stringId: [selectedRow.id] };
    onMultipleDelete(data);
    setConfirmDelete(false);
  };

  const onCancel = () => {
    setConfirmDelete(false);
  };

  return (
    <MDBox>
      <DeleteConfirmation
        entityName={componentName}
        data={selectedRow}
        columns={getColumns(deleteRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLForm
        entityName={componentName}
        columns={getColumns(deleteRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLTable
        onSelectionChange={onSelectionChange}
        apiURL={operationURLS.list}
        columns={getColumns(deleteRow)}
        advancedFilters={advancedFilters}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={true}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
      />

      <CTLAdvancedFilter
        entityName={componentName}
        columns={getColumns(deleteRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
        advancedFilters={advancedFilters}
      />

      <Dialog open={open} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Are you sure you want to delete the selected user location{" "}
        </DialogTitle>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClickClose} color="secondary">
            Disagree
          </MDButton>
          <MDButton onClick={selectedDelete} color="error" autoFocus>
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default userLocation;
