import { v4 as uuidV4 } from "uuid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function ContextMenu({
  contextMenu,
  handleClose,
  handlePopupLocationAssetsOpen,
}) {
  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <MenuItem key={uuidV4()} onClick={handlePopupLocationAssetsOpen}>
        Open Assets
      </MenuItem>
    </Menu>
  );
}

export default ContextMenu;
