import { useState, useEffect } from "react";
import Draggable from "react-draggable";
import PopupLocationTree from "../PopupLocationTree";
import { Menu, MenuItem } from "@mui/material";
import LabelPopUp from "../LabelPopUp";
import Tooltip from "./Tooltip";
import MDBox from "components/MDBox";
import { SMART_TOPOLOGY_MARKER_SIZE } from "../../../configs/smartTopologyConfigs";
import PopupLocationAssets from "../PopupLocationAssets";

function getLocationTreeNodesColor(location, descendantsData, severityData) {
  if (!descendantsData[location.uuid]) return "#000";

  const locationNodeSeverity = descendantsData[location.uuid].severity || 0;

  const severityObj = severityData.filter(
    (severity) => severity.severityLevel === locationNodeSeverity,
  )[0];

  if (!severityObj)
    console.log(
      `No configuration found for severity level ${locationNodeSeverity}`,
    );

  const severityColor = severityObj.color;

  return severityColor;
}

function Location({
  isAdmin,
  isEditMode,
  location,
  descendantsData,
  categoryData,
  severityData,
  data,
  threshold,
  handleLocationDragStop,
  setDroppedLocations,
  setUndroppedLocations,
  handleSaveConfiguration,
  smartTopologyQuery,
}) {
  const [popupLocationTreeOpen, setPopupLocationTreeOpen] = useState(false);
  const [subNetworkRoot, setSubNetworkRoot] = useState(null);
  const [popupLocationTreeNodeColor, setPopupLocationTreeNodeColor] =
    useState(null);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const [labelPopUpOpen, setLabelPopUpOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [popupContextMenu, setPopupContextMenu] = useState(null);
  const [popupLocationAssetsOpen, setPopupLocationAssetsOpen] = useState(false);

  const handlePopupContextMenu = (event) => {
    event.preventDefault();
    setPopupContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
    event.stopPropagation();
  };

  const handlePopupContextMenuClose = () => setPopupContextMenu(null);

  const handlePopupLocationAssetsOpen = () => setPopupLocationAssetsOpen(true);

  const handlePopupLocationAssetsClose = () => {
    setPopupLocationAssetsOpen(false);
    setPopupContextMenu(null);
  };

  const markerSize = SMART_TOPOLOGY_MARKER_SIZE;

  const getSubNetworkOrigin = () => {
    let subNetworkOrigin = null;
    let subNetwork = data;

    if (descendantsData[location.uuid])
      descendantsData[location.uuid].path.forEach((nodeId) => {
        const node = subNetwork.find((node) => node.id === nodeId);

        if (node) {
          subNetworkOrigin = node;
          subNetwork = node.children;
        }
      });

    return subNetworkOrigin;
  };

  useEffect(() => {
    const subNetworkOrigin = getSubNetworkOrigin();
    const nodeColor = getLocationTreeNodesColor(
      location,
      descendantsData,
      severityData,
    );
    setSubNetworkRoot(subNetworkOrigin);
    setPopupLocationTreeNodeColor(nodeColor);
  }, [smartTopologyQuery.data]);

  const handlePopupLocationTreeOpen = () => setPopupLocationTreeOpen(true);

  const handlePopupLocationTreeClose = () => setPopupLocationTreeOpen(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleLabelPopupOpen = () => {
    setLabelPopUpOpen(true);
    setContextMenu(null);
  };

  const handleDeleteLocation = () => {
    const newLocation = {
      id: location.id,
      xcoordinate: "",
      ycoordinate: "",
      configuration: "",
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.filter(
        (targetLocation) => targetLocation.uuid !== newLocation.uuid,
      ),
    );
    setUndroppedLocations((prevUndroppedLocations) => [
      ...prevUndroppedLocations,
      newLocation,
    ]);
    setContextMenu(null);
    handleSaveConfiguration(newLocation);
  };

  const handleLabelPopupClose = (labelConfig, labelAssociatedLocation) => {
    const newLocation = {
      ...labelAssociatedLocation,
      configuration: {
        ...labelAssociatedLocation.configuration,
        labelText: labelConfig.text,
        labelOrientation: labelConfig.orientation,
        labelFontSize: labelConfig.fontSize,
        showSummary: labelConfig.showSummary,
      },
    };
    setDroppedLocations((prevDroppedLocations) =>
      prevDroppedLocations.map((location) =>
        location.uuid === labelAssociatedLocation.uuid ? newLocation : location,
      ),
    );
    setLabelPopUpOpen(false);
    handleSaveConfiguration(newLocation);
  };

  let indicatorTimeoutId = null;

  return (
    <>
      {descendantsData[location.uuid] && location.configuration.showSummary && (
        <Tooltip
          location={location}
          active={descendantsData[location.uuid].active}
          inactive={descendantsData[location.uuid].inactive}
        />
      )}
      {descendantsData[location.uuid] &&
        !location.configuration.showSummary &&
        isIndicatorVisible && (
          <Tooltip
            location={location}
            active={descendantsData[location.uuid].active}
            inactive={descendantsData[location.uuid].inactive}
          />
        )}
      <Draggable
        position={{
          x: location.xcoordinate - markerSize / 2,
          y: location.ycoordinate - markerSize / 2,
        }}
        onStop={(e, ui) => handleLocationDragStop(ui.lastX, ui.lastY, location)}
        disabled={!isAdmin || !isEditMode}
      >
        <MDBox
          position="absolute"
          component="div"
          width={`${markerSize}px`}
          height={`${markerSize}px`}
          sx={{
            cursor: "pointer",
            backgroundColor: popupLocationTreeNodeColor,
            borderRadius: "50%",
          }}
          border="2px solid black"
          onDoubleClick={handlePopupLocationTreeOpen}
          onMouseEnter={() => {
            if (!isIndicatorVisible)
              indicatorTimeoutId = setTimeout(
                () => setIsIndicatorVisible(!isIndicatorVisible),
                300,
              );
          }}
          onMouseLeave={() => {
            clearTimeout(indicatorTimeoutId);
            if (isIndicatorVisible) setIsIndicatorVisible(!isIndicatorVisible);
          }}
          onContextMenu={(e) => {
            if (isAdmin && isEditMode) handleContextMenu(e);
            else if (!isEditMode) handlePopupContextMenu(e);
          }}
        />
      </Draggable>
      {isAdmin && isEditMode && (
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleLabelPopupOpen}>Edit label</MenuItem>
          <MenuItem onClick={handleDeleteLocation}>Delete location</MenuItem>
        </Menu>
      )}
      {!isEditMode && (
        <Menu
          open={popupContextMenu !== null}
          onClose={handlePopupContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            popupContextMenu !== null
              ? { top: popupContextMenu.mouseY, left: popupContextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handlePopupLocationAssetsOpen}>
            Open Assets
          </MenuItem>
        </Menu>
      )}
      <LabelPopUp
        location={location}
        labelPopUpOpen={labelPopUpOpen}
        handleLabelPopupClose={handleLabelPopupClose}
        setLabelPopUpOpen={setLabelPopUpOpen}
      />
      {subNetworkRoot && subNetworkRoot.children.length > 0 && (
        <PopupLocationTree
          nodeDatum={subNetworkRoot}
          categoryData={categoryData}
          descendantsData={descendantsData}
          severityData={severityData}
          threshold={threshold}
          popupLocationTreeOpen={popupLocationTreeOpen}
          handlePopupLocationTreeClose={handlePopupLocationTreeClose}
          isAdmin={isAdmin}
          isEditMode={isEditMode}
        />
      )}
      <PopupLocationAssets
        popupLocationAssetsOpen={popupLocationAssetsOpen}
        handlePopupLocationAssetsClose={handlePopupLocationAssetsClose}
        location={location.name}
        descendantsData={descendantsData}
        categoryData={categoryData}
        severityData={severityData}
        isAdmin={isAdmin}
        isEditMode={isEditMode}
      />
    </>
  );
}

export default Location;
