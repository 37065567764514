import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: false,
  enableGroupHeading: false,
  verticalTabs: false,
  tabSize: 4,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "/discoverSubnet/list",
  update: "/discoverSubnet/update",
  register: "/discoverSubnet/create ",
  delete: "/discoverSubnet/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "IP",
      accessor: "ip",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "ip",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().ipv4().required("Required"),
    },
    {
      Header: "Subnet",
      accessor: "subnet",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl
        .string()
        .digitsOnly()
        .lenthDefination31()
        .required("Required"),
    },
    {
      Header: "IP Type",
      accessor: "ipType",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "IPv4" }, { description: "IPv6" }],
      },
    },
    {
      Header: "Location",
      accessor: "location",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      tabId: 0,
      groupHeading: "Scada Asset",
      options: {
        keyField: "locationCode",
        keyDescription: "locationName",
        values: [],
        url: "location/dropdown/list",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
