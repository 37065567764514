import DefaultCell from "application/components/DefaultCell";
import TelephoneCell from "application/components/TelephoneCell";



const Attributes = {
  enableAddButton: false,
  enableAdvanceSearch: true,
  refetchInterval:5000,
  modes: [
  ],
};


const operationURLS = {
  list: "uc/missedCall/list",
  Update: "uc/update/4",
};

const getColumns = ( onCall) => {
  
  return [    
    {
      Header: "Call Time",
      accessor: "epoch",
      displayInList: true,
      allowFilter:true,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Caller Extension",
      accessor: "entityNumber",
      displayInList: true,
      allowFilter:false,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value}  onCall={onCall} />,
    },
    {
      Header: "Caller Name",
      accessor: "localName",
      displayInList: true,
      allowFilter:false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value}/>,
    },
    
    
  ];
};

export { getColumns, Attributes , operationURLS };
