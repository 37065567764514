export default function computeSidebarColumns(sidebar, breakpoint) {
  let sidebarColumnsSum = 0;
  Object.keys(sidebar).forEach((bar) => {
    switch (breakpoint) {
      case "xxl":
        sidebarColumnsSum +=
          sidebar[bar].width.xxl ||
          sidebar[bar].width.xl ||
          sidebar[bar].width.lg ||
          sidebar[bar].width.md ||
          sidebar[bar].width.sm ||
          sidebar[bar].width.xs ||
          0;
        break;

      case "xl":
        sidebarColumnsSum +=
          sidebar[bar].width.xl ||
          sidebar[bar].width.lg ||
          sidebar[bar].width.md ||
          sidebar[bar].width.sm ||
          sidebar[bar].width.xs ||
          0;
        break;
      case "lg":
        sidebarColumnsSum +=
          sidebar[bar].width.lg ||
          sidebar[bar].width.md ||
          sidebar[bar].width.sm ||
          sidebar[bar].width.xs ||
          0;
        break;
      case "md":
        sidebarColumnsSum +=
          sidebar[bar].width.md || sidebar[bar].width.sm || sidebar[bar].width.xs || 0;
        break;
      case "sm":
        sidebarColumnsSum += sidebar[bar].width.sm || sidebar[bar].width.xs || 0;
        break;
      case "xs":
        sidebarColumnsSum += sidebar[bar].width.xs || 0;
        break;
      default:
        sidebarColumnsSum += 0;
        break;
    }
  });
  return sidebarColumnsSum;
}
