import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import loggerFactory from "globals/logger/logger-factory";
import CTLDirtyClean from "application/components/DirtyClean";
import CdrReport from "application/components/CTLCdrReport";

function Hotel({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
  const componentName = "configuration.hotel";
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openDirtyClean, setOpenDirtyClean] = useState(false);
  const [openCdr, setOpenCdr] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const printRow = (row) => {
    setFormData(row);
    setOpenCdr(true);
  };

  const DirtyCleanRow = (row) => {
    setFormData(row);
    setOpenDirtyClean(true);
  };

  // Room Dirty/Clean Status for Api Integration
  const DirtyCleanStatus = (data) => {
    application
      .post(operationURLS.status, {
        extension: formData.extension,
        isFeedBack: data,
      })
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.status,
          message: response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
  };

  // Update Hotel

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          console.log("updateSuccessfully");
          setNotify({
            isOpen: true,
            message: response.data.message ,
            type: "success",
            status:response.data.status,
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            status:error.response.status,
            pagename: pageName,
          });
        });
    }
    setOpenForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  function onCloseDirtyClean() {
    setOpenDirtyClean(false);
  }

  function onCloseCdr() {
    setOpenCdr(false);
  }

  return (
    <MDBox>
      <CTLDirtyClean
        entityName="Room cleanliness Status Of "
        entityDiscription={formData.action}
        openForm={openForm}
        columns={getColumns(DirtyCleanRow)}
        openDirtyClean={openDirtyClean}
        DirtyCleanStatus={DirtyCleanStatus}
        formData={formData}
        onCloseDirtyClean={onCloseDirtyClean}
        onCloseForm={onCloseForm}
      />

      <CdrReport
        entityName="CDR History Of Room Number"
        entityDiscription={formData.action}
        columns={getColumns(printRow)}
        openCdr={openCdr}
        formData={formData}
        onCloseCdr={onCloseCdr}
      />
      <CTLBaseDialogForm
        entityName="Update Calling Services Of Room Number"
        entityDiscription={formData.action}
        columns={getColumns(editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLAdvancedFilter
        entityName="Hotel"
        columns={getColumns(editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(editRow, printRow, DirtyCleanRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="extension"
        pageId={pageId}
        list
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default Hotel;
