import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Grid,
} from "@mui/material";

import { useMaterialUIController } from "context";

import CTLDialogContent from "../styles/CTLDialogContent";

import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";

import CTLBaseForm from "../CTLBaseForm";
import { useFormik } from "formik";
import loggerFactory from "globals/logger/logger-factory";

export default function CTLBaseDialogForm({
  openForm,
  onSaveForm,
  onCloseForm,
  entityName,
  columns,
  formData,
  entityDiscription,
  pageAttributes,
  enableDefaultButton,
  ActionButtonView
}) {
  const componentName = "CTLBaseDialogForm";
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let keyField = pageAttributes && pageAttributes.keyField;

  if(keyField == null) {
    keyField = "id";
  }


  const maxWidth =
    pageAttributes && pageAttributes.formSize ? pageAttributes.formSize : "lg";

  const registeredCallbacks = {
    onSave: null,
  };

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);

  function registerCallBacks(events) {
    registeredCallbacks.onSave = events.onSave;
  }

  function handleSaveButton() {
    registeredCallbacks &&
      registeredCallbacks.onSave &&
      registeredCallbacks.onSave();
  }
  /// Set Default Value
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      saveData(values);
    },
  });

  function saveData(values) {
    let dataToSave = {};

    loggerFactory.debug(componentName, "Raw Data To Save", values);

    columns &&
      columns.map((column) => {
        if (column.allowInForm) {
          if (formData[keyField]) {
            dataToSave[keyField] = formData[keyField];
            dataToSave[`${column.accessor}`] =
              values[`field_${column.accessor}`];
          } else {
            dataToSave[keyField] = 0;
            dataToSave[`${column.accessor}`] =
              values[`field_${column.accessor}`];
          }
        }
      });
    loggerFactory.debug(componentName, "Data To Save", dataToSave);
    onSaveForm(dataToSave, null, 2);
  }

  function HandleReset() {
    let values = {};
    columns &&
      columns.map((column) => {
        if (column.allowInForm) {
          values[`field_${column.accessor}`] = column.defaultValue;
          formik.setFieldValue(`field_${column.accessor}`, column.defaultValue);
        }
      });
    saveData(values);
  }


  return (
    <Dialog
      open={open}
      onClose={onCloseForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={maxWidth}
      classes={{ dialog }}
    >
      <DialogTitle
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
        id="alert-dialog-title"
      >
        {entityName}:{entityDiscription}
      </DialogTitle>
      <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
        <CTLBaseForm
          enableActionButtons={false}
          ActionButtonView={false}
          entityName={entityName}
          columns={columns}
          openForm={openForm}
          onSaveForm={onSaveForm}
          onCloseForm={onCloseForm}
          formData={formData}
          formAttributes={pageAttributes}
          registerCallbacks={registerCallBacks}
          dialogSize={maxWidth}
        />
      </DialogContent>
      <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
      
        <MDButton onClick={onCloseForm} color="secondary">
          Cancel
        </MDButton>
        {enableDefaultButton && formData[keyField] && (
           <MDButton color="secondary" autoFocus onClick={HandleReset}>
           Set-To-Default
         </MDButton>
        )}
          {!ActionButtonView && (
        <MDButton color="success" autoFocus onClick={handleSaveButton}>
          Apply
        </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
