import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import TelephoneCell from "application/components/TelephoneCell";
import UserStatusCell from "application/components/UserStatusCell";

const Attributes = {
  modes: [
  ],
};
const operationURLS = {
  sync: "/externalApi/syncLDAP",
  add : "/ownPhoneBook/create"
};

const getColumns = () => {
  return [
  ]}

export { getColumns, Attributes, operationURLS };
