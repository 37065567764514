import { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Pie } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Draggable } from "@hello-pangea/dnd";
import { useGetDataQuery } from "features/apiSlice";
import Dropdown from "../../../Dropdown";
import chartsData from "../../../data/chartData";
import chartColors from "../chartColors";
import configs from "./Config";
import PopUp from "../../../PopUp";
import { PIECHART_DROPDOWN_LIST } from "../../../configs/dropdownConfigs";
import ContextMenu from "../../ContextMenu";
import { useMaterialUIController } from "context";

function Piechart({
  setLayoutData,
  widgetHeight,
  widget,
  cellWidgetInfo,
  index,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  containerType,
  handleBreadcrumbs,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let refreshInterval = 0;
  let actions = [];
  let showLegends = true;
  if (widget) {
    getDataURL = widget.getDataURL;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
    actions = widget.actions ? widget.actions : [];
    showLegends = widget.showLegends ? widget.showLegends : true;
  }

  const piechartQuery = getDataURL
    ? useGetDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpDashboardId, setPopUpDashboardId] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const piechartLabels = [];
  const piechartDatasets = [];

  if (piechartQuery.data)
    piechartQuery.data.forEach((dataPoint) => {
      piechartDatasets.push(dataPoint[widget.valueField]);
      if (
        widget.legendField === "eventEpoch" ||
        widget.valueFieldDataType === "epoch"
      ) {
        const event = new Date(dataPoint[widget.legendField] * 1000);
        const epochTime = event.toTimeString().substring(0, 8);
        piechartLabels.push(epochTime);
      } else {
        piechartLabels.push(dataPoint[widget.legendField]);
      }
    });

  const chartData = {
    labels: piechartQuery.data ? piechartLabels : chartsData.labels,
    data: {
      label: "Categories",
      backgroundColors: Object.keys(chartColors.gradients),
      datasets: piechartQuery.data ? piechartDatasets : chartsData.datasets,
    },
  };

  const legendTooltipId = `custom-tooltip-${uuidv4()}`;

  const { data, options } = configs(
    chartData.labels,
    chartData.data,
    showLegends,
    actions,
    setPopUpOpen,
    setPopUpDashboardId,
    handleBreadcrumbs,
    isDarkMode,
    legendTooltipId,
  );

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let secondaryLabel = null;
  let chart = null;
  let dropdownOptions = isAdmin
    ? PIECHART_DROPDOWN_LIST
    : PIECHART_DROPDOWN_LIST.slice(0, -1);
  let error = null;
  if (widget) {
    if (piechartQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      secondaryLabel = widget.secondaryLabel ? widget.secondaryLabel : "";
      chart = (
        <MDBox height={widgetHeight}>
          <Pie data={data} options={options} />
          <MDBox
            id={legendTooltipId}
            style={{ display: "none", position: "absolute" }}
          />
          <PopUp
            isAdmin={isAdmin}
            open={popUpOpen}
            handleClose={() => setPopUpOpen(false)}
            dashboardId={popUpDashboardId}
          />
        </MDBox>
      );
    } else {
      primaryLabel = "Unable to update the widget";
      secondaryLabel = "";
      chart = "";
      dropdownOptions = isAdmin
        ? PIECHART_DROPDOWN_LIST.slice(PIECHART_DROPDOWN_LIST.length - 1)
        : [];
      error = (
        <Icon
          sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
          fontSize="medium"
        >
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Dummy Primary Label";
    secondaryLabel = "Widget ID not configured";
    chart = (
      <MDBox height={widgetHeight}>
        <Pie data={data} options={options} />
        <MDBox
          id={legendTooltipId}
          style={{ display: "none", position: "absolute" }}
        />
      </MDBox>
    );
    error = (
      <Icon
        sx={{ marginLeft: "auto", marginTop: ".3em", color: "red" }}
        fontSize="medium"
      >
        warningambericon
      </Icon>
    );
  }

  const renderChart = (
    <MDBox p={2} position="relative">
      <MDBox
        width="2.5rem"
        height="2.5rem"
        bgColor="info"
        variant="gradient"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">donut_small</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
          <MDTypography component="div" variant="button" color="text">
            {secondaryLabel}
          </MDTypography>
        </MDBox>
        {error}
        {isEditMode && dropdownOptions.length > 0 && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            setLayoutData={setLayoutData}
            options={dropdownOptions}
          />
        )}
      </MDBox>
      {useMemo(() => chart, [piechartQuery.data, popUpOpen])}
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={{
            flexGrow: "1",
            flexBasis: "0",
            margin: ".5em",
            marginTop:
              index > 0 && containerType === "vertical" ? "1em" : "0.5em",
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderChart}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card
      sx={{
        flexGrow: "1",
        flexBasis: "0",
        margin: ".5em",
        marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
        cursor: "context-menu",
      }}
      onContextMenu={handleContextMenu}
      id={pdfExportId}
    >
      {renderChart}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Piechart;
