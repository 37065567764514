import { useState } from "react";
import MDTypography from "components/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import CTLNotification from "application/components/CTLNotification";
import variables from "globals/variables";
import axios from "axios";
function CTLFaxFileDownload({ row }) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const filename = row.original.filename;
  let str = filename;
  let index = str.lastIndexOf("/");
  let extracted = str.slice(index + 1, str.length)

  const operationURLSS = {
    fileDownload: `${variables.app.services}/auth/faxDownload?path=${filename}`,
  }

  // Download Fax-File
  function onDownloadFile() {
    axios
      .get(operationURLSS.fileDownload, { responseType: "arraybuffer" })
      .then((response) => {
        if (response.headers["content-type"] !== "image/tiff") {
          throw new Error("Invalid file type. Expected image/tiff.");
        }
        const blobData = new Blob([response.data], { type: "image/tiff" });
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${extracted}`;
        link.click();

        URL.revokeObjectURL(url);
        setNotify({
          isOpen: true,
          message: "Fax-File Download Successfully",
          type: "success",
          pagename: "Send-Fax",
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "Unable To Download Fax-File",
          type: "error",
          pagename: "Send-Fax",
          status: error.response ? error.response.status : null,
        });
      });
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      <Tooltip title="DownloadFile">
        <IconButton color="error" onClick={() => onDownloadFile()}>
          <DownloadingIcon></DownloadingIcon>
        </IconButton>
      </Tooltip>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDTypography>
  );
}

export default CTLFaxFileDownload;



