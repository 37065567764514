import { configureStore } from "@reduxjs/toolkit";
import { coralApi } from "./apiSlice";
import { dynamicFormApi } from "./dynamicFormApiSlice";

export const store = configureStore({
  reducer: {
    // Add the API slices as separate reducers
    [coralApi.reducerPath]: coralApi.reducer,
    [dynamicFormApi.reducerPath]: dynamicFormApi.reducer,
  },
  // Adding the middleware from API slices is necessary for RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      coralApi.middleware,
      dynamicFormApi.middleware,
    ),
});
