import { Settings } from "@material-ui/icons";
import { PunchClockOutlined, TryOutlined, Webhook } from "@mui/icons-material";

  
export default [
    {
      id: 0,
      name: "General",
      icon: <Settings></Settings>,
    },
    {
      id: 1,
      name: "Input Configuration",
      icon: <PunchClockOutlined></PunchClockOutlined>,
    },
    {
      id: 2,
      name: "Input Retry",
      icon: <TryOutlined></TryOutlined>,
    }
  ]