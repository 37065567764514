const viewerMenus = [
    {
      icon: "edit",
      key: "configuration.billing-cdr",
      name: "Billing CDR",
      url: "home/app/billing/Cdr",
      description: "Billing-CDR",
      productionReady: true,
    },
    {
      icon: "crisis_alert",
      key: "configuration.nms-alert-history",
      name: "Nms-Alert-History",
      url: "home/app/configurations/nms-alert-history",
      description: "Nms-Alert-History",
      productionReady: true,
    },
  ];
  
  export { viewerMenus };
  