import CTLUnReadCell from "application/components/CTLUnReadCell";
import DefaultCell from "application/components/DefaultCell";
import VoicePickerPlayAudio from "application/components/voicePickerPlayAdios";
import { cdrs } from "globals/configuration/cdrs";

const Attributes = {
  modes: cdrs.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};

const operationURLS = {
  list: "cdrsVoicePicker/list",
  groupList: "extGroup/listAll",
};

const getColumns = () => {
  return [
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "date",
      displayInDeleteConfirmation: true,
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Ring Time",
      accessor: "startCall",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "Answer Time ",
      accessor: "answerCall",
      displayInList: true,
      allowFilter: false,
      allowInForm: false,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },
    {
      Header: "End Time",
      accessor: "endCall",
      displayInList: true,
      allowFilter: false,
      allowInForm: true,
      dataType: "string",
      Cell: ({ row, value }) => <CTLUnReadCell row={row} value={value} />,
    },

    {
      Header: "Call Direction",
      accessor: "callDirection",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value, row }) => <CTLUnReadCell value={value} row={row} />,
    },
    {
      Header: "Duration",
      accessor: "duration",
      allowFilter: true,
      displayInList: true,
      allowInForm: true,
      dataType: "date",
      dateTime: true,
      Cell: ({ value, row }) => <CTLUnReadCell value={value} row={row} />,
    },
    {
      Header: "Play",
      accessor: "recordingFileName",
      displayInList: true,
      Cell: ({ row, value }) => (
        <VoicePickerPlayAudio row={row} value={value} />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
