import React, { useState, useEffect } from "react";
import SIPPhoneService from "application/components/SIPPhone/SIPPhoneService";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { getColumns, Attributes, operationURLS } from "./model";
import MDInput from "components/MDInput";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import PanToolIcon from "@mui/icons-material/PanTool";
import {
  ButtonGroup,
  Card,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Icon,
  IconButton,
  Tooltip,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  ListItemText,
  Avatar,
  TextField,
  Typography,
} from "@mui/material";
import ring from "assets/sound/ringing.wav";
import ringBack from "assets/sound/ringback_tone.mp3";
import {
  useMaterialUIController,
  setOpenPhone,
  setCallState,
  setClickToCall,
  setActivePage,
  setConferenceData,
} from "context";
import variables from "globals/variables";
import loggerFactory from "globals/logger/logger-factory";
import { application } from "globals/endpoints";

import {
  Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  RegistererState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions,
  Web,
  InvitationRejectOptions,
  LogLevel,
} from "sip.js";
import MDTypography from "components/MDTypography";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import CTLNotification from "application/components/CTLNotification";
import CTLBaseDialogForm from "../CTLBaseDialogForm";

function CTLConferencePhone({
  phoneType,
  onOpenForm,
  updateList,
  setPhoneType,
}) {
  const componentName = "SIPPhone";
  const [registration, setRegistration] = useState("Not Registered");
  const [inCall, setInCall] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [isRinging, setIsRinging] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [isDialing, setIsDialing] = useState(false);
  const [activeUser, setActiveUser] = useState();
  const [dialNumber, setDialNumber] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [phoneDisplay, setPhoneDisplay] = useState("block");
  const [timer, setTimer] = useState("0:0:0");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  let talkStart = null;
  let timerThread = null;

  const [controller, dispatch] = useMaterialUIController();
  const { callState, clickToCall, conferenceData } = controller;

  const updateCallState = (callInfo) => {
    loggerFactory.debug(componentName, "Dispatching call state", callInfo);
    setCallState(dispatch, callInfo);
  };

  const audioRinging = new Audio(ring);
  audioRinging.loop = true;

  const ringBackTone = new Audio(ringBack);
  ringBackTone.loop = true;


  useEffect(() => {
      if(!inCall){
        dial(false, conferenceData.didMapping);
    }
  }, [conferenceData]);

  function handleChange(event) {
    setDialNumber(event.target.value);
  }

  function onInvite(invitation) {
    openForIncomingCall(invitation);
  }

  function callIsRinging(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.play();
   callInfo.isRinging = true;
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsDialing(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    callInfo.isDialing = true;
    callInfo.dialNumber = "";
    if (callStatus) {
      callInfo.callStatus = callStatus;
    }
    updateCallState(callInfo);
  }

  function callIsConnected(callStatus) {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    startTimer();

    audioRinging.pause();
    callInfo.inCall = true;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = callStatus;
    callInfo.dialNumber = "";

    updateCallState(callInfo);
  }

  function callIsTerminated() {
    let callInfo = {
      inCall,
      isOnHold,
      isMute,
      isRinging,
      isDialing,
      dialNumber,
      callStatus,
    };

    audioRinging.pause();

    callInfo.inCall = false;
    callInfo.isRinging = false;
    callInfo.isDialing = false;
    callInfo.callStatus = "";
    callInfo.dialNumber = "";

    stopTimer();

    updateCallState(callInfo);
  }

  function openForIncomingCall(invitation) {
    const messageFrom = invitation.incomingInviteRequest.message.from;

    callIsRinging("Incoming call : " + messageFrom.uri.normal.user);
    SIPPhoneService.inviteSession = invitation;

    invitation.stateChange.addListener((state) => {
      switch (state) {
        case SessionState.Initial:
          callIsRinging();
          break;
        case SessionState.Establishing:
          callIsRinging();
          break;
        case SessionState.Established:
          const messageTalk =
            SIPPhoneService.inviteSession.incomingInviteRequest.message.from;

          callIsConnected("Talking : " + messageTalk.uri.normal.user);

          const remoteAudioStream = new MediaStream();
          const remoteVideoStream = new MediaStream();
          const localVideoStream = new MediaStream();

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getSenders()
            .forEach((sender) => {
              if (sender.track) {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);

                  SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                  SIPPhoneService.localVideoMedia.play();
                }
              }
            });

          SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
            .getReceivers()
            .forEach((receiver) => {
              if (receiver.track) {
                if (receiver.track.kind === "audio") {
                  remoteAudioStream.addTrack(receiver.track);
                }
                if (receiver.track.kind === "video") {
                  remoteVideoStream.addTrack(receiver.track);
                }
              }
            });
          SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
          SIPPhoneService.remoteAudioMedia.play();

          SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
          SIPPhoneService.remoteVideoMedia.play();
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          callIsTerminated();
          SIPPhoneService.remoteAudioMedia.srcObject = null;
          SIPPhoneService.remoteAudioMedia.pause();

          SIPPhoneService.remoteVideoMedia.srcObject = null;
          SIPPhoneService.remoteVideoMedia.pause();

          SIPPhoneService.localVideoMedia.srcObject = null;
          SIPPhoneService.localVideoMedia.pause();

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  }

  function updateTimer() {
    const total = Date.parse(new Date()) - Date.parse(talkStart);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setTimer(`${hours}:${minutes}:${seconds}`);
  }

  function startTimer() {
    talkStart = new Date();
    timerThread = setInterval(() => {
      updateTimer();
    }, 1000);
  }

  function stopTimer() {
    talkStart = null;

    if (timerThread) {
      clearInterval(timerThread);
      timerThread = null;
    }
  }

  function answerCall(asVideoCall) {
    SIPPhoneService.inviteSession?.accept({
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: asVideoCall,
        },
      },
    });
  }

  function updateRegistrationStatus(status) {
    setRegistration(status);
  }

  function bye() {
    setConferenceData(dispatch, "");
    setPhoneType("");
    SIPPhoneService.inviteSession.bye();
  }

  function cancel() {
    setPhoneType("");
    setConferenceData(dispatch, "");
    SIPPhoneService.inviteSession.cancel();
  }

  function rejectCall() {
    setPhoneType("");
    setConferenceData(dispatch, "");
    if (SIPPhoneService.inviteSession) {
      SIPPhoneService.inviteSession.reject({
        statusCode: 486,
      });
    }
  }

  function mute() {
    console.log(SIPPhoneService.currentSessionAudio);
    SIPPhoneService.currentSessionAudio.track.enabled = false;
    setIsMute(true);
  }

  function unMute() {
    console.log(SIPPhoneService.currentSessionAudio);
    SIPPhoneService.currentSessionAudio.track.enabled = true;
    setIsMute(false);
  }

  function hold() {
    const options = {
      sessionDescriptionHandlerModifiers: [Web.holdModifier],
    };
    SIPPhoneService.inviteSession.invite(options);
    setIsOnHold(true);
  }

  function unHold() {
    const options = {
      sessionDescriptionHandlerModifiers: [],
    };
    SIPPhoneService.inviteSession.invite(options);
    setIsOnHold(false);
  }

  function transfer() {
    const target = UserAgent.makeURI(
      "sip:" + dialNumber + "@" + variables.sip.domain
    );
    SIPPhoneService.inviteSession.refer(target);
    setDialNumber("");
  }

  function forceTransfer(number) {
    const target = UserAgent.makeURI(
      "sip:" + number + "@" + variables.sip.domain
    );
    SIPPhoneService.inviteSession.refer(target);
    setDialNumber("");
  }

  function keyClicked(key) {
    // if (inCall) {
    //   const options = {
    //     requestOptions: {
    //       body: {
    //         contentDisposition: "render",
    //         contentType: "application/dtmf-relay",
    //         content: `Signal=${key}\r\nDuration=1000`,
    //       },
    //     },
    //   };
    //   SIPPhoneService.inviteSession.info(options);
    // } else {
    setDialNumber(dialNumber + key);
    // }
  }

  function dial(videoCall, number) {
    let toDial = number;

    if (toDial) {
      SIPPhoneService.lastNumberDialed = toDial;

      const number = "sip:" + toDial + "@" + variables.sip.domain;
      const target = UserAgent.makeURI(number);
      setDialNumber("");

      SIPPhoneService.inviteSession = new Inviter(
        SIPPhoneService.userAgent,
        target
      );
       const outgoingRequestMessage =
         SIPPhoneService.inviteSession.outgoingRequestMessage;

       
       outgoingRequestMessage.setHeader("X-Conferencecall", conferenceData.accessCode);
      SIPPhoneService.inviteSession.invite({
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: videoCall,
          },
        },
      });
  
      SIPPhoneService.inviteSession.stateChange.addListener((newState) => {
        switch (newState) {
          case SessionState.Establishing:
            ringBackTone.play();
            if (toDial === SIPPhoneService.swipeCode) {
              callIsDialing("Call Swipe Initiated");
            } else {
              callIsDialing("Dialing : " + toDial);
            }
            break;

          case SessionState.Established:
            ringBackTone.pause();
            if (toDial === SIPPhoneService.swipeCode) {
              callIsConnected("Call Swiped");
            } else {
              callIsConnected(`Talking : ${toDial}`);
            }
            const remoteAudioStream = new MediaStream();
            const remoteVideoStream = new MediaStream();
            const localVideoStream = new MediaStream();

            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getSenders()
              .forEach((sender) => {
                if (sender.track.kind === "audio") {
                  SIPPhoneService.currentSessionAudio = sender;
                }
                if (sender.track.kind === "video") {
                  SIPPhoneService.currentSessionVideo = sender;
                  localVideoStream.addTrack(sender.track);
                }

                SIPPhoneService.localVideoMedia.srcObject = localVideoStream;
                SIPPhoneService.localVideoMedia.play();
              });

            SIPPhoneService.inviteSession.sessionDescriptionHandler.peerConnection
              .getReceivers()
              .forEach((receiver) => {
                if (receiver.track) {
                  if (receiver.track.kind === "audio") {
                    remoteAudioStream.addTrack(receiver.track);
                  }
                  if (receiver.track.kind === "video") {
                    remoteVideoStream.addTrack(receiver.track);
                  }
                }
              });
            SIPPhoneService.remoteAudioMedia.srcObject = remoteAudioStream;
            SIPPhoneService.remoteAudioMedia.play();

            SIPPhoneService.remoteVideoMedia.srcObject = remoteVideoStream;
            SIPPhoneService.remoteVideoMedia.play();
            break;
          case SessionState.Terminated:
            setPhoneType("");
            setConferenceData(dispatch, "");
            ringBackTone.pause();
            callIsTerminated();

            SIPPhoneService.remoteAudioMedia.srcObject = null;
            SIPPhoneService.remoteAudioMedia.pause();

            SIPPhoneService.remoteVideoMedia.srcObject = null;
            SIPPhoneService.remoteVideoMedia.pause();

            SIPPhoneService.localVideoMedia.srcObject = null;
            SIPPhoneService.localVideoMedia.pause();

            break;
          default:
            break;
        }
      });
    } else {
      if (SIPPhoneService.lastNumberDialed) {
        // setDialNumber(SIPPhoneService.lastNumberDialed);
      }
    }
  }

  useEffect(() => {
    if (!SIPPhoneService.callbacks.invite) {
      SIPPhoneService.callbacks.invite = onInvite;
      SIPPhoneService.callbacks.registration = updateRegistrationStatus;
      SIPPhoneService.remoteAudioMedia = document.getElementById("remoteAudio");
      SIPPhoneService.remoteVideoMedia = document.getElementById("remoteVideo");
      SIPPhoneService.localVideoMedia = document.getElementById("localVideo");
    }
    setRegistration(SIPPhoneService.registeredState);
    loggerFactory.debug(componentName, "State Update", callState);
    if (callState) {
      setCallStatus(callState.callStatus);
      setDialNumber(callState.dialNumber);
      setInCall(callState.inCall);
      setIsDialing(callState.isDialing);
      setIsMute(callState.isMute);
      setIsOnHold(callState.isOnHold);
      setIsRinging(callState.isRinging);
    }
  }, [callState]);

  useEffect(() => {
    setDialNumber(clickToCall);
    dial(false, clickToCall);
    loggerFactory.debug(componentName, "Event clickToCall ", clickToCall);
  }, [clickToCall]);
  const [state, setState] = React.useState({ right: false });
  const [participantList, setParticipantList] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredList = participantList?.filter((item) =>
    item.participantName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function fetchData(data) {
    if (data) {
      application
        .post(`conferenceParticipant/list/${data.modId}`)
        .then((response) => {
          setParticipantList(response.data.data);
        })
        .catch((error) => {
          console.log("Unable to load ParticipantList ", error);
        });

      application
        .post(`conferenceParticipant/confCallDetail/${data.didMapping}`)
        .then((response) => {
          setActiveUser(response.data.data);
        })
        .catch((error) => {
          console.log("Unable to load active Users ", error);
        });
    } else {
      setParticipantList([]);
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    fetchData(conferenceData);
    setState({ ...state, ["left"]: open });
  };

  const handleJoinParticipant = (user) => {
    application
      .post(
        `conferenceParticipant/dial/${user.mobileNumber}/${user.accessCode}`
      )
      .then((response) => {
        setNotify({
          isOpen: true,
          message: "",
          type: "success",
          pagename: "Conference",
          status: response.data.message,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
  };

  const handleAddParticipant = () => {
    setOpenForm(true);
    setFormData({
      eventTime: Date.now(),
    });
  };

  function onCloseForm() {
    setOpenForm(false);
  }

  function onSaveForm(data) {
    const dataToSave = {
      configureConferrerId: conferenceData.modId,
      ...data,
    };
    application
      .post(operationURLS.create, dataToSave)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: "Conference",
          status: response.data.messageDetail,
        });
        setOpenForm(false);
        fetchData(conferenceData.modId);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: "Conference",
          status: error.response.data.status,
        });
      });
  }


  const getColorForContact = (contactNumber) => {
    if (activeUser) {
      const activeContacts = activeUser.map((user) => user.contact);
      if (activeContacts.includes(contactNumber)) {
        return "success";
      }
    }
    return "error";
  };

  const list = (anchor) => (
    <MDBox
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ padding: "10px" }}
    >
      <MDBox pl={1} sx={{ display: "flex", alignItems: "center" }}>
        <MDTypography variant="h6" style={{ marginRight: "90px" }}>
          {" "}
          Participant List
        </MDTypography>
        {phoneType == "moderator" && (
          <MDButton
            variant="outlined"
            color="info"
            iconOnly
            onClick={() => handleAddParticipant()}
          >
            <Tooltip title="Add participant">
              <Icon color="info" sx={{ fontWeight: "bold" }}>
                add
              </Icon>
            </Tooltip>
          </MDButton>
        )}
      </MDBox>
      <Divider />
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchInputChange}
        autoComplete="off"
      />
      <Divider />
      <List fullWidth>
        {filteredList && filteredList.length === 0 ? (
          <MDTypography variant="h6" style={{ textAlign: "center" }}>
            No participant found
          </MDTypography>
        ) : (
          filteredList &&
          filteredList.map((item, index) => (
            <ListItemButton
              key={item.participantId}
              disablePadding
              sx={{ cursor: "auto" }}
            >
              {/* <ListItemIcon>
                {phoneType === "moderator" && (
                  <IconButton
                    color="info"
                    // onClick={() => handleJoinParticipant(item)}
                  >
                    <PhoneIcon />
                  </IconButton>
                )}
              </ListItemIcon> */}
              <Icon
                style={{ marginBottom: "-4px", marginRight: "5px", marginLeft:"40px"}}
                color={getColorForContact(item.mobileNumber)}
              >
                circle
              </Icon>
              <MDTypography>
                {" "}
                <ListItemText primary={item.participantName} />
              </MDTypography>
            </ListItemButton>
          ))
        )}
      </List>
    </MDBox>
  );

   return (
    <Card id="phone" style={{ width: "100%", marginTop: "10px" }}>
      {!AuthenticationService.getDeskPhoneStatus() && (
        <MDTypography
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "center",
            minHeight: "20px",
          }}
        >
          <Icon
            style={{ marginBottom: "-4px", marginRight: "5px" }}
            color={registration === "Registered" ? "success" : "error"}
          >
            circle
          </Icon>
          {SIPPhoneService.userAgentOptions.authorizationUsername}
          &nbsp;&nbsp;{" "}
          {conferenceData && `Conference : ${conferenceData?.roomName}`}
        </MDTypography>
      )}
      {AuthenticationService.getDeskPhoneStatus() && (
        <MDTypography
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            textAlign: "center",
            minHeight: "20px",
          }}
        >
          <Icon
            style={{ marginTop: "5px", marginRight: "5px" }}
            color={registration === "Registered" ? "Desk Phone" : "Desk Phone"}
          >
            circle
          </Icon>
          {/* {SIPPhoneService.userAgentOptions.authorizationUsername +
            " " +
            SIPPhoneService.userAgentOptions.displayName
            } */}
        </MDTypography>
      )}
      <audio id="remoteAudio">
        <p>Your browser doesn't support HTML5 audio.</p>
      </audio>
      <MDBox id="video-block" style={{ width: "100%" }}>
        <video id="remoteVideo" style={{ width: "50%" }}></video>
        <video id="localVideo" style={{ width: "50%" }}></video>
      </MDBox>

      <MDBox style={{ maxWidth: "100%" }}>
        <MDTypography
          style={{
            marginBottom: "20px",
            textAlign: "center",
            minHeight: "20px",
          }}
        >
          {callStatus}
        </MDTypography>
        {inCall && (
          <MDTypography
            variant="h6"
            style={{
              marginBottom: "20px",
              textAlign: "center",
              minHeight: "20px",
            }}
          >
            Duration : {timer}
          </MDTypography>
        )}
        {/* <MDInput
          fullWidth
          style={{ marginBottom: "5px" }}
          value={dialNumber}
          onChange={handleChange}
        ></MDInput>
        <Grid container spacing={1}>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="1" color="dark" onClick={() => keyClicked("1")}>
              1
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="2" color="dark" onClick={() => keyClicked("2")}>
              2
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="3" color="dark" onClick={() => keyClicked("3")}>
              3
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="4" color="dark" onClick={() => keyClicked("4")}>
              4
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="5" color="dark" onClick={() => keyClicked("5")}>
              5
            </MDButton>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="6" color="dark" onClick={() => keyClicked("6")}>
              6
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="7" color="dark" onClick={() => keyClicked("7")}>
              7
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="8" color="dark" onClick={() => keyClicked("8")}>
              8
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="9" color="dark" onClick={() => keyClicked("9")}>
              9
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="*" color="dark" onClick={() => keyClicked("*")}>
              *
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="0" color="dark" onClick={() => keyClicked("0")}>
              0
            </MDButton>
          </Grid>

          <Grid item xs={4} style={{ textAlign: "center" }}>
            <MDButton id="#" color="dark" onClick={() => keyClicked("#")}>
              #
            </MDButton>
          </Grid>
        </Grid> */}
        <MDBox
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <ButtonGroup>
            {/* {isRinging && (
              <Tooltip title="Answer Video Call">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(true)}
                >
                  <Icon color="text">videocam</Icon>
                </MDButton>
              </Tooltip>
            )} */}
            {isRinging && (
              <Tooltip title="Answer Audio Call">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => answerCall(false)}
                >
                  <Icon color="text">phone</Icon>
                </MDButton>
              </Tooltip>
            )}

            {isDialing && (
              <Tooltip title="Cancel Call">
                <MDButton
                  color="error"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => cancel()}
                >
                  <Icon color="success">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}

            {inCall && (
              <Tooltip title="Disconnect">
                <MDButton
                  color="error"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => bye()}
                >
                  <Icon color="text">call_end</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && !isOnHold && (
              <Tooltip title="Hold">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => hold()}
                >
                  <Icon color="warning">phone_paused</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && isOnHold && (
              <Tooltip title="Un Hold">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unHold()}
                >
                  <Icon color="success">phone_in_talk</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && !isMute && (
              <Tooltip title="Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => mute()}
                >
                  <Icon color="error">mic_off</Icon>
                </MDButton>
              </Tooltip>
            )}
            {inCall && isMute && (
              <Tooltip title="Un Mute">
                <MDButton
                  color="info"
                  variant="contained"
                  id="call"
                  size="medium"
                  onClick={() => unMute()}
                >
                  <Icon color="error">mic</Icon>
                </MDButton>
              </Tooltip>
            )}
            {dialNumber.length > 0 && (
              <MDButton
                color="info"
                variant="contained"
                id="call"
                size="medium"
                onClick={() => setDialNumber("")}
              >
                <Icon color="warning">clear</Icon>
              </MDButton>
            )}
            {phoneType == "participant" && inCall && (
              <Tooltip title="Raise hand">
                <MDButton
                  color="success"
                  variant="contained"
                  id="call"
                  size="medium"
                  // onClick={}
                >
                  <Icon color="error"> pan_tool_icon</Icon>
                </MDButton>
              </Tooltip>
            )}
            <Tooltip title="participant">
              <MDButton
                color="info"
                variant="contained"
                id="call"
                size="medium"
                onClick={toggleDrawer(true)}
              >
                <Icon color="text">group_icon</Icon>
              </MDButton>
            </Tooltip>
          </ButtonGroup>
        </MDBox>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer(false)}
        >
          {list("left")}
        </Drawer>
      </MDBox>

      <CTLBaseDialogForm
        entityName="Add participant in conference"
        columns={getColumns()}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </Card>
  );
}

export default CTLConferencePhone;
