import { useState } from "react";
import Graph from "./Graph";
import renderSvgNode from "./renderSvgNode";
import renderSvgLink from "./renderSvgLink";
import { Slider } from "@mui/material";

function GraphTopology({
  isEditMode,
  isAdmin,
  data,
  descendantsData,
  categoryData,
  severityData,
  isDarkMode,
  toggleRefreshLocationGraph,
}) {
  const [rangeFactor, setRangeFactor] = useState(
    JSON.parse(localStorage.getItem("locationGraphRangeFactor")) || 1,
  );

  const renderedNode = renderSvgNode(
    descendantsData,
    isDarkMode,
    categoryData,
    severityData,
    "",
    isAdmin,
    isEditMode,
    1,
  );

  const renderedLink = renderSvgLink(isDarkMode);

  return (
    <>
      <Slider
        defaultValue={1}
        min={1}
        max={3}
        value={rangeFactor}
        onChange={(e, val) => {
          setRangeFactor(val);
          localStorage.setItem("locationGraphRangeFactor", val);
        }}
        step={0.05}
        sx={{
          width: "15%",
          position: "absolute",
          top: ".7em",
          right: "1em",
          opacity: 0.5,
        }}
      />
      {Object.keys(data).length > 0 && (
        <Graph
          data={data}
          NodeComponent={renderedNode}
          rangeFactor={rangeFactor}
          LineComponent={renderedLink}
          nodeDistance={200}
          zoomDepth={4}
          hoverOpacity={0.2}
          enableDrag
          pullIn
          toggleRefreshLocationGraph={toggleRefreshLocationGraph}
        />
      )}
    </>
  );
}

export default GraphTopology;
