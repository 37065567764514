import Settings from "layouts/pages/account/settings";
import DefaultAuthorisedPage from "application/components/DefaultAuthorisedPage";
import DefaultUnAuthorisedPage from "application/components/DefaultUnAuthorisedPage";
import Provisioning from "application/modules/provisioning";
import Illustration from "application/modules/authentication/sign-in";
import ChangePassword from "application/modules/authentication/reset-password/cover";
import CTLPassword from "application/modules/authentication/sign-in/CTLPassword";
import CTLMenuDashboard from "application/components/CTLMenuDashboard";


const routes = [
  {
    group: "authenticated",
    collapse: [
      {
        key: "auth-default",
        route: "/app/home",
        component: <DefaultAuthorisedPage />,
        display:true
      },
      {
        key: "provisioning",
        route: "/app/provisioning",
        component: <Provisioning />,
        display:true
      },
      {
        key: "profile-settings",
        route: "/app/settings",
        component: <Settings />,
        display:true
      },
      {
        key: "Change-requiredPassword",
        route: "/app/ChangeRequiredPassword",
        component:<CTLPassword/>,
        display: false,
      },
    ]
  },
  {
    group: "un-authenticated",
    collapse: [
      {
        key: "auth-default",
        route: "/site/nms",
        component: <DefaultUnAuthorisedPage />,
      },
      {
        key: "site-sign-out",
        route: "/site/log-out",
        component: <Illustration />,
      }
    ],
  }
];

export default routes;
