import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import EmptyImage from "../../../../../assets/images/mediacenter/emptyFolder.svg";
import MDButton from 'components/MDButton'

function EmptyContainer({handleNewData}) {
    return (
        <Container sx={{width:'100vw'}} >
            <Box  display="flex" flexDirection='column' justifyContent="center" alignItems="center" sx={{height:'89vh'}}>
                <Box  display="flex" flexDirection='column' justifyContent="center" alignItems="center" sx={{width:'100%', }}>
                    <img src={EmptyImage} alt='Empty Folder' />
                </Box>
                <Box display="flex" flexDirection='column' justifyContent="center" alignItems="center">
                    <Typography variant='h5' fontFamily={600}>No Data Found</Typography>
                    <Typography variant='p'mt={3} >Uh-oh! The table is currently on a data diet. Feed it by clicking the button below.</Typography>
                    <MDButton variant='gradient' color="info" sx={{marginTop:'20px'}} onClick={handleNewData}>Add Data</MDButton>
                </Box>
            </Box>
        </Container>
    )
}

export default EmptyContainer