const callCenterConfigurationReports = [
  {
    icon: "article",
    key: "callDisposition",
    name: "Disposition Analysis-Campaign",
    url: "home/app/callDisposition",
    description: "CallDisposition Report",
    productionReady: true,
  },
  {
    icon: "analytics_icon  ",
    key: "campaignSummary",
    name: "Campaign Summary",
    url: "home/app/campaignSummary",
    description: "Campaign Summary",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "user-login-logout",
    name: "Agent-LogIn-LogOut",
    url: "home/app/configurations/user-login-logout",
    description: "Agent-Login-Logout Report",
    productionReady: true,
  },
  {
    icon: "summarize_icon",
    key: "Customer.CDRreport",
    name: "Customer CDR Rreport",
    url: "home/app/configurations/CustomerCDRreport",
    description: "Customer CDR report",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-DailyReport",
    name: "Daily Report",
    url: "home/app/billing/agent-dailyReport",
    description: "Agent Daily Report",
    productionReady: true,
  },
  {
    icon: "summarize",
    key: "agent-performance",
    name: "Daily Performance Report",
    url: "home/app/billing/agent-performance",
    description: "Agent Daily Performance Report",
    productionReady: true,
  },
  {
    icon: "calendar_view_week",
    key: "group-abandoned-call",
    name: "Group Abandoned Call",
    url: "home/app/configurations/report/GroupAbandonedCall",
    description: "Group Abandoned Call",
    productionReady: true,
  },
];

export { callCenterConfigurationReports };
