import DefaultCell from "application/components/DefaultCell";
import { useMaterialUIController, setShowIVRPlayFile } from "context";
import formControl from "globals/form-control";

export default function (){
  
  const [controller, dispatch] = useMaterialUIController();
  const { showIVRPlayFile } = controller;

  const onChange = (event, formik) => {
      if (event.target.checked === false) {
        setShowIVRPlayFile(dispatch, true);
      }else{
        formik.setFieldValue("field_apiResponseFile", "");
         setShowIVRPlayFile(dispatch, false);
      }
 
   
  };
 

  return [
  {
    Header: "Wait Time",
    accessor: "waitTime",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Number of seconds that system will wait for before triggering no input event",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
  },

  {
    Header: "Description",
    accessor: "description",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Description",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "Reference Code",
    accessor: "code",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Unique code defined by user to create link from some other step",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "Variable Name",
    accessor: "variableName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Store input captured by IVR to defined variable. This variable can be used to pass a parameter to API Call",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "API Required Variable ",
    accessor: "reqVariableName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Required input captured by IVR for API Call as requested variable",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "API Required Parameter",
    accessor: "reqCustomParam",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Required input captured by IVR for API Call as requested parameter",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "API Method",
    accessor: "apiMethod",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Enter User Password / Authorisation Key.",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "Comparison Type",
    accessor: "apiComparisonType",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Comparison Type for response",
    defaultValue: "",
    dataUnit: "",
    controlType: "select",
    options: {
      keyField: "id",
      keyDescription: "description",
      values: [
        { id: "=", description: "Equal-To" },
        { id: "!=", description: "Not Equal-To" },
        { id: "!", description: "Not Equal" },
        { id: ">", description: "Greater-Than" },
        { id: "<", description: "Less-Than" },
        { id: "!>", description: "Not Greater Than" },
        { id: "!<", description: "Not Less Than" },
      ],
    },
    tabId: 0,
  },
  {
    Header: "Comparison Value",
    accessor: "apiComparisonValue",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Success value from response",
    defaultValue: "",
    dataUnit: "",
    tabId: 0,
  },
  {
    Header: "No Input File Name",
    accessor: "noInputFileName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select file from media center / upload new file to play prompt for no input provided. The prompt will be played after wait time expires",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Input Terminater",
    accessor: "inputTerminator",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "use This Field  For Terminate Input Process",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
  },
  {
    Header: "Validation",
    accessor: "validation",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    width: 10,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    controlType: "boolean",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Use This Field  For Validate Input Process",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
  },
  {
    Header: "Input Break",
    accessor: "inputBreak",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Use This Field For Breaking OnGoing Process",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
  },
  {
    Header: "Invalid Input Sequence",
    accessor: "invalidInputSeq",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "string",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "use This Field  For User Invalid Input Sequences ",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
  },
  {
    Header: "Maximum length of input",
    accessor: "maxInput",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "integer",
    Cell: ({ value }) => <DefaultCell value={value} />,
    validation: formControl.string().digitsOnly().nullable(true),
    detailedExplanation:
      "Use this value to define the maximum number of digits allowed.",
    defaultValue: "1",
    dataUnit: "Digits",
    tabId: 1,
  },
  {
    Header: "Input Minimum",
    accessor: "inputMin",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "integer",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Use this value to define the minimum number of digits allowed.",
    defaultValue: "",
    dataUnit: "Digits",
    tabId: 1,
  },
  {
    Header: "Invalid File Name",
    accessor: "invalidFileName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "medialFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select file from media center / upload new file to play prompt for not selecting a valid input",
    defaultValue: "",
    dataUnit: "",
    tabId: 1,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: "Maximum Retry",
    accessor: "maxRetry",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "integer",
    Cell: ({ value }) => <DefaultCell value={value} />,
    validation: formControl.string().digitsOnly().nullable(true),
    detailedExplanation:
      "Maximum number retries allowed before Maximum Retry event.",
    defaultValue: "",
    dataUnit: "",
    tabId: 2,
  },
  {
    Header: "Maximum Retry File Name",
    accessor: "maxRetryFileName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "mediaFile",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Select file from media center / upload new file to play prompt when user has exceeded maximum tries of providing valid input",
    defaultValue: "",
    dataUnit: "",
    tabId: 2,
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
  },
  {
    Header: " API Response Variable",
    accessor: "apiResponseVariable",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "This is API Response Variable used to playing Response",
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
  },
  {
    Header: "Custom Play",
    accessor: "cpm",
    displayInList: true,
    allowInForm: true,
    allowFilter: false,
    displayInDeleteConfirmation: true,
    dataType: "string",
    controlType: "boolean",
    Cell: ({ value }) => <CTLBooleanCell value={value} />,
    detailedExplanation: "Disable for playing custom message",
    onChange: onChange,
    defaultValue: "",
    dataUnit: "",
    tabId: 3,
  },

  {
    Header: "Play File",
    accessor: "apiResponseFile",
    displayInList: true,
    allowFilter: true,
    allowInForm: showIVRPlayFile,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Announcement to be played",
    defaultValue: "",
    dataUnit: "Audio File",
    controlType: "select",
    options: {
      keyField: "fileName",
      keyDescription: "fileName",
      values: [],
      url: "autoAttendant/fileList",
    },
    tabId: 3,
  },
  {
    Header: "API URL",
    accessor: "apiURL",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "Define this API URL if it is different from default configuration",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
  {
    Header: "API Type",
    accessor: "apiType",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Type of API SOAP / RESTFul",
    defaultValue: "",
    dataUnit: "",
    controlType: "select",
    options: {
      keyField: "id",
      keyDescription: "description",
      values: [
        { id: "SOAP", description: "SOAP" },
        { id: "REST", description: "REST" },
      ],
    },
    tabId: 4,
  },
  {
    Header: "API Content",
    accessor: "soapContent",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    controlType: "textField",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation:
      "This is the definition of API method calling for api call ",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
  {
    Header: "API Return Variable",
    accessor: "apiReturnVariable",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "This is the definition of API Return for api call ",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
  {
    Header: "Authorisation Type",
    accessor: "apiAuthType",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Type of Authentication",
    defaultValue: "",
    dataUnit: "",
    controlType: "select",
    options: {
      keyField: "id",
      keyDescription: "description",
      values: [
        { id: "no-auth", description: "Authorsation not required" },
        { id: "api-key", description: "API Key" },
        { id: "bearer-token", description: "Bearer Token" },
        { id: "basic", description: "Basic Auth" },
      ],
    },
    tabId: 4,
  },
  {
    Header: "Auth User Name / Header",
    accessor: "apiAuthUserName",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Enter User name / Authorisation Header name",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
  {
    Header: "Auth Password / Key",
    accessor: "apiAuthPassword",
    displayInList: true,
    allowFilter: true,
    allowInForm: true,
    displayInDeleteConfirmation: true,
    dataType: "String",
    Cell: ({ value }) => <DefaultCell value={value} />,
    detailedExplanation: "Enter User Password / Authorisation Key.",
    defaultValue: "",
    dataUnit: "",
    tabId: 4,
  },
]
}