import { useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Backdrop, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import RenderSmartAsset from "../../RenderSmartAsset";

function SmartAsset({
  isAdmin,
  isEditMode,
  primaryLabel,
  assetId,
  assetIpAddress,
  assetImageName,
  assetImageWidth,
  assetImageHeight,
  category,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [parentRefCurrent, setParentRefCurrent] = useState(null);

  const handleParentRefCurrent = (current) => setParentRefCurrent(current);

  return (
    <Card>
      <MDBox position="relative">
        <MDBox
          width="2.5rem"
          height="2.5rem"
          bgColor="info"
          variant="gradient"
          coloredShadow="info"
          borderRadius="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
          position="absolute"
          top="-.7em"
          zIndex="1000"
        >
          <Icon fontSize="small">hub</Icon>
        </MDBox>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
        </MDBox>
        <MDBox id="smartAssetParent">
          <RenderSmartAsset
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            assetId={assetId}
            assetIpAddress={assetIpAddress}
            assetImageName={assetImageName}
            assetImageWidth={assetImageWidth}
            assetImageHeight={assetImageHeight}
            category={category}
            setIsSaving={setIsSaving}
            parentRefCurrent={parentRefCurrent}
            handleParentRefCurrent={handleParentRefCurrent}
          />
          <Backdrop
            open={isSaving}
            sx={{ color: "#ccc", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress disableShrink color="white" />
          </Backdrop>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default SmartAsset;
