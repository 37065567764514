import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Tab, Tabs } from "@mui/material";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import SmartAsset from "./tabs/SmartAsset";
import MDBox from "components/MDBox";
import Sensors from "./tabs/Sensors";
import ActiveAlerts from "./tabs/ActiveAlerts";
import AlertHistory from "./tabs/AlertHistory";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <MDBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ marginTop: ".7em" }}
    >
      {value === index && children}
    </MDBox>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Icc4Popup({
  open,
  handleClose,
  isAdmin,
  isEditMode,
  primaryLabel,
  assetId,
  assetIpAddress,
  assetImageName,
  assetImageWidth,
  assetImageHeight,
  category,
}) {
  const { darkMode } = useMaterialUIController()[0];
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newTab) => setCurrentTab(newTab);

  return (
    <Dialog
      onContextMenu={(e) => e.stopPropagation()}
      open={open}
      onClose={handleClose}
      fullScreen
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          sx={{ width: "100%", marginInline: "auto", marginTop: ".7em" }}
        >
          <Tab label="Smart Asset" {...a11yProps(0)} />
          <Tab label="Sensors" {...a11yProps(1)} />
          <Tab label="Active Alerts" {...a11yProps(2)} />
          <Tab label="Alert History" {...a11yProps(2)} />
        </Tabs>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <CustomTabPanel value={currentTab} index={0}>
          <SmartAsset
            isAdmin={isAdmin}
            isEditMode={isEditMode}
            primaryLabel={primaryLabel}
            assetId={assetId}
            assetImageName={assetImageName}
            assetImageWidth={assetImageWidth}
            assetImageHeight={assetImageHeight}
            category={category}
            assetIpAddress={assetIpAddress}
          />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={1}>
          <Sensors assetId={assetId} />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={2}>
          <ActiveAlerts assetId={assetId} />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={3}>
          <AlertHistory assetId={assetId} />
        </CustomTabPanel>
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: darkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
          marginTop: "-1em",
        })}
      >
        <MDButton color="primary" type="button" onClick={handleClose}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default Icc4Popup;
