import { v4 as uuidV4 } from "uuid";

export default function getColumnsConfig(columns) {
  return columns
    .filter((column) => column.display)
    .map((column) => ({
      id: `${column.field}///${uuidV4()}` || "Dummy field",
      name: column.heading || "Dummy heading",
      wrap: true,
      sortable: true,
      reorder: true,
      omit: column.field === "id" || column.field === "metricName",
      selector: (row) => row[column.field],
    }));
}
