import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function DatasetFilter({
  datasetType,
  handleDatasetTypeChange,
  layout,
  transformedData,
  isDarkMode,
}) {
  return (
    <FormControl
      sx={{ minWidth: 200, marginTop: ".1em", marginRight: ".4em" }}
      size="large"
    >
      <InputLabel
        id="datasetType-label"
        sx={{ color: isDarkMode && layout !== "preset" && "#FFF" }}
      >
        Dataset filter
      </InputLabel>
      <Select
        labelId="datasetType-label"
        id="datasetType"
        value={datasetType}
        onChange={handleDatasetTypeChange}
        label="Dataset filter"
        disabled={layout === "preset" && transformedData.nodes.length > 20}
        sx={{
          color: isDarkMode && "#FFF",
          padding: ".5em",
        }}
      >
        <MenuItem value="location-graph">Location graph</MenuItem>
        <MenuItem value="device-graph">Device graph</MenuItem>
      </Select>
    </FormControl>
  );
}

export default DatasetFilter;
