import Tooltip from "../Tooltip";
import Node from "../Node";

export default function renderSvgNode(
  descendantsData,
  isDarkMode,
  categoryData,
  threshold,
  isAdmin,
) {
  return ({ nodeDatum, toggleNode }) => {
    let color = null;
    const activeDescendants = descendantsData[nodeDatum.id].active;
    const inactiveDescendants = descendantsData[nodeDatum.id].inactive;
    const totalDescendants = activeDescendants + inactiveDescendants;
    const activePercentage = (activeDescendants * 100) / totalDescendants;

    if (nodeDatum.children && nodeDatum.children.length) {
      if (activePercentage === 0) color = "#e91e63";
      else if (activePercentage > 0 && activePercentage <= threshold) color = "orange";
      else if (activePercentage > threshold && activePercentage < 100) color = "yellow";
      else color = "#4CAF50";
    } else {
      color = nodeDatum.status ? "#4CAF50" : "#e91e63";
    }

    const placeholder = "ID";
    const identifier = nodeDatum.id;
    Tooltip(placeholder + identifier, nodeDatum);
    const deviceIcon = (
      <Node
        toggleNode={toggleNode}
        isDarkMode={isDarkMode}
        nodeDatum={nodeDatum}
        descendantsData={descendantsData}
        placeholder={placeholder}
        identifier={identifier}
        color={color}
        contextMenuData={categoryData.filter((menuData) => menuData.code === nodeDatum.category)[0]}
        isAdmin={isAdmin}
      />
    );
    return deviceIcon;
  };
}
