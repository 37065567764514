export default function defaultLayout(widgetId, isSensorsDashboard, sensorsWidgetList) {
  return {
    header: [],
    footer: [],
    leftSidebar: [],
    rightSidebar: [],
    innerHeader: [],
    innerFooter: [],
    innerLeftSidebar: [],
    innerRightSidebar: [],
    center: [
      {
        celId: "center1",
        height: "53vh",
        width: {
          xs: 12,
        },
        defaultWidgetHeight: "53.9vh",
        defaultWidgetWidth: null,
        widgetIds: !isSensorsDashboard ? [widgetId] : sensorsWidgetList.map((sensor) => sensor.id),
      },
    ],
  };
}
