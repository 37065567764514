import Icon from "@mui/material/Icon";
import styles from "./css/markup/ContainerLayout.module.css";
import SearchPopUp from "./SearchPopUp";

function ContainerLayout({
  isPopUpOpen,
  setIsPopUpOpen,
  masterContainerId,
  cellId,
  setLayoutData,
  widgets,
  setWidgetsDataObj2,
  isEditMode,
  isAdmin,
}) {
  return (
    <>
      {isAdmin && isEditMode && (
        <button
          type="button"
          onClick={() => setIsPopUpOpen(true)}
          className={
            isPopUpOpen ? `${styles.configBtn} ${styles.configBtnClicked}` : styles.configBtn
          }
        >
          <Icon fontSize="small" color="secondary">
            settings
          </Icon>
        </button>
      )}
      <SearchPopUp
        masterContainerId={masterContainerId}
        isPopUpOpen={isPopUpOpen}
        cellId={cellId}
        setLayoutData={setLayoutData}
        setIsPopUpOpen={setIsPopUpOpen}
        setWidgetsDataObj2={setWidgetsDataObj2}
      />
      {widgets}
    </>
  );
}

export default ContainerLayout;
