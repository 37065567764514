export default function calculateSmartAssetMarkerPosition(
    mousePosition,
    imagePosition,
    scrollY,
    bufferLeft,
    bufferTop
  ) {
    const pixelsLeft = mousePosition.clientX - imagePosition.left;
    let pixelsTop;
    if (imagePosition.top < 0) {
      pixelsTop = mousePosition.pageY - scrollY + imagePosition.top * -1;
    } else {
      pixelsTop = mousePosition.pageY - scrollY - imagePosition.top;
    }
  
    return [pixelsTop - bufferTop, pixelsLeft - bufferLeft];
  }
  