import Tree from "react-d3-tree";
import Legend from "../../Legend";
import Zoom from "../Zoom";
import renderSvgNode from "../../renderSvgNode";
import AssetFilter from "../../AssetFilter";
import ShowLegendsSwitch from "../../ShowLegendsSwitch";

function RenderPopupTopology({
  data,
  categoryData,
  descendantsData,
  severityData,
  asset,
  handleSearchAsset,
  isDarkMode,
  translate,
  showLegends,
  handleShowLegends,
  zoomLevel,
  handleZoomIn,
  handleZoomOut,
  isAdmin,
  isEditMode,
}) {
  const renderedNode = renderSvgNode(
    descendantsData,
    isDarkMode,
    categoryData,
    severityData,
    {},
    {},
    asset,
    "",
    isAdmin,
    isEditMode,
    2,
  );

  const getDynamicPathClass = ({ target }) => {
    if (!target.children) {
      // Target node has no children -> this link leads to a leaf node.
      return isDarkMode ? "link__to-leaf-dark" : "link__to-leaf-light";
    }

    // Style it as a link connecting two branch nodes by default.
    return isDarkMode ? "link__to-branch-dark" : "link__to-branch-light";
  };

  return (
    <>
      <AssetFilter
        asset={asset}
        handleSearchAsset={handleSearchAsset}
        level={2}
      />
      <Zoom zoomIn={handleZoomIn} zoomOut={handleZoomOut} />
      <ShowLegendsSwitch
        showLegends={showLegends}
        handleShowLegends={handleShowLegends}
        level={2}
      />
      <Legend
        title="Severity"
        isDarkMode={isDarkMode}
        level={2}
        showLegends={showLegends}
        categories={severityData.map((severity) => ({
          color: severity.color,
          colorName: severity.color,
          name: severity.severityName,
        }))}
      />
      <Tree
        data={data}
        transitionDuration={0}
        translate={translate}
        zoom={zoomLevel}
        separation={{ nonSiblings: 1.5, siblings: 1.2 }}
        depthFactor={
          data.children.length * 100 < 500
            ? "300"
            : data.children.length * 50
        }
        renderCustomNodeElement={renderedNode}
        shouldCollapseNeighborNodes
        pathClassFunc={getDynamicPathClass}
        linkClass="my-link-class"
      />
    </>
  );
}

export default RenderPopupTopology;
