import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import TelephoneCell from "application/components/TelephoneCell";
import UserStatusCell from "application/components/UserStatusCell";

const Attributes = {
  enableAddButton: false,
  enableSyncButton: true,
  refetchInterval: 5000,
  modes: [
  ],
};
const operationURLS = {
  sync: "/externalApi/syncLDAP",
  add : "/ownPhoneBook/create"
};

const getColumns = (onAdd, onCall) => {
  return [
    {
      Header: "Type",
      accessor: "type",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Designation",
      accessor: "designation",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "User Status",
      accessor: "statusDescription",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
       Cell: ({ value }) => <UserStatusCell value={value}  />,
    },
    {
      Header: "Office Ext",
      accessor: "offEXtn",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
    },
    {
      Header: "Office Phone",
      accessor: "phoneOffice",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
    },
    {
      Header: "Mobile",
      accessor: "phoneHome",
      displayInList: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <TelephoneCell value={value} onCall={onCall} />,
    },
    {
      Header: "",
      accessor: "mobileNumber",
      displayInList: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ row, value }) => <RowAction row={row} value={value} onAdd={onAdd} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
