import { v4 as uuidV4 } from "uuid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import variables from "globals/variables";
import { telemetry } from "globals/endpoints";

function ContextMenu({
  contextMenuData,
  contextMenu,
  handleClose,
  assetId,
  ipAddress,
  handleDashboard,
}) {
  const handleAction = async (apiURL, key = "") => {
    if (apiURL === null) {
      const alertMessage =
        key === "mgmt"
          ? "No Management Interface URL found for this asset."
          : "No API URL found for this asset.";
      alert(alertMessage);
      handleClose();
      return;
    }

    const baseURL = variables.api.telemetry;
    const dollar = "$";
    let URL = null;
    URL = apiURL.replace(`${dollar}{{baseURL}}`, baseURL);
    URL = URL.replace("{id}", assetId);
    URL = URL.replace("{{ipAddress}}", ipAddress);
    URL = URL.replace("{{ipAddres}}", ipAddress);

    if (key === "mgmt") {
      window.open(URL, "_blank");
    } else {
      try {
        const actionResponse = await telemetry.post(URL);
        alert(actionResponse.data);
      } catch (e) {
        alert(e.message);
      }
    }

    handleClose();
  };

  return contextMenuData
    ? (contextMenuData.hasManagementInterface ||
        contextMenuData.managementURL ||
        contextMenuData.hasSelfDashboard ||
        contextMenuData.actions.length > 0) && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {(contextMenuData.hasManagementInterface ||
            contextMenuData.managementURL) && (
            <MenuItem
              key={uuidV4()}
              onClick={() =>
                handleAction(contextMenuData.managementURL, "mgmt")
              }
            >
              Management Interface
            </MenuItem>
          )}
          {contextMenuData.hasSelfDashboard && (
            <MenuItem key={uuidV4()} onClick={handleDashboard}>
              Dashboard
            </MenuItem>
          )}
          {contextMenuData.actions.map((action) => (
            <MenuItem
              key={uuidV4()}
              onClick={() => handleAction(action.apiURL)}
            >
              {action.actionName}
            </MenuItem>
          ))}
        </Menu>
      )
    : null;
}

export default ContextMenu;
