
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";


const Attributes = {
  selectUrl: "form/listAll",
  selectForm: true,
  enableToggleButton:true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
};


function CallCenterReport({
  onLoad,
  pageId,
  searchText,
  registerCallBacks,
  pageName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension, toggleButtonStatus } = controller;
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [column, setColumn] = useState(null);
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
      });
  }, [activePage]);
const operationURLS = {
  list: toggleButtonStatus === "Disposition"? `dynamicPopUpData/list/${ivrExtension.code}` : `customer/list/${ivrExtension.code}`,
  getColumn: `form/${ivrExtension.code}`,
};


useEffect(() => {setUpdateList(Date.now());}, [toggleButtonStatus]);

  useEffect(() => {
    if (ivrExtension.code) {
      application
        .get(operationURLS.getColumn)
        .then((response) => {
         setColumn(response.data.data.content);
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    }
  }, [ivrExtension.changedOn ]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }



  return (
    <MDBox>
      {column && (
        <CTLTable
          apiURL={operationURLS.list}
          advancedFilters={advancedFilters}
          columns={column}
          entriesPerPage={false}
          defaultSortDirection="desc"
          defaultSortColumn=""
          pageId={pageId}
          canSearch
          hasRowSelection={false}
          updateList={updateList}
          searchText={searchText}
        />
      )}

      <CTLAdvancedFilter
        entityName="JSON Form"
        columns={""}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default CallCenterReport;
