import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { application } from "globals/endpoints";


function MediaGatewayCard({ bgColor, color, title, icon, id }) {
  const handleOpenMediaGateway = () => {
    application
      .post(`/SCADAAsset/mediaGateway/${id}`)
      .then((res) => {
        const url = res.data.data;
        const popupFeatures = "fullscreen=yes,resizable=yes,scrollbars=yes";
        window.open(url, "_blank", popupFeatures);
      
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Card>
        <MDBox display="flex" justifyContent="space-between" pt={1} px={1}>
          <MDBox
            variant="gradient"
            bgColor={bgColor}
            color={color === "light" ? "dark" : "white"}
            coloredShadow={color}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="2rem"
            height="2rem"
          >
            <Icon fontSize="medium" color={color}>
              {icon}
            </Icon>
          </MDBox>
          <MDTypography variant="h6" color="text">
            {title}
          </MDTypography>
        </MDBox>
        <Divider />
        <MDBox m={2} display="flex" justifyContent="flex-end">
          <MDButton color="info" onClick={handleOpenMediaGateway}>
            Configure
          </MDButton>
        </MDBox>
      </Card>
    </>
  );
}

export default MediaGatewayCard;
