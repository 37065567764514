import { useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetDataQuery } from "features/apiSlice";
import Dropdown from "../../Dropdown";
import { COUNTER_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import ContextMenu from "../ContextMenu";
import Grid from "@mui/material/Grid";
import chartColors from "../charts/chartColors";
import Divider from "@mui/material/Divider";

function Multicounter({
  widget,
  cellWidgetInfo,
  index,
  containerType,
  widgetHeight,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let refreshInterval = 0;
  if (widget) {
    getDataURL = widget.getDataURL;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
  }

  const multicounterQuery = getDataURL
    ? useGetDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let chickletsData = null;
  let error = null;
  if (widget) {
    if (multicounterQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      chickletsData = multicounterQuery.data;
    } else {
      primaryLabel = "Unable to update the widget";
      chickletsData = [];
      error = (
        <Icon sx={{ marginTop: ".3em", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Widget ID not configured";
    chickletsData = [];
    error = (
      <Icon sx={{ color: "red", marginLeft: "auto" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  const styles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
    cursor: "context-menu",
    minHeight: "7em",
    height: widgetHeight,
  };
  if (containerType === "vertical") styles.height = widgetHeight;

  let cnt = 1;
  const renderCounter = (
    <MDBox
      position="relative"
      p={2}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <MDBox
        variant="gradient"
        bgColor="info"
        color="white"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="2.5rem"
        height="2.5rem"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">leaderboard</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <MDBox ml={6}>
          <MDTypography variant="h6">{primaryLabel}</MDTypography>
        </MDBox>
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={COUNTER_DROPDOWN_LIST}
          />
        )}
      </MDBox>
      <Grid
        spacing={1}
        container
        columns={chickletsData.length}
        sx={{ height: "100%" }}
      >
        {chickletsData.map((chicklet) => {
          const fontColor =
            !chicklet.fontColor || chicklet.fontColor === "null"
              ? "#FFF"
              : chicklet.fontColor;
          let bgColor = null;
          if (
            !chicklet.backGroundColor ||
            chicklet.backGroundColor === "null"
          ) {
            bgColor = chartColors.gradients[`chartColor${cnt}`].chicklet;
            cnt++;
            if (cnt === 51) cnt = 1;
          } else bgColor = chicklet.backGroundColor;

          return (
            <Grid key={uuidv4()} item xs={1}>
              <MDBox
                height="100%"
                sx={{
                  backgroundColor: bgColor,
                  borderRadius: "1em",
                  boxShadow:
                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                }}
              >
                <MDBox
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderTopLeftRadius: "1em",
                    borderTopRightRadius: "1em",
                    backgroundColor: "rgba(0,0,0,0.15)",
                    height: "40%",
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: "1.15rem",
                      color: fontColor,
                      marginInline: ".5em"
                    }}
                  >
                    {chicklet.iconName}
                  </Icon>
                  <Divider
                    orientation="vertical"
                    sx={{ margin: "0", bgcolor: "#FFF" }}
                    flexItem
                  />
                  <MDTypography
                    variant="h5"
                    sx={{
                      fontSize: "1.15rem",
                      color: fontColor,
                      marginInline: "auto",
                      lineHeight: "1"
                    }}
                  >
                    {chicklet[widget.legendField]}
                  </MDTypography>
                </MDBox>
                <Divider sx={{ margin: "0", bgcolor: "#FFF" }} />
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottomLeftRadius: "1em",
                    borderBottomRightRadius: "1em",
                    height: "60%",
                  }}
                >
                  <MDTypography sx={{ fontSize: "1.4rem", color: fontColor }}>
                    {(chicklet[widget.valueField]).toLocaleString('en-IN')}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          );
        })}
      </Grid>
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={styles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderCounter}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card sx={styles} onContextMenu={handleContextMenu} id={pdfExportId}>
      {renderCounter}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Multicounter;
