import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Legend({ title, categories, isDarkMode }) {
  const renderItems = categories.map(({ color, name }) => (
    <MDBox
      key={name}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      pr={2}
    >
      <MDBox display="flex" alignItems="center">
        <MDBox
          display="grid"
          alignItems="center"
          justifyContent="center"
          borderRadius="sm"
          shadow="md"
          color="white"
          width="1.2rem"
          height="1.2rem"
          mr={2}
          variant="gradient"
          fontSize="0.875rem"
          sx={{ backgroundColor: color }}
        />
        <MDBox display="flex" flexDirection="column" mt={1}>
          <MDTypography
            variant="button"
            sx={({ palette: { white, dark } }) => ({
              color: isDarkMode ? white.main : dark.main,
            })}
            fontWeight="medium"
            gutterBottom
          >
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ position: "absolute", right: "1%", bottom: "-9%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox px={2} py={1}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Legend;
