import MDTypography from "components/MDTypography";
import { IconButton, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CTLDialogContent from "../styles/CTLDialogContent";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";

export default function CTLDirtyClean({
  openDirtyClean,
  DirtyCleanStatus,
  onCloseDirtyClean,
  formData,
  entityName,
  entityDiscription
}) {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setIsDirty] = useState(formData.isFeedback);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    setOpen(openDirtyClean);
  }, [openDirtyClean]);

  useEffect(() => {
    setIsDirty(formData.isFeedback);
  }, [formData]);

  const handleClickOpen = () => {
    setOpen(openDirtyClean);
  };

  const handleClickClose = () => {
    setOpen(onCloseDirtyClean);
  };

  const handleSwitchChange = () => {
    setIsDirty((prevIsDirty) => !prevIsDirty);
  };

  const handleClickStatus = () => {
    const data = isDirty;
    DirtyCleanStatus(data);
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
        {entityName}:{entityDiscription}
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            ...CTLDialogContent(theme, { darkMode }),
            color: isDirty ? "red" : "green",
          })}
        >
          {isDirty ? "Dirty" : "Clean"}
          <Switch
            size="large"
            name="cleanup"
            checked={isDirty}
            onChange={handleSwitchChange}
            sx={{
              "& .MuiSwitch-thumb": {
                bgcolor: isDirty ? "red" : "green",
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClickClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={handleClickStatus} color="success" autoFocus>
            Apply
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
