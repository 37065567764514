import Center from "../containers/Center";
import Footer from "../containers/Footer";
import Header from "../containers/Header";
import InnerFooter from "../containers/InnerFooter";
import InnerHeader from "../containers/InnerHeader";
import InnerSidebar from "../containers/InnerSidebar";
import Sidebar from "../containers/Sidebar";
import CONTAINER_TYPES from "../configs/containersConfigs";

export default function RenderContainer(
  masterContainerId,
  layoutData,
  setLayoutData,
  handleDeleteWidget,
  widgetsDataObj,
  handleBreadcrumbs,
  widgetsDataObj2,
  setWidgetsDataObj2,
  isEditMode,
  isAdmin
) {
  const allContainers = [];
  const masterContainer = layoutData[masterContainerId];

  masterContainer.forEach((childContainer) => {
    switch (masterContainerId) {
      case CONTAINER_TYPES.header:
        allContainers.push(
          <Header
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.footer:
        allContainers.push(
          <Footer
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.leftSidebar:
        allContainers.push(
          <Sidebar
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.rightSidebar:
        allContainers.push(
          <Sidebar
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.innerHeader:
        allContainers.push(
          <InnerHeader
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.innerFooter:
        allContainers.push(
          <InnerFooter
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.innerLeftSidebar:
        allContainers.push(
          <InnerSidebar
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;

      case CONTAINER_TYPES.innerRightSidebar:
        allContainers.push(
          <InnerSidebar
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );

        break;

      default:
        allContainers.push(
          <Center
            key={childContainer.celId}
            masterContainerId={masterContainerId}
            cellId={childContainer.celId}
            cellData={childContainer}
            setLayoutData={setLayoutData}
            handleDeleteWidget={handleDeleteWidget}
            widgetsDataObj={widgetsDataObj}
            handleBreadcrumbs={handleBreadcrumbs}
            widgetsDataObj2={widgetsDataObj2}
            setWidgetsDataObj2={setWidgetsDataObj2}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
          />
        );
        break;
    }
  });

  return allContainers;
}
