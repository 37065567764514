import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import { Box } from "@material-ui/core";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

function AllReports({ onLoad, registerCallBacks, pageId, searchText }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;

  const Attributes = {
    formSize: "md",
    columns: 3,
    enableAddButton: true,
    enableAddAgentButton: false,
    enableAcdDetails: true,
    selectUrl: "/queue/list/acdList",
    modes: callCenterConfigMenuMoreVert.map((item) => {
      return {
        id: item.key,
        name: item.name,
        iconName: item.icon,
        url: item.url,
        description: item.description,
      };
    }),
  };

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage, ivrExtension]);

  return (
    <>
      <MDBox style={{ display: "flex" }}>
        <Box
          style={{
            marginLeft: "10px",
            width: "calc(100% - 60px)",
          }}
        ></Box>
      </MDBox>
    </>
  );
}

export default AllReports;
