import colors from "assets/theme-dark/base/colors";

function configs(multiseries, isDarkMode, legendFieldDataType) {
  try {
    const series = multiseries.series.map((s) => ({
      name: s.seriesName,
      type: s.seriesType === "bar" ? "column" : "line",
      data: s.values,
    }));

    const multiseriesLabelsColorScheme = isDarkMode
      ? colors.grey[200]
      : colors.grey[600];

    return {
      series,
      options: {
        chart: {
          id: "multiseries",
          zoom: {
            enabled: legendFieldDataType === "epoch",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "40%",
          },
        },
        labels:
          legendFieldDataType === "epoch"
            ? multiseries.label.map((label) => {
                const event = new Date(label * 1000);
                const epochTime = event.toTimeString().substring(0, 8);
                return epochTime;
              })
            : multiseries.label,
        xaxis: {
          labels: {
            rotateAlways: false,
            style: {
              colors: multiseriesLabelsColorScheme,
            },
          },
          axisBorder: {
            color: multiseriesLabelsColorScheme,
          },
          axisTicks: {
            color: multiseriesLabelsColorScheme,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: multiseriesLabelsColorScheme,
            },
          },
        },
        fill: {
          opacity: 1,
        },
        grid: {
          borderColor: isDarkMode ? "#c1c4ce5c" : colors.grey[400],
          strokeDashArray: [5, 5],
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 4,
          strokeWidth: 0,
          hover: {
            size: 4,
          },
        },
        stroke: {
          width: 2.5,
        },
        legend: {
          labels: {
            colors: multiseriesLabelsColorScheme,
          },
        },
      },
      height: "100%",
    };
  } catch (error) {
    console.error(error);
    return { series: [], options: {}, height: "100%" };
  }
}

export default configs;
