export function getColumnsConfig(columns) {
  return columns
    .filter((column) => column.display)
    .map((column) => ({
      id: `${column.field}` || "Dummy field",
      name: column.heading || "Dummy heading",
      grow: column.heading.length,
      wrap: true,
      sortable: true,
      reorder: true,
      omit: column.field === "id" || column.field === "severityText",
      selector: (row) => row[column.field],
    }));
}

export function getColumnsConfigLocalStorage(localStorageColumns) {
  return localStorageColumns
    ? localStorageColumns.map((column) => ({
        id: column.id,
        name: column.name,
        grow: column.grow,
        wrap: true,
        sortable: true,
        reorder: true,
        omit: column.field === "id" || column.field === "severityText",
        selector: (row) => row[column.id],
      }))
    : null;
}
