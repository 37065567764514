
import MDTypography from "components/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import MarkChatReadTwoToneIcon from '@mui/icons-material/MarkChatReadTwoTone';
import React from 'react';
import { Dialog, DialogActions, DialogContent,  DialogTitle, Icon } from '@mui/material';
import CTLDialogContent from '../styles/CTLDialogContent';
import dialog from 'assets/theme/components/dialog';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from "context";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function UpdateAsRead({columns, row ,markAsRead,onDelete }) {

  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const onAgree = () => {
    onDelete(row.original);
    setOpen(false);
  };


  return (
    <>
      <MDTypography variant="caption" fontWeight="medium" color="text">
        <Tooltip title={"Mark As Read"}>
          <IconButton color="success" onClick={() => markAsRead(row.original)}>
            <MarkChatReadTwoToneIcon></MarkChatReadTwoToneIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title={"delete"}>
          <IconButton color="error" onClick={ handleClickOpen}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </Tooltip>
      </MDTypography>

    <Dialog
    open={open}
    maxWidth="md"
    classes={{ dialog }}
    >
    <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })} id="alert-dialog-title"> Are you sure you want to delete </DialogTitle>
    <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
      <MDButton onClick={handleClickClose} color="secondary">
        Disagree
      </MDButton>
      <MDButton onClick={onAgree} color="error" autoFocus>
        Agree
      </MDButton>
    </DialogActions>
     </Dialog>
    </>
  );
}


