import { useEffect } from "react";
import { useMaterialUIController } from "context";
import { Attributes } from "./model";
import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Menucard from "./menucard";
import CTLConferencePhone from "application/components/CTLConferencePhone";

function ConferenceParticipant({ onLoad, registerCallBacks }) {
  const [controller] = useMaterialUIController();
  const { activePage } = controller;

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage]);

  return (
      <Grid>
      <CTLConferencePhone phoneType="participant"/>
      </Grid>
  );
}

export default ConferenceParticipant;
