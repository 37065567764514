import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import CTLDialogContent from "../../../../components/styles/CTLDialogContent";
import { application } from "globals/endpoints";
import MDTypography from "components/MDTypography";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import loggerFactory from "globals/logger/logger-factory";

const ITEM_HEIGHT = 88;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

export default function CTLForm({
  entityName,
  columns,
  openForm,
  onSaveForm,
  onCloseForm,
  formData,
  pageAttributes,
}) {
  const [open, setOpen] = React.useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationList, setLocationList] = useState([]);
  
  
  useEffect(() => {
    setOpen(openForm);
    setSelectedLocations([]);
    setSelectedUser("");
  }, [openForm]);

  const UserUrl = "user/dropdownList";
  const LocationUrl = "location/dropdown/list";

  useEffect(() => {
    application
      .post(UserUrl)
      .then((response) => {
        setUserList(response.data.data);
      })
      .catch((error) => {
        loggerFactory.error("Error fetching UserList:", error);
      });

    application
      .post(LocationUrl)
      .then((response) => {
        setLocationList(response.data.data);
      })
      .catch((error) => {
        loggerFactory.error("Error fetching LocationUrl:", error);
      });
  }, []);

  const handleLocationUpdate = (event) => {
    const locationName = event.target.value;
    setSelectedLocations((prevSelectedLocations) => {
      if (prevSelectedLocations.includes(locationName)) {
        return prevSelectedLocations.filter((name) => name !== locationName);
      } else {
        return [...prevSelectedLocations, locationName];
      }
    });
  };

  useEffect(() => {
    setSelectedUser(formData?.username || "");
    setSelectedLocations(formData?.locationName ? [formData.locationName] : []);
  }, [formData]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLocations(value);
  };

  const saveData = () => {
    const locationCode = selectedLocations.map((locationName) => {
      const location = locationList.find(
        (item) => item.locationName === locationName
      );
      return location ? location.locationCode : null;
    });

    if (formData.id) {
      const Data = {
        id: formData.id,
        username: selectedUser,
        locationCodes: locationCode,
      };
      onSaveForm(Data);
    } else {
      const Data = {
        id: 0,
        username: selectedUser,
        locationCodes: locationCode,
      };
      onSaveForm(Data);

      setSelectedLocations([]);
      setSelectedUser("");
    }
  };
  const handleCancel = () => {
    onCloseForm();
    setSelectedLocations([]);
    setSelectedUser("");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseForm}
        fullWidth
        maxWidth="lg"
        sx={(theme) => CTLDialogContent(theme, { darkMode })}
      >
        <DialogTitle sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          {entityName}
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDTypography variant="caption"> User </MDTypography>

          <Select
            fullWidth
            style={{ height: "60px" }}
            name="User"
            value={selectedUser}
            onChange={handleUserChange}
            MenuProps={MenuProps}
            sx={{
              marginBottom: "35px",
              "& option": {
                color: "#344767",
              },
            }}
            required
            disabled={formData.id}
          >
            <MenuItem value="">
              <em style={{ height: "40px" }}></em>
            </MenuItem>
            {userList.map((item) => (
              <MenuItem key={item.username} value={item.username}>
                <ListItemText primary={item.username} />
              </MenuItem>
            ))}
          </Select>

          <MDTypography variant="caption"> Locations </MDTypography>
          {formData.id ? (
            <Select
              fullWidth
              style={{ height: "60px" }}
              name="Location"
              value={selectedLocations}
              onChange={handleLocationUpdate}
              MenuProps={MenuProps}
              sx={{
                marginBottom: "35px",
                "& option": {
                  color: "#344767",
                },
              }}
              required
            >
              {locationList.map((item) => (
                <MenuItem key={item.locationCode} value={item.locationName}>
                  <ListItemText primary={item.locationName} />
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              multiple
              fullWidth
              name="Location"
              style={{ height: "60px" }}
              value={selectedLocations}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              sx={{
                marginBottom: "35px",
                "& option": {
                  color: "#344767",
                },
              }}
              MenuProps={MenuProps}
              disabled={selectedUser == ""}
            >
              {locationList.map((item) => (
                <MenuItem key={item.locationCode} value={item.locationName}>
                  <Checkbox
                    checked={selectedLocations?.indexOf(item.locationName) > -1}
                  />
                  <ListItemText primary={item.locationName} />
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleCancel} autoFocus color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={saveData} autoFocus color="success">
            Apply
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
