import { Fragment } from "react";
import cc from "classcat";
import { useGauge } from "use-gauge";
import getSpeedometerNeedleColor from "../../../utils/speedometerNeedleColor";
import "../css/segments.css";
import { v4 as uuidv4 } from "uuid";
import { useMaterialUIController } from "context";

function Gauge({ min, max, thresholdLower, thresholdUpper, needleValue }) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  const thresholdLowerPercent =
    (Math.abs(thresholdLower - min) / Math.abs(max - min)) * 100;

  const thresholdUpperPercent =
    (Math.abs(thresholdUpper - min) / Math.abs(max - min)) * 100;

  const calc = (Math.abs(needleValue - min) / Math.abs(max - min)) * 100;

  const gauge = useGauge({
    domain: [0, 100],
    startAngle: 90,
    endAngle: 270,
    numTicks: 1000,
    diameter: 200,
  });

  const needle = gauge.getNeedleProps({
    value: calc,
    baseRadius: 8,
    tipRadius: 0,
  });

  const needleColor = getSpeedometerNeedleColor(
    needleValue,
    min,
    thresholdLower,
    thresholdUpper,
  );
  let flagCounter = 0; //maxValue === 2
  return (
    <svg className="speedometer-svg" {...gauge.getSVGProps()}>
      <g id="ticks">
        {gauge.ticks.map((angle) => {
          const asValue = gauge.angleToValue(angle);

          const lineSegments = {
            "green-segment": asValue >= 0 && asValue <= thresholdLowerPercent,
            "yellow-segment":
              asValue > thresholdLowerPercent &&
              asValue <= thresholdUpperPercent,
            "red-segment": asValue > thresholdUpperPercent && asValue <= 100,
          };
          
          if (
            (asValue === Math.ceil(thresholdLowerPercent) &&
              flagCounter === 0) ||
            (asValue === Math.ceil(thresholdLowerPercent) && flagCounter === 1)
          )
            flagCounter++;
          return (
            <Fragment key={uuidv4()}>
              <line
                className={cc(lineSegments)}
                strokeWidth={2}
                {...gauge.getTickProps({
                  angle,
                  length: 20,
                })}
              />
              <text
                fill={isDarkMode ? "#FFF" : "#000"}
                fontSize="15"
                {...gauge.getLabelProps({ angle, offset: -35 })}
              >
                {asValue === 0
                  ? min
                  : asValue === Math.ceil(thresholdLowerPercent) &&
                    flagCounter === 1
                  ? thresholdLower
                  : asValue === Math.ceil(thresholdUpperPercent) &&
                    flagCounter === 2
                  ? thresholdUpper
                  : asValue === 100
                  ? max
                  : ""}
              </text>
            </Fragment>
          );
        })}
      </g>
      <text textAnchor="middle" y="-40" fill={isDarkMode ? "#FFF" : "#000"}>
        {needleValue.toFixed(2)}
      </text>
      <g id="needle">
        <circle style={{ fill: "#BEF264" }} {...needle.base} r={12} />
        <circle style={{ fill: "#3F3F46" }} {...needle.base} />
        <circle style={{ fill: needleColor }} {...needle.tip} />
        <polyline style={{ fill: needleColor }} points={needle.points} />
        <circle style={{ fill: "#FFF" }} {...needle.base} r={4} />
      </g>
    </svg>
  );
}

export default Gauge;
