import DefaultCell from "application/components/DefaultCell";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "md",
  settingsMode: false,
  columns: 3,
  enablePriPublish: false,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/systemConfig/list",
  update: "/systemConfig/update",
};

const getColumns = () => {
  return [
    {
      Header: "SMS-URL",
      accessor: "smsUrl",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "SMS-Text",
      accessor: "smsText",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Max-Sms-Try",
      accessor: "maxSmsTry",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "No-Of-Old-Day_Sms Send",
      accessor: "noOfOldDaySmsSend",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ];
};

export { getColumns, Attributes, operationURLS };
