export const addWidgetTableColumns = [
  {
    field: "categoryName",
    heading: "Category name",
    type: "string",
    display: true,
  },
  {
    field: "nodeName",
    heading: "Node name",
    type: "string",
    display: true,
  },
  {
    field: "name",
    heading: "Name",
    type: "string",
    display: true,
  },
  {
    field: "widgetName",
    heading: "Widget name",
    type: "string",
    display: true,
  },
  {
    field: "id",
    heading: "ID",
    type: "string",
    display: true,
  },
  {
    field: "metricName",
    heading: "Metric Name",
    type: "string",
    display: true,
  },
];
