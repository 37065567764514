
import FormField from "layouts/applications/wizard/components/FormField";

function CTLIpField({ name, value, onChange, error, success , placeholder,label,disabled }) {
  return (
    <>
      <FormField
        fullWidth
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        error={error}
        success={success}
        label={label}
        disabled={disabled}
      />
    </>
  );
}

export default CTLIpField;
