import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import CTLTable from "application/components/CTLTable";
import {Attributes, operationURLS } from "./model";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import CTLNotification from "application/components/CTLNotification";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import RowAction from "application/components/RowAction";
import DefaultCell from "application/components/DefaultCell";
import CTLBooleanCell from "application/components/CTLBooleanCell";
function InboundCos({ onLoad, pageId, searchText, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [metadata, setMetaData] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
      });
  }, [activePage]);

  useEffect(() => {
    application
      .get(`form/inboundCos`)
      .then((response) => {
        setMetaData(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [onLoad]);

  const getRows = (onDelete, onEdit) => {
    return (
      metadata &&
      metadata?.content?.map((item) => {
        const {
          Header,
          accessor,
          displayInList,
          controlType,
          allowInForm,
          dataType,
          allowFilter,
          isMandatory,
          options,
        } = item;
        let CellComponent;
        switch (Header) {
          case "Action":
            CellComponent = ({ row, value }) => (
              <RowAction
                row={row}
                value={value}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            );
            break;
          default:
            switch (dataType) {
              case "Boolean":
                CellComponent = ({ row, value }) => (
                  <CTLBooleanCell row={row} value={value} />
                );
                break;
              default:
                CellComponent = ({ value }) => <DefaultCell value={value} />;
            }
        }
        return {
          Header,
          accessor,
          displayInList,
          allowInForm,
          allowFilter,
          dataType,
          controlType,
          isMandatory,
          options,
          Cell: CellComponent,
        };
      })
    );
  };


  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        id: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.messageDetail,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      });

    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.messageDetail,
          });
        });
    } else {
      application
        .post(operationURLS.create, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: pageName,
            status: error.response.messageDetail,
          });
        });
    }
    setOpenForm(false);
  }
  function onCloseForm() {
    setOpenForm(false);
  }


  function onCloseFilter() {
    setOpenFilter(false);
  }
  return (
    <MDBox>
      {metadata && (
        <>
          <DeleteConfirmation
            entityName="InboundCos"
            data={selectedRow}
            columns={getRows(deleteRow, editRow)}
            confirmDelete={confirmDelete}
            onAgree={onAgree}
            onCancel={onCancel}
          />
          <CTLBaseDialogForm
            entityName="InboundCos"
            columns={getRows(deleteRow, editRow)}
            openForm={openForm}
            onSaveForm={onSaveForm}
            onCloseForm={onCloseForm}
            formData={formData}
            pageAttributes={Attributes}
          />
          <CTLAdvancedFilter
            entityName="InboundCos"
            columns={getRows(deleteRow, editRow)}
            applyFilter={openFilter}
            onApplyFilter={onApplyFilter}
            onCloseFilter={onCloseFilter}
          />

          <CTLTable
            apiURL={operationURLS.list}
            advancedFilters={advancedFilters}
            columns={getRows(deleteRow, editRow)}
            entriesPerPage={false}
            canSearch
            defaultSortDirection="caf"
            defaultSortColumn="id"
            pageId={pageId}
            list
            searchText={searchText}
            hasRowSelection={false}
            updateList={updateList}
            disablePagination={false}
          />
        </>
      )}
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default InboundCos;