const dateConverter = (t) => {
  const date = new Date(t.receivedEpoch);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const dateString = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, "0")}-${String(date.getDate()).padStart(
    2,
    "0",
  )} ${hours}:${minutes}:${seconds}`;

  return dateString;
};

function configs(timeseries) {
  const newTimeseries = timeseries.toReversed();

  const len = newTimeseries[0].attributeValue.length;

  const series = [];
  for (let i = 0; i < len; i++) {
    series.push({
      name: "",
      data: newTimeseries.map((t) => t.attributeValue[i]),
    });
  }

  return {
    series,
    options: {
      chart: {
        id: "timeseries-line",
      },
      xaxis: {
        categories: newTimeseries.map((t) => dateConverter(t).split(" ")[1]),
        labels: {
          rotateAlways: false,
          style: {
            colors: "#c1c4ce5c",
          },
        },
        axisBorder: {
          color: "#c1c4ce5c",
        },
        axisTicks: {
          color: "#c1c4ce5c",
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c1c4ce5c",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: "#c1c4ce5c",
        strokeDashArray: [5, 5],
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        size: 2.5,
        strokeWidth: 0,
        hover: {
          size: 4,
        },
      },
      stroke: {
        width: 2.5,
      },
      tooltip: {
        x: {
          show: true,
          formatter: (value, { dataPointIndex }) =>
            dateConverter(newTimeseries[dataPointIndex]),
        },
      },
    },
    height: "100%",
  };
}

export default configs;
