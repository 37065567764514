import { useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";

function AssetFilter({ asset, handleSearchAsset, level }) {
  const [filterText, setFilterText] = useState(asset);

  const handleFilterTextChange = (event) => setFilterText(event.target.value);

  return (
    <MDBox
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      mt={level === 2 && 1}
    >
      <MDInput
        id="search"
        type="text"
        label="Enter asset name"
        size="small"
        value={filterText}
        onChange={handleFilterTextChange}
      />
      <MDButton
        iconOnly
        variant="gradient"
        color="info"
        size="medium"
        sx={{ marginInline: ".5em" }}
        onClick={() => handleSearchAsset(filterText)}
      >
        <Icon fontSize="medium">search</Icon>
      </MDButton>
    </MDBox>
  );
}

export default AssetFilter;
