import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import { coralApi } from "./features/apiSlice";
// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import styled from "styled-components";
import SENSOR_CONFIGS from "layouts/nms/configs/sensorConfigs";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { store } from "features/store";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: SENSOR_CONFIGS[0],
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: SENSOR_CONFIGS[1],
    color: "#000",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: SENSOR_CONFIGS[2],
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: SENSOR_CONFIGS[3],
  },
  "&.notistack-MuiContent-default": {
    backgroundColor: SENSOR_CONFIGS[4],
  },
}));

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={process.env.REACT_APP_NOTIFICATION_MAX_SNACK}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
            default: StyledMaterialDesignContent,
          }}
        >
          <App />
        </SnackbarProvider>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
