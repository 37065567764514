import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function MenuCards({
  bgColor,
  color,
  title,
  icon,
  manager,
  sysConfig,
  handleOpenManager,
  handleOpenSysConfig,
}) {
  return (
    <>
      <Card>
        <MDBox display="flex" justifyContent="space-between" pt={1} px={1}>
          <MDBox
            variant="gradient"
            bgColor={bgColor}
            color={color === "light" ? "dark" : "white"}
            coloredShadow={color}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="2rem"
            height="2rem"
          >
            <Icon fontSize="medium" color={color}>
              {icon}
            </Icon>
          </MDBox>
          <MDTypography variant="h6" color="text">
            {title}
          </MDTypography>
        </MDBox>
        <Divider />
        <MDBox m={2} display="flex" justifyContent="space-between">
          <MDButton color="info" onClick={handleOpenManager}>
            {manager}
          </MDButton>
          <MDButton color="secondary" onClick={handleOpenSysConfig}>
            {sysConfig}
          </MDButton>
        </MDBox>
      </Card>
    </>
  );
}

export default MenuCards;
