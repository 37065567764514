import MDTypography from "components/MDTypography";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


function CTLUserStatusCell({ value, suffix }) {
  if (value === "Available") {
    suffix = (
      <tooltip title="Available">
        <FiberManualRecordIcon fontSize="medium" color="success" />
      </tooltip>
    );
  }
  if (value === "Not Available") {
    suffix = (
      <tooltip title="Not-Available">
        <FiberManualRecordIcon fontSize="medium" color="error" />
      </tooltip>
    );
  }
  if (value === "Busy") {
    suffix = (
      <tooltip title="Busy">
        <FiberManualRecordIcon fontSize="medium" color="warning" />
      </tooltip>
    );
  }
 
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLUserStatusCell.defaultProps = {
  suffix: "",
};

export default CTLUserStatusCell;
