export const tableData = [
  {
    id: 1,
    nodeId: 104,
    nodeName: "Dummy-1",
    category: "Dummy-2",
    status: "Active",
  },
  {
    id: 2,
    nodeId: 105,
    nodeName: "Dummy-3",
    category: "Dummy-4",
    status: "Active",
  },
  {
    id: 3,
    nodeId: 183,
    nodeName: "Dummy-5",
    category: "Dummy-6",
    status: "Active",
  },
  {
    id: 4,
    nodeId: 89,
    nodeName: "Dummy-7",
    category: "Dummy-8",
    status: "Active",
  },
  {
    id: 5,
    nodeId: 134,
    nodeName: "Dummy-9",
    category: "Dummy-10",
    status: "Active",
  },
  {
    id: 6,
    nodeId: 135,
    nodeName: "Dummy-11",
    category: "Dummy-12",
    status: "Active",
  },
  {
    id: 7,
    nodeId: 139,
    nodeName: "Dummy-13",
    category: "Dummy-14",
    status: "Active",
  },
  {
    id: 8,
    nodeId: 1008,
    nodeName: "Dummy-15",
    category: "Dummy-16",
    status: "Active",
  },
  {
    id: 9,
    nodeId: 1008,
    nodeName: "Dummy-17",
    category: "Dummy-18",
    status: "Active",
  },
  {
    id: 10,
    nodeId: 1008,
    nodeName: "Dummy-19",
    category: "Dummy-20",
    status: "Active",
  },
  {
    id: 11,
    nodeId: 1008,
    nodeName: "Dummy-21",
    category: "Dummy-22",
    status: "Active",
  },
  {
    id: 12,
    nodeId: 201,
    nodeName: "Dummy-23",
    category: "Dummy-24",
    status: "Active",
  },
];

export const dummyColumns = [
  {
    field: "id",
    heading: "ID",
    type: "number",
    display: true,
  },
  {
    field: "nodeId",
    heading: "Node ID",
    type: "number",
    display: true,
  },
  {
    field: "nodeName",
    heading: "Node Name",
    type: "string",
    display: true,
  },
  {
    field: "category",
    heading: "Category",
    type: "string",
    display: true,
  },
  {
    field: "status",
    heading: "Status",
    type: "boolean",
    display: true,
  },
];
