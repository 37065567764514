import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController,  } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import CTLNotification from "application/components/CTLNotification";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Wans({ onLoad, pageId, searchText, registerCallBacks,pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage , darkMode} = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [openConfigure,setOpenConfigure]=useState(false)
  const [loading, setLoading] = useState(false);
  const [openpublishData, setOpenPublishData] = useState(false);
  const [showContent, setShowContent] = useState(null);
  const [publishData, setPublishData] = useState({});
  const [filePreViewData, setFilePreViewData] = useState({});

  const handleOpen = () => setOpenPublishData(true);
  const handleClose = () => setOpenPublishData(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({
      openAdvancedFilter: onOpenFilter,
      openForm: onOpenForm,
      onPublish: onPublish,
      onFilePreView: onFilePreView,
    });
  }, [activePage]);


  const handleCloseConfigure=()=>{
    setOpenConfigure(false)
  }

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime : Date.now()
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  }

   // File PreView in Cluster
   const onFilePreView = () => {
    setShowContent("filePreview");
    application
      .post(operationURLS.publish, { isPreview: true })
      .then((response) => {
        setTimeout(() => {
          setFilePreViewData(response.data.data);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
          handleOpen();
        }, 2000);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // For Coral File  Publish

  const onPublish = () => {
    setOpenConfigure(true);
   
  };
  /// For Delete Cluster
  const onConfirmPublish =()=>{
    setOpenConfigure(false);
    setLoading(true);
    setShowContent("publish");
    application
      .post(operationURLS.publish, { isPreview: false })
      .then((response) => {
        setTimeout(() => {
          setPublishData(response.data.data);
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
          handleOpen();
        }, 2000);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            error.response.data.message +
            " " +
            error.response.data.messageDetail,
          type: "error",
          pagename: pageName,
          status: error.response.status,
        });
      })
      .finally(() => {
       
        setLoading(false);
      });
  }


  const onAgree = (row) => {
    application.post(operationURLS.delete, {
      id: [row.id]
    }).then((response) => {
      setUpdateList(Date.now());
      setNotify({
        isOpen: true,
        message: response.data.message,
        type: "success",
        pagename: pageName,
        status: response.data.status,
      });
    }).catch((error)=>{
      setNotify({
        isOpen: true,
        message:
          error.response.data.message +
          " " +
          error.response.data.messageDetail,
        type: "error",
        pagename: pageName,
        status: error.response.status,
      });
    })

    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.update, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          console.error('Error updating data:', error);
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    } else {
      application
        .post(operationURLS.register, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
            status: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message:
              error.response.data.message +
              " " +
              error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            status: error.response.status,
          });
        });
    }
    setOpenForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }
  
  return (
    <MDBox>
      <DeleteConfirmation
        entityName="Wans"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLBaseDialogForm
        entityName="Wans"
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes = {Attributes}
      />

      {/* <CTLAdvancedFilter
        entityName="Nodes"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      /> */}

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
      />
       <MDBox>
        <div>
          <Dialog
            PaperProps={{
              sx: {
                maxWidth: "80%", 
                maxHeight: "80%", 
              },
            }}
            open={openpublishData}
            classes={{ dialog }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={(theme) => CTLDialogContent(theme, { darkMode })}
            >
              <MDBox
                sx={{ display: "flex", justifyContent: "center" }}
                color="white"
              >
                Configure-Node Response
              </MDBox>
            </DialogTitle>
            <hr></hr>
            {showContent === "publish" && (
              <DialogContent
                sx={(theme) => ({
                  ...CTLDialogContent(theme, { darkMode }),
                  width: "100%",
                  whiteSpace: "pre-wrap",
                })}
              >
                {onPublish && typeof publishData === "string" && (
                  <pre>
                    {publishData.split("\n").map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </pre>
                )}
              </DialogContent>
            )}

            {showContent === "filePreview" && (
              <DialogContent
                sx={(theme) => ({
                  ...CTLDialogContent(theme, { darkMode }),
                  width: "100%",
                  whiteSpace: "pre-wrap",
                })}
              >
                {onFilePreView && typeof filePreViewData === "string" && (
                  <pre>
                    {filePreViewData.split("\n").map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </pre>
                )}
              </DialogContent>
            )}
            <DialogActions
              sx={(theme) => ({
                ...CTLDialogContent(theme, { darkMode }),
                justifyContent: "center",
              })}
            >
              <MDButton onClick={handleClose} color="secondary">
                Ok
              </MDButton>
            </DialogActions>
            <CTLNotification notify={notify} setNotify={setNotify} />
          </Dialog>
        </div>
        <MDBox>
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </MDBox>

        <Dialog open={openConfigure} maxWidth="md" classes={{ dialog }}>
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="alert-dialog-title"
        >
          Confirmation
        </DialogTitle>
        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          Are you sure you want to Run the Configure Node?
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleCloseConfigure} color="secondary">
            Cancel
          </MDButton>
          <MDButton onClick={onConfirmPublish} color="success" autoFocus>
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>

      </MDBox>
    </MDBox>
  );
}

export default Wans;
