import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  enableAdvanceSearch: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  create: "/denyList/create",
  update: "/denyList/update",
  list: "/denyList/list",
  delete: "/denyList/delete",
};


const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Caller Id Number",
      accessor: "number",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Deny Till Date & Time",
      accessor: "denyTillEpoch",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dateTime: true,
      dataType: "string",
      controlType: "dateTime",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
