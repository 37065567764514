import { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import calculateSmartAssetMarkerPosition from "../../../utils/calculateSmartAssetMarkerPosition";

function MapImage({
  isAdmin,
  isEditMode,
  handleAssignLocationPopupOpen,
  bufferLeft,
  bufferTop,
  handleParentRefCurrent,
  imageSrc,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuEvent, setContextMenuEvent] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const parent = document.getElementById("parent");
    handleParentRefCurrent(parent);
  }, []);

  const handleImageClick = (event) => {
    if (!imageRef.current) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateSmartAssetMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop,
    );

    handleAssignLocationPopupOpen(top, left);

    setContextMenu(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
    setContextMenuEvent(event);
  };
  return (
    <MDBox onContextMenu={handleContextMenu} sx={{ cursor: "context-menu" }}>
      <MDBox
        component="img"
        position="absolute"
        width="100%"
        height="100%"
        src={imageSrc}
        alt="MapImage"
        ref={imageRef}
      />
      {isAdmin && isEditMode && (
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => handleImageClick(contextMenuEvent)}>
            Assign location
          </MenuItem>
        </Menu>
      )}
    </MDBox>
  );
}

export default MapImage;
