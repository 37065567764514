import { useMemo, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter,  useAsyncDebounce,  useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import CTLTableBodyCell from "./CTLTableBodyCell";
import CTLTableHeadCell from "./CTLTableHeadCell";
import { Checkbox, TablePagination } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import {
  useMaterialUIController,
  setTableValue,
  setAction,
  setTableSortColumn, 
  setTableColumnDataType,
  setValue,
} from "context";
import CTLLinearProgress from "../CTLLinearProgress";
import { application } from "globals/endpoints";

const queryClient = new QueryClient();

function CTLTableContent({
  table,
  apiURL,
  isSorted,
  defaultSortColumn,
  defaultSortDirection,
  noEndBorder,
  pageId,
  searchText,
  hasRowSelection,
  advancedFilters,
  updateList,
  onSelectedIDdata,
  disablePagination,
  disableSort,
  refetchInterval,

}) {

  useEffect(() => { setSelectedId([]); setSelectAll(false); }, [updateList]);
  useEffect(() => {setQueryPageIndex(0);}, [searchText]);
  
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, value, action } = controller;
  const [clickedHeader, setClickedHeader] = useState("");
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageCount, setQueryPageCount] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortColumn, setQuerySortColumn] = useState(defaultSortColumn);
  const [querySortDirection, setQuerySortDirection] =
  useState(defaultSortDirection);
  const [querySortDataType, setQuerySortDataType] = useState("integer");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [lastEpoch, setLastEpoch] = useState(0);
  onSelectedIDdata(selectedId);

  const StyledTablePagination = withStyles((theme) => ({
    toolbar: {
      opacity: 0.7,
      fontWeight: "700",
      fontSize: "0.65rem",
      color: darkMode ? "white" : "secondary",
    },
  }))(TablePagination);

  const columns = useMemo(() => table.columns, [table]);
  //const data = useMemo(() => table.rows, [table]);
  const [tableData, setTableData] = useState([]);
  const { isLoading, error, data, isSuccess } = useQuery(
    [
      lastEpoch,
      pageId,
      searchText,
      advancedFilters,
      updateList,
      queryPageIndex,
      queryPageSize,
      querySortColumn,
      querySortDirection,
    ],
    () =>
      fetchData(
        queryPageIndex,
        queryPageSize,
        querySortColumn,
        querySortDirection,
        searchText,
        advancedFilters,
        updateList
      ),
    {
      keepPreviousData: false,
      staleTime: 5000,
      refetchInterval: refetchInterval,
    }
  );

  const fetchData = async (
    newPage,
    newPageSize,
    sortColumn,
    sortDirection,
    searchText,
    advancedFilters
  ) => {
    if (!sortDirection) {
      sortColumn = "";
    }
setQueryPageCount
    const pageDefinition = {
      currentPage: newPage,
      pageSize: newPageSize,
      sortDirection: sortDirection ? sortDirection : defaultSortDirection,
      sortBy: sortColumn ? sortColumn : defaultSortColumn,
      search: searchText,
      sortDataType: querySortDataType,
      advancedFilters: advancedFilters,
    };

    if (disablePagination) {
      const response = await application.post(apiURL, pageDefinition);
      setQueryPageCount(1);
      return {
        results: response.data.data,
      };
    } else {
      const response = await application.post(apiURL, pageDefinition);
      setQueryPageCount(response.data.data.totalRecords);
      return {
        results: response.data.data.currentPageData,
      };
    }
  };
  useEffect(() => {
    if (isSuccess) {
     
      setTableData(data?.results || []);
    } else {
      setTableData([]);
    }
  }, [data, isSuccess]);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      manualPagination: true,
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
    state: { pageSize },
  } = tableInstance;

  useEffect(() => {
    function setDefaults(pageSize) {
      setPageSize(pageSize);
    }
    setDefaults(pageSize || 10);
  }, [pageSize]);

  const handlePageChange = (event, newPage) => {
    setQueryPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setQueryPageSize(newPageSize);
  };

  const handleSortChange = (columnId, columnDataType) => {
    if (!disableSort) {
      if (querySortColumn === columnId) {
        if (querySortDirection === "asc") {
          setQuerySortDirection("desc");
        } else if (querySortDirection === "desc") {
          setQuerySortDirection("none");
        } else {
          setQuerySortDirection("asc");
        }
      } else {
        setQuerySortColumn(columnId);
        setTableSortColumn(dispatch, columnId);
        setQuerySortDirection("asc");
      }
      setQuerySortDataType(columnDataType);
      setTableColumnDataType( dispatch, columnDataType);
       setClickedHeader(columnId);
    }
  };

  const setSortedValue = (column) => {
    let sortedValue;
    let newSortInfo = {
      sortBy: "",
      sortDirection: "",
    };

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
      newSortInfo.sortDirection = column.isSortedDesc ? "desc" : "asc";
      newSortInfo.sortBy = column.id;
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const rowSelection = (rowId) => {
    const idList = [...selectedId];
    const itemIndex = idList.indexOf(rowId);
    if (itemIndex >= 0) {
      idList.splice(itemIndex, 1);
    } else {  idList.push(rowId); }
      setSelectedId(idList);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedId([]);
    } else {
      const allIds = tableData.map((row) => row.id);
      setSelectedId(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
   if ( value !== null && action != "") {
     if (action === "create") {
         setTableData((prevTableData) => [...prevTableData, value]);  
     } else if (action === "update") {
       setTableData((prevTableData) => {
         const updatedData = prevTableData.map((item) =>
           item.name === value.name ? value : item
         );
         return updatedData;
       });
     } else if (action === "delete") {
       setTableData((prevTableData) => {
         const filteredData = prevTableData.filter(
           (item) => item.name !== value.name
         );
         return filteredData;
       });
     }
     setAction(dispatch, "");
     setValue(dispatch, null);
   }
  }, [value, action]);

  useEffect(() => {
    setTableValue(dispatch, tableData);
  }, [dispatch, tableData]);

  return (
    <>
      <CTLLinearProgress isLoading={isLoading} />
      <TableContainer sx={{ boxShadow: "none" }}>
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {hasRowSelection && (
                  <CTLTableHeadCell width="50px">
                    <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  </CTLTableHeadCell>
                )}
                {headerGroup.headers.map((column) => {
                  if (column.displayInList) {
                    return (
                      <CTLTableHeadCell
                        {...column.getHeaderProps(isSorted)}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                        sorted={
                          querySortColumn === column.id
                            ? querySortDirection
                            : "none"
                        }
                        onClick={() =>
                          handleSortChange(column.id, column.dataType)
                        }
                        style={{
                          backgroundColor:
                            clickedHeader === column.id
                              ? darkMode
                                ? "white"
                                : "gray"
                              : "",
                          color:
                            clickedHeader === column.id
                              ? darkMode
                                ? "gray"
                                : "white"
                              : "",
                          padding: clickedHeader === column.id ? "10px" : "",
                          borderRadius:
                            clickedHeader === column.id ? "10px" : "",
                        }}
                      >
                        {column.render("Header")}
                      </CTLTableHeadCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {hasRowSelection && (
                    <CTLTableBodyCell>
                      <Checkbox
                        checked={selectedId.includes(row.original.id)}
                        onChange={() => rowSelection(row.original.id)}
                      />
                    </CTLTableBodyCell>
                  )}
                  {row.cells.map((cell) => {
                    if (cell.column.displayInList) {
                      return (
                        <CTLTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </CTLTableBodyCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!disablePagination && (
          <StyledTablePagination
            showFirstButton={true}
            showLastButton={true}
            component="div"
            page={queryPageIndex}
            count={queryPageCount}
            rowsPerPage={pageSize}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
}

CTLTableContent.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

CTLTableContent.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

const TableWrapper = ({
  table,
  apiURL,
  defaultSortColumn,
  defaultSortDirection,
  pageId,
  searchText,
  hasRowSelection,
  advancedFilters,
  updateList,
  onSelectedIDdata,
  disablePagination,
  disableSort,
  refetchInterval,
}) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CTLTableContent
          onSelectedIDdata={onSelectedIDdata}
          table={table}
          apiURL={apiURL}
          defaultSortColumn={defaultSortColumn}
          defaultSortDirection={defaultSortDirection}
          pageId={pageId}
          searchText={searchText}
          hasRowSelection={hasRowSelection}
          advancedFilters={advancedFilters}
          updateList={updateList}
          disablePagination={disablePagination}
          disableSort={disableSort}
          refetchInterval={refetchInterval == null ? 0 : refetchInterval}
        />
      </QueryClientProvider>
    </>
  );
};

export default TableWrapper;
