import React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ucMenus } from "globals/configuration/uc";
import { application } from "globals/endpoints";
import SidebarCollapse from "./SidebarCollapse";
import SidebarList from "./SidebarList";
import SideBarItem from "./SideBarItem";
import SidebarRoot from "./SidebarRoot";
import sidebarLogoLabel from "./styles/sidebar";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { ivrMenus } from "globals/configuration/ivr/menu";
import { callCenterMenus } from "globals/configuration/call-center/menu";
import { kmAdminMenus } from "globals/configuration/kmAdmin";
import { Menus } from "globals/configuration/changePass";
import { quickMenus } from "globals/configuration/quickMenus";
import { ucNotificationMenus } from "globals/configuration/ucNotification";
import { nmsQuickMenus } from "globals/configuration/nmsQuickMenus";
import CTLUserStatus from "../CTLUserStatus";

import {
  useMaterialUIController,
  setMiniSidenav,
  setActivePage,
  setSearchText,
  setProfileStatus,
  setManualCall,
} from "context";
import { adminMenus } from "globals/configuration/admin";
import NotificationManager from "globals/websocket/WebSocket";

function CTLSideBar({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    activePage,
    profileStatus,
    manualCall,
  } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = activePage.url.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  function LoginStatus() {
    application
      .post("/customerAccount/status")
      .then((response) => {
        const responseData = response.data.data;
        setProfileStatus(dispatch, responseData);
      })
      .catch((error) => {
        setProfileStatus(dispatch, error.response.data.data);
        if (error.response.status === 400) {
          navigateToHomePage();
        }
      });
  }

  useEffect(() => {
    if (AuthenticationService.allowUCMenus()) {
      LoginStatus();
    }
  }, []);

  const navigateToHomePage = () => {
    (activePage.key = "uc.settings"),
      (activePage.name = "User Profile"),
      (activePage.description = "User Profile"),
      (activePage.url = "home/app/uc/settings"),
      setActivePage(dispatch, activePage);
    return true;
  };

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
    // LoginStatus();
  }, []);

  //handle Notification Bar
  function handleNotificationBar() {
    setMiniSidenav(dispatch, true);
  }

  useEffect(() => {
    handleNotificationBar();
    return () => {};
  }, [dispatch, location]);

  //Link to Page clicked
  const menuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setRefreshComponent(true);
    setSearchText(dispatch, "");
  };

  const handleAgentStatus = () => {
    const payload = { status: "Logout" };
    application
      .post("/agentStatus/create", payload)
      .then((response) => {
        console.log("agent Status", response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 

  const [refreshComponent, setRefreshComponent] = useState(false);
  const [counts, setCounts] = useState([]);
  const GetNotifications = "/uc/countNotification/list";
  function GetNotification() {
    application
      .post(GetNotifications)
      .then((res) => {
        const response = res.data.data;
        setCounts(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (AuthenticationService.allowUCMenus()) {
      const interval = setInterval(() => {
        GetNotification();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [counts]);


  const renderNotifications = useMemo(() => {
    const countsByMessageType = (counts || []).reduce((acc, notification) => {
      acc[notification.messageType] = notification.count;
      return acc;
    }, {});

    return ucNotificationMenus.map((menu) => {
      if (menu.type === "divider") {
        return null;
      }
      const count = countsByMessageType[menu.messageType] || 0;
      if (count) {
        return (
          <Link
            key={menu.key}
            onClick={() => menuClicked(menu)}
            to="javascript:void(0)"
          >
            <SidebarCollapse
              name={menu.name}
              icon={menu.icon}
              badge={count}
              count={count}
            />
          </Link>
        );
      } else {
        return null;
      }
    });
  }, [counts, ucNotificationMenus, refreshComponent]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SideBarItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SideBarItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SideBarItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SideBarItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SideBarItem
              color={color}
              name={name}
              active={key === activePage.key}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SideBarItem
              color={color}
              name={name}
              active={key === activePage.key}
            />
          </NavLink>
        );
      }
      return <SidebarList key={key}>{returnValue}</SidebarList>;
    });

  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      status,
    }) => {
      let returnValue;
      if (type === "collapse") {
        if (status === "Logout") {
          returnValue = (
            <Link
              onClick={handleAgentStatus}
              href={route}
              key={key}
              sx={{ textDecoration: "none" }}
            >
              <SidebarCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidebarCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidebarCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidebarCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidebarCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  let renderIvrMenu = null;

  renderIvrMenu = adminMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderCallCenterMenu = null;

  renderCallCenterMenu = callCenterMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderAdminMenu = null;

  renderAdminMenu = ivrMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderquickMenu = null;
  renderquickMenu = quickMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let renderMenu = null;
  renderMenu = Menus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  let rendernmsMenu = null;
  rendernmsMenu = nmsQuickMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidebarCollapse name={menu.name} icon={menu.icon} />
      </Link>
    );
  });

  return (
    <SidebarRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={1} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && (
            <MDBox
              component="img"
              src={
                (darkMode && !whiteSidenav) ||
                (!darkMode && !whiteSidenav && !transparentSidenav)
                  ? "/logoDark.png"
                  : "/logoLight.png"
              }
              alt="Brand"
              width="100%"
            />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidebarLogoLabel(theme, { miniSidenav })}
          ></MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      {AuthenticationService.allowUCMenus() && (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CTLUserStatus />
        </MDBox>
      )}

      <MDTypography variant="h6" color="info" sx={{ textAlign: "center" }}>
        {AuthenticationService.getUserName()}
      </MDTypography>

      {AuthenticationService.getExtensionNumber() && (
        <MDTypography variant="body2" color="info" sx={{ textAlign: "center" }}>
          {AuthenticationService.getExtensionNumber()}
        </MDTypography>
      )}
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      {AuthenticationService.allowUCMenus() && (
        <List>{renderNotifications}</List>
      )}
      {!AuthenticationService.allowAgentMenus() && (
        <List>{renderquickMenu}</List>
      )}
      <List>{renderMenu}</List>
      <List>{renderRoutes}</List>
    </SidebarRoot>
  );
}

// Setting default values for the props of CTLSideBar
CTLSideBar.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the CTLSideBar
CTLSideBar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CTLSideBar;
