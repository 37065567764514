import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setActivePage } from "context";
import CTLTable from "application/components/CTLTable";
import { getColumns, Attributes, operationURLS } from "./model";
import DeleteConfirmation from "application/components/DeleteConfirmation";
import CTLAdvancedFilter from "application/components/CTLAdvancedFilter";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";
import loggerFactory from "globals/logger/logger-factory";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";

function PhoneBook({
  onLoad,
  mode,
  pageId,
  defaultSortColumn,
  defaultSortDirection,
  searchText,
  registerCallBacks,
  onCall,
  pageName
}) {
  const componentName = "uc.phone.book";
  
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openAdvancedFilter: onOpenFilter,
        openForm: onOpenForm,
        onPublish: onPublish,
      });
  }, [activePage]);

  function onOpenFilter() {
    setOpenFilter(true);
  }

  function onOpenForm() {
    let epoch = Date.now();
    setFormData({
      epoch : epoch
    });
    setOpenForm(true);
  }

  function onCloseFilter() {
    setOpenFilter(false);
  }

  function onApplyFilter(advancedFilters) {
    setAdvancedFilters(advancedFilters);
    setOpenFilter(false);
  }

  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  const onPublish = () => {
    application.post(operationURLS.publish);
  };

  const onToggleFavorite = (data) => {
    loggerFactory.debug(componentName,"Toggle Favorite", data);

    application.post(operationURLS.toggleFavorite,{
      id : data.id,
      favorite: data.favorite ? false : true
    });
  }

  const onAgree = (row) => {
    application
      .post(operationURLS.delete, {
        id: [row.id],
      })
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: 'success',
          pagename: pageName,
          status: response.data.status,
        })
      });

    setConfirmDelete(false);
  };

  const onCancel = () => {
    setSelectedRow([]);
    setConfirmDelete(false);
  };

  function onSaveForm(data) {
    if (data.id) {
      application.post(operationURLS.update, data).then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: 'success',
          pagename: pageName,
          status: response.data.status,
        })
      }) .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: 'error',
          pagename: pageName,
          status:error.response.status,
        })
      });
    } else {
      application.post(operationURLS.register, data).then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          message: response.data.message,
          type: 'success',  
           pagename: pageName,
           status: response.data.status,
        })
      }).catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: 'error',
          pagename: pageName,
          status:error.response.status,
        })
      });
    }
   onCloseForm();
  }
  function onCloseForm() {
    setOpenForm(false);
  }

  return (
    <MDBox>
      <DeleteConfirmation
        entityName="PhoneBook"
        data={selectedRow}
        columns={getColumns(deleteRow, editRow)}
        confirmDelete={confirmDelete}
        onAgree={onAgree}
        onCancel={onCancel}
      />

      <CTLBaseDialogForm
        entityName="PhoneBook"
        columns={getColumns(deleteRow, editRow)}
        openForm={openForm}
        onSaveForm={onSaveForm}
        onCloseForm={onCloseForm}
        formData={formData}
        pageAttributes={Attributes}
      />

      <CTLAdvancedFilter
        entityName="PhoneBook"
        columns={getColumns(deleteRow, editRow)}
        applyFilter={openFilter}
        onApplyFilter={onApplyFilter}
        onCloseFilter={onCloseFilter}
      />

      <CTLTable
        apiURL={operationURLS.list}
        advancedFilters={advancedFilters}
        columns={getColumns(deleteRow, editRow, onCall, onToggleFavorite)}
        entriesPerPage={false}
        canSearch
        defaultSortDirection="asc"
        defaultSortColumn="id"
        pageId={pageId}
        searchText={searchText}
        hasRowSelection={false}
        updateList={updateList}
        refetchInterval={Attributes.refetchInterval}
        onCall={onCall}
      />
       <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default PhoneBook;


