const ivrMenus = [
    {
        icon: "phone",
        key: "configuration.ivr",
        name: "Ivr",
        url: "home/app/configurations/ivr",
        description: "",
        disabled: false,
        productionReady : true
      }, 
]

export { ivrMenus }