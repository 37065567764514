import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { callCenterConfigMenuMoreVert } from "globals/configuration/callCenterConfigMenuMoreVert";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: callCenterConfigMenuMoreVert.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "/dnd/list",
  update: "/dnd/update",
  create: "/dnd/create",
  delete: "/dnd/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Phone Number",
      accessor: "phoneNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Ref No",
      accessor: "refNo",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
