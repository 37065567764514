import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import { userDefinedWidgetMenus } from "globals/configuration/nmsDashboard";
import CTLDashboardGroupCell from "application/components/CTLDashboardGroupCell";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: userDefinedWidgetMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "dashboard/list",
  update: "dashboard/update",
  create: "dashboard/create",
  delete: "dashboard/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Chart Type",
      accessor: "chartType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Legend Field Data Type",
      accessor: "legendFieldDataType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "dataType",
        keyDescription: "dataType",
        values: [],
        url: "widget/dataTypeList",
      },
    },
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Refresh Interval",
      accessor: "refreshInterval",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      validation: formControl.string().digitsOnly().nullable().required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Code",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Has Web Socket Update",
      accessor: "hasWebSocketUpdate",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Image Name",
      accessor: "imageName",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      groupHeading: "Category Service",
      options: {
        keyField: "categoryCode",
        keyDescription: "categoryName",
        values: [],
        url: "categoryService/dropdownCategoryCode/list",
      },
    },
    {
      Header: "Is Default Dashboard",
      accessor: "isDefaultDashboard",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Export",
      accessor: "export",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Enabled",
      accessor: "enabled",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Dashboard Group",
      accessor: "dashboardGroup",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <CTLDashboardGroupCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "/dashboardGroup/listAll",
      },
    },
    {
      Header: "Dashboard-Layout-Type",
      accessor: "dashboardlayouttype",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [{ description: "Option 1" }, { description: "Option 2" },{ description: "Option 3" }],
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
