import Select from "@mui/material/Select";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { application } from "globals/endpoints";

function CTLSelect({
  label,
  name,
  value,
  onChange,
  error,
  column,
  success,
  disabled,
  url,
  options,
  keyField,
  keyDescription,
  accessorKeyField,
  accessorValueField
}) {
  const [listOptions, setListOptions] = useState(options);
  useEffect(() => {
    if (url) {
      application.post(url).then((response) => {
        setListOptions(response.data.data);
      });
    }
  }, []);
  return (
    <>
      <MDTypography variant="caption">{label}</MDTypography>
      <Select
        fullWidth
        native
        name={name}
        disabled={disabled}
        value={accessorKeyField ? value[accessorKeyField] : value}
        onChange={onChange}
        sx={{
          backgroundColor: disabled ? "aliceblue" : "inherit",
          marginBottom: "4px",
          "& option": {
            color: "#344767",
          },
        }}
      >
        <option key="" value=""> Select </option>
        {listOptions.map((option) => {
          return (
            <option key={option[keyField]} value={option[keyField]}>
              {option[keyDescription]}
            </option>
          );
        })}
      </Select>
    </>
  );
}
export default CTLSelect;
