import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import CTLDashboardGroupCell from "application/components/CTLDashboardGroupCell";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAdvanceSearch: false,
  enableGroupHeading: false,
  verticalTabs: false,
  tabSize: 4,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: true,
};

const operationURLS = {
  list: "/dashboardWidget/list",
  update: "/dashboardWidget/update",
  register: "/dashboardWidget/create ",
  delete: "/dashboardWidget/delete",
  accessKey: "/user/createAPIKey",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 12,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "DashboardGroup",
      accessor: "dashboardGroup",
      displayInList: true,
      allowInForm: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      width: 6,
      Cell: ({ value }) => <CTLDashboardGroupCell value={value} />,
      tabId: 0,
      options: {
        keyField: "id",
        keyDescription: "name",
        values: [],
        url: "/dashboardGroup/listAll",
      },
    },
    {
      Header: "Code",
      accessor: "code",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Sensor",
      accessor: "sensor",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "LegendFieldDataType",
      accessor: "legendFieldDataType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "ValueFieldDataType",
      accessor: "valueFieldDataType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "WidgetType",
      accessor: "widgetType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Api",
      accessor: "api",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "RefreshInterval",
      accessor: "refreshInterval",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "ChartType",
      accessor: "chartType",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      dataType: "string",
    },
    {
      Header: "ImageName",
      accessor: "imageName",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: true,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "IsDefaultDashboard",
      accessor: "isDefaultDashboard",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Enabled",
      accessor: "enabled",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "export",
      accessor: "export",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
    },
    {
      Header: "HasWebSocketUpdate",
      accessor: "hasWebSocketUpdate",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      width: 6,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          // onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
