import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useMaterialUIController } from "context";
import IconButton from "@mui/material/IconButton";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import CTLDialogContent from "../styles/CTLDialogContent";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import dialog from "assets/theme/components/dialog";
import MDButton from "components/MDButton";
import { application } from "globals/endpoints";
import CTLNotification from "application/components/CTLNotification";

export default function CTLForwardFax({ row }) {
  const Id = row.original.rowId
  const [formData, setformData] = useState({
    messageValue: "",
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  const [showNotification, setShowNotification] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newData = [value]
    setSelectedValues(newData);
  };

  const handleRemarksChange = (e) => {
    setformData({ ...formData, messageValue: e.target.value });
  };

  const operationURLS = {
    forwardFax: "/fax/transfer",
    selectList: "/fax/unLoggedUser/list",
  };

  // selected List
  const selectedlist = () => {
    application.post(operationURLS.selectList).then((response) => {
      setList(response.data.data);
    });
  };

  useEffect(() => {
    selectedlist();
  }, []);

  const onSend = () => {
    application
      .post(operationURLS.forwardFax, {
        extension: selectedValues,
        remarks: formData.messageValue,
        id: Id
      })

      .then((response) => {
        setSelectedValues([]);
        setformData({ messageValue: "" });
        setOpen(false);
        setShowNotification(true);
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Sent Fax Status",
          status: response.data.status,
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.messageDetail,
          type: "error",
          pagename: "fax",
          status: error.response.data.status,
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={"Forward"}>
        <IconButton color="primary" onClick={handleClickOpen}>
          <ShortcutIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="forward-message-dialog-title"
        aria-describedby="forward-message-dialog-description"
        maxWidth="md"
        classes={{ dialog }}
      >
        <DialogTitle
          sx={(theme) => CTLDialogContent(theme, { darkMode })}
          id="forward-message-dialog-title"
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Fax-Transfer
          </div>
          <FormControl fullWidth>
            <InputLabel>Select-User</InputLabel>
            <Select
              sx={{ height: 50 }}
              value={selectedValues}
              onChange={handleChange}
              label="Select-User"
              displayEmpty
              renderValue={(selected) => selected || "Select User"}
              inputProps={{ "aria-label": "Without label" }}
            >
              {list.map((item) => (
                <MenuItem key={item.extension} value={item.extension}>
                  <ListItemText primary={item.extension} />
                  <ListItemText primary={item.profileId} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogTitle>

        <DialogContent sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDBox sx={{ width: 400 }}>
            <MDInput
              rows={8}
              multiline
              fullWidth
              onChange={handleRemarksChange}
              value={formData.messageValue}
            />
          </MDBox>
        </DialogContent>
        <DialogActions sx={(theme) => CTLDialogContent(theme, { darkMode })}>
          <MDButton onClick={handleClose} color="secondary">
            Cancel
          </MDButton>
          <MDButton color="success" autoFocus onClick={onSend} type="submit">
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}
