import CTLCategoryServiceCell from "application/components/CTLCategoryServiceCell";
import CTLUnReadCell from "application/components/CTLUnReadCell";
import RowAction from "application/components/RowAction";
import { nmsMenus } from "globals/configuration/nms";

const Attributes = {
  formSize: "lg",
  columns: 3,
  enableAddButton: true,
  modes: nmsMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  create: "snmpRules/create",
  update: "snmpRules/update",
  delete: "snmpRules/delete",
  list: "snmpRules/list",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Trap Id",
      accessor: "trapId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 6,

      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Trap Type",
      accessor: "trapIdType",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      controlType: "select",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [
          { id: "F", description: "Service Failure" },
          { id: "S", description: "Service Success" },
          { id: "C", description: "Condition Based Rule" },
        ],
        url: "",
      },

      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Success Service Variable",
      accessor: "successServiceCodeVariable",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Success Service Value",
      accessor: "successServiceCodeValue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Failure Service variable",
      accessor: "failureServiceCodeVariable",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Failure Service Value",
      accessor: "failureServiceCodeValue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      width: 6,
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Service Code",
      accessor: "serviceCode",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",
      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Success Variable",
      accessor: "successVariable",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Success Value",
      accessor: "successValue",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },

    {
      Header: "Severity",
      accessor: "severity",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <CTLCategoryServiceCell value={value} />,
      dataType: "string",
      controlType: "selectWithColor",
      options: {
        keyField: "severityLevel",
        keyDescription1: "severityName",
        keyDescription2: "color",
        values: [],
        url: "severity/listAll",
      },
    },
    {
      Header: "Slot",
      accessor: "slot",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <CTLUnReadCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
