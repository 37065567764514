import { v4 as uuidV4 } from "uuid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import variables from "globals/variables";
import { telemetry } from "globals/endpoints";

function ContextMenu({
  contextMenuData,
  contextMenu,
  handleClose,
  nodeDatum,
  handlePopupTopologyOpen,
  handleManagementInterface,
  handleEnableGraphTopoDrag,
  handleDashboard,
}) {
  const handleAction = async (apiURL) => {
    if (apiURL === null) {
      alert("No API URL found for this asset.");
      handleClose();
      return;
    }

    const baseURL = variables.api.telemetry;
    const dollar = "$";
    let URL = null;
    URL = apiURL.replace(`${dollar}{{baseURL}}`, baseURL);
    URL = URL.replace("{id}", nodeDatum.id);
    URL = URL.replace("{{ipAddress}}", nodeDatum.ipAddress);
    URL = URL.replace("{{ipAddres}}", nodeDatum.ipAddress);
    try {
      const actionResponse = await telemetry.post(URL);
      alert(actionResponse.data);
    } catch (e) {
      alert(e.message);
    }
    handleClose();
  };

  return contextMenuData
    ? (contextMenuData.hasManagementInterface ||
        contextMenuData.managementURL ||
        contextMenuData.hasSelfDashboard ||
        contextMenuData.actions.length > 0 ||
        nodeDatum.children.length > 0) && (
        <Menu
          open={contextMenu !== null}
          onClose={() => {
            handleClose();
            handleEnableGraphTopoDrag();
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {nodeDatum.children.length > 0 && (
            <MenuItem key={uuidV4()} onClick={handlePopupTopologyOpen}>
              Open current node topology
            </MenuItem>
          )}
          {(contextMenuData.hasManagementInterface ||
            contextMenuData.managementURL) && (
            <MenuItem
              key={uuidV4()}
              onClick={() =>
                handleManagementInterface(contextMenuData.managementURL)
              }
            >
              Management Interface
            </MenuItem>
          )}
          {contextMenuData.hasSelfDashboard && (
            <MenuItem key={uuidV4()} onClick={handleDashboard}>
              Dashboard
            </MenuItem>
          )}
          {contextMenuData.actions.map((action) => (
            <MenuItem
              key={uuidV4()}
              onClick={() => handleAction(action.apiURL)}
            >
              {action.actionName}
            </MenuItem>
          ))}
        </Menu>
      )
    : nodeDatum.children.length > 0 && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem key={uuidV4()} onClick={handlePopupTopologyOpen}>
            Open current node topology
          </MenuItem>
        </Menu>
      );
}

export default ContextMenu;
