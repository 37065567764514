import React, { useState } from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { application } from 'globals/endpoints';

export default function CTLAddFav({ row, value, onFavorite }) {
    return (
        <>
            {value ? <FavoriteIcon color='error' onClick={() => onFavorite(row.original)} /> : <FavoriteBorderIcon onClick={() => onFavorite(row.original)} />}

        </>
    );
}