import Card from "@mui/material/Card";
import React from "react";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import Footer from "features/Footer";


//Table 
import CTLTable from "application/components/CTLTable";

// Columns
import { Columns } from "./table-definition";

function NMSAlerts() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
            <CTLTable apiURL="/alertDetail/getFilterDataV2" columns={Columns} entriesPerPage={false} canSearch  />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
  }
export default NMSAlerts;
