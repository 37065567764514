import { useEffect } from "react";

import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import Footer from "features/Footer";

import { useMaterialUIController, setActivePage } from "context";

function Provisioning() {
    const [controller, dispatch] = useMaterialUIController();
    const { activePage } = controller;

    useEffect(() => {
        activePage.key = "provisioning";
        activePage.name = "Provisioning";
        activePage.url = "system/provisioning";
        activePage.description = "Configure your devices"
        setActivePage(dispatch, activePage);
    }, [activePage]);

    return (
        <DashboardLayout>
            <DashboardNavbar /> 
            <Footer />
        </DashboardLayout>
    );
}

export default Provisioning