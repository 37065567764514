import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import formControl from "globals/form-control";
import CTLBooleanCell from "application/components/CTLBooleanCell";
import { userDefinedWidgetMenus } from "globals/configuration/nmsDashboard";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: userDefinedWidgetMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
    };
  }),
  settingsMode: false,
};

const operationURLS = {
  list: "lineChart/list",
  update: "lineChart/update",
  create: "lineChart/create",
  delete: "lineChart/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Full Name",
      accessor: "name",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      width:12
    },
    {
      Header: "Refresh Interval",
      accessor: "refreshInterval",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width:6,
      validation: formControl
        .string()
        .digitsOnly()
        .nullable()
        .required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Code-IDentifier",
      accessor: "code",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Metrics Data Duration",
      accessor: "metricsDataDuration",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      width:6,
      validation: formControl
        .string()
        .digitsOnly()
        .nullable()
        .required("Required"),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Widget Type",
      accessor: "WidgetType",
      displayInList: false,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      width:6,
      Cell: ({ value }) => <DefaultCell value={value} />,
      options: {
        keyField: "id",
        keyDescription: "description",
        url: "/widgetType/listAll",
      },
    },
   
    {
      Header: "API URL",
      accessor: "apiURL",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Chart Type",
      accessor: "chartType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Legend Field",
      accessor: "legendField",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Legend Field Data Type",
      accessor: "legendFieldDataType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "dataType",
        keyDescription: "dataType",
        values: [],
        url: "widget/dataTypeList",
      },
    },
    {
      Header: "Sensor Attribute",
      accessor: "sensorAttribute",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Value Field",
      accessor: "valueField",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "value Field Data Type",
      accessor: "valueFieldDataType",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      options: {
        keyField: "dataType",
        keyDescription: "dataType",
        url: "widget/dataTypeList",
      },
    },
   
    {
      Header: "Has Web Socket Update",
      accessor: "hasWebSocketUpdate",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      controlType: "boolean",
      Cell: ({ value }) => <CTLBooleanCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Metrics Name",
      accessor: "metricsName",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
    },
    {
      Header: "Category",
      accessor: "category",
      displayInList: false,
      allowInForm: false,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required"),
      groupHeading: "Category Service",
      options: {
        keyField: "categoryCode",
        keyDescription: "categoryName",
        values: [],
        url: "categoryService/dropdownCategoryCode/list",
      },
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
