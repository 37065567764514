
import MDTypography from "components/MDTypography";

function CTLSendFaxStatusCell({ value, suffix }) {
  if (value == 1) {
    suffix = "Success";
  }
  if (value == 0) {
    suffix = "Failed";
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLSendFaxStatusCell.defaultProps = {
  suffix: "",
};

export default CTLSendFaxStatusCell;
