import NodeLabel from "../NodeLabel";

const TreeNode = (
    nodeDatum,
    toggleNode,
    addChild,
    deleteRow,
    shiftNode,
    onOpenForm,
    editProperty,
    onOpenMenu,
    onNavigate,
    onTransferNode
  ) => {
    return (
      <NodeLabel
        onOpenForm={onOpenForm}
        deleteRow={deleteRow}
        toggleNode={toggleNode}
        nodeDatum={nodeDatum}
        addChild={addChild}
        shiftNode={shiftNode}
        editProperty={editProperty}
        onOpenMenu={onOpenMenu}
        onNavigate={onNavigate}
        onTransferNode={onTransferNode}
        foreignObjectProps={{
          width: 150,
          height: 150,
          x: -75,
          y: -60,
        }}
      ></NodeLabel>
    );
  };

  export default TreeNode;
  