import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function Orientation({ orientation, handleOrientationChange }) {
  return (
    <FormControl sx={{ zIndex: "2", position: "absolute", right: ".7em", top: ".7em" }}>
      <FormLabel id="demo-controlled-radio-buttons-group">Orientation</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={orientation}
        onChange={handleOrientationChange}
      >
        <FormControlLabel value="horizontal" control={<Radio />} label="Horizontal" />
        <FormControlLabel value="vertical" control={<Radio />} label="Vertical" />
      </RadioGroup>
    </FormControl>
  );
}

export default Orientation;