import { useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetDataQuery } from "features/apiSlice";
import counterData from "../../data/counterData";
import Dropdown from "../../Dropdown";
import { COUNTER_DROPDOWN_LIST } from "../../configs/dropdownConfigs";
import ContextMenu from "../ContextMenu";

function Counter({
  widget,
  cellWidgetInfo,
  index,
  containerType,
  widgetHeight,
  masterContainerId,
  cellId,
  handleDeleteWidget,
  isEditMode,
  isAdmin,
}) {
  let getDataURL = null;
  let refreshInterval = 0;
  if (widget) {
    getDataURL = widget.getDataURL;
    refreshInterval = widget.refreshInterval ? widget.refreshInterval : 0;
  }

  const counterQuery = getDataURL
    ? useGetDataQuery(getDataURL, {
        pollingInterval: refreshInterval,
        refetchOnReconnect: true,
      })
    : {};

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleClose = () => setContextMenu(null);

  const draggableId = `${cellWidgetInfo.widgetType}/////${
    cellWidgetInfo.id
  }/////${uuidv4()}`;

  let primaryLabel = null;
  let count = null;
  let error = null;
  if (widget) {
    if (counterQuery.data) {
      primaryLabel = widget.primaryLabel ? widget.primaryLabel : "";
      count = counterQuery.data.deviceCount;
    } else {
      primaryLabel = "Unable to update the widget";
      count = "";
      error = (
        <Icon sx={{ marginTop: ".3em", color: "red" }} fontSize="medium">
          warningambericon
        </Icon>
      );
    }
  } else {
    primaryLabel = "Widget ID not configured";
    count = counterData;
    error = (
      <Icon sx={{ marginTop: ".3em", color: "red" }} fontSize="medium">
        warningambericon
      </Icon>
    );
  }

  const styles = {
    flexGrow: "1",
    flexBasis: "0",
    margin: ".5em",
    marginTop: index > 0 && containerType === "vertical" ? "1em" : "0.5em",
    cursor: "context-menu",
  };
  if (containerType === "vertical") styles.height = widgetHeight;

  const renderCounter = (
    <MDBox position="relative" p={2}>
      <MDBox
        variant="gradient"
        bgColor="info"
        color="white"
        coloredShadow="info"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="2.5rem"
        height="2.5rem"
        position="absolute"
        top="-.7em"
      >
        <Icon fontSize="small">leaderboard</Icon>
      </MDBox>
      <MDBox display="flex" justifyContent="flex-end">
        <MDBox mr={1} textAlign="right">
          <MDTypography variant="h3" fontWeight="medium">
            {primaryLabel}
          </MDTypography>
          <MDTypography variant="h3" color="text">
            {count}
          </MDTypography>
        </MDBox>
        {error}
        {isEditMode && isAdmin && (
          <Dropdown
            handleDeleteWidget={handleDeleteWidget}
            masterContainerId={masterContainerId}
            cellId={cellId}
            index={index}
            options={COUNTER_DROPDOWN_LIST}
          />
        )}
      </MDBox>
    </MDBox>
  );

  const pdfExportId = `${primaryLabel}/${cellWidgetInfo.id}`;

  return isEditMode ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <Card
          sx={styles}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderCounter}
        </Card>
      )}
    </Draggable>
  ) : (
    <Card sx={styles} onContextMenu={handleContextMenu} id={pdfExportId}>
      {renderCounter}
      {widget && (
        <ContextMenu
          id={pdfExportId}
          contextMenu={contextMenu}
          handleClose={handleClose}
          name={primaryLabel}
        />
      )}
    </Card>
  );
}

export default Counter;
