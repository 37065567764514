import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { userMenus } from "globals/configuration/user-management";
import formControl from "globals/form-control";

const Attributes = {
  formSize: "md",
  columns: 3,
  enableAddButton: true,
  modes: userMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
  messages: {
    success: "Updated successfully",
    error: "Unable to update data",
  },
};

const operationURLS = {
  list: "/configuration/list",
  update: "/configuration/update",
  register: "/configuration/register",
  delete: "/configuration/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Node Name",
      accessor: "nodeName",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      width: 6,
      displayInDeleteConfirmation: true,
      dataType: "string",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").nullable(),
    },
    {
      Header: "Host IP",
      accessor: "hostIP0",
      displayInList: true,
      allowFilter: true,
      allowInForm: true,
      dataType: "string",
      width: 6,
      controlType: "ip",
      Cell: ({ value }) => <DefaultCell value={value} />,
      validation: formControl.string().required("Required").ipv4().nullable(),
    },
    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
