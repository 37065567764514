import CTLUserStatusCell from "application/components/CTLUserStatusCell";
import DefaultCell from "application/components/DefaultCell";
import RowAction from "application/components/RowAction";
import { adminMenus } from "globals/configuration/admin";

const Attributes = {
  formSize: "md",
  enableAddButton: true,
  modes: adminMenus.map((item) => {
    return {
      id: item.key,
      name: item.name,
      iconName: item.icon,
      url: item.url,
      description: item.description,
      disabled: item.disabled,
      productionReady: item.productionReady,
    };
  }),
};

const operationURLS = {
  list: "userStatusMaster/list",
  update: "userStatusMaster/update",
  create: "userStatusMaster/create",
  delete: "userStatusMaster/delete",
};

const getColumns = (onDelete, onEdit) => {
  return [
    {
      Header: "Create On",
      accessor: "createdOn",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      displayInList: true,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Updated On",
      accessor: "updatedOn",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "updated By",
      accessor: "updatedBy",
      displayInList: false,
      allowInForm: false,
      allowFilter: false,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Role",
      accessor: "roleId",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",
      controlType: "select",
      Cell: ({ value }) => <CTLUserStatusCell value={value} />,
      groupHeading: "Role Id",
      options: {
        keyField: "id",
        keyDescription: "description",
        values: [],
        url: "role/dropdownList",
      },
    },
    {
      Header: "User Status",
      accessor: "description",
      displayInList: true,
      allowInForm: true,
      allowFilter: true,
      dataType: "string",

      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "Action",
      accessor: "id",
      displayInList: true,
      allowInForm: false,
      Cell: ({ row, value }) => (
        <RowAction
          row={row}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ),
    },
  ];
};

export { getColumns, Attributes, operationURLS };
