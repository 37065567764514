import MDBox from "components/MDBox";

import React, { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import MDSnackbar from "components/MDSnackbar";
import loggerFactory from "globals/logger/logger-factory";

import { getColumns, Attributes, operationURLS } from "./model";

import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";

function Timer({ onLoad, pageName }) {
  const componentName = "pbx.configuration.settings.timer";
  const [formData, setFormData] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  function onSaveForm(data) {
    loggerFactory.debug(componentName, "Data to update", data);
    application
      .post(operationURLS.update, data)
      .then((response) => {
         setNotify({
          isOpen: true,
          message: response.data.message,
          type: "success",
          pagename: pageName,
          status: response.data.status,
        });
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          loggerFactory.debug(
            componentName,
            "Unable to save the user profile data.",
            error
          );
        }
        setNotify({
          isOpen: true,
          status: error.response.status,
          message: response.data.message,
          type: "error",
          pagename: pageName,
        });
      });
  }

  useEffect(() => {
    Attributes && onLoad(Attributes);
    application.post(operationURLS.list).then((response) => {
      let dataToDisplay = response.data.data[0];
      loggerFactory.debug(componentName, "Data Received", dataToDisplay);
      setFormData(dataToDisplay);
    });
  }, []);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.error}`}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={`${pageName}`}
      content={`${pageName} ${Attributes.messages.success}`}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  return (
    <MDBox>
      <CTLBaseForm
        enableActionButtons={true}
        entityName="Nodes"
        columns={getColumns(null, null)}
        openForm={true}
        onSaveForm={onSaveForm}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
      />
       <CTLNotification notify={notify} setNotify={setNotify} />
      {renderSuccessSB}
      {renderErrorSB}
    </MDBox>
  );
}

export default Timer;
