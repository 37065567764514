import Dashboard from "layouts/nms/dashboard";
import React, { useEffect, useState, useRef } from "react";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { telemetry } from "globals/endpoints";
import loggerFactory from "globals/logger/logger-factory";

const dashboardController = ({ id, onLoad }) => {
  const componentName = "Dashboard Controller";
  const [list, setList] = useState([]);
  const onLoadRef = useRef();
  const [dashboardId, setDashboardId] = useState(id);

  function dashboardList() {
    telemetry
      .get("dashboard/list")
      .then((response) => {
        setList(response.data.data.dashboards);
      })
      .catch((error) => {
        loggerFactory.error(
          componentName,
          "Unable to fetch Dashboard list",
          error
        );
      });
  }

  useEffect(() => {
    dashboardList();
  }, []);

  useEffect(() => {
    onLoadRef.current = onLoad;
  }, [onLoad]);

  useEffect(() => {
    displayDashboard(id);
  }, [id]);

  useEffect(() => {
    if (list.length > 0 && onLoadRef.current) {
      const Attributes = {
        enableCollapsibleMenu: true,
        modes: [],
      };
      onLoadRef.current(Attributes);
      displayDashboard(id);
    }
    if (AuthenticationService.allowDashboardConfigMenus()) {
      const Attributes = {
        enableCollapsibleMenu: true,
        enableApiAccess: true,
        modes: [],
      };
      onLoadRef.current(Attributes);
    }
    if (AuthenticationService.allowApps()) {
      const Attributes = { 
        enableExternalApp: true,
        enableCollapsibleMenu: true,
        modes: [],
      };
      onLoadRef.current(Attributes);
    }
  }, [list]);

  const allDashboardId = list.map((item) => item.widgetId);
  const LastViewedDashboardId = AuthenticationService.getLastViewDashboardId();

  const displayDashboard = (id) => {
    if (allDashboardId.length > 0) {
      if (id && allDashboardId.includes(id)) {
        setDashboardId(id);
        AuthenticationService.setLastViewDashboardId(id);
      } else {
        if (
          LastViewedDashboardId &&
          allDashboardId.includes(LastViewedDashboardId)
        ) {
          setDashboardId(LastViewedDashboardId);
        } else if (allDashboardId.length > 0) {
          setDashboardId(allDashboardId[0]);
          AuthenticationService.setLastViewDashboardId(allDashboardId[0]);
        } else {
          setDashboardId(null);
          AuthenticationService.setLastViewDashboardId(null);
        }
      }
    }
  };

  return (
    <>
      {dashboardId && (
        <Dashboard
          id={dashboardId}
          isAdmin={
            AuthenticationService.allowAdminMenus() ||
            AuthenticationService.allownmsAdminMenus() ||
            AuthenticationService.allowDashboardConfigMenus()
          }
          userName={AuthenticationService.getUserName()}
          dashboardType={"dashboard"}
        ></Dashboard>
      )}
    </>
  );
};

export default dashboardController;
