import { useEffect, useRef, useState } from "react";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import calculateSmartAssetMarkerPosition from "layouts/nms/utils/calculateSmartAssetMarkerPosition";
import FolderIcon from "@mui/icons-material/Folder";
import CircleIcon from "@mui/icons-material/Circle";
import { Card, Grid, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import FaxIcon from "@mui/icons-material/Fax";  


function AssetImage({
  isAdmin,
  isEditMode,
  bufferLeft,
  bufferTop,
  handleAssignSensorPopupOpen,
  handleParentRefCurrent,
  assetSensorListQuery,
  assetIpAddress,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuEvent, setContextMenuEvent] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const parent = document.getElementById("smartAssetParent");
    handleParentRefCurrent(parent);
  }, []);

  const handleImageClick = (event) => {
    if (!imageRef.current) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateSmartAssetMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    handleAssignSensorPopupOpen(left, top);

    setContextMenu(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
    setContextMenuEvent(event);
  };

  const handleClick = () => {
    const popupFeatures = "fullscreen=yes,resizable=yes,scrollbars=yes";
    window.open(`http://${assetIpAddress}`, "_blank", popupFeatures);
  };

  return (
    <MDBox onContextMenu={handleContextMenu} sx={{ cursor: "context-menu" }}>
      <div>
       {generateMarkerElements()}
      </div>
      {isAdmin && isEditMode && (
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={() => handleImageClick(contextMenuEvent)}>
            Assign sensor
          </MenuItem>
        </Menu>
      )}
    </MDBox>
  );

  function generateMarkerElements() {
    const data = assetSensorListQuery?.data;

    return data?.map((item, index) => {
      
      let color;
      switch (item.sensorStatus) {
        case 0:
          color = "green";
          break;
        case 1:
          color = "yellow";
          break;
        case 2:
          color = "red";
          break;
        default:
          color = "gray";
      }

      if (item.sensorCode == "ICMP") {
        return (
          <>
            <MDBox
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "60vh",
              }}
            >
              <MDTypography>Phone Configuration</MDTypography>
              <IconButton onClick={handleClick}>
                <FaxIcon
                  style={{
                    color: color,
                    height: "150px",
                    width: "150px",
                  }}
                ></FaxIcon>
              </IconButton>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card
                  style={{
                    alignItems: "center",
                    padding: "15px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 1.3)",
                  }}
                >
                  <MDTypography
                    style={{ cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    {" "}
                    <div
                      style={{
                        position: "absolute",
                        marginTop: "8px",
                        left: "46%",
                        color: color,
                        fontSize: "24px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          transform: "translateZ(24px)",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        <CircleIcon />
                      </div>
                    </div>
                    {item.sensorType}
                  </MDTypography>
                </Card>
              </Grid>
            </Grid>
          </>
        );
      }
    });
  }
}

export default AssetImage;
