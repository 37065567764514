import React from "react";
import MDTypography from "components/MDTypography";

function CTLSelectCell({ value }) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value && (
        <MDTypography variant="caption" fontWeight="medium" color="#FFFFFFCC">
          &nbsp;&nbsp;{value.description}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLSelectCell.defaultProps = {
  value: null,
};

export default CTLSelectCell;