import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "features/Cards/StatisticsCards/ComplexStatisticsCard";
import { nmsMenus } from "globals/configuration/nms";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import UCNotificationItem from "application/components/UCNotificationItem";
import { useMaterialUIController, setActivePage, setSearchText } from "context";
import { hmsAdminMenus } from "globals/configuration/hmsAdmin";
import { ivrMenus } from "globals/configuration/ivr/menu";
import { adminMenus } from "globals/configuration/admin";
import { nodeMenus } from "globals/configuration/nodes";
import Menu from "@mui/material/Menu";
import { surakshaMenu } from "globals/configuration/suraksha";
import { callCenterMenus } from "globals/configuration/call-center/menu";
import { ucNotificationMenus } from "globals/configuration/ucNotification";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { application } from "globals/endpoints";
import { nodeConfigurationMenus } from "globals/configuration/callServer";
import { voicePickerMenus } from "globals/configuration/voice-Picker";
import CTLIfram from "../CTLifram";
import { kanAdminMenus } from "globals/configuration/kanAdminMenu";
import { userDefinedWidgetMenus } from "globals/configuration/nmsDashboard";
import { reportMenus } from "globals/configuration/reports";
import { userMenus } from "globals/configuration/user-management";
import { DashboardConfigMenu } from "globals/configuration/DashboardConfigMenu";
import WidgetConfigMenu from "globals/configuration/WidgetConfigMenu";
import { cdrs } from "globals/configuration/cdrs";
import { viewerMenus } from "globals/configuration/reports/viewerMenus";
import { CallCenterConfigurationsMenu } from "globals/configuration/callCenterConfigurationMenu";
import { extensionMenus } from "globals/configuration/extension";
import DirectCallCenterMenu from "globals/configuration/DirectCallcenterPage";
import paMenu from "globals/configuration/paMenu";

const Attributes = {
  enableAddButton: false,
  enableApiAccess: AuthenticationService.allowDashboardConfigMenus()
    ? true
    : false,
  modes: [],
};

function CTLMenuDashboard({ onLoad }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [openExtensionMenu, setOpenExtensionMenu] = useState(false);
  const [openGroupMenu, setOpenGroupMenu] = useState(false);
  const [openNmsMenu, setOpenNmsMenu] = useState(false);
  const [openNmsViewerMenu, setOpenNmsViewerMenu] = useState(false);
  const [openDashboardConfigMenu, setOpenDashboardConfigMenu] = useState(false);
  const [openWidgetConfigMenu, setOpenWidgetConfigMenu] = useState(false);
  const [openReportsMenu, setOpenReportsMenu] = useState(false);
  const [openCallCenterMenu, setOpenCallCenterMenu] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openCDRSMenu, setOpenCDRSMenu] = useState(false);
  const [openUserDefinedWidgetMenu, setOpenUserDefinedWidgetMenu] =
    useState(false);
  const [counts, setCounts] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [selectedGroupItem, setSelectedGroupItem] = useState(null);
  const [pathUrl, setPathUrl] = useState("");
  const [openIfram, setOpenIfram] = useState(false);

  const [MenuDynamic, setGroupMenu] = useState([]);

  useEffect(() => setGroupMenu(AuthenticationService.getGroupMenus()), []);

  const filteredCallCenterConfigurationsMenu =
    CallCenterConfigurationsMenu.filter((menu) =>
      MenuDynamic?.find(
        (MenuDynamic) =>
          MenuDynamic.name === "Call Center" &&
          MenuDynamic.menus.some(
            (item) =>
              item.menuName === menu.name &&
              (item.product === "main" || item.product === "both")
          )
      )
    );

  const CallCenterConfigurationsMenus =
    filteredCallCenterConfigurationsMenu?.length == 0
      ? CallCenterConfigurationsMenu.map((menu) => ({
          icon: menu.icon,
          key: menu.key,
          name: menu.name,
          url: menu.url,
          description: menu.description,
          productionReady: menu.productionReady,
        }))
      : filteredCallCenterConfigurationsMenu.map((menu) => ({
          icon: menu.icon,
          key: menu.key,
          name: menu.name,
          url: menu.url,
          description: menu.description,
          productionReady: menu.productionReady,
        }));

  CallCenterConfigurationsMenus.sort((a, b) => {
    const callCenterMenu = MenuDynamic.find(
      (menu) => menu.name === "Call Center"
    );
    const sequenceA =
      callCenterMenu?.menus.find((item) => item.menuName === a.name)
        ?.sequence || 0;
    const sequenceB =
      callCenterMenu?.menus.find((item) => item.menuName === b.name)
        ?.sequence || 0;
    return sequenceA - sequenceB;
  });

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenExtensionMenu = (event) =>
    setOpenExtensionMenu(event.currentTarget);
  const handleCloseExtensionMenu = () => setOpenExtensionMenu(false);
  const handleOpenGroupMenu = (event, group) => {
    setOpenGroupMenu(event.currentTarget);
    setSelectedGroupItem(group);
  };

  const handleCloseGroupMenu = () => {
    setOpenGroupMenu(null);
    setSelectedGroupItem(null);
  };

  function handleClose() {
    setOpenIfram(false);
  }
  const handleOpenDashboardConfigMenu = (event) =>
    setOpenDashboardConfigMenu(event.currentTarget);
  const handleCloseDashboardConfigMenu = () =>
    setOpenDashboardConfigMenu(false);

  const handleOpenWidgetConfigMenu = (event) =>
    setOpenWidgetConfigMenu(event.currentTarget);
  const handleCloseWidgetConfigMenu = () => setOpenWidgetConfigMenu(false);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenNmsMenu = (event) => setOpenNmsMenu(event.currentTarget);
  const handleCloseNmsMenu = () => setOpenNmsMenu(false);
  const handleOpenNmsViewerMenu = (event) =>
    setOpenNmsViewerMenu(event.currentTarget);
  const handleCloseNmsViewerMenu = () => setOpenNmsViewerMenu(false);
  const handleOpenReportMenu = (event) =>
    setOpenReportsMenu(event.currentTarget);
  const handleCloseReportMenu = () => setOpenReportsMenu(false);
  const handleOpenCallCenterMenu = (event) =>
    setOpenCallCenterMenu(event.currentTarget);
  const handleCloseCallCenterMenu = () => setOpenCallCenterMenu(false);

  const handleOpenUserMenu = (event) => setOpenUserMenu(event.currentTarget);
  const handleCloseUserMenu = () => setOpenUserMenu(false);

  const handleOpenCDRSMenu = (event) => setOpenCDRSMenu(event.currentTarget);
  const handleCloseCDRSMenu = () => setOpenCDRSMenu(false);

  const handleopenUserDefinedWidgetMenu = (event) =>
    setOpenUserDefinedWidgetMenu(event.currentTarget);
  const handleCloseNmsdashboardMenu = () => setOpenUserDefinedWidgetMenu(false);
  const [controller, dispatch] = useMaterialUIController();
  const { activePage } = controller;

  const MenuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setOpenMenu(false);
    setSearchText(dispatch, "");
  };

  let enableSystemMenu = false;
  let enableHMSmenu = false;
  let enableNMSmenu = false;
  let enablesurakshaMenu = false;
  let enableIvrmenu = false;
  let enablecallCenterMenus = false;
  let enablecallCenterConfigurationsMenus = false;
  let enablevoicePickerMenus = false;
  let enablenodeMenus = false;
  let enableUcMenus = false;
  let enableKanAdminMenu = false;
  let enableDashboardConfigMenu = false;
  let enableWidgetConfigMenu = false;
  let enableCdrsMenu = false;
  let enableReportMenu = false;
  let enableViewerMenu = false;
  let enableDirectCallCenter = false;
  let enablepaMenu= false;

  adminMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowAdminMenus()
    ) {
      enableSystemMenu = true;
    }
  });

  viewerMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allownmsViewerMenus()
    ) {
      enableViewerMenu = true;
    }
  });

  reportMenus.map((menu) => {
    if (
      (!menu.disabled &&
        (menu.productionReady ||
          window.demoMode ||
          process.env.REACT_APP_MODE !== "ui-production") &&
        AuthenticationService.allowAdminMenus()) ||
      AuthenticationService.allownmsAdminMenus()
    ) {
      enableReportMenu = true;
    }
  });

  hmsAdminMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowhmsAdminMenus()
    ) {
      enableHMSmenu = true;
    }
  });
  nmsMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allownmsAdminMenus()
    ) {
      enableNMSmenu = true;
    }
  });
  // For Dashboard Configuration
  DashboardConfigMenu.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowDashboardConfigMenus()
    ) {
      enableDashboardConfigMenu = true;
    }
  });
  // For Widget Configuration
  WidgetConfigMenu.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowDashboardConfigMenus()
    ) {
      enableWidgetConfigMenu = true;
    }
  });
  kanAdminMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowkanAdminMenus()
    ) {
      enableKanAdminMenu = true;
    }
  });
  surakshaMenu.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowsurakshaMenus()
    ) {
      enablesurakshaMenu = true;
    }
  });
  ivrMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowAdminMenus()
    ) {
      enableIvrmenu = true;
    }
  });

  cdrs.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowAdminMenus()
    ) {
      enableCdrsMenu = true;
    }
  });

  callCenterMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowAdminMenus()
    ) {
      enablecallCenterMenus = true;
    }
  });

  /// Direct only CallCenter Access
  DirectCallCenterMenu.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowCallcenterTabMenus()
    ) {
      enableDirectCallCenter = true;
    }
  });
    /// Direct only CallCenter Access
    paMenu.map((menu) => {
      if (
        !menu.disabled &&
        (menu.productionReady ||
          window.demoMode ||
          process.env.REACT_APP_MODE !== "ui-production") &&
        AuthenticationService.allowPAMenu()
      ) {
        enablepaMenu = true;
      }
    });

  CallCenterConfigurationsMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowAdminMenus()
    ) {
      enablecallCenterConfigurationsMenus = true;
    }
  });

  voicePickerMenus.map((menu) => {
    if (
      (!menu.disabled &&
        (menu.productionReady ||
          window.demoMode ||
          process.env.REACT_APP_MODE !== "ui-production") &&
        AuthenticationService.allowVoicePickerMenus()) ||
      AuthenticationService.allowAdminMenus()
    ) {
      enablevoicePickerMenus = true;
    }
  });

  nodeMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowAdminMenus()
    ) {
      enablenodeMenus = true;
    }
  });

  ucNotificationMenus.map((menu) => {
    if (
      !menu.disabled &&
      (menu.productionReady ||
        window.demoMode ||
        process.env.REACT_APP_MODE !== "ui-production") &&
      AuthenticationService.allowUCMenus()
    ) {
      enableUcMenus = true;
    }
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
  }, []);

  //Admin Menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: -7 }}
    >
      {AuthenticationService.allowAdminMenus() &&
        adminMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );
  //Extension Menu
  const renderExtensionMenu = () => (
    <Menu
      anchorEl={openExtensionMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      open={Boolean(openExtensionMenu)}
      onClose={handleCloseExtensionMenu}
      sx={{ mt: -7 }}
    >
      {AuthenticationService.allowAdminMenus() &&
        extensionMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );
  // user defined widgetMenus
  const renderuserDefinedWidgetMenus = () => (
    <Menu
      anchorEl={openUserDefinedWidgetMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      sx={{ mt: -1 }}
      open={Boolean(openUserDefinedWidgetMenu)}
      onClose={handleCloseNmsdashboardMenu}
    >
      {AuthenticationService.allowAdminMenus() &&
        userDefinedWidgetMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  const renderNmsMenu = () => (
    <Menu
      anchorEl={openNmsMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openNmsMenu)}
      onClose={handleCloseNmsMenu}
    >
      {AuthenticationService.allownmsMenus() &&
        nmsMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link
                  key={menu.key}
                  onClick={
                    menu.submenu
                      ? handleopenUserDefinedWidgetMenu
                      : () => MenuClicked(menu)
                  }
                  to="#"
                >
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  //for nms viewer
  const renderNmsViewerMenu = () => (
    <Menu
      anchorEl={openNmsViewerMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      open={Boolean(openNmsViewerMenu)}
      onClose={handleCloseNmsViewerMenu}
      sx={{ mt: -7 }}
    >
      {AuthenticationService.allownmsViewerMenus() &&
        viewerMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  // For Dashboard Configuration
  const renderDashboardConfigMenu = () => (
    <Menu
      anchorEl={openDashboardConfigMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openDashboardConfigMenu)}
      onClose={handleCloseDashboardConfigMenu}
    >
      {AuthenticationService.allowDashboardConfigMenus() &&
        DashboardConfigMenu.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link
                  key={menu.key}
                  onClick={
                    menu.submenu
                      ? handleopenUserDefinedWidgetMenu
                      : () => MenuClicked(menu)
                  }
                  to="#"
                >
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );
  // For Widget Configuration
  const renderWidgetConfigMenu = () => (
    <Menu
      anchorEl={openWidgetConfigMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openWidgetConfigMenu)}
      onClose={handleCloseWidgetConfigMenu}
    >
      {AuthenticationService.allowDashboardConfigMenus() &&
        WidgetConfigMenu.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link
                  key={menu.key}
                  onClick={
                    menu.submenu
                      ? handleopenUserDefinedWidgetMenu
                      : () => MenuClicked(menu)
                  }
                  to="#"
                >
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );
  const renderReportMenu = () => (
    <Menu
      anchorEl={openReportsMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openReportsMenu)}
      onClose={handleCloseReportMenu}
    >
      {AuthenticationService.allowAdminMenus() &&
        reportMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  //CallCenter Menu

  const renderCallCenterMenu = () => (
    <Menu
      anchorEl={openCallCenterMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openCallCenterMenu)}
      onClose={handleCloseCallCenterMenu}
    >
      {AuthenticationService.allowAdminMenus() &&
        CallCenterConfigurationsMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  //userManagement menus

  const renderuserManagementMenus = () => (
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openUserMenu)}
      onClose={handleCloseUserMenu}
    >
      {AuthenticationService.allowAdminMenus() &&
        userMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  const renderCDRSMenus = () => (
    <Menu
      anchorEl={openCDRSMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openCDRSMenu)}
      onClose={handleCloseCDRSMenu}
    >
      {AuthenticationService.allowAdminMenus() &&
        cdrs.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  // kanAdminMenu
  const renderKanAdminMenu = () => (
    <Menu
      anchorEl={openNmsMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{ mt: -7 }}
      open={Boolean(openNmsMenu)}
      onClose={handleCloseNmsMenu}
    >
      {AuthenticationService.allowkanAdminMenus() &&
        kanAdminMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link key={menu.key} onClick={() => MenuClicked(menu)} to="#">
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  //UcMenus Notifications
  const fetchNotifications = async () => {
    try {
      const response = await application.post("/uc/countNotification/list");
      const notifications = response.data.data;
      setCounts(notifications);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };

  useEffect(() => {
    const startFetching = () => {
      if (AuthenticationService.allowUCMenus()) {
        fetchNotifications();
        const id = setInterval(fetchNotifications, 2000);
        setIntervalId(id);
      }
    };
    if (AuthenticationService.allowUCMenus()) {
      startFetching();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const getMenuCountByMessageType = (messageType) => {
    const notification = counts.find(
      (notification) => notification.messageType === messageType
    );
    return notification ? notification.count : 0;
  };

  //menuGroup

  async function handleOpenManager(urlPath) {
    let urlScheme = window.location.protocol;
    let isDomain = window.location.hostname.toLowerCase().match(/[a-z]/i);
    let host = window.location.host;

    application
      .post("/userToken/create", { createdBy: "API" })
      .then((response) => {
        let urlParts = urlPath.split("/");
        let application = urlParts[0];
        urlParts.shift();
        urlPath = urlParts.join("/");
        let managerURL = `${urlScheme}//${host}/${application}/access?authKey=${response.data.data}&appURL=${urlPath}`;
        console.log(managerURL);
        if (isDomain) {
          setPathUrl(managerURL);
          setOpenIfram(true);
        } else {
          const popupFeatures = "fullscreen=yes resizable=yes,scrollbars=yes";
          window.open(managerURL, ` - ${urlPath}`, popupFeatures);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  const groupMenu = AuthenticationService.getGroupMenus();

  const renderMenuGroup = () => (
    <Menu
      anchorEl={openGroupMenu}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      open={Boolean(openGroupMenu)}
      onClose={handleCloseGroupMenu}
      sx={{ mt: -7 }}
    >
      {selectedGroupItem && (
        <div key={selectedGroupItem.menuGroup}>
          {selectedGroupItem.menus.map((menu) => (
            <UCNotificationItem
              key={menu.menuId}
              onClick={() => handleOpenManager(menu.urlPath)}
              icon={<Icon>{menu.icon}</Icon>}
              title={menu.menuName}
            />
          ))}
        </div>
      )}
    </Menu>
  );

  return (
    <MDBox>
      <Grid container spacing={3}>
        {enableSystemMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              onClick={handleOpenMenu}
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                badge={undefined}
                color="secondary"
                icon="supervisor_account"
                title="System Settings"
                menuDescription="Use this to configure and monitor system settings"
              />
            </MDBox>
          </Grid>
        )}
        {enableNMSmenu && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox
                mb={1.5}
                onClick={handleOpenNmsMenu}
                style={{ cursor: "pointer" }}
              >
                <ComplexStatisticsCard
                  color="info"
                  icon="network_check"
                  title="NMS"
                  menuDescription="Use this to configure and monitor your devices"
                />
              </MDBox>
            </Grid>
          </>
        )}

        {enableDashboardConfigMenu && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox
                mb={1.5}
                // onClick={handleOpenDashboardConfigMenu}
                onClick={() => MenuClicked(DashboardConfigMenu[0])}
                style={{ cursor: "pointer" }}
              >
                <ComplexStatisticsCard
                  color="success"
                  icon="perm_data_setting"
                  title="Dashboard-Configuration"
                  menuDescription="Use this to configure and monitor your Dashboard-Configuration "
                />
              </MDBox>
            </Grid>
          </>
        )}

        {enableWidgetConfigMenu && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox
                mb={1.5}
                // onClick={handleOpenWidgetConfigMenu}
                onClick={() => MenuClicked(WidgetConfigMenu[0])}
                style={{ cursor: "pointer" }}
              >
                <ComplexStatisticsCard
                  color="warning"
                  icon="confirmation_number"
                  title="Widget-Configuration"
                  menuDescription="Use this to configure and monitor your Widget-Configuration"
                />
              </MDBox>
            </Grid>
          </>
        )}

        {enableSystemMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={nodeConfigurationMenus[0].key}
              onClick={() => MenuClicked(nodeConfigurationMenus[0])}
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="secondary"
                icon="network_check"
                title="Node Configuration"
                menuDescription="Use this to configure and monitor your call server"
              />
            </MDBox>
          </Grid>
        )}

        {enableKanAdminMenu && (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox
                mb={1.5}
                onClick={handleOpenNmsMenu}
                style={{ cursor: "pointer" }}
              >
                <ComplexStatisticsCard
                  color="info"
                  icon="network_check"
                  title="NMS"
                  menuDescription="Use this to configure and monitor your devices"
                />
              </MDBox>
            </Grid>
          </>
        )}

        {enablenodeMenus && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={nodeMenus[0].key}
              onClick={() => MenuClicked(nodeMenus[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="info"
                icon="workspaces"
                title="Cluster Configuration"
                menuDescription="Use This to configure your Single Node 
deployment, Active passive & multi RLU"
              />
            </MDBox>
          </Grid>
        )}
        {enablesurakshaMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={surakshaMenu[0].key}
              onClick={() => MenuClicked(surakshaMenu[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="success"
                icon="map"
                title="Suraksha"
                menuDescription="Use This Map to Monitor Zones & Broadcast messages "
              />
            </MDBox>
          </Grid>
        )}
        {enableHMSmenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={hmsAdminMenus[0].key}
              onClick={() => MenuClicked(hmsAdminMenus[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="primary"
                icon="hotel"
                title="Hotel"
                menuDescription="Use this to configure and monitor Pms functionality"
              />
            </MDBox>
          </Grid>
        )}
        {enableIvrmenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={ivrMenus[0].key}
              onClick={() => MenuClicked(ivrMenus[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="warning"
                icon="settings_voice"
                title="Auto Attendant"
                menuDescription="Use this to configure IVR (Interactive voice response)"
              />
            </MDBox>
          </Grid>
        )}
        {enablecallCenterMenus && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={callCenterMenus[0].key}
              onClick={() => MenuClicked(callCenterMenus[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="error"
                icon="call"
                title="Call Center"
                menuDescription="Use this to configure and monitor Call center functionality"
              />
            </MDBox>
          </Grid>
        )}

        {enableDirectCallCenter && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={DirectCallCenterMenu[0].key}
              onClick={() => MenuClicked(DirectCallCenterMenu[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="error"
                icon="call"
                title="Call Center Configuration"
                menuDescription="Use this to configure and monitor Call center functionality"
              />
            </MDBox>
          </Grid>
        )}
         {enablepaMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={paMenu[0].key}
              onClick={() => MenuClicked(paMenu[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="error"
                icon="report"
                title="Report"
                menuDescription="Use this to Read the report"
              />
            </MDBox>
          </Grid>
        )}
        {enablecallCenterConfigurationsMenus && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              onClick={handleOpenCallCenterMenu}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="info"
                icon="connect_without_contact"
                title="Call Center Configurations"
                menuDescription="Use this to configurations for all call Center Setting Configuration"
              />
            </MDBox>
          </Grid>
        )}

        {enablevoicePickerMenus && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={voicePickerMenus[0].key}
              onClick={() => MenuClicked(voicePickerMenus[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="success"
                icon="keyboard_voice"
                title="Voice Picker"
                menuDescription="Use this to configure and monitor Voice Picker  functionality"
              />
            </MDBox>
          </Grid>
        )}
        {enableUcMenus &&
          ucNotificationMenus.map((menu) => (
            <Grid item xs={12} md={6} lg={3} key={menu.key}>
              <MDBox
                mb={1.5}
                onClick={() => MenuClicked(menu)}
                to="#"
                style={{ cursor: "pointer" }}
              >
                <ComplexStatisticsCard
                  badge={
                    getMenuCountByMessageType(menu.messageType) > 0
                      ? getMenuCountByMessageType(menu.messageType)
                      : undefined
                  }
                  color={menu.color}
                  icon={menu.icon}
                  title={menu.name}
                  menuDescription={
                    menu.messageType
                      ? `You have${
                          getMenuCountByMessageType(menu.messageType) > 0
                            ? ` ${getMenuCountByMessageType(
                                menu.messageType
                              )} new`
                            : " 0"
                        } ${menu.name} notification${
                          getMenuCountByMessageType(menu.messageType) !== 0
                            ? "s"
                            : ""
                        }`
                      : ""
                  }
                />
              </MDBox>
            </Grid>
          ))}
        {enablesurakshaMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              key={surakshaMenu[0].key}
              onClick={() => MenuClicked(surakshaMenu[0])}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="success"
                icon="map"
                title="Suraksha"
                menuDescription="Use This Map to Monitor Zones & Broadcast messages "
              />
            </MDBox>
          </Grid>
        )}
        {enableReportMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              style={{ cursor: "pointer" }}
              onClick={handleOpenReportMenu}
            >
              <ComplexStatisticsCard
                color="primary"
                icon="reports"
                title="Reports"
                menuDescription="Use this to monitor your billings and reports"
              />
            </MDBox>
          </Grid>
        )}

        {enableViewerMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              style={{ cursor: "pointer" }}
              onClick={handleOpenNmsViewerMenu}
            >
              <ComplexStatisticsCard
                color="primary"
                icon="reports"
                title="Reports"
                menuDescription="Use this to monitor your billings and reports"
              />
            </MDBox>
          </Grid>
        )}
        {enableSystemMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              style={{ cursor: "pointer" }}
              onClick={handleOpenUserMenu}
            >
              <ComplexStatisticsCard
                color="info"
                icon="manage_accounts"
                title="User Management"
                menuDescription="Use this to configure user management"
              />
            </MDBox>
          </Grid>
        )}
        {enableSystemMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              onClick={handleOpenExtensionMenu}
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="success"
                icon="extension_icon"
                title="Extension"
                menuDescription="Use this to configure and monitor Extension pages"
              />
            </MDBox>
          </Grid>
        )}
        {enableSystemMenu &&
          groupMenu.map((group) => (
            <Grid item xs={12} md={6} lg={3} key={group.menuGroup}>
              <MDBox
                mb={1.5}
                onClick={(event) => handleOpenGroupMenu(event, group)}
                style={{ cursor: "pointer" }}
              >
                <ComplexStatisticsCard
                  color="info"
                  icon={group.icon}
                  title={group.name}
                  menuDescription={group.description}
                />
              </MDBox>
            </Grid>
          ))}

        {enableCdrsMenu && (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox
              mb={1.5}
              onClick={handleOpenCDRSMenu}
              to="#"
              style={{ cursor: "pointer" }}
            >
              <ComplexStatisticsCard
                color="warning"
                icon="graphic_eq_icon"
                title="CDRS"
                menuDescription="Use this to configure CDRS"
              />
            </MDBox>
          </Grid>
        )}

        {AuthenticationService.allowAdminMenus() && (
          <>
            {renderMenuGroup()}
            {renderuserManagementMenus()}
            {renderCDRSMenus()}
            {renderuserDefinedWidgetMenus()}
            {renderWidgetConfigMenu()}
            {renderCallCenterMenu()}
            {renderExtensionMenu()}
          </>
        )}
        {AuthenticationService.allowAdminMenus() && <>{renderMenuGroup()}</>}
        {AuthenticationService.allownmsViewerMenus() && (
          <>{renderNmsViewerMenu()}</>
        )}
        {AuthenticationService.allowAdminMenus() && <>{renderMenu()}</>}
        {AuthenticationService.allowAdminMenus() && <>{renderReportMenu()}</>}
        {AuthenticationService.allownmsAdminMenus() && <>{renderNmsMenu()}</>}
        {AuthenticationService.allowDashboardConfigMenus() && (
          <>{renderDashboardConfigMenu()}</>
        )}
        {AuthenticationService.allowDashboardConfigMenus() && (
          <>{renderWidgetConfigMenu()}</>
        )}
        {AuthenticationService.allowkanAdminMenus() && (
          <>{renderKanAdminMenu()}</>
        )}
      </Grid>
      <CTLIfram
        handleClose={handleClose}
        openIfram={openIfram}
        entityName="Menu Dashboard"
        entityDescription={" "}
        url={pathUrl}
      />
    </MDBox>
  );
}

export default CTLMenuDashboard;
