import React, { useEffect, useState, useRef } from "react";
import { Fab, IconButton, CircularProgress } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CTLNotification from "../CTLNotification";
import { application } from "globals/endpoints";

function CTLSelectFile({ getValues, formik, column }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });
  useEffect(() => {
    setSelectedFile(getValues(column.accessor));
  }, [getValues, column]);

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      formik.handleChange({
        target: { name: `field_${column.accessor}`, value: newFile.name },
      });
      setSelectedFile(newFile.name);
      setFile(newFile);
       e.target.value = "";
    } else {
      setSelectedFile(null);
    }
  };

  const handleFileRemove = () => {
    formik.handleChange({
      target: { name: `field_${column.accessor}`, value: "" },
    });
    setSelectedFile(null);
  };

  const handleFileUpload = () => {
    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "default");
      application
        .post(`${column.uploadUrl}/${selectedFile}`, formData)
        .then((response) => {
          setNotify({
            isOpen: true,
            status: response.data.message,
            type: "success",
            pagename: "",
            message: response.data.status,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status: error.response.data.message,
            type: "error",
            pagename: "",
            message: error.response.data.status,
          });
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const handleAddFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <>
        <input
          ref={fileInputRef}
          type="file"
          accept={column.fileTypeAccept}
          name={`field_${column.accessor}`}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {!selectedFile && (
          <Fab
            color="info"
            size="medium"
            component="span"
            aria-label="add"
            variant="extended"
            onClick={handleAddFileClick}
          >
            + Add {column.Header}
          </Fab>
        )}
        {selectedFile && (
          <>
            <MDTypography variant="h6">
              {selectedFile} &nbsp;
              <tooltip title="Remove file">
                <IconButton
                  color="error"
                  size="small"
                  onClick={handleFileRemove}
                >
                  <ClearIcon />
                </IconButton>
              </tooltip>
              {uploading ? (
                <>
                  &nbsp; &nbsp; <CircularProgress color="info" size={24} />
                  &nbsp; file uploading ...
                </>
              ) : (
                <MDButton color="info" size="small" onClick={handleFileUpload}>
                  Upload File
                </MDButton>
              )}
            </MDTypography>
          </>
        )}
        <CTLNotification notify={notify} setNotify={setNotify} />
      </>
    </form>
  );
}

export default CTLSelectFile;
