import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useMaterialUIController, setQueueValue } from "context";
import CTLNotification from "application/components/CTLNotification";
import { application } from "globals/endpoints";
import CTLBaseDialogForm from "application/components/CTLBaseDialogForm";
import { Stack } from "@mui/material";
import { getColumns, Attributes, operationURLS } from "./model";
import {
  generalQueueUpdateColumns,
  generalQueueAttribute,
  generalQueueOperationURLS,
} from "./generalQueueUpdate";
import CTLBaseForm from "application/components/CTLBaseForm";

function GeneralQueue({ onLoad, registerCallBacks, pageName }) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, queueValue, ivrExtension } = controller;
  const [selectedRow, setSelectedRow] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openAgentForm, setAgentOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    generalQueueAttribute && onLoad(generalQueueAttribute);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
        onAgent: onOpenAgentForm,
      });
  }, [activePage]);

  function onOpenAgentForm() {
    setAgentOpenForm(true);
  }

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }
  const deleteRow = (row) => {
    setSelectedRow(row);
    setConfirmDelete(true);
  };

  const editRow = (row) => {
    setFormData(row);
    setOpenForm(true);
  };

  //for queue
  function queueOnSaveForm(data) {
    if (data.id) {
      application
        .post(operationURLS.queueUpdate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.message,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: pageName,
          });
        });
    } else {
      application
        .post(operationURLS.queueCreate, data)
        .then((response) => {
          setUpdateList(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: pageName,
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            status: error.response.data.messageDetail,
            type: "error",
            pagename: pageName,
            message: error.response.data.message,
          });
        });
    }
    setOpenForm(false);
  }

  function onCloseForm() {
    setOpenForm(false);
  }

  const QueueDetail = (id) => {
    application
      .post(`${generalQueueOperationURLS.qlist}${id}`)
      .then((response) => {
        const result = response.data.data;

        setQueueValue(dispatch, result);
      })
      .catch((err) => {
        console.error("error: ", err);
      });
  };

  useEffect(() => {
    if (ivrExtension && ivrExtension.code && ivrExtension.code.id) {
      QueueDetail(ivrExtension.code.id);
    }
  }, [ivrExtension]);

  useEffect(() => {
    setFormData(queueValue);
  }, [queueValue]);


  function GeneralQueueSave(queueValue) {
    const id = ivrExtension?.code?.id;
    const acd = ivrExtension?.code?.acdNumber;
    queueValue = { ...queueValue, acdNumber: acd ,id:id};
    application  .post( generalQueueOperationURLS.queueUpdate,queueValue  )
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.message,
          type: "success",
          pagename: "Call-Center",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.message,
          type: "error",
          pagename: "Call-Center",
        });
      });
  }

  return (
    <>
      <Stack spacing={1} direction="row">
        <MDBox>
          <CTLBaseDialogForm
            entityName="Call-Center"
            columns={getColumns(deleteRow, editRow)}
            openForm={openForm}
            onSaveForm={queueOnSaveForm}
            onCloseForm={onCloseForm}
            formData={formData}
            pageAttributes={Attributes}
          />
          <CTLBaseForm
            enableFormButtons={true}
            entityName="Queue"
            columns={generalQueueUpdateColumns()}
            openForm={true}
            onCloseForm={null}
            formData={formData}
            formAttributes={generalQueueAttribute}
            onSaveForm={GeneralQueueSave}
          />
          <CTLNotification notify={notify} setNotify={setNotify} />
        </MDBox>
      </Stack>
    </>
  );
}

export default GeneralQueue;
