import React from 'react';
import { useEffect, useState } from "react";
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ucMenus } from "globals/configuration/uc";
import { application } from "globals/endpoints";
// Material Dashboard 2 PRO React examples
import SidenavCollapse from "features/Sidenav/SidenavCollapse";
import SidenavList from "features/Sidenav/SidenavList";
import SidenavItem from "features/Sidenav/SidenavItem";
// Custom styles for the Sidenav
import SidenavRoot from "features/Sidenav/SidenavRoot";
import sidenavLogoLabel from "features/Sidenav/styles/sidenav";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import { useQuery } from 'react-query';
import { ivrMenus } from 'globals/configuration/ivr/menu';
import { callCenterMenus } from 'globals/configuration/call-center/menu';
// Material Dashboard 2 PRO React context



import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setActivePage
} from "context";
import { adminMenus } from 'globals/configuration/admin';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, activePage } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = activePage.url.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  useEffect(() => {

    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);



  //handle Notification Bar
  function handleNotificationBar() {
    setMiniSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
    setWhiteSidenav(dispatch, false);
  }

  useEffect(() => {
    handleNotificationBar();
    return () => { };
  }, [dispatch, location]);


  //Link to Page clicked
  const menuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
  collapses.filter(menu => menu.display).map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === activePage.key} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === activePage.key} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.filter(menu => menu.display).map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );


  let renderNotifications = null;

  renderNotifications = ucMenus.map((menu) => {
    if (menu.type === "divider") {
      return null;
    }
    const countsByMessageType = (counts || []).reduce((acc, notification) => {
      acc[notification.messageType] = notification.count;
      return acc;
    }, {});
    const count = countsByMessageType[menu.messageType] ?? 0;
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidenavCollapse
          name={menu.name}
          icon={menu.icon}
          badge={count}
        />
      </Link>
    );


  })

  //ivr Menus
  let renderIvrMenu = null;

  renderIvrMenu = adminMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidenavCollapse
          name={menu.name}
          icon={menu.icon}
        />
      </Link>
    );
  })

  //Call -Center Menus
  let renderCallCenterMenu = null;

  renderCallCenterMenu = callCenterMenus.map((menu) => {
    return (
      <Link
        key={menu.key}
        onClick={() => menuClicked(menu)}
        to="javascript:void(0)"
      >
        <SidenavCollapse
          name={menu.name}
          icon={menu.icon}
        />
      </Link>
    );
  })
   //Admin Menus
   let renderAdminMenu = null;

   renderAdminMenu = ivrMenus.map((menu) => {
     return (
       <Link
         key={menu.key}
         onClick={() => menuClicked(menu)}
         to="javascript:void(0)"
       >
         <SidenavCollapse
           name={menu.name}
           icon={menu.icon}
         />
       </Link>
     );
   })

  return (

    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >

      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={(darkMode && !whiteSidenav) ||
            (!darkMode && !whiteSidenav && !transparentSidenav)
            ? "/logo.png" : "/logo.png"} alt="Brand" width="100%" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <MDTypography variant="h6" color="secondary" sx={{ textAlign: "center" }}>
        {AuthenticationService.getFullName()}
      </MDTypography>
      {AuthenticationService.getExtensionNumber() && <MDTypography variant="body2" color="secondary" sx={{ textAlign: "center" }}>
        {AuthenticationService.getExtensionNumber()}
      </MDTypography>
      }
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      

      {AuthenticationService.allowUCMenus() && (
        <List>
          {renderNotifications}
        </List>
      )}
      {AuthenticationService.allowAdminMenus() && (
        <List>
          <List>{renderAdminMenu}</List>
          <List>{renderIvrMenu}</List>
          <List>{renderCallCenterMenu}</List>
        </List>
      )}

      <List>{renderRoutes}</List>


    </SidenavRoot>

  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
