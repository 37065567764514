import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Wrapper from "../Wrapper";

function PopUp({ open, isAdmin, handleClose, dashboardId }) {
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode ? theme.palette.background.default : theme.palette.white.main,
        })}
      >
        <Wrapper id={dashboardId} isAdmin={isAdmin} dashboardType="dashboard" popupMode />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode ? theme.palette.background.default : theme.palette.white.main,
          marginTop: "-1em",
        })}
      >
        <MDButton color="primary" type="button" onClick={handleClose}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default PopUp;
