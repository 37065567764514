import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Add } from "@mui/icons-material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PanToolIcon from "@mui/icons-material/PanTool";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import LanIcon from "@mui/icons-material/Lan";
import PreviewIcon from '@mui/icons-material/Preview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

function RowAction({
  row,
  onAdd,
  onUploadLead,
  onDelete,
  onView,
  onEdit,
  onCopy,
  onNodeStatusOpen,
  formData,
  onSpeaker,
  onMute,
  onRaiseHand,
  onHoldCall,
  nmsSetting,
  onFormSave,
  onUpdateConfiguration,
  onUpdateFirmware,
  onReboot,
  onAssigned,
  onReset,
  onDeployConfiguration
}) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      <tooltip title="Lead Uplaod">
        {onUploadLead && (
          <IconButton
            color="primary"
            onClick={() => onUploadLead(row.original)}
          >
            <Add></Add>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Add">
        {onAdd && (
          <IconButton color="info" onClick={() => onAdd(row.original)}>
            <Add></Add>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Update">
        {onEdit && (
          <IconButton color="success" onClick={() => onEdit(row.original)}>
            <OpenInNewIcon></OpenInNewIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Copy">
        {onCopy && (
          <IconButton color="success" onClick={() => onCopy(row.original)}>
            <ContentCopyIcon />
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Delete">
        {onDelete && (
          <IconButton color="error" onClick={() => onDelete(row.original)}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="View">
        {onView && (
          <IconButton color="info" onClick={() => onView(row.original)}>
            <PreviewIcon></PreviewIcon>
          </IconButton>
        )}
      </tooltip>
      {onNodeStatusOpen && (
        <IconButton
          color="info"
          onClick={() => onNodeStatusOpen(formData.nodeCscf || row.original)}
        >
          <PlaylistAddIcon></PlaylistAddIcon>
        </IconButton>
      )}
      <tooltip title="Speaker">
        {onSpeaker && (
          <IconButton color="primary" onClick={() => onSpeaker(row.original)}>
            <VolumeUpIcon></VolumeUpIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="mute">
        {onMute && (
          <IconButton color="warning" onClick={() => onMute(row.original)}>
            <VolumeOffIcon></VolumeOffIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Raise Hand">
        {onRaiseHand && (
          <IconButton color="success" onClick={() => onRaiseHand(row.original)}>
            <PanToolIcon></PanToolIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Hold Call">
        {onHoldCall && (
          <IconButton color="info" onClick={() => onHoldCall(row.original)}>
            <PhonePausedIcon></PhonePausedIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="NMS Settings">
        {nmsSetting && (
          <IconButton color="primary" onClick={() => nmsSetting(row.original)}>
            <LanIcon></LanIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Open File">
        {onFormSave && (
          <IconButton color="info" onClick={() => onFormSave(row.original)}>
            <AttachFileIcon></AttachFileIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Update Config">
        {onUpdateConfiguration && (
          <IconButton
            color="info"
            onClick={() => onUpdateConfiguration(row.original)}
          >
            <AppSettingsAltIcon></AppSettingsAltIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Deploy Firmware">
        {onUpdateFirmware && (
          <IconButton
            color="warning"
            onClick={() => onUpdateFirmware(row.original)}
          >
            <SecurityUpdateGoodIcon></SecurityUpdateGoodIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Deploy Configuration">
        {onDeployConfiguration && (
          <IconButton
            color="warning"
            onClick={() => onDeployConfiguration(row.original)}
          >
            <SecurityUpdateGoodIcon></SecurityUpdateGoodIcon>
          </IconButton>
        )}
      </tooltip>

      <tooltip title="Reset">
        {onReset && (
          <IconButton color="success" onClick={() => onReset(row.original)}>
            <SettingsBackupRestoreIcon></SettingsBackupRestoreIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Reboot">
        {onReboot && (
          <IconButton color="primary" onClick={() => onReboot(row.original)}>
            <RestartAltIcon></RestartAltIcon>
          </IconButton>
        )}
      </tooltip>
      <tooltip title="Assigned Phone">
        {onAssigned && (
          <IconButton color="primary" onClick={() => onAssigned(row.original)}>
            <BrowserUpdatedIcon></BrowserUpdatedIcon>
          </IconButton>
        )}
      </tooltip>
    </MDTypography>
  );
}

export default RowAction;
