import { useEffect, useState } from "react";
import { application } from "globals/endpoints";
import {
  useMaterialUIController,
  setSundayDisabled,
  setMondayDisabled,
  setTuesdayDisabled,
  setWednesdayDisabled,
  setThursdayDisabled,
  setFridayDisabled,
  setSaturdayDisabled,
} from "context";
import CTLBaseForm from "application/components/CTLBaseForm";
import CTLNotification from "application/components/CTLNotification";
import { getColumns, Attributes, operationURLS } from "./model";

function DayTimeSetting({
  onLoad,
  registerCallBacks,
  updateTableList,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { activePage, ivrExtension } = controller;
  const [openForm, setOpenForm] = useState(false);
  const [data , setData] = useState();
  const [formData, setFormData] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    QueueDetail(ivrExtension?.code?.id);
  }, [ivrExtension]);

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks &&
      registerCallBacks({
        openForm: onOpenForm,
      });
  }, [activePage]);

  const QueueDetail = (id) => {
    application
      .post(`${operationURLS.qlist}/${id}`)
      .then((response) => {
        const result = response.data.data;
        setData(result);
      })
      .catch((err) => {
        console.error("error: ", err);
      });
  };

  useEffect(() => {
    if(data){
    if (data.dsun) {
      setSundayDisabled(dispatch, false);
    }
    if (data.dmon) {
      setMondayDisabled(dispatch, false);
    }
    if (data.dtue) {
      setTuesdayDisabled(dispatch, false);
    }
    if (data.dwed) {
      setWednesdayDisabled(dispatch, false);
    }
     if (data.dthu) {
       setThursdayDisabled(dispatch, false);
     }
     if (data.dfri) {
       setFridayDisabled(dispatch, false);
     }
     if (data.dsat) {
       setSaturdayDisabled(dispatch, false);
     }
    }
    setFormData(data);
  }, [data]);

  useEffect(() => {
    setUpdateList(Date.now());
  }, [updateTableList]);

  function onOpenForm() {
    setFormData({
      eventTime: Date.now(),
    });
    setOpenForm(true);
  }

  function onSaveForm(data) {
    data = {
      ...data,
      systemName: data.systemName,
      acdNumber: ivrExtension.code.acdNumber,
    };
    application
      .post(operationURLS.queueUpdate, data)
      .then((response) => {
        setUpdateList(Date.now());
        setNotify({
          isOpen: true,
          status: response.data.message,
          type: "success",
          pagename: "DayTimeSetting",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          status: error.response.data.message,
          type: "error",
          pagename: "DayTimeSetting",
        });
      });
  }

  return (
    <>
      <CTLBaseForm
        enableFormButtons={true}
        entityName="DayTimeSetting"
        columns={getColumns(null, null)}
        openForm={true}
        onCloseForm={null}
        formData={formData}
        formAttributes={Attributes}
        onSaveForm={onSaveForm}
      />
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default DayTimeSetting;
