import { useState } from "react";
import Draggable from "react-draggable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import LabelPopup from "../LabelPopup";
import { Menu, MenuItem } from "@mui/material";

function Label({
  isAdmin,
  isEditMode,
  sensor,
  handleLabelDragStop,
  setDroppedSensors,
  handleSaveConfiguration,
}) {
  const [labelPopUpOpen, setLabelPopUpOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const { darkMode } = useMaterialUIController()[0];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleLabelPopupOpen = () => {
    setLabelPopUpOpen(true);
    setContextMenu(null);
  };

  const handleLabelPopupClose = (labelConfig, labelAssociatedSensor) => {
    const newSensor = {
      ...labelAssociatedSensor,
      configuration: {
        ...labelAssociatedSensor.configuration,
        labelText: labelConfig.text,
        labelOrientation: labelConfig.orientation,
        labelFontSize: labelConfig.fontSize,
      },
    };
    setDroppedSensors((prevDroppedSensors) =>
      prevDroppedSensors.map((sensor) =>
        sensor.provisioningId === labelAssociatedSensor.provisioningId
          ? newSensor
          : sensor,
      ),
    );
    setLabelPopUpOpen(false);
    handleSaveConfiguration(newSensor);
  };

  return (
    <>
      <Draggable
        position={{
          x: sensor.configuration.labelX,
          y: sensor.configuration.labelY,
        }}
        onStop={(e, ui) => handleLabelDragStop(ui.lastX, ui.lastY, sensor)}
        disabled={!isAdmin || !isEditMode}
      >
        <MDBox
          sx={{
            cursor: "pointer",
            writingMode:
              sensor.configuration.labelOrientation === "vertical"
                ? "vertical-lr"
                : "",
          }}
          position="absolute"
          onContextMenu={handleContextMenu}
        >
          <MDTypography
            sx={{
              color: "black",
              backgroundColor: darkMode ? "#000" : "#FFF",
              paddingInline: "0.3em",
              borderRadius: "0.5em",
              fontSize: `${sensor.configuration.labelFontSize / 10}em`,
              textOrientation:
                sensor.configuration.labelOrientation === "vertical"
                  ? "upright"
                  : "",
            }}
            fontWeight="bold"
          >
            {sensor.configuration.labelText}
          </MDTypography>
        </MDBox>
      </Draggable>
      {isAdmin && isEditMode && (
        <Menu
          open={contextMenu !== null}
          onClose={() => setContextMenu(null)}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleLabelPopupOpen}>Edit label</MenuItem>
        </Menu>
      )}
      <LabelPopup
        sensor={sensor}
        labelPopUpOpen={labelPopUpOpen}
        handleLabelPopupClose={handleLabelPopupClose}
        setLabelPopUpOpen={setLabelPopUpOpen}
      />
    </>
  );
}

export default Label;
