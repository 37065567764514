import MDTypography from "components/MDTypography";

function CTLACDCell({ value, suffix }) {
  if (value === 0) {
    suffix = "InBound";
  }
  if (value === 1) {
    suffix = OutBound;
  }
  if (value === 2) {
    suffix = Blanded;
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLACDCell.defaultProps = {
  suffix: "",
};

export default CTLACDCell;
