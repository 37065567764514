import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import CTLDialogContent from "application/components/styles/CTLDialogContent";
import Calendar from "./Calendar";
import NextEvents from "./NextEvents";
import { application } from "globals/endpoints";
import CTLConferenceForm from "application/components/CTLConferenceForm";
import CTLNotification from "application/components/CTLNotification";

function CTLMeetingCalendar({ onLoad }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [open, setOpen] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [calendarKey, setCalendarKey] = useState(Date.now());
  const [data, setData] = useState([]);
  const [apiFormData, setApiFormData] = useState();
  const [phoneType, setPhoneType] = useState("");
  const [schedule, setSchedule] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const operationURLS = {
    list: "conferenceSettings/listAll",
    update: "conferenceRoom/update",
    create: "conferenceRoom/create",
    delete: "conferenceRoom/delete",
    reSchedule: "conference/update/reSchedule",
    cancel: "conference/update/cancel",
    conferenceNumberList: "extensions/conferenceNumber",
    groupList: "userGroup/groupUserList",
    conferenceParticipant: "conferenceParticipant/list",
    participantDelete: "conferenceParticipant/delete",
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    let isMounted = true; 

    const fetchData = async () => {
      try {
        const response = await application.post(`conferenceRoom/listAll`);
        const activeMeetings = response?.data?.data.activeMeeting;
        const meetingList = response?.data?.data.meetingList;
        const mergedArray = [
          ...meetingList,
          ...activeMeetings.filter(
            (meeting) =>
              !meetingList.some(
                (activeMeeting) => activeMeeting.roomName === meeting.roomName
              )
          ),
        ];
        if (isMounted) {
          setData(mergedArray);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) {
          setData([]);
          setNotify({
            isOpen: true,
            message: "",
            type: "error",
            pagename: "Conference Event",
            status: error.response?.data?.message,
          });
        }
      }
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [calendarDate, calendarKey]);

  const createConference = (formData) => {
    setOpenPopup(false);
    if (formData.id) {
      application
        .post(operationURLS.update, formData)
        .then((response) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: error.response.data.messageDetail,
            type: "error",
            pagename: "Conference",
            status: error.response.data.message,
          });
        });
    } else if (formData.modId) {
      application
        .post(operationURLS.reSchedule, formData)
        .then((response) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.status,
            type: "success",
            pagename: "Conference",
            status: response.data.messageDetail,
          });
        })
        .catch((error) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: "Conference",
            status: error.response.data.status,
          });
        });
    } else {
      application
        .post(operationURLS.create, formData)
        .then((response) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: response.data.messageDetail,
            type: "success",
            pagename: "Conference",
            status: response.data.message,
          });
        })
        .catch((error) => {
          setCalendarKey(Date.now());
          setNotify({
            isOpen: true,
            message: error.response.data.message,
            type: "error",
            pagename: "Conference",
            status: error.response.data.status,
          });
        });
    }
    setCalendarKey(Date.now());
  };

  const currentDate = new Date();

  const calendarEventsData = data
    ? data.map((item, index) => {
        const currentDateFormatted = currentDate.toISOString().slice(0, 10);
        const eventDateFormatted = new Date(item.meetingScheduledDate)
          .toISOString()
          .slice(0, 10);

        let className = "";

        if (eventDateFormatted === currentDateFormatted) {
          className = "success";
        } else if (eventDateFormatted > currentDateFormatted) {
          const oneWeekLater = new Date();
          oneWeekLater.setDate(oneWeekLater.getDate() + 7);
          if (new Date(eventDateFormatted) <= oneWeekLater) {
            className = "warning";
          } else {
            className = "info";
          }
        } else if (eventDateFormatted < currentDateFormatted) {
          className = "error";
        }

        return {
          id: JSON.stringify(item),
          title: item.roomName,
          start: `${item.meetingScheduledDate}T${item.scheduledFromTime}`,
          end: `${item.meetingScheduledDate}T${item.scheduledToTime}`,
          className: className,
        };
      })
    : [];

  return (
    <MDBox>
      <CTLConferenceForm
        open={open}
        setOpen={setOpen}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        operationURLS={operationURLS}
        createConference={createConference}
        apiFormData={apiFormData}
        setApiFormData={setApiFormData}
        schedule={schedule}
        setSchedule={setSchedule}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} xl={9} sx={{ height: "max-content" }}>
          <Card sx={(theme) => CTLDialogContent(theme, { darkMode })}>
            {data && calendarKey && (
              <Calendar
                setPhoneType={setPhoneType}
                setOpen={setOpen}
                calendarKey={calendarKey}
                setCalendarKey={setCalendarKey}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                events={calendarEventsData.flat()}
                setCalendarDate={setCalendarDate}
                setApiFormData={setApiFormData}
                setSchedule={setSchedule}
                operationURLS={operationURLS}
                setOpenPopup={setOpenPopup}
                openPopup={openPopup}
                selectable
                editable
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} xl={3}>
          <MDBox mb={3}>
            <NextEvents
              data={data}
              phoneType={phoneType}
              setPhoneType={setPhoneType}
            />
          </MDBox>
        </Grid>
      </Grid>
      <CTLNotification notify={notify} setNotify={setNotify} />
    </MDBox>
  );
}

export default CTLMeetingCalendar;
