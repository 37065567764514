import React, { useState, useEffect } from "react";
import CTLNotification from "application/components/CTLNotification";
import { useMaterialUIController } from "context";
import { getColumns } from "./model";
import CTLTable from "application/components/CTLTable";
import CSVTable from "./csvTable";
import { Card } from "@mui/material";

const Attributes = {
  enableAcdDetails: true,
  modes: [],
};

function LeadUpload({
  onLoad,
  registerCallBacks,
  pageId,
  searchText,
  campaignId,
  viewClick,
  updateLeadUpload,
}) {
  const [controller] = useMaterialUIController();
  const { ivrExtension, activePage } = controller;
  const [open, setOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [updateList, setUpdateList] = useState(Date.now());
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useEffect(() => {
    Attributes && onLoad(Attributes);
    registerCallBacks && registerCallBacks({});
  }, [activePage, updateLeadUpload]);

  const operationURLS = {
    list: `/leadMaster/listAllLeadMaster/${campaignId}`,
  };

  useEffect(() => {
    setUpdateList(Date.now());
  }, [ivrExtension, campaignId]);

  const onSelectionChange = (row) => {
    setSelectedRow(row);
  };
  function onView(row) {
    setOpen(true);
    setUuid(row.uniqueLeadUpload);
  }

  function onCloseCSVTable() {
    setOpen(false);
  }

  return (
    <>
      <CSVTable
        openCSVTable={open}
        onCloseCSVTable={onCloseCSVTable}
        LeadUUID={uuid}
      />
    
        {viewClick && updateLeadUpload && (
          <Card
            style={{
              margin: 1,
              overflow: "scroll",
              overflowX: "hidden",
              maxHeight: 240,
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            <CTLTable
              apiURL={operationURLS.list}
              onSelectionChange={onSelectionChange}
              columns={getColumns(onView)}
              entriesPerPage="false"
              canSearch
              defaultSortDirection="asc"
              defaultSortColumn="campaignName"
              pageId={pageId}
              searchText={searchText}
              hasRowSelection={false}
              updateList={updateList}
              disablePagination={true}
            />
          </Card>
        )}
     
      <CTLNotification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default LeadUpload;
