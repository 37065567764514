import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  Link,
  ToggleButtonGroup,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import Breadcrumbs from "features/Breadcrumbs";
import UCNotificationItem from "application/components/UCNotificationItem";
import Divider from "@mui/material/Divider";
import { ucMenus } from "globals/configuration/uc";
import { hmsAdminMenus } from "globals/configuration/hmsAdmin";
import { adminMenus } from "globals/configuration/admin";
import { userProfileMenus } from "globals/configuration/userProfile";
import { kmAdminMenus } from "globals/configuration/kmAdmin";
import Badge from "@mui/material/Badge";
import AuthenticationService from "application/modules/authentication/AuthenticationService";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import SIPPhoneService from "../SIPPhone/SIPPhoneService";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ListIcon from "@mui/icons-material/List";
import ClearIcon from "@material-ui/icons/Clear";
import loggerFactory from "globals/logger/logger-factory";
import AppsIcon from "@mui/icons-material/Apps";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "features/Navbars/DashboardNavbar/styles";
import MarkChatReadTwoToneIcon from "@mui/icons-material/MarkChatReadTwoTone";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setOpenConfigurator,
  setOpenPhone,
  setActivePage,
  setSearchText,
  setIvrExtension,
  setUpdateListFlag,
  setSipTrunkSelect,
  setToggleButtonStatus,
} from "context";
import MDInput from "components/MDInput";
import { makeStyles } from "@material-ui/core/styles";
import { application } from "globals/endpoints";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import CTLNMSSelect from "../CTLNMSSelect";
import CTLAlertNotification from "../CTLAlertNotification";
import useNotification from "globals/hooks/useNotification";
import MDTypography from "components/MDTypography";
import CollapsibleMenu from "../DashboardController/CollapsibleMenu";
import CTLServerSwitch from "application/components/CTLServerSwitch";
import AgentDetails from "../CTLAgentDashboard/agentDetails";
import CTLNotification from "../CTLNotification";
import CTLConfirmationBox from "../CTLConfirmationBox";
import MDButton from "components/MDButton";

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));

function CTLNavbar({
  absolute,
  light,
  isMini,
  pageAttributes,
  onSearchTextChange,
  onAdvancedFilter,
  onOpenForm,
  DownloadSelected,
  onPublish,
  onChat,
  onFileExport,
  onFileImport,
  onSendMessage,
  onSync,
  onNMSSelect,
  onMarkasRead,
  onMultipleDelete,
  onAgent,
  onDefaultComparison,
  onNoDefaultComparison,
  onAllList,
  onPrint,
  onFilePreView,
  PriPublish,
  Paste,
  onRefresh,
  DownloadData,
  DownloadPdf,
  onUploadFax,
  CollapsibleMenuOpen,
  UpdateTableRecords,
  MultipleUpdate,
  onEnableApiAccess,
  ExitPage,
}) {
  const componentName = "CTLNavbar";
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
    openPhone,
    activePage,
    searchText,
    ivrExtension,
    updateListFlag,
    AgentData,
  } = controller;
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openApp, setOpenApp] = useState(false);
  const [openUCMenu, setOpenUCMenu] = useState(false);
  const [openCollapsibleMenu, setOpenCollapsibleMenu] = useState(false);
  const [openkmAdminMenu, setOpenkmAdminMenu] = useState(false);
  const [openhmsAdminMenu, setOpenhmsAdminMenu] = useState(false);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  // const [searchText, setSearchText] = useState("");
  const [changeSipSelect, setChangeSipSelect] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [list, setList] = useState([]);
  const classes = useStyles();
  const [alignment, setAlignment] = useState("Disposition");
  const [counts, setCounts] = useState([]);
  const [selectedIVR, setSelectedIVR] = useState("");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
    pagename: "",
    status: "",
  });

  useNotification();

  useEffect(() => {
    if (isFirstRender && SIPPhoneService.enabled) {
      setOpenPhone(dispatch, false);
    }
    setIsFirstRender(false);
  }, [isFirstRender]);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    selectedList();
  }, [pageAttributes.selectUrl]);

  useEffect(() => {
    if (ivrExtension?.code) {
      setSelectedIVR(ivrExtension.code);
    }
  }, [ivrExtension]);

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenApp = (event) => setOpenApp(event.currentTarget);
  const handleCloseApp = () => setOpenApp(false);
  const handleOpenPhone = () => setOpenPhone(dispatch, !openPhone);
  const handleOpenMoreMenu = (event) => setOpenMoreMenu(event.currentTarget);
  const handleCloseMoreMenu = () => setOpenMoreMenu(false);
  const handleUCMenuOpen = (event) => {
    setOpenUCMenu(event.currentTarget);
  };
  const handleUCMenuClose = () => setOpenUCMenu(false);
  const handlehmsAdminMenuOpen = (event) => {
    setOpenhmsAdminMenu(event.currentTarget);
  };
  const handlehmsAdminMenuClose = () => setOpenhmsAdminMenu(false);
  const handlekmAdminMenuOpen = (event) => {
    setOpenkmAdminMenu(event.currentTarget);
  };
  const handlekmAdminMenuClose = () => setOpenkmAdminMenu(false);

  const handleMoreOptionMenuClicked = (menuItem) => {
    activePage.key = menuItem.id;
    activePage.name = menuItem.name;
    activePage.url = menuItem.url;
    activePage.description = menuItem.description;
    activePage.appId = menuItem.appId;
    setActivePage(dispatch, activePage);
    setOpenMoreMenu(false);
    setSearchText(dispatch, "");
  };

  function handleTransparentNavbar() {
    setTransparentNavbar(
      dispatch,
      (fixedNavbar && window.scrollY === 0) || !fixedNavbar
    );
  }

  const ucMenuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setOpenUCMenu(false);
    setSearchText(dispatch, "");
  };
  const hmsAdminMenuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setOpenhmsAdminMenu(false);
    setSearchText(dispatch, "");
  };
  const kmAdminMenuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setOpenkmAdminMenu(false);
    setSearchText(dispatch, "");
  };

  const adminMenuClicked = (menu) => {
    activePage.key = menu.key;
    activePage.name = menu.name;
    activePage.url = menu.url;
    activePage.description = menu.description;
    setActivePage(dispatch, activePage);
    setOpenMenu(false);
    setSearchText(dispatch, "");
  };
  const userProfileMenuClicked = () => {
    (activePage.key = "change-Usert-Profile"),
      (activePage.name = "Change-User-Profile"),
      (activePage.description = "Change User Profile"),
      (activePage.url = "home/app/configurations/changeAgentProfile"),
      setActivePage(dispatch, activePage);
    setSearchText(dispatch, "");
    return true;
  };

  const handleAppOpen = (menu) => {
    if (menu && menu.url) {
      const url = `${currentServerAddress}${menu.url}`;
      console.log("Constructed URL:", url);
      window.location.href = url;
    } else {
      console.error("Invalid menu object or missing URL");
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {AuthenticationService.allowAdminMenus() &&
        adminMenus.map((menu) => {
          if (
            !menu.disabled &&
            (menu.productionReady ||
              window.demoMode ||
              process.env.REACT_APP_MODE !== "ui-production")
          ) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link
                  key={menu.key}
                  onClick={() => adminMenuClicked(menu)}
                  to="javascript:void(0)"
                >
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  //For External App
  const appMenuString = window.sessionStorage.getItem(
    `${window.location.hostname}-coral-X-Apps`
  );
  let tokenForCookie = AuthenticationService.getAuthenticationToken();

  // Check if appMenuString is not null or undefined
  if (appMenuString) {
    // Convert the JSON string back to an array of objects
    const appMenu = JSON.parse(appMenuString);

    // Loop through each object in appMenu
    appMenu.forEach((item) => {
      // Extract the cookieName property
      const cookieName = item.cookieName;

      // Set the cookie with the cookieName
      document.cookie = `${cookieName}=${tokenForCookie};`;
    });
  }

  const currentServerAddress = window.location.origin;
  const AppMenu = AuthenticationService.getApps();
  const renderApps = () => (
    <Menu
      anchorEl={openApp}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openApp)}
      onClose={handleCloseApp}
      sx={{ mt: 2 }}
    >
      {AuthenticationService.allowApps() &&
        AppMenu?.map((menu) => {
          return (
            <Link
              key={menu.key}
              to={`${currentServerAddress}${menu.url}`}
              onClick={() => handleAppOpen(menu)}
            >
              <UCNotificationItem
                icon={<Icon>arrow_right_icon</Icon>}
                title={menu.applicationName}
              />
            </Link>
          );
        })}
    </Menu>
  );

  const renderhmsAdminMenu = () => (
    <Menu
      anchorEl={openhmsAdminMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openhmsAdminMenu)}
      onClose={handlehmsAdminMenuClose}
      sx={{ mt: 2 }}
    >
      {AuthenticationService.allowhmsAdminMenus() &&
        hmsAdminMenus.map((menu) => {
          if (!menu.disabled) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link
                  key={menu.key}
                  onClick={() => hmsAdminMenuClicked(menu)}
                  to="javascript:void(0)"
                >
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  const renderkmAdminMenu = () => (
    <Menu
      anchorEl={openkmAdminMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openkmAdminMenu)}
      onClose={handlekmAdminMenuClose}
      sx={{ mt: 2 }}
    >
      {AuthenticationService.allowkmAdminMenus() &&
        kmAdminMenus.map((menu) => {
          if (!menu.disabled) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              return (
                <Link
                  key={menu.key}
                  onClick={() => kmAdminMenuClicked(menu)}
                  to="javascript:void(0)"
                >
                  <UCNotificationItem
                    icon={<Icon>{menu.icon}</Icon>}
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  const renderUCMenu = () => (
    <Menu
      anchorEl={openUCMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUCMenu)}
      onClose={handleUCMenuClose}
      sx={{ mt: 2 }}
    >
      {AuthenticationService.allowUCMenus(counts) &&
        ucMenus &&
        ucMenus.map((menu) => {
          if (!menu.disabled) {
            if (menu.type == "divider") {
              return <Divider key={menu.key} />;
            } else {
              const countsByMessageType = (counts || []).reduce(
                (acc, notification) => {
                  acc[notification.messageType] = notification.count;
                  return acc;
                },
                {}
              );
              const count = countsByMessageType[menu.messageType] ?? 0;
              return (
                <Link
                  key={menu.key}
                  onClick={() => ucMenuClicked(menu)}
                  to="javascript:void(0)"
                >
                  <UCNotificationItem
                    icon={
                      <Badge badgeContent={count} color="primary">
                        <Icon>{menu.icon}</Icon>
                      </Badge>
                    }
                    title={menu.name}
                  />
                </Link>
              );
            }
          }
        })}
    </Menu>
  );

  const renderMoreOptions = () => (
    <Menu
      anchorEl={openMoreMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMoreMenu)}
      onClose={handleCloseMoreMenu}
      sx={{ mt: 2 }}
    >
      {pageAttributes.modes.map((menuItem) => {
        if (
          !menuItem.disabled &&
          (menuItem.productionReady ||
            window.demoMode ||
            process.env.REACT_APP_MODE !== "ui-production")
        ) {
          return (
            <Link
              to="javascript:void(0)"
              key={menuItem.id}
              onClick={() => handleMoreOptionMenuClicked(menuItem)}
            >
              <UCNotificationItem
                icon={<Icon>{menuItem.iconName}</Icon>}
                title={menuItem.name}
              />
            </Link>
          );
        } else {
          return null;
        }
      })}
    </Menu>
  );

  //API Access Key

  const operationUrl = {
    accessKey: "/user/createAPIKey",
  };

  function onEnableApiAccess() {
    setConfirmBox(true);
  }

  function onAgree() {
    application.post(operationUrl.accessKey).then((response) => {
      setNotify({
        isOpen: true,
        message: response.data.messageDetail,
        status: `API KEY = ${response.data.data}`,
        type: "success",
        pagename: "Please Copy this token, or it will not be displayed again.",
      });
      setConfirmBox(false);
    });
  }
  function onCancel() {
    setConfirmBox(false);
  }

  function CollapsibleMenuOpen(menuItem) {
    activePage.key = menuItem.widgetId;
    activePage.name = menuItem.dashboardType;
    activePage.url = "home/app";
    activePage.description = menuItem.dashboardName;
    activePage.appId = menuItem.widgetId;
    setActivePage(dispatch, activePage);
  }

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleSearchText = (event) => {
    setSearchText(dispatch, event.target.value);
    onSearchTextChange(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSipTrunkSelect(dispatch, value);
    setChangeSipSelect(value);
  };

  const onIVRSelect = (event) => {
    const {
      target: { value },
    } = event;
    setIvrExtension(dispatch, {
      code: value,
      changedOn: Date.now(),
    });
    setSelectedIVR(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (updateListFlag) {
      setTimeout(() => {
        selectedList();
      }, 1000);
    }
  }, [updateListFlag]);

  const operationURLS = { selectList: pageAttributes.selectUrl };
  const selectedList = () => {
    application
      .post(operationURLS.selectList)
      .then((response) => {
        setList(response.data.data);
        setUpdateListFlag(dispatch, false);
      })
      .catch((error) => {
        loggerFactory.error(componentName, "Unable to fetch the list", error);
      });
  };

  const URLS = {
    qdelete: "/queue/delete",
  };
  const OnDeleteQueue = (id) => {
    application
      .post(`${URLS.qdelete}/${id}`)
      .then((response) => {
        selectedList();
        setNotify({
          isOpen: true,
          message: response.data.messageDetail,
          type: "success",
          pagename: "Call-Center",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: error.response.data.message,
          type: "error",
          pagename: "Call-Center",
        });
      });
  };

  const handleChangeMode = (newAlignment) => {
    setAlignment(newAlignment);
    setToggleButtonStatus(dispatch, newAlignment);
  };

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            {!AuthenticationService.allowAgentMenus() &&
              !AuthenticationService.allowAdminMenus() && (
                <Breadcrumbs
                  icon="home"
                  description={activePage.description}
                  title={activePage.name}
                  route={activePage.url.split("/")}
                  light={light}
                />
              )}
            {AuthenticationService.allowAdminMenus() && (
              <CTLServerSwitch
                description={activePage.description}
                title={activePage.name}
                light={light}
              />
            )}
            {/* <MDBox minWidth="200px">
            {AuthenticationService.allowAgentMenus() && AgentData ? (
              <MDBox style={{ padding: "5px", width: "300px" }}>
                <MDTypography
                  fontWeight="bold"
                  textTransform="capitalize"
                  variant="h6"
                  color={light ? "white" : "dark"}
                  noWrap
                >
                  {`Campaign : ${AgentData.campaignId}`}
                </MDTypography>
                <MDTypography
                  fontWeight="bold"
                  textTransform="capitalize"
                  variant="h6"
                  color={light ? "white" : "dark"}
                  noWrap
                >
                  Lead &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:{" "}
                  {` ${AgentData.dialId}`}
                </MDTypography>
              </MDBox>
            ) : (
              <MDTypography>No Active Call</MDTypography>
            )}
          </MDBox> */}
            <MDBox minWidth="200px">
              {AuthenticationService.allowAgentMenus() && AgentData ? (
                <MDBox style={{ padding: "5px", width: "300px" }}>
                  {AgentData.agentInfo?.queueDescription && (
                    <MDTypography
                      fontWeight="bold"
                      textTransform="capitalize"
                      variant="h6"
                      color={light ? "white" : "dark"}
                      noWrap
                    >
                      {`Campaign / Queue : ${AgentData.agentInfo.queueDescription}`}
                    </MDTypography>
                  )}
                  {AgentData.dialId && (
                    <MDTypography
                      fontWeight="bold"
                      textTransform="capitalize"
                      variant="h6"
                      color={light ? "white" : "dark"}
                      noWrap
                    >
                      Lead &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:{" "}
                      {`${AgentData.dialId}`}
                    </MDTypography>
                  )}
                </MDBox>
              ) : (
                AuthenticationService.allowAgentMenus() && (
                  <>
                    {activePage.description === "Agent Dashboard" &&
                    activePage.name === "Agent Dashboard" ? (
                      <MDTypography>No Active Call</MDTypography>
                    ) : (
                      <MDTypography
                        fontWeight="bold"
                        textTransform="capitalize"
                        variant="h6"
                        color={light ? "white" : "dark"}
                        noWrap
                      >
                        {activePage.description}:{activePage.name}
                      </MDTypography>
                    )}
                  </>
                )
              )}
            </MDBox>

            <MDBox pr={1}>
              <Card>
                <CardContent style={{ padding: "0px" }}>
                  <tooltip title="Search">
                    <IconButton style={{ marginTop: "5px" }} disableRipple>
                      <Icon color="info" fontSize="large">
                        search
                      </Icon>
                    </IconButton>
                  </tooltip>
                  <MDInput
                    value={searchText}
                    onChange={handleSearchText}
                    classes={{ notchedOutline: classes.input }}
                    className={classes.textField}
                    label=""
                    style={{ padding: "5px", width: "300px" }}
                    InputProps={{
                      classes: { notchedOutline: classes.noBorder },
                      style: {
                        fontSize: "15px",
                        padding: "5px",
                        cursor: "pointer",
                      },
                      endAdornment: searchText !== "" && (
                        <tooltip title="clear">
                          <ClearIcon
                            color="error"
                            fontSize="small"
                            onClick={() => {
                              setSearchText(dispatch, "");
                              onSearchTextChange("");
                            }}
                          />
                        </tooltip>
                      ),
                    }}
                  />

                  {pageAttributes.enableAdvanceSearch && (
                    <tooltip title="Advance Filter">
                      <IconButton
                        style={{ marginTop: "5px" }}
                        onClick={onAdvancedFilter}
                      >
                        <Icon color="success" fontSize="large">
                          tune
                        </Icon>
                      </IconButton>
                    </tooltip>
                  )}
                </CardContent>
              </Card>
            </MDBox>
          </MDBox>
          {pageAttributes.enableToggleButton && (
            <ToggleButtonGroup
              color={alignment == "Disposition" ? "info" : "error"}
            >
              <MDButton
                variant={alignment == "Disposition" ? "contained" : "outlined"}
                color="info"
                onClick={() => handleChangeMode("Disposition")}
              >
                Dispositions
              </MDButton>
              <MDButton
                variant={alignment == "Customer" ? "contained" : "outlined"}
                color="info"
                onClick={() => handleChangeMode("Customer")}
              >
                Customers
              </MDButton>
            </ToggleButtonGroup>
          )}
          {pageAttributes.enableNMSSelect && (
            <MDBox>
              <CTLNMSSelect />
            </MDBox>
          )}

          {pageAttributes.enableSipSelect && (
            <MDBox>
              <Box>
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select "
                    value={changeSipSelect}
                    onChange={handleChange}
                    sx={{ height: "50px" }}
                    MenuProps={MenuProps}
                  >
                    {list.map((item) => (
                      <MenuItem key={item.fileName} value={item.file}>
                        {" "}
                        {item.file}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
          )}
          {pageAttributes.enableIVRSelect && (
            <MDBox>
              <Box>
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select "
                    value={selectedIVR}
                    onChange={onIVRSelect}
                    sx={{ height: "50px" }}
                    MenuProps={MenuProps}
                  >
                    {list.map((item, index) => (
                      <MenuItem key={index} value={item.extension}>
                        {item.accessDesc}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
          )}
          {pageAttributes.enableAcdDetails && (
            <MDBox>
              <Box>
                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="demo-simple-select-label">
                    SELECT (ACD/QUEUE)
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="SELECT ACD QUEUE"
                    value={selectedIVR}
                    onChange={onIVRSelect}
                    sx={{ height: "50px" }}
                    MenuProps={MenuProps}
                    onClose={() => setDeleteOpen(false)}
                    onOpen={() => setDeleteOpen(true)}
                  >
                    <MenuItem value="">SELECT</MenuItem>
                    {list.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item.description}&nbsp;-&nbsp;({item.acdNumber})
                        {deleteOpen && (
                          <div style={{ position: "absolute", right: 0 }}>
                            <IconButton
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                OnDeleteQueue(item.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
          )}

          {pageAttributes.enableQueue && (
            <MDBox>
              <Box>
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select "
                    value={selectedIVR}
                    onChange={onIVRSelect}
                    sx={{ height: "50px" }}
                    MenuProps={MenuProps}
                  >
                    {list.map((item, index) => (
                      <MenuItem key={index} value={item.queue}>
                        {item.description}:{item.queue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
          )}
          {pageAttributes.selectForm && (
            <MDBox>
              <Box>
                <FormControl sx={{ width: "250px" }}>
                  <InputLabel>Select</InputLabel>
                  <Select
                    label="Select "
                    value={selectedIVR}
                    onChange={onIVRSelect}
                    sx={{ height: "50px" }}
                    MenuProps={MenuProps}
                  >
                    {list.map((item, index) => (
                      <MenuItem key={index} value={item.formCode}>
                        {item.formName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </MDBox>
          )}

          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              {AuthenticationService.allowAgentMenus() && (
                <MDBox
                  color="inherit"
                  sx={(theme) => navbarRow(theme, { isMini })}
                >
                  {/* {window.isCustomer && (
                  <>
                    <MDBox style={{ display: "flex", flexDirection: "column" }}>
                      <AgentDetails
                        tooltipDescription="Recieved Calls"
                        details="calls"
                        detailsDescription="0070"
                        iconColor="info"
                        light={light}
                      />
                      <AgentDetails
                        tooltipDescription="Disposed Call"
                        details="keyboard"
                        detailsDescription="0050"
                        iconColor="warning"
                        light={light}
                      />
                    </MDBox>
                    <MDBox style={{ display: "flex", flexDirection: "column" }}>
                      <AgentDetails
                        tooltipDescription="Answered Call"
                        details="phone_callback_icon"
                        detailsDescription="0010"
                        iconColor="success"
                        light={light}
                      />
                      <AgentDetails
                        tooltipDescription="Missed Call"
                        details="phone_missed_icon"
                        detailsDescription="0060"
                        iconColor="error"
                        light={light}
                      />
                    </MDBox>
                  </>
                )} */}
                </MDBox>
              )}

              <MDBox color={light ? "white" : "inherit"}>
                {AuthenticationService.allowAdminMenus() &&
                  !AuthenticationService.allownmsMenus() && (
                    <CTLAlertNotification light={light} />
                  )}
                {pageAttributes.enableUpdateTableRecords && (
                  <tooltip title="Report Column Filter">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={UpdateTableRecords}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>table_rows</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableDownloadAllButton && (
                  <tooltip title="Download Selected">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      color="info"
                      onClick={DownloadSelected}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>save_alt_icon</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableAddButton && (
                  <tooltip title="Add">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onOpenForm}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>add</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableSendButton && (
                  <tooltip title="Broadcast Message">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onSendMessage}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>messagesent</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableMultipleUpdate && (
                  <tooltip title="Multiple Update">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={MultipleUpdate}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>dynamic_feed</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableMarkAsReadButton && (
                  <tooltip title="Multiple Read Update">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onMarkasRead}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>
                        <MarkChatReadTwoToneIcon />
                      </Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableMultipleDeleteButton && (
                  <tooltip title="Multiple Delete">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onMultipleDelete}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>delete</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableFileExportButton && (
                  <tooltip title="Upload-File">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onFileExport}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>cloud_upload</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableDownloadButton && (
                  <tooltip title="Download-CSV">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={DownloadData}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>file_download</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableUploadFaxButton && (
                  <tooltip title="Upload-Fax">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onUploadFax}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>cloud_upload</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableFileImportButton && (
                  <tooltip title="Download-File">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onFileImport}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>downloading</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enablePublishButton && (
                  <tooltip title="Publish">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onPublish}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>publish</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableChatButton && (
                  <tooltip title="Chat">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onChat}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>chat</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableFilePreViewButton && (
                  <tooltip title="File-PreView">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onFilePreView}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>visibility</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableDownloadPdf && (
                  <tooltip title="Download Pdf">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={DownloadPdf}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>picture_as_pdf</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enablePriPublish && (
                  <tooltip title="PriPublish">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={PriPublish}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>published_with_changes</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enablePaste && (
                  <tooltip title="Paste">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={Paste}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>content_paste_go_icon</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableRefresh && (
                  <tooltip title="Refresh">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onRefresh}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>refresh_icon</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableSyncButton && (
                  <tooltip title="Sync">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onSync}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>sync</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableAddAgentButton && (
                  <tooltip title="Add Agent">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onAgent}
                      disableRipple
                    >
                      <PersonAddAlt1Icon sx={iconsStyle}>
                        Agent
                      </PersonAddAlt1Icon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableNoDefaultComparisonButton && (
                  <tooltip title="Default Configuration">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onNoDefaultComparison}
                      disableRipple
                    >
                      <PlaylistAddCheckIcon sx={iconsStyle}>
                        Default Configuration
                      </PlaylistAddCheckIcon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableDefaulComparisontButton && (
                  <tooltip title="   Changed Configuration  ">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onDefaultComparison}
                      disableRipple
                    >
                      <PlaylistRemoveIcon sx={iconsStyle}>
                        Changed Configuration
                      </PlaylistRemoveIcon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableAllList && (
                  <tooltip title=" All Configuration ">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onAllList}
                      disableRipple
                    >
                      <ListIcon sx={iconsStyle}>Changed Configuration</ListIcon>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enablePrintButton && (
                  <tooltip title="Print">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onPrint}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>print</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableApiAccess && (
                  <tooltip title="Enable Api Access">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={onEnableApiAccess}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>token</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {AuthenticationService.allowPhone() &&
                  !AuthenticationService.allowAgentMenus() && (
                    <tooltip title="Phone">
                      <IconButton
                        sx={navbarIconButton}
                        size="small"
                        onClick={handleOpenPhone}
                      >
                        <Icon sx={iconsStyle}>phone</Icon>
                      </IconButton>
                    </tooltip>
                  )}

                {AuthenticationService.allownmsMenus() && (
                  <CTLAlertNotification light={light} />
                )}

                {AuthenticationService.allowkanAdminMenus() && (
                  <CTLAlertNotification light={light} />
                )}
                {AuthenticationService.allowCallcenterTabMenus() && (
                  <CTLAlertNotification light={light} />
                )}
                 {AuthenticationService.allowPAMenu() && (
                  <CTLAlertNotification light={light} />
                )}

                {AuthenticationService.allowUCMenus(counts) && (
                  <>
                    <tooltip title="settings">
                      <IconButton
                        sx={navbarIconButton}
                        size="small"
                        onClick={handleUCMenuOpen}
                      >
                        <Icon sx={iconsStyle}>settings</Icon>
                      </IconButton>
                    </tooltip>
                    {ucMenus.map((menu) => {
                      const countsByMessageType = (counts || []).reduce(
                        (acc, notification) => {
                          acc[notification.messageType] = notification.count;
                          return acc;
                        },
                        {}
                      );
                      const count = countsByMessageType[menu.messageType] ?? 0;

                      return count > 0 ? (
                        <StarBorderPurple500Icon color="error" />
                      ) : null;
                    })}
                  </>
                )}
                {AuthenticationService.allowAdminMenus() && (
                  <tooltip title="Setting">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={handleOpenMenu}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>settings</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}
                {AuthenticationService.allowhmsAdminMenus() && (
                  <tooltip title="settings">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      variant="contained"
                      onClick={handlehmsAdminMenuOpen}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>settings</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}

                {AuthenticationService.allowkmAdminMenus() && (
                  <tooltip title="settings">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      variant="contained"
                      onClick={handlekmAdminMenuOpen}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>settings</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}

                <tooltip title="Configurator">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    onClick={handleConfiguratorOpen}
                  >
                    <Icon sx={iconsStyle}>display_settings</Icon>
                  </IconButton>
                </tooltip>
                {pageAttributes.modes.length > 0 && (
                  <tooltip title="More Option">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={handleOpenMoreMenu}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>more_vert</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableCollapsibleMenu && (
                  <tooltip title="menu">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={() => {
                        setOpenCollapsibleMenu((prev) => !prev);
                      }}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>more_vert</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {pageAttributes.enableExternalApp && AppMenu.length > 0 && (
                  <tooltip title="menu">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={handleOpenApp}
                      disableRipple
                    >
                      <Icon sx={iconsStyle}>apps_icon</Icon>
                    </IconButton>
                  </tooltip>
                )}

                {/* Change  user Profile Setting   */}
                {AuthenticationService.allowUCMenus() && (
                  <tooltip title="User Profile">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={() => userProfileMenuClicked()}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>account_circle</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}

                {AuthenticationService.allowCallcenterTabMenus() && (
                  <tooltip title="User Profile">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={() => userProfileMenuClicked()}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>account_circle</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}
       {AuthenticationService.allowPAMenu() && (
                  <tooltip title="User Profile">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={() => userProfileMenuClicked()}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>account_circle</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}

                {AuthenticationService.allowAgentMenus() && (
                  <tooltip title="User Profile">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={() => userProfileMenuClicked()}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>account_circle</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}
                {AuthenticationService.allowAdminMenus() && (
                  <tooltip title="User Profile">
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={() => userProfileMenuClicked()}
                    >
                      <MDBadge color="error" size="xs" circular>
                        <Icon sx={iconsStyle}>account_circle</Icon>
                      </MDBadge>
                    </IconButton>
                  </tooltip>
                )}

                {pageAttributes.enableExitButton && (
                  <tooltip title="Exit Page">
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      onClick={ExitPage}
                      disableRipple
                    >
                      <Icon color="error">close</Icon>
                    </IconButton>
                  </tooltip>
                )}
                {renderApps()}
                {renderUCMenu()}
                {renderMenu()}
                {renderhmsAdminMenu()}
                {renderkmAdminMenu()}
                {renderMoreOptions()}
                {openCollapsibleMenu && (
                  <CollapsibleMenu
                    CollapsibleMenuOpen={CollapsibleMenuOpen}
                    setOpenCollapsibleMenu={setOpenCollapsibleMenu}
                  />
                )}
              </MDBox>
            </MDBox>
          )}

          <CTLNotification notify={notify} setNotify={setNotify} />
        </Toolbar>
      </AppBar>
      <CTLConfirmationBox
        confirmationBox={confirmBox}
        onAgree={onAgree}
        onCancel={onCancel}
      />
    </>
  );
}

CTLNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

CTLNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default CTLNavbar;
