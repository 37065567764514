import MDTypography from "components/MDTypography";
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';


function CTLPhoneStatusCell({ value, suffix }) {
  if (value === 0) {
    suffix = (
      <tooltip title="Unregister/Rights Missing">
        <SecurityUpdateWarningIcon fontSize="medium" color="error" />
      </tooltip>
    );
  }
  if (value === 1) {
    suffix = (
      <tooltip title="Unregister/With Rights">
        <AppBlockingIcon fontSize="medium" color="warning" />
      </tooltip>
    );
  }
  if (value === 2) {
    suffix = (
      <tooltip title="Registered/Rights Missing">
        <AppSettingsAltIcon fontSize="medium" color="info" />
      </tooltip>
    );
  } else if (value === 3) {
    suffix = (
      <tooltip title="Registered/With Rights">
        <SystemSecurityUpdateGoodIcon fontSize="medium" color="success" />
      </tooltip>
    );
  }
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {suffix && (
        <MDTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

CTLPhoneStatusCell.defaultProps = {
  suffix: "",
};

export default CTLPhoneStatusCell;
