import { forwardRef } from "react";
import { Slide } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Topology from "./Topology";
import useCenteredTree from "./Topology/Helpers/useCenteredTree";
import "./Topology/css/links.css";
import locationTreeDataTransform from "../../../utils/locationTreeDataTransform";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function PopupLocationTree({
  nodeDatum,
  categoryData,
  descendantsData,
  severityData,
  popupLocationTreeOpen,
  handlePopupLocationTreeClose,
  isAdmin,
  isEditMode,
}) {
  const [translate, containerRef] = useCenteredTree();
  const darkModeContext = useMaterialUIController();
  const isDarkMode = darkModeContext[0].darkMode;

  const transformedSubnetworkData = locationTreeDataTransform([nodeDatum], "popupLocationTree");

  return (
    <Dialog
      onContextMenu={(e) => e.stopPropagation()}
      open={popupLocationTreeOpen}
      onClose={handlePopupLocationTreeClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        Sub-Network Topology
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
        ref={containerRef}
      >
        <Topology
          data={transformedSubnetworkData}
          descendantsData={descendantsData}
          categoryData={categoryData}
          severityData={severityData}
          isDarkMode={isDarkMode}
          translate={translate}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      </DialogContent>
      <DialogActions
        sx={(theme) => ({
          backgroundColor: isDarkMode
            ? theme.palette.background.default
            : theme.palette.white.main,
        })}
      >
        <MDButton
          color="primary"
          type="button"
          onClick={handlePopupLocationTreeClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default PopupLocationTree;
