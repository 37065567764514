import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function GraphLayoutFilter({
  layout,
  handleLayoutChange,
  isDarkMode,
}) {
  return (
    <FormControl
      sx={{ minWidth: 200, marginTop: ".1em", marginRight: ".4em" }}
      size="large"
    >
      <InputLabel id="layout-label" sx={{ color: isDarkMode && "#FFF" }}>
        Layout filter
      </InputLabel>
      <Select
        labelId="layout-label"
        id="layout"
        value={layout}
        onChange={handleLayoutChange}
        label="Layout filter"
        sx={{
          color: isDarkMode && "#FFF",
          padding: ".5em",
        }}
      >
        <MenuItem value="cose">Cose</MenuItem>
        <MenuItem value="breadthfirst">Breadthfirst</MenuItem>
        <MenuItem value="circle">Circle</MenuItem>
        <MenuItem value="grid">Grid</MenuItem>
        <MenuItem value="random">Random</MenuItem>
      </Select>
    </FormControl>
  );
}

export default GraphLayoutFilter;
